
import Vue from 'vue';

export default Vue.extend({
  props: {
    items: Array,
    hideBack: {
      type: Boolean,
      default: false,
    },
    breadcrumbsClass: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
});
