import Vue from 'vue';
import TypeIcon, { defaultIconProps, IconData } from '@/components/icons/TypeIcon';

const POINT_FUNCTIONS_ICONS = {
  attach: ['image', 'Photo'],
  contacts: ['phone', 'Contacts'],
  comment: ['fa-comment', 'Comment'],
  checklist: ['mdi-format-list-checks', 'Checklist'],
  smsNotification: ['fa-envelope', 'SMS notification'],
  droplist: ['mdi-format-list-bulleted', 'Droplist'],
};

type PointFunctionType = 'comment' | 'contacts' | 'smsNotification' | 'checklist' | 'droplist' | 'attach';

export default Vue.extend({
  functional: true,

  name: 'PointIcon',

  props: {
    ...defaultIconProps,
    value: String,
  },

  render(h, { props }) {
    let data: IconData;

    const icon: string = POINT_FUNCTIONS_ICONS[props.value as PointFunctionType][0];
    const tooltip: string = props.tooltip || POINT_FUNCTIONS_ICONS[props.value as PointFunctionType][1];
    return h(TypeIcon, {
      props: { icon: icon, tooltip, size: 14, additionalProps: 'mr-1' },
    });
  },
});
