import Vue from 'vue';
import { createLocalVue, mount, Wrapper, MountOptions } from '@vue/test-utils';
import chai, { expect } from 'chai';
import chaiDom from 'chai-dom';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import VueOnboarding from '@/components/onboarding';
import sinon, { SinonStub } from 'sinon';

import Trips from '@/pages/Trips.vue';
import companyStore from '@/store/modules/company';
import authStore from '@/store/modules/auth';
import tripNew from '@/api/tripNew';

chai.use(chaiDom);

function readTrContent(nodes: NodeListOf<Element>): string[][] {
  const result = [];
  for (const el of nodes) {
    const cells = el.querySelectorAll('th,td');
    const rowResult: string[] = [];
    for (const cell of cells) {
      if (cell.textContent) {
        rowResult.push(cell.textContent.trim());
      } else {
        rowResult.push('');
      }
    }
    result.push(rowResult);
  }
  return result;
}

describe('page/Trips.vue', () => {
  type Instance = InstanceType<typeof Trips>;
  let mountFunction: (options?: MountOptions<Instance>) => Wrapper<Instance>;
  let tripApiStub: SinonStub;
  tripApiStub = sinon.stub(tripNew, 'list');

  const store = new Vuex.Store({
    modules: {
      company: {
        ...companyStore,
      },
      auth: {
        ...authStore,
      },
    },
  });

  beforeEach(() => {
    mountFunction = (options?: MountOptions<Instance>) => {
      // Vue вместо localVue из за ошибок, в Vuetify v2 это пофиксили
      // https://github.com/vuetifyjs/vuetify/issues/4861
      Vue.use(Vuetify, {
        lang: {
          t: (value: string) => value,
        },
      });
      Vue.use(VueRouter);
      const localVue = createLocalVue();
      localVue.use(VueOnboarding);

      const routes = [{ path: '/trips', component: Trips, name: 'trips' }];

      const router = new VueRouter({
        routes,
      });

      return mount(Trips, {
        localVue,
        store,
        router,
        mocks: {
          /*$route: {
            path: '/trips',
            hash: '',
            query: { sort: 'dbId' },
          },*/
          $t: (val: string) => val,
          $d: (val: any) => val,
        },
        stubs: {
          TripsFilter: true,
        },
        sync: false,
        ...options,
      });
    };
  });

  afterEach(() => {
    tripApiStub.restore();
  });

  it('should properly render header of trips grid', () => {
    const wrapper = mountFunction();

    return Vue.nextTick().then(() => {
      expect(wrapper.element).not.to.be.empty;
      expect(wrapper.contains('table.v-table')).to.be.true;

      const headerRows = readTrContent(wrapper.element.querySelectorAll('thead tr'));
      expect(headerRows[1].map(header => header.replace(/arrow_upward/g, '').trim())).to.deep.equal([
        'ID',
        'Events',
        'Created on',
        'Trip',
        'TransportPage.number',
        'Executor',
        'Responsible',
        'Duration',
        'Fact clients',
        'In transit',
        'At stops',
        'Status',
        'Actions',
      ]);
      expect(headerRows[2].map(header => header.replace(/arrow_upward/g, '').trim())).to.deep.equal([
        'Start',
        'Endpoint',
        'Path',
        'Trip time',
        'Amount',
        'At clients',
      ]);
    });
  });

  it.skip('should request trips', () => {
    mountFunction();
    expect(tripApiStub.calledOnce).to.be.true;
  });
});
