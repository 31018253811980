export default {
  methods: {
    userPlan(plan) {
      if (!plan) {
        return plan;
      }
      const slots = plan.split('-');
      if (slots.length !== 2) {
        return this.$tc('from {0} accounts', [plan]);
      } else if (slots[1] === '1000000') {
        return this.$tc('from {0} accounts', slots[0], [slots[0]]);
      }
      return this.$tc('{0}-{1} accounts', slots[1], slots);
    },
  },
};
