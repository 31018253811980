import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/account';
import billing from './modules/billing';
import auth from './modules/auth';
import company from './modules/company';
import branches from './modules/branches';
import payment from './modules/payment';
import site from './modules/site';
import map from './modules/map';
import mapSearch from './modules/mapSearch';
import mapTooltip from './modules/mapTooltip';
import pointFunctions from './modules/pointFunctions';
import orders from './modules/orders';
import trip from './modules/trip';
import tags from './modules/tags';
import chats from './modules/chats';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    billing,
    auth,
    company,
    branches,
    payment,
    site,
    map,
    mapSearch,
    mapTooltip,
    pointFunctions,
    orders,
    trip,
    tags,
    chats,
  },
});
