
import Vue from 'vue';
import TripPoint, {
  DeliveryWindowsDto,
  PointRadius,
  SmsNotification,
  TripPointDto,
  WorkingHours,
} from '@/models/TripPoint';
import ContactsInfo from '@/components/ContactsInfo.vue';
import { PointFunctionsDto, StayTime } from '@/models/PointFunctions';
import { PlaceLink } from '@/models/Place';
import { PointLocation } from '@/models/Location';

interface IChangePoint {
  data: Partial<TripPoint>;
  timestamp: string;
  timezoneOffset: number;
  account: {
    id: any;
    fullName: string;
  };
  status: string;
  from: Partial<TripPoint>;
  to: Partial<TripPoint>;
  error_code: any;
}

type TripPointKeys =
  | 'title'
  | 'comment'
  | 'stayTime'
  | 'contacts'
  | 'workingHours'
  | 'deliveryWindows'
  | 'smsNotification'
  | 'placeLink'
  | 'attachFiles'
  | 'radius'
  | 'location'
  | 'functions';

export default Vue.extend({
  name: 'ChangePoint',

  components: { ContactsInfo },

  props: {
    value: {
      type: Object as () => IChangePoint,
      required: true,
    },
  },

  methods: {
    formatChange(change: TripPointKeys, value: TripPointDto[keyof TripPointDto]): string {
      switch (change) {
        case 'title':
          return (value as TripPointDto['title']) || '';
        case 'comment':
          return (value as TripPointDto['comment']) || '';
        case 'stayTime':
          return this.$d(Number(value as StayTime), 'durationShort');
        case 'deliveryWindows':
          value = value as DeliveryWindowsDto[];
          return value && value.length
            ? this.$d(value[0].from as any, 'date') + ' — ' + this.$d(value[0].to as any, 'date')
            : '';
        case 'workingHours':
          value = value as WorkingHours[];
          return value && value.length ? value[0].from + ' — ' + value[0].to : '';
        case 'placeLink':
          return (value && (value as PlaceLink).title) || '';
        case 'location':
          return `${(value as PointLocation).coordinates.lat} ${(value as PointLocation).coordinates.lng}`;
        case 'radius':
          value = value as PointRadius;
          return `${this.$t('Checkin')}: ${value.checkin}, ${this.$t('Checkout')}: ${value.checkout}`;
        case 'functions':
          return (value as PointFunctionsDto)?.name || '';
        case 'smsNotification':
          return `${this.$t('Phone')} ${(value as SmsNotification).contact}`;
        default:
          return '';
      }
    },
  },
});
