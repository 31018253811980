import Uuid from '@/models/Uuid';
import uuid from 'uuid';
import { WorkingHours, PointRadius } from '@/models/TripPoint';
import { Contact } from '@/models/Contact';
import { LocationAddress, PlaceLocation } from './Location';

export interface PlaceLink {
  id?: Uuid;
  title: string | null;
  contacts: Contact[];
  location?: PlaceLocation;
  placeId?: string | null;
  placeForeignId?: string;
}

export interface PlaceTags {
  id: Uuid;
  foreignId: string;
  name: string;
  color: string;
}

export interface PlaceDto {
  comment: string | null;
  companyId: Uuid;
  contacts: Contact[];
  contractorId: string | null;
  createdAt: Date;
  foreignId: string | null;
  googlePlaceId: string | null;
  id: Uuid;
  location: PlaceLocation;
  radius: PointRadius;
  rating: number | null;
  tags: PlaceTags[] | null;
  title: string | null;
  updatedAt: Date;
  workingHours: WorkingHours[];
}

export class Place implements PlaceDto {
  comment: string | null;
  companyId: Uuid;
  contacts: Contact[];
  contractorId: string | null;
  createdAt: Date;
  foreignId: string | null;
  googlePlaceId: string | null;
  id: Uuid;
  location: PlaceLocation;
  radius: PointRadius;
  rating: number | null;
  tags: PlaceTags[] | null;
  title: string | null;
  updatedAt: Date;
  workingHours: WorkingHours[];

  constructor(data?: Partial<PlaceDto>) {
    if (!data) {
      data = {};
    }

    this.comment = data.comment !== undefined ? data.comment : null;
    this.companyId = data.id || uuid();
    this.contacts = data.contacts || [];
    this.contractorId = data.contractorId || null;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : new Date();
    this.foreignId = data.foreignId || null;
    this.googlePlaceId = data.googlePlaceId || null;
    this.id = data.id || uuid();
    this.location = data.location || {
      id: uuid(),
      googlePlaceId: null,
      coordinates: {
        lat: 0,
        lng: 0,
      },
      timezone: {
        name: '',
        offset: null,
      },
      addresses: [
        {
          locale: '',
          address: '',
          addressComponents: null,
        },
      ],
      arrivalDuration: 0,
      departureDuration: 0,
      transportRestrictions: [],
    };
    this.radius = data.radius || {
      checkin: 0,
      checkout: 0,
    };
    this.rating = data.rating || 0;
    this.tags = data.tags !== undefined ? data.tags : null;
    this.title = data.title !== undefined ? data.title : null;
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : new Date();
    this.workingHours =
      data.workingHours && data.workingHours[0].from && data.workingHours[0].to
        ? data.workingHours
        : [
            {
              from: '00:00',
              to: '00:00',
            },
          ];
  }

  getAddress(): string | null {
    return this.getLocationAddress()?.address || null;
  }

  getLocationAddress(): LocationAddress | null {
    if (!this.location.addresses.length) {
      return null;
    }

    return this.location.addresses[0];
  }
}
