import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Debounce } from 'typescript-debounce';

import api from '@/api/wayBuilder';
import { sleep } from '@/lib/util';
import { isEqual } from 'lodash';

import { getWayQuery } from '@/services/routeService';
import { reorderPointsByWay } from '@/services/tripService';
import Trip from '@/models/Trip';
import { RouteQuery, ActiveRoute } from '@/models/Way';
import WayPoint from '@/models/WayPoint';

// function _stripUuids(wayPoints) {
//   return wayPoints.map(w => {
//     const result = cloneDeep(w);
//     delete result.id;
//     return result;
//   });
// }

@Component
export default class WayBuilderMixin extends Vue {
  trip!: Trip;
  preventWayBuild: boolean = false;
  wayDirty: boolean = false;
  localBuildId: number = 0;
  wayError: Error | null = null;
  showWayLatenessDialog: boolean = false;
  wayLateness: boolean = false;
  loadingOptimization: boolean = false;
  runAnotherBuild: boolean = false;

  /**
   * Computes trips points list for route query
   * @return {Object[]} List of trip points
   */
  get wayQuery(): RouteQuery {
    return getWayQuery(this.trip);
  }

  get wayState(): string {
    if (this.wayError) {
      return 'error';
    } else if (this.trip.way.optimize && this.wayDirty) {
      return 'loadingOptimize';
    } else if (this.trip.way.optimize) {
      return 'optimize';
    } else if (this.wayDirty) {
      return 'loading';
    }
    return '';
  }

  startBuildWay(optimization = false) {
    this.wayDirty = true;
    this.wayError = null;
    this.wayLateness = false;
    this.localBuildId = Math.random();
    if (optimization) {
      this.loadingOptimization = true;
    }
    this.buildWay(optimization, this.localBuildId);
  }

  resetBuildWay() {
    this.wayDirty = false;
    this.wayLateness = false;
    this.localBuildId = 0;
    this.loadingOptimization = false;
  }

  @Debounce<any, (arg0: boolean, arg1: number) => void>({ millisecondsDelay: 1000 })
  buildWayDebounce(optimization = false, localBuildId: number): void {
    this.buildWay(optimization, localBuildId);
  }

  async buildWay(optimization = false, localBuildId: number): Promise<void> {
    if (this.wayQuery.tripPoints.length <= 1) {
      this.trip.way.activeWay = null;
      this.resetBuildWay();
      return;
    }

    try {
      this.wayDirty = true;
      const result = await api.buildWay({ ...this.wayQuery, optimization });
      if (localBuildId !== this.localBuildId) {
        return;
      }
      this.getBuiltWay(result.buildId, localBuildId, optimization);
    } catch (err) {
      if (localBuildId !== this.localBuildId) {
        return;
      }
      if (err.code === 'TRIP_POINTS_NOT_ENOUGH' || err.code === 'WAYPOINTS_NOT_ENOUGH') {
        this.trip.way.activeWay = null;
      } else {
        console.error(err);
        this.wayError = err;
      }
      this.handleWayError(err);
    }
  }

  async getBuiltWay(buildId: string, localBuildId: number, optimization: boolean) {
    try {
      let result;
      do {
        if (result) {
          await sleep(1000);
        }
        if (this.localBuildId !== localBuildId) {
          return;
        }
        result = await api.getBuild(buildId);
      } while (result.status === 202);

      if (this.localBuildId !== localBuildId) {
        return;
      }
      this.wayDirty = false;

      this.trip.way.activeWay = result.data.activeWay;

      this.trip.finishPlanAt = this.trip.way.activeWay?.summary?.finishAt
        ? new Date(this.trip.way.activeWay.summary.finishAt)
        : null;

      if (optimization) {
        this.reorderPoints(result.data.activeWay);
      }

      if (result.data.lateness) {
        this.wayLateness = true;
        if (optimization) {
          this.showWayLatenessDialog = true;
        }
      }
    } catch (err) {
      if (this.localBuildId !== localBuildId) {
        return;
      }
      console.error(err);
      this.handleWayError(err);
    } finally {
      this.loadingOptimization = false;
    }
  }

  handleWayError(err: any) {
    if (err.code === 'TRIP_POINTS_NOT_ENOUGH' || err.code === 'WAYPOINTS_NOT_ENOUGH') {
      this.trip.way.activeWay = null;
    } else {
      this.wayError = err;
    }
    if (this.trip.way.activeWay && this.trip.way.activeWay.summary) {
      this.trip.way.activeWay.summary.finishAt = null;
    }
    this.resetBuildWay();
  }

  updateWayPoints(wayPoints: WayPoint[]) {
    const newWayPoints = [...wayPoints];
    const visitedWayPoints = this.trip.wayPoints.filter(wayPoint => wayPoint.checkins && wayPoint.checkins.length);
    visitedWayPoints.reverse();

    visitedWayPoints.forEach(wayPoint => {
      newWayPoints.unshift(wayPoint);
    });

    this.trip.wayPoints = newWayPoints;
  }

  reorderPoints(activeWay: ActiveRoute) {
    this.preventWayBuild = true;
    this.trip.tripPoints = reorderPointsByWay(this.trip, activeWay);
  }

  closeWayLatenessDialog() {
    this.showWayLatenessDialog = false;
  }

  updated() {
    if (this.preventWayBuild) {
      this.preventWayBuild = false;
    }
  }

  @Watch('wayQuery')
  onWayQueryChange(newValue: RouteQuery, oldValue: RouteQuery) {
    if (this.preventWayBuild) {
      return;
    }

    if (!isEqual(newValue, oldValue)) {
      if (this.loadingOptimization) {
        this.runAnotherBuild = true;
      } else {
        this.startBuildWay();
      }
    }
  }

  @Watch('loadingOptimization')
  onLoadingOptimizationChange(newValue: boolean) {
    if (!newValue && this.runAnotherBuild) {
      this.runAnotherBuild = false;
      this.loadingOptimization = true;
      this.startBuildWay(true);
    }
  }
}
