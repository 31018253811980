import Vue, { PropType } from 'vue';
import { isSameDay } from 'date-fns';

import { isNil } from 'lodash';
import { convertDateWithTimeZone } from '../lib/date';
import { getLocalTimezoneInSeconds, getTimeZoneString } from '../lib/timezone';

export type DisplayStatus = 'marked' | 'actualFinish' | 'cancelled' | null | undefined;
export const POSSIBLE_STATUSES = ['marked', 'actualFinish', 'cancelled'] as DisplayStatus[];

export default Vue.extend({
  functional: true,

  name: 'TimeWithOptionalDay',

  props: {
    value: Date as PropType<Date>,
    showDate: Boolean as PropType<boolean | null>,
    showTimezones: Boolean as PropType<boolean | null>,
    inOneLine: Boolean,
    timezoneOffset: Number,
    status: String as PropType<DisplayStatus>,
    option: String,
    invalid: Boolean,
  },

  render(h, { props, parent }) {
    let { timezoneOffset, inOneLine, status, invalid, option } = props;
    const $t = parent.$t.bind(parent);
    const $d = parent.$d.bind(parent);

    const value = convertDateWithTimeZone(props.value, timezoneOffset);

    if (!value) {
      return h();
    }

    if (!POSSIBLE_STATUSES.includes(status)) {
      status = null;
    }

    const showTimezones =
      typeof props.showTimezones === 'boolean'
        ? props.showTimezones
        : !isNil(timezoneOffset) && props.timezoneOffset !== getLocalTimezoneInSeconds();

    const showDate = typeof props.showDate === 'boolean' ? props.showDate : !isSameDay(value, new Date());

    const statusInfo = status ? <span style="color: gray">{$t(`checkinStatuses.${status}`)}</span> : null;
    const timezoneInfo = showTimezones ? <span class="tz-font">{getTimeZoneString(timezoneOffset)}</span> : null;
    const optionInfo = option === 'tilde' ? <v-icon>mdi-tilde</v-icon> : null;

    if (!status && !showDate) {
      inOneLine = true;
    }

    const invalidClass = invalid ? 'red--text text--darken-3' : '';

    if (inOneLine) {
      return (
        <span class={invalidClass}>
          {statusInfo}{' '}
          <span class="time-font">
            {optionInfo} {showDate ? $d(value, 'short') : null} {$d(value, 'time')}{' '}
          </span>
          {timezoneInfo}
        </span>
      );
    }

    let timeDate = (
      <v-layout justify-center column text-xs-center class={invalidClass}>
        <v-flex class="time-font">
          {optionInfo}
          {$d(value, 'time')}
        </v-flex>
        {showDate && (
          <v-flex class="cell-subheader day-font" style={optionInfo ? { marginLeft: '19px' } : null}>
            {$d(value, 'short')}
          </v-flex>
        )}
      </v-layout>
    );

    if (showTimezones) {
      timeDate = (
        <v-layout row d-flex shrink class={invalidClass}>
          <v-flex shrink class="time-font">
            {timeDate}
          </v-flex>
          <v-flex shrink ml-1 class="tz-font">
            {timezoneInfo}
          </v-flex>
        </v-layout>
      );
    }

    if (status) {
      return (
        <v-layout justify-center column text-xs-center>
          <v-flex>{statusInfo}</v-flex>
          <v-flex style="margin: 0 auto">{timeDate}</v-flex>
        </v-layout>
      );
    } else {
      return timeDate;
    }
  },
});
