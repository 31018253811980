<template>
  <v-dialog :value="true" width="350">
    <v-card>
      <v-card-text>
        <ContactListEditor v-model="contacts" @input="l" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ContactListEditor from '@/components/inputs/ContactListEditor';

export default {
  components: {
    ContactListEditor,
  },

  data() {
    return {
      phones: [
        {
          key: 0,
          value: '',
        },
      ],

      contacts: [],
    };
  },

  methods: {
    l(data) {
      //console.log(data);
    },
  },
};
</script>
