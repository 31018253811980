
import Vue from 'vue';
import PointPlaceCreate from '@/components/trip/PointPlaceCreate.vue';
import place from '@/api/place';
import uuid from 'uuid/v4';
import { $t } from '@/i18n';
import { TripTableRow } from '@/services/TripPageService';
import { PlaceLink } from '@/models/Place';

export default Vue.extend({
  name: 'PointPlaceCreateDialog',

  components: { PointPlaceCreate },

  data() {
    return {
      error: null,
      showDialog: true,
      valid: true,
      internalValue: {
        placeLink: {
          title: null,
          location: undefined,
          contacts: [],
        } as PlaceLink,
        isFirstTimeSavePlace: null,
      },
    };
  },

  props: {
    value: TripTableRow,
  },

  methods: {
    hideModal(): void {
      this.$emit('close');
    },

    handleOk(): void {
      if (this.internalValue.isFirstTimeSavePlace) {
        this.addPlace();
      }
    },

    async addPlace(): Promise<void> {
      try {
        this.error = null;
        const internalValue = this.internalValue;

        const newPlaceId = uuid();
        internalValue.placeLink.placeId = newPlaceId;

        const mergedContacts = [
          ...(this.value.tripPoint?.placeLink?.contacts || []),
          ...(this.value.tripPoint?.contacts || []),
        ];

        await place.create({
          id: newPlaceId,
          title: internalValue.placeLink.title,
          contacts: mergedContacts,
          radius: {
            checkin: 0,
            checkout: 0,
          },
          location: {
            coordinates: {
              lat: this.value.tripPoint?.location.coordinates.lat,
              lng: this.value.tripPoint?.location.coordinates.lng,
            },
            addresses: [
              {
                address: this.value.tripPoint?.getAddress(),
                addressComponents: this.value.tripPoint?.location.addresses[0].addressComponents || null,
              },
            ],
          },
        });

        this.$emit('input', {
          ...this.value,
          placeLink: internalValue.placeLink,
        });
      } catch (err) {
        if (err.code === 'DUPLICATE_FAVORITE_NAME') {
          this.error = $t('error.DUPLICATE_FAVORITE_NAME');
        } else {
          this.error = err;
        }
      }
    },

    updateInternalValue(newValue: any): void {
      this.internalValue = newValue;
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
