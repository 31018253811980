import http, { pagingParams, Params } from './httpV2';
import Uuid from '@/models/Uuid';
import { Contractor, ContractorDto } from '@/models/Contractor';

const path = '/contractors';

export interface ContractorsResponse {
  result: Array<Contractor>;
  total: number;
}

interface ContractorsParams extends Params {
  idList?: Uuid[];
}

async function getContractors(opts: ContractorsParams): Promise<ContractorsResponse> {
  if (opts && opts.filter) {
    if (opts.filter.toHttpParams) {
      opts.filter = opts.filter.toHttpParams();
    }

    if (opts.idList) {
      opts.filter['filters[id][$in]'] = opts.idList;
      delete opts.idList;
    }
  }

  const res = await http.get(path, {
    params: pagingParams(opts),
  });

  res.data.result = res.data?.result.map((e: ContractorDto) => new Contractor(e));
  return res.data;
}

async function patchContractor(id: Uuid, data: any): Promise<any> {
  const res = await http.patch(`${path}/${id}`, data);
  return res.data;
}

async function deleteContractor(id: Uuid): Promise<any> {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function deleteContractors(data: Uuid[]): Promise<any> {
  const res = await http.delete(path, { data });
  return res.data;
}

async function createContractor(data: { name: string }): Promise<any> {
  const res = await http.post(path, data);
  return res.data;
}

export default {
  getContractors,
  patchContractor,
  deleteContractor,
  deleteContractors,
  createContractor,
};
