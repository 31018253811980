<template>
  <v-layout column :style="chatStyles" class="chat-container elevation-2">
    <MessageList
      @close="close"
      :messages="messages"
      :participants="[]"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="messageStyling"
    />
  </v-layout>
</template>

<script>
import Vue from 'vue';
import { isNil } from 'lodash';
import MessageList from '../../../vendor/vue-beautiful-chat/MessageList';

import chat from '@/api/chat';

import { getTimeZoneString } from '@/lib/timezone';
import { convertDateWithTimeZone } from '@/lib/date';

const FOOTER_HEIGHT = 75;

export default Vue.extend({
  name: 'SystemChat',

  components: { MessageList },

  props: {
    tripId: {
      type: Number,
      default: null,
    },
    showTimezone: {
      type: Boolean,
      default: false,
    },
    timezoneOffset: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      messageList: [],
      colors: {
        messageList: {
          bg: '#ffffff',
        },
        systemMessage: {
          bg: '#ffffff',
          text: '#ff3131',
        },
      },
      alwaysScrollToBottom: true,
      messageStyling: true,
    };
  },

  created() {
    this.fetchData();
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);

      this.timer = null;
    }
  },

  computed: {
    messages() {
      return this.messageList.map(systemMsg => {
        const log = systemMsg.data.log;
        const system = 'trip_system_events.';

        if (log.event === 'GPS_MODE' && log.mode === 'highAccuracy') {
          log.event = 'HIGH_ACCURACY_ON';
        } else if (log.event === 'GPS_MODE') {
          log.event = 'HIGH_ACCURACY_OFF';
        }

        const message = this.$t(system + log.event, {
          mode: this.$t(system + log.mode),
          perm: this.$t(system + log.perm),
          time:
            this.showTimezone && !isNil(this.timezoneOffset)
              ? this.$d(convertDateWithTimeZone(log.time, this.timezoneOffset), 'time') +
                getTimeZoneString(this.timezoneOffset)
              : this.$d(log.time, 'time'),
          timeDiff: log.data?.timeDiff
            ? this.$d(log.data.timeDiff / 1000, 'duration')
            : this.$t(system + 'TIME_DIFF_UNDEFINED'),
        });

        systemMsg.data.text = this.$t('system_message') + message;

        return {
          ...systemMsg,
          data: {
            ...systemMsg.data,
            meta:
              this.showTimezone && !isNil(this.timezoneOffset)
                ? this.$d(convertDateWithTimeZone(systemMsg.created_at, this.timezoneOffset), 'date') +
                  getTimeZoneString(this.timezoneOffset)
                : this.$d(systemMsg.created_at, 'date'),
          },
        };
      });
    },

    appPaddingLeft() {
      return this.$vuetify.application.left;
    },

    appPaddingTop() {
      return this.$vuetify.application.top;
    },

    chatWidth() {
      if (this.hide) {
        return '0px';
      }

      return !this.hide && this.full ? '100%' : `calc((100vw - ${this.appPaddingLeft + 8}px)*0.416666 - 24px)`;
    },

    chatHeight() {
      let height;

      if (this.$vuetify.breakpoint.smAndDown) {
        height = `calc(100% - ${+this.$root.$children[0].footerHeight}px - 50px)`;
      } else {
        height = this.height ? this.height + 'px' : `calc(100% - ${this.appPaddingTop + FOOTER_HEIGHT + 48 - 1}px)`;
      }

      return height;
    },

    chatStyles() {
      return {
        position: 'fixed',
        width: this.chatWidth,
        height: this.chatHeight,
        top: '0',
        marginTop: `${this.appPaddingTop + 24}px`,
        marginRight: '24px',
        marginBottom: '24px',
        overflow: 'hidden',
        zIndex: 2,
      };
    },
  },

  methods: {
    async fetchData() {
      const data = await chat.getSystem(this.tripId);

      this.messageList = data.messages;

      this.$emit('update', {
        newMessages: data.newMessages,
      });
    },

    close() {
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
@media (max-width: 960px) {
  .chat-container {
    position: fixed !important;
    margin: 0 !important;
    top: 50px !important;
    left: 0;
    width: 100% !important;
  }
}
</style>
