/* global google */

import MarkerGroup from '../primitives/MarkerGroup';
import MarkerBase from './MarkerBase';
import { IPointMarker } from './PointMarker';
import { MapPointData, MapGroupData } from '@/services/TripPageService';
import { PointCoordinates } from '@/models/Location';
import MapMarker from '../primitives/MapMarker';

export interface IGroupMarker extends IPointMarker {
  groupData: MapGroupData | null;
  addMarker(arg0: IPointMarker): void;
  removeMarker(arg0: IPointMarker): void;
}

/**
 * Component that acts as a facade for MarkerGroup object
 */
export default MarkerBase.extend({
  name: 'GroupMarker',

  data() {
    return {
      pointMarkers: [] as IPointMarker[],
      groupData: null as MapGroupData | null,
      points: [] as MapPointData[],
    };
  },

  computed: {
    position(): PointCoordinates | null {
      return this.groupData && this.groupData.coordinates;
    },

    pointMapMarkers(): MapMarker[] {
      return this.pointMarkers.filter(p => p.marker !== null).map(p => p.marker) as MapMarker[];
    },
  },

  methods: {
    async createMarker() {
      if (!this.markerData) {
        return;
      }

      if (!this.marker) {
        const map = await (this as any).getMap();
        (this as any).marker = new MarkerGroup(this.markerData); // FIXME WTF ANY
        this.marker.addListener('click', () => this.$emit('click', this));
        this.marker.setMap(map);
        // added here because adding marker from MapUpdateMarkersMixin calls earlier than gettin async map (too long after some refreshes, don't know why)
        this.pointMarkers.forEach(pointMarker => {
          if (pointMarker.marker) {
            this.marker.addMarker(pointMarker.marker);
          }
        });
      }
    },

    addMarker(pointMarker: IPointMarker) {
      if (!this.pointMarkers.includes(pointMarker)) {
        this.pointMarkers.push(pointMarker);

        if (pointMarker.point && !this.points.includes(pointMarker.point)) {
          this.points.push(pointMarker.point);
        }

        if (this.marker && pointMarker.marker) {
          this.marker.addMarker(pointMarker.marker);
        }
      }
    },

    removeMarker(pointMarker: IPointMarker) {
      this.pointMarkers.forEach(marker => {
        if (marker === pointMarker) {
          this.marker.removeMarker(marker.marker);
        }
      });
      this.points = this.points.filter(pointData => pointData.id !== pointMarker.point?.id);
      this.pointMarkers = this.pointMarkers.filter(marker => marker !== pointMarker);
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },

  watch: {
    pointMapMarkers(newMapMarkers: MapMarker[], oldMapMarkers: MapMarker[]) {
      newMapMarkers.forEach(marker => this.marker && this.marker.addMarker(marker));
    },
  },
});
