
import Vue from 'vue';
import { mapState } from 'vuex';
import store from '@/store';

import auth from '@/api/auth';
import restore from '@/api/restore';
import { REG_EXPS } from '@/api/rules';

export default Vue.extend({
  name: 'SpecifyDomain',

  data() {
    return {
      companyId: '',
      error: null as Error | null,
      restoreDone: false,
    };
  },

  props: {
    form: {
      type: Object,
    },
    isRestorePassword: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('company', ['loginCompanies']),

    succesText(): string {
      return REG_EXPS.email.test(this.form.data.login)
        ? (this.$t('common_strings.password_recovery_sent') as string)
        : (this.$t('common_strings.password_recovery_sent_phone') as string);
    },

    companies(): any {
      return this.loginCompanies.map((el: any) => ({
        text: el.title,
        value: el.id,
      }));
    },
  },

  methods: {
    async login(): Promise<void> {
      try {
        this.error = null;

        await auth.verify({
          login: this.form.data.login,
          password: this.form.data.password,
          companyId: this.companyId,
          isDebug: false,
        });

        this.$router.replace((this.$route.query.redirect as string) || '/');
        store.commit('company/setLoginCompanies', []);
      } catch (err) {
        this.error = err;
      }
    },

    async restore(): Promise<void> {
      try {
        this.restoreDone = false;
        this.error = null;

        await restore.create({ login: this.form.data.login, companyId: this.companyId });

        this.restoreDone = true;
      } catch (err) {
        this.error = err;
      }
    },
  },
});
