function findField(fields, name, cb) {
  for (const f of fields) {
    if (f.name === name) {
      if (cb) {
        cb(f);
      }
      return f;
    }
  }
  return null;
}

function getFields(obj, fields) {
  const res = {};
  for (const key of fields) {
    res[key] = obj[key];
  }
  return res;
}

function collectErrors(errors, field) {
  const errList = [];
  for (const err of errors) {
    if (err.dataPath === '.' + field) {
      errList.push(err.message);
    }
  }
  return errList;
}

function collectAllErrors(errors) {
  const errorMap = {};
  for (const [key, errorDetails] of Object.entries(errors)) {
    if (Array.isArray(errorDetails)) {
      errorMap[key] = errorDetails.map(individualError => individualError.message).join(',');
    }
  }
  return errorMap;
}

const apiError = {
  collect: (errors, ctx) => {
    const tmp = [];
    if (errors.data) {
      const localeMessages = ctx.$i18n.getLocaleMessage(ctx.$i18n.locale);

      for (const key of Object.keys(errors.data)) {
        if (Array.isArray(errors.data[key])) {
          for (const error of errors.data[key]) {
            if (error.message) {
              let message = error.message;

              if (localeMessages && localeMessages.error && localeMessages.error[message]) {
                message = ctx.$t(`error.${message}`);
              }

              tmp.push(message);
            }
          }
        }
      }
    }
    return tmp;
  },
};

export { findField, getFields, collectErrors, collectAllErrors, apiError };
