import httpV2 from './httpV2';
import store from '@/store';

const path = '/invites';

async function create(data) {
  const res = await httpV2.post(path, data);
  return res.data;
}

async function get(id) {
  const res = await httpV2.get(`${path}/${id}`);
  store.commit('company/setCompanyData', res.data.company);
  return res.data;
}

async function register(id, data) {
  const res = await httpV2.post(`${path}/${id}/register`, data);
  return res.data;
}

export default {
  create,
  get,
  register,
};
