<template>
  <v-tabs fixed icons centered v-model="active" dark color="cyan" slider-color="yellow">
    <!--
    <v-tab href="#card">
      <v-icon>credit_card</v-icon>
      {{ $t('Card') }}
    </v-tab>
    -->
    <v-tab href="#invoice">
      <v-icon>account_balance</v-icon>
      {{ $t('Invoice') }}
    </v-tab>
    <v-tabs-items touchless>
      <!--
      <v-tab-item :key="1" :id="'card'">
        <v-card>
          <v-card-text>
            <billing-card :card="card" @error="err => (errorCards = err)" :update="fetchCards" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      -->
      <v-tab-item :key="2" :id="'invoice'">
        <v-card>
          <v-card-text>
            <invoice
              v-if="invoiceEditable"
              :invoice="invoice"
              :invoice-can-close="invoiceCanClose"
              @update="updateInvoice"
              @close="invoiceEditable = false"
            />
            <div v-if="!invoiceEditable">
              <p style="color: #be5f5f">
                {{ $t('Before sending the invoice, please check your details: ') }}
              </p>
              <p>* {{ $t('mutual_settlements_between') }}.</p>
              <v-layout row wrap align-end class="text-xs-center" v-if="!createdInvoice">
                <v-flex xs12 sm6>
                  <btn-async
                    round
                    color="error"
                    @click="createInvoice"
                    :loading="createInvoiceLoading"
                    :disabled="createInvoiceLoading"
                  >
                    {{ $t('Create an invoice') }}
                  </btn-async>
                </v-flex>
                <v-flex xs12 sm6>
                  <btn-async round color="error" @click="editInvoice">
                    {{ $t('Edit details') }}
                  </btn-async>
                </v-flex>
              </v-layout>
              <v-layout v-if="createdInvoice" row wrap class="text-xs-center">
                <v-flex v-if="!sendEmailBtsnLocked" xs12 :class="buttonWidthClass">
                  <v-btn round color="error" @click="sendEmailInvoice">
                    {{ $t('Send to email') }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 :class="buttonWidthClass">
                  <v-btn round color="error" :href="invoiceUrl" download>
                    {{ $t('Download invoice') }}
                  </v-btn>
                </v-flex>
                <v-flex v-if="country === 'ru' && actUrl" xs12 :class="buttonWidthClass">
                  <v-btn round color="error" :href="actUrl" download>
                    {{ $t('Download act') }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 :class="buttonWidthClass">
                  <v-btn round color="error" @click="editInvoice">
                    {{ $t('Edit details') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
// import BillingCard from './BillingCard';
import Invoice from './Invoice';
import api from '@/api';
import { mapState } from 'vuex';

export default {
  components: {
    // BillingCard,
    Invoice,
  },
  props: {
    showPaidAction: {
      type: Function,
      required: true,
    },
    invoiceUrl: {
      type: String,
      default: '',
    },
    actUrl: {
      type: String,
      default: '',
    },
    changeInvoice: {
      type: Boolean,
      required: true,
    },
    sendEmailBtnLocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ex1: true,
      invoice: {},
      card: {},
      active: 'invoice',
      errorCards: null,
      invoiceEditable: false,
      createdInvoice: false,
      createInvoiceLoading: false,
      invoiceCanClose: false,
      invoiceDefaultPayment: false,
      cardDefaultPayment: false,
    };
  },
  computed: {
    ...mapState('company', ['country']),

    buttonWidthClass() {
      return this.country === 'ru' ? 'sm3' : 'sm4';
    },
  },
  created() {
    this.fetchCards();
    this.fetchInvoice();
  },
  methods: {
    async fetchCards() {
      const data = await api.billing.card.list();
      this.$store.commit('payment/setCardProlongation', data.card.auto_prolongation);
      data.card.id
        ? this.$store.commit('payment/setCardHasId', true)
        : this.$store.commit('payment/setCardHasId', false);
      this.card = data.card;
    },
    async fetchInvoice() {
      const data = await api.billing.invoice.get();
      this.invoice = data.invoice;
    },
    editInvoice() {
      this.invoiceEditable = true;
      this.invoiceCanClose = true;
    },
    updateInvoice(form) {
      this.invoiceEditable = false;
      this.createdInvoice = false;
      this.invoice = form;
    },
    sendEmailInvoice() {
      this.$emit('sendEmailInvoice');
    },
    createInvoice() {
      this.createInvoiceLoading = true;
      this.$emit('createInvoice');
    },
    changeInvoiceAutoProlongation(state) {
      this.$emit('changeAutoProlongationPaymentMethod', 'BANK', state);
      this.card.auto_prolongation = false;
    },
    changeCardAutoProlongation(state) {
      this.$emit('changeAutoProlongationPaymentMethod', 'CARD', state);
      this.invoice.auto_prolongation = false;
    },
  },
  watch: {
    active(val) {
      this.showPaidAction(val);
    },
    invoiceUrl(val) {
      if (val) {
        this.createInvoiceLoading = false;
        this.createdInvoice = true;
      }
    },
    invoice(val) {
      if (!val.bank_bic && this.country === 'ru') {
        this.invoiceEditable = true;
      }
      if (!val.vat_number && this.country !== 'ru') {
        this.invoiceEditable = true;
      }
    },
    changeInvoice(value) {
      if (value) {
        this.createdInvoice = false;
      }
    },
  },
};
</script>
