import Vue, { CreateElement, VNode } from 'vue';
import Component from 'vue-class-component';

@Component
export default class IncompleteList extends Vue {
  render(h: CreateElement): VNode {
    if (!Array.isArray(this.$slots.default) || !this.$slots.default.length) {
      return h();
    }

    const text = this.$slots.default.map((elem, idx) => {
      return idx < 2 && elem;
    });

    const toolText = h(
      'div',
      {
        style: {
          padding: '3px',
          minWidth: '80px',
        },
      },
      this.$slots.default,
    );

    const tooltip = h(
      'v-tooltip',
      {
        attrs: { bottom: true },
        scopedSlots: {
          activator: ({ on }) => {
            return h(
              'a',
              {
                on: on,
                style: { position: 'relative' },
              },
              [`+ ${this.$t('more')} ${this.$slots.default && this.$slots.default.length - 2}`],
            );
          },
        },
      },
      [toolText],
    );

    return h('div', [text, this.$slots.default.length > 2 && tooltip]);
  }
}
