<template>
  <v-flex>
    <div class="title mb-2">
      {{ $t('Shift parameters') }}
    </div>

    <div class="ml-4">
      <AddressInput
        :value="value"
        @input="value => $emit('input', value)"
        path="defaultShift.startLocation"
        :label="$t('Start address')"
        :readonly="readonly"
      />

      <v-layout>
        <v-text-field
          v-model.trim="startWorkingTime"
          :label="$t('Start time')"
          :error-messages="fieldErrors.startWorkingTime"
          :disabled="!(value.defaultShift && value.defaultShift.startLocation)"
          :readonly="readonly"
        />

        <TimeInput
          v-model="startArrivalDuration"
          :label="$t('Time at start')"
          :error-messages="fieldErrors.startArrivalDuration"
          :disabled="!(value.defaultShift && value.defaultShift.startLocation)"
          :readonly="readonly"
          manual-input
          duration
          class="ml-4 mt-0 pa-0"
        />
      </v-layout>

      <AddressInput
        :value="value"
        @input="value => $emit('input', value)"
        path="defaultShift.finishLocation"
        :label="$t('Finish address')"
        :readonly="readonly"
      />

      <v-layout>
        <v-text-field
          v-model.trim="finishWorkingTime"
          :label="$t('Finish time')"
          :error-messages="fieldErrors.finishWorkingTime"
          :disabled="!(value.defaultShift && value.defaultShift.finishLocation)"
          :readonly="readonly"
        />

        <TimeInput
          v-model="finishArrivalDuration"
          :label="$t('Time at finish')"
          :error-messages="fieldErrors.finishArrivalDuration"
          :disabled="!(value.defaultShift && value.defaultShift.finishLocation)"
          :readonly="readonly"
          manual-input
          duration
          class="ml-4 mt-0 pa-0"
        />
      </v-layout>

      <v-text-field
        v-model.trim="costPerShift"
        :label="$t('Cost per shift')"
        :error-messages="fieldErrors.costPerShift"
        :readonly="readonly"
      />

      <v-text-field
        v-model.trim="costPerUnit"
        :label="$t(`Cost per ${unit}`)"
        :error-messages="fieldErrors.costPerUnit"
        :readonly="readonly"
      />

      <v-text-field
        v-model.trim="maxStops"
        :label="$t(`Max shift stops`)"
        :error-messages="fieldErrors.maxStops"
        :readonly="readonly"
      />

      <TimeInput
        v-if="unit == 'min'"
        v-model="stageLength"
        :label="$t('Max shift time')"
        :error-messages="fieldErrors.stageLength"
        :disabled="readonly"
        manual-input
        duration
        style="width: auto"
      />

      <v-text-field
        v-else
        v-model.trim="stageLength"
        :label="$t(`Max shift length`)"
        :error-messages="fieldErrors.stageLength"
        :readonly="readonly"
      />
    </div>
  </v-flex>
</template>

<script>
import Vue from 'vue';
import { get, set, cloneDeep } from 'lodash';
import TimeInput from '@/components/inputs/TimeInput.vue';
import AddressInput from '@/components/inputs/AddressInput.vue';

function makeArrivalDurationParameter(path) {
  return {
    get() {
      return get(this.value, `${path}.arrivalDuration`);
    },

    set(value) {
      const address = get(this.value, `${path}.addresses[0].address`);

      if (address) {
        this.$emit(
          'input',
          set(cloneDeep(this.value) || {}, `${path}.arrivalDuration`, !isNaN(value) ? Number(value) : 0),
        );
      }
    },
  };
}

function makeWorkingTimeParameter(path) {
  return {
    get() {
      return get(this.value, path, '00:00:00');
    },

    set(value) {
      this.$emit('input', set(cloneDeep(this.value) || {}, path, value || '00:00:00'));
    },
  };
}

function makeNumericParameter(path, defaultValue = 0, convertTo = v => v, convertFrom = v => v) {
  return {
    get() {
      if (typeof defaultValue === 'function') {
        defaultValue = defaultValue.call(this);
      }

      return convertFrom.call(this, get(this.value, path, defaultValue));
    },

    set(value) {
      this.$emit(
        'input',
        set(cloneDeep(this.value) || {}, path, convertTo.call(this, !isNaN(value) ? Number(value) : 0)),
      );
    },
  };
}

function makeArrayParameter(path) {
  return {
    get() {
      return get(this.value, path, []).join(',');
    },

    set(value) {
      this.$emit(
        'input',
        set(
          cloneDeep(this.value),
          path,
          value.split(',').map(s => s.trim()),
        ),
      );
    },
  };
}

const MAX_STAGE_TIME = 23 * 60 * 60 + 59 * 60;

export default Vue.extend({
  name: 'DefaultShiftParameters',

  components: { TimeInput, AddressInput },

  props: {
    value: Object,
    unit: String, // "km" or "min"
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fieldErrors() {
      return {};
    },

    startArrivalDuration: makeArrivalDurationParameter('defaultShift.startLocation'),

    startWorkingTime: makeWorkingTimeParameter('defaultShift.workingTime.from'),

    finishArrivalDuration: makeArrivalDurationParameter('defaultShift.finishLocation'),

    finishWorkingTime: makeWorkingTimeParameter('defaultShift.workingTime.to'),

    costPerShift: makeNumericParameter('defaultShift.tariff.costPerShift'),

    // When unit is km — we show it as km and store as meters
    costPerUnit: makeNumericParameter(
      'defaultShift.tariff.constraints[0].costPerUnit',
      function() {
        return 0;
      },
      function(v) {
        return this.unit === 'km' ? v / 1000 : v / 60;
      },
      function(v) {
        return this.unit === 'km' ? v * 1000 : v * 60;
      },
    ),

    stageLength: makeNumericParameter(
      'defaultShift.tariff.constraints[0].stageLength',
      function() {
        return 0;
      },
      function(v) {
        return this.unit === 'km' ? v * 1000 : v > MAX_STAGE_TIME ? MAX_STAGE_TIME : v;
      },
      function(v) {
        return this.unit === 'km' ? v / 1000 : v > MAX_STAGE_TIME ? MAX_STAGE_TIME : v;
      },
    ),

    maxStops: makeNumericParameter(
      'defaultShift.maxStops',
      0,
      function(v) {
        return v > 0 ? v : null;
      },
      function(v) {
        return v ? v : 0;
      },
    ),
  },
});
</script>
