
import Vue from 'vue';
import { PointIcon, PointFunctionsIcon } from '@/components/icons';
import { TripTableRow } from '@/services/TripPageService';

export default Vue.extend<any, any, any, any>({
  inject: ['getComputeColor'],

  components: {
    PointIcon,
    PointFunctionsIcon,
  },

  props: {
    item: {
      type: TripTableRow,
    },
    getComputeColor: {
      type: Function,
    },
  },

  computed: {
    computeColor(): string {
      return this.getComputeColor(this.item.status);
    },
  },
});
