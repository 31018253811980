
import Vue from 'vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Grid from '@/components/grid/GridCrud';
import { FieldsOptions } from '@/components/grid/Options';
import { analyzeUnplanned } from '@/api/distributions';
import CustomHorizontalList from '@/components/list/CustomHorizontalList.tsx';

export default Vue.extend({
  name: 'UnplannedOrdersAnalytics',

  components: {
    Breadcrumbs,
    Grid,
    CustomHorizontalList,
  },

  data() {
    return {
      apiFunction: () => analyzeUnplanned(this.$route.params.id),
    };
  },

  computed: {
    breadcrumbs(this: any): object[] {
      return [
        {
          text: this.$t('Planning'),
          href: { name: 'distributionList' },
        },
        {
          text: this.$t('Planning'),
          href: { name: 'distributionInfo', params: { id: this.$route.params.id } },
        },
        {
          text: this.$t('Analytics'),
          href: '',
          bold: true,
        },
      ];
    },

    fields(): FieldsOptions {
      return {
        essence: {
          label: 'Entities',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        error: {
          label: 'Error',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
      };
    },
  },
});
