<template functional>
  <v-layout
    v-if="$options.methods.getContacts(props) && $options.methods.getContacts(props).length"
    v-bind="data.attrs"
  >
    <v-flex shrink mr-4 v-for="contact in $options.methods.getContacts(props)" :key="contact.id">
      <v-layout row>
        <v-flex v-if="props.withIcon" shrink>
          <v-icon small>
            phone
          </v-icon>
        </v-flex>
        <v-flex shrink>
          <div v-if="contact.name">
            {{ contact.name }}
          </div>
          <div v-if="contact.jobTitle">
            <i>{{ contact.jobTitle }}</i>
          </div>
          <template v-if="contact.note && parent.$t">
            <div>{{ parent.$t('Note') }}: {{ contact.note }}</div>
          </template>
          <template v-if="contact.phones.length">
            <div v-for="phoneNumber in contact.phones" :key="phoneNumber.id">
              <a :href="'tel:' + phoneNumber.phone">{{ phoneNumber.phone }}</a>
            </div>
          </template>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getContacts(props) {
      let result = [];

      if (props && props.value && Array.isArray(props.value)) {
        result = props.value;
      }

      return result;
    },
  },
};
</script>
