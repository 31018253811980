
import Vue from 'vue';
import Uuid from '@/models/Uuid';
import Trip from '@/models/Trip';
import Grid from '../grid/Grid';
import { GridTh } from '@/components/grid/Grid';
import GridSortable from '@/components/grid/GridSortable';
import PointEditRow from './PointEditRow.vue';
import { TripTableRow } from '@/services/TripPageService';
import TripPoint from '@/models/TripPoint';

export default Vue.extend({
  name: 'TripPointsEditGrid',

  props: {
    trip: Trip,
    items: Array,
  },

  components: {
    Grid,
    GridTh,
    PointEditRow,
  },

  directives: {
    GridSortable,
  },

  computed: {
    haveDeliveryWindows(): boolean {
      if (this.trip.tripPoints.some(tripPoint => tripPoint.deliveryWindows.length)) {
        return true;
      }
      return false;
    },

    haveOrders(): boolean {
      return this.trip.tripPoints.some(tripPoint => tripPoint.orders && tripPoint.orders.length);
    },

    havePointFunctions(): boolean {
      return this.trip.tripPoints.some(
        tripPoint => tripPoint.functions?.checklist?.length || tripPoint.functions?.droplist?.length,
      );
    },

    fields(): object {
      return {
        number: {
          label: '№',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        type: {
          label: 'Point Status',
          align: 'center',
          sortable: false,
          style: {
            width: '40px',
            whiteSpace: 'normal',
            paddingRight: '10px',
            paddingLeft: '10px',
          },
        },
        address: {
          label: 'Title and address',
          nowrap: false,
          sortable: false,
          align: 'center',
          style: {
            minWidth: '350px',
            paddingLeft: '10px',
            paddingRight: '10px',
          },
        },
        ...(this.haveOrders
          ? {
              orderNumber: {
                label: 'Order number',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        ...(this.havePointFunctions
          ? {
              pointFunctions: {
                label: 'Point functions',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        ...(this.trip.tripPoints.some(tripPoint => tripPoint.deliveryWindows.length)
          ? {
              deliveryWindows: {
                label: 'Delivery windows',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        arrivalPlanAt: {
          label: 'ETA',
          sortable: false,
          class: 'text-no-wrap',
        },
        stayTime: {
          label: 'At client',
          sortable: false,
          align: 'center',
          class: 'col-narrow',
          style: {
            width: '100px',
          },
        },
        actions: {
          class: 'col-narrow',
          sortable: false,
          style: {
            width: '124px',
          },
        },
      };
    },
  },

  methods: {
    makeFinish(id: Uuid) {
      this.$emit('makeFinish', id);
    },

    makeClient(id: Uuid) {
      this.$emit('makeClient', id);
    },

    deletePoint(id: Uuid) {
      this.$emit('deletePoint', id);
    },

    undeletePoint(id: Uuid) {
      this.$emit('undeletePoint', id);
    },

    checkMove(oldIndex: number, newIndex: number) {
      const oldRow = this.items[oldIndex] as TripTableRow;
      const newRow = this.items[newIndex] as TripTableRow;

      return oldRow.canMove && newRow.canMove;
    },

    dragReorder({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) {
      if (!this.checkMove(oldIndex, newIndex)) {
        return false;
      }

      if (Array.isArray(this.items) && this.items.length) {
        const oldPointId = this.items[oldIndex]?.tripPoint?.id;
        const newPointId = this.items[newIndex]?.tripPoint?.id;

        if (oldPointId && newPointId) {
          const oldPointIndex = this.trip.tripPoints.findIndex(tripPoint => tripPoint.id === oldPointId);
          const newPointIndex = this.trip.tripPoints.findIndex(tripPoint => tripPoint.id === newPointId);

          const movedPoint = this.trip.tripPoints.splice(oldPointIndex, 1)[0];
          this.trip.tripPoints.splice(newPointIndex, 0, movedPoint);
        }
      }
    },

    openPointSettingsDialog(tableRow: TripTableRow) {
      this.$emit('openPointSettingsDialog', tableRow);
    },

    openPointPlaceCreateDialog(tableRow: TripTableRow) {
      this.$emit('openPointPlaceCreateDialog', tableRow);
    },
  },
});
