
import Vue, { VNode } from 'vue';
import ResourceImport from './ResourceImport.vue';
import { importTransports } from '@/api/import';

export default Vue.extend({
  functional: true,

  render(h, { parent, listeners }): VNode {
    return h(ResourceImport, {
      props: {
        title: parent.$t('Transports import'),
        url: '/transports',
        api: importTransports,
      },
      on: listeners,
    });
  },
});
