/* global google */
import SingleMarker, { ISingleMarker } from './SingleMarker';
import { TripPointType } from '@/models/TripPoint';
import { PointCoordinates } from '@/models/Location';
import StartMarkerInfo from './info/StartMarkerInfo';
import ScheduledMarkerInfo from './info/ScheduledMarkerInfo';
import NotScheduledMarkerInfo from './info/NotScheduledMarkerInfo';
import FinishMarkerInfo from './info/FinishMarkerInfo';
import { MapPointData } from '@/services/TripPageService';
import MapMarker, { MapMarkerOptions } from '../primitives/MapMarker';
import { ITripMap } from './info/lib';

const POINT_CLASSES = {
  [TripPointType.start]: StartMarkerInfo,
  [TripPointType.scheduled]: ScheduledMarkerInfo,
  [TripPointType.notScheduled]: NotScheduledMarkerInfo,
  [TripPointType.finish]: FinishMarkerInfo,
  [TripPointType.actualFinish]: FinishMarkerInfo,
};

export interface IPointMarker extends ISingleMarker {
  marker: MapMarker | null;
  point: MapPointData | null;

  setMap(arg0: google.maps.Map): void;
  removeMarker(arg0: IPointMarker): void;
}

/**
 * Component that acts as a facade for MapMarker object
 * Computes icon/label for point and passes changes in computed data to marker
 */
export default SingleMarker.extend({
  render(h) {
    return h();
  },

  name: 'PointMarker',

  data() {
    return {
      point: null as MapPointData | null,
    };
  },

  computed: {
    isActive(): boolean {
      if (this.point && this.point.wayPointId) {
        const wayPointId = this.point.wayPointId;
        const pointId = this.point.id;

        if (
          (this.currentSelectRow && pointId === this.currentSelectRow) ||
          (this.tableRowSelectId && this.tableRowSelectId === wayPointId)
        ) {
          if (this.marker && (this.marker as any).active && !this.pointSelectId) {
            this.$store.commit('map/toggleSelection', {
              marker: { marker: this.marker },
              point: this.point,
              currentSelectRow: this.currentSelectRow,
            });
          }

          return true;
        } else if (
          !this.currentSelectRow &&
          (wayPointId === this.pointSelectId || wayPointId === this.tableRowSelectId)
        ) {
          return true;
        }
      }

      return false;
    },

    markerData(): MapMarkerOptions | null {
      if (!this.point || !this.point.tripPoint || !this.position) {
        return null;
      }

      const func = POINT_CLASSES[this.point.tripPoint.type];

      if (func) {
        return {
          ...func(this.point, (this.$parent as unknown) as ITripMap),
          position: this.position,
        };
      }
      return null;
    },

    position(): PointCoordinates | null {
      return this.point && this.point.tripPoint && this.point.tripPoint.getCoordinates();
    },
  },
});
