import Uuid from '@/models/Uuid';
import { PointRadius, WorkingHours, SmsNotification, DeliveryWindows, Checkin } from '@/models/TripPoint';
import { PointFunctions, PointFunctionsDto } from '@/models/PointFunctions';
import { ActiveRoute } from '@/models/Way';
import { PlaceLink } from '@/models/Place';
import { TripSettings } from '@/models/Trip';
import { AddressComponent, PointLocation } from '@/models/Location';
import { Contact } from '@/models/Contact';

export enum TripChangeType {
  pointAdd = 'pointAdd',
  pointDelete = 'pointDelete',
  pointChange = 'pointChange',
  pointsReorder = 'pointsReorder',
  updateResponsible = 'updateResponsible',
  updateExecutor = 'updateExecutor',
  updateTransport = 'updateTransport',
  tripCancel = 'tripCancel',
  tripFinish = 'tripFinish',
  tripChange = 'tripChange',
}

export enum TripChangeStatus {
  pending = 'pending',
  pendingSent = 'pendingSent',
  accepted = 'accepted',
  acceptedViewed = 'acceptedViewed',
  declined = 'declined',
  canceled = 'canceled',
}

export type ArrivalAts = { id: Uuid; arrivalAt: string }[];

interface TripPointAddDto {
  external_id: Uuid;
  type: number;
  name: string | null;
  address: string;
  address_components: AddressComponent[];
  arrival_plan_at?: string;
  contacts: Contact[];
  latitude: number;
  longitude: number;
  radius: PointRadius;
  place_id: string | null;
  place_external_id: Uuid | null;
  place_contacts: Contact[];
  place_name: string | null;
  working_hours: WorkingHours[];
  delivery_windows: DeliveryWindows[];
  sms_notification: SmsNotification | null;
  functions: PointFunctionsDto | null;
  comment: string | null;
  stay_time: number | null;
}

interface TripPointDeleteDto {
  external_id: Uuid;
  arrivalAts: ArrivalAts;
}

interface UpdateSmsDto {
  external_id: Uuid;
  sms_notification: SmsNotification | null;
}

interface PointChangeDto {
  external_id: Uuid;
  title?: string | null;
  comment?: string | null;
  contacts?: Contact[];
  location?: PointLocation;
  stayTime?: number | null;
  deliveryWindows?: DeliveryWindows[];
  smsNotification?: SmsNotification | null;
  functions?: PointFunctions;
  placeLink?: PlaceLink;
  checkins?: Checkin[];
  status?: string;
}

interface PointsReorderDto {
  tripPointIds: Uuid[];
  arrivalAts: ArrivalAts;
}

interface UpdateResponsibleDto {
  responsibleId: Uuid | null;
}

interface UpdateExecutorDto {
  executorId: Uuid | null;
}

interface UpdateTransportDto {
  transportId: Uuid | null;
}

interface TripChangeDto {
  settings?: TripSettings;
}

interface UpdateWayDto {
  way: ActiveRoute | null;
}

type TripChange =
  | {
      external_id: Uuid;
      type: 'pointAdd';
      info: TripPointAddDto;
    }
  | {
      external_id: Uuid;
      type: 'pointDelete';
      info: TripPointDeleteDto;
    }
  | {
      external_id: Uuid;
      type: 'updateSms';
      info: UpdateSmsDto;
    }
  | {
      external_id: Uuid;
      type: 'pointChange';
      info: PointChangeDto;
      onAllSide?: boolean; // нужно для того, чтобы создать change и сразу применить его, не дожидаясь синка с мобилкой
    }
  | {
      external_id: Uuid;
      type: 'pointsReorder';
      info: PointsReorderDto;
    }
  | {
      external_id: Uuid;
      type: 'updateResponsible';
      info: UpdateResponsibleDto;
    }
  | {
      external_id: Uuid;
      type: 'updateExecutor';
      info: UpdateExecutorDto;
    }
  | {
      external_id: Uuid;
      type: 'updateTransport';
      info: UpdateTransportDto;
    }
  | {
      external_id: Uuid;
      type: 'tripCancel';
    }
  | {
      external_id: Uuid;
      type: 'tripFinish';
    }
  | {
      external_id: Uuid;
      type: 'updateWay';
      info: UpdateWayDto;
    }
  | {
      external_id: Uuid;
      type: 'tripChange';
      info: TripChangeDto;
    };

export default TripChange;
