<template>
  <v-text-field
    v-model.trim="array"
    :label="label"
    :error-messages="errorMessages"
    :rules="rules"
    @keydown.prevent.enter="preventEnter"
    :autocomplete="autocomplete"
    :prepend-icon="icon"
    :readonly="readonly"
  />
</template>

<script>
import Vue from 'vue';
import { get, set, unset, cloneDeep } from 'lodash';

export default Vue.extend({
  name: 'ArrayParameterInput',

  props: {
    value: Object,
    path: {
      type: String,
      default: 'location',
    },
    label: String,
    errorMessages: Object,
    rules: Array,
    preventEnter: {
      type: Function,
      default: () => {},
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    array: {
      get() {
        return (get(this.value, this.path, []) ?? []).join(',');
      },
      set(value) {
        this.$emit('input', set(cloneDeep(this.value), this.path, value ? value.split(',').map(s => s.trim()) : []));
      },
    },
  },
});
</script>
