import { ITripMap } from './lib';
import { getIconSettings, MarkerIconName } from '../../config';
import { MapPointData } from '@/services/TripPageService';
import { MapMarkerOptions } from '../../primitives/MapMarker';

export default function(point: MapPointData, parent: ITripMap): Omit<MapMarkerOptions, 'position'> {
  const icon: MarkerIconName = 'active';

  const { tripPoint, isCurrent } = point;
  const { secondLabelInfoMap } = parent;

  let text = '';

  if (Array.isArray(tripPoint.demands) && tripPoint.demands.length) {
    const demandTitle =
      tripPoint.demands.length > 1
        ? parent.$t('ShareTrip.tooltips.OrderNumbers')
        : parent.$t('ShareTrip.tooltips.OrderNumber');

    if (tripPoint.demands.length > 1) {
      let textDemands = '';

      tripPoint.demands.forEach(demand => {
        textDemands += `<span>${demand.order.number}</span></br>`;
      });

      text += `<tr><th>${demandTitle}</th><td>${textDemands}</td></tr>`;
    } else {
      text += `<tr><th>${demandTitle}</th><td>${tripPoint.demands[0].order.number}</td></tr>`;
    }
  }

  const arrivalTitle = parent.$t('ShareTrip.tooltips.ArrivalPlan');

  const arrivalDate = parent.formatTimeAndDateOneLine(
    tripPoint.arrivalPlanAt ? tripPoint.arrivalPlanAt : tripPoint.arrivalPredictAt,
    false,
    point.showTimezone,
    point.timezoneOffset,
  );

  text += `<tr><th>${arrivalTitle}</th><td>${arrivalDate}</td></tr>`;

  if (tripPoint?.location?.addresses[0]) {
    const addressTitle = parent.$t('ShareTrip.tooltips.Address');

    text += `<tr><th>${addressTitle}</th><td>${tripPoint?.location?.addresses[0].address}</td></tr>`;
  }

  if (Array.isArray(tripPoint.deliveryWindows) && tripPoint.deliveryWindows.length) {
    const deliveryWindowTitle = parent.$t('ShareTrip.tooltips.TimeWindow');

    const deliveryWindow = tripPoint.deliveryWindows[0];
    const fromDate = parent.formatTimeAndDateOneLine(new Date(deliveryWindow.from));
    const toDate = parent.formatTimeAndDateOneLine(new Date(deliveryWindow.to));

    text += `<tr><th>${deliveryWindowTitle}</th><td>${fromDate} - ${toDate}</td></tr>`;
  }

  if (tripPoint.transport) {
    const transportTitle = parent.$t('ShareTrip.tooltips.TransportNumber');

    text += `<tr><th>${transportTitle}</th><td>${tripPoint.transport.number}</td></tr>`;
  }

  if (tripPoint.activePointsCount) {
    const transportTitle = parent.$t('ShareTrip.tooltips.ActivePointsCount');

    text += `<tr><th>${transportTitle}</th><td>${tripPoint.activePointsCount}</td></tr>`;
  }

  if (tripPoint.activePointsStayTime) {
    const transportTitle = parent.$t('ShareTrip.tooltips.ActivePointsStayTime');

    text += `<tr><th>${transportTitle}</th><td>${parent.$duration(
      tripPoint.activePointsStayTime,
      'duration',
    )}</td></tr>`;
  }

  const iconData = getIconSettings(icon);

  const result = {
    icon: iconData,
    label: { text: '' },
    secondLabel:
      text && secondLabelInfoMap
        ? {
            html: `<table class='share-table-info'>${text}</table>`,
            origin: iconData.secondLabelOrigin,
          }
        : undefined,
    downLabel: undefined,
    isCurrent,
    isNext: point.isNext,
    zindex: point.zindex,
    draggable: parent.$store.state.map.draggable,
  };

  return result;
}
