
import Vue from 'vue';
import { TripPointStatus } from '@/models/TripPoint';

export default Vue.extend({
  name: 'PointStatusPicker',

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Point Status',
    },
    disabled: {
      type: Boolean,
    },
  },

  computed: {
    statuses() {
      const statusList = Object.keys(TripPointStatus).filter(s => !['waitingForAdd', 'waitingForDelete'].includes(s));

      return statusList.map(s => ({
        text: this.$t(`standartPointStatuses.${s}`),
        value: s,
      }));
    },
  },

  methods: {
    selectStatus(value: Array<any>) {
      this.$emit('input', value);
    },
  },
});
