<script>
import { mapState } from 'vuex';
import Overlayable from 'vuetify/es5/mixins/overlayable';
import { ONBOARDING_ELEMENT_ZINDEX } from '@/const';

export default {
  name: 'OnboardingFixedOverlay',
  mixins: [Overlayable],

  props: {
    transparent: { type: Boolean, default: false },
    makeOverlay: { type: Boolean, default: true },
    overlayClass: { type: String, default: null },
    activeClass: { type: String, default: null },
    zIndex: { type: Number, default: ONBOARDING_ELEMENT_ZINDEX },
  },

  data() {
    return {
      activeZIndex: this.zIndex,
      isActive: false,
      absolute: true,
      parentEl: null,
      saveZIndex: null,
    };
  },

  computed: {
    ...mapState('site', ['firstTimeGuide']),
    ...mapState('site', {
      value: 'showHelp',
    }),
  },

  mounted() {
    if (this.value) {
      this.isActive = true;
      this.show();
    } else {
      this.isActive = false;
      this.removeOverlay();
    }
  },

  watch: {
    value() {
      if (this.value) {
        this.isActive = true;
        this.show();
      } else {
        this.isActive = false;
        this.hide();
      }
    },
  },

  methods: {
    show() {
      if (this.makeOverlay) {
        this.genOverlay();
        if (this.overlayClass) {
          this.overlay.classList.add(this.overlayClass);
        }

        if (this.transparent) {
          this.overlay.classList.add('onboarding--overlay--transparent');
        }

        if (!this.firstTimeGuide) {
          this.overlay.addEventListener('click', () => {
            this.$store.commit('site/hideHelp');
          });
        }
      }

      this.parentEl = this.$parent.$el;
      this.saveZIndex = this.parentEl.style.zIndex;
      this.parentEl.style.zIndex = `${this.zIndex}`;
    },

    hide() {
      this.removeOverlay();
      this.parentEl.style.zIndex = this.saveZIndex;
    },
  },

  render() {
    return <div class={this.activeClass}></div>;
  },
};
</script>
