
import Vue from 'vue';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

import { baseUrl } from '@/api/httpV2';
import { getDistribution, acceptDistribution } from '@/api/distributions';

import { FieldsOptions } from '@/components/grid/Options';
import Grid, { GridTh, GridSelectAll } from '@/components/grid/Grid';
import IncompleteList from '@/components/list/IncompleteList.tsx';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DateWithTimezone from '@/components/DateWithTimezone.vue';
import { TRANSPORT_ICONS } from '@/components/pickers/TransportTypePicker.vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import DistributionErrors from '@/components/distribution/DistributionErrors.vue';
import TagComponent from '@/components/Tag';

import { TripStatus, TripInstanceDto } from '@/models/Trip';
import { Order, Tag } from '@/models/Order';
import { Distribution } from '@/models/Distribution';
import { Contractor } from '@/models/Contractor';

export default Vue.extend({
  name: 'DistributionInfo',

  components: {
    Grid,
    IncompleteList,
    Breadcrumbs,
    DateWithTimezone,
    Checkbox,
    GridTh,
    GridSelectAll,
    DistributionErrors,
    Tag: TagComponent,
  },

  data() {
    return {
      data: null as Distribution | null,
      error: null,
      acceptLoading: false,

      gridPagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: null,
        descending: false,
        totalItems: 0,
      },

      unplannedPagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: null,
        descending: false,
        totalItems: 0,
      },

      unassignedOrdersPagination: {
        page: 1,
        rowsPerPage: 25,
        sortBy: null,
        descending: false,
        totalItems: 0,
      },

      selectAllLoading: false,
      selected: [] as string[],
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('tags', ['tags']),

    breadcrumbs(this: any): object[] {
      return [
        {
          text: this.$t('LotPlanning'),
          href: { name: 'distributionList' },
        },
        {
          text: this.$t('Planning'),
          href: '',
          disabled: true,
        },
      ];
    },

    fields(): FieldsOptions {
      return {
        ordersCount: {
          label: 'OrdersCount',
          align: 'center',
          sortable: false,
          defaultSort: 1,
          style: {
            maxHeight: '200px',
          },
        },
        tripsCount: {
          label: 'TripsCount',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        totalMass: {
          label: 'Total mass',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        totalVolume: {
          label: 'Total volume',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        averageStops: {
          label: 'Average stops',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        totalDistance: {
          label: 'Total distance',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
      };
    },

    tripFields(): FieldsOptions {
      return {
        number: {
          label: '№',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        executor: {
          label: 'Executor',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        transport: {
          label: 'Transport',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        type: {
          label: 'Type',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        timeWindowFrom: {
          label: 'Working time from',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        timeWindowTo: {
          label: 'Working time to',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        distance: {
          label: 'Distance',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        ordersCount: {
          label: 'OrdersCount',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        ordersNumbers: {
          label: 'OrderNumbers',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        contractor: {
          label: 'Contractors',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        stopsCount: {
          label: 'StopsCount',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        maxMass: {
          label: 'Total mass',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        maxVolume: {
          label: 'Total volume',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
      };
    },

    unplannedOrderFields(): FieldsOptions {
      return {
        number: {
          label: '№',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        address: {
          label: 'Address',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        mass: {
          label: 'Mass',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        volume: {
          label: 'Volume',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        restrictions: {
          label: 'Restrictions',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
      };
    },

    unassignedOrdersFields(): FieldsOptions {
      return {
        number: {
          label: '№',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        address: {
          label: 'Address',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        mass: {
          label: 'Mass',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        volume: {
          label: 'Volume',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        restrictions: {
          label: 'Restrictions',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
      };
    },

    exportLink(this: any): string {
      return baseUrl + '/distributions/' + this.$route.params.id + '/export?locale=' + this.$i18n.locale;
    },

    assumptions(this: any): string[] {
      const result = [];

      if (this.data.settings.assumptions) {
        for (const [key, value] of Object.entries(this.data.settings.assumptions)) {
          if (value) {
            result.push(key);
          }
        }
      }

      return result;
    },

    replanStrategy(this: any): string[] {
      const result = [];

      if (this.data.settings.replanStrategy) {
        for (const [key, value] of Object.entries(this.data.settings.replanStrategy)) {
          if (value) {
            result.push(key);
          }
        }
      }

      return result;
    },

    canAccept(this: any): boolean {
      return (
        this.hasPermission('distributions accept') &&
        this.data &&
        ['finishedInTime', 'finishedOutOfTime'].includes(this.data.status)
      );
    },

    tripStatusesById() {
      const mapData = new Map();

      if (Array.isArray(this.data.trips)) {
        this.data.trips.forEach(trip => mapData.set(trip.id, trip.status));
      }

      return mapData;
    },
  },

  async created(this: any) {
    await this.fetchData();
    await this.updateTags();
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),
    ...mapActions('tags', ['updateTags']),

    async fetchData(this: any): Promise<void> {
      try {
        this.data = await getDistribution(this.$route.params.id);

        if (this.data.trips) {
          this.$nextTick(() => {
            this.data.trips.forEach((trip: TripInstanceDto) => {
              this.selected.push(trip.id);
            });
          });
        }

        if (this.data.unplannedOrders) {
          this.unplannedPagination.totalItems = this.data.unplannedOrders.length;
        }

        if (this.data.unassignedOrders) {
          this.unassignedOrdersPagination.totalItems = this.data.unassignedOrders.length;
        }
      } catch (error) {
        this.error = error;
      }
    },

    async acceptDistribution(this: any): Promise<void> {
      try {
        this.acceptLoading = true;
        this.error = null;

        await acceptDistribution(this.$route.params.id, { tripIds: this.selected });
        await this.fetchData();

        this.showSnackbar(this.$t('Planning has been accepted'));
      } catch (error) {
        this.error = error;
      } finally {
        this.acceptLoading = false;
      }
    },

    selectAllTrips(value: boolean) {
      if (!value) {
        return;
      }

      try {
        this.selectAllLoading = true;

        this.selected = this.data?.trips.map((trip: TripInstanceDto) => trip.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading = false;
      }
    },

    isPlanned(id: string): boolean {
      return this.tripStatusesById.get(id) === TripStatus.planned;
    },

    getIconByTransportType(type: string): string {
      return TRANSPORT_ICONS[type];
    },

    getOrderNumbers(orders: Order[]): string[] {
      return orders.map(order => order.number).filter(Boolean);
    },

    getContractorNames(contractors: Contractor[]): string[] {
      return contractors.map(contractor => contractor.name).filter(Boolean);
    },

    getItemTags(tagIds: string[]): Tag[] {
      if (tagIds) {
        return this.tags.filter((item: Tag) => tagIds.includes(item.foreignId));
      }

      return [];
    },

    details(data: any, item: any, event: MouseEvent): void {
      this.$router.push({ name: 'distributionTrip', params: { distributionId: data.id, tripId: item.tripId } });
    },
  },
});
