import http, { pagingParams, Params } from './httpV2';
import uuid from 'uuid/v4';

import Trip, { TripDto } from '@/models/Trip';
import TripPoint, { TripPointDto } from '@/models/TripPoint';
import { Demand, DemandDto } from '@/models/Order';
import WayPoint, { WayPointDto } from '@/models/WayPoint';

const path = '/tripPoints';

export interface PointsListItemDto {
  id: string;
  tripPoint?: TripPointDto;
  wayPoint?: WayPointDto;
  trip?: TripDto;
  demand?: DemandDto;
}

export interface TripPointsListResponse {
  result: PointsListItemDto[];
  total: number;
  totalRelation: string;
  aggregations?: { contractors?: { id: string; name: string }[] };
}

export class PointsListItem {
  id: string;
  tripPoint?: TripPoint;
  wayPoint?: WayPoint;
  trip?: Trip;
  demand?: Demand;

  constructor(data?: Partial<PointsListItemDto>) {
    if (!data) {
      data = {};
    }

    this.id = data.id || uuid();
    this.tripPoint =
      data.tripPoint !== undefined && data.tripPoint.status !== null ? new TripPoint(data.tripPoint) : undefined;
    this.wayPoint = data.wayPoint !== undefined ? new WayPoint(data.wayPoint) : undefined;
    this.trip = data.trip !== undefined ? new Trip(data.trip) : undefined;
    this.demand = data.demand !== undefined && data.demand.type !== null ? new Demand(data.demand) : undefined;
  }
}

interface PointsListParams extends Params {
  isFull?: boolean;
  agg?: string;
}

async function list(opts: PointsListParams = {}): Promise<TripPointsListResponse> {
  if (opts && opts.filter && opts.filter.toHttpParams) {
    opts.filter = opts.filter.toHttpParams();
  }

  const res = await http.get(path, {
    params: pagingParams(opts),
  });

  res.data.result = res.data?.result.map((e: PointsListItemDto) => new PointsListItem(e));
  return res.data;
}

export default {
  list,
};
