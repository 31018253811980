
import Vue from 'vue';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { intersection, union, keyBy, isEqual } from 'lodash';
import { sleep } from '@/lib/util';

import EventBus from '@/event-bus';

import branch from '@/api/branch';
import demands from '@/api/demands';
import tripPointsApi, { PointsListItemDto, TripPointsListResponse } from '@/api/tripPoints';
import { copyTripPointsParams } from '@/api/tripNew';

import { TripPointStatus, TripPointType } from '@/models/TripPoint';
import { Tag } from '@/models/Order';

import PointsFilter from '@/components/filters/PointsFilter.vue';
import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import GridCrud from '@/components/grid/GridCrud';
import { FieldsOptions } from '@/components/grid/Options';
import PointsFilterDto from '@/components/filters/PointsFilterDto';
import PointsListRow from '@/components/trip/PointsListRow.vue';
import AssignTagsDialog from '@/components/modals/AssignTagsDialog.vue';
import MoveDemandDialog from '@/components/modals/MoveDemandDialog.vue';

export default Vue.extend({
  name: 'PointsList',

  components: {
    PointsFilter,
    GridCrud,
    GridTh,
    PointsListRow,
    AssignTagsDialog,
    MoveDemandDialog,
    GridSelectAll,
  },

  data() {
    return {
      apiFunction: tripPointsApi.list,
      pointIds: [] as string[],
      filter: new PointsFilterDto(),
      pagination: {} as {
        page: number;
        rowsPerPage: number;
        sortBy: string;
        descending: boolean;
      },
      selected: [] as string[],
      showAssignTagsDialog: false,
      showAssignDialogLoading: false,
      showMovePointsDemandsDialog: false,
      assignTagError: null,
      error: null,
    };
  },

  async created() {
    this.parseURL();

    await this.getMyBranchList();
    await this.updateTags();

    EventBus.$on('points-demands-refresh', () => {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    });
  },

  beforeDestroy() {
    EventBus.$off('points-demands-refresh');
  },

  mounted() {
    (this.$refs.filter as any).getInitialContractors();
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('company', ['settings']),
    ...mapState('tags', ['tags']),

    fields(): FieldsOptions {
      return {
        orderNumber: {
          label: 'Order number',
          align: 'left',
          sortable: false,
        },
        ...(this.settings.enableCashBoxPayment
          ? {
              'demand.order.paymentStatus': {
                label: 'Payment status',
                sortable: true,
                align: 'left',
              },
            }
          : {}),
        'demand.type': {
          label: 'Type',
          sortable: true,
          align: 'left',
        },
        pointFunctions: {
          label: 'Point functions',
          align: 'left',
          sortable: false,
        },
        events: {
          label: 'Events',
          align: 'left',
          sortable: false,
        },
        'tripPoint.status': {
          label: 'Point Status',
          align: 'left',
          sortable: true,
        },
        'tripPoint.position': {
          label: 'Point number',
          align: 'left',
          sortable: true,
        },
        contractor: {
          label: 'Contractor',
          align: 'left',
          sortable: false,
        },
        address: {
          label: 'Title and address',
          align: 'left',
          sortable: false,
        },
        'trip.executor': {
          label: 'Employee',
          align: 'left',
          sortable: this.hasPermission('search'),
        },
        'trip.dbId': {
          label: 'Trip',
          sortable: true,
          align: 'left',
          defaultSort: 1,
        },
        deliveryWindows: {
          label: 'Delivery window',
          align: 'left',
          sortable: false,
        },
        arrivalAt: {
          label: 'Arrival',
          align: 'left',
          sortable: false,
        },
        'tripPoint.timeInPoint': {
          label: 'Time in point',
          align: 'left',
          sortable: true,
        },
        ...(this.settings.tripMode === 'manual'
          ? {
              'tripPoint.totalWorkTime': {
                label: 'Work time',
                align: 'left',
                sortable: true,
              },
            }
          : {}),
        actions: {
          label: 'Actions',
          align: 'center',
          sortable: false,
        },
      };
    },

    selectedItems(): PointsListItemDto[] {
      if (this.$refs.grid) {
        const items = (this.$refs.grid as any).items;

        return items.filter((item: PointsListItemDto) => this.selected.includes(item.id));
      }

      return [];
    },

    tripPoints(): Array<copyTripPointsParams> {
      const demandsByTripPoint = {} as any;

      for (const item of this.selectedItems) {
        const id = item?.tripPoint?.id || item.id.split(',')[0];
        const tripId = item?.trip?.id;
        const tripDbId = item?.trip?.dbId;

        if (id && tripId && tripDbId) {
          demandsByTripPoint[id] = demandsByTripPoint[id] || { id, tripId, tripDbId, demandIds: [] };
          item?.demand?.id && demandsByTripPoint[id].demandIds.push(item?.demand?.id);
        }
      }

      return Object.values(demandsByTripPoint);
    },

    demandsTagsMap(): Dictionary<string[]> {
      return this.selectedItems
        .filter(item => item.demand)
        .map(item => item.demand)
        .reduce<Dictionary<string[]>>((acc, item) => {
          if (item) {
            acc[item.id] = item.tagIds || [];
          }

          return acc;
        }, {});
    },

    everyTagsSelected(): Dictionary<boolean> {
      const tags = keyBy(intersection(...Object.values(this.demandsTagsMap)));

      return (this.tags as Tag[]).reduce<Dictionary<boolean>>((acc, item) => {
        acc[item.id] = Boolean(tags[item.id]);

        return acc;
      }, {});
    },

    someTagsSelected(): Dictionary<Boolean> {
      const tags = keyBy(union(...Object.values(this.demandsTagsMap)));

      return (this.tags as Tag[]).reduce<Dictionary<boolean>>((acc, item) => {
        acc[item.id] = Boolean(tags[item.id]);

        return acc;
      }, {});
    },

    // used in watcher
    canAssignTags(): boolean {
      return Boolean(
        this.selected.length &&
          this.selected.map((el: string) => el.split(',')[1]).filter(Boolean).length === this.selected.length,
      );
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),
    ...mapActions('tags', ['updateTags']),

    async getMyBranchList(options?: { inactive: boolean }) {
      await branch.listMy(options);
    },

    routerReplace() {
      const query = this.makeQuery();

      if (!isEqual(this.$route.query, query)) {
        this.$router.replace({ query });
      }
    },

    filterUpdate(value: PointsFilterDto) {
      localStorage.setItem('filterPoints', JSON.stringify(value));
      this.filter = new PointsFilterDto(value);
    },

    refresh() {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    },

    parseURL(): void {
      if (Object.keys(this.$route.query).length) {
        this.filterUpdate(new PointsFilterDto().fromQuery(this.$route.query));
      } else if (![null, undefined, ''].includes(localStorage.getItem('filterPoints'))) {
        this.filter = new PointsFilterDto(JSON.parse(localStorage.getItem('filterPoints') || ''));
      }

      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page as string) + 1;
      }

      if (this.$route.query.pageSize) {
        this.pagination.rowsPerPage = parseInt(this.$route.query.pageSize as string);
      }

      if (this.$route.query.sort) {
        this.pagination.sortBy = String(this.$route.query.sort);
      }

      if (this.$route.query.sortDesc) {
        this.pagination.descending = this.$route.query.sortDesc === '1';
      }
    },

    details(item: PointsListItemDto, event: MouseEvent): void {
      if (item.trip && item.tripPoint) {
        const url = '/trips' + '/' + item.trip.dbId + '/' + 'tripPoints/' + item.tripPoint.id;

        if (event && (event.metaKey || event.ctrlKey || event.shiftKey)) {
          window.open(url, '_blank');
        } else {
          this.$router.push(url);
        }
      }
    },

    makeQuery() {
      return {
        ...(this.$refs.grid as Vue & { makeQuery: () => any }).makeQuery(),
        ...this.filter.toQueryForCheck(),
      };
    },

    errorUpdate(error: any) {
      this.error = error;
    },

    pointsUpdate(data: TripPointsListResponse) {
      this.pointIds = data.result.map((point: PointsListItemDto) => point.id);
    },

    selectAllPoints(value?: boolean) {
      if (!value) {
        return;
      }

      this.selected = this.pointIds;
    },

    async assignTags(selectedTags: Dictionary<string>) {
      try {
        const demandsTagsMap = {} as Dictionary<string[]>;

        for (const tripPointDemandId of this.selected) {
          if (!tripPointDemandId.includes(',')) {
            continue;
          }

          const demandId = tripPointDemandId.split(',')[1];

          if (demandId) {
            const demandTags = keyBy(this.demandsTagsMap[demandId]);
            let changes = 0;

            for (const [tagId, value] of Object.entries(selectedTags)) {
              if (value && !demandTags[tagId]) {
                demandTags[tagId] = value;
                changes++;
              }

              if (!value && demandTags[tagId]) {
                delete demandTags[tagId];
                changes++;
              }
            }

            if (changes > 0) {
              demandsTagsMap[demandId] = Object.keys(demandTags);
            }
          }
        }

        this.showAssignDialogLoading = true;

        await demands.assignTags(demandsTagsMap);

        await sleep(5000);
        this.refresh();
        this.showSnackbar(this.$t('Tags has been successfully assigned'));

        this.showAssignTagsDialog = false;
        this.selected = [];
      } catch (err) {
        this.assignTagError = err;
      } finally {
        this.showAssignDialogLoading = false;
      }
    },

    onPointsDemandsMoved({ tripDbId, tripPointIds }: { tripDbId?: number; tripPointIds?: string[] } = {}) {
      this.showMovePointsDemandsDialog = false;
      this.showSnackbar(this.$t('Points demands have been moved'));
      this.$router.push(`/trips/${tripDbId}#${tripPointIds?.length ? tripPointIds[0] : undefined}`);
    },
  },

  provide() {
    return {
      getComputeColor(status: string, type?: string): string {
        // TODO: Нужно еще дописать условие для обновления цвета маркера МТ
        const mapColors = new Map();
        mapColors.set('darkBlue', '#2a67b3');
        mapColors.set('blue', 'blue');
        mapColors.set('lightBlue', '#88c5f5');
        mapColors.set('red', '#b8325b');
        mapColors.set('lightRed', '#cc577b');
        mapColors.set('black', '#757575');

        if (type === TripPointType.finish) {
          if (status === TripPointStatus.deleted) {
            return mapColors.get('lightRed') as string;
          } else {
            return mapColors.get('black') as string;
          }
        }

        if (type === TripPointType.actualFinish) {
          return mapColors.get('lightRed') as string;
        }

        if (type === TripPointType.notScheduled) {
          return mapColors.get('lightBlue') as string;
        }

        switch (status) {
          case 'active':
            return mapColors.get('darkBlue') as string;
          case 'passed':
            return mapColors.get('blue') as string;
          case 'current':
            return mapColors.get('darkBlue') as string;
          case 'marked':
            return mapColors.get('lightBlue') as string;
          case 'cancelled':
            return mapColors.get('lightBlue') as string;
          case 'waitingForAdd':
            return mapColors.get('darkBlue') as string;
          case 'waitingForDelete':
            return mapColors.get('blue') as string;
          case 'planned':
            return mapColors.get('darkBlue') as string;
          case 'deleted':
            return mapColors.get('lightBlue') as string;
          default:
            return mapColors.get('darkBlue') as string;
        }
      },
    };
  },

  watch: {
    filter: {
      handler: async function(n, o) {
        await this.routerReplace();

        // show points / demands of inactive accounts
        if (n && o && n.inactive !== o.inactive) {
          await this.getMyBranchList({ inactive: Boolean(n.inactive) });
        }
      },
      deep: true,
    },

    pagination: {
      handler: async function(n, o) {
        await this.routerReplace();

        // move to another page in the table
        if (n && o && n.page !== o.page) {
          this.selected = [];
        }
      },
      deep: true,
    },

    pointIds() {
      if (this.selected.length) {
        this.selected = this.pointIds.filter((id: string) => this.selected.includes(id));
      }
    },

    canAssignTags(newValue) {
      const el = document.getElementById('assign-tags-tile');

      if (newValue) {
        el?.classList.remove('v-list__tile--disabled');
        el?.parentElement?.classList.remove('v-list--disabled');
      } else {
        el?.classList.add('v-list__tile--disabled');
        el?.parentElement?.classList.add('v-list--disabled');
      }
    },
  },
});
