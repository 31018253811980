
import Vue from 'vue';
import { DemandType } from '@/models/Order';

export default Vue.extend({
  name: 'DemandTypePicker',

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Demand type',
    },
    disabled: {
      type: Boolean,
    },
    items: {
      type: Array,
      default() {
        return Object.keys(DemandType);
      },
    },
  },

  computed: {
    types() {
      return this.items.map(s => ({
        text: this.$t(`DemandType.${s}`),
        value: s,
      }));
    },
  },

  methods: {
    selectStatus(value: Array<any>) {
      this.$emit('input', value);
    },
  },
});
