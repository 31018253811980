
import Vue from 'vue';
import { mapState } from 'vuex';

import AccountsInvite from '@/pages/AccountsInvite.vue';
import AccountsImport from '@/components/import/AccountsImport.vue';
import AccountsFilterDto from '@/components/filters/AccountsFilterDto';
// @ts-ignore
import BranchAccountPicker from '@/components/pickers/BranchAccountPicker';
import AccountStatusPicker from '@/components/pickers/AccountStatusPicker.vue';

export default Vue.extend({
  name: 'AccountsFilter',

  components: {
    AccountsInvite,
    AccountsImport,
    BranchAccountPicker,
    AccountStatusPicker,
  },

  props: {
    canCreate: {
      type: Boolean,
      default: false,
    },
    isActiveStatus: {
      type: Boolean,
      default: false,
    },
    availableSlots: {
      type: Number,
    },
    paidTrips: {
      type: Number,
    },
    form: {
      type: AccountsFilterDto,
      required: true,
    },
  },

  data() {
    const showFullFilter = this.form && Object.keys(this.form.toQuery()).some(k => !['search'].includes(k));

    return {
      valid: true,
      filter: new AccountsFilterDto(this.form),
      searchText: '',
      showDetails: showFullFilter,
    };
  },

  async created() {
    this.searchText = this.form.search || '';
  },

  computed: {
    ...mapState('branches', ['branches']),
    ...mapState('company', ['settings']),

    ownTransportType(): any {
      return ['car', 'truck', 'taxi', 'bus', 'motorcycle', 'bicycle', 'pedestrian'].map(tr => ({
        text: this.$t(`TransportType.${tr}`),
        value: tr,
      }));
    },
  },

  methods: {
    searchUpdate() {
      this.filter.search = this.searchText;
    },

    toggleVisibility() {
      this.showDetails = !this.showDetails;
    },

    reloadClick() {
      this.$emit('reload');
    },

    resetFilter() {
      this.filter = new AccountsFilterDto();
    },
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.valid) {
          this.$emit('filterUpdate', this.filter);
        }
      },
    },

    valid() {
      if (this.valid) {
        this.$emit('filterUpdate', this.filter);
      }
    },

    searchText(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.searchUpdate();
      }
    },
  },
});
