<template>
  <select-helper
    v-bind="selectProps"
    :items="selectItems"
    :value="shownItems"
    :parsed-items-data="selectedItems"
    :multiple="!single"
    item-text="name"
    @select="selectItem"
    @unselect="unselectItem"
    ref="select"
    class="chip-select"
    :error="branchError"
  >
    <template slot="label">
      <slot name="label" />
    </template>

    <template slot="selection" slot-scope="data">
      <v-chip
        v-if="data.parent.value[0].allBranch ? data.item.allBranch : true"
        close
        @input="data.parent.selectItem(data.item)"
        :selected="data.selected"
        :disabled="data.disabled"
        class="v-chip--select-multi"
        :key="JSON.stringify(data.item)"
      >
        <span :class="{ 'font-italic': data.item.is_inactive }">
          {{ data.item.name }}
        </span>
      </v-chip>
    </template>

    <template slot="item" slot-scope="data">
      <template>
        <v-subheader v-if="data.item.branchHeader" class="px-0">
          <span class="blue--text text--darken-2">
            {{ data.item.name }}
          </span>
        </v-subheader>

        <v-list-tile-content v-else>
          <v-list-tile-title>
            <span :class="{ 'font-italic': data.item.is_inactive }" v-html="highlight(data.item.name)" />
          </v-list-tile-title>

          <v-list-tile-sub-title v-text="data.item.job_title" :class="data.item.is_owner && 'font-weight-bold'" />
        </v-list-tile-content>
      </template>
    </template>
  </select-helper>
</template>

<script>
import _ from 'lodash';

import { AccountNumericStatus } from '@/models/Account';
import { highlight } from '@/lib/text';
import SelectHelper from '../SelectHelper';

/**
 * Component to select branches and accounts
 */
/* eslint-disable vue/require-default-prop */
export default {
  name: 'BranchAccountPicker',

  components: { SelectHelper },

  props: {
    label: String,
    prependIcon: String,
    items: Array,
    disabled: Boolean,
    loading: Boolean,
    accountsOnly: Boolean,
    single: Boolean,
    uuid: Boolean,
    value: Object,
    itemMapper: Function,
    selected: {
      type: Object,
      default: null,
    },
    branchError: Boolean,
    rules: Array,
  },

  methods: {
    selectItem(item) {
      if (item.target) {
        return;
      }

      if (this.single) {
        this.$emit('input', {
          branches: item.branch !== undefined ? [item.branch] : [],
          accounts: item.account ? [item.account] : [],
        });

        return;
      }

      let result = {
        branches: this.value && this.value.branches ? _.clone(this.value.branches) : [],
        accounts: this.value && this.value.accounts ? _.clone(this.value.accounts) : [],
      };

      if (item.allBranch) {
        result.branches = [];
        result.accounts = [];

        for (const branch of this.items) {
          if (branch.id !== undefined) {
            result.branches.push(branch.id);
          } else {
            branch.accounts.forEach(account => {
              result.accounts.push(account[this.idField]);
            });
          }
        }
      } else {
        if (item.branch !== undefined) {
          result.branches.push(item.branch);
        } else if (item.account) {
          result.accounts.push(item.account);
        }

        result = this.normalizeResult(result);
      }

      this.$emit('input', result);
    },

    unselectItem(item) {
      if (this.single) {
        this.$emit('input', {
          branches: [],
          accounts: [],
        });

        return;
      }

      let result = {
        branches: this.value && this.value.branches ? _.clone(this.value.branches) : [],
        accounts: this.value && this.value.accounts ? _.clone(this.value.accounts) : [],
      };

      if (item.allBranch) {
        result.branches = [];
        result.accounts = [];
      } else if (item.branch !== undefined) {
        result = this.unselectBranch(result, item.branch);
      } else if (item.account) {
        const i = result.accounts.indexOf(item.account);

        if (i !== -1) {
          // Account is selected alone
          result.accounts.splice(i, 1);
        } else {
          // Logic to unselect account from its branches
          result = this.unselectAccount(result, item.account);
        }
      }

      this.$emit('input', result);
    },

    unselectBranch(result, unselectId) {
      const branch = this.branchById.get(unselectId);

      if (branch.accounts && branch.accounts.length !== 0) {
        const accountsInBranch = new Map();

        branch.accounts.forEach(branchAccount => accountsInBranch.set(branchAccount[this.idField], true));

        const branchesLeft = [];

        for (const selectedBranchId of result.branches) {
          if (selectedBranchId === unselectId) {
            continue;
          }

          const selectedBranch = this.branchById.get(selectedBranchId);

          if (selectedBranch.accounts && selectedBranch.accounts.length !== 0) {
            selectedBranch.accounts.forEach(branchAccount => {
              if (!accountsInBranch.has(branchAccount[this.idField])) {
                result.accounts.push(branchAccount[this.idField]);
              }
            });
          } else {
            branchesLeft.push(selectedBranchId);
          }
        }

        result.branches = branchesLeft;
      } else {
        const i = result.branches.indexOf(unselectId);

        if (i !== -1) {
          result.branches.splice(i, 1);
        }
      }

      return this.normalizeResult(result);
    },

    unselectAccount(result, unselectId) {
      for (const branchId of this.accountBranches.get(unselectId)) {
        const i = result.branches.indexOf(branchId);

        if (i !== -1) {
          result.branches.splice(i, 1);

          const branch = this.branchById.get(branchId);

          if (branch.accounts) {
            branch.accounts.forEach(branchAccount => {
              if (branchAccount[this.idField] !== unselectId) {
                result.accounts.push(branchAccount[this.idField]);
              }
            });
          }
        }
      }

      return this.normalizeResult(result);
    },

    getSelectedGrouped(data) {
      const accountsSelected = new Map();
      const branchesSelected = new Map();

      if (data && data.accounts) {
        for (const accountId of data.accounts) {
          if (this.accountBranches.has(accountId)) {
            accountsSelected.set(accountId, true);
          }
        }
      }

      if (data && data.branches) {
        for (const branchId of data.branches) {
          const branch = this.branchById.get(branchId);

          if (branch && branch.accounts && branch.accounts.length) {
            for (const account of branch.accounts) {
              if (this.accountBranches.has(account[this.idField])) {
                accountsSelected.set(account[this.idField], true);
              }
            }
          }
        }
      }

      const accountsSelectedShow = new Map(accountsSelected);
      let allBranches = false;

      if (this.items && !this.accountsOnly) {
        for (const branch of this.items) {
          if (branch.accounts && branch.accounts.length) {
            branchesSelected.set(
              branch.id,
              _.every(branch.accounts, account => accountsSelected.get(account[this.idField])),
            );
          } else if (data && data.branches) {
            branchesSelected.set(branch.id, data.branches.indexOf(branch.id) !== -1);
          } else {
            branchesSelected.set(branch.id, false);
          }
        }

        if (this.items.length > 0) {
          allBranches = true;

          branchesSelected.forEach((value, branchId) => {
            if (!value) {
              allBranches = false;
            }
          });
        }

        branchesSelected.delete(undefined);

        for (const branch of this.items) {
          if (branchesSelected.get(branch.id) && branch.accounts) {
            branch.accounts.forEach(account => {
              accountsSelectedShow.set(account[this.idField], false);
            });
          }
        }
      }

      return {
        allBranches,
        accountsSelected,
        accountsSelectedShow,
        branchesSelected,
      };
    },

    normalizeResult(data) {
      const { branchesSelected, accountsSelectedShow } = this.getSelectedGrouped(data);

      const result = {
        branches: [],
        accounts: [],
      };

      branchesSelected.forEach((value, branchId) => {
        if (value) {
          result.branches.push(branchId);
        }
      });

      accountsSelectedShow.forEach((value, accountId) => {
        if (value) {
          result.accounts.push(accountId);
        }
      });

      return result;
    },

    highlight(text) {
      const el = this.$refs.select;
      let searchInput;

      if (el) {
        searchInput = (el.internalSearch || '').toString().toLowerCase();
      }

      return highlight(searchInput, text);
    },
  },

  computed: {
    idField() {
      return this.uuid ? 'external_id' : 'id';
    },

    selectItems() {
      const result = [];

      if (!this.items) {
        return result;
      }

      const hasAnyBranch = _.some(this.items, branch => branch.id !== undefined);

      if (!this.accountsOnly && hasAnyBranch) {
        if (!this.single) {
          result.push({
            allBranch: true,
            name: this.$t('All departments'),
            value: 'branch:all',
          });
          result.push({ divider: true });
        }

        let num = 1;
        for (const branch of this.items) {
          if (branch.id !== undefined) {
            result.push({
              branch: branch.id,
              name: branch.name,
              value: `branch:${branch.id}`,
              accent: num,
            });
          }
          num++;
        }
      }

      for (const branch of this.items) {
        if (branch.accounts && branch.accounts.length) {
          if (result.length) {
            if (branch.id === undefined) {
              // branch.id can be null
              result.push({ divider: true, otherAccounts: true });
            } else {
              result.push({ divider: true });
            }
          }

          if (![undefined, -1].includes(branch.id)) {
            result.push({
              name: branch.name,
              value: `branch:${branch.id}`,
              branch: branch.id,
              branchHeader: true,
              disabled: !!this.accountsOnly,
            });
          }

          for (const account of branch.accounts) {
            const elem = {
              account: account[this.idField],
              name: account.full_name,
              value: `account:${account[this.idField]}`,
              job_title: account.job_title,
              is_inactive: account.status === AccountNumericStatus.inactive,
              is_owner: account.is_owner,
              accent: account.full_name.slice(0, 1).toUpperCase(),
            };

            result.push(elem);
          }
        }
      }

      if (this.itemMapper) {
        return result.map(this.itemMapper);
      }

      return result;
    },

    accountBranches() {
      const accountBranches = new Map();

      for (const branch of this.items) {
        if (branch.accounts) {
          for (const account of branch.accounts) {
            if (!accountBranches.has(account[this.idField])) {
              accountBranches.set(account[this.idField], []);
            }

            accountBranches.get(account[this.idField]).push(branch.id);
          }
        }
      }

      return accountBranches;
    },

    branchById() {
      const branchById = new Map();

      for (const branch of this.items) {
        branchById.set(branch.id, branch);
      }

      return branchById;
    },

    selectedGrouped() {
      return this.getSelectedGrouped(this.value);
    },

    /**
     * Items that are selected in list
     */
    selectedItems() {
      const { allBranches, accountsSelected, branchesSelected } = this.selectedGrouped;
      const selected = new Map();

      if (allBranches) {
        selected.set('branch:all', true);
      }

      branchesSelected.forEach((value, branchId) => {
        if (value) {
          selected.set(`branch:${branchId}`, true);
        }
      });

      accountsSelected.forEach((value, accountId) => {
        if (value) {
          selected.set(`account:${accountId}`, true);
        }
      });

      return selected;
    },

    /**
     * Items that are shown in selection chips
     */
    shownItems() {
      const { allBranches, accountsSelectedShow, branchesSelected } = this.selectedGrouped;
      const selected = [];

      if (allBranches) {
        selected.push('branch:all');
      } else {
        if (branchesSelected.get(null)) {
          selected.push('branch:null');
        }

        branchesSelected.forEach((value, branchId) => {
          if (value && branchId !== null) {
            selected.push(`branch:${branchId}`);
          }
        });

        accountsSelectedShow.forEach((value, accountId) => {
          if (value) {
            selected.push(`account:${accountId}`);
          }
        });
      }

      if (this.single) {
        return selected[0];
      }

      return selected;
    },

    selectProps() {
      const props = {
        label: this.label,
        prependIcon: this.prependIcon,
        disabled: this.disabled,
        loading: this.loading,
        rules: this.rules,
      };

      if (!this.single) {
        props.multiple = true;
      }

      props.chips = true;

      return props;
    },
  },

  watch: {
    items() {
      const currentValue = {
        branches: this.value && this.value.branches ? _.clone(this.value.branches) : [],
        accounts: this.value && this.value.accounts ? _.clone(this.value.accounts) : [],
      };

      // Emit new value only if has dirrerence
      const result = this.normalizeResult(currentValue);

      if (
        _.xor(currentValue.branches, result.branches).length ||
        _.xor(currentValue.accounts, result.accounts).length
      ) {
        this.$emit('input', result);
      }
    },
  },
};
</script>
