import { TripPointStatus } from '@/models/TripPoint';
import { ITripMap } from './lib';
import { getIconSettings, MarkerIconName } from '../../config';
import { MapPointData } from '@/services/TripPageService';
import { MapMarkerOptions } from '../../primitives/MapMarker';

export default function(point: MapPointData, parent: ITripMap): Omit<MapMarkerOptions, 'position'> {
  let icon: MarkerIconName = 'stop';

  const { tripPoint, showTimezone, timezoneOffset } = point;
  const { secondLabelInfoMap } = parent;

  let text = '';
  let downText;

  let selectedCheckin = tripPoint.checkins[0];

  const outDate = selectedCheckin.outDate || new Date();
  const duration = selectedCheckin.getDuration();

  text += parent.formatTimeAndDateOneLine(selectedCheckin.inDate, false, showTimezone, timezoneOffset);
  text += ' – ' + parent.formatTimeAndDateOneLine(outDate, false, showTimezone, timezoneOffset);
  text += ' (' + parent.$duration(duration, 'duration') + ')';

  if (tripPoint.status === TripPointStatus.current) {
    icon = 'stopCurrent';
    downText = parent.updatedAtText;
  }

  const iconData = getIconSettings(icon);

  const result = {
    icon: iconData,
    secondLabel:
      text && secondLabelInfoMap
        ? {
            html: text,
            origin: iconData.secondLabelOrigin,
          }
        : undefined,
    downLabel: downText
      ? {
          html: downText,
          origin: iconData.downLabelOrigin,
        }
      : undefined,
    zindex: point.zindex,
    draggable: parent.$store.state.map.draggable,
  };

  return result;
}
