<template>
  <v-dialog v-model="moveDialog" :max-width="maxWidth">
    <v-card>
      <error-alert :value="moveError" />

      <v-card-title class="headline">{{ moveTitle || this.$t('Move') }}?</v-card-title>

      <v-card-text>
        <span v-if="appendText">
          {{ appendText }}
        </span>

        <span v-if="moveItem">
          {{ $t('common_strings.move_query_item', [moveItem]) }}
        </span>

        <span v-else>
          {{ $t('common_strings.move_query') }}
        </span>

        <br />

        <span v-if="supplementText" style="color: green; font-size: 13px">
          {{ supplementText }}
        </span>

        <v-select
          v-if="moveBranches"
          :label="$t('Branches')"
          :items="myBranches"
          item-text="name"
          item-value="id"
          v-model="selectedBranch"
          class="mt-2"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn flat @click.native="hideModal">
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn color="primary" flat @click.native="handleMove" :disabled="!Boolean(selectedBranch)">
          {{ $t('Move') }}
        </v-btn>
      </v-card-actions>

      <v-progress-linear :indeterminate="true" :height="2" :active="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import branch from '@/api/branch';

export default {
  name: 'MoveDialog',

  data() {
    return {
      moveDialog: true,
      myBranches: [],
      selectedBranch: null,
    };
  },

  props: {
    maxWidth: {
      type: String,
      default: '320',
    },
    moveError: {
      type: Error,
      default: null,
    },
    moveTitle: {
      type: String,
      default: null,
    },
    moveItem: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    moveBranches: {
      type: Boolean,
      default: false,
    },
    withoutBranchIds: {
      type: Array,
      default: () => [],
    },
    supplementText: String,
    appendText: String,
  },

  async created() {
    if (this.moveBranches) {
      this.myBranches = await branch.listMy({
        withoutBranchIds: this.withoutBranchIds,
      });
    }
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    handleMove() {
      if (this.moveBranches) {
        this.$emit('move', this.selectedBranch);
      } else {
        this.$emit('delete');
      }
    },
  },

  watch: {
    moveDialog() {
      if (!this.moveDialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
