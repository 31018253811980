export default {
  namespaced: true,

  state: {
    tripDto: null,
  },

  getters: {
    tripDtoFromStore(state) {
      return state.tripDto;
    },
  },

  mutations: {
    setTripDto(state, data) {
      state.tripDto = data;
    },
  },
};
