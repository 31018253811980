import http from './httpV2';
import auth from './auth';

const path = '/restore';

interface CreateListParams {
  login?: string;
  domain?: string;
  companyId?: string;
}

async function create(params: CreateListParams) {
  const res = await http.post(path, {
    login: params.login,
    domain: params.domain,
    companyId: params.companyId,
  });

  return res.data;
}

async function get(code: any) {
  const res = await http.get(`${path}/${code}`);
  return res.data;
}

async function reset(code: any, password: any) {
  return auth.reset(code, password);
}

export default {
  create,
  get,
  reset,
};
