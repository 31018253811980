import Uuid from '@/models/Uuid';

export interface ContractorDto {
  id: Uuid;
  foreignId: string;
  name: string;
}

export class Contractor {
  id: Uuid;
  foreignId: string;
  name: string;

  constructor(contractor: ContractorDto) {
    this.id = contractor.id;
    this.foreignId = contractor.foreignId;
    this.name = contractor.name;
  }
}
