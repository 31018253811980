import debounce from 'lodash/debounce';
import store from '@/store';
import EventBus from '@/event-bus';

const updateAvailableOnboarding = debounce(function() {
  const elms = document.querySelectorAll('[onboarding]');
  store.commit('site/setHasHelpAvailable', !!elms.length);
  EventBus.$emit('onboarding-update');
}, 0);

function updateBinding(el, value, modifiers) {
  // console.log('bind', value);
  const text = typeof value === 'object' ? value.text : value;
  el.setAttribute('onboarding', text === true ? '' : text);

  if (typeof value === 'object') {
    if (value.maxWidth) {
      el.setAttribute('onboarding-maxwidth', value.maxWidth);
    }
    if (value.zIndex) {
      el.setAttribute('onboarding-zindex', value.zIndex);
    }
    if (value.nudgeTop) {
      el.setAttribute('onboarding-nudgetop', value.nudgeTop);
    }
    if (value.nudgeRight) {
      el.setAttribute('onboarding-nudgeright', value.nudgeRight);
    }
    if (value.nudgeBottom) {
      el.setAttribute('onboarding-nudgebottom', value.nudgeBottom);
    }
    if (value.nudgeLeft) {
      el.setAttribute('onboarding-nudgeleft', value.nudgeLeft);
    }
  }

  const modifiersNames = Object.getOwnPropertyNames(modifiers);
  if (modifiersNames.length > 0) {
    el.setAttribute('onboarding-placement', modifiersNames[0]);
  }

  updateAvailableOnboarding();
}

function removeBinding(el) {
  // console.log('unbind', el);
  if (el && el.removeAttribute) {
    el.removeAttribute('onboarding');
    el.removeAttribute('onboarding-maxwidth');
    el.removeAttribute('onboarding-zindex');
    el.removeAttribute('onboarding-nudgetop');
    el.removeAttribute('onboarding-nudgeright');
    el.removeAttribute('onboarding-nudgebottom');
    el.removeAttribute('onboarding-nudgeleft');
    el.removeAttribute('onboarding-placement');
  }
  updateAvailableOnboarding();
}

const VueOnboarding = {
  install(Vue) {
    Vue.directive('onboarding', {
      bind(el, binding) {
        if (binding && binding.value) {
          updateBinding(el, binding.value, binding.modifiers);
        }
      },

      update(el, binding) {
        if (binding && binding.value) {
          updateBinding(el, binding.value, binding.modifiers);
        } else {
          removeBinding(el);
        }
      },

      unbind(el) {
        removeBinding(el);
      },
    });
  },
};

export default VueOnboarding;
