import { isEqual } from 'lodash';
import uuid from 'uuid/v4';

import Trip from '@/models/Trip';
import TripChange, { ArrivalAts } from '@/models/TripChange';
import TripPoint, { TripPointStatus, TripPointType } from '@/models/TripPoint';
import Uuid from '@/models/Uuid';
import { AddressComponent } from '@/models/Location';
import { Contact } from '@/models/Contact';

export function getTripChanges(originalTrip: Trip, trip: Trip, pointsETA: Map<Uuid, Date>): TripChange[] {
  const result: TripChange[] = [];

  const originalTripPoints = new Map<Uuid, TripPoint>();

  const deletedPointIds = new Set<Uuid>();
  const addedPointsIds = [] as Uuid[];

  const arrivalAts: ArrivalAts = [];
  pointsETA.forEach((arrivalAt: Date, id: Uuid) => arrivalAts.push({ id, arrivalAt: arrivalAt?.toISOString() }));

  originalTrip.tripPoints.forEach(tripPoint => {
    originalTripPoints.set(tripPoint.id, tripPoint);
  });

  trip.tripPoints.forEach(tripPoint => {
    const originalTripPoint = originalTripPoints.get(tripPoint.id);

    if (
      !originalTripPoint ||
      (tripPoint.status === TripPointStatus.waitingForAdd &&
        originalTripPoint.status === TripPointStatus.waitingForDelete)
    ) {
      addedPointsIds.push(tripPoint.id);
      result.push({
        external_id: uuid(),
        type: 'pointAdd',
        info: {
          external_id: tripPoint.id,
          type: tripPointTypeToNumber(tripPoint.type),
          name: tripPoint.title,
          address: tripPoint.getAddress() as string,
          address_components: tripPoint.getLocationAddress()?.addressComponents as AddressComponent[],
          contacts: tripPoint.contacts,
          latitude: tripPoint.getCoordinates().lat,
          longitude: tripPoint.getCoordinates().lng,
          radius: tripPoint.radius,
          place_id: tripPoint.location.googlePlaceId,
          place_external_id: tripPoint.placeLink?.placeId || null,
          place_name: tripPoint.placeLink?.title || null,
          place_contacts: tripPoint.placeLink?.contacts as Contact[],
          working_hours: tripPoint.workingHours,
          delivery_windows: tripPoint.deliveryWindows,
          sms_notification: tripPoint.smsNotification,
          functions: tripPoint.functions.toDTO(),
          comment: tripPoint.comment,
          stay_time: tripPoint.stayTime,
          arrival_plan_at: pointsETA.get(tripPoint.id)?.toISOString(),
        },
      });
    } else if (
      tripPoint.status === TripPointStatus.waitingForDelete &&
      originalTripPoint.status !== TripPointStatus.waitingForDelete
    ) {
      deletedPointIds.add(tripPoint.id);
      result.push({
        external_id: uuid(),
        type: 'pointDelete',
        info: {
          external_id: tripPoint.id,
          arrivalAts,
        },
      });
    } else {
      result.push(...getTripPointChanges(tripPoint, originalTripPoint));
    }
  });

  const originalTripPointsOrder = originalTrip.tripPoints
    .filter(
      tripPoint =>
        (tripPoint.isScheduled() || tripPoint.isFinish()) && tripPoint.isActive() && !deletedPointIds.has(tripPoint.id),
    )
    .map(tripPoint => tripPoint.id);

  if (addedPointsIds.length) {
    const finishPoint = originalTrip.getFinish();
    const finishPointId = finishPoint && finishPoint.id;
    let insertIndex = originalTripPointsOrder.findIndex(pointId => pointId === finishPointId);
    insertIndex = insertIndex !== -1 ? insertIndex : originalTripPointsOrder.length;
    originalTripPointsOrder.splice(insertIndex, 0, ...addedPointsIds);
  }

  const newTripPointsOrder = trip.tripPoints
    .filter(
      tripPoint =>
        (tripPoint.isScheduled() || tripPoint.isFinish()) && tripPoint.isActive() && !deletedPointIds.has(tripPoint.id),
    )
    .map(tripPoint => tripPoint.id);

  if (!isEqual(newTripPointsOrder, originalTripPointsOrder)) {
    result.push({
      external_id: uuid(),
      type: 'pointsReorder',
      info: {
        tripPointIds: newTripPointsOrder,
        arrivalAts,
      },
    });
  }

  if (trip.responsibleId !== originalTrip.responsibleId) {
    result.push({
      external_id: uuid(),
      type: 'updateResponsible',
      info: {
        responsibleId: trip.responsibleId || null,
      },
    });
  }

  if (trip.executorId !== originalTrip.executorId) {
    result.push({
      external_id: uuid(),
      type: 'updateExecutor',
      info: {
        executorId: trip.executorId || null,
      },
    });
  }

  if (trip.transportId !== originalTrip.transportId) {
    result.push({
      external_id: uuid(),
      type: 'updateTransport',
      info: {
        transportId: trip.transportId || null,
      },
    });
  }

  if (trip.settings && !isEqual(trip.settings, originalTrip.settings)) {
    result.push({
      external_id: uuid(),
      type: 'tripChange',
      info: {
        settings: trip.settings,
      },
    });
  }

  const needUpdateWay = result.some(change => {
    if (['pointAdd', 'pointDelete', 'pointsReorder'].includes(change.type)) {
      return true;
    }

    if (change.type === 'pointChange' && change.info.location) {
      return true;
    }

    if (change.type === 'tripChange' && change.info.settings?.transportType !== undefined) {
      return true;
    }

    return false;
  });

  if (needUpdateWay) {
    result.push({
      external_id: uuid(),
      type: 'updateWay',
      info: {
        way: trip.way.activeWay,
      },
    });
  }

  return result;
}

const TRIP_POINT_PROPERTIES = [
  'comment',
  'contacts',
  'deliveryWindows',
  'functions',
  'location',
  'placeLink',
  'smsNotification',
  'stayTime',
  'title',
  'type',
] as const;

function getTripPointChanges(tripPoint: TripPoint, originalTripPoint: TripPoint): Array<TripChange> {
  const changes = [] as Array<TripChange>;
  TRIP_POINT_PROPERTIES.forEach(propName => {
    const change = checkPropertyChange(propName, tripPoint, originalTripPoint);
    if (change) {
      changes.push(change);
    }
  });
  return changes;
}

function checkPropertyChange(
  propName: keyof TripPoint,
  tripPoint: TripPoint,
  originalTripPoint: TripPoint,
): TripChange | null {
  let newValue = tripPoint[propName];
  let oldValue = originalTripPoint[propName];

  if (propName === 'type') {
    newValue = tripPointTypeToNumber(tripPoint[propName]);
    oldValue = tripPointTypeToNumber(originalTripPoint[propName]);
  }

  if (
    !isEqual(newValue, oldValue) &&
    !(newValue === null && oldValue === '') &&
    !(newValue === '' && oldValue === null)
  ) {
    return {
      external_id: uuid(),
      type: 'pointChange',
      info: {
        external_id: tripPoint.id,
        [propName]: newValue,
      },
    };
  }

  return null;
}

function tripPointTypeToNumber(type: TripPointType): number {
  return {
    [TripPointType.start]: 1,
    [TripPointType.scheduled]: 2,
    [TripPointType.notScheduled]: 3,
    [TripPointType.finish]: 4,
    [TripPointType.actualFinish]: 5,
  }[type];
}
