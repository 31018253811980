<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Price list') }}</h1>
    </v-flex>
    <v-flex>
      <v-card style="padding-left: 20px;">
        <v-card-text class="px-0">
          <h3>{{ $t('Which tariff is the best to choose') }}?</h3>
          {{ $t('attention_two_points_pricing') }}:
          <ul style="padding-left: 20px">
            <li>{{ $t('number_paid_accounts_system') }}</li>
            <li>{{ $t('subscription_period_system') }}</li>
          </ul>
          <br />
          <div style="color: red">{{ $t('activating_additional_accounts') }}!</div>
          <ul style="padding-left: 20px">
            <li>{{ $t('payment_individually_price_list') }}</li>
            <li>{{ $t('additional_reaching_new_tariff') }}</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex>
      <v-card>
        <v-card-text class="text-xs-center">
          <h3>{{ $t('The cost is indicated for 1 account per month') }}</h3>
        </v-card-text>
        <v-data-table :headers="headers" :items="numSlotsList" hide-actions>
          <template slot="items" slot-scope="props">
            <td>{{ userPlan(props.item) }}</td>
            <td v-for="slots in numMonthList" :key="slots">
              {{ getPlanCell(props.item, slots) }}
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-flex>
      <v-card>
        <v-card-text style="padding-left: 20px;">
          <v-layout row wrap align-end>
            <v-flex xs12 sm3>
              {{ $t('Specify the number of accounts') }}
            </v-flex>
            <v-flex xs12 sm2>
              <number-picker v-model="numSlots" />
            </v-flex>
          </v-layout>
          <v-layout row wrap align-end>
            <v-flex xs12 sm3>
              {{ $t('Select a subscription period') }}
            </v-flex>
            <v-flex xs12 sm2>
              <v-select :items="numMonthListSelect" v-model="numMonth" menu-props="bottom" hide-details class="pr-3" />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider />
        <v-card-text style="padding-left: 20px;">
          <div>
            <b>{{ $t('Total') }} {{ $n(totalCost, 'currency', currency) }}</b>
          </div>
          <div>{{ $t('Subscription period') }}: {{ $tc('{0} months', numMonth) }}</div>
          <div>{{ $t('Number of accounts') }}: {{ numSlots }}</div>
          <div>{{ $t('Tariff') }}: {{ userPlan(plan) }}</div>
          <div>{{ $t('Subscription fee per month') }}: {{ $n(costPerMonth, 'currency', currency) }}</div>
          <div>
            {{ $t('The cost is indicated for 1 account per month') }}:
            {{ $n(costPerSlotByMonth, 'currency', currency) }}
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex v-if="showSmsCounter" id="smspacks">
      <BuySmsTable />
    </v-flex>
    <v-flex>
      <v-card style="padding-left: 20px;">
        <v-card-text class="px-0">
          {{ $t('In case you have a question, click on the button ')
          }}<a href="mailto:info@homestretch.ch">"{{ $t('Support') }}"</a>
          {{ $t('write_request_HomeStretch_specialists') }}
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import api from '@/api/billing';

import NumberPicker from '@/components/pickers/NumberPicker';
import Common from '@/components/billing/Common';
import BuySmsTable from '@/components/billing/BuySmsTable';

export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Price list'),
    };
  },

  components: { BuySmsTable, NumberPicker },
  mixins: [Common],

  data() {
    return {
      numSlotsList: ['1-10', '11-50', '51-100', '101-1000000'],
      numMonthList: [1, 12],
      prices: {
        '1-10': { 1: null, 12: null },
        '11-50': { 1: null, 12: null },
        '51-100': { 1: null, 12: null },
        '101-1000000': { 1: null, 12: null },
      },
      numSlots: 1,
      numMonth: 1,
      totalCost: null,
      plan: 0,
      costPerMonth: null,
      costPerSlotByMonth: null,
      currency: null,
    };
  },

  async created() {
    await this.getPlan();
    await this.updateCost();
  },

  methods: {
    async getPlan() {
      const data = await api.info.getPlan();

      _.each(data.plan, price => {
        this.prices[`${price.paid_slot_min}-${price.paid_slot_max}`][price.paid_month] = {
          value: price.value,
          currency: price.currency,
        };
      });
    },

    updateCost: _.debounce(async function() {
      try {
        this.$emit('error', null);
        const data = await api.calculate.subscriptionPlan(this.numSlots, this.numMonth);
        Object.assign(this, data);
      } catch (err) {
        this.$emit('error', err);
      }
    }, 500),

    getPlanCell(numOfMonth, numSlots) {
      const price = this.prices && this.prices[numOfMonth][numSlots];
      return price && this.$n(price.value, 'currency', price.currency);
    },
  },

  computed: {
    ...mapGetters('billing', ['showSmsCounter']),

    headers() {
      return [
        {
          text: this.$t('tariff\\subscription period'),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$tc('{0} months', 1),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$tc('{0} months', 12),
          align: 'left',
          sortable: false,
        },
      ];
    },

    headersSms() {
      return [
        {
          text: this.$t('Pack'),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('Price'),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('Count of packs'),
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          align: 'center',
          sortable: false,
        },
      ];
    },

    numMonthListSelect() {
      return [
        {
          text: this.$tc('{0} months', 1),
          value: 1,
        },
        {
          text: this.$tc('{0} months', 12),
          value: 12,
        },
      ];
    },
  },

  watch: {
    numMonth() {
      this.updateCost();
    },

    numSlots() {
      this.updateCost();
    },
  },
};
</script>

<style></style>
