// @ts-nocheck
import * as geolib from 'geolib';
import { isEmpty, compact } from 'lodash';
import Uuid from '@/models/Uuid';
import uuid from 'uuid/v4';
import Trip, { TripMode } from '@/models/Trip';
import TripPoint, { TripPointStatus, TripPointType } from '@/models/TripPoint';
import WayPoint from '@/models/WayPoint';
import { ActiveRoute, RouteQuery, RouteQueryPoint } from '@/models/Way';
import { MULTIPOINT_RADIUS } from '@/const';
import { PointCoordinates } from '@/models/Location';

const ZERO_UUID = '00000000-0000-0000-0000-000000000000';

function tripPointToRoutePoint(tripPoint: TripPoint, wayPointId: Uuid | null): RouteQueryPoint {
  return {
    id: tripPoint.id,
    coordinates: {
      lat: tripPoint.location.coordinates.lat,
      lng: tripPoint.location.coordinates.lng,
    },
    type: tripPoint.type,
    stayTime: tripPoint.stayTime,
    workingHours: tripPoint.deliveryWindows.map(deliveryWindow => {
      return {
        from: deliveryWindow.from.toISOString(),
        to: deliveryWindow.to.toISOString(),
      };
    }),
    timezoneOffset: tripPoint.location.timezone.offset || 0,
    wayPointId,
  };
}

export const getWayQuery = (trip: Trip): RouteQuery => {
  const routePoints: RouteQueryPoint[] = [];

  const wayPointsByTripPointId = trip.getWayPointsByTripPointId();

  trip.tripPoints.forEach((tripPoint: TripPoint) => {
    if (tripPoint.isActive()) {
      const wayPoint = wayPointsByTripPointId.get(tripPoint.id);
      const routePoint = tripPointToRoutePoint(tripPoint, wayPoint ? wayPoint.id : null);
      routePoints.push(routePoint);
    }
  });

  let startingCoordinates: PointCoordinates | undefined;

  const currentPosition = trip.getCurrentPosition();
  if (currentPosition) {
    startingCoordinates = currentPosition;
  } else {
    const currentTripPoints = trip.getCurrentTripPoints();
    if (currentTripPoints.length) {
      startingCoordinates = currentTripPoints[0].getCoordinates();
    }
  }

  let startAt = new Date(Math.ceil(Date.now() / 300000) * 300000); // round to nearest 5 min - need it to make way query stable
  const currentTripPoints = trip.getCurrentTripPoints();

  if (currentTripPoints.length) {
    /*
    // FIXME
    const plannedCheckout = new Date(this.lastCheckinPoint.checkin_at).valueOf() + (this.lastCheckinPoint.stayTime || 0) * 1000;
    if (plannedCheckout > time) {
      time = plannedCheckout;
    }
    */
  } else if (trip.plannedAt) {
    startAt = trip.plannedAt;
  }

  if (startingCoordinates) {
    routePoints.unshift({
      id: ZERO_UUID,
      coordinates: startingCoordinates,
      type: TripPointType.start,
      stayTime: 0,
      workingHours: [],
      timezoneOffset: 0,
      wayPointId: ZERO_UUID,
    });
  }

  return {
    startAt: startAt.toISOString(),
    tripPoints: routePoints,
    optimization: trip.way.optimize,
    transportType: trip.settings?.transportType,
  };
};

export const calculateETAToPoints = (trip: Trip, activeWay: ActiveRoute | null): Map<Uuid, Date> => {
  const { tripPoints } = trip;
  const ONE_SECOND = 1000;

  const arriveById = new Map<Uuid, number>();
  let mainTime: number = Date.now();

  if (trip.isPendingOrPlanned() && trip.plannedAt) {
    mainTime = trip.plannedAt.getTime();
  } else if (activeWay?.summary?.startAt) {
    mainTime = new Date(activeWay.summary.startAt).getTime();
  } else if (trip.syncAt) {
    mainTime = trip.syncAt.getTime();
  }

  let timeArrive: number = mainTime;

  const tripPointsById = trip.getTripPointsById();

  if (tripPoints.some(tripPoint => tripPoint.isCurrent())) {
    const wayPointsByTripPointId = trip.getWayPointsByTripPointId();

    tripPoints.forEach(tripPoint => {
      if (tripPoint.stayTime && tripPoint.isCurrent()) {
        let leftStayTime = 0;

        if (trip.tripMode === TripMode.manual) {
          const checkin = tripPoint.checkins.find(checkin => !checkin.outDate);
          if (checkin) {
            leftStayTime = Math.max(0, tripPoint.stayTime - (checkin.getDuration() || 0));
          }
        } else {
          const wayPoint = wayPointsByTripPointId.get(tripPoint.id);
          if (wayPoint) {
            const checkin = wayPoint.checkins.find(checkin => !checkin.outDate);
            if (checkin) {
              leftStayTime = Math.max(0, tripPoint.stayTime - (checkin.getDuration() || 0));
            }
          }
        }

        timeArrive += leftStayTime * ONE_SECOND;
      }
    });
  } else if (activeWay?.summary?.startTripPointId) {
    const firstTripPoint = tripPointsById.get(activeWay.summary.startTripPointId);
    if (firstTripPoint) {
      arriveById.set(firstTripPoint.id, timeArrive);
      timeArrive += (firstTripPoint.stayTime || 0) * ONE_SECOND;
    }
  } else {
    const firstActiveTripPoint = trip.getFirstActiveTripPoint();
    if (firstActiveTripPoint && activeWay) {
      if (!activeWay.legs.some(leg => leg.toTripPointId === firstActiveTripPoint.id)) {
        arriveById.set(firstActiveTripPoint.id, timeArrive);
        timeArrive += (firstActiveTripPoint.stayTime || 0) * ONE_SECOND;
      }
    }
  }

  if (activeWay && activeWay.legs) {
    activeWay.legs.forEach(leg => {
      const {
        summary: { travelTime },
        toTripPointId,
      } = leg;

      const tripPoint = tripPointsById.get(toTripPointId);

      if (tripPoint && tripPoint.isActive()) {
        timeArrive += (travelTime || 0) * ONE_SECOND;
        arriveById.set(toTripPointId, timeArrive);

        timeArrive += (tripPoint.stayTime || 0) * ONE_SECOND;
      }
    });
  }

  const pointDates = new Map<Uuid, Date>();
  arriveById.forEach((value, key) => {
    pointDates.set(key, new Date(value));
  });

  let allPointsHaveEta = true;
  tripPoints.forEach(point => {
    if (!point.isFinish() && (point.isActive() || point.isWaitingForDelete())) {
      if (!allPointsHaveEta) {
        pointDates.delete(point.id);
      } else if (!pointDates.get(point.id)) {
        allPointsHaveEta = false;
        pointDates.delete(point.id);
      }
    }
  });
  return pointDates;
};

export const pathCalculator = (currentPosition: PointCoordinates, points: PointCoordinates[]): PointCoordinates[] => {
  const LIM_ANGLE = 90;
  const LIM_GIP = 20;
  const LIM_K = 1.2;
  const newPath: PointCoordinates[] = [];
  let founded = false;

  for (let i = 0; i < points.length - 1; i++) {
    const cCurr = points[i];
    const cNext = points[i + 1];

    if (founded) {
      newPath.push(cCurr);

      if (i === points.length - 2) {
        newPath.push(cNext);
      }
      continue;
    }

    const startAngle = diffCourse(currentPosition, cNext, cCurr);

    if (startAngle >= LIM_ANGLE) {
      continue;
    }

    const endAngle = diffCourse(cCurr, currentPosition, cNext);

    if (endAngle > LIM_ANGLE) {
      continue;
    }

    const gipotenuzaCurrent = geolib.getDistance(cCurr, currentPosition);
    const gipotenuzaNext = geolib.getDistance(cNext, currentPosition);

    if (gipotenuzaCurrent > LIM_GIP && gipotenuzaNext > LIM_GIP) {
      const d1 = geolib.getDistance(cCurr, cNext);

      if (d1 * LIM_K < gipotenuzaCurrent + gipotenuzaNext) {
        continue;
      }
    }

    const dist = Math.cos((startAngle * Math.PI) / 180) * gipotenuzaCurrent;
    const course = geolib.getRhumbLineBearing(cCurr, cNext);
    const projectionPoint = geolib.computeDestinationPoint(cCurr, dist, course);

    newPath.push({ lat: projectionPoint.latitude, lng: projectionPoint.longitude });

    founded = true;

    if (i === points.length - 2) {
      newPath.push(cNext);
    }
  }

  if (!founded) {
    return points;
  }

  return newPath;
};

export function cutActiveWayByPoint(currentPosition: PointCoordinates, activeRoute: ActiveRoute): ActiveRoute {
  if (!activeRoute.legs.length) {
    return activeRoute;
  }

  const leg = activeRoute.legs[0];
  const newPath = pathCalculator(currentPosition, leg.points);

  const newLength = geolib.getPathLength(newPath);
  const newTravelTime = (leg.summary.travelTime * newLength) / leg.summary.length;
  const newActiveRoute = {
    legs: [...activeRoute.legs],
    summary: activeRoute.summary,
  };

  newActiveRoute.legs[0] = {
    points: newPath,
    summary: {
      travelTime: newTravelTime,
      length: newLength,
    },
    toTripPointId: leg.toTripPointId,
  };

  return newActiveRoute;
}

function diffCourse(start: PointCoordinates, finish: PointCoordinates, coordinates: PointCoordinates) {
  const firstCourse = geolib.getRhumbLineBearing(start, coordinates);
  const secondCourse = geolib.getRhumbLineBearing(finish, coordinates);

  return Math.min(Math.abs(firstCourse - secondCourse), 360 - Math.abs(firstCourse - secondCourse));
}

export const combiningPointsToWayPoints = (tripPoints: TripPoint[], wayPoints: WayPoint[]): WayPoint[] => {
  const DEFAULT_CHECKIN_RADIUS = 150;
  const DEFAULT_CHECKOUT_RADIUS = 200;
  const mapTripPointIds = new Map<Uuid, WayPoint>();
  const tripPointsToUnite: TripPoint[] = [];

  wayPoints.forEach(wayPoint => {
    wayPoint.tripPointIds.forEach(tripPointId => {
      mapTripPointIds.set(tripPointId, wayPoint);
    });
  });

  tripPoints.forEach(tripPoint => {
    tripPointsToUnite.push(tripPoint);
  });

  const getCoordsFromTripPoints = (ids: string[]) =>
    compact(
      tripPoints.map(tripPoint => {
        if (ids.includes(tripPoint.id)) {
          return tripPoint.location.coordinates;
        }
      }),
    );

  const getDistance = (wayPointCoord: PointCoordinates, ids: string[]) => {
    const checkinRadiuses: number[] = [];
    const checkoutRadiuses: number[] = [];
    tripPoints.forEach(tripPoint => {
      if (ids.includes(tripPoint.id)) {
        const CHECKIN_RADIUS = tripPoint.radius.checkin ? tripPoint.radius.checkin : DEFAULT_CHECKIN_RADIUS;
        const CHECKOUT_RADIUS = tripPoint.radius.checkout ? tripPoint.radius.checkout : DEFAULT_CHECKOUT_RADIUS;
        const wayPointPlusCheckinRadius =
          geolib.getDistance(tripPoint.location.coordinates, wayPointCoord) + CHECKIN_RADIUS;
        const wayPointPlusCheckoutRadius =
          geolib.getDistance(tripPoint.location.coordinates, wayPointCoord) + CHECKOUT_RADIUS;
        checkinRadiuses.push(wayPointPlusCheckinRadius);
        checkoutRadiuses.push(wayPointPlusCheckoutRadius);
      }
    });

    return {
      checkinRadius: Math.round(Math.max.apply(null, checkinRadiuses)),
      checkoutRadius: Math.round(Math.max.apply(null, checkoutRadiuses)),
    };
  };

  // recalculate central position waypoint and radius chekin, checkout
  // TODO replace with recalculating for each single waypoint
  const recalculatePositions = () => {
    wayPoints.forEach(wayPoint => {
      const coords = getCoordsFromTripPoints(wayPoint.tripPointIds);

      const centerPosition = geolib.getCenter(coords) || {
        latitude: wayPoint.coordinates.lat,
        longitude: wayPoint.coordinates.lng,
      };
      wayPoint.coordinates = {
        lat: centerPosition.latitude,
        lng: centerPosition.longitude,
      };

      const maxDistance = getDistance(wayPoint.coordinates, wayPoint.tripPointIds);

      wayPoint.radius.checkin = maxDistance.checkinRadius;
      wayPoint.radius.checkout = maxDistance.checkoutRadius;
    });
  };

  const canBeUnited = (tripPoint: TripPoint): Boolean => {
    return tripPoint.status !== TripPointStatus.deleted && tripPoint.type == TripPointType.scheduled;
  };

  while (tripPointsToUnite.length) {
    const tripPoint = tripPointsToUnite[0];
    const { id, location } = tripPoint;

    const createNewWayPoint = () =>
      new WayPoint({
        id: uuid(),
        coordinates: location.coordinates,
        checkins: [],
        radius: { checkin: 0, checkout: 0 },
        tripPointIds: [id],
      });

    // check if trip point need to be removed from its waypoint because it was deleted
    if (tripPoint.status === TripPointStatus.deleted) {
      if (mapTripPointIds.has(id)) {
        const wayPoint = mapTripPointIds.get(id);
        if (wayPoint && !wayPoint.checkins.length) {
          const indexOfDeletedTripPointId = wayPoint.tripPointIds.indexOf(id);
          wayPoint.tripPointIds.splice(indexOfDeletedTripPointId, 1);
        }
      }
      mapTripPointIds.delete(id);
      const indexOfTripPoint = tripPointsToUnite.indexOf(tripPoint);
      tripPointsToUnite.splice(indexOfTripPoint, 1);
      continue;
    }

    // creating waypoint for current trippoint
    if (!mapTripPointIds.has(id)) {
      const wayPoint = createNewWayPoint();
      wayPoints.push(wayPoint);
      mapTripPointIds.set(id, wayPoint);
      recalculatePositions();
    }
    const currentWayPoint = mapTripPointIds.get(id)!;

    if (!canBeUnited(tripPoint)) {
      const indexOfTripPoint = tripPointsToUnite.indexOf(tripPoint);
      tripPointsToUnite.splice(indexOfTripPoint, 1);
      continue;
    }

    let checkTripPointIndex = 1;
    while (checkTripPointIndex < tripPointsToUnite.length) {
      const nextTripPoint = tripPointsToUnite[checkTripPointIndex];

      // check if next trip point need to be removed from current waypoint if it became single type or deleted
      if (!canBeUnited(nextTripPoint)) {
        if (currentWayPoint.tripPointIds.includes(nextTripPoint.id) && !nextTripPoint.checkins.length) {
          const indexOfDeletedTripPointId = currentWayPoint.tripPointIds.indexOf(nextTripPoint.id);

          currentWayPoint.tripPointIds.splice(indexOfDeletedTripPointId, 1);
          mapTripPointIds.delete(nextTripPoint.id);

          recalculatePositions();
        }

        checkTripPointIndex++;
        continue;
      }

      // check distance and ability to merge trippoints
      const distance = geolib.getDistance(location.coordinates, nextTripPoint.location.coordinates);
      const distanceIsOk = distance < MULTIPOINT_RADIUS;
      const alreadyUnited = currentWayPoint.tripPointIds.includes(nextTripPoint.id);

      // unite points that can be united
      // else remove points from current waupoint if it cannot be united anymore
      if (distanceIsOk && !alreadyUnited) {
        // remove next trip point from its waypoint if it exist
        if (mapTripPointIds.has(nextTripPoint.id)) {
          const nextWayPoint = mapTripPointIds.get(nextTripPoint.id);
          if (nextWayPoint && !nextTripPoint.checkins.length) {
            const indexOfDeletedTripPointId = nextWayPoint.tripPointIds.indexOf(nextTripPoint.id);
            nextWayPoint.tripPointIds.splice(indexOfDeletedTripPointId, 1);
          }
        }

        currentWayPoint.tripPointIds.push(nextTripPoint.id);
        mapTripPointIds.set(nextTripPoint.id, currentWayPoint);

        recalculatePositions();

        const indexOfTripPoint = tripPointsToUnite.indexOf(nextTripPoint);
        tripPointsToUnite.splice(indexOfTripPoint, 1);
      } else if (!distanceIsOk && alreadyUnited) {
        const indexOfDeletedTripPointId = currentWayPoint.tripPointIds.indexOf(nextTripPoint.id);
        currentWayPoint.tripPointIds.splice(indexOfDeletedTripPointId, 1);
        mapTripPointIds.delete(nextTripPoint.id);

        recalculatePositions();

        checkTripPointIndex++;
      } else if (distanceIsOk && alreadyUnited) {
        const indexOfTripPoint = tripPointsToUnite.indexOf(nextTripPoint);
        tripPointsToUnite.splice(indexOfTripPoint, 1);

        recalculatePositions();
      } else {
        checkTripPointIndex++;
      }
    }

    const indexOfTripPoint = tripPointsToUnite.indexOf(tripPoint);
    tripPointsToUnite.splice(indexOfTripPoint, 1);
  }

  wayPoints = wayPoints.filter(wayPoint => !isEmpty(wayPoint.tripPointIds));

  return wayPoints;
};
