
import Vue from 'vue';
import { sleep } from '@/lib/util';
import UploadButton from '../../../vendor/vuetify-upload-button/UploadButton.vue';
import { baseUrl } from '@/api/httpV2';
import { ValidationErrorInfo } from '@/api/import';
import XlsImportErrors from '@/components/import/XlsImportErrors.vue';

export default Vue.extend({
  name: 'ResourceImport',

  components: {
    'upload-btn': UploadButton,
    XlsImportErrors,
  },

  props: {
    title: { type: String },
    url: { type: String },
    api: { type: Function },
  },

  data() {
    return {
      showDialog: false,
      loading: false,
      error: null,
      validationErrors: [] as ValidationErrorInfo[],
    };
  },

  computed: {
    templateLink(): string {
      return baseUrl + `${this.url}/import/template?locale=` + this.$i18n.locale;
    },

    rulesLink(): string {
      return baseUrl + `${this.url}/import/rules?locale=` + this.$i18n.locale;
    },
  },

  methods: {
    openDialog() {
      this.showDialog = true;
    },

    hideModal() {
      this.showDialog = false;
    },

    cleanup() {
      this.loading = false;
      this.error = null;
      this.validationErrors = [];
    },

    async readFile(file: File) {
      try {
        this.loading = true;
        this.error = null;
        this.validationErrors = [];

        const result = await this.api(file);

        if (result.errors && result.errors.length) {
          this.validationErrors = result.errors;
        } else {
          await sleep(5000); // crutch
          this.showDialog = false;
          this.$emit('done');
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;

        if (this.$refs.uploadButton) {
          const $input = $('input[type=file]', (this.$refs.uploadButton as any).$el);
          if (/MSIE/.test(navigator.userAgent)) {
            $input.replaceWith($input.clone(true));
          } else {
            $input.val('');
          }
        }
      }
    },
  },

  watch: {
    showDialog(value) {
      if (value === false) {
        this.cleanup();
      }
    },
  },
});
