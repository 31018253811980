import { getObjectValueByPath } from 'vuetify/es5/util/helpers';

/**
 * Overrides DataIterable methods to store value (list of selected items) as list of item keys, not whole objects
 */
export default {
  provide: function() {
    return {
      toggle: this.toggle,
    };
  },

  computed: {
    selected() {
      const selected = {};

      for (let index = 0; index < this.value.length; index++) {
        const key = this.value[index];
        selected[key] = true;
      }

      return selected;
    },

    indeterminate() {
      return this.someItems && !this.everyItem;
    },

    everyItem() {
      // check if every selectable item is selected
      return (
        this.filteredItems.length &&
        this.filteredItems.filter(i => !this.unselectedItems.includes(i.id)).every(i => this.isSelected(i))
      );
    },
  },

  methods: {
    toggle(value) {
      const selected = Object.assign({}, this.selected);

      for (let index = 0; index < this.filteredItems.length; index++) {
        const key = getObjectValueByPath(this.filteredItems[index], this.itemKey);
        selected[key] = value;
      }

      this.$emit(
        'input',
        this.items.map(i => getObjectValueByPath(i, this.itemKey)).filter(key => selected[key]),
      );
    },

    createProps(item, index) {
      const props = { item, index };
      const keyProp = this.itemKey;
      const itemKey = getObjectValueByPath(item, keyProp);

      Object.defineProperty(props, 'selected', {
        get: () => this.selected[itemKey],
        set: value => {
          if (itemKey == null) {
            console.warn(`"${keyProp}" attribute must be defined for item`);
          }

          let selected = this.value.slice();
          if (value) {
            selected.push(itemKey);
          } else {
            selected = selected.filter(i => i !== itemKey);
          }
          this.$emit('input', selected);
        },
      });

      Object.defineProperty(props, 'expanded', {
        get: () => this.expanded[itemKey],
        set: value => {
          if (itemKey == null) {
            console.warn(`"${keyProp}" attribute must be defined for item`);
          }

          if (!this.expand) {
            for (const key in this.expanded) {
              this.expanded.hasOwnProperty(key) && this.$set(this.expanded, key, false);
            }
          }
          this.$set(this.expanded, itemKey, value);
        },
      });

      return props;
    },

    getSelectAllProps() {
      return {
        dark: this.dark,
        light: this.light,
        inputValue: this.everyItem,
        indeterminate: this.indeterminate,
      };
    },
  },
};
