
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import branch from '@/api/branch';

import { DemandType } from '@/models/Order';

import OrdersImport from '@/components/import/OrdersImport.vue';
import OrdersFilterDto from '@/components/filters/OrdersFilterDto';
import OrderPaymentStatusPicker from '@/components/pickers/OrderPaymentStatusPicker.vue';
import DateRangePicker from '@/components/pickers/DateRangePicker.vue';
import AccountPicker from '@/components/pickers/AccountPicker';
import NumRangePickerInOneLine from '@/components/pickers/NumRangePickerInOneLine.vue';
import ArrayParameterInput from '@/components/inputs/ArrayParameterInput.vue';
import DemandTypePicker from '@/components/pickers/DemandTypePicker.vue';
import TagsPicker from '@/components/pickers/TagsPicker.vue';

export default Vue.extend({
  name: 'OrdersFilter',

  components: {
    OrdersImport,
    OrderPaymentStatusPicker,
    DateRangePicker,
    AccountPicker,
    NumRangePickerInOneLine,
    ArrayParameterInput,
    DemandTypePicker,
    TagsPicker,
  },

  props: {
    form: {
      type: OrdersFilterDto,
      required: true,
    },
  },

  data() {
    const showFullFilter = this.form && Object.keys(this.form.toQuery()).some(k => !['search'].includes(k));

    return {
      valid: true,
      filter: new OrdersFilterDto(this.form),
      searchText: '',
      showDetails: showFullFilter,
      owners: [] as Array<any>,
    };
  },

  async created() {
    await this.getOwners();

    this.searchText = this.form.search || '';
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    demandTypes() {
      return Object.keys(DemandType).filter(type => type !== 'null');
    },
  },

  methods: {
    searchUpdate() {
      this.filter.search = this.searchText;
    },

    createOrder() {
      this.$router.push({
        name: 'orderDetails',
        params: {
          id: 'new',
          back: this.$route.path,
        },
      });
    },

    toggleVisibility() {
      this.showDetails = !this.showDetails;
    },

    reloadClick() {
      this.$emit('reload');
    },

    resetFilter() {
      this.filter = new OrdersFilterDto();
    },

    async getOwners() {
      this.owners = await branch.listMy();
    },
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.valid) {
          this.$emit('filterUpdate', this.filter);
        }
      },
    },

    valid() {
      if (this.valid) {
        this.$emit('filterUpdate', this.filter);
      }
    },

    searchText(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.searchUpdate();
      }
    },
  },
});
