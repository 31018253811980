import http from './httpV2';
import store from '@/store';

const path = '/billing';

async function infoGetPlan() {
  const res = await http.get(`${path}/info/plan`, {});
  return res.data;
}

async function infoGetSmsPlan() {
  const res = await http.get(`${path}/info/smsPlan`, {});
  return res.data;
}

async function infoGetMain() {
  const res = await http.get(`${path}/info/main`, {});
  store.commit('billing/setBillingInfo', res.data);
  return res.data;
}

async function infoGetEventLog() {
  const res = await http.get(`${path}/info/eventLog`, {});
  return res.data;
}

async function getAdvancePayments() {
  const res = await http.get(`${path}/info/advancePayments`, {});
  return res.data;
}

async function infoGetInvoiceList() {
  const res = await http.get(`${path}/info/invoices`, {});
  if (res.data && res.data.list) {
    res.data.list.forEach(item => {
      if (item.url) {
        const url = item.url.split('/invoice_');
        item.actUrl = `${url[0]}/act_${url[1]}`;
      }
    });
  }
  return res.data;
}

async function infoGetCountOfPaidSms() {
  const res = await http.get(`${path}/info/paidSms/count`, {});
  store.commit('billing/setPaidSms', res.data.count);
  return res.data;
}

async function calculateSubscriptionPlan(slots, month) {
  const res = await http.post(`${path}/calculate/subscriptionPlan`, { slots, month });
  return res.data;
}

async function calculateSlots(slots) {
  const res = await http.post(`${path}/calculate/slots`, { slots });
  return res.data;
}

async function createCard(data) {
  const res = await http.post(`${path}/card`, data);
  return res.data;
}

async function listCards() {
  const res = await http.get(`${path}/cards`);
  return res.data;
}

async function removeCard(data) {
  const res = await http.post(`${path}/card/delete`, data);
  return res.data;
}

async function purchasePaySubscription(data) {
  const res = await http.post(`${path}/purchase/paySubscription`, data);
  return res.data;
}

async function purchaseBuySlots(data) {
  const res = await http.post(`${path}/purchase/buySlots`, data);
  return res.data;
}

async function purchaseSellSlots(data) {
  const res = await http.post(`${path}/purchase/sellSlots`, data);
  return res.data;
}

async function purchaseBuySmsPackages(data) {
  const res = await http.post(`${path}/purchase/buySmsPackages`, data);
  return res.data;
}

async function updateInvoice(data) {
  const res = await http.post(`${path}/invoice`, data);
  return res.data;
}

async function getInvoice(data) {
  const res = await http.get(`${path}/invoice`, data);
  return res.data;
}

async function createInvoice(data) {
  const res = await http.post(`${path}/invoice/createInvoice`, data);
  return res.data;
}

async function sendEmailInvoice(data) {
  const res = await http.post(`${path}/invoice/sendEmail`, data);
  return res.data;
}

async function changeAutoProlongationPaymentMethod(data) {
  const res = await http.post(`${path}/paymentMethod/changeAutoProlongation`, data);
  return res.data;
}

async function changeDefaultPaymentMethod(data) {
  const res = await http.post(`${path}/paymentMethod/changeDefault`, data);
  return res.data;
}

// Tests
async function clearState() {
  const res = await http.post(`${path}/test/clearState`);
  return res.data;
}

async function enableTrialMode() {
  const res = await http.post(`${path}/test/enableTrialMode`);
  return res.data;
}

async function enableBlockMode() {
  const res = await http.post(`${path}/test/enableBlockMode`);
  return res.data;
}

async function enableRestrictedMode() {
  const res = await http.post(`${path}/test/enableRestrictedMode`);
  return res.data;
}

async function enableConfidenceMode() {
  const res = await http.post(`${path}/test/enableConfidenceMode`);
  return res.data;
}

async function changeTime(data) {
  const res = await http.post(`${path}/test/changeTime`, data);
  return res.data;
}

async function getTime() {
  const res = await http.get(`${path}/test/getTime`);
  return res.data;
}

export default {
  info: {
    getPlan: infoGetPlan,
    getSmsPlan: infoGetSmsPlan,
    infoGetMain: infoGetMain,
    getEventLog: infoGetEventLog,
    getInvoiceList: infoGetInvoiceList,
    getAdvancePayments: getAdvancePayments,
    getCountOfPaidSms: infoGetCountOfPaidSms,
  },
  calculate: {
    subscriptionPlan: calculateSubscriptionPlan,
    slots: calculateSlots,
  },
  card: {
    create: createCard,
    list: listCards,
    remove: removeCard,
  },
  invoice: {
    create: createInvoice,
    update: updateInvoice,
    get: getInvoice,
    sendEmail: sendEmailInvoice,
  },
  paymentMethod: {
    changeAutoProlongation: changeAutoProlongationPaymentMethod,
    changeDefault: changeDefaultPaymentMethod,
  },
  purchase: {
    paySubscription: purchasePaySubscription,
    buySlots: purchaseBuySlots,
    sellSlots: purchaseSellSlots,
    buySmsPackages: purchaseBuySmsPackages,
  },
  test: {
    enableTrialMode: enableTrialMode,
    enableBlockMode: enableBlockMode,
    enableRestrictedMode: enableRestrictedMode,
    enableConfidenceMode: enableConfidenceMode,
    changeTime: changeTime,
    getTime: getTime,
    clearState: clearState,
  },
};
