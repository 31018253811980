import Sortable from 'sortablejs';

/* eslint-disable */

const SortableDirective = {
  bind(el, binding, vnode) {
    const sortableElement = el;

    const options = {
      handle: '.sortHandle',
      draggable: '.sortItem',
      animation: 150,
      onEnd: function (event) {
        const data = { oldIndex: event.oldIndex, newIndex: event.newIndex };
        if (vnode.child) {
          vnode.child.$emit('sorted', data);
        }
        if (typeof binding.value === 'function') {
          binding.value(data);
        } else if (binding.value && binding.value.sorted) {
          binding.value.sorted(data);
        }
      }
    };

    const sortable = Sortable.create(sortableElement, options);
    binding.sortable = sortable;
  },

  unbind(el, binding, vnode) {
    if (binding.sortable) {
      binding.sortable.destroy();
    }
  }
};

export default SortableDirective;
