
import Vue from 'vue';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';

import { baseUrl } from '@/api/httpV2';
import tripsApi from '@/api/tripNew';
import ordersApi from '@/api/orders';
import accountsApi from '@/api/account';
import transportsApi from '@/api/transports';
import { createDistributionManual, getTripResources } from '@/api/distributions';
import { ValidationErrorInfo } from '@/api/import';

import Uuid from '@/models/Uuid';
import { DemandType, Order, Tag } from '@/models/Order';
import { IDeliveryWindowsItem } from '@/models/TripPoint';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import GridCrud, { defaultPagination } from '@/components/grid/GridCrud';
import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import DistributionOptions from '@/components/distribution/DistributionOptions.vue';
import XlsImportErrors from '@/components/import/XlsImportErrors.vue';
import { FieldsOptions } from '@/components/grid/Options';
import DateRangePicker from '@/components/pickers/DateRangePicker.vue';
import DeliveryWindows from '@/components/trip/grid/DeliveryWindows';
import TripsGrid from '@/components/trip/TripsGrid.vue';
import TagComponent from '@/components/Tag';

export default Vue.extend({
  name: 'DistributionAdditional',

  components: {
    Breadcrumbs,
    Checkbox,
    DateRangePicker,
    DistributionOptions,
    XlsImportErrors,
    GridCrud,
    GridTh,
    GridSelectAll,
    DeliveryWindows,
    TripsGrid,
    Tag: TagComponent,
  },

  data() {
    return {
      loading: false,
      progress: 0,
      error: null,
      validationErrors: [] as ValidationErrorInfo[],
      file: null as File | null,
      planningTime: 20,
      active: null,

      settings: {
        configuration: 'optimizeCarsThenSingleLocationGrouping',
        tripStartTimeStrategy: 'earliestFinish',
        planningTime: 20,
        assumptions: {
          disableTrafficJams: false,
          flightDistance: false,
          disableCompatibility: false,
          disableCapacity: false,
          sameOrderTimeWindow: false,
          expandShiftTimeWindow: false,
          optimizeInAQuickAndQualitativeWay: false,
        },
        ordersDurationMultiplier: 1,
        replanStrategy: {
          reorder: true,
          planNewOrders: true,
          createNewTrips: true,
        },
      },

      filter: {
        date: {
          from: null,
          to: null,
        },
      },

      pagination: {
        trips: { ...defaultPagination },
        orders: { ...defaultPagination },
        accounts: { ...defaultPagination },
        transport: { ...defaultPagination },
      },

      selected: {
        trips: [] as string[],
        orders: [] as string[],
        accounts: [] as string[],
        transport: [] as string[],
      },

      tripResources: {
        orders: new Set<Uuid>(),
        accounts: new Set<Uuid>(),
        transport: new Set<Uuid>(),
      },

      selectAllLoading: {
        trips: false,
        orders: false,
        accounts: false,
        transport: false,
      },
      breadcrumbs: [
        {
          text: this.$t('Planning'),
          href: { name: 'distributionList' },
        },
        {
          text: this.$t('Create planning'),
          href: { name: 'distributionCreate' },
        },
        {
          text: this.$t('Replanning'),
          href: { name: 'distributionAdditional' },
          disabled: true,
        },
      ],
    };
  },

  async created() {
    await this.updateTags();
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('tags', ['tags']),

    templateLink(): string {
      return baseUrl + '/distributions/template?locale=' + this.$i18n.locale;
    },

    rulesLink(): string {
      return baseUrl + '/distributions/rules?locale=' + this.$i18n.locale;
    },

    enableRun(): boolean {
      return Boolean(this.selected.trips.length);
    },

    tripsApi(): Function {
      return tripsApi.list;
    },

    tripsFields(): FieldsOptions {
      return {
        dbId: {
          label: 'ID',
        },
        id: {
          label: 'ID',
        },
      };
    },

    ordersApi(): Function {
      return ordersApi.getOrders;
    },

    ordersFields(): FieldsOptions {
      return {
        number: {
          label: 'OrdersThs.Number',
          align: 'center',
          sortable: true,
        },
        pickupAddress: {
          label: 'OrdersThs.PickupAddress',
          format: (_: never, item: Order): string => {
            const demand = item?.demands?.find(
              demand => demand.type === DemandType.pickup || demand.type === DemandType.work,
            );
            const addresses = demand?.location?.addresses;
            const coordinates = demand?.location?.coordinates;
            return Array.isArray(addresses) && addresses.length
              ? addresses[0].address
              : coordinates
              ? `${coordinates.lat}:${coordinates.lng}`
              : '';
          },
        },
        pickupDeliveryWindow: {
          label: 'OrdersThs.PickupDeliveryWindows',
          format: (_: never, item: Order): IDeliveryWindowsItem | null => {
            const demand = item?.demands?.find(
              demand => demand.type === DemandType.pickup || demand.type === DemandType.work,
            );

            if (demand?.deliveryWindows?.length) {
              return {
                deliveryWindows: demand.deliveryWindows,
                missDeliveryWindow: false,
                showDate: true,
                timezoneOffset: null,
              };
            }

            return null;
          },
        },
        dropAddress: {
          label: 'OrdersThs.DropAddress',
          format: (_: never, item: Order) => {
            const demand = item?.demands?.find(demand => demand.type === DemandType.drop);
            const addresses = demand?.location?.addresses;
            const coordinates = demand?.location?.coordinates;
            return Array.isArray(addresses) && addresses.length
              ? addresses[0].address
              : coordinates
              ? `${coordinates.lat}:${coordinates.lng}`
              : '';
          },
        },
        dropDeliveryWindow: {
          label: 'OrdersThs.DropDeliveryWindows',
          format: (_: never, item: Order): IDeliveryWindowsItem | null => {
            const demand = item?.demands?.find(demand => demand.type === DemandType.drop);

            if (demand?.deliveryWindows?.length) {
              return {
                deliveryWindows: demand.deliveryWindows,
                missDeliveryWindow: false,
                showDate: true,
                timezoneOffset: null,
              };
            }

            return null;
          },
        },
        mass: {
          label: 'OrdersThs.Mass',
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.capacity?.mass}` : '';
          },
        },
        volume: {
          label: 'OrdersThs.Volume',
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.capacity?.volume}` : '';
          },
        },
        length: {
          label: 'OrdersThs.Length',
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.length}` : '';
          },
        },
        width: {
          label: 'OrdersThs.Width',
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.width}` : '';
          },
        },
        height: {
          label: 'OrdersThs.Height',
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.height}` : '';
          },
        },
      };
    },

    accountsApi(): Function {
      return accountsApi.list;
    },

    accountsFields(): FieldsOptions {
      return {
        fullName: {
          label: 'Employee',
          sortable: true,
          defaultSort: true,
        },
        jobTitle: {
          label: 'Job title',
          sortable: true,
        },
        email: {
          label: 'E-Mail',
          sortable: true,
        },
        phone: {
          label: 'Phone',
          sortable: true,
        },
        status: {
          label: 'Status',
          sortable: true,
          format: (v: string) => {
            return this.$t(`account_status.${v.toLowerCase()}`);
          },
        },
      };
    },

    transportApi(): Function {
      return transportsApi.getTransports;
    },

    transportFields(): FieldsOptions {
      return {
        name: {
          label: 'Name',
          align: 'center',
          sortable: true,
          defaultSort: true,
        },
        number: {
          label: 'Number',
          align: 'center',
          sortable: true,
        },
        mass: {
          label: 'Mass',
          align: 'center',
          sortable: false,
        },
        volume: {
          label: 'Volume',
          align: 'center',
          sortable: false,
        },
        capacityX: {
          label: 'CapacityX',
          align: 'center',
          sortable: false,
        },
        capacityY: {
          label: 'CapacityY',
          align: 'center',
          sortable: false,
        },
        capacityZ: {
          label: 'CapacityZ',
          align: 'center',
          sortable: false,
        },
        length: {
          label: 'Length',
          align: 'center',
          sortable: false,
        },
        height: {
          label: 'Height',
          align: 'center',
          sortable: false,
        },
      };
    },

    ordersDurationMultiplier: {
      get: function(): number {
        return this.settings.ordersDurationMultiplier;
      },
      set: function(value: string) {
        this.settings.ordersDurationMultiplier = Number(value) || 1;
      },
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),
    ...mapActions('tags', ['updateTags']),

    async runPlan() {
      try {
        this.loading = true;
        this.error = null;
        this.validationErrors = [];

        const data = {
          trips: this.selected.trips.map(id => ({ id })),
          executors: Array.from(this.tripResources.accounts.keys()).map(id => ({ id })),
          transports: Array.from(this.tripResources.transport.keys()).map(id => ({ id })),
          orders: Array.from(this.tripResources.orders.keys()).map(id => ({ id })),
          settings: this.settings,
        };

        this.selected.accounts.forEach(id => this.tripResources.accounts.has(id) || data.executors.push({ id }));
        this.selected.transport.forEach(id => this.tripResources.transport.has(id) || data.transports.push({ id }));
        this.selected.orders.forEach(id => this.tripResources.orders.has(id) || data.orders.push({ id }));

        const result = await createDistributionManual(data);

        if (result.errors && result.errors.length) {
          this.validationErrors = result.errors;
        } else {
          this.showSnackbar(this.$t('Planning has been created'));
          this.$router.push({ name: 'distributionList' });
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async selectAllTrips(value: boolean) {
      if (value !== true) {
        this.selected.trips = [];
        return;
      }

      try {
        this.selectAllLoading.trips = true;

        const trips = await tripsApi.list({
          select: ['id'],
          filter: this.filter,
          rowsPerPage: -1,
          isFull: true,
        });

        this.selected.trips = trips.result.map(trip => trip.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading.trips = false;
      }
    },

    async selectAllOrders(value: boolean) {
      if (value !== true) {
        this.selected.orders = [];
        return;
      }

      try {
        this.selectAllLoading.orders = true;

        const orders = await ordersApi.getOrders({
          select: ['id'],
          filter: this.filter,
          rowsPerPage: -1,
          isFull: true,
        });

        this.selected.orders = orders.result.map(order => order.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading.orders = false;
      }
    },

    async selectAllAccounts(value: boolean) {
      if (value !== true) {
        this.selected.accounts = [];
        return;
      }

      try {
        this.selectAllLoading.accounts = true;

        const accounts = await accountsApi.list({
          select: ['id'],
          rowsPerPage: -1,
          permissionName: 'distribution',
        });

        this.selected.accounts = accounts.result.map(account => account.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading.accounts = false;
      }
    },

    async selectAllTransport(value: boolean) {
      if (value !== true) {
        this.selected.transport = [];
        return;
      }

      try {
        this.selectAllLoading.transport = true;

        const transports = await transportsApi.getTransports({
          select: ['id'],
          rowsPerPage: -1,
          isFull: true,
        });

        this.selected.transport = transports.result.map(transport => transport.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading.transport = false;
      }
    },

    setTripResources(data: any) {
      this.tripResources = {
        accounts: new Set(data.accounts),
        orders: new Set(data.orders),
        transport: new Set(data.transports),
      };
    },

    getItemTags(tagIds: string[]): Tag[] {
      if (tagIds) {
        return this.tags.filter((item: Tag) => tagIds.includes(item.id));
      }

      return [];
    },
  },

  watch: {
    'selected.trips': {
      deep: true,
      async handler() {
        try {
          this.setTripResources(await getTripResources(this.selected.trips));
        } catch (err) {
          console.error(err);
        }
      },
    },
  },
});
