<template>
  <v-autocomplete
    :label="$t('TransportPage.title')"
    prepend-icon="mdi-car-info"
    :items="transports"
    :value="value"
    @input="onInput"
    :clearable="clearable"
    :disabled="disabled"
  >
    <template #item="{ item }">
      <v-icon class="mr-1">{{ item.icon }}</v-icon>
      {{ item.text }}
    </template>
  </v-autocomplete>
</template>

<script>
import Vue from 'vue';
import transports from '@/api/transports';
import { TRANSPORT_ICONS } from '@/components/pickers/TransportTypePicker';

export default Vue.extend({
  name: 'TransportPicker',

  data() {
    return {
      items: [],
    };
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
  },

  async created() {
    const { result } = await transports.getTransports();
    this.items = result;
  },

  computed: {
    transports() {
      return this.items.map(ts => ({
        text: `${ts.name} ${ts.number || ''}`,
        icon: TRANSPORT_ICONS[ts.type],
        value: ts.id,
      }));
    },
  },

  methods: {
    onInput(id) {
      const itemData = this.items.find(item => item.id === id);

      if (itemData && itemData.type) {
        this.$emit('type', itemData.type);
      }

      this.$emit('input', id || null);
    },
  },
});
</script>
