
import Vue from 'vue';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { addDays } from 'date-fns';

import uuid from 'uuid/v4';

import tripApi, { TripUpdateRequestDto } from '@/api/tripNew';

import TripPoint, { TripPointStatus, TripPointType, getIntervalDuration, TripPointCheckin } from '@/models/TripPoint';
import { Contact } from '@/models/Contact';
import Trip, { TripMode, TripStatus } from '@/models/Trip';
import WayPoint, { WayPointCheckin } from '@/models/WayPoint';
import { Tag } from '@/models/Order';
import { PointFunctions } from '@/models/PointFunctions';
import TripChange from '@/models/TripChange';

import Grid, { GridTh } from '@/components/grid/Grid';
import ContactsInfoForTripPointInfo from '@/components/ContactsInfoForTripPointInfo.vue';
import ShareDialog from '@/components/modals/ShareDialog.vue';
import TimeWithOptionalDay from '@/components/TimeWithOptionalDay';
import Checkpoints from '@/components/trip/grid/Checkpoints';
import DeliveryWindows from '@/components/trip/grid/DeliveryWindows';
import ImageFullCarousel from '@/components/modals/ImageFullCarousel.vue';
import TagComponent from '@/components/Tag';

export default Vue.extend({
  name: 'TripPointInfo',

  props: {
    tripPoint: Object as () => TripPoint,
    trip: Object as () => Trip,
  },

  components: {
    ContactsInfoForTripPointInfo,
    Grid,
    GridTh,
    TimeWithOptionalDay,
    ImageFullCarousel,
    Tag: TagComponent,
    ShareDialog,
    Checkpoints,
    DeliveryWindows,
  },

  data() {
    return {
      activeTab: 0,
      tabs: ['details', 'photo'],

      tripPointStatus: TripPointStatus,
      tripPointType: TripPointType,
      tripMode: TripMode,

      showCarousel: false,
      carouselCurrent: null as number | null,

      shareDialog: false,

      showFunctions: false,
      canEditFunctions: false,
      stringOriginalFunctions: null as string | null,
      functions: {} as PointFunctions,
      droplistValue: null as number | null,
    };
  },

  created() {
    this.updateTags();
    this.getFunctions();
  },

  computed: {
    ...mapState('tags', ['tags']),
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    contacts(): Contact[] {
      let contacts: Contact[] = [];
      contacts = contacts.concat(this.tripPoint.contacts);
      contacts = contacts.concat(this.tripPoint.placeLink?.contacts || []);

      return contacts;
    },

    carouselItems(): Array<{ id: number; src: string }> {
      return this.tripPoint.attachFiles.map(file => {
        return { id: file.id, src: file.fileUri.original };
      });
    },

    wayPointForTripPoint(): WayPoint | undefined {
      return this.trip.wayPoints.find(wayPoint => wayPoint.tripPointIds.includes(this.tripPoint.id))!;
    },

    wayPointCheckins(): WayPointCheckin[] | null {
      const wayPoint = this.wayPointForTripPoint;

      if (wayPoint) {
        return this.trip.tripMode === TripMode.manual
          ? wayPoint.checkins
          : wayPoint.checkins.filter(wayPointCheckin => {
              return this.tripPoint.checkins.some(tripPointCheckin => tripPointCheckin.isInside(wayPointCheckin));
            });
      } else {
        return null;
      }
    },

    summaryTimeInPoint(): number | null {
      const checkins = this.wayPointCheckins;

      if (checkins && checkins.length) {
        return checkins.reduce((sum, checkin) => {
          let duration: number;

          if (checkin.isMarked()) {
            duration = 0;
          } else if (checkin.inDate && !checkin.outDate) {
            duration = getIntervalDuration({ startDate: checkin.inDate, finishDate: null }) || 0;
          } else {
            duration = checkin.getDuration()!;
          }

          return sum + duration;
        }, 0);
      } else {
        return null;
      }
    },

    fields(): any {
      return {
        type: {
          label: 'type',
          align: 'center',
          sortable: false,
        },
        orderNumber: {
          label: 'orderNumber',
          align: 'center',
          sortable: false,
        },
        clientName: {
          label: 'clientName',
          align: 'center',
          sortable: false,
        },
        contractor: {
          label: 'Contractor',
          align: 'center',
          sortable: false,
        },
        comment: {
          label: 'Comment',
          align: 'center',
          sortable: false,
        },
        address: {
          label: 'address',
          align: 'center',
          sortable: false,
        },
        volume: {
          label: 'volume',
          align: 'center',
          sortable: false,
        },
        name: {
          label: 'name',
          align: 'center',
          sortable: false,
        },
        price: {
          label: 'price',
          align: 'center',
          sortable: false,
        },
        amount: {
          label: 'amount',
          align: 'center',
          sortable: false,
        },
        mass: {
          label: 'mass',
          align: 'center',
          sortable: false,
        },
        length: {
          label: 'length',
          align: 'center',
          sortable: false,
        },
        width: {
          label: 'width',
          align: 'center',
          sortable: false,
        },
        height: {
          label: 'height',
          align: 'center',
          sortable: false,
        },
      };
    },

    demandRows(): Array<any> {
      const results: Array<any> = [];
      const tripPoint = this.tripPoint;

      if (tripPoint && tripPoint.demands) {
        tripPoint.demands.forEach(demand => {
          results.push({
            type: demand.type,
            tagIds: demand.tagIds || [],
            contractor: demand.contractor,
            comment: demand.order.comment,
            orderNumber: demand.order.number,
            clientName: demand.place && demand.place.title,
            address: tripPoint.getAddress(),
            cargos: demand.cargos,
          });
        });
      }

      return results;
    },

    isStayTimeExceeded(): boolean {
      return (this.tripPoint.stayTimeExceeded || 0) > 0;
    },

    isWorkTimeExceeded(): boolean {
      return (this.tripPoint.workTimeExceeded || 0) > 0;
    },

    canShare(): boolean {
      return this.trip && this.tripPoint ? !this.trip.isDeleted() && this.tripPoint.canBeShared() : false;
    },

    isManual(): boolean {
      return this.trip.tripMode === 'manual';
    },

    isScheduled(): boolean {
      return TripPointType.scheduled === this.tripPoint.type;
    },

    haveCorrectStatuses(): boolean {
      let haveFinishedAt = false;

      if (this.trip.finishedAt) {
        haveFinishedAt = addDays(new Date(this.trip.finishedAt), 1) > new Date();
      }

      return (
        TripStatus.active === this.trip.status ||
        ([TripStatus.manuallyFinished, TripStatus.finished].includes(this.trip.status) && haveFinishedAt)
      );
    },
  },

  methods: {
    ...mapActions('tags', ['updateTags']),
    ...mapMutations('site', ['showSnackbar', 'showErrorSnackbar']),

    openCarousel(fileId: number) {
      this.carouselCurrent = fileId;
      this.showCarousel = true;
    },

    showShareDialog() {
      this.shareDialog = true;
    },

    closeShareDialog() {
      this.shareDialog = false;
    },

    getItemTags(tagIds: string[]): Tag[] {
      if (tagIds) {
        return this.tags.filter((item: Tag) => tagIds.includes(item.id));
      }

      return [];
    },

    getFunctions() {
      if (this.tripPoint && this.tripPoint.functions) {
        const fs = new PointFunctions(this.tripPoint.functions);

        if (fs) {
          this.stringOriginalFunctions = JSON.stringify(fs);

          this.functions = fs;

          if (fs.droplist && Array.isArray(fs.droplist) && fs.droplist.length) {
            const selectedDroplist = fs.droplist.find(el => el.selected);

            if (selectedDroplist) {
              this.droplistValue = selectedDroplist.id;
            }
          }
        }
      }
    },

    refreshFunctions() {
      this.canEditFunctions = false;

      if (this.stringOriginalFunctions) {
        const originalFs = JSON.parse(this.stringOriginalFunctions);

        this.functions = originalFs;

        if (
          originalFs.droplist &&
          Array.isArray(originalFs.droplist) &&
          originalFs.droplist.length &&
          Object.keys(originalFs.droplist.find((el: any) => el.selected) || {}).length
        ) {
          this.droplistValue = originalFs.droplist.find((el: any) => el.selected).id;
        }
      }
    },

    getPointChangeData(info: any): TripChange {
      return {
        onAllSide: true,
        external_id: uuid(),
        type: 'pointChange',
        info,
      };
    },

    async saveFunctions() {
      try {
        if (this.trip && this.trip.dbId) {
          if (this.functions.droplist && typeof this.droplistValue === 'number') {
            for (const el of this.functions.droplist) {
              if (el.id === this.droplistValue) {
                el.selected = true;
              } else {
                el.selected = false;
              }
            }
          }

          const changes: TripUpdateRequestDto = {
            changes: [
              this.getPointChangeData({
                external_id: this.tripPoint.id,
                functions: this.functions,
              }),
            ],
          };

          await tripApi.updateTrip(this.trip.dbId, changes);

          this.showSnackbar(this.$t('Assignments to the point have been changed'));
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.canEditFunctions = false;
      }
    },

    async updateTripPointCheckinsDates(checkin: TripPointCheckin) {
      try {
        if (this.trip && this.trip.dbId) {
          // if point has checkins, we change the correct checkin, otherwise, we add a new checkin
          const updatedCheckins = this.tripPoint?.checkins?.length
            ? this.tripPoint.checkins.map(e => (e.id === checkin.id ? checkin : e))
            : [checkin];

          const changes: TripUpdateRequestDto = {
            changes: [
              this.getPointChangeData({
                external_id: this.tripPoint.id,
                checkins: updatedCheckins,
              }),
            ],
          };

          if (this.tripPoint.status !== TripPointStatus.passed) {
            changes.changes.push(
              this.getPointChangeData({
                external_id: this.tripPoint.id,
                status: TripPointStatus.passed,
              }),
            );
          }

          await tripApi.updateTrip(this.trip.dbId, changes);

          this.$set(this.tripPoint, 'checkins', updatedCheckins);
          this.$set(this.tripPoint, 'status', TripPointStatus.passed);

          this.showSnackbar(this.$t('Work time at the point has been changed'));
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.canEditFunctions = false;
      }
    },
  },
});
