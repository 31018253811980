<template>
  <div>
    <v-snackbar :timeout="2000" :top="true" v-model="snackbar">
      {{ snackbarText }}
      <v-btn flat color="pink" @click.native="snackbar = false">
        {{ $t('Close') }}
      </v-btn>
    </v-snackbar>
    <v-card>
      <v-card-text class="text-xs-center">
        <h3>{{ $t('Sms and radar') }}</h3>
      </v-card-text>
      <v-data-table :headers="headers" :items="smsGrid" hide-actions>
        <template slot="items" slot-scope="props">
          <td>{{ $tc('{0} sms and radar', props.item.pack) }}</td>
          <td>{{ $n(props.item.price, 'currency', 'RUB') }}</td>
          <td>
            <v-layout justify-center mb-2>
              <NumberPicker :min="0" v-model="props.item.count" />
            </v-layout>
          </td>
        </template>
      </v-data-table>
      <v-card-actions right>
        {{ $t('Tariffs SMS+radar in the Russia') }}.
        <v-spacer />
        <v-btn color="primary" :disabled="isPackagesEmpty" @click="toggleDialog">
          {{ $t('Buy') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <BuySmsDialog
      v-if="showDialog"
      :packages="packages"
      :sms-plan="smsPlan"
      @buy-packages="onBuyPackages"
      @create-invoice="onCreateInvoice"
      @close="toggleDialog"
    />
  </div>
</template>

<script>
import api from '@/api/billing';

import NumberPicker from '@/components/pickers/NumberPicker';
import BuySmsDialog from '@/components/billing/BuySmsPackDialog';

export default {
  components: { NumberPicker, BuySmsDialog },

  data: () => ({
    smsGrid: [],
    smsPlan: null,
    selectedSmsPack: null,
    showDialog: false,
    snackbar: false,
    snackbarText: '',
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t('Pack'),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('Package price'),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('Count of packs'),
          align: 'center',
          sortable: false,
        },
      ];
    },

    packages() {
      return this.smsGrid
        .filter(item => item.count > 0)
        .map(item => ({
          pack: item.pack,
          count: item.count,
        }));
    },

    isPackagesEmpty() {
      return !Array.isArray(this.packages) || !this.packages.length;
    },
  },

  methods: {
    async getSmsPlan() {
      const data = await api.info.getSmsPlan();
      this.smsPlan = data;

      for (const pack in data) {
        this.smsGrid.push({
          pack,
          price: data[pack],
          count: 0,
        });
      }
    },

    toggleDialog() {
      this.showDialog = !this.showDialog;
    },

    onBuyPackages() {
      this.snackbar = false;
      this.snackbarText = this.$t('Sms packages paid successfully');
      this.snackbar = true;
    },

    onCreateInvoice(number) {
      this.snackbar = false;
      this.snackbarText = this.$t('Invoice #{0} was generated', [number]);
      this.snackbar = true;
    },
  },

  async created() {
    await this.getSmsPlan();
  },
};
</script>
