import { Dictionary } from 'vue-router/types/router';

class ContractorsFilterDto {
  search: string | null = null;

  constructor(data?: Partial<ContractorsFilterDto>) {
    Object.assign(this, data);
  }

  fromQuery(query: Dictionary<string | (string | null)[]>) {
    const data = {
      search: query.search,
    };

    Object.assign(this, data);

    return this;
  }

  toQuery(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      search: this.search,
    };

    for (const i in query) {
      if (query[i] === null || query[i] === undefined || (query[i] instanceof Array && query[i]?.length === 0)) {
        delete query[i];
      }
    }

    return query as Dictionary<string | string[]>;
  }

  toHttpParams() {
    return this.toQuery();
  }
}

export default ContractorsFilterDto;
