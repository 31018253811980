<template functional>
  <div v-if="$options.methods.getContacts(props) && $options.methods.getContacts(props).length" v-bind="data.attrs">
    <v-layout v-for="contact in $options.methods.getContacts(props)" :key="contact.id">
      <v-flex>
        <v-expansion-panel class="primary-panel my-1">
          <v-expansion-panel-content color="primary">
            <template v-slot:header>
              <div>{{ contact.name || contact.phones[0].phone || '' }}</div>
            </template>
            <v-card style="padding: 5px 20px">
              <v-layout v-if="contact.name">
                <v-flex xs5>
                  {{ parent.$t('Name') }}
                </v-flex>
                <v-flex xs7>
                  {{ contact.name }}
                </v-flex>
              </v-layout>

              <v-layout v-if="contact.jobTitle">
                <v-flex xs5>
                  {{ parent.$t('Job title') }}
                </v-flex>
                <v-flex xs7>
                  {{ contact.jobTitle }}
                </v-flex>
              </v-layout>

              <v-layout v-if="contact.note" align-baseline>
                <v-flex xs5>
                  {{ parent.$t('Note') }}
                </v-flex>
                <v-flex xs7>
                  <v-textarea :value="contact.note" disabled rows="1" auto-grow />
                </v-flex>
              </v-layout>

              <template v-for="phoneNumber in contact.phones">
                <v-layout :key="phoneNumber.id">
                  <v-flex xs5>
                    {{ parent.$t('Phone') }}
                  </v-flex>
                  <v-flex xs7>
                    {{ phoneNumber.phone }}
                  </v-flex>
                </v-layout>
              </template>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getContacts(props) {
      let result = [];
      if (props && props.value && Array.isArray(props.value)) {
        result = props.value;
      }
      return result;
    },
  },
};
</script>

<style>
.primary-panel.v-expansion-panel {
  box-shadow: none;
  border: 1px solid grey;
}

.primary-panel .v-expansion-panel__header {
  background: white;
  color: rgba(0, 0, 0, 0.54);
  font-weight: bold;
}
</style>
