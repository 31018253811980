
import Vue from 'vue';
import { mapMutations } from 'vuex';
import MoveDemandDialog from '@/components/modals/MoveDemandDialog.vue';
import { PointsListItemDto } from '@/api/tripPoints';

export default Vue.extend({
  name: 'PointSettingsBtn',

  components: { MoveDemandDialog },

  props: {
    item: {
      type: Object as () => PointsListItemDto,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    moveDemandDialog: false,
    moveOrderDialog: false,
    movePointDialog: false,
  }),

  computed: {
    settings(): Array<any> {
      const result = [] as Array<any>;

      if (this.item.demand && this.item.demand.type) {
        result.push({
          title: this.$t('Move demand'),
          onClick: this.moveDemand,
        });

        result.push({
          title: this.$t('Move order'),
          onClick: this.moveOrder,
        });
      } else if (this.item.tripPoint) {
        result.push({
          title: this.$t('Move point'),
          onClick: this.movePoint,
        });
      }

      return result;
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),

    moveDemand() {
      this.moveDemandDialog = true;
    },

    moveOrder() {
      this.moveOrderDialog = true;
    },

    movePoint() {
      this.movePointDialog = true;
    },

    onDemandMoved({ tripDbId, tripPointIds }: { tripDbId?: number; tripPointIds?: string[] } = {}) {
      this.moveDemandDialog = false;
      this.showSnackbar(this.$t('Demand has been moved'));
      this.$router.push(`/trips/${tripDbId}#${tripPointIds?.length ? tripPointIds[0] : undefined}`);
    },

    onOrderMoved({ tripDbId, tripPointIds }: { tripDbId?: number; tripPointIds?: string[] } = {}) {
      this.moveOrderDialog = false;
      this.showSnackbar(this.$t('Order has been moved'));
      this.$router.push(`/trips/${tripDbId}#${tripPointIds?.length ? tripPointIds[0] : undefined}`);
    },

    onPointMoved({ tripDbId, tripPointIds }: { tripDbId?: number; tripPointIds?: string[] } = {}) {
      this.movePointDialog = false;
      this.showSnackbar(this.$t('Point has been moved'));
      this.$router.push(`/trips/${tripDbId}#${tripPointIds?.length ? tripPointIds[0] : undefined}`);
    },
  },
});
