
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { MetaInfo } from 'vue-meta';
import { Params } from '@/api/httpV2';

import Grid from '@/components/grid/GridCrud';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';

import { Role } from '@/models/Role';

import api from '@/api/roles';

export default Vue.extend({
  name: 'RolesList',

  metaInfo(): MetaInfo {
    return {
      title: String(this.$i18n.t('roles.list.title')),
    };
  },

  components: {
    Grid,
    DeleteDialog,
  },

  data() {
    return {
      isLoading: false,
      filter: '',
      pagination: {} as {
        page: number | null;
        rowsPerPage: number | null;
        sortBy: string;
        descending: boolean | null;
      },
      rules: [] as Array<any>,
      deleteDialog: false,
      deleteError: null,
      deleteItem: null as Role | null,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('company', ['settings']),

    fields(): object {
      return {
        name: {
          label: 'roles.field.name',
          sortable: true,
          defaultSort: true,
        },
        rules: {
          label: 'roles.field.rules',
          sortable: false,
        },
        ...(this.hasPermission('roles update') || this.hasPermission('roles delete')
          ? {
              actions: {
                sortable: false,
              },
            }
          : {}),
      };
    },

    deleteItemName(): string {
      const name = this.deleteItem ? this.deleteItem.name : '';

      return String(this.$t('roles.confirm.delete', { name: name }));
    },

    apiFunction(): Function {
      return async (opts: Params) => {
        return await api.getRoles(opts);
      };
    },
  },
  methods: {
    async loadRules(): Promise<void> {
      this.isLoading = true;
      this.rules = await api.getRules();
      this.isLoading = false;
    },

    details(role: Role, event: MouseEvent): void {
      const url = this.$route.path + '/update' + role.id;

      if (event && (event.metaKey || event.ctrlKey || event.shiftKey)) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },

    createRole(): void {
      this.$router.push({ name: 'createRoles' });
    },

    editRole(role: Role): void {
      this.$router.push({ name: 'editRoles', params: { id: role.id } });
    },

    deleteRole(role: Role): void {
      this.deleteError = null;
      this.deleteDialog = true;
      this.deleteItem = role;
    },

    async deleteRoleHandle(): Promise<void> {
      try {
        if (this.deleteItem?.id) {
          await api.deleteRole(this.deleteItem.id);
        }

        (this.$refs.grid as Vue & { getItems: () => void }).getItems();

        this.deleteDialog = false;
      } catch (err) {
        this.deleteError = err;
      }
    },

    getRules(role: Role): string {
      const rulesByGroup = new Map();

      for (const rule of role.rules) {
        if (this.isRuleVisible(rule)) {
          const groupName = this.findGroupByRule(rule);

          if (groupName && !rulesByGroup.has(groupName)) {
            rulesByGroup.set(groupName, 0);
          }

          if (groupName) {
            rulesByGroup.set(groupName, rulesByGroup.get(groupName) + 1);
          }
        }
      }

      const str = [];

      for (const [groupName, countOfRules] of rulesByGroup.entries()) {
        str.push(
          `${String(this.$t('roles.rulesGroups.' + groupName))} (${String(this.$t('selected'))} ${countOfRules})`,
        );
      }

      return str.join(', ');
    },

    findGroupByRule(rule: string): string | null {
      return this.rules.find(r => r.name === rule)?.group;
    },

    isRuleVisible(rule: string): boolean | null {
      return this.rules.find(r => r.name === rule)?.visible;
    },
  },

  mounted() {
    this.loadRules();
  },
});
