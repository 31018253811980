<template>
  <div class="trip-history">
    <v-expansion-panel @input="inputExpand">
      <v-expansion-panel-content>
        <template #header>
          <v-layout row wrap align-center>
            <v-flex shrink>
              <v-icon>view_list</v-icon>
            </v-flex>

            <v-flex>
              <v-subheader>{{ $t('Trip history') }}</v-subheader>
            </v-flex>
          </v-layout>
        </template>

        <error-alert :value="error" />

        <template v-if="data">
          <template v-if="data.length === 0">
            <v-card>
              <v-card-text>
                {{ $t('$vuetify.noDataText') }}
              </v-card-text>
            </v-card>
          </template>

          <template v-for="(item, index) in data">
            <v-divider v-if="index > 0" :key="index" />

            <TripEventItem
              :key="item.external_id"
              :value="transformItemData(item)"
              :executor="executor"
              :trip-mode="trip.tripMode"
            />
          </template>
        </template>

        <template v-else-if="loading">
          <v-layout row justify-center py-4>
            <v-progress-circular color="grey" indeterminate />
          </v-layout>
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import Vue from 'vue';
import api from '@/api/trip';
import TripEventItem from './events/TripEventItem';
import { getTimeZoneString } from '@/lib/timezone';
import { convertDateWithTimeZone } from '@/lib/date';

export default Vue.extend({
  components: { TripEventItem },

  props: {
    id: {
      type: Number,
      default: null,
    },

    showTimezone: {
      type: Boolean,
      default: null,
    },

    executor: {
      type: Object,
    },

    trip: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      error: null,
      expand: null,
      data: null,
    };
  },

  methods: {
    async loadTripEvents() {
      try {
        this.loading = true;
        this.error = null;
        this.data = await api.getEvents(this.id);
        this.loading = false;
      } catch (err) {
        this.error = err;
      }
    },

    async inputExpand(data) {
      this.expand = data;
      if (this.expand === 0 && !this.data && !this.loading) {
        await this.loadTripEvents();
      }
    },

    transformItemData(itemData) {
      if (this.showTimezone) {
        itemData = { ...itemData };
        const timezoneOffset = this.trip.summaryIndicators.startTimezone.offset;

        itemData.timezoneOffset = getTimeZoneString(timezoneOffset);
        itemData.timestamp = convertDateWithTimeZone(itemData.timestamp, timezoneOffset);
      }

      return itemData;
    },

    async refresh() {
      if (this.expand === 0 && !this.loading) {
        await this.loadTripEvents();
      }
    },
  },
});
</script>
