<template>
  <v-dialog v-model="showDialog" max-width="400" persistent>
    <v-card>
      <v-card-title>{{ $t('Contacts') }}</v-card-title>
      <v-card-text>
        <template v-for="(item, i) in sanitizedValue">
          <ContactEditorNew
            :key="item.id"
            :value="item"
            @delete="deleteItem(i)"
            @input="v => input({ value: v, index: i })"
            @updatePhoneValid="updatePhoneValid"
          />
          <v-divider v-if="i < sanitizedValue.length - 1 || canAddContact" :key="i" />
        </template>
        <template v-if="canAddContact && allPhonesValid">
          <v-input>
            <v-btn class="mx-auto text-none" @click="addItem" color="primary">
              {{ $t('Add contact') }}
            </v-btn>
          </v-input>
        </template>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn flat color="grey" @click="hideModalWithCancel">{{ $t('Cancel') }}</v-btn>
          <v-btn flat color="primary" @click="hideModalWithSave" :disabled="!allPhonesValid">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import uuid from 'uuid/v4';
import ContactEditorNew from './ContactEditorNew';

export default {
  components: { ContactEditorNew },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    maxCount: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      allPhonesValid: false,
      showDialog: true,
      showDummyItem: false,
      originValue: null,
    };
  },

  computed: {
    sanitizedValue() {
      let value = this.value || [];
      if (this.showDummyItem) {
        value = [
          ...value,
          {
            id: uuid(),
            name: '',
            note: '',
            jobTitle: '',
            phones: [],
          },
        ];
      }
      return value;
    },

    canAddContact() {
      return (
        this.sanitizedValue.length === 0 ||
        (this.sanitizedValue.length < this.maxCount &&
          this.isValidItem(this.sanitizedValue[this.sanitizedValue.length - 1]))
      );
    },
  },

  created() {
    if (this.sanitizedValue.length === 0) this.addItem();
    if (this.value) this.originValue = this.value;
  },

  methods: {
    input({ value, index }) {
      const result = [...this.value];
      if (index === this.value.length) {
        this.showDummyItem = false;
      }
      result[index] = value;

      for (let item of result) {
        if ((item.name || item.jobTitle || item.note) && !this.isValidItem(item)) this.allPhonesValid = false;
      }

      this.$emit('input', result);
    },

    addItem() {
      this.showDummyItem = true;
    },

    deleteItem(index) {
      if (index < this.value.length) {
        const result = [...this.value];
        result.splice(index, 1);
        if (this.allPhonesValid) {
          this.$emit('input', result);
        }
      } else {
        this.showDummyItem = false;
      }
    },

    isValidItem(item) {
      return item.phones.length > 0 && item.phones[0].phone;
    },

    hideModalWithCancel() {
      this.$emit('input', this.originValue);
      this.$emit('close');
    },
    hideModalWithSave() {
      this.$emit('close');
    },

    updatePhoneValid(value) {
      this.allPhonesValid = value;
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },

  /*
    watch: {
      value: {
        deep: true,
        handler(n, o) {
          const emptyIndex = _.findIndex(this.value, (item) => this.isEmptyItem(item));
          if (emptyIndex !== -1) {
            const result = [...this.value];
            result.splice(emptyIndex, 1);
            this.$emit('input', result);
          }
        }
      }
    }
    */
};
</script>
