import auth from '@/api/auth';
import store from '@/store';

export default {
  name: 'Logout',
  async created() {
    try {
      await auth.logout();
      store.commit('billing/resetState');
      store.commit('account/resetState');
      store.commit('auth/resetState');
      this.$router.replace('/login');
    } catch (err) {
      this.$router.push('/');
    }
  },
  render() {},
};
