import api from '@/api/pointFunctions';

export default {
  namespaced: true,

  state: {
    pointFunctionsList: [],
    lastUpdate: 0,
  },

  mutations: {
    setPointFunctionsList(state, data) {
      state.pointFunctionsList = data;
      state.lastUpdate = new Date().valueOf();
    },
  },

  actions: {
    async updatePointFunctions({ state, commit }) {
      if (state.lastUpdate < new Date().valueOf() + 60000) {
        const data = await api.list();
        commit('setPointFunctionsList', data.result);
      }
    },
  },
};
