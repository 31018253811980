<template>
  <v-dialog v-model="showDialog" max-width="450">
    <v-card>
      <v-card-title class="headline">
        {{ $t('Error') }}
      </v-card-title>
      <v-card-text>
        {{ $t('routeLatenessMessage') }}
      </v-card-text>
      <v-card-actions>
        <v-layout row>
          <v-spacer />
          <v-flex pb-2>
            <v-btn color="primary" @click="hideModal">
              {{ $t('Change the route') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      showDialog: true,
    };
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },
    handleOk() {
      this.$emit('ok');
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
