<template>
  <v-layout row fill-height justify-center align-center>
    <v-flex md6>
      <v-card v-if="card === 'welcome'">
        <v-card-title primary-title>
          <div class="headline">
            {{ $t('Welcome!') }}
          </div>
        </v-card-title>

        <v-card-text>{{ $t('trial_help.start_trial') }}</v-card-text>

        <v-card-actions>
          <v-btn :class="{ caption: $vuetify.breakpoint.name === 'xs' }" @click="card = 'register'" color="primary">
            {{ $t('Register new company') }}
          </v-btn>
        </v-card-actions>

        <v-card-text>
          <p>{{ $t('trial_help.for_success_trial') }}</p>

          <v-timeline dense>
            <v-timeline-item small>
              {{ $t('trial_help.register_company') }}
            </v-timeline-item>

            <v-timeline-item small>
              {{ $t('trial_help.install_app') }}
            </v-timeline-item>

            <v-timeline-item small>
              {{ $t('trial_help.create_trip') }}
            </v-timeline-item>

            <v-timeline-item small>
              {{ $t('trial_help.try_add_points') }}
            </v-timeline-item>

            <v-timeline-item small>
              {{ $t('trial_help.direct_support_service') }}
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>

      <v-card v-if="card === 'register'">
        <v-card-title primary-title>
          <div class="headline">
            {{ $t('Company registration') }}
          </div>
        </v-card-title>

        <v-container grid-list-sm class="pa-4">
          <Form :fields="formFields" :error="error" v-model="form" ref="form">
            <template slot="company_country" slot-scope="props">
              <v-autocomplete
                :name="props.field.name"
                :prepend-icon="props.field.icon"
                :items="props.field.items"
                :rules="props.field.rules"
                item-text="name"
                item-value="alpha2"
                v-model="form.data[props.field.name]"
              >
                <template #label>
                  <strong class="red--text">* </strong>
                  {{ $t(props.field.label) }}
                </template>
              </v-autocomplete>

              <div class="caption grey--text" style="margin-left: 33px">
                {{ currencyHint }}
              </div>
            </template>

            <template slot="agree_support" slot-scope="props">
              <v-checkbox label hide-details v-model="form.data[props.field.name]" :name="props.field.name">
                <template slot="label">
                  {{ $t('Agree support') }}
                </template>
              </v-checkbox>
            </template>

            <template slot="agree_terms" slot-scope="props">
              <v-checkbox
                label
                hide-details
                v-model="form.data[props.field.name]"
                :rules="props.field.rules"
                :name="props.field.name"
              >
                <template slot="label">
                  <AgreementLinks />
                </template>
              </v-checkbox>
            </template>
          </Form>
        </v-container>

        <v-card-actions>
          <v-btn @click="submit" :disabled="!form.valid" color="primary" name="register-company-btn">
            {{ $t('Register') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="card === 'done'">
        <v-card-text v-html="$t('Registration.Done')" />
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { getCountriesList, locales } from '@/i18n';
import rules from '@/api/rules';
import request from '@/api/request';
import AgreementLinks from '@/components/AgreementLinks.vue';

let state;

export default {
  name: 'Register',

  metaInfo() {
    return {
      title: this.$i18n.t('Registration.Title'),
      fillHeight: true,
    };
  },

  components: {
    AgreementLinks,
  },

  data() {
    return {
      form: {
        valid: true,
        data: {
          company_country: locales.filter(lang => lang !== 'en').includes(this.$i18n.locale) ? this.$i18n.locale : null,
          agree_support: true,
          roistat_visit: null,
          promocode: null,
          referrer: null,
        },
      },
      error: null,
      card: 'welcome',
    };
  },

  // Save and restore state when user clicks on links
  created() {
    if (state) {
      Object.assign(this.$data, state);
    }

    this.form.data.roistat_visit = this.$route.query.roistat_visit || null;
    this.form.data.referrer = document.referrer;

    if (this.$route.path === '/coronahelp') {
      this.form.data.promocode = 'CORONAHELP';
    }

    this.$router.replace(this.$route.path);
  },

  mounted() {
    if (this.$refs.form) {
      this.$refs.form.validate();
    }
  },

  beforeRouteLeave(to, from, next) {
    state = this.$data;
    next();
  },

  methods: {
    async submit() {
      try {
        this.error = null;

        const data = { ...this.form.data };

        await request.create(data);

        this.card = 'done';
      } catch (err) {
        this.error = err;
      }
    },

    getUrl(url) {
      if (this.form.data.company_country === 'ru') {
        return url + '/ru';
      } else {
        return url;
      }
    },
  },

  computed: {
    formFields() {
      return [
        {
          name: 'company_name',
          label: 'Enter company name',
          icon: 'business',
          rules: rules.string.required('Company name'),
          isRequired: true,
        },
        {
          name: 'company_country',
          label: 'Select your country',
          type: 'select',
          items: this.countriesList,
          icon: 'mdi-compass-outline',
          hint: this.currencyHint,
          rules: rules.required('Country'),
        },
        {
          name: 'account_email',
          label: 'Enter your e-mail',
          icon: 'mail',
          type: 'email',
          rules: rules.email.required,
          isRequired: true,
        },
        {
          name: 'account_phone',
          label: 'Enter your phone number',
          icon: 'phone',
          placeholder: '+7(000)0000000',
          type: 'text',
          rules: rules.phone.required,
          isRequired: true,
        },
        {
          name: 'size_of_company',
          label: 'Enter your employees count',
          type: 'select',
          icon: 'group',
          item_value: 'value',
          item_text: 'name',
          items: this.employeesCountList,
        },
        [
          {
            name: 'account_last_name',
            label: 'Enter last name',
            icon: 'person',
            rules: rules.name.required('Last name'),
            isRequired: true,
          },
          {
            name: 'account_first_name',
            label: 'Enter name',
            rules: rules.name.required('Name'),
            isRequired: true,
          },
        ],
        {
          name: 'account_password',
          label: 'Set password',
          type: 'password',
          icon: 'lock',
          ...rules.passwordAttrs,
          isRequired: true,
        },
        {
          name: 'agree_support',
        },
        {
          name: 'agree_terms',
          rules: [value => value === true || 'must_agree'],
        },
      ];
    },

    currencyHint() {
      let currency = 'EUR';

      if (this.form.data.company_country === 'ch') {
        currency = 'CHF';
      } else if (this.form.data.company_country === 'ru') {
        currency = 'RUB';
      }

      return this.$t('Currency for payment: {0}', [currency]);
    },

    countriesList() {
      return getCountriesList();
    },

    employeesCountList() {
      return [
        { id: 1, name: this.$i18n.t('Employees count list'), value: '1-35', selected: '1-35' },
        { id: 2, name: this.$i18n.t('Employees count list2'), value: '35-100' },
        { id: 3, name: this.$i18n.t('Employees count list3'), value: '>100' },
      ];
    },
  },
};
</script>
