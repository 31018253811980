import http from './httpV2';
import { ActiveRoute, RouteQuery, WayBuilderResponse, WayBuilderIntermediate } from '@/models/Way';
import { AxiosResponse } from 'axios';

async function buildWay(data: RouteQuery): Promise<WayBuilderIntermediate> {
  const res = await http.post(`/way/build`, data);
  return res.data;
}

async function getBuild(buildId: string): Promise<AxiosResponse<WayBuilderResponse>> {
  const res = await http.get<WayBuilderResponse>(`/way/build/${buildId}`);
  return res;
}

export default {
  buildWay,
  getBuild,
};
