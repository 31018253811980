/* global google */

import { MapMarkerOptions } from '../primitives/MapMarker';
import { ITripMap } from './info/lib';
import RadarMarkerInfo from '@/components/map/markers/info/RadarMarkerInfo';
import PointMarker from '@/components/map/markers/PointMarker';

/**
 * Component that acts as a facade for MapMarker object
 * Computes icon/label for point and passes changes in computed data to marker
 */
export default PointMarker.extend({
  name: 'RadarMarker',

  computed: {
    markerData(): MapMarkerOptions | null {
      if (!this.point || !this.point.tripPoint || !this.position) {
        return null;
      }

      return {
        ...RadarMarkerInfo(this.point, (this.$parent as unknown) as ITripMap),
        position: this.position,
      };
    },
  },
});
