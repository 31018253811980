<template>
  <v-layout column>
    <v-flex>
      <v-layout class="align-end">
        <div>
          <h1>{{ $t('Favorites') }}</h1>
        </div>

        <v-spacer />

        <v-flex style="max-width: 350px" mr-2>
          <v-text-field append-icon="search" :label="$t('Search')" clearable hide-details v-model="filter" />
        </v-flex>

        <v-btn v-if="hasPermission('places create')" @click="createPlace" color="primary" class="mr-0 mb-0">
          <v-icon>mdi-plus</v-icon>
          {{ $t('Add') }}
        </v-btn>
      </v-layout>
    </v-flex>

    <error-alert :value="error" v-if="error" />

    <v-flex xs12>
      <v-card>
        <GridCrud
          :api="apiFunction"
          :fields="fields"
          :filter="filter"
          ref="grid"
          :pagination.sync="pagination"
          @click="editPlace"
        >
          <template slot="name" slot-scope="slotProps">
            {{ slotProps.item.title }}
          </template>

          <template slot="address" slot-scope="slotProps">
            {{ getItemAddresses(slotProps.item) }}
          </template>

          <template slot="workingHours" slot-scope="slotProps">
            <div v-if="slotProps.item.workingHours !== null" style="white-space: nowrap;">
              <span>{{ slotProps.item.workingHours[0].from }}</span> —
              <span>{{ slotProps.item.workingHours[0].to }}</span>
              <span
                v-if="isWorkingHoursNextDay(slotProps.item.workingHours[0])"
                style="color: rgba(0,0,0,0.54); font-weight: 500;"
              >
                +1</span
              >
            </div>
          </template>

          <template slot="radius" slot-scope="slotProps">
            <div>
              {{
                slotProps.item.radius.checkin ? $n(slotProps.item.radius.checkin, 'number') : defaultRadiuses.checkin
              }}
            </div>
            <div>
              {{
                slotProps.item.radius.checkout ? $n(slotProps.item.radius.checkout, 'number') : defaultRadiuses.checkout
              }}
            </div>
          </template>

          <template slot="actions" slot-scope="slotProps">
            <td @click.prevent.stop style="max-width: 115px; padding: 0;">
              <div style="width: 94px; margin: auto;">
                <span @click="editPlace(slotProps.item)">
                  <v-btn
                    v-if="hasPermission('places update')"
                    icon
                    style="margin-left: 5px; margin-right: 5px;"
                    :key="slotProps.item.id + '-edit'"
                  >
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn v-else icon style="margin-left: 5px; margin-right: 5px;" :key="slotProps.item.id + '-details'">
                    <v-icon>
                      mdi-format-list-bulleted-square
                    </v-icon>
                  </v-btn>
                </span>
                <v-btn
                  v-if="hasPermission('places delete')"
                  icon
                  style="margin-left: 5px; margin-right: 5px;"
                  :key="slotProps.item.id + '-delete'"
                  @click="showDeleteDialog(slotProps.item)"
                >
                  <v-icon>
                    delete
                  </v-icon>
                </v-btn>
              </div>
            </td>
          </template>
        </GridCrud>
      </v-card>
    </v-flex>

    <DeleteDialog
      v-if="deleteDialog"
      :delete-item="deleteItemName"
      :delete-error="deleteError"
      @close="deleteDialog = false"
      @delete="handleDelete"
    />
  </v-layout>
</template>

<script>
import api from '@/api/place';

import ContactsInfo from '../components/ContactsInfo.vue';
import GridCrud from '@/components/grid/GridCrud';
import DeleteDialog from '@/components/modals/DeleteDialog';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Places',

  metaInfo() {
    return {
      title: this.$i18n.t('Favorites'),
      fillHeight: true,
    };
  },

  components: { GridCrud, DeleteDialog },

  data() {
    return {
      places: {
        features: [],
        type: 'FeatureCollection',
      },
      filter: '',
      pagination: {},
      error: null,
      deleteDialog: false,
      deleteError: null,
      deleteItem: null,
      apiFunction: null,
      canLoading: true,
    };
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    defaultRadiuses() {
      return {
        checkin: this.settings.pointRadius.checkin,
        checkout: this.settings.pointRadius.checkout,
      };
    },
    fields() {
      return {
        name: {
          label: this.$t('Name'),
          sortable: true,
          defaultSort: true,
        },
        address: {
          label: this.$t('Address'),
          sortable: true,
        },
        workingHours: {
          label: this.$t('Work time'),
          sortable: false,
        },
        radius: {
          label: this.$t('Radiuses') + ', ' + this.$t('m'),
          sortable: false,
          style: {
            whiteSpace: 'nowrap',
          },
        },
        contacts: {
          label: this.$t('Contacts'),
          sortable: true,
          component: ContactsInfo,
          format: v => (v && v.length ? [v[0]] : null),
        },
        actions: {
          label: this.$t('Actions'),
          sortable: false,
          align: 'center',
          width: 150,
        },
      };
    },

    deleteItemName() {
      const str = this.deleteItem ? ` "${this.deleteItem.title}"` : '';
      return this.$t('common_strings.from_place') + str;
    },

    filteredPoints() {
      if (!this.filter) {
        return this.places;
      }

      const searchString = this.filter.toLowerCase();

      const filteredPoints = this.places.features.filter(place => {
        if (place.properties.title.toLowerCase().includes(searchString)) {
          return true;
        } else if (place.properties.address.toLowerCase().includes(searchString)) {
          return true;
        }
      });

      return {
        features: filteredPoints,
        type: 'FeatureCollection',
      };
    },
  },

  created() {
    this.parseURL();
    this.apiFunction = api.list;
  },

  methods: {
    async fetchMapData() {
      try {
        this.places = await api.getPlacesMap();
      } catch (err) {
        this.error = err;
      }
    },

    parseURL() {
      if (this.$route.query.filter) {
        this.filter = this.$route.query.filter;
      }
      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page) + 1;
      }
      if (this.$route.query.pageSize) {
        this.pagination.rowsPerPage = parseInt(this.$route.query.pageSize);
      }
      if (this.$route.query.sort) {
        this.pagination.sortBy = this.$route.query.sort;
      }
      if (this.$route.query.sortDesc) {
        this.pagination.descending = this.$route.query.sortDesc === '1';
      }
    },

    makeQuery() {
      return {
        ...this.$refs.grid.makeQuery(),
        ...(this.filter ? { filter: this.filter } : null),
      };
    },

    createPlace() {
      this.$router.push({
        name: 'PlaceDetails',
        params: {
          id: 'new',
          back: {
            path: this.$route.path,
            query: this.$route.query,
          },
        },
      });
    },

    editPlace(place) {
      this.$router.push({
        name: 'PlaceDetails',
        params: {
          id: place.id,
          back: {
            path: this.$route.path,
            query: this.$route.query,
          },
        },
      });
    },

    getItemAddresses(place) {
      const raw = place?.location?.addresses;
      if (Array.isArray(raw) && raw.length) {
        const addresses = [];
        raw.forEach(item => {
          addresses.push(item.address);
        });
        return addresses.join('. ');
      }
      return '';
    },

    isWorkingHoursNextDay(workingHours) {
      return new Date('1/1/2021 ' + workingHours.from) > new Date('1/1/2021 ' + workingHours.to);
    },

    showDeleteDialog(item) {
      this.deleteError = null;
      this.deleteDialog = true;
      this.deleteItem = item;
    },

    async handleDelete() {
      try {
        await api.remove(this.deleteItem.id);
        this.$refs.grid.getItems();
        this.deleteDialog = false;
      } catch (err) {
        this.deleteError = err;
      }
    },

    checkLoading(e) {
      this.canLoading = e;
    },
  },

  watch: {
    filter() {
      this.$router.replace({ name: 'Places', query: this.makeQuery() });
    },

    pagination() {
      this.$router.replace({ name: 'Places', query: this.makeQuery() });
    },
  },
};
</script>
