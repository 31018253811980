<template>
  <v-btn :style="'margin-left: 10px'" color="info" @click="getTemplate">
    {{ $t('import.downloadTemplate') }}
  </v-btn>
</template>

<script>
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { makeTemplateData as defaultData } from './default/XlsTemplate';

export default {
  name: 'XlsTemplate',
  methods: {
    async getTemplate() {
      const workbook = XLSX.utils.book_new();
      workbook.Props = {
        Title: 'Import template',
        Author: 'HomeStretch AG',
      };

      defaultData(workbook);

      const wbout = XLSX.write(workbook, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'array',
      });
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'homestretch_import_template.xlsx');
    },
  },
};
</script>
