import http from './http';

async function debugQuery(url, data) {
  const res = await http.post(`/debug/${url}`, data);
  return res.data;
}

export default {
  debugQuery,
};
