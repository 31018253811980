<template>
  <div>
    <h1>Dashboard</h1>
    Home
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {};
  },
};
</script>
