<template>
  <v-layout row fill-height justify-center align-center>
    <v-flex md6>
      <v-card v-if="!done">
        <v-card-title primary-title>
          <div class="headline">
            {{ $t('Register new user') }}
          </div>
        </v-card-title>

        <error-alert :value="error" v-if="!loaded" />

        <v-container grid-list-sm class="pa-4" v-else>
          <Form :fields="formFields" :error="error" v-model="form">
            <template slot="country" slot-scope="props">
              <v-autocomplete
                :disabled="props.field.disabled"
                :label="$t(props.field.label)"
                :name="props.field.name"
                :prepend-icon="props.field.icon"
                :items="props.field.items"
                :rules="props.field.rules"
                item-text="name"
                item-value="alpha2"
                v-model="form.data[props.field.name]"
              />
            </template>

            <template slot="default_branch_id" slot-scope="props">
              <v-select
                :disabled="props.field.disabled"
                :label="$t(props.field.label)"
                :prepend-icon="props.field.icon"
                :items="props.field.items"
                item-text="name"
                item-value="id"
                v-model="inviteData.default_branch_id"
                :error-messages="props.errors"
              />
            </template>

            <template slot="default_role_id" slot-scope="props">
              <v-select
                :disabled="props.field.disabled"
                :label="$t(props.field.label)"
                :prepend-icon="props.field.icon"
                :items="props.field.items"
                item-text="name"
                item-value="id"
                v-model="inviteData.default_role_id"
                :error-messages="props.errors"
              />
            </template>
          </Form>
        </v-container>

        <v-card-actions v-if="loaded">
          <v-btn @click="submit" :disabled="!form.valid" color="primary" name="invite-register-btn">
            {{ $t('Register') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-else>
        <v-card-text>
          <template v-if="!used"> {{ $t('You have been successfully registered') }}.</template>

          <template v-else> {{ $t('You have already entered all data') }}.</template>

          <i18n path="Registration.you_may_login">
            <a place="link" href="/">{{ $t('HomeStretch web version') }}</a>
          </i18n>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';

import { getCountriesList } from '@/i18n';
import { getMainSiteUrl } from '@/lib/url';

import rules from '@/api/rules';
import invite from '@/api/invite';
import branch from '@/api/branch';

let state;

export default {
  name: 'Register',

  metaInfo() {
    return {
      title: this.$i18n.t('Registration.Title'),
    };
  },

  data() {
    return {
      inviteData: {
        account_email: null,
        account_first_name: null,
        account_last_name: null,
        account_job_title: null,
        account_country: null,
        account_phone: null,
        default_branch_id: null,
        default_role_id: null,
      },
      form: {
        valid: true,
        data: {
          email: null,
          first_name: null,
          last_name: null,
          job_title: null,
          country: null,
          phone: null,
        },
      },
      error: null,
      loaded: false,
      done: false,
      used: false,
    };
  },

  // Save and restore state when user clicks on links
  async created() {
    if (state) {
      Object.assign(this.$data, state);
    } else {
      await this.fetchInvite();
    }
  },

  mounted() {
    if (this.$refs.form) {
      this.$refs.form.validate();
    }
  },

  beforeRouteLeave(to, from, next) {
    state = this.$data;
    next();
  },

  computed: {
    ...mapState('company', ['settings']),

    formFields() {
      return [
        {
          type: 'tel',
          name: 'phone',
          icon: 'phone',
          label: 'Enter phone number',
          placeholder: '+7(000)0000000',
          autocomplete: false,
          disabled: !!this.inviteData.account_phone,
          rules: rules.phone,
        },
        {
          name: 'email',
          icon: 'mail',
          label: 'Enter your e-mail',
          type: 'email',
          autocomplete: false,
          disabled: !!this.inviteData.account_email,
          rules: this.emailRules,
          isRequired: true,
        },
        [
          {
            name: 'last_name',
            label: 'Enter last name',
            icon: 'person',
            autocomplete: false,
            disabled: !!this.inviteData.account_last_name,
            rules: rules.name.required('Last name'),
            isRequired: true,
          },
          {
            name: 'first_name',
            label: 'Enter name',
            autocomplete: false,
            disabled: !!this.inviteData.account_first_name,
            rules: rules.name.required('Name'),
            isRequired: true,
          },
        ],
        {
          name: 'password',
          label: 'Set password',
          icon: 'lock',
          type: 'password',
          autocomplete: false,
          ...rules.passwordAttrs,
          isRequired: true,
        },
        {
          name: 'job_title',
          icon: 'mdi-account-details',
          label: 'Enter your job title',
          autocomplete: false,
          disabled: !!this.inviteData.account_job_title,
          rules: rules.string.required('Job title'),
          isRequired: true,
        },
        {
          name: 'country',
          label: 'Select your country',
          type: 'select',
          items: this.countriesList,
          icon: 'mdi-compass-outline',
          rules: rules.required('Country'),
          disabled: !!this.inviteData.account_country,
        },
        {
          name: 'default_branch_id',
          label: 'Select branch',
          type: 'select',
          items: [this.inviteData.branch],
          icon: 'business',
          disabled: true,
        },
        {
          name: 'default_role_id',
          label: 'Select role',
          type: 'select',
          items: [this.inviteData.role],
          icon: 'mdi-key',
          disabled: true,
        },
      ];
    },

    emailRules() {
      if (!this.form.data.phone && !this.form.data.email) {
        return [v => this.$t('Please input email or phone')];
      }

      return rules.email;
    },

    countriesList() {
      return getCountriesList();
    },
  },

  methods: {
    async fetchInvite() {
      try {
        const data = await invite.get(this.$route.params.code);
        this.inviteData = data;

        this.error = null;

        if (data.company.language) {
          this.$i18n.locale = data.company.language;
        }

        this.form.data.country = data.company.country;

        if (data.account_email) {
          this.form.data.email = data.account_email;
        }

        if (data.account_first_name) {
          this.form.data.first_name = data.account_first_name;
        }

        if (data.account_last_name) {
          this.form.data.last_name = data.account_last_name;
        }

        if (data.account_job_title) {
          this.form.data.job_title = data.account_job_title;
        }

        if (data.account_country) {
          this.form.data.country = data.account_country;
        }

        if (data.account_phone) {
          this.form.data.phone = data.account_phone;
        }

        this.loaded = true;
      } catch (err) {
        if (err.code === 'CodeIsUsed') {
          this.done = true;
          this.used = true;
        } else {
          this.error = err;
        }
      }
    },

    async submit() {
      try {
        this.error = null;

        const data = { ...this.form.data };

        delete data.default_branch_id;
        delete data.default_role_id;

        data.email = data.email || null;
        data.phone = data.phone || null;

        await invite.register(this.$route.params.code, data);

        this.done = true;
      } catch (err) {
        this.error = err;
      }
    },

    getUrl(link) {
      if (this.form.data.country === 'ru') {
        return getMainSiteUrl(link) + '/ru';
      } else {
        return getMainSiteUrl(link);
      }
    },
  },
};
</script>
