import Vue from 'vue';
import VAutocomplete from 'vuetify/es5/components/VAutocomplete';
import VSelectList from 'vuetify/es5/components/VSelect/VSelectList';
import VListTileAction from 'vuetify/es5/components/VList/VListTileAction';
import CheckboxNoState from '../inputs/CheckboxNoState';

const SelectListHelper = Vue.extend({
  extends: VSelectList,

  methods: {
    genAction(item, inputValue) {
      const data = {
        on: {
          click: e => {
            e.stopPropagation();
            this.$emit('select', item);
          },
        },
      };

      return this.$createElement(VListTileAction, data, [
        this.$createElement(CheckboxNoState, {
          props: {
            color: this.color,
            inputValue,
          },
        }),
      ]);
    },
  },
});

export default Vue.extend({
  extends: VAutocomplete,

  computed: {
    staticList() {
      return this.$createElement(SelectListHelper, this.listData);
    },
  },
});
