<template>
  <v-layout column mb-2>
    <v-combobox
      clearable
      persistent-hint
      :hint="coordinates"
      :rules="rules"
      :error-messages="errorMessages"
      :disabled="disabled"
      :readonly="readonly"
      :items="addresses"
      :search-input.sync="search"
      :value="getValue"
    >
      <template #label>
        <strong class="red--text">* </strong>
        {{ label }}
      </template>
    </v-combobox>
  </v-layout>
</template>

<script>
import Vue from 'vue';
import { cloneDeep, get, set, debounce } from 'lodash';
import gmap from '@/lib/gmap';

export default Vue.extend({
  name: 'AddressInputAutocomplete',

  data() {
    return {
      addresses: [],
      search: '',
    };
  },

  props: {
    label: String,
    errorMessages: Object,
    value: Object,
    rules: Array,
    path: {
      type: String,
      default: 'location',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getValue() {
      return get(this.value, `${this.path}.addresses[0].address`);
    },

    coordinates() {
      const coordinates = get(this.value, `${this.path}.coordinates`, null);

      if (!coordinates) {
        return null;
      }

      const label = this.$t('Coordinates');
      const lat = this.$n(coordinates.lat, 'coordinate');
      const lng = this.$n(coordinates.lng, 'coordinate');

      return label + ': ' + lat + ' ' + lng;
    },
  },

  methods: {
    clearAddresses() {
      this.$nextTick(() => {
        this.addresses = [];
      });
    },

    async getAutocompleteItems(query) {
      await gmap.init();
      await gmap.waitInit();

      const autocompleteService = new google.maps.places.AutocompleteService();
      const sessionToken = new google.maps.places.AutocompleteSessionToken();

      return new Promise((resolve, reject) => {
        autocompleteService.getPlacePredictions(
          {
            input: query,
            sessionToken,
          },
          (result, status) => {
            if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              resolve([]);
              return;
            }

            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
              return;
            }

            resolve(result.map(place => place.description));
          },
        );
      });
    },
  },

  watch: {
    search: debounce(function(value) {
      if (value) {
        this.$nextTick(async () => {
          this.addresses = await this.getAutocompleteItems(value);
        });

        const newValue = {
          addresses: [
            {
              address: value,
              locale: this.$i18n.locale,
              addressComponents: null,
            },
          ],
          arrivalDuration: get(this.value, `${this.path}.arrivalDuration`, 0),
          departureDuration: get(this.value, `${this.path}.departureDuration`, undefined),
          transportRestrictions: get(this.value, `${this.path}.transportRestrictions`, []),
        };

        this.$emit('input', set(cloneDeep(this.value), this.path, newValue));
      }
    }, 200),
  },
});
</script>
