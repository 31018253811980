const getDefaultState = () => {
  return {
    costPerMonth: 0,
    currency: '',
    paidSlots: 0,
    paidTrips: 0,
    activeAccounts: 0,
    plan: '',
    status: '',
    currentPeriodEnd: 0,
    paymentMethodType: '',
    paidSms: 0,
  };
};

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setBillingInfo(state, data) {
      Object.assign(state, data);
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    setPaidSms(state, data) {
      state.paidSms = data;
    },
  },

  getters: {
    paidLeft(state) {
      const ms = state.currentPeriodEnd * 1000 - new Date();
      return Math.max(0, Math.floor(ms / 1000));
    },

    currentPeriodEnd(state) {
      return state.currentPeriodEnd * 1000;
    },

    billingAlert(state) {
      return ['RESTRICTED', 'BLOCKED', 'CONFIDENCE'].includes(state.status);
    },

    paidSms(state) {
      return state.paidSms;
    },

    showSmsCounter(state, getters, rootState) {
      return state.currency === 'RUB' && rootState.auth.permissions['billing'];
    },

    availableSlots(state) {
      if (
        typeof state.paidSlots === 'number' &&
        typeof state.activeAccounts === 'number' &&
        state.paidSlots > state.activeAccounts
      ) {
        return state.paidSlots - state.activeAccounts;
      } else {
        return 0;
      }
    },

    availableTrips(state) {
      return state.paidTrips;
    },

    isActiveBillingStatus(state) {
      return state.status === 'ACTIVE';
    },
  },
};
