<template>
  <v-btn-toggle slot="activator" @change="change" :value="toggleValue" class="round" ref="toggle">
    <btn-helper icon flat :value="true" @click="click" :loading="loading" :disabled="disabled">
      <slot />
      <slot slot="loader" name="loader" />
    </btn-helper>
  </v-btn-toggle>
</template>

<script>
import Vue from 'vue';
import VBtn from 'vuetify/es5/components/VBtn/VBtn';

const BtnHelper = {
  extends: VBtn,
  methods: {
    click(e) {
      !this.fab && e.detail && this.$el.blur();
      this.$emit('click', e);
    },
  },
};

export default Vue.extend({
  components: {
    BtnHelper, // eslint-disable-line
  },

  props: {
    value: Boolean,
    disabled: {
      type: Boolean,
      default: null,
    },
    loading: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    toggleValue() {
      return this.value ? true : undefined;
    },
  },

  methods: {
    change(newValue) {
      this.$emit('input', !!newValue);
    },

    click(event) {
      if (this.$listeners.click) {
        this.$emit('click');
      }
    },
  },
});
</script>
