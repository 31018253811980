import Vue from 'vue';
import ChangeIcon from './ChangeIcon';
import ChangeStatusIcon from './ChangeStatusIcon';
import PointIcon from './PointIcon';
import PointFunctionsIcon from './PointFunctionsIcon';
import PlaceIcon from './PlaceIcon';
import TripStatusIcon from './TripStatusIcon';

function install() {
  Vue.component('PlaceIcon', PlaceIcon);
  Vue.component('ChangeIcon', ChangeIcon);
  Vue.component('ChangeStatusIcon', ChangeStatusIcon);
}

export { ChangeIcon };
export { ChangeStatusIcon };
export { PointIcon };
export { PointFunctionsIcon };
export { PlaceIcon };
export { TripStatusIcon };
export default {
  install,
};
