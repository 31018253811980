import Vue from 'vue';

import { TripTableRow } from '@/services/TripPageService';
import { CheckinStatus, TripPointStatus, TripPointType } from '@/models/TripPoint';
import TimeWithOptionalDay from '../../TimeWithOptionalDay';
import Trip from '@/models/Trip';

export default Vue.extend({
  functional: true,

  name: 'CheckinAt',

  props: {
    item: TripTableRow,
    trip: Trip,
  },

  render(h, { props }) {
    const { item, trip } = props;
    const { tripPoint, checkin, timezoneOffset, showTimezone, showDate } = item;

    if (checkin) {
      let status: any = checkin.status;

      if (checkin.status === CheckinStatus.current || tripPoint?.status === TripPointStatus.current) {
        status = TripPointStatus.current;
      }

      return h(TimeWithOptionalDay, {
        props: {
          value: checkin.inDate,
          showDate,
          showTimezones: showTimezone,
          timezoneOffset,
          status,
        },
      });
    } else if (
      tripPoint &&
      [TripPointStatus.active, TripPointStatus.planned, TripPointStatus.waitingForAdd].includes(tripPoint.status)
    ) {
      if (item.arrivalPlanAt && !trip.isEnded()) {
        return h(TimeWithOptionalDay, {
          props: {
            value: item.arrivalPlanAt,
            showDate,
            showTimezones: showTimezone,
            timezoneOffset,
            option: 'tilde',
            invalid: Boolean(item.missDeliveryWindow),
          },
        });
      }
    } else if (tripPoint) {
      let status;

      if (item.status === TripPointStatus.cancelled) {
        status = TripPointStatus.cancelled;
      } else if (tripPoint?.type === TripPointType.actualFinish) {
        status = TripPointType.actualFinish;
      }

      if (status) {
        return h(TimeWithOptionalDay, {
          props: {
            value: trip.finishedAt,
            showDate,
            showTimezones: showTimezone,
            timezoneOffset,
            status,
          },
        });
      }
    }

    return h();
  },
});
