
import Vue from 'vue';
import { PaymentStatus } from '@/models/Order';

export default Vue.extend({
  name: 'OrderPaymentStatusPicker',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Order payment status',
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    types() {
      const typeList = Object.keys(PaymentStatus);
      return typeList.map(s => ({
        text: this.$t(`OrderPaymentStatus.${s}`),
        value: s,
      }));
    },
  },
  methods: {
    selectStatus(value: Array<any>) {
      this.$emit('input', value);
    },
  },
});
