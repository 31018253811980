import {
  addSeconds,
  differenceInSeconds as dateFnsDifferenceInSeconds,
  isSameDay as dateFnsIsSameDay,
  parseISO,
} from 'date-fns';
import { isNil } from 'lodash';

function differenceInSeconds(date1: string | Date, date2: string | Date) {
  if (typeof date1 === 'string') {
    date1 = parseISO(date1);
  }
  if (typeof date2 === 'string') {
    date2 = parseISO(date2);
  }
  return dateFnsDifferenceInSeconds(date1, date2);
}

function isSameDay(date1: string | Date, date2: string | Date) {
  if (typeof date1 === 'string') {
    date1 = parseISO(date1);
  }
  if (typeof date2 === 'string') {
    date2 = parseISO(date2);
  }
  return dateFnsIsSameDay(date1, date2);
}

function convertDateWithTimeZone(date: null, timeOffset: number | null): null;

function convertDateWithTimeZone(date: Date, timeOffset: number | null): Date;

function convertDateWithTimeZone(date: string, timeOffset: number | null): Date;

function convertDateWithTimeZone(date: any, timeOffset: any) {
  if (!date) {
    return null;
  }
  if (isNil(timeOffset)) {
    return date;
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const utcTime = convertDateToUTC(date);
  return addSeconds(utcTime, timeOffset);
}

function convertDateToUTC(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

export { differenceInSeconds, isSameDay, convertDateWithTimeZone, convertDateToUTC };
