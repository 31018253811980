<template>
  <v-tabs>
    <v-tab>
      <div :class="{ 'red--text': errorsWithOutConflicts.length }">
        {{ $t('import.errorInfo', [errorsWithOutConflicts.length]) }}
      </div>
    </v-tab>
    <v-tab-item>
      <v-card>
        <NewGrid :fields="errorFields" :items="errorsWithOutConflicts">
          <template #reason="prop">
            <span v-html="errorFields.reason.formatHtml(prop.item.reason, prop.item)" />
          </template>
        </NewGrid>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import Vue from 'vue';
import NewGrid from '@/components/grid/Grid';

export default Vue.extend({
  components: { NewGrid },

  props: {
    value: {
      type: Object,
      default: () => ({
        trip: {
          tripPoints: [],
        },
        errors: [],
      }),
    },
  },

  computed: {
    errorsWithOutConflicts() {
      return (this.value.errors && this.value.errors.filter(error => error.reason !== 'PLACE_UPDATE_CONFLICT')) || [];
    },

    errorFields() {
      return {
        numberOfRow: {
          label: this.$t('Number of row'),
        },
        reason: {
          label: this.$t('Reason'),
          formatHtml: (v, row) => row.message || this.$t(`importError.${v}`),
        },
      };
    },
  },
});
</script>
