import BranchAccountPicker from './BranchAccountPicker';

/**
 * Component to select accounts
 */
export default {
  name: 'AccountPicker',

  props: {
    items: Array,
    single: Boolean,
    uuid: Boolean,
    disabled: Boolean,
    prependIcon: String,
    label: String,
    value: {
      type: [Array, Number, String],
    },
    rules: Array,
  },

  render(h) {
    return h(BranchAccountPicker, {
      props: this.selectProps,
      scopedSlots: this.$scopedSlots,
      on: {
        input: value => {
          if (this.single) {
            if (value.accounts.length > 0) {
              this.$emit('input', value.accounts[0]);
            } else {
              this.$emit('input', null);
            }
          } else {
            this.$emit('input', value.accounts);
          }
        },
      },
    });
  },

  computed: {
    selectProps() {
      return {
        label: this.label,
        prependIcon: this.prependIcon,
        disabled: this.disabled,
        items: this.items,
        value: this.pickerValue,
        single: this.single,
        uuid: this.uuid,
        accountsOnly: true,
        rules: this.rules,
      };
    },

    pickerValue() {
      if (this.single) {
        return {
          accounts: this.value ? [this.value] : [],
        };
      } else {
        return {
          accounts: this.value,
        };
      }
    },
  },
};
