<template>
  <v-input :rules="inputRules" :value="value">
    <div style="width: 100%">
      <v-card v-if="value && value.length">
        <v-flex ma-2 v-sortable="dragReorder">
          <template v-for="(item, i) in value">
            <v-hover :key="item.id">
              <v-text-field
                class="sortItem"
                slot-scope="{ hover }"
                :value="item.text"
                v-bind="hover ? { 'append-icon': 'close' } : {}"
                @click:append="deleteItem(i)"
                @input="v => updateText(v, i)"
              >
                <v-icon slot="append-outer" class="sortHandle">
                  drag_handle
                </v-icon>
              </v-text-field>
            </v-hover>
          </template>
        </v-flex>
      </v-card>

      <text-field v-model="textToAdd" append-icon="mdi-plus-circle-outline" @click:append="addItem" @enter="addItem" />
    </div>
  </v-input>
</template>

<script>
import _ from 'lodash';
import TextField from '@/components/TextField';
import Sortable from '@/directives/Sortable';

export default {
  name: 'TextFunctionsEditor',

  components: { TextField },

  directives: { Sortable },

  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      textToAdd: '',
    };
  },

  computed: {
    inputRules(v) {
      return [
        v => (v && Array.isArray(v) && v.length > 0) || this.$t('rules.required', [this.$t(this.label)]),
        v => {
          const values = {};
          let unique = true;

          v.forEach(({ text }) => {
            if (values[text] === undefined) {
              values[text] = true;
            } else {
              unique = false;
            }
          });

          return unique || this.$t('rules.unique_elements');
        },
      ];
    },
  },

  methods: {
    addItem() {
      if (this.textToAdd) {
        const newValue = [
          ...this.value,
          {
            id: this.value.length ? _.max(_.map(this.value, 'id')) + 1 : 0,
            text: this.textToAdd,
            selected: false,
          },
        ];

        this.$emit('input', newValue);

        this.textToAdd = '';
      }
    },

    deleteItem(idx) {
      const newValue = [...this.value];

      newValue.splice(idx, 1);

      this.$emit('input', newValue);
    },

    dragReorder({ oldIndex, newIndex }) {
      const newList = [...this.value];
      const movedItem = newList.splice(oldIndex, 1)[0];

      newList.splice(newIndex, 0, movedItem);

      this.$emit('input', newList);
    },

    updateText(newText, idx) {
      const newValue = [...this.value];
      const oldItem = this.value[idx];

      newValue[idx] = {
        id: oldItem.id,
        text: newText,
        selected: false,
      };

      this.$emit('input', newValue);
    },
  },
};
</script>
