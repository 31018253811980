<template>
  <v-layout column>
    <v-flex pb-3>
      <v-layout class="align-end">
        <div>
          <h1>{{ $t('Company settings') }}</h1>
        </div>

        <v-spacer />

        <div>
          <v-btn @click="updateSettings" :disabled="!dirty" color="primary" class="mr-0 mb-0">
            {{ $t('Save') }}
          </v-btn>
        </div>
      </v-layout>
    </v-flex>

    <v-flex>
      <v-layout row wrap>
        <v-flex sm12 md12>
          <v-card>
            <v-tabs ref="tabs">
              <v-tab href="#general">
                {{ $t('General') }}
              </v-tab>

              <v-tab href="#shifts">
                {{ $t('Transport and executors') }}
              </v-tab>

              <v-tabs-items touchless>
                <v-tab-item id="general">
                  <v-container fluid>
                    <div class="title">
                      {{ $t('Access') }}
                    </div>

                    <div class="ml-4 pt-4">
                      <v-checkbox label hide-details v-model="agreeSupport">
                        <template slot="label">
                          {{ $t('Settings agree') }}
                        </template>
                      </v-checkbox>
                    </div>

                    <div class="title mt-4">
                      {{ $t('Planned trips settings block') }}
                    </div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1">
                          {{ $t('Trip send before planned settings') }}
                        </span>

                        <v-flex ml-2 xs1>
                          <TimeInput
                            v-model="settings.trip_send_before_planned"
                            :min-value="600"
                            :max-value="86400"
                            duration
                          />
                        </v-flex>
                      </v-layout>
                    </div>

                    <div class="title mt-2">
                      {{ $t('Time in point settings block') }}
                    </div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1">
                          {{ $t('Default stay time') }}
                        </span>

                        <v-flex ml-2 xs1>
                          <TimeInput v-model="settings.stay_time.default" duration />
                        </v-flex>
                      </v-layout>

                      <v-checkbox label hide-details v-model="settings.stay_time.ask_when_adding">
                        <template slot="label">
                          {{ $t('Ask stay time') }}
                        </template>
                      </v-checkbox>
                    </div>

                    <div class="title mt-4">
                      {{ $t('Checkin time in point settings block') }}
                    </div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1">
                          {{ $t('Minimal checkin time') }}
                        </span>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              mdi-help-circle
                            </v-icon>
                          </template>

                          <span>{{ $t('Minimal checkin time should be less than unplanned') }}</span>
                        </v-tooltip>

                        <v-flex ml-2 xs1>
                          <TimeInput
                            v-model="settings.checkin_settings.time_for_checkin_delay"
                            :min-value="60"
                            :max-value="7200"
                            duration
                          />
                        </v-flex>
                      </v-layout>

                      <v-checkbox label hide-details v-model="settings.checkin_settings.ignore_way_order">
                        <template slot="label">
                          {{ $t('Minimal checkin time ignore way') }}
                        </template>
                      </v-checkbox>
                    </div>

                    <div class="title mt-4">{{ $t('Unplanned stop settings block') }}</div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1">
                          {{ $t('Unplanned stop checkin time') }}
                        </span>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              mdi-help-circle
                            </v-icon>
                          </template>

                          <span>{{ $t('Minimal checkin time should be less than unplanned') }}</span>
                        </v-tooltip>

                        <v-flex ml-2 xs1>
                          <TimeInput
                            v-model="settings.checkin_settings.time_for_unplanned_stop"
                            :min-value="this.settings.checkin_settings.time_for_checkin_delay + 1"
                            :max-value="7200"
                            duration
                          />
                        </v-flex>
                      </v-layout>
                    </div>

                    <div class="title mt-2">
                      {{ $t('Point calibration settings block') }}
                    </div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1">{{ $t('Point calibration radius') }}</span>

                        <v-flex ml-2 mr-2 style="max-width: 137px">
                          <v-text-field mask="########" v-model.number="settings.point_calibration.radius" />
                        </v-flex>

                        <span class="subheading grey--text text--darken-1">{{ $t('meters') }}</span>
                      </v-layout>

                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1">
                          {{ $t('Point calibration distance from user') }}
                        </span>

                        <v-flex ml-2 mr-2 style="max-width: 137px">
                          <v-text-field
                            mask="########"
                            v-model.number="settings.point_calibration.distance_from_user"
                          />
                        </v-flex>

                        <span class="subheading grey--text text--darken-1">{{ $t('meters') }}</span>
                      </v-layout>
                    </div>

                    <div class="title mt-4">
                      {{ $t('Branches') }}
                    </div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1 mr-2">
                          {{ $t('Default branch') }}
                        </span>

                        <v-flex style="max-width: 300px">
                          <v-select
                            :items="myBranches"
                            item-text="name"
                            item-value="id"
                            v-model="settings.defaultBranchId"
                          />
                        </v-flex>
                      </v-layout>
                    </div>

                    <div class="title mt-2">
                      {{ $t('Roles') }}
                    </div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1 mr-2">
                          {{ $t('Default role') }}
                        </span>

                        <v-flex style="max-width: 300px">
                          <v-select :items="roles" item-text="name" item-value="id" v-model="settings.defaultRoleId" />
                        </v-flex>
                      </v-layout>
                    </div>

                    <div class="title mt-2">
                      {{ $t('Other settings block') }}
                    </div>

                    <div class="ml-4">
                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1 mr-2">
                          {{ $t('Default navigator') }}
                        </span>

                        <v-flex style="max-width: 300px">
                          <v-select :items="navigatorItems" v-model="settings.defaultNavigator" />
                        </v-flex>
                      </v-layout>

                      <v-layout align-center row>
                        <span class="subheading grey--text text--darken-1 mr-2">
                          {{ $t('Transport type for new employees') }}
                        </span>

                        <v-flex style="max-width: 300px">
                          <TransportTypePicker v-model="settings.defaultTransportType" :prepend-icon="false" label="" />
                        </v-flex>
                      </v-layout>

                      <v-checkbox label hide-details v-model="settings.allowTollRoads">
                        <template slot="label">
                          {{ $t('Allow toll roads') }}
                        </template>
                      </v-checkbox>
                    </div>
                  </v-container>
                </v-tab-item>

                <v-tab-item id="shifts">
                  <v-container fluid>
                    <v-form ref="form">
                      <div class="title mb-2">
                        {{ $t('Shifts of company executors') }}
                      </div>

                      <div class="ml-4">
                        <v-layout align-center row>
                          <span class="subheading grey--text text--darken-1">
                            {{ $t('Working time from') }}
                          </span>

                          <v-flex ml-2 mr-4 style="max-width: 160px">
                            <v-text-field
                              v-model="settings.defaultShifts.executor.workingTime.from"
                              :rules="timeRules"
                            />
                          </v-flex>

                          <span class="subheading grey--text text--darken-1">
                            {{ $t('Working time to') }}
                          </span>

                          <v-flex ml-2 style="max-width: 160px">
                            <v-text-field v-model="settings.defaultShifts.executor.workingTime.to" :rules="timeRules" />
                          </v-flex>
                        </v-layout>
                      </div>

                      <div class="title mt-4 mb-2">
                        {{ $t('Settings of company transport') }}
                      </div>

                      <div class="ml-4">
                        <v-layout align-center row>
                          <span class="subheading grey--text text--darken-1">
                            {{ $t('Working time from') }}
                          </span>

                          <v-flex ml-2 mr-4 style="max-width: 160px">
                            <v-text-field
                              v-model="settings.defaultShifts.transport.workingTime.from"
                              :rules="timeRules"
                            />
                          </v-flex>

                          <span class="subheading grey--text text--darken-1">
                            {{ $t('Working time to') }}
                          </span>

                          <v-flex ml-2 style="max-width: 160px">
                            <v-text-field
                              v-model="settings.defaultShifts.transport.workingTime.to"
                              :rules="timeRules"
                            />
                          </v-flex>
                        </v-layout>

                        <v-layout align-center row>
                          <span class="subheading grey--text text--darken-1">
                            {{ $t('Maximum mileage in one trip') }}
                          </span>

                          <v-flex ml-2 mr-2 style="max-width: 100px">
                            <v-text-field mask="########" v-model.number="transportStageLength" />
                          </v-flex>

                          <span class="subheading grey--text text--darken-1">{{ $t('km') }}</span>
                        </v-layout>
                      </div>
                    </v-form>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-dialog :value="dialog" persistent max-width="550">
      <v-card>
        <v-card-title>
          {{ $t('Agree popup') }}
        </v-card-title>

        <v-card-actions>
          <v-layout wrap :justify-center="$vuetify.breakpoint.smAndDown" :justify-end="$vuetify.breakpoint.mdAndUp">
            <v-btn @click="confirmUncheck" color="error" :class="$vuetify.breakpoint.xs ? 'mb-2' : ''">
              {{ $t('Uncheck') }}
            </v-btn>

            <v-btn @click="dontConfirmUncheck" color="primary">
              {{ $t("Don't uncheck") }}
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import { mapMutations } from 'vuex';

import companyApi from '@/api/company';
import branch from '@/api/branch';
import apiRoles from '@/api/roles';

import TimeInput from '@/components/inputs/TimeInput';
import TransportTypePicker from '@/components/pickers/TransportTypePicker.vue';

const TIME_REGEXP = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;

export default {
  name: 'Settings',

  metaInfo() {
    return {
      title: this.$i18n.t('Company settings'),
    };
  },

  components: { TimeInput, TransportTypePicker },

  data() {
    return {
      dialog: false,
      agreeSupport: this.$store.state.company.terms.support || false,
      settings: {
        trip_send_before_planned: this.$store.state.company.settings.trip_send_before_planned || 3600,
        point_calibration: {
          enable: true,
          radius: this.$store.state.company.settings.point_calibration.radius || 1000,
          distance_from_user: this.$store.state.company.settings.point_calibration.distance_from_user || 1000,
        },
        stay_time: {
          enable: true,
          default: this.$store.state.company.settings.stay_time.default || 1800,
          ask_when_adding: this.$store.state.company.settings.stay_time.ask_when_adding || false,
        },
        checkin_settings: {
          time_for_unplanned_stop: this.$store.state.company.settings.checkin_settings.time_for_unplanned_stop || 1800,
          time_for_checkin_delay: this.$store.state.company.settings.checkin_settings.time_for_checkin_delay || 180,
          ignore_way_order: this.$store.state.company.settings.checkin_settings.ignore_way_order || false,
        },
        allowTollRoads: this.$store.state.company.settings.allowTollRoads || false,
        defaultTransportType: this.$store.state.company.settings.defaultTransportType || 'car',
        defaultNavigator: this.$store.state.company.settings.defaultNavigator || '',
        defaultShifts: {
          executor: {
            workingTime: this.$store.state.company.settings.defaultShifts.executor.workingTime || {
              from: '00:00:01',
              to: '23:59:59',
            },
          },
          transport: {
            workingTime: this.$store.state.company.settings.defaultShifts.transport.workingTime || {
              from: '00:00:01',
              to: '23:59:59',
            },
            stageLength: this.$store.state.company.settings.defaultShifts.transport.stageLength || 100,
          },
        },
        defaultBranchId: this.$store.state.company.settings.defaultBranchId,
        defaultRoleId: this.$store.state.company.settings.defaultRoleId,
      },
      originalAgreeSupport: null,
      originalSettings: null,
      myBranches: [],
      roles: [],
    };
  },

  async created() {
    this.originalSettings = cloneDeep(this.settings);
    this.originalAgreeSupport = this.agreeSupport;

    await this.getMyBranches();
    await this.getRoles();
  },

  computed: {
    navigatorItems() {
      return [
        { text: '', value: '' },
        { text: this.$t('Google maps navigator'), value: 'googleMaps' },
        { text: this.$t('Yandex maps navigator'), value: 'yandexMaps' },
      ];
    },

    dirty() {
      return (
        (!isEqual(this.settings, this.originalSettings) || this.agreeSupport !== this.originalAgreeSupport) &&
        !Object.values(this.$refs.form.errorBag).filter(Boolean).length
      );
    },

    transportStageLength: {
      get() {
        return this.settings.defaultShifts.transport.stageLength / 1000;
      },

      set(value) {
        this.settings.defaultShifts.transport.stageLength = value * 1000;
      },
    },

    timeRules() {
      return [v => TIME_REGEXP.test(v) || this.$t('error.ErrorTimeFormat')];
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar', 'showErrorSnackbar']),

    async getMyBranches() {
      this.myBranches = await branch.listMy({
        accounts: false,
      });
    },

    async getRoles() {
      const { result } = await apiRoles.getRoles({});

      this.roles = result;
    },

    confirmUncheck() {
      this.dialog = false;
      this.agreeSupport = false;
    },

    dontConfirmUncheck() {
      this.dialog = false;
      this.agreeSupport = true;
    },

    async updateSettings() {
      if (
        this.settings.checkin_settings.time_for_unplanned_stop <= this.settings.checkin_settings.time_for_checkin_delay
      ) {
        this.settings.checkin_settings.time_for_unplanned_stop =
          this.settings.checkin_settings.time_for_checkin_delay + 60;
      }

      try {
        if (this.agreeSupport !== this.originalAgreeSupport) {
          await companyApi.update({ agreeSupport: this.agreeSupport });

          this.originalAgreeSupport = this.agreeSupport;
        }

        if (this.settings.stay_time.default) {
          const settings = { ...this.settings };

          await companyApi.updateSettings(settings);

          this.originalSettings = cloneDeep(settings);
        }

        this.showSnackbar(this.$t('Settings has been saved'));
      } catch (e) {
        console.error(e);
        this.showErrorSnackbar(this.$t('Error saving settings'));
      }
    },
  },

  watch: {
    agreeSupport() {
      if (!this.agreeSupport) {
        this.dialog = true;
      }
    },
  },
};
</script>
