
import Vue from 'vue';
import EventBus from '@/event-bus';
import { mapGetters } from 'vuex';

import contractors, { ContractorsResponse } from '@/api/contractors';

import Uuid from '@/models/Uuid';
import { Contractor } from '@/models/Contractor';

import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import Grid from '@/components/grid/GridCrud';
import ShareTripsDialog from '@/components/modals/ShareTripsDialog.vue';
import CreateContractor from '@/components/modals/contractors/CreateContractor.vue';
import EditContractor from '@/components/modals/contractors/EditContractor.vue';
import ContractorsFilter from '@/components/filters/ContractorsFilter.vue';
import ContractorsFilterDto from '@/components/filters/ContractorsFilterDto';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import { isEqual } from 'lodash';

export default Vue.extend({
  name: 'Contractors',

  components: {
    Grid,
    GridTh,
    GridSelectAll,
    ContractorsFilter,
    Checkbox,
    DeleteDialog,
    ShareTripsDialog,
    CreateContractor,
    EditContractor,
  },

  data() {
    return {
      apiFunction: contractors.getContractors,
      contractorIds: [] as string[],
      filter: new ContractorsFilterDto(),
      pagination: {} as {
        page: number;
        rowsPerPage: number;
        sortBy: string;
        descending: boolean;
      },
      selectedContractorToShare: null as string | null,
      contractorToEdit: null as Contractor | null,
      showCreateContractorDialog: false,
      deleteDialog: false,
      deleteError: null,
      deleteItem: null as Contractor | null,
      multiDeleteDialog: false,
      multiDeleteError: null,
      error: null,
      selected: [] as string[],
    };
  },

  async created() {
    this.parseURL();

    EventBus.$on('contractors-refresh', () => {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    });
  },

  beforeDestroy() {
    EventBus.$off('contractors-refresh');
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    fields(): object {
      return {
        name: {
          label: 'ContractorsThs.Name',
          sortable: true,
          defaultSort: true,
        },
        actions: {
          label: 'ContractorsThs.Actions',
          sortable: false,
          align: 'center',
          style: {
            width: '84px',
          },
        },
      };
    },

    deleteItemName(): string {
      const str = this.deleteItem ? ` "${this.deleteItem.name}"` : '';
      return this.$t('common_strings.contractor') + str;
    },
  },

  methods: {
    routerReplace() {
      const query = this.makeQuery();

      if (!isEqual(this.$route.query, query)) {
        this.$router.replace({ query });
      }
    },

    refresh() {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    },

    filterUpdate(value: ContractorsFilterDto) {
      localStorage.setItem('filterContractors', JSON.stringify(value));
      this.filter = new ContractorsFilterDto(value);
    },

    showMultiDeleteDialog() {
      this.multiDeleteError = null;
      this.multiDeleteDialog = true;
    },

    async multiHandleDelete() {
      try {
        if (this.selected.length) {
          await contractors.deleteContractors(this.selected);
        }

        (this.$refs.grid as Vue & { getItems: () => void }).getItems();

        this.multiDeleteDialog = false;
      } catch (err) {
        this.multiDeleteError = err;
      } finally {
        this.selected = [];
      }
    },

    showDeleteDialog(item: Contractor): void {
      this.deleteError = null;
      this.deleteDialog = true;
      this.deleteItem = item;
    },

    async handleDelete(): Promise<void> {
      try {
        if (this.deleteItem?.id) {
          await contractors.deleteContractor(this.deleteItem.id);
        }

        (this.$refs.grid as Vue & { getItems: () => void }).getItems();

        this.deleteDialog = false;
      } catch (err) {
        this.deleteError = err;
      } finally {
        this.selected = this.selected.filter(id => id !== this.deleteItem?.id);
      }
    },

    parseURL(): void {
      if (Object.keys(this.$route.query).length) {
        this.filter = this.filter.fromQuery(this.$route.query);
      } else if (![null, undefined, ''].includes(localStorage.getItem('filterContractors'))) {
        this.filter = new ContractorsFilterDto(JSON.parse(localStorage.getItem('filterContractors') || ''));
      }

      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page as string) + 1;
      }

      if (this.$route.query.pageSize) {
        this.pagination.rowsPerPage = parseInt(this.$route.query.pageSize as string);
      }

      if (this.$route.query.sort) {
        this.pagination.sortBy = String(this.$route.query.sort);
      }

      if (this.$route.query.sortDesc) {
        this.pagination.descending = this.$route.query.sortDesc === '1';
      }
    },

    makeQuery() {
      return {
        ...(this.$refs.grid as Vue & { makeQuery: () => any }).makeQuery(),
        ...this.filter.toQuery(),
      };
    },

    errorUpdate(error: any) {
      this.error = error;
    },

    contractorsUpdate(data: ContractorsResponse) {
      this.contractorIds = data.result.map((contractor: Contractor) => contractor.id);
    },

    async selectAllContractors(value?: boolean) {
      if (!value) {
        return;
      }

      this.selected = this.contractorIds;
    },

    setSelectedContractorToShare(id: Uuid): void {
      this.selectedContractorToShare = id;
    },

    closeShareTripsDialog(): void {
      this.selectedContractorToShare = null;
    },

    setContractorToEdit(contractor: Contractor): void {
      this.contractorToEdit = contractor;
    },

    closeEditContractor(): void {
      this.contractorToEdit = null;
    },

    openCreateContractorDialog(): void {
      this.showCreateContractorDialog = true;
    },

    closeCreateContractorDialog(): void {
      this.showCreateContractorDialog = false;
    },
  },

  watch: {
    filter: {
      handler: async function() {
        await this.routerReplace();
      },
      deep: true,
    },

    pagination: {
      handler: async function(n, o) {
        await this.routerReplace();

        // move to another page in the table
        if (n && o && n.page !== o.page) {
          this.selected = [];
        }
      },
      deep: true,
    },

    contractorIds() {
      if (this.selected.length) {
        this.selected = this.contractorIds.filter((id: string) => this.selected.includes(id));
      }
    },
  },
});
