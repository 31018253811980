import faker from 'faker';
import Trip, { TripStatus, TripMode } from '@/models/Trip';
import TripPoint, {
  BatteryInfo,
  TripPointType,
  TripPointStatus,
  TripPointCheckin,
  BatteryInfoState,
  CheckinStatus,
} from '@/models/TripPoint';
import WayPoint, { WayPointDto, WayPointCheckin } from '@/models/WayPoint';
import { computeSummaryIndicators } from '@/services/tripService';
import { getWayForTrip, makeRandomSteps } from './WayBuilder';
import { PointFunctions } from '@/models/PointFunctions';
import { getTZInfoByCoordinate } from '@/lib/timezone';
import tripPoints from '@/api/tripPoints';
import { PointCoordinates, Timezone } from '@/models/Location';
import { RoadLocationType } from '@/models/Way';

/**
 * Построитель данных точки
 */
class TripPointBuilder {
  private time: Date;
  private point: TripPoint;

  /**
   * @constructor
   */
  constructor(_time: Date, tz?: number) {
    this.time = _time;

    let lngMin = 25;
    let lngMax = 60;
    if (typeof tz === 'number') {
      lngMin = -7.5 + 15 * tz;
      lngMax = 7.5 + 15 * tz;
    }

    const coordinates = {
      lat: faker.random.number({ max: 60, min: 40, precision: 0.000001 }),
      lng: faker.random.number({ min: lngMin, max: lngMax, precision: 0.000001 }),
    };

    const geoTimezone = getTZInfoByCoordinate(coordinates);
    const timezone = {
      name: geoTimezone.timezoneName,
      offset: geoTimezone.timezoneOffsetInSec,
    } as Timezone;

    this.point = new TripPoint({
      id: faker.random.uuid(),
      title: null,
      location: {
        id: faker.random.uuid(),
        googlePlaceId: null,
        timezone,
        coordinates,
        addresses: [
          {
            address: faker.address.streetAddress(),
            locale: faker.random.locale(),
            addressComponents: [],
          },
        ],
        arrivalDuration: null,
        departureDuration: null,
        transportRestrictions: [],
      },
      type: TripPointType.scheduled,
      status: TripPointStatus.active,
      placeLink: null,
      comment: null,
      stayTime: (faker.random.number(15) + 3) * 300,
      workingHours: [],
      deliveryWindows: [],
      functions: {},
      contacts: [],
      checkins: [],
      radius: {
        checkin: faker.random.number(),
        checkout: faker.random.number(),
      },
      createdAt: this.time.toISOString(),
      updatedAt: this.time.toISOString(),
    });
  }

  /**
   * Возвращает entity
   * @return {TripPointEntity}
   */
  getTripPointEntity() {
    return this.point;
  }

  /**
   * Возвращает поездку в формате json
   * @return {Object}
   */
  toJSON() {
    return this.point;
  }

  /**
   * Устанавливает тип точки
   * @param {*} type
   * @return {*}
   */
  setType(type: TripPointType) {
    this.point.type = type;
    if (type !== TripPointType.scheduled) {
      this.point.stayTime = null;
    }
    return this;
  }

  /**
   * Устанаваливает координаты
   */
  setCoordinates(coordinates: PointCoordinates) {
    this.point.location.coordinates = { ...coordinates };
    return this;
  }

  /**
   * Возвращает координаты
   */
  getCoordinates(): PointCoordinates {
    return this.point.location.coordinates;
  }

  /**
   * Устанавливает stay time
   */
  setStayTime(stayTime: number) {
    this.point.stayTime = stayTime;
  }
}

/**
 * Симулятор батареи
 */
export class BatterySimulator {
  private level: number;
  private state: BatteryInfoState;
  private savePowerModeEnabled: boolean;

  constructor(data?: BatteryInfo) {
    if (data && data.state !== BatteryInfoState.unknown) {
      this.level = data.level || 0;
      this.savePowerModeEnabled = data.savePowerModeEnabled;
      this.state = data.state;
      return;
    }

    this.level = Math.random();
    this.savePowerModeEnabled = faker.random.boolean();
    this.state = faker.random.arrayElement([
      BatteryInfoState.unplugged,
      BatteryInfoState.full,
      BatteryInfoState.charging,
    ]);
    if (this.state === BatteryInfoState.full) {
      this.level = 1;
    }
  }

  get value(): BatteryInfo {
    if (Math.random() < 0.25) {
      this.state = faker.random.arrayElement([BatteryInfoState.unplugged, BatteryInfoState.charging]);
    }

    if (this.state === BatteryInfoState.unplugged) {
      this.level -= Math.random() / 4;
    }
    if (this.state === BatteryInfoState.charging) {
      this.level += Math.random() / 4;
    }

    if (this.level >= 1) {
      this.level = 1;
      this.state = BatteryInfoState.full;
    }
    if (this.level <= 0) {
      this.level = 0;
    }

    return new BatteryInfo({
      level: this.level,
      state: this.state,
      savePowerModeEnabled: this.savePowerModeEnabled,
    });
  }
}

/**
 * Построитель данных поездки
 */
export default class TripBuilder {
  private _time: Date;
  private battery: BatterySimulator;
  private trip: Trip;
  private currentIndexesWayPoint = new Map();
  private timezone?: number;

  /**
   * @constructor
   */
  constructor(trip?: Trip, tz?: number) {
    this.timezone = tz;

    if (trip) {
      this.trip = trip;
      this._time = new Date(trip.syncAt || trip.createdAt);
      this.battery = new BatterySimulator(trip.batteryInfo);
      return;
    }

    this._time = faker.date.past(1);
    this.battery = new BatterySimulator();

    const mobileDeviceInfo = {
      os: faker.random.boolean() ? 'android' : 'ios',
      locale: faker.random.locale(),
      vendor: faker.company.companyName() + ' ' + faker.commerce.product(),
      osVersion: '',
      clientVersion: '',
      phoneNumber: faker.phone.phoneNumber('+79#########'),
      isOutdated: faker.random.boolean(),
    };
    if (mobileDeviceInfo.os === 'android') {
      mobileDeviceInfo.osVersion = 'Android SDK ' + (faker.random.number(28) + 1);
    } else {
      mobileDeviceInfo.osVersion =
        'iOS ' + [faker.random.number(20), faker.random.number(20), faker.random.number(20)].join('.');
    }
    mobileDeviceInfo.clientVersion = faker.random.boolean()
      ? [faker.random.number(20), faker.random.number(20)].join('.')
      : [faker.random.number(20), faker.random.number(20), faker.random.number(20)].join('.');

    this.trip = new Trip({
      id: faker.random.uuid(),
      dbId: 0,
      status: TripStatus.active,
      hasChanges: false,
      tripMode: TripMode.auto,
      title: null,
      executorId: null,
      executor: null,
      creatorId: faker.random.uuid(),
      cancelReason: null,
      responsibleId: null,
      transportId: null,
      responsible: null,
      way: {
        activeWay: null,
        passedWay: [],
        optimize: false,
      },
      mobileDeviceInfo,
      batteryInfo: this.battery.value,
      createdAt: this.timeString,
      updatedAt: this.timeString,
      startedAt: null,
      finishedAt: null,
      finishPlanAt: null,
      plannedAt: null,
      syncAt: this.timeString,
      tripPoints: [],
      wayPoints: [],
      transport: null,
      settings: null,
      isLostSync: false,
    });
  }

  /**
   * Получить время построителя
   */
  get time() {
    return new Date(this._time);
  }

  get timeString() {
    return this.time.toISOString();
  }

  /**
   * Возвращает entity
   */
  getTrip() {
    this.updateWay();
    return this.trip;
  }

  /**
   * Сдвигает время построителя на sec секунд
   * @param {Number} sec
   * @return {*}
   */
  shiftTime(sec?: number) {
    if (!sec) {
      sec = faker.random.number(3600);
    }

    if (this.trip.way.passedWay.length > 0) {
      const currentPosition = this.trip.way.passedWay[this.trip.way.passedWay.length - 1];
      const currentTripPoint = this.trip.tripPoints.find(tripPoint => tripPoint.status === TripPointStatus.current);
      const firstActiveTripPoint = this.trip.tripPoints.find(tripPoint => tripPoint.status === TripPointStatus.active);
      if (!currentTripPoint && firstActiveTripPoint) {
        this.trip.way.passedWay = this.trip.way.passedWay.concat(
          makeRandomSteps(
            currentPosition,
            firstActiveTripPoint.getCoordinates(),
            this.time.getTime() / 1000,
            this.time.getTime() / 1000 + sec,
          ),
        );
      }
    }

    this._time.setTime(this._time.getTime() + sec * 1000);
    this.trip.syncAt = this.time;
    this.trip.batteryInfo = this.battery.value;

    return this;
  }

  /**
   * Добавляет точку и связанный с ней вейпойнт
   * @param {Function} fn
   * @return {TripBuilder}
   */
  addPoint(fn?: (arg0: TripPointBuilder) => void) {
    const builder = new TripPointBuilder(this._time, this.timezone);
    if (fn) {
      fn(builder);
    }

    let finishIndex = this.trip.tripPoints.findIndex(p => p.type === TripPointType.finish);
    if (finishIndex === -1) {
      finishIndex = this.trip.tripPoints.length;
    }

    const tripPoint = builder.getTripPointEntity();
    this.trip.tripPoints.splice(finishIndex, 0, tripPoint);
    this._addWayPoint({
      id: faker.random.uuid(),
      checkins: [],
      tripPointIds: [tripPoint.id],
      coordinates: tripPoint.location.coordinates,
      radius: tripPoint.radius,
    });

    this.updateWay();

    return this;
  }

  /**
   * @private
   * @param {*} sec
   */
  private _addWayPoint(data: WayPointDto) {
    const wayPoint = new WayPoint(data);
    this.trip.wayPoints.push(wayPoint);
  }

  /**
   * Установить ручной режим поездки
   * @param {this} sec
   */
  setManualTripMode() {
    this.trip.tripMode = TripMode.manual;
    return this;
  }

  /**
   * Добавляет точку к уже существующему вейпойнту
   * @param {Function} fn
   * @return {TripBuilder}
   */
  addMultipoint(num: number, fn?: (arg0: TripPointBuilder) => void) {
    const builder = new TripPointBuilder(this._time, this.timezone);
    builder.setCoordinates(this.trip.tripPoints[num].getCoordinates());
    if (fn) {
      fn(builder);
    }

    let finishIndex = this.trip.tripPoints.findIndex(p => p.type === TripPointType.finish);
    if (finishIndex === -1) {
      finishIndex = this.trip.tripPoints.length;
    }

    const tripPoint = builder.getTripPointEntity();
    this.trip.tripPoints.splice(finishIndex, 0, tripPoint);

    const existingTripPoint = this.trip.tripPoints[num];
    this.trip.wayPoints.forEach(wayPoint => {
      if (wayPoint.tripPointIds.includes(existingTripPoint.id)) {
        wayPoint.tripPointIds.push(tripPoint.id);
      }
    });

    this.updateWay();

    return this;
  }

  /**
   * Добавляет старт и финиш
   * @return {*}
   */
  addStartAndFinish() {
    let coordinates: PointCoordinates;
    return this.addPoint(p => {
      p.setType(TripPointType.start);
      coordinates = p.getCoordinates();
    }).addPoint(p => p.setType(TripPointType.finish).setCoordinates(coordinates));
  }

  /**
   * Начало работ в точке (Для ручного режима).
   * @return {this}
   */
  beginWork(num: number) {
    const tripPoint = this.trip.tripPoints[num];

    const checkinData = {
      id: faker.random.uuid(),
      inDate: this.time.toISOString(),
      outDate: null,
      status: CheckinStatus.auto,
      batteryInfo: this.battery.value,
    };

    tripPoint.status = TripPointStatus.current;
    this.reorderPointBeforeActive(num);

    if (!tripPoint.checkins.length) {
      tripPoint.checkins.push(new TripPointCheckin(checkinData, tripPoint));
    }

    return this;
  }

  /**
   * Конец работ в точке (Для ручного режима).
   * @return {this}
   */
  endUpWork() {
    const tripPointWithCurrentStatus = this.trip.tripPoints.filter(
      tripPoint => tripPoint.status === TripPointStatus.current,
    );

    tripPointWithCurrentStatus.forEach(point => {
      point.checkins[0].outDate = this.time.toISOString() as any;
    });
    // ...
    return this;
  }

  /**
   * Въехать в точку или мультиточку
   * @return {this}
   */
  checkin(num: number) {
    const { tripPoints } = this.trip;
    const tripPoint = this.trip.tripPoints[num];
    const currentTime = this.time.toISOString();

    if (tripPoint) {
      if (tripPoint.type === TripPointType.start) {
        this.trip.startedAt = this.time;
      }

      const checkinData = (tripPint?: boolean) => ({
        id: faker.random.uuid(),
        inDate: currentTime,
        outDate: tripPint && this.trip.tripMode !== TripMode.manual ? currentTime : null,
        status: CheckinStatus.auto,
        batteryInfo: this.battery.value,
      });

      let currentWayPoint = null as WayPoint | null;

      this.trip.wayPoints.forEach(wayPoint => {
        if (wayPoint.tripPointIds.includes(tripPoint.id)) {
          wayPoint.checkins.push(new WayPointCheckin(checkinData(), wayPoint));
          currentWayPoint = wayPoint;
        }
      });

      if (currentWayPoint && this.currentIndexesWayPoint.has(currentWayPoint.id)) {
        num = this.currentIndexesWayPoint.get(currentWayPoint.id);
      }

      if (this.trip.tripMode === TripMode.auto || tripPoint.type === TripPointType.start) {
        const tripPointids: string[] = tripPoints.map(tripPoint => tripPoint.id);
        if (currentWayPoint && currentWayPoint.tripPointIds.length > 1) {
          for (let i = num; i < tripPoints.length; i++) {
            const tripPoint = tripPoints[i];

            if (currentWayPoint.tripPointIds.includes(tripPoint.id)) {
              if (tripPoint.status !== TripPointStatus.passed) {
                tripPoint.status = TripPointStatus.current;
                tripPoint.checkins.push(new TripPointCheckin(checkinData(true), tripPoint));
                this.reorderPointBeforeActive(num);
                tripPointids[i] = 'empty';
              }
            } else {
              // запоминаем index следующего tripPointId для текущего wayPoint
              for (let i = 0; i < tripPointids.length; i++) {
                if (currentWayPoint.tripPointIds.includes(tripPointids[i])) {
                  this.currentIndexesWayPoint.set(currentWayPoint.id, i);
                  break;
                }
              }
              break;
            }
          }
        } else {
          tripPoint.status = TripPointStatus.current;
          tripPoint.checkins.push(new TripPointCheckin(checkinData(true), tripPoint));
        }
      }

      if (this.trip.way.passedWay.length > 0) {
        this.trip.way.passedWay.push({
          ...tripPoint.getCoordinates(),
          type: RoadLocationType.control,
          timestamp: this.time.getTime() / 1000,
        });
      } else {
        this.trip.way.passedWay.push({
          ...tripPoint.getCoordinates(),
          type: RoadLocationType.control,
          timestamp: this.time.getTime() / 1000,
        });
      }
    }

    return this;
  }

  /**
   * Выехать из текущей точки
   * @return {this}
   */
  checkout() {
    const tripPointWithCurrentStatus = this.trip.tripPoints.filter(
      tripPoint => tripPoint.status === TripPointStatus.current,
    );
    tripPointWithCurrentStatus.forEach(point => {
      point.status = TripPointStatus.passed;
    });

    this.trip.wayPoints.forEach(wayPoint => {
      wayPoint.checkins.forEach(checkin => {
        if (checkin.outDate === null) {
          checkin.outDate = this.time;
        }
      });
    });

    // in manual mode set the time from checkin
    if (this.trip.tripMode === TripMode.manual) {
      this.trip.tripPoints.forEach(tripPoint => {
        tripPoint.checkins.forEach(checkin => {
          if (checkin.outDate === null) {
            checkin.outDate = this.time;
          }
        });
      });
    }

    return this;
  }

  /**
   * Пометить точку удаленной
   * @return {this}
   */
  deletePoint(num: number) {
    const { tripPoints } = this.trip;
    const tripPoint = tripPoints[num];

    tripPoint.status = TripPointStatus.deleted;

    this.trip.wayPoints.forEach((wayPoint, index) => {
      wayPoint.tripPointIds = wayPoint.tripPointIds.filter(tripPointId => tripPointId !== tripPoint.id);
      if (!wayPoint.tripPointIds.length) {
        this.trip.wayPoints.splice(index, 1);
      }
    });

    return this;
  }

  /**
   * Пометить точку пройденной
   * @return {this}
   */
  mark(num: number) {
    const { tripPoints } = this.trip;
    const tripPoint = tripPoints[num];

    const currentTime = this.time.toISOString();

    if (tripPoint.status === TripPointStatus.active) {
      const checkinData = {
        id: faker.random.uuid(),
        inDate: currentTime,
        outDate: currentTime,
        status: CheckinStatus.marked,
        batteryInfo: this.battery.value,
      };

      tripPoint.status = TripPointStatus.marked;
      tripPoint.checkins.push(new TripPointCheckin(checkinData, tripPoint));

      this.reorderPointBeforeActive(num);
    }

    return this;
  }

  /**
   * Переставить точку перед active
   * @return {void}
   */
  private reorderPointBeforeActive(num: number) {
    const { tripPoints } = this.trip;
    const tripPoint = tripPoints[num];
    let copyTripPoint = Object.assign(tripPoints[num]);

    tripPoints.splice(num, 1);

    for (let i = 0; i < tripPoints.length; i++) {
      if (tripPoints[i].status === TripPointStatus.active) {
        tripPoints.splice(i, 0, copyTripPoint);
        break;
      }
    }
  }

  /**
   * Завершить поездку (въехать в финиш)
   * @return {this}
   */
  finish() {
    const finishPoint = this.trip.getFinish();

    if (finishPoint) {
      const checkinData = {
        id: faker.random.uuid(),
        inDate: this.timeString,
        outDate: this.timeString,
        status: CheckinStatus.auto,
        batteryInfo: this.battery.value,
      };

      finishPoint.status = TripPointStatus.passed;
      finishPoint.checkins.push(new TripPointCheckin(checkinData, finishPoint));

      this.trip.wayPoints.forEach(wayPoint => {
        if (wayPoint.tripPointIds.includes(finishPoint.id)) {
          wayPoint.checkins.push(new WayPointCheckin(checkinData, wayPoint));
        }
      });
    }

    this.trip.finishedAt = this.time;

    return this;
  }

  addFunctionsToPoint(num: number) {
    this.trip.tripPoints[num].functions = new PointFunctions({
      id: faker.random.uuid(),
      droplist: [
        {
          id: 0,
          text: 'First',
          selected: false,
        },
        {
          id: 1,
          text: 'Second',
          selected: true,
        },
      ],
      checklist: [
        {
          id: 0,
          text: 'Первый',
          selected: false,
        },
        {
          id: 1,
          text: 'Второй',
          selected: true,
        },
      ],
      // attachFiles: [
      //   {
      //     id: 1,
      //     attachType: 'image',
      //     fileUri: {
      //       preview: 'https://tst2005.github.io/love-doc/wiki/love_game_logo_256x256.png',
      //       original: 'https://tst2005.github.io/love-doc/wiki/love_game_logo_256x256.png',
      //     },
      //     createdAt: this.timeString,
      //     updatedAt: this.timeString,
      //   },
      // ],
    });
  }

  /**
   * Установить статус notScheduled к точке
   * @return {this}
   */
  setNotScheduled(num: number) {
    const checkinData = {
      id: faker.random.uuid(),
      inDate: this.timeString,
      outDate: this.timeString,
      status: CheckinStatus.auto,
      batteryInfo: this.battery.value,
    };

    const tripPoint = this.trip.tripPoints[num];
    tripPoint.type = TripPointType.notScheduled;
    tripPoint.status = TripPointStatus.passed;
    tripPoint.checkins.push(new TripPointCheckin(checkinData, tripPoint));
    return this;
  }

  /**
   * Устанавливает временную зону
   * @param {number | undefined} tz Зона - число, от -12 до +11
   */
  setTimezone(tz?: number) {
    this.timezone = tz;
  }

  /**
   * Обновляет маршрут поездки
   */
  private updateWay() {
    this.trip.way.activeWay = getWayForTrip(this.trip);
    this.trip.summaryIndicators = computeSummaryIndicators(this.trip);

    const time = this.time;
    for (const tripPoint of this.trip.tripPoints) {
      if (tripPoint.status === TripPointStatus.active) {
        tripPoint.arrivalPlanAt = new Date(time);
      }
    }

    const tripPointMap = this.trip.getTripPointsById();

    for (const leg of this.trip.way.activeWay.legs) {
      time.setTime(time.getTime() + leg.summary.travelTime * 1000);

      const tripPoint = tripPointMap.get(leg.toTripPointId);
      if (tripPoint) {
        tripPoint.arrivalPlanAt = new Date(time);
      }
    }
  }
}
