import Uuid from './Uuid';
import { Checkin, CheckinDto, PointRadius } from './TripPoint';
import { PointCoordinates } from './Location';

export class WayPointCheckin extends Checkin {
  protected wayPoint: WayPoint;

  constructor(data: CheckinDto, parent: WayPoint) {
    super(data);
    this.wayPoint = parent;
  }

  getTripPoint() {
    return null;
  }

  getWayPoint() {
    return this.wayPoint;
  }
}

export interface WayPointDto {
  id: Uuid;
  coordinates: PointCoordinates;
  tripPointIds: Uuid[];
  checkins: CheckinDto[];
  radius: PointRadius;
}

export default class WayPoint {
  /** ID of way point */
  id: Uuid;

  /** Координаты */
  coordinates: PointCoordinates;

  /** Список трип пойнтов который входят в вей пойнт */
  tripPointIds: Uuid[];

  /** Чекины в вей пойнт */
  checkins: WayPointCheckin[];

  /** Радиусы чекина-чекаута */
  radius: PointRadius;

  constructor(data: WayPointDto) {
    this.id = data.id;
    this.coordinates = data.coordinates;
    this.tripPointIds = data.tripPointIds;
    this.radius = data.radius;

    this.checkins = data.checkins.map(checkin => new WayPointCheckin(checkin, this));
  }

  toJSON() {
    return {
      id: this.id,
      coordinates: this.coordinates,
      radius: this.radius,
      tripPointIds: this.tripPointIds,
      checkins: this.checkins.map(checkin => checkin.toJSON()),
    };
  }

  isCurrent() {
    return this.checkins.some(checkin => {
      return checkin.inDate && !checkin.outDate;
    });
  }
}
