import c from '@/lib/coerce';
import { Dictionary } from 'vue-router/types/router';

class DistributionsFilterDto {
  date = {
    from: null as string | null,
    to: null as string | null,
  };

  owners: string[] = [];

  configurations: string[] = [];

  statuses: string[] = [];

  unplannedOrders: boolean = false;

  name: string | null = null;

  constructor(data?: Partial<DistributionsFilterDto>) {
    Object.assign(this, data);
  }

  fromQuery(query: Dictionary<string | (string | null)[]>) {
    const data = {
      date: {
        from: query.dateFrom,
        to: query.dateTo,
      },
      owners: c(query.owners)
        .array()
        .int()
        .coerce(),
      configurations: c(query.configurations)
        .array()
        .coerce(),
      statuses: c(query.statuses)
        .array()
        .coerce(),
      unplannedOrders: c(query.unplannedOrders)
        .boolean()
        .coerce(),
      name: query.name,
    };

    Object.assign(this, data);

    return this;
  }

  toQuery(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      dateFrom: typeof this.date.from === 'string' ? new Date(this.date.from).toISOString() : null,
      dateTo: typeof this.date.to === 'string' ? new Date(this.date.to).toISOString() : null,
      owners: this.owners.map(v => `${v}`),
      configurations: this.configurations.map(v => `${v}`),
      statuses: this.statuses.map(v => `${v}`),
      unplannedOrders: this.unplannedOrders ? 'true' : null,
      name: this.name,
    };

    return this.queryWithoutEmptyValues(query);
  }

  toQueryForCheck(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      dateFrom: typeof this.date.from === 'string' ? new Date(this.date.from).toISOString() : null,
      dateTo: typeof this.date.to === 'string' ? new Date(this.date.to).toISOString() : null,
      owners: this.owners.length === 1 ? String(this.owners[0]) : this.owners.map(v => `${v}`),
      configurations:
        this.configurations.length === 1 ? String(this.configurations[0]) : this.configurations.map(v => `${v}`),
      statuses: this.statuses.length === 1 ? String(this.statuses[0]) : this.statuses.map(v => `${v}`),
      unplannedOrders: this.unplannedOrders ? 'true' : null,
      name: this.name,
    };

    return this.queryWithoutEmptyValues(query);
  }

  queryWithoutEmptyValues(query: Dictionary<string | string[] | null>): Dictionary<string | string[]> {
    for (const i in query) {
      if (query[i] === null || query[i] === undefined || (query[i] instanceof Array && query[i]?.length === 0)) {
        delete query[i];
      }
    }

    return query as Dictionary<string | string[]>;
  }

  toHttpParams() {
    const query = this.toQuery();

    if (typeof query.dateFrom === 'string') {
      query.dateFrom = new Date(query.dateFrom).toISOString();
    } else {
      const dateFrom = new Date();

      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
      dateFrom.setMilliseconds(0);

      query.dateFrom = dateFrom.toISOString();
    }

    if (typeof query.dateTo === 'string') {
      query.dateTo = new Date(query.dateTo).toISOString();
    } else {
      const dateTo = new Date();

      dateTo.setDate(dateTo.getDate() + 1);
      dateTo.setHours(0);
      dateTo.setMinutes(0);
      dateTo.setSeconds(0);
      dateTo.setMilliseconds(0);

      query.dateTo = dateTo.toISOString();
    }

    return query;
  }

  toQueryStringParams() {
    const data = this.toHttpParams();

    if (Object.keys(data).length) {
      let queryString = new URLSearchParams();
      for (const [fieldName, value] of Object.entries(data)) {
        if (Array.isArray(value) && value.length) {
          for (const item of value) {
            queryString.append(fieldName + '[]', item);
          }
        }

        if (['boolean', 'string', 'number'].includes(typeof value)) {
          queryString.append(fieldName, String(value));
        }
      }

      return queryString.toString();
    }

    return '';
  }
}

export default DistributionsFilterDto;
