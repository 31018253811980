import Vue from 'vue';
import TypeIcon, { defaultIconProps, IconData } from './TypeIcon';

export default Vue.extend({
  functional: true,

  name: 'PlaceIcon',

  props: {
    ...defaultIconProps,
  },

  render(h, { props, parent }) {
    let data: IconData = { icon: 'star', tooltip: '' };
    return h(TypeIcon, {
      props: { icon: data.icon },
    });
  },
});
