import { isNil } from 'lodash';

const HOUR_REGEXP = /^[0-2][0-9]\:[0-5][0-9]$/;

function secondsToTimezoneFormat(totalSeconds: number) {
  if (totalSeconds === 0 || !totalSeconds) {
    return 'Z';
  }

  const isNegative = totalSeconds < 0;

  totalSeconds = Math.abs(totalSeconds);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);

  let result = isNegative ? '-' : '+';

  result += hours < 10 ? '0' + hours : hours;
  result += ':' + (minutes < 10 ? '0' + minutes : minutes);

  return result;
}

function toTimestamp(
  value: string | number | Date,
  timezoneOffset: number | null = null,
  currentTimestamp = Date.now(),
) {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string' && value.length === 5 && HOUR_REGEXP.test(value)) {
    const timezone = !isNil(timezoneOffset) ? secondsToTimezoneFormat(timezoneOffset) : '';
    const partsOfTime = value.split(':');
    const date = new Date(currentTimestamp);

    date.setHours(Number(partsOfTime[0]), Number(partsOfTime[1]), 0, 0);

    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const formattedDate = `${date.getFullYear()}-${month}-${day}`;

    value = `${formattedDate}T${value}:00.000${timezone}`;
  }

  return new Date(value).valueOf();
}

export { toTimestamp };
