<template>
  <div>
    <h1>{{ $t('User Agreement') }}</h1>

    <p>
      Настоящий документ «Пользовательское соглашение» (далее – Соглашение) представляет собой предложение Общества с
      ограниченной ответственностью «ХоумСтретч Рус» (далее – «Правообладатель») заключить договор на изложенных ниже
      условиях.
    </p>

    <h4>1. Общие положения</h4>
    <p>
      1.1. В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и
      определения:
    </p>
    <p>
      а) <b>Сервис</b> – совокупность информационных служб и связанных с ними функциональных возможностей,
      предоставляемых с использованием Приложения.
    </p>
    <p>б) <b>Приложение</b> – Сайт и Мобильное приложение, на основе которых реализуется Сервис.</p>
    <p>
      в) <b>Сайт</b> – автоматизированная информационная система, доступная в сети Интернет по адресу (включая
      поддомены) <a href="https://co.homestretch.ch/">https://co.homestretch.ch/</a>.
    </p>
    <p>
      г) <b>Мобильное приложение</b> – предназначенная для установки и использования на мобильном устройстве программа
      для ЭВМ, позволяющая пользоваться различными опциями Сервиса.
    </p>
    <p>
      д) <b>Пользователь</b> – лицо, заключившее настоящее Соглашение с Правообладателем в своем или чужом интересе в
      соответствии с требованиями действующего законодательства и настоящего Соглашения.
    </p>
    <p>
      е) <b>Личный кабинет</b> – персональный раздел Приложения в виде учетной записи или панели управления, к которому
      Пользователь получает доступ после прохождения регистрации и/или авторизации в Сервисе. Личный кабинет
      предназначен для хранения персональных данных Пользователя, просмотра и управления доступными функциональными
      возможностями Сервиса.
    </p>
    <p>
      ж) <b>Тестовый период</b> – период времени, на который доступ к Сервису предоставляется без оплаты в целях его
      тестирования и оценки функциональных возможностей. Оплата за Тестовый период не взимается. Если иное не оговорено
      при предоставлении доступа к Сервису или в обязательных документах, продолжительность Тестового периода составляет
      30 (Тридцать) календарных дней с момента предоставления доступа.
    </p>
    <p>
      1.2. В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в п.1.1. Соглашения.
      В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае отсутствия
      однозначного толкования термина или определения в тексте Соглашения следует руководствоваться его толкованием,
      определенным: в первую очередь – указанными в нем Обязательными документами, во вторую очередь – законодательством
      Российской Федерации, и в последующем — обычаями делового оборота и научной доктриной.
    </p>
    <p>
      1.3. Использование вами Сервиса любым способом и в любой форме в пределах его объявленных функциональных
      возможностей, включая:
    </p>
    <p>регистрация и/или авторизация в Сервисе;</p>
    <p>использование Сервиса в рамках Тестового периода;</p>
    <p>оформление заказа на предоставление доступа к функциональным возможностям Сервиса;</p>
    <p>иное использование Сервиса,</p>
    <p>
      создает договор на условиях настоящего Соглашения и указанных в нем обязательных для Сторон документов в
      соответствии с положениями ст.437 и 438 Гражданского кодекса Российской Федерации.
    </p>
    <p>1.4. Воспользовавшись любой из указанных выше возможностей по использованию Сервиса вы подтверждаете, что:</p>
    <p>
      а) Ознакомились с условиями настоящего Соглашения и указанных в нем Обязательных документов в полном объеме до
      начала использования Сервиса.
    </p>
    <p>
      б) Принимаете все условия настоящего Соглашения и указанных в нем Обязательных документов в полном объеме без
      каких-либо изъятий и ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование Сервиса.
      Если вы не согласны с условиями настоящего Соглашения и указанных в нем Обязательных документов или не имеете
      права на заключение договора на их основе, вам следует незамедлительно прекратить любое использование Сервиса.
    </p>
    <p>
      в) Соглашение (в том числе любая из его частей) и/ или указанные в нем Обязательные документы могут быть изменены
      Правообладателем без какого-либо специального уведомления. Новая редакция Соглашения и/или указанных в нем
      Обязательных документов вступает в силу с момента размещения на Сайте либо доведения до сведения Пользователя в
      иной удобной форме, если иное не предусмотрено новой редакцией Соглашения и/или указанных в нем обязательных для
      Сторон документов.
    </p>

    <h4>2. Общие условия пользования Сервисом</h4>
    <p>
      2.1. Обязательным условием заключения настоящего Соглашения является полное и безоговорочное принятие и соблюдение
      Пользователем в установленных ниже случаях требований и положений, определенных следующими документами
      («Обязательные документы»):
    </p>
    <p>
      а) <b>Лицензионное соглашение на Мобильное приложение</b>, размещенное и/или доступное в сети Интернет по адресу
      <a href="https://co.homestretch.ch/license-agreement">https://co.homestretch.ch/license-agreement</a>, которое
      устанавливает порядок использования Мобильного приложения Пользователем.
    </p>
    <p>
      б) <b>Политика конфиденциальности</b>, размещенная и/или доступная в сети Интернет по адресу
      <a href="https://co.homestretch.ch/policy">https://co.homestretch.ch/policy</a>, которая содержит правила
      предоставления и использования персональной информации, включая персональные данные Пользователя.
    </p>
    <p>
      в) <b>Правила оказания информационных услуг</b>, которые размещены и/или доступны в сети Интернет по адресу
      <a href="https://co.homestretch.ch/rules">https://co.homestretch.ch/rules</a> и являются обязательным для Сторон
      документом в случае оформления Пользователем заказа на приобретение доступа к определенным в них функциональным
      возможностям Сервиса.
    </p>
    <p>
      2.2. Для использования функциональных возможностей Сервиса обязательны регистрация и/или авторизация Пользователя
      в Сервисе в соответствии с установленными Правообладателем правилами, а также заключение договора возмездного
      оказания услуг (за исключением Тестового периода).
    </p>
    <p>
      2.3. Перечень функциональных возможностей, доступных Пользователю в течение Тестового периода, определяется по
      единоличному усмотрению Правообладателя и может время от времени изменяться.
    </p>
    <p>
      2.4. Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по вопросам,
      предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Пользователь
      предоставляет неверную информацию или у Правообладателя есть основания полагать, что предоставленная Пользователем
      информация неполна или недостоверна, Правообладатель имеет право по своему усмотрению заблокировать либо удалить
      Личный кабинет Пользователя, а также отказать Пользователю в использовании Сервиса полностью или в определенной
      части.
    </p>
    <p>
      2.5. Правообладатель оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных,
      указанных при регистрации, и запросить в связи с этим подтверждающие документы (в частности документы,
      удостоверяющие личность), непредоставление которых, по усмотрению Правообладателя, может быть приравнено к
      предоставлению недостоверной информации и повлечь последствия, предусмотренные п. 2.4. Соглашения.
    </p>
    <p>
      2.6. В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным,
      указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют
      идентифицировать пользователя, Правообладатель вправе применить меры, указанные в п.2.4. Соглашения.
    </p>
    <p>
      2.7. Технические, организационные и коммерческие условия использования Сервиса могут доводиться до сведения
      Пользователей путем отдельного размещения на Сайте или путем нотификации Пользователей.
    </p>
    <p>
      2.8. Правообладатель вправе устанавливать лимиты и вводить иные технические ограничения использования Сервиса,
      которые время от времени будут доводиться до сведения Пользователей в форме и способом по выбору Правообладателя.
    </p>
    <p>
      2.9. Пользователь поручает Правообладателю осуществлять обработку персональных данных лиц, с которыми он
      взаимодействует в рамках Сервиса. Разрешенные действия (операции) с персональными данными включают: сбор, запись,
      хранение, накопление, систематизацию, уточнение (обновление, изменение), извлечение, использование, передачу
      (предоставление, доступ) третьим лицам, обезличивание, блокирование, удаление, уничтожение в соответствии с
      правилами использования доступных функциональных возможностей Сервиса.
    </p>
    <p>
      2.10. Обработка персональных данных Правообладателем осуществляется с целью обеспечения Пользователю технической
      возможности заключения и/или исполнения договоров и/или административных функций, стороной которых является
      Пользователь и субъект персональных данных.
    </p>
    <p>
      2.11. Правообладатель обязуется соблюдать конфиденциальность обрабатываемых им персональных данных и обеспечивать
      их безопасность при обработке в соответствии с положениями настоящего Соглашения.
    </p>

    <h4>3. Гарантии Пользователя</h4>
    <p>Принимая условия настоящего Соглашения, вы подтверждаете и гарантируете, что:</p>
    <p>
      3.1. Вы обладаете всеми необходимыми правами и полномочиями для заключения Договора на использование Сервиса и его
      исполнения;
    </p>
    <p>
      3.2. Использование Сервиса будет осуществляться вами исключительно для целей, разрешенных настоящим Соглашением с
      соблюдением его положений, а равно требований применимого права и общепринятой практики;
    </p>
    <p>
      3.3. Вы не будете совершать каких-либо действий, которые вступают в противоречие или препятствуют предоставлению
      Сервиса или работе соответствующего оборудования, сетей, или программного обеспечения, с помощью которых
      предоставляется Сервис.
    </p>
    <p>
      3.4. Вами получено надлежащее согласие Пользователей и/или третьих лиц, данные которых обрабатываются вами с
      использованием Сервиса, на обработку их персональных данных и передачу третьим лицам, либо что вы не обязаны
      получать такое согласие в соответствии с действующим законодательством.
    </p>

    <h4>4. Ограничения</h4>
    <p>Соглашаясь с условиями настоящего Соглашения, вы понимаете и признаете, что:</p>
    <p>
      4.1. К отношениям Сторон по предоставлению Сервиса на безвозмездной основе не подлежат применению положения
      законодательства о защите прав потребителей.
    </p>
    <p>
      4.2. Сервис предоставляется на условиях «как есть», в связи с чем вам не представляются какие-либо гарантии, что
      Сервис будет соответствовать вашим требованиям; услуги будут предоставляться непрерывно, быстро, надежно и без
      ошибок; результаты, которые могут быть получены с использованием Сервиса, будут точными и надежными; качество
      какого-либо продукта, услуги и информации, полученных с использованием Сервиса, будет соответствовать вашим
      ожиданиям; все ошибки в программном обеспечении Сервиса будут исправлены.
    </p>
    <p>
      4.3. Поскольку Сервис находится на стадии постоянного дополнения и обновления новых функциональных возможностей,
      форма и характер предоставляемых услуг могут время от времени меняться без вашего предварительного уведомления.
      Правообладатель вправе по собственному усмотрению прекратить (временно или окончательно) предоставление услуг (или
      каких-либо отдельных функций в рамках услуг) всем Пользователям вообще или вам, в частности, без вашего
      предварительного уведомления.
    </p>
    <p>4.4. Вы не имеете права самостоятельно или с привлечением третьих лиц:</p>
    <p>
      копировать (воспроизводить) в любой форме и способом входящие в состав Сервиса Правообладателя программы для ЭВМ и
      базы данных, включая любые их элементы, без получения предварительного письменного согласия их владельца;
    </p>
    <p>
      вскрывать технологию, эмулировать, декомпилировать, дизассемблировать, дешифровать, и производить иные аналогичные
      действия с Сервисом;
    </p>
    <p>
      создавать программные продукты и/или сервисы с использованием Сервиса без получения предварительного разрешения
      Правообладателя.
    </p>
    <p>
      4.5. Пользователям запрещается использование Сервиса для совершения противоправных действий, включая получение
      несанкционированного доступа к конфиденциальной информации, ее распространение, неправомерный доступ к
      компьютерной информации, использование и распространение вредоносных компьютерных программ, нарушение правил
      эксплуатации средств хранения, обработки или передачи компьютерной информации и информационно-телекоммуникационных
      сетей, организации азартных игр, проведения лотерей и иных стимулирующих мероприятий в нарушение действующего
      законодательства.
    </p>
    <p>
      4.6. При обнаружении ошибок в работе Сервиса сообщите об этом Правообладателю через Личный кабинет (раздел
      настроек Мобильного приложения) либо по адресу, указанному в реквизитах или отдельно на Сайте для службы
      поддержки.
    </p>
    <p>
      4.7. В случае многократного или грубого нарушения условий настоящего Соглашения, включая указанные в нем
      Обязательные документы, и/или требований законодательства, Правообладатель оставляет за собой право заблокировать
      доступ к Сервису либо иным образом ограничить (прекратить) предоставление Пользователю услуг с использованием
      Сервиса.
    </p>
    <p>
      4.8 . В случае привлечения Правообладателя к ответственности или наложения на него взыскания в связи с допущенными
      вами нарушениями прав и/или интересов третьих лиц, а равно установленных законодательством запретов или
      ограничений, вы обязаны в полном объеме возместить убытки Правообладателя.
    </p>
    <p>
      4.9. При любых обстоятельствах ответственность Правообладателя в связи с предоставлением Сервиса ограничена 1 000
      (Одной тысячей) рублей и возлагается на Правообладателя исключительно при наличии в его действиях доказанной вины.
    </p>

    <h4>5. Уведомления</h4>
    <p>
      5.1. Вы соглашаетесь получать от Правообладателя на указанный при работе с Сервисом электронный адрес
      информационные электронные сообщения, а также входящие вызовы (далее — «нотификаторы») с сообщениями о важных
      событиях, происходящих в рамках Сервиса или в связи с ним.
    </p>
    <p>
      5.2. Правообладатель вправе использовать нотификаторы в рамках информационного обслуживания для информирования
      Пользователя об изменениях и новых возможностях Сервиса и/или об изменении Соглашения или указанных в нем
      Обязательных документов, а также в связи с оформлением Заказов, заключением и исполнением Договоров, либо
      проведением маркетинговых акций, в том числе в рекламных целях.
    </p>

    <h4>6. Соглашение об использовании электронной подписи</h4>
    <p>
      6.1. Во взаимоотношениях между Правообладателем и Пользователем могут использоваться электронные документы,
      удостоверенные простой электронной подписью.
    </p>
    <p>
      6.2. Простой электронной подписью признается электронная подпись, которая посредством использования логина и
      пароля Пользователя или указанного при использовании Сервиса адреса электронной почты Пользователя (ключ
      электронной подписи) подтверждает факт формирования электронной подписи непосредственно Пользователем.
    </p>
    <p>
      6.3. По соглашению Сторон электронные документы, подписанные простой электронной подписью, признаются
      равнозначными документам на бумажных носителях, подписанным собственноручной подписью.
    </p>
    <p>
      6.4. Правообладатель определяет Пользователя, которому соответствует простая электронная подпись, по используемому
      Пользователем логину и паролю, указанным при регистрации/или авторизации в Сервисе, - в случае совершения любых
      действий по использованию Сервиса, либо по используемому Пользователем адресу электронной почты – в случае
      поступления Правообладателю сообщений с такого адреса.
    </p>
    <p>
      6.5. Любые действия, совершенные с использованием простой электронной подписи определенного Пользователя,
      считаются совершенными таким Пользователем.
    </p>
    <p>
      6.6. Пользователь обязуется соблюдать конфиденциальность ключа электронной подписи. В частности, Пользователь не
      имеет права передавать свои логин и пароль или предоставлять доступ к своей электронной почте третьим лицам, и
      несет полную ответственность за их сохранность и индивидуальное использование, самостоятельно выбирая способ их
      хранения и ограничения к ним доступа.
    </p>
    <p>
      6.7. В случае несанкционированного доступа к логину и паролю, их утраты или раскрытия третьим лицам Пользователь
      обязан незамедлительно сообщить об этом Правообладателю путем направления электронного письма с указанного при
      использовании Сервиса адреса электронной почты.
    </p>
    <p>
      6.8. В случае утраты или несанкционированного доступа к электронной почте, адрес которой указан при использовании
      Сервиса, Пользователь обязан незамедлительно заменить такой адрес на новый и сообщить о данном факте
      Правообладателю путем направления электронного письма с нового адреса электронной почты.
    </p>

    <h4>7. Прочие условия</h4>
    <p>
      7.1. Зарегистрированный Пользователь самостоятельно определяет условия и порядок использования функциональных
      возможностей Сервиса, которые, однако, ни при каких условиях не могут противоречить настоящему Соглашению.
    </p>
    <p>
      7.2. <b>Применимое право</b>. Настоящее Соглашение, порядок его заключения и исполнения, а также вопросы, не
      урегулированные настоящим Соглашением, регулируются действующим законодательством Российской Федерации.
    </p>
    <p>
      7.3. <b>Арбитраж</b>. Все споры по Соглашению или в связи с ним подлежат рассмотрению в суде по месту нахождения
      Правообладателя в соответствии с действующим процессуальным правом Российской Федерации.
    </p>
    <p>
      7.4. <b>Изменения</b>. Настоящее Соглашение может быть изменено или прекращено Правообладателем в одностороннем
      порядке без предварительного уведомления Пользователя и без выплаты какой-либо компенсации в связи с этим.
    </p>
    <p>
      7.5. <b>Редакция Соглашения</b>. Действующая редакция настоящего Соглашения размещена на Сайте и доступна в сети
      Интернет по адресу: <a href="https://co.homestretch.ch/terms">https://co.homestretch.ch/terms</a>.
    </p>
    <p>7.6. Реквизиты Правообладателя:</p>
    <p>
      <i>
        ООО «ХоумСтретч Рус» <br />
        <br />
        ОГРН 1187746678778; ИНН 7734414304; <br />
        Адрес: Россия, Москва г., ул. Кулакова, д. 20, стр. 1А, пом. XI, этаж 2, ком. 47; <br />
        e-mail: <a href="email:info@homestretch.ch">info@homestretch.ch</a>
      </i>
    </p>
    <p>
      Редакция от 01 октября 2018 г.
    </p>
  </div>
</template>

<script>
/* eslint no-irregular-whitespace: 0 */
export default {
  metaInfo() {
    return {
      title: this.$i18n.t('User Agreement'),
    };
  },
};
</script>
