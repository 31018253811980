import Uuid from '@/models/Uuid';
import Trip from '@/models/Trip';
import { getWayQuery } from '@/services/routeService';
import { PointCoordinates } from '@/models/Location';
import { WayCoordinates, RoadLocationType } from '@/models/Way';
import { getDistance, getRhumbLineBearing, computeDestinationPoint, getPathLength } from 'geolib';
import { Leg, ActiveRoute } from '@/models/Way';

const KMH_TO_METERS_PER_SECOND = 1000 / 3600;
const SPEED = 600 * KMH_TO_METERS_PER_SECOND;
const STEP_TIME = 600;
const STEP_SIZE = STEP_TIME * SPEED;

function gaussianRand() {
  let rand = 0;

  for (let i = 0; i < 6; i += 1) {
    rand += Math.random();
  }

  return rand / 6;
}

function gaussianRandom(start: number, end: number) {
  return Math.floor(start + gaussianRand() * (end - start + 1));
}

export function makeRandomSteps(
  start: PointCoordinates,
  end: PointCoordinates,
  startTimestamp: number,
  lastTimestamp: number,
): WayCoordinates[] {
  const points: WayCoordinates[] = [];

  let currentPosition = start;

  let timestamp = startTimestamp || 0;

  while (timestamp < lastTimestamp + STEP_TIME) {
    if (getDistance(currentPosition, end) < STEP_SIZE * 2) {
      points.push({
        ...end,
        type: RoadLocationType.control,
        timestamp,
      });
      break;
    }

    const bearing = getRhumbLineBearing(currentPosition, end) + gaussianRandom(-180, 180);
    const stepDistance = STEP_SIZE * gaussianRandom(0.5, 1.5);

    let pos = computeDestinationPoint(currentPosition, stepDistance, bearing);
    currentPosition = {
      lat: pos.latitude,
      lng: pos.longitude,
    };
    points.push({
      ...currentPosition,
      type: RoadLocationType.control,
      timestamp,
    });

    timestamp += STEP_TIME;
  }

  return points;
}

function randomPath(start: PointCoordinates, end: PointCoordinates, toTripPointId: Uuid): Leg {
  const points: PointCoordinates[] = makeRandomSteps(start, end, 0, Number.MAX_SAFE_INTEGER).map(point => ({
    lat: point.lat,
    lng: point.lng,
  }));

  const distance = getPathLength(points);

  return {
    points,
    summary: {
      length: distance,
      travelTime: distance / SPEED,
    },
    toTripPointId,
  };
}

export function getWayForTrip(trip: Trip): ActiveRoute {
  const wayQuery = getWayQuery(trip);
  const routePoints = [...wayQuery.tripPoints];

  const route: ActiveRoute = {
    legs: [],
    summary: {
      length: 0,
      travelTime: 0,
    },
  };

  let lastCoordinates: PointCoordinates | null = null;
  let lastWayPointId: Uuid | null = null;

  for (const point of routePoints) {
    if (lastCoordinates) {
      if (lastWayPointId !== point.wayPointId) {
        const leg = randomPath(lastCoordinates, point.coordinates, point.id);
        route.legs.push(leg);
        route.summary.length += leg.summary.length;
        route.summary.travelTime += leg.summary.travelTime;
      } else {
        route.legs.push({
          points: [],
          summary: {
            length: 0,
            travelTime: 0,
          },
          toTripPointId: point.id,
        });
      }
    }
    lastCoordinates = point.coordinates;
    lastWayPointId = point.wayPointId;
  }

  return route;
}
