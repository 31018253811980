
import Vue from 'vue';
import contractors from '@/api/contractors';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'CreateContractor',

  data() {
    return {
      showDialog: true,
      error: null,
      name: '',
    };
  },

  computed: {
    checkValid(): boolean {
      return Boolean(this.name);
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),

    async createContractor(): Promise<void> {
      try {
        this.error = null;

        await contractors.createContractor({ name: this.name });

        this.$emit('fetch');
        this.showDialog = false;

        this.showSnackbar(this.$t('Success contractor create'));
      } catch (e) {
        this.error = e;
      }
    },

    hideModal(): void {
      this.$emit('close');
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
