
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Role } from '@/models/Role';

import api from '@/api/roles';
import authApi from '@/api/auth';
import { Rule } from '@/models/Rule';
import { $t } from '@/i18n';

export default Vue.extend({
  name: 'RolesForm',

  props: {
    item: {
      type: Role,
      default: null,
    },
  },

  data() {
    return {
      error: null as string | null,
      isValid: false,
      isLoading: false,
      isSending: false,
      form: {
        name: null as string | null,
        ruleGroups: [] as Array<string>,
      },
      formRules: {
        name: [(v: any) => !!v || this.$t('roles.message.required.name')],
      },
      countOfSelected: 0,
      ruleGroups: null as Array<any> | null,
      groupsByName: new Map<string, any>(),
      rulesByName: new Map<string, any>(),
      allRules: [] as Array<Rule>,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    isSendButtonEnabled(): boolean {
      const isValid = Boolean(this.countOfSelected && this.isValid);
      const isCreatePermission = !this.item && this.hasPermission('roles create');
      const isUpdatePermission = this.item && this.hasPermission('roles update');

      return (isCreatePermission || isUpdatePermission) && isValid;
    },
  },

  methods: {
    async save(): Promise<void> {
      this.error = null;
      this.isSending = true;

      const data: any = {
        name: this.form.name,
        rules: Array.from(this.rulesByName.values())
          .filter(rule => rule.selected)
          .map(rule => rule.name),
      };

      try {
        if (this.item) {
          await api.patchRole(this.item.id, data);
          await authApi.profile(); // reload profile for reset permissions
        } else {
          await api.createRole(data);
        }

        this.back();
      } catch (err) {
        if (err.code === 'DuplicateError' && err.data.name) {
          this.error = $t('error.DuplicateErrorElement', [err.data.name, $t('Role')]);
        } else {
          this.error = err;
        }

        document?.getElementById('app')?.scrollIntoView({ behavior: 'smooth' });
      } finally {
        this.isSending = false;
      }
    },

    back(): void {
      this.$router.push({ name: 'roles' });
    },

    changeGroup(group: any): void {
      for (const rule of group.rules) {
        const newValue = Boolean(group.selected);
        if (!rule.disabled && rule.selected !== newValue) {
          rule.selected = newValue;
        }
      }

      this.recalcProps();
    },

    changeRule() {
      this.recalcProps();
    },

    recalcRulesProps() {
      let hasAffectedRules = false;

      for (const rule of this.rulesByName.values()) {
        if (rule.selected && rule.deps.length) {
          for (const depRule of rule.deps) {
            if (!depRule.selected) {
              depRule.selected = true;
              hasAffectedRules = true;
            }
          }
        }

        if (rule.backDeps.length) {
          const disabledByDeps = rule.backDeps.some((rule: any) => rule.selected);

          if (rule.disabledByDeps !== disabledByDeps) {
            rule.disabledByDeps = disabledByDeps;
          }
        }
      }

      if (hasAffectedRules) {
        this.recalcRulesProps();
      }
    },

    recalcGroupProps() {
      let totalSelected = 0;
      for (const group of this.groupsByName.values()) {
        const rulesCounts = {
          selected: 0,
          visibleSelected: 0,
          visibleDisabled: 0,
          disabledByDeps: 0,
          visible: 0,
        };

        for (const rule of group.rules) {
          if (rule.visible) {
            rule.selected && rulesCounts.visibleSelected++;
            rule.disabled && rulesCounts.visibleDisabled++;
            rule.disabledByDeps && rulesCounts.visibleDisabled++;
          }

          if (rule.selected) {
            rulesCounts.selected++;
          }

          if (rule.visible) {
            rulesCounts.visible++;
          }
        }

        const countOfVisibleRules = rulesCounts.visible;
        const groupProps = {
          selected: countOfVisibleRules === rulesCounts.visibleSelected,
          disabled: countOfVisibleRules === rulesCounts.visibleDisabled,
          visible: countOfVisibleRules > 0,
          indeterminate: rulesCounts.visibleSelected > 0 && countOfVisibleRules > rulesCounts.visibleSelected,
        };

        for (const [key, value] of Object.entries(groupProps)) {
          if (group[key] !== value) {
            group[key] = value;
          }
        }

        totalSelected += rulesCounts.selected;
      }

      this.countOfSelected = totalSelected;
    },

    recalcProps() {
      this.recalcRulesProps();
      this.recalcGroupProps();
    },

    async loadRules() {
      this.isLoading = true;

      this.allRules = await api.getRules();

      this.isLoading = false;
    },

    prepareData() {
      let groups: Array<any> = [];

      for (const rule of this.allRules) {
        let group = this.groupsByName.get(rule.group);

        if (!group) {
          group = {
            name: rule.group,
            label: this.$t(`roles.rulesGroups.${rule.group}`),
            intermediate: false,
            visible: true,
            disabled: false,
            selected: false,
            rules: [],
          };

          groups.push(group);

          this.groupsByName.set(group.name, group);
        }

        const item = {
          ...rule,
          label: this.$t(`roles.rules.${rule.name}`),
          selected: false,
          disabledByDeps: false,
          backDeps: [],
          deps: rule.deps || [],
        };

        group.rules.push(item);

        this.rulesByName.set(item.name, item);
      }

      for (const rule of this.rulesByName.values()) {
        for (const [index, depName] of rule.deps.entries()) {
          const dep = this.rulesByName.get(depName);

          if (dep) {
            rule.deps.splice(index, 1, dep);
            dep.backDeps.push(rule);
          }
        }
      }

      if (this.item) {
        for (const ruleName of this.item.rules) {
          const rule = this.rulesByName.get(ruleName);

          if (rule) {
            rule.selected = true;
          }
        }
      } else {
        for (const rule of this.rulesByName.values()) {
          rule.selected = rule.default;
        }
      }

      this.recalcProps();

      groups = groups.filter(group => group.visible);
      groups.sort((a: any, b: any) => a.title - b.title);

      for (const group of groups) {
        group.rules = group.rules.filter((rule: any) => rule.visible);
        group.rules.sort((a: any, b: any) => a.title - b.title);
      }

      this.ruleGroups = groups;
    },
  },

  async created() {
    if (this.item) {
      this.form.name = this.item.name;
    }

    await this.loadRules();
    this.prepareData();
  },
});
