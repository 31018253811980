<template>
  <v-dialog v-model="showDialog" max-width="520">
    <v-card>
      <v-card-title class="headline">
        <v-layout row justify="top">
          {{ $t('Appointment of an operator for the trip') }}
          <v-spacer />
          <v-btn icon small class="close-btn" @click="$emit('close')">
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-layout>
      </v-card-title>

      <v-card-text>
        <AccountPicker prepend-icon="person" :label="$t('Responsible')" :items="branches" v-model="value" single />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat text @click="$emit('close')">
          {{ $t('Cancel') }}
        </v-btn>
        <btn-async :disabled="!isSelected" color="primary" text @click="assignResponsible">
          {{ $t('Assign responsible') }}
        </btn-async>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';
import uuid from 'uuid/v4';
import AccountPicker from '@/components/pickers/AccountPicker';

export default {
  name: 'AssignResponsibleDialog',

  components: { AccountPicker },

  props: {
    tripId: {
      type: Number,
      required: true,
    },

    executorId: {
      type: String,
      required: true,
    },

    responsibleId: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    showDialog: true,
    value: null,
    branches: [],
  }),

  computed: {
    isSelected() {
      return this.responsibleId !== this.value;
    },
  },

  async created() {
    this.value = this.responsibleId;

    await this.fetchAccountsList();
  },

  methods: {
    async assignResponsible() {
      if (!this.isSelected) {
        return;
      }

      await api.trip.updateTrip(this.tripId, {
        changes: [
          {
            external_id: uuid(),
            type: 'updateResponsible',
            info: {
              responsibleId: this.value || null,
            },
          },
        ],
      });
      this.$emit('select');
    },

    async fetchAccountsList() {
      const accounts = await api.branch.responsibles(this.executorId);

      this.branches = [{ accounts }];
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
