import Vue, { CreateElement, VNode } from 'vue';
import Component from 'vue-class-component';
import uuid from 'uuid/v4';
import TripPoint, { TripPointType, TripPointStatus } from '@/models/TripPoint';
import gmap from '@/lib/gmap';
import { getTZInfoByCoordinate } from '@/lib/timezone';
import { Timezone } from '@/models/Location';

@Component({
  inject: ['getMap'],
})
export default class MapEditDoubleClickHandler extends Vue {
  getMap!: { (): Promise<google.maps.Map> };
  geocoder?: google.maps.Geocoder;

  async created() {
    const map = await this.getMap();
    this.geocoder = new google.maps.Geocoder();

    google.maps.event.addListener(map, 'dblclick', e => {
      this.onDoubleClick(e);
    });
  }

  render(h: CreateElement): VNode {
    return h();
  }

  geocode(location: google.maps.LatLng): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve, reject) => {
      if (!this.geocoder) {
        return reject(new Error('No geocoder'));
      }
      this.geocoder.geocode({ location }, (results, status) => {
        if (status === 'OK' && results.length) {
          resolve(results[0]);
        } else {
          reject(new Error('Geocoder error'));
        }
      });
    });
  }

  async onDoubleClick(e: google.maps.MouseEvent) {
    e.stop();
    this.$store.commit('mapSearch/startLoading');

    const result = await this.geocode(e.latLng);

    const coordinates = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    const geoTimezone = getTZInfoByCoordinate(coordinates);
    const timezone = {
      name: geoTimezone.timezoneName,
      offset: geoTimezone.timezoneOffsetInSec,
    } as Timezone;

    this.$emit(
      'create',
      new TripPoint({
        id: uuid(),
        type: TripPointType.scheduled,
        title: null,
        location: {
          id: uuid(),
          googlePlaceId: result.place_id,
          coordinates,
          addresses: [
            {
              address: result.formatted_address,
              addressComponents: result.address_components,
              locale: gmap.locale,
            },
          ],
          timezone,
          arrivalDuration: null,
          departureDuration: null,
          transportRestrictions: [],
        },
        contacts: [],
        placeLink: {
          title: null,
          placeId: null,
          contacts: [],
        },
        stayTime: 0,
        checkins: [],
        comment: null,
        workingHours: [],
        deliveryWindows: [],
        smsNotification: null,
        functions: {},
        radius: {
          checkin: 0,
          checkout: 0,
        },
        status: TripPointStatus.planned,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }),
    );

    this.$store.commit('mapSearch/stopLoading');
  }
}
