<template>
  <div>
    <v-snackbar :timeout="2000" :top="true" v-model="snackbar">
      {{ snackbarText }}
      <v-btn flat color="pink" @click.native="snackbar = false">
        {{ $t('Close') }}
      </v-btn>
    </v-snackbar>

    <v-flex pb-1>
      <h1>{{ $t('Payment') }}</h1>
    </v-flex>

    <v-tabs v-model="activeTab" color="transparent">
      <v-tab>
        {{ $t('Go to payment') }}
      </v-tab>
      <v-tab>
        {{ $t('Payment details') }}
      </v-tab>
      <v-tab-item>
        <v-layout column id="payment__item">
          <!-- тестовая панель -->
          <debug-only>
            <v-flex>
              <v-layout row>
                <v-flex xs12 sm6 text-xs-center pr-2>
                  <v-card class="fill-height">
                    <v-card-text>
                      <v-btn @click="enableTrialMode" color="success">
                        {{ $t('Trial') }}
                      </v-btn>
                      <v-btn @click="enableConfidenceMode" color="error">
                        {{ $t('Trust mode') }}
                      </v-btn>
                      <v-btn @click="enableRestrictedMode" color="warning">
                        {{ $t('Limited') }}
                      </v-btn>
                      <v-btn @click="enableBlockMode" color="info">
                        {{ $t('Blocked') }}
                      </v-btn>
                      <v-btn @click="clearState" color="error">
                        Reset state
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 sm6 text-xs-center pl-2>
                  <v-card class="fill-height">
                    <v-card-text>
                      <v-layout row wrap>
                        <v-flex sm6>
                          <v-text-field :mask="mask" v-model="datetime" label="Change server time (yyyy-MM-dd hh:mm)" />
                        </v-flex>
                        <v-flex sm6>
                          <v-btn @click="changeTime" color="info">
                            {{ $t('Refresh') }}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      To restore real time clear field and press refresh
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </debug-only>

          <!-- числовая панель -->
          <v-flex>
            <v-layout row>
              <v-flex v-if="isActive" xs6 text-xs-center pr-2>
                <v-card color="teal lighten-4">
                  <v-card-text class="px-0">
                    {{ $t('Accounts') }}: {{ $t('active') }}/{{ $t('inactive') }}<br />
                    {{ usedSlots }}/{{ freeSlots }}
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex v-if="isActive" xs6 text-xs-center pl-2>
                <v-card color="teal lighten-4">
                  <v-card-text class="px-0">
                    {{ $t('Subscription fee per month') }}<br />
                    {{ $n(billing.costPerMonth, 'currency', billing.currency) }}
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex v-if="!isActive" class="text-xs-center">
                <v-card color="teal lighten-4">
                  <v-card-text class="px-0">
                    {{ $t('Number of registered employees') }}: {{ billing.activeAccounts }}
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>

          <!-- инфо о подписке -->
          <v-flex v-if="isTrial">
            <v-card style="padding-left: 50px;">
              <v-card-text class="px-0">
                <div>
                  <b>{{ $t('Period') }}:</b> {{ status }}
                </div>
                <div>
                  <b>{{ $t('Expires') }}:</b> {{ $d(billing.currentPeriodEnd * 1000, 'standard') }}
                </div>
                <div>
                  <b>{{ $t('Left') }}:</b> {{ $d(paidLeft, 'durationDays') }}
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex v-if="!isTrial">
            <v-card style="padding-left: 50px;">
              <v-card-text class="px-0">
                <div v-if="isActive">
                  <b>{{ $t('Number of paid accounts') }}:</b> {{ billing.paidSlots }}
                </div>
                <div v-if="isActive">
                  <b>{{ $t('Tariff') }}:</b> {{ userPlan(billing.plan) }}
                </div>
                <div>
                  <b>{{ $t('Period') }}:</b> {{ status }}
                </div>
                <div v-if="isActive">
                  <b>{{ $t('Subscription expires') }}:</b> {{ $d(billing.currentPeriodEnd * 1000, 'standard') }}
                </div>
                <div v-if="isConfidence">
                  <b>{{ $t('Postponement expires') }}:</b> {{ $d(billing.currentPeriodEnd * 1000, 'standard') }}
                </div>
                <div v-if="isRestricted">
                  <b>{{ $t('It will be blocked in') }}:</b> {{ $d(billing.currentPeriodEnd * 1000, 'standard') }}
                </div>
                <div v-if="isBlocked">
                  <b>{{ $t('Deleting an account after') }}:</b> {{ $d(billing.currentPeriodEnd * 1000, 'standard') }}
                </div>
                <div>
                  <b>{{ $t('Left') }}:</b> {{ $d(paidLeft, 'durationDays') }}
                </div>
                <div>
                  <b>{{ $t('Payment method') }}:</b> {{ paymentMethod }}
                </div>
              </v-card-text>
            </v-card>
          </v-flex>

          <advance-payment ref="advancePayment" />

          <!-- кнопки -->
          <v-flex>
            <v-layout :column="$vuetify.breakpoint.xsOnly" row>
              <v-flex
                v-if="!isActive"
                xs12
                text-xs-center
                :px-2="$vuetify.breakpoint.smAndUp"
                :pt-2="$vuetify.breakpoint.xsOnly"
              >
                <v-card color="teal lighten-4">
                  <v-card-text class="px-0">
                    <modalSubscribe
                      :annual-subscription="false"
                      :invoice-url="invoiceUrl"
                      :act-url="actUrl"
                      @paySubscription="paySubscription"
                      @createInvoice="createInvoice"
                      @sendEmailInvoice="sendEmailInvoice"
                      @changeAutoProlongationPaymentMethod="changeAutoProlongationPaymentMethod"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                v-if="showSmsCounter"
                xs12
                text-xs-center
                :px-2="$vuetify.breakpoint.smAndUp"
                :pt-2="$vuetify.breakpoint.xsOnly"
              >
                <v-card color="teal lighten-4">
                  <v-card-text class="px-0">
                    <v-btn to="/billing/prices#smspacks" round>
                      {{ $t('Choose SMS + radar pack') }}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                v-if="isActive"
                xs12
                sm6
                text-xs-center
                :px-2="$vuetify.breakpoint.smAndUp"
                :pt-2="$vuetify.breakpoint.xsOnly"
              >
                <v-card color="teal lighten-4">
                  <v-card-text class="px-0">
                    <modal-buy-slots
                      :invoice-url="invoiceUrl"
                      @createInvoice="createInvoice"
                      @sendEmailInvoice="sendEmailInvoice"
                      @buySlots="buySlots"
                      @changeAutoProlongationPaymentMethod="changeAutoProlongationPaymentMethod"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex
                v-if="isActive"
                xs12
                sm6
                text-xs-center
                :pl-2="$vuetify.breakpoint.smAndUp"
                :pt-2="$vuetify.breakpoint.xsOnly"
              >
                <v-card color="teal lighten-4">
                  <v-card-text class="px-0">
                    <modal-sell-slots @sellSlots="sellSlots" />
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex v-if="isTrial">
            <v-card style="padding-left: 50px;">
              <v-card-text class="px-0">
                <h3>{{ $t('Important to know') }}!</h3>
                <ul style="padding-left: 20px">
                  <li>{{ $t('have_pay_your') }}</li>
                  <li>{{ $t('once_switch_trial_active') }}</li>
                  <li>{{ $t('in the active period, each employee is tied to a account, so ') }}</li>
                  <li>{{ $t('number_employees_active_period') }}</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-flex>

          <!-- История событий -->
          <event-log ref="eventLog" />
        </v-layout>
      </v-tab-item>
      <v-tab-item>
        <PaymentMethods />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Common from '@/components/billing/Common';
import ModalSubscribe from '../../components/billing/modalSubscribe';
import ModalBuySlots from '../../components/billing/modalBuySlots';
import ModalSellSlots from '../../components/billing/modalSellSlots';
import EventLog from '@/components/billing/EventLog';
import AdvancePayment from '@/components/billing/AdvancePayment';
import PaymentMethods from '@/pages/billing/PaymentMethods';
import api from '@/api';
import { mapState, mapGetters } from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Go to payment'),
    };
  },
  components: {
    ModalBuySlots,
    ModalSellSlots,
    ModalSubscribe,
    EventLog,
    AdvancePayment,
    PaymentMethods,
  },
  mixins: [Common],
  data() {
    return {
      invoiceUrl: '',
      invoiceNumber: 0,
      actUrl: '',
      mask: '####-##-## ##:##',
      datetime: '',
      snackbar: false,
      snackbarText: '',
      activeTab: 'payment',
      tabs: ['payment', 'methods'],
    };
  },

  async created() {
    await this.fetchMainInfo();
    await this.getTime();
  },

  methods: {
    async fetchMainInfo() {
      const data = await api.billing.info.infoGetMain();
      await api.auth.profile();
      this.$store.commit('billing/setBillingInfo', data);
      this.$refs.advancePayment.fetchAdvancePayments();
    },
    async paySubscription(numSlots, numMonth) {
      await api.billing.purchase.paySubscription({ slots: numSlots, month: numMonth });

      this.snackbar = false;
      this.snackbarText = this.$t('Subscription paid successfully');
      this.snackbar = true;

      await this.fetchMainInfo();
    },
    async sendEmailInvoice() {
      await api.billing.invoice.sendEmail({ invoiceNumber: this.invoiceNumber });

      this.snackbar = false;
      this.snackbarText = this.$t('Invoice sent to email');
      this.snackbar = true;
    },
    async createInvoice(numSlots, numMonth, type) {
      const data = await api.billing.invoice.create({ slots: numSlots, month: numMonth, type: type });
      this.invoiceUrl = data.url;
      this.invoiceNumber = data.invoice_number;

      const url = data.url.split('/invoice_');
      this.actUrl = `${url[0]}/act_${url[1]}`;

      this.snackbar = false;
      this.snackbarText = this.$t('Invoice #{0} was generated', [data.invoice_number]);
      this.snackbar = true;

      await this.fetchMainInfo();
    },
    async changeAutoProlongationPaymentMethod(type, status) {
      await api.billing.paymentMethod.changeAutoProlongation({ payment_method_type: type, status: status });

      this.snackbar = false;
      this.snackbarText = this.$t('Changes saved');
      this.snackbar = true;
    },
    async changeDefaultPaymentMethod(type, status) {
      await api.billing.paymentMethod.changeDefault({ payment_method_type: type, status: status });

      this.snackbar = false;
      this.snackbarText = this.$t('Changes saved');
      this.snackbar = true;
    },
    async buySlots(numSlots) {
      await api.billing.purchase.buySlots({ slots: numSlots });

      this.snackbar = false;
      this.snackbarText = this.$t('Accounts successfully added');
      this.snackbar = true;

      await this.fetchMainInfo();
    },
    async sellSlots(numSlots) {
      await api.billing.purchase.sellSlots({ slots: numSlots });

      this.snackbar = false;
      this.snackbarText = this.$t('Accounts were removed');
      this.snackbar = true;

      await this.fetchMainInfo();
    },
    async clearState() {
      await api.billing.test.clearState();
      await this.fetchMainInfo();

      this.snackbar = false;
      this.snackbarText = 'State reset';
      this.snackbar = true;
    },
    async enableTrialMode() {
      await api.billing.test.enableTrialMode();
      await this.fetchMainInfo();
    },
    async enableConfidenceMode() {
      await api.billing.test.enableConfidenceMode();
      await this.fetchMainInfo();
    },
    async enableRestrictedMode() {
      await api.billing.test.enableRestrictedMode();
      await this.fetchMainInfo();
    },
    async enableBlockMode() {
      await api.billing.test.enableBlockMode();
      await this.fetchMainInfo();
    },
    async changeTime() {
      await api.billing.test.changeTime({ time: this.datetime });
      await this.getTime();
      await this.fetchMainInfo();
    },
    async getTime() {
      const data = await api.billing.test.getTime();
      this.datetime = data.time;
    },

    formatDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
  },

  computed: {
    ...mapState('payment', ['cardInfo', 'invoice']),
    ...mapState(['billing']),
    ...mapGetters('billing', ['paidLeft', 'showSmsCounter']),

    isTrial() {
      return this.billing.status === 'TRIAL';
    },

    isActive() {
      return this.billing.status === 'ACTIVE';
    },

    isConfidence() {
      return this.billing.status === 'CONFIDENCE';
    },

    isRestricted() {
      return this.billing.status === 'RESTRICTED';
    },

    isBlocked() {
      return this.billing.status === 'BLOCKED';
    },

    usedSlots() {
      return this.billing.activeAccounts;
    },

    freeSlots() {
      return this.billing.paidSlots - this.billing.activeAccounts;
    },

    status() {
      const action = {
        TRIAL: this.$t('Trial'),
        ACTIVE: this.$t('Active'),
        RESTRICTED: this.$t('Limited'),
        BLOCKED: this.$t('Blocked'),
        CONFIDENCE: this.$t('Trust mode'),
      };

      return action[this.billing.status];
    },

    paymentMethod() {
      let method = '';

      if (this.billing.paymentMethodType === 'CARD') {
        method += this.$t('Bank card');
      }

      if (this.billing.paymentMethodType === 'BANK') {
        method += this.$t('Invoice');
      }

      if (this.cardInfo.auto_prolongation) {
        method += ' ' + this.$t('(auto prolongation)');
      }

      if (this.invoice.auto_prolongation) {
        method += ' ' + this.$t('(auto payment)');
      }

      return method;
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>

<style>
#payment__item .flex {
  padding-bottom: 16px;
}
</style>
