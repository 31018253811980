<template>
  <v-dialog v-model="showDialog">
    <v-container fluid pa-0 fill-height>
      <div @click="showDialog = false" class="close-slider-button">
        <v-btn fab dark small color="grey-darken">
          <v-icon dark>
            close
          </v-icon>
        </v-btn>
      </div>
      <div class="slider-items-count">
        {{ sliderItemsCountStr() }}
      </div>
      <v-carousel dark :cycle="false" v-model="internalValue" hide-delimiters height="90vh">
        <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src" :value="item.id" :index="i" contain />
      </v-carousel>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImageFullCarousel',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showDialog: true,
      internalValue: null,
    };
  },

  created() {
    this.internalValue = this.value;
    document.addEventListener('keydown', this.keyPressListener);
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.keyPressListener);
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    sliderItemsCountStr() {
      let result = '';
      if (this.items.length) {
        const activeImageIndex = this.items.findIndex(item => item.id === this.internalValue) + 1;
        const itemsCount = this.items.length;
        result = `${activeImageIndex} / ${itemsCount}`;
      }
      return result;
    },

    keyPressListener(e) {
      if (e.code === 'Escape' && this.showDialog) {
        this.showDialog = false;
      } else if (e.code === 'ArrowLeft' && this.showDialog) {
        this.internalValue--;
      } else if (e.code === 'ArrowRight' && this.showDialog) {
        this.internalValue++;
      }
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped>
.slider-items-count {
  color: white;
  position: absolute;
  left: 34px;
  bottom: 6%;
  z-index: 1;
}

.v-window-item {
  background: rgba(0, 0, 0, 0.9);
  position: relative;
}

.close-slider-button {
  top: 5%;
  right: 23px;
  position: absolute;
  z-index: 1;
}

.v-carousel__prev,
.v-carousel__next {
  z-index: 1;
}

.v-carousel__prev i,
.v-carousel__next i {
  color: white !important;
}
</style>
