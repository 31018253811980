<template>
  <v-layout column>
    <v-flex pb-3>
      <v-layout class="align-start">
        <div>
          <h1>{{ $t('LotPlanning') }}</h1>
        </div>

        <v-spacer />

        <v-btn
          v-if="hasPermission('distributions create')"
          @click="createDistribution"
          color="primary"
          class="mr-0 mb-0"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('Create planning') }}
        </v-btn>

        <slot />
      </v-layout>
    </v-flex>

    <v-flex>
      <v-card mb-5>
        <v-card-text fill-height>
          <v-form ref="form" v-model="valid">
            <v-layout row wrap align-start>
              <v-flex xs12 md5 style="background: white" v-onboarding.top="$t('helpText.distributionsListFilterDate')">
                <DateRangePicker v-model="filter.date" />
              </v-flex>

              <v-spacer />

              <v-flex xs12 md6>
                <v-text-field v-model="filter.name" :label="$t('DistributionName')" prepend-icon="search" />
              </v-flex>
            </v-layout>

            <div v-if="showDetails">
              <v-layout row wrap align-start>
                <v-flex
                  xs12
                  md5
                  style="background: white"
                  v-onboarding.top="$t('helpText.distributionsListFilterOwner')"
                >
                  <AccountPicker
                    prepend-icon="desktop_windows"
                    :label="$t('Owners')"
                    :items="owners"
                    v-model="filter.owners"
                  />
                </v-flex>

                <v-spacer />

                <v-flex xs12 md6>
                  <v-select
                    v-model="filter.statuses"
                    :items="statuses"
                    :label="$t('Statuses')"
                    prepend-icon="insert_chart"
                    class="distribution-filter-statuses"
                    multiple
                  >
                  </v-select>
                </v-flex>
              </v-layout>

              <v-layout row wrap align-start>
                <v-flex xs12 md5>
                  <v-select
                    v-model="filter.configurations"
                    :items="configurations"
                    :label="$t('Configurations')"
                    prepend-icon="tune"
                    class="mb-4"
                    hide-details
                    multiple
                  >
                  </v-select>
                </v-flex>
              </v-layout>

              <v-layout row wrap align-start>
                <v-flex>
                  <v-checkbox v-model="filter.unplannedOrders" :label="$t('Unplanned Orders')" />
                </v-flex>
              </v-layout>
            </div>
          </v-form>

          <v-layout class="text-xs-right" align-end :column="$vuetify.breakpoint.xsOnly">
            <v-btn flat @click="toggleVisibility" v-if="!this.showDetails">
              <v-icon>arrow_downward</v-icon>
              {{ $t('More parameters') }}
            </v-btn>

            <v-btn flat @click="toggleVisibility" v-else>
              <v-icon>arrow_upward</v-icon>
              {{ $t('Less parameters') }}
            </v-btn>

            <v-flex>
              <v-btn @click.stop="resetFilter">{{ $t('Clear') }}</v-btn>

              <v-btn @click.stop="reloadClick" :disabled="!valid">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import branch from '@/api/branch';

import DateRangePicker from '@/components/pickers/DateRangePicker';
import AccountPicker from '@/components/pickers/AccountPicker';

import { DistributionConfiguration, DistributionStatus } from '@/models/Distribution';

import DistributionsFilterDto from './DistributionsFilterDto';

export default {
  name: 'DistributionsFilter',

  props: {
    form: {
      type: DistributionsFilterDto,
      required: true,
    },

    show: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    DateRangePicker,
    AccountPicker,
  },

  data() {
    return {
      valid: true,
      showDetails: this.show,
      filter: new DistributionsFilterDto(this.form),
      owners: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    configurations() {
      const keys = Object.keys(DistributionConfiguration);
      return keys.map(key => ({
        value: key,
        text: this.$t(`Distribution.Configuration.${key}`),
      }));
    },

    statuses() {
      const keys = Object.keys(DistributionStatus);
      return keys.map(key => ({
        value: key,
        text: this.$t(`Distribution.Status.${key}`),
      }));
    },
  },

  methods: {
    async getOwners() {
      const accounts = await branch.accounts();
      this.owners = [{ accounts }];
    },

    toggleVisibility() {
      this.showDetails = !this.showDetails;
    },

    reloadClick() {
      this.$emit('reload');
    },

    resetFilter() {
      this.filter = new DistributionsFilterDto();
    },

    createDistribution() {
      this.$router.push({
        name: 'distributionCreate',
      });
    },
  },

  created() {
    this.getOwners();
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.valid) {
          this.$emit('filterUpdate', this.filter);
        }
      },
    },

    valid() {
      if (this.valid) {
        this.$emit('filterUpdate', this.filter);
      }
    },
  },
};
</script>

<style>
.distribution-filter-statuses {
  margin-top: 14px;
}
</style>
