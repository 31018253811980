const parsingMapping = {
  lexeco: 'LEX',
  ews: 'EWS',
  tomilo: 'LEX',
};

export default {
  namespaced: true,

  state: {
    owner_id: null,
    ownerId: null,
    domain: null,
    name: null,
    country: null,
    language: null,
    agree_terms: null,
    agree_rules: null,
    settings: {
      have_work_time: null,
      trip_send_before_planned: null,
      point_calibration: {
        enable: null,
        radius: null,
        distance_from_user: null,
      },
      stay_time: {
        enable: null,
        default: null,
        ask_when_adding: null,
      },
      checkin_settings: {
        time_for_unplanned_stop: null, // in seconds
        time_for_checkin_delay: null, // in seconds
        ignore_way_order: null,
      },
      defaultNavigator: null,
      dontAccountDepartureInDownTime: null,
      defaultShifts: {
        executor: {
          workingTime: null,
        },
        transport: {
          workingTime: null,
          stageLength: null,
        },
      },
      defaultBranchId: null,
      defaultRoleId: null,
      defaultNonRegularRoleId: null,
    },
    loginCompanies: [],
  },

  getters: {
    haveWorkTime(state) {
      if (state.settings && state.settings.have_work_time) {
        return state.settings.have_work_time;
      }

      return false;
    },

    dontAccountDeparture(state) {
      return (state.settings && state.settings.dontAccountDepartureInDownTime) || false;
    },

    importType(state) {
      return parsingMapping[state.domain] || 'DEFAULT';
    },

    defaultStayTime(state) {
      return state.settings.stay_time.default;
    },

    canChangeStayTime(state) {
      return state.settings.stay_time.ask_when_adding;
    },
  },

  mutations: {
    setCompanyData(state, data) {
      Object.assign(state, data);
    },

    setAgreeTerms(state, data) {
      state.agree_terms = data;
    },

    setAgreeRules(state, data) {
      state.agree_rules = data;
    },

    setLoginCompanies(state, data) {
      state.loginCompanies = data;
    },
  },
};
