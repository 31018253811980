
import { VueConstructor } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import tripApi from '@/api/tripNew';
import chatApi from '@/api/chatNew';
import wayBuilderApi from '@/api/wayBuilder';
import commentApi from '@/api/tripComment';
import { getDistributionTrip } from '@/api/distributions';

import { convertDateWithTimeZone, differenceInSeconds } from '@/lib/date';
import { getTimeZoneString } from '@/lib/timezone';
import { sleep } from '@/lib/util';

import Trip, { TripStatus } from '@/models/Trip';
import Account from '@/models/Account';
import { Timezone } from '@/models/Location';

import { TripTableRow } from '@/services/TripPageService';
import { getWayQuery } from '@/services/routeService';
import { calculateETAToPoints } from '@/services/routeService';
import { getTripChanges } from '@/services/changesService';
import { reorderPointsByWay } from '@/services/tripService';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import AccountInfo from '@/components/AccountInfo.vue';
import TripPageMixin from '@/components/trip/TripPageMixin';
import TripPointsDetailsGrid from '@/components/trip/TripPointsDetailsGrid.vue';
import MapWidget from '@/components/map/MapWidgetNew';
import TripRoute from '@/components/map/TripRoute';
import TripRawPassedWay from '@/components/map/TripRawPassedWay';
import PointInfoWindow from '@/components/map/PointInfoWindow';
import TripStatusIcon from '@/components/icons/TripStatusIcon';
import MobileDeviceInfo from '@/components/MobileDeviceInfo';
import BatteryInfo from '@/components/BatteryInfo';
import BtnToggleRound from '@/components/BtnToggleRound.vue';
import TripExport from '@/components/export/TripExport.vue';
import Chat from '@/components/chat/Chat.vue';
import SystemChat from '@/components/chat/SystemChat.vue';
import HideButtonComponent from '@/components/map/controls/HideButtonComponent';
import CurrentPositionButtonComponent from '@/components/map/controls/CurrentPositionButtonComponent';
import ShareDialog from '@/components/modals/ShareDialog.vue';
import TripEventsList from '@/components/trip/TripEventsList.vue';
import CancelTripDialog from '@/components/modals/CancelTripDialog.vue';
import FinishTripDialog from '@/components/modals/FinishTripDialog.vue';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import RestoreDialog from '@/components/modals/RestoreDialog.vue';
import ImageFullCarousel from '@/components/modals/ImageFullCarousel.vue';
import TripComments from '@/components/trip/TripComments.vue';

import TripDebugger from '../../tests/trip/TripDebugger.vue';

import { AUTORELOAD_TIME, ONE_DAY, THREE_DAYS } from '@/const';
import { addDays } from 'date-fns';

type RightPanelState = 'map' | 'chat' | 'system' | 'comments';

const RIGHT_PANEL_STATES: any = {
  map: 'map',
  chat: 'chat',
  system: 'system',
  comments: 'comments',
};

export default TripPageMixin.extend({
  name: 'TripDetails',

  components: {
    Breadcrumbs,
    AccountInfo,
    TripPointsDetailsGrid,
    MapWidget,
    TripRoute,
    TripRawPassedWay,
    PointInfoWindow: PointInfoWindow as VueConstructor, // FIXME WTF TS ???
    TripStatusIcon,
    MobileDeviceInfo,
    BatteryInfo,
    TripDebugger,
    BtnToggleRound,
    TripExport,
    Chat,
    SystemChat,
    HideButtonComponent,
    CancelTripDialog,
    FinishTripDialog,
    ShareDialog,
    DeleteDialog,
    RestoreDialog,
    TripEventsList,
    ImageFullCarousel,
    CurrentPositionButtonComponent,
    TripComments,
  },

  props: {
    propMessage: String,
  },

  data() {
    return {
      id: null as number | null,
      error: null as any,
      loading: true,
      debugDirty: false,
      trip: new Trip() as any,
      rawPassedWay: null as any,
      executor: null as Account | null,
      responsible: null as Account | null,

      hideHiddenButton: false,
      hideMap: false,
      mapFullScreen: false,
      canRefresh: false,
      autoRefresh: false,
      cancelError: null as any,
      finishError: null as any,
      cancelDialog: false,
      finishDialog: false,
      shareDialog: false,
      shareError: null as any,
      deleteDialog: false,
      deleteError: null as any,
      restoreDialog: false,
      restoreError: null as any,
      rightPanelState: 'map' as RightPanelState,
      showBadge: {
        chat: false,
        system: false,
        comments: false,
      },
      messages: {
        chat: null as any, // TODO
        systemChat: null as any, // TODO
        comments: null as any, // TODO
      },
      filter: {
        showPassedRoute: true,
        showActiveRoute: true,
        showRawPassedWay: false,
        deleted: false,
      },
      refreshLoading: false,
      showCarousel: false,
      carouselCurrent: null as number | null,
      timer: null as any,
      showFullInfo: false,
      showUseRawWayDistance: true,
      loadingOptimization: false,
      countLoader: 0,
    };
  },

  async created() {
    this.id = parseInt(this.$route.params.id);

    await this.fetchData();
    await this.updateTags();

    setTimeout(() => {
      this.countLoader += 1;
    }, 0);
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapGetters('company', ['haveWorkTime', 'dontAccountDeparture']),
    ...mapState('map', ['currentSelectRow', 'currentPoint']),

    isDistributionTrip(): boolean {
      return this.$route.name === 'distributionTrip';
    },

    isDeletedTrip(): boolean {
      return this.trip.status === TripStatus.deleted;
    },

    tripId(): number {
      if (this.isDistributionTrip) {
        return parseInt(this.$route.params.tripId);
      }
      return parseInt(this.$route.params.id);
    },

    isAllowsUpdate(): boolean {
      return (
        [
          TripStatus.notAssigned,
          TripStatus.pending,
          TripStatus.pendingViewed,
          TripStatus.planned,
          TripStatus.active,
        ].includes(this.trip.status) ||
        (this.trip.finishedAt && differenceInSeconds(new Date(), this.trip.finishedAt) < THREE_DAYS)
      );
    },

    breadcrumbs(): Array<object> {
      const tmp: Array<object> = [];

      if (this.isDistributionTrip) {
        tmp.push({
          text: this.$t('Planning'),
          href: { name: 'distributionList' },
        });
        tmp.push({
          text: this.$t('Planning'),
          href: { name: 'distributionInfo', params: { id: this.$route.params.distributionId } },
        });
      } else {
        tmp.push({ text: this.$t('Trips'), href: { name: 'trips' } });
      }

      tmp.push({
        text: this.$t('Trip') + (this.trip && this.trip.title ? `: ${this.trip.title}` : ''),
        href: '',
        disabled: true,
      });

      return tmp;
    },

    canUseRawWayDistance(): Boolean {
      return (
        this.trip?.summaryIndicators?.passedRawWayDistance &&
        this.trip?.summaryIndicators?.passedRawWayDistance !== this.trip.summaryIndicators.passedWayDistance
      );
    },

    haveCorrectStatuses(): boolean {
      let haveFinishedAt = false;

      if (this.trip.finishedAt) {
        haveFinishedAt = addDays(new Date(this.trip.finishedAt), 1) > new Date();
      }

      return (
        TripStatus.active === this.trip.status ||
        ([TripStatus.manuallyFinished, TripStatus.finished].includes(this.trip.status) && haveFinishedAt)
      );
    },
  },

  methods: {
    ...mapActions('tags', ['updateTags']),

    async fetchData(): Promise<void> {
      if (this.debugDirty) {
        return;
      }

      try {
        if (this.isDistributionTrip) {
          const data = await getDistributionTrip(this.$route.params.distributionId, this.$route.params.tripId);
          this.trip = new Trip(data);
          this.executor = new Account(data.executor);

          return;
        }

        if (this.$refs.chat) {
          (this.$refs.chat as any).fetchData();
        }

        if (this.$refs.eventsList) {
          (this.$refs.eventsList as any).refresh();
        }

        const data = await tripApi.get(parseInt(this.$route.params.id), { location: true });

        const chatsInfo = await chatApi.getMessagesCount(this.tripId);
        if (chatsInfo) {
          this.messages.chat = chatsInfo.chat;
          this.messages.systemChat = chatsInfo.systemChat;
        }

        const comments = await commentApi.getCommentsCount(this.tripId);
        if (comments) {
          this.messages.comments = { count: comments.count };
        }

        if (this.filter.showRawPassedWay) {
          this.rawPassedWay = await tripApi.getRawPassedWay(parseInt(this.$route.params.id));
        }

        if (data) {
          this.trip = new Trip(data);
          this.executor = new Account(data.executor);
          this.responsible = new Account(data.responsible);
        }

        if (this.messages.chat && this.messages.chat.newMessages > 0) {
          this.showBadge.chat = true;
        }

        if (this.messages.systemChat && this.messages.systemChat.newMessages > 0) {
          this.showBadge.system = true;
        }

        if (this.messages.comments && this.messages.comments.count > 0) {
          this.showBadge.comments = true;
        }

        if (this.isAllowsUpdate) {
          this.canRefresh = true;
          this.autoRefresh = true;
        }

        let reloadTime = AUTORELOAD_TIME;

        if (
          [TripStatus.active, TripStatus.notAssigned, TripStatus.pending, TripStatus.pendingViewed].includes(
            this.trip.status,
          )
        ) {
          clearInterval(this.timer);
          this.timer = setInterval(this.fetchData, reloadTime);
        } else if ([TripStatus.planned].includes(this.trip.status)) {
          clearInterval(this.timer);
          this.timer = setInterval(this.fetchData, reloadTime * 15);
        } else if (
          [
            TripStatus.finished,
            TripStatus.cancelled,
            TripStatus.manuallyFinished,
            TripStatus.operatorCancelled,
          ].includes(this.trip.status) &&
          this.trip.finished_at &&
          differenceInSeconds(new Date(), this.trip.finished_at) < THREE_DAYS
        ) {
          reloadTime =
            differenceInSeconds(new Date(), this.trip.finished_at) < ONE_DAY ? reloadTime * 5 : reloadTime * 15;
          clearInterval(this.timer);
          this.timer = setInterval(this.fetchData, reloadTime);
        }
      } catch (err) {
        console.error(err);
        this.error = err;

        if (err.code === 'EntityNotFound') {
          await this.$router.push('/trips');
        }
      } finally {
        this.loading = false;
      }
    },

    updateSelection(this: any): void {
      const rows = this.tableRowsData as TripTableRow[];

      if (rows.length) {
        let entry: TripTableRow | undefined;

        if (window.location.hash) {
          entry = rows.find(row => row.id === window.location.hash.substring(1));
        }

        if (entry && this.$refs.grid) {
          this.$refs.grid.tableRowClick(entry);
        }
      }
    },

    async handleCancel() {
      try {
        this.cancelError = null;

        await tripApi.cancelTrip(this.trip.id);
        await this.fetchData();

        this.cancelDialog = false;
      } catch (err) {
        console.error(err);
        this.cancelError = err;
      }
    },

    async handleFinish() {
      try {
        this.finishError = null;

        await tripApi.finishTrip(this.trip.id);
        await this.fetchData();

        this.finishDialog = false;
      } catch (err) {
        console.error(err);
        this.finishError = err;
      }
    },

    hideMapHandler() {
      this.hideMap = !this.hideMap;
    },

    async refreshBtnClick() {
      this.refreshLoading = true;
      await this.fetchData();
      this.refreshLoading = false;
    },

    showCancelDialog() {
      this.cancelDialog = true;
    },

    showFinishDialog() {
      this.finishDialog = true;
    },

    isCurrentRightPanelEquals(panel: RightPanelState) {
      return this.rightPanelState === panel;
    },

    formatDate(date: Date, format: String, timezone: Timezone) {
      if (this.trip.containsDifferentTimezones() && timezone) {
        const correctedValueWithTimezone = convertDateWithTimeZone(date, timezone.offset);

        return this.$d(correctedValueWithTimezone as any, 'long') + getTimeZoneString(timezone.offset);
      } else {
        return this.$d(date, 'long');
      }
    },

    setRightPanel(panel: string) {
      if (!RIGHT_PANEL_STATES[panel]) {
        return;
      }
      if (this.rightPanelState === RIGHT_PANEL_STATES[panel]) {
        this.rightPanelState = RIGHT_PANEL_STATES.map;
      } else {
        this.rightPanelState = RIGHT_PANEL_STATES[panel];
        this.hideMap = false;
      }
    },

    onSystemChatUpdate(data: Object) {
      this.messages.systemChat = data;
      this.$set(this.messages, 'systemChat', this.messages.systemChat);
      this.showBadge.system = this.messages.systemChat.newMessages > 0;
    },

    onChatMessageAdd() {
      if (!this.messages.chat) {
        this.$set(this.messages, 'chat', {
          id: null,
          newMessages: 0,
          unreadMessages: 0,
          totalMessages: 1,
        });
      } else {
        this.messages.chat.totalMessages += 1;
      }
    },

    showShareDialog() {
      this.shareDialog = true;
    },

    closeShareDialog() {
      this.shareDialog = false;
    },

    showDeleteDialog(): void {
      this.deleteDialog = true;
    },

    showRestoreDialog(): void {
      this.restoreDialog = true;
    },

    onChatUpdate(data: Object) {
      if (!this.messages.chat) {
        this.$set(this.messages, 'chat', {
          id: null,
          newMessages: 0,
          unreadMessages: 0,
          totalMessages: 0,
        });
      }

      this.messages.chat = data;
      this.$set(this.messages, 'chat', this.messages.chat);
      this.showBadge.chat = this.messages.chat.newMessages > 0;
    },

    openCarousel(fileId: number) {
      this.carouselCurrent = fileId;
      this.showCarousel = true;
    },

    openTripPointDetails(tripTableRow: TripTableRow): void {
      if (tripTableRow.tripPoint) {
        this.$store.commit('trip/setTripDto', this.trip.toJSON());

        this.$router.push({
          name: 'tripPointDetails',
          params: {
            tripDbId: this.trip.dbId,
            tripPointId: tripTableRow.tripPoint.id,
          },
        });
      }
    },

    async useRawDistance(): Promise<void> {
      await tripApi.useRawDistance(this.trip.id);
      await this.fetchData();

      this.showUseRawWayDistance = false;
    },

    async handleOptimization(): Promise<void> {
      try {
        this.loadingOptimization = true;

        const wayQuery = getWayQuery(this.trip);
        const { buildId } = await wayBuilderApi.buildWay({ ...wayQuery, optimization: true });

        let result;
        let iter = 120;

        do {
          if (result) {
            await sleep(1000);
          }
          result = await wayBuilderApi.getBuild(buildId);
          if (--iter < 0) {
            throw new Error('Unable to build route');
          }
        } while (result.status === 202);

        const activeWay = result.data.activeWay;

        const originalTrip = { ...this.trip };

        this.trip.way.activeWay = activeWay;
        this.trip.tripPoints = reorderPointsByWay(this.trip, activeWay);

        const pointsETA = calculateETAToPoints(this.trip, activeWay);
        (this as any).populateTripWithETA(this.trip, pointsETA);

        const changes = getTripChanges(originalTrip, this.trip, pointsETA);

        await tripApi.updateTrip(this.trip.dbId, { changes });
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingOptimization = false;
      }
    },

    async onDelete(): Promise<void> {
      try {
        this.deleteError = null;

        await tripApi.deleteTrips([this.trip.id]);
        await this.fetchData();

        this.deleteDialog = false;
      } catch (error) {
        this.deleteError = error;
      }
    },

    async onRestore(): Promise<void> {
      try {
        this.restoreError = null;

        await tripApi.restoreTrips([this.trip.id]);
        await this.fetchData();

        this.restoreDialog = false;
      } catch (error) {
        this.restoreError = error;
      }
    },

    onCommentsUpdate(data: Object) {
      if (!this.messages.comments) {
        this.$set(this.messages, 'comment', {
          count: 0,
        });
      }

      this.messages.comments = data;
      this.$set(this.messages, 'comments', this.messages.comments);
      this.showBadge.comments = this.messages.comments.count > 0;
    },

    onCommentAdd() {
      if (!this.messages.comments) {
        this.$set(this.messages, 'comments', {
          count: 1,
        });
      } else {
        this.messages.comments.count += 1;
        this.showBadge.comments = true;
      }
    },

    onCommentRemove() {
      this.messages.comments.count -= 1;
      this.showBadge.comments = this.messages.comments.count > 0;
    },
  },

  watch: {
    async 'filter.showRawPassedWay'(value) {
      if (value) {
        this.rawPassedWay = await tripApi.getRawPassedWay(parseInt(this.$route.params.id));
      } else {
        this.rawPassedWay = null;
      }
    },

    currentSelectRow(value) {
      if (value) {
        window.location.hash = value;
      }
    },

    currentPoint(value) {
      if (value) {
        window.location.hash = value?.id;
      }
    },

    tableRowsData(data) {
      if (data.length) {
        this.countLoader += 1;
      }
    },

    countLoader(value) {
      if (value === 5) {
        this.updateSelection();
      }
    },
  },
});
