import c from '@/lib/coerce';
import { Dictionary } from 'vue-router/types/router';

class TripsFilterDto {
  date = {
    from: null as string | null,
    to: null as string | null,
  };

  branches = {
    branches: [] as string[],
    accounts: [] as string[],
  };

  responsible: string[] = [];

  inactive = false;
  status: string[] = [];
  events: string[] = [];

  search: string | null = null;

  constructor(data?: Partial<TripsFilterDto>) {
    Object.assign(this, data);
  }

  fromQuery(query: Dictionary<string | (string | null)[]>) {
    const data = {
      date: {
        from: query.dateFrom,
        to: query.dateTo,
      },
      branches: {
        branches: c(query.branches)
          .array()
          .nullStr()
          .int()
          .coerce(),
        accounts: c(query.accounts)
          .array()
          .int()
          .coerce(),
      },
      responsible: c(query.responsible)
        .array()
        .int()
        .coerce(),
      inactive: c(query.inactive)
        .boolean()
        .coerce(),
      status: c(query.status)
        .array()
        .coerce(),
      events: c(query.events)
        .array()
        .coerce(),
      search: query.search,
    };

    Object.assign(this, data);

    return this;
  }

  toQuery(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      dateFrom: typeof this.date.from === 'string' ? new Date(this.date.from).toISOString() : null,
      dateTo: typeof this.date.to === 'string' ? new Date(this.date.to).toISOString() : null,
      branches: this.branches.branches.map(v => (v === null ? 'null' : `${v}`)),
      accounts: this.branches.accounts.map(v => `${v}`),
      responsible: this.responsible.map(v => `${v}`),
      inactive: this.inactive ? 'true' : null,
      status: this.status.map(v => `${v}`),
      events: this.events.map(v => `${v}`),
      search: this.search,
    };

    return this.queryWithoutEmptyValues(query);
  }

  toQueryForCheck(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      dateFrom: typeof this.date.from === 'string' ? new Date(this.date.from).toISOString() : null,
      dateTo: typeof this.date.to === 'string' ? new Date(this.date.to).toISOString() : null,
      branches:
        this.branches.branches.length === 1
          ? String(this.branches.branches[0])
          : this.branches.branches.map(v => (v === null ? 'null' : `${v}`)),
      accounts:
        this.branches.accounts.length === 1
          ? String(this.branches.accounts[0])
          : this.branches.accounts.map(v => `${v}`),
      responsible: this.responsible.length === 1 ? String(this.responsible[0]) : this.responsible.map(v => `${v}`),
      inactive: this.inactive ? 'true' : null,
      status: this.status.length === 1 ? String(this.status[0]) : this.status.map(v => `${v}`),
      events: this.events.length === 1 ? String(this.events[0]) : this.events.map(v => `${v}`),
      search: this.search,
    };

    return this.queryWithoutEmptyValues(query);
  }

  queryWithoutEmptyValues(query: Dictionary<string | string[] | null>): Dictionary<string | string[]> {
    for (const i in query) {
      if (query[i] === null || query[i] === undefined || (query[i] instanceof Array && query[i]?.length === 0)) {
        delete query[i];
      }
    }

    return query as Dictionary<string | string[]>;
  }

  toHttpParams() {
    const query = this.toQuery();

    if (typeof query.dateFrom === 'string') {
      query.dateFrom = new Date(query.dateFrom).toISOString();
    } else {
      const dateFrom = new Date();

      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
      dateFrom.setMilliseconds(0);

      query.dateFrom = dateFrom.toISOString();
    }

    if (typeof query.dateTo === 'string') {
      query.dateTo = new Date(query.dateTo).toISOString();
    } else {
      const dateTo = new Date();

      dateTo.setDate(dateTo.getDate() + 1);
      dateTo.setHours(0);
      dateTo.setMinutes(0);
      dateTo.setSeconds(0);
      dateTo.setMilliseconds(0);

      query.dateTo = dateTo.toISOString();
    }

    return query;
  }

  toQueryStringParams() {
    const data = this.toHttpParams();

    if (Object.keys(data).length) {
      let queryString = new URLSearchParams();
      for (const [fieldName, value] of Object.entries(data)) {
        if (Array.isArray(value) && value.length) {
          for (const item of value) {
            queryString.append(fieldName + '[]', item);
          }
        }

        if (['boolean', 'string', 'number'].includes(typeof value)) {
          queryString.append(fieldName, String(value));
        }
      }

      return queryString.toString();
    }

    return '';
  }
}

export default TripsFilterDto;
