
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import { debounce } from 'lodash';

import rules from '@/api/rules';
import api from '@/api/place';
import { geocode } from '@/api/geocode';

import { Place } from '@/models/Place';
import { PointCoordinates } from '@/models/Location';

import ContactListEditor from '@/components/inputs/ContactListEditor.vue';
import AddressInputAutocomplete from '@/components/inputs/AddressInputAutocomplete.vue';
import TimeInput from '@/components/inputs/TimeInput.vue';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import HideButtonComponent from '@/components/map/controls/HideButtonComponent';
import MapWidget from '@/components/map/MapWidgetNew';
import PlaceMarker from '@/components/map/markers/PlaceMarker';
import PlaceEditDoubleClickHandler from '@/components/map/PlaceEditDoubleClickHandler';
import PlaceCalibrationWindow from '@/components/map/PlaceCalibrationWindow';
import ArrayParameterInput from '@/components/inputs/ArrayParameterInput.vue';

export default Vue.extend({
  name: 'PlaceDetails',

  components: {
    ContactListEditor,
    AddressInputAutocomplete,
    TimeInput,
    MapWidget,
    PlaceMarker,
    PlaceEditDoubleClickHandler,
    PlaceCalibrationWindow,
    DeleteDialog,
    HideButtonComponent,
    ArrayParameterInput,
  },

  data() {
    return {
      id: null as String | null,
      valid: false as Boolean,
      showContactList: false as Boolean,
      errorText: null as String | null,
      loading: false as Boolean,
      internalValue: new Place({
        radius: {
          checkin: 0,
          checkout: 0,
        },
      }) as Place,
      canShowDeleteDialog: false,
      deleteError: null,
      hideHiddenButton: false,
      hideMap: false,
      mapFullScreen: false,
    };
  },

  async created() {
    if (this.$route.params.id !== 'new') {
      this.id = this.$route.params.id;

      await this.getPlace();
    } else {
      this.internalValue.radius.checkin = this.settings.pointRadius.checkin;
      this.internalValue.radius.checkout = this.settings.pointRadius.checkout;
    }
  },

  methods: {
    getLocation(data: any) {
      this.internalValue.location = data.location;
    },

    async calibrateHandler(newPosition: PointCoordinates) {
      if (this.internalValue.location && this.internalValue.location.coordinates !== newPosition) {
        this.internalValue.location.coordinates = { ...newPosition };

        this.$store.commit('map/setDraggable', false);
        this.$store.commit('map/setCalibrateLoading', true);

        const data = await geocode({ coordinates: this.internalValue.location.coordinates });

        this.$store.commit('map/setCalibrateLoading', false);

        if (Array.isArray(data.addresses) && data.addresses.length) {
          this.internalValue.location.addresses = data.addresses;
        }
      }
    },

    async getPlace(): Promise<void> {
      const data = await api.get(this.id);

      if (!data.radius || (!data.radius?.checkin && !data.radius?.checkout)) {
        data.radius = {};
        data.radius.checkin = this.settings.pointRadius.checkin;
        data.radius.checkout = this.settings.pointRadius.checkout;
      }

      if (!data.workingHours) {
        data.workingHours = [{ from: '', to: '' }];
      }

      this.internalValue = new Place(data);
    },

    back() {
      this.$router.push(this.$route.params.back || '/favorites');
    },

    showDeleteDialog() {
      this.deleteError = null;
      this.canShowDeleteDialog = true;
    },

    async handleDelete() {
      try {
        await api.remove(this.internalValue.id);

        this.canShowDeleteDialog = false;
        this.back();
      } catch (err) {
        this.deleteError = err;
      }
    },

    checkSaveData(saveData: any): void {
      if (
        saveData.radius.checkin === this.settings.pointRadius.checkin &&
        saveData.radius.checkout === this.settings.pointRadius.checkout
      ) {
        saveData.radius.checkin = 0;
        saveData.radius.checkout = 0;
      }
      if (saveData.workingHours[0].from === ':' || saveData.workingHours[0].to === ':') {
        saveData.workingHours = null;
      }
    },

    async handleSave(): Promise<void> {
      this.loading = true;
      try {
        this.errorText = null;
        const saveData: any = JSON.parse(JSON.stringify(this.internalValue));
        this.checkSaveData(saveData);

        if (this.id) {
          await api.update(saveData.id, saveData);
        } else {
          await api.create(saveData);
        }

        this.back();
      } catch (err) {
        console.error(err);
        this.errorText = err;
      } finally {
        this.loading = false;
      }
    },

    updateAddress: debounce(async function(this: any, value: any) {
      this.internalValue.location = value.location;

      if (
        value &&
        value.location &&
        value.location.addresses &&
        value.location.addresses[0].address &&
        value.location.addresses[0].locale
      ) {
        const data = await geocode({ addresses: value.location.addresses });

        if (data.coordinates) {
          this.$set(this.internalValue.location, 'coordinates', data.coordinates);
        }
      }
    }, 1000),

    updateCheckInRadius(this: any, value: string): void {
      this.internalValue.radius.checkin = value.trim() ? parseFloat(value) : '';
    },

    updateCheckOutRadius(this: any, value: string): void {
      this.internalValue.radius.checkout = value.trim() ? parseFloat(value) : '';
    },

    updateContacts(value: any): void {
      this.internalValue.contacts = value;
    },

    hideMapHandler() {
      this.hideMap = !this.hideMap;
    },
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    rules(): any {
      return {
        titleName: rules.required('TitleName'),
        address: rules.required('Address'),
        time: rules.time,
        floatNumber: rules.floatNumber,
        radius: rules.radius,
      };
    },

    deleteItemName(): any {
      const str = this.internalValue && this.internalValue.title ? ` "${this.internalValue.title}"` : '';
      return this.$t('common_strings.from_place') + str;
    },

    isCreatePlaceWindow(): boolean {
      return this.$route.path === '/favorites/new';
    },

    isEditPlaceWindow(): boolean {
      return this.$route.path === `/favorites/${this.id}`;
    },

    maxContactsCount(): any {
      return 3;
    },

    requiredFrom(): TranslateResult | boolean {
      const to = this.internalValue.workingHours[0].to;
      const from = this.internalValue.workingHours[0].from;
      return !(from === ':' && to !== ':' && to) || this.$t('rules.required', [this.$t('Time from')]);
    },

    requiredTo(): TranslateResult | boolean {
      const to = this.internalValue.workingHours[0].to;
      const from = this.internalValue.workingHours[0].from;
      return !(to === ':' && from !== ':' && from) || this.$t('rules.required', [this.$t('Time to')]);
    },

    checkinMore(): TranslateResult | boolean {
      const checkin = this.internalValue.radius.checkin;
      const checkout = this.internalValue.radius.checkout;
      return checkin <= checkout || this.$t('rules.can_not_be_more_than', [this.$t('Checkout radius')]);
    },

    canSave(): Boolean {
      return this.valid;
    },
  },
});
