import httpV2 from './httpV2';

const path = '/chats';

async function get(id) {
  const res = await httpV2.get(`${path}/${id}`);

  if (res.data && res.data.participants.length) {
    res.data.participants.forEach(item => {
      const initials = item.name.split(' ');
      item.initials = `${initials[0][0]}${initials[1][0]}`;
    });
  }

  return res.data;
}

async function sendMessage(id, data) {
  const res = await httpV2.post(`${path}/${id}/messages`, { data });
  return res.data;
}

async function getSystem(id) {
  const res = await httpV2.get(`${path}/${id}/system`);
  return res.data;
}

export default {
  get,
  sendMessage,
  getSystem,
};
