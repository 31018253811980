import Vue from 'vue';
import { TripPointType } from '@/models/TripPoint';
import { VIcon, VTooltip } from 'vuetify/lib';

export interface IconData {
  icon: string;
  color?: string | null;
  tooltip?: string | null;
}

export const defaultIconProps = {
  item: Object,
  tooltip: String,
  detailed: Boolean,
  value: String,
};

export interface IconDataList {
  [icon: string]: IconData;
}

interface IconProps {
  value: string;
  tooltip?: string;
}

export default Vue.extend({
  functional: true,

  name: 'TypeIcon',

  props: {
    icon: String,
    color: {
      type: String,
      required: false,
    },
    tooltip: String,
    detailed: Boolean,
    size: {
      type: Number,
      required: false,
    },
    additionalProps: String,
    attach: Node, // for tests
  },

  render(h, { props, parent, children }: any) {
    let { icon, color, tooltip } = props;

    const hasChildren = children && children.length;

    const iconProps = { class: [] as string[], props: { color, size: undefined as number | undefined }, slot: '' };
    if (props && props.detailed) {
      iconProps.class.push('mr-1');
      iconProps.props.size = 18;
    }
    if (props && props.size) {
      iconProps.props.size = props.size;
    }
    if (props && props.additionalProps) {
      iconProps.class.push(props.additionalProps);
    }

    if (hasChildren) {
      // iconProps.class.push('fa-stack-1x');
    }

    if (tooltip && !hasChildren) {
      iconProps.slot = 'activator';
    }
    const iconEl = h(VIcon, iconProps, [icon]);
    let el = iconEl;

    if (hasChildren) {
      const elProps = { class: 'fa-stack', style: '', slot: '' };
      if (props && props.detailed) {
        elProps.class += ' mr-1';
        elProps.style = 'width: 19px; font-size: 9px';
      }
      if (tooltip) {
        elProps.slot = 'activator';
      }
      el = h('span', elProps, [iconEl, ...children]);
    }

    if (tooltip) {
      tooltip = String(parent.$t(`${tooltip}`));
    }

    if (props && props.detailed && tooltip) {
      return h('span', [el, tooltip && tooltip]);
    }

    if (tooltip) {
      return h(
        VTooltip,
        {
          props: {
            bottom: true,
            attach: props.attach,
          },
        },
        [el, h('span', [tooltip])],
      );
    }

    return el;
  },
});
