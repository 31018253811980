<template>
  <div>
    <v-input v-model="value" :hide-details="hideDetails" :disabled="disabled" ref="input">
      <v-layout column>
        <v-layout align-center>
          <span class="grey--text text--darken-2 body-1">
            <strong class="red--text">* </strong>
            {{ $t(labelFrom) }}
          </span>

          <v-flex ml-3>
            <date-time-picker
              v-model="value.from"
              icon="event"
              ref="from"
              :disabled="disabled"
              :time-zone-offset="timeZoneOffset"
              :manual-time-input="manualTimeInput"
              :error-messages="errorMessages"
              :rules="rangeRules"
              not-disable-time
            />
          </v-flex>
        </v-layout>

        <v-layout align-center>
          <span class="grey--text text--darken-2 body-1">
            <strong class="red--text">* </strong>
            {{ $t(labelTo) }}
          </span>

          <v-flex ml-3>
            <date-time-picker
              v-model="value.to"
              icon="event"
              ref="to"
              :disabled="disabled"
              :time-zone-offset="timeZoneOffset"
              :manual-time-input="manualTimeInput"
              :error-messages="errorMessages"
              :rules="rules"
              not-disable-time
            />
          </v-flex>
        </v-layout>
      </v-layout>
    </v-input>
  </div>
</template>

<script>
import _ from 'lodash';
import DateTimePicker from '@/components/pickers/timepickers/DateTimePicker';

// TODO make this picker to work with internal value and emit input events

export default {
  name: 'DateTimeRangeField',

  components: { DateTimePicker },

  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    timeZoneOffset: {
      type: Number,
      default: null,
    },
    min: {
      type: [Date, String],
      default: undefined,
    },
    max: {
      type: [Date, String],
      default: undefined,
    },
    returnIso: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'access_time',
    },
    duration: {
      type: Boolean,
      default: false,
    },
    labelFrom: {
      type: String,
      default: 'Time from',
    },
    labelTo: {
      type: String,
      default: 'Time to',
    },
    labelTitle: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    manualTimeInput: {
      type: Boolean,
      default: false,
    },
    errorMessages: Object,
    rules: Array,
  },

  computed: {
    rangeRules() {
      return this.value.from > this.value.to
        ? [this.$t('rules.range', [this.$t(this.labelFrom), this.$t(this.labelTo)])]
        : this.rules;
    },
  },

  methods: {
    validate() {
      return this.$refs.input.validate();
    },
  },

  watch: {
    '$i18n.locale'() {
      this.$refs.input.validate();
    },
  },
};
</script>
