
import Vue from 'vue';
import rules from '@/api/rules';
import TripPoint from '@/models/TripPoint';

export default Vue.extend({
  name: 'PointPlaceCreate',

  props: {
    value: TripPoint,
  },

  data() {
    return {
      error: null,
      valid: true,
      disableWatch: false,
      internalValue: {
        placeLink: {
          title: '',
        },
        isFirstTimeSavePlace: null as Boolean | null,
      },
    };
  },

  computed: {
    placeNameRules(): any {
      return [(value: any) => !!value || this.$t('rules.required', [this.$t('Favorite name')])];
    },
  },

  async created(): Promise<void> {
    await this.updateInternalValue();
  },

  updated(): void {
    if (this.disableWatch) {
      this.disableWatch = false;
    }
  },

  methods: {
    updateInternalValue(): void {
      this.disableWatch = true;

      let value = this.value;

      if (value) {
        if (value.placeLink?.title) {
          this.internalValue.isFirstTimeSavePlace = false;
          this.internalValue.placeLink.title = value.placeLink.title;
        } else {
          if (value.placeLink?.title) this.internalValue.placeLink.title = value.placeLink.title;
          this.internalValue.isFirstTimeSavePlace = true;
        }
      }
      // For the case where values have not changed - but we need to trigger
      // updated() to clear disableWatch flag
      this.$forceUpdate();
    },
  },

  watch: {
    valid() {
      this.$emit('update:valid', this.valid);
    },

    value() {
      this.updateInternalValue();
    },

    internalValue: {
      deep: true,
      handler() {
        this.$emit('input', this.internalValue);
      },
    },
  },
});
