import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide, Watch } from 'vue-property-decorator';
import Uuid from '@/models/Uuid';
import Trip, { TripDto } from '@/models/Trip';
import TripPoint, { AttachFile, TripPointStatus, TripPointType } from '@/models/TripPoint';
import TripPageService, { MapPointData, TripPageFilter } from '@/services/TripPageService';
import { getWayQuery } from '@/services/routeService';
import { compact } from 'lodash';

import EventBus from '@/event-bus';
import StickyHeaders from '../grid/StickyHeaders';

@Component
export default class TripPageMixin extends Vue {
  trip!: Trip;
  isEditingTrip!: boolean;
  mapFullScreen!: boolean;
  hideHiddenButton!: boolean;
  showChat!: boolean;
  hideMap!: boolean;
  unwatch!: Function;
  filter: TripPageFilter = {
    showPassedRoute: true,
    showActiveRoute: true,
    deleted: false,
  };
  refreshLoading = false;
  canRefresh = false;
  pointSelect = null;

  readonly dontAccountDeparture!: boolean;

  get tripPageService() {
    return new TripPageService(this.trip, this.filter, {
      dontAccountDeparture: this.dontAccountDeparture,
      isEditingTrip: Boolean(this.isEditingTrip),
    });
  }

  private _deletedPoint: TripPoint[] = [];

  get wayPointsByTripPointId() {
    return this.tripPageService.getWayPointsByTripPointId();
  }

  get pointNumbers() {
    return this.tripPageService.getPointNumbers();
  }

  get shortAddresses(): Map<Uuid, string> {
    return this.tripPageService.getShortAddresses();
  }

  get pointsETA(): Map<Uuid, Date> {
    return this.tripPageService.getPointsETA();
  }

  get activeWay() {
    return this.tripPageService.getActiveWay();
  }

  get isCreatingTrip(): boolean {
    return this.tripPageService.isCreatingTrip();
  }

  get missDeliveryWindows(): Map<Uuid, boolean> {
    return this.tripPageService.getMissDeliveryWindows();
  }

  get tableRowsData() {
    return this.tripPageService.getTableRowsData();
  }

  get mapPointsData() {
    return this.tripPageService.getMapPointsData();
  }

  mounted() {
    if (process.env.NODE_ENV !== 'test') {
      EventBus.$on('back', () => {
        this.mapFullScreen = false;
      });

      this.unwatch = this.$watch(
        function() {
          return this.$vuetify.application.left;
        },
        function() {
          setTimeout(() => StickyHeaders.methods.updateWidth.call((this.$refs.grid as any).$refs.grid), 300);
        },
      );
    }
  }

  beforeDestroy() {
    this.unwatch();
    EventBus.$off('back');
  }

  get wayQuery() {
    return getWayQuery(this.trip);
  }

  showMap() {
    this.mapFullScreen = true;
    this.hideHiddenButton = true;
    if (this.showChat) {
      this.showChat = false;
    }
    if (this.hideMap) {
      this.hideMap = false;
    }
    this.$store.commit('site/showBackButton');
  }

  get filterEnabled() {
    return this.filter.showActiveRoute || this.filter.showPassedRoute || this.filter.deleted;
  }

  get formattedPoints() {
    return this.trip.tripPoints;
  }

  get carouselItems() {
    const result: Object[] = [];
    for (const row of this.tableRowsData) {
      const point = row.tripPoint;
      if (point && point.attachFiles) {
        point.attachFiles.forEach((file: AttachFile) => {
          result.push({
            id: file.id,
            src: file.fileUri.original,
          });
        });
      }
    }
    return result;
  }

  private _filterDeleted() {
    const stash = this.trip.tripPoints.filter(tripPoint => tripPoint.isDeleted());

    if (stash.length) {
      this._deletedPoint = [];
      this._deletedPoint = this._deletedPoint.concat(stash);
    }

    this.trip.tripPoints = this.trip.tripPoints.filter(tripPoint =>
      this.trip.isDeleted() ? tripPoint.isDeleted() : !tripPoint.isDeleted(),
    );
  }

  isPointActive(tripPoint: MapPointData) {
    if (!tripPoint || !this.pointSelect) {
      return null;
    }

    if (tripPoint.id === this.pointSelect || tripPoint.groupId === this.pointSelect) {
      return true;
    }

    return false;
  }

  get appPaddingTop() {
    return this.$vuetify.application.top;
  }

  @Watch('hideMap')
  onHideMapChanged() {
    setTimeout(() => StickyHeaders.methods.updateWidth.call((this.$refs.grid as any).$refs.grid), 300);
  }

  @Watch('filter.deleted')
  onFilterChanged() {
    if (!this.filter.deleted) {
      this._filterDeleted();
    } else {
      this.trip.tripPoints = compact(this.trip.tripPoints.concat(this._deletedPoint));
      this._deletedPoint = [];
    }
  }

  @Watch('trip')
  onTripChanged() {
    if (!this.filter.deleted) {
      this._filterDeleted();
    }
  }

  @Provide() getComputeColor(status: string, type?: string): string {
    // TODO: Нужно еще дописать условие для обновления цвета маркера МТ
    const mapColors = new Map();
    mapColors.set('darkBlue', '#2a67b3');
    mapColors.set('blue', 'blue');
    mapColors.set('lightBlue', '#88c5f5');
    mapColors.set('red', '#b8325b');
    mapColors.set('lightRed', '#cc577b');
    mapColors.set('black', '#757575');

    if (type === TripPointType.finish) {
      if (status === TripPointStatus.deleted) {
        return mapColors.get('lightRed') as string;
      } else {
        return mapColors.get('black') as string;
      }
    }

    if (type === TripPointType.actualFinish) {
      return mapColors.get('lightRed') as string;
    }

    if (type === TripPointType.notScheduled) {
      return mapColors.get('lightBlue') as string;
    }

    switch (status) {
      case 'active':
        return mapColors.get('darkBlue') as string;
      case 'passed':
        return mapColors.get('blue') as string;
      case 'current':
        return mapColors.get('darkBlue') as string;
      case 'marked':
        return mapColors.get('lightBlue') as string;
      case 'cancelled':
        return mapColors.get('lightBlue') as string;
      case 'waitingForAdd':
        return mapColors.get('darkBlue') as string;
      case 'waitingForDelete':
        return mapColors.get('blue') as string;
      case 'planned':
        return mapColors.get('darkBlue') as string;
      case 'deleted':
        return mapColors.get('lightBlue') as string;
      default:
        return mapColors.get('darkBlue') as string;
    }
  }

  populateTripDTOWithETA(trip: TripDto, pointsETA: Map<Uuid, Date>): TripDto {
    const nowSeconds = Date.now() / 1000;
    for (const tripPoint of trip.tripPoints || []) {
      const arrivalPlanAt = pointsETA.get(tripPoint.id);
      if (arrivalPlanAt) {
        tripPoint.duration = Math.floor(arrivalPlanAt.getTime() / 1000 - nowSeconds);
      }
    }

    return trip;
  }

  populateTripWithETA(trip: Trip, pointsETA: Map<Uuid, Date>): Trip {
    for (const tripPoint of trip.tripPoints || []) {
      const arrivalPlanAt = pointsETA.get(tripPoint.id);
      if (arrivalPlanAt) {
        tripPoint.arrivalPlanAt = arrivalPlanAt;
      }
    }

    return trip;
  }
}
