
import Vue from 'vue';

// @ts-ignore
import android from 'android-versions';

import { MobileDeviceInfo } from '@/models/Trip';

export default Vue.extend({
  name: 'AccountDeviceInfo',

  props: {
    value: {
      type: Object,
    },
  },

  methods: {
    osVersion(mobileDeviceInfo: MobileDeviceInfo): string | null {
      if (!mobileDeviceInfo) {
        return null;
      }

      const { os, osVersion, clientVersion } = mobileDeviceInfo;

      if (!os || !osVersion || !clientVersion) {
        return null;
      }

      let osDescription = osVersion;

      switch (os) {
        case 'ios':
          break;
        case 'android':
          try {
            const api = parseInt(osVersion.split(' ')[2]);
            const ver = android.get(api);

            if (ver && ver.semver) {
              osDescription = 'Android ' + ver.semver;
            }
          } catch (err) {
            console.error(err);
          }
          break;
      }

      return `HS ${clientVersion}, ${osDescription}`;
    },
  },
});
