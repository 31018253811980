import Vue from 'vue';
import TypeIcon, { defaultIconProps, IconData } from './TypeIcon';
import { TripChangeStatus } from '@/models/TripChange';

const CHANGE_STATUS_ICON_DATA = {
  [TripChangeStatus.pending]: { icon: 'fa-hourglass-o', tooltip: 'change_status.pending' },
  [TripChangeStatus.pendingSent]: { icon: 'fa-paper-plane', tooltip: 'change_status.pendingSent' },
  [TripChangeStatus.accepted]: { icon: 'fa-check', tooltip: 'change_status.accepted' },
  [TripChangeStatus.acceptedViewed]: { icon: 'visibility', tooltip: 'change_status.acceptedViewed' },
  [TripChangeStatus.declined]: { icon: 'fa-times', tooltip: 'change_status.declined' },
  [TripChangeStatus.canceled]: { icon: 'fa-times-circle', tooltip: 'change_status.canceled' },
};

const ChangeStatusIcon = {
  // functional: true,
  // props: {
  //   ...defaultIconProps,
  //   item: TripPoint,
  // },
  // render(h, { data: IconData, props, parent }) {
  //   const children: VNode[] = [];
  //   const point = props.item;
  //   if (props.errorCode) {
  //     const iconData = CHANGE_STATUS_ICON_DATA[props.value];
  //     props.tooltip = parent.$t(iconData[1]) + ' (' + parent.$t('change_error.' + props.errorCode) + ')';
  //   }
  //   return h(TypeIcon, { props }, children);
  // },
};

export default Vue.extend({
  functional: true,

  props: {
    ...defaultIconProps,
    errorCode: String,
  },

  name: 'ChangeStatusIcon',

  render(h, { props, parent }) {
    const data: IconData = CHANGE_STATUS_ICON_DATA[props.value as TripChangeStatus];
    let tooltip = data?.tooltip;

    if (props.errorCode) {
      tooltip += ' (' + parent.$t('change_error.' + props.errorCode) + ')';
    }

    return h(TypeIcon, {
      props: { ...props, ...data, tooltip },
    });
  },
});
