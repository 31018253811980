
import Vue from 'vue';

import branchApi from '@/api/branch';
import tripApi, { copyTripPointsParams } from '@/api/tripNew';
import ordersApi from '@/api/orders';

import { TripStatus } from '@/models/Trip';

import AccountPicker from '@/components/pickers/AccountPicker';

export default Vue.extend({
  name: 'MoveDemandDialog',

  components: { AccountPicker },

  props: {
    orderId: {
      type: String,
      default: null,
    },
    tripPoints: {
      type: Array as () => Array<copyTripPointsParams>,
      default() {
        return [];
      },
    },
  },

  data: () => ({
    showDialog: true,
    myBranches: [],
    error: null as Error | null,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    executorId: null,
    newTripId: null as string | null,
    possibleTrips: [] as { value: string; text: string }[],
  }),

  computed: {
    title(): string {
      if (this.tripPoints.length === 1) {
        if (this.tripPoints[0].demandIds && this.tripPoints[0].demandIds.length === 1) {
          return String(this.$t('Move demand'));
        } else if (this.tripPoints[0].demandIds && this.tripPoints[0].demandIds.length > 1) {
          return String(this.$t('Move demands'));
        } else {
          return String(this.$t('Move point'));
        }
      } else if (this.tripPoints.length > 1) {
        if (this.tripPoints.every(obj => obj.demandIds && obj.demandIds.length)) {
          return String(this.$t('Move demands'));
        } else if (this.tripPoints.every(obj => !Boolean(obj.demandIds && obj.demandIds.length))) {
          return String(this.$t('Move points'));
        } else {
          return String(this.$t('Move points demands'));
        }
      } else if (this.orderId) {
        return String(this.$t('Move order'));
      }

      return '';
    },

    formattedDate(): string {
      return this.$d(new Date(this.date), 'standard');
    },

    isSelected(): Boolean {
      return Boolean(this.newTripId);
    },
  },

  async created(): Promise<void> {
    await this.fetchAccountsList();
  },

  methods: {
    async handleOk(): Promise<void> {
      if (this.newTripId) {
        try {
          let result;

          if (this.tripPoints.length) {
            result = await tripApi.copyTripPoints(this.tripPoints, this.newTripId);
          } else if (this.orderId) {
            result = await ordersApi.moveOrder(this.orderId, this.newTripId);
          }

          this.$emit('ok', result);
        } catch (error) {
          this.error = error;
        }
      }
    },

    async fetchAccountsList(): Promise<void> {
      this.myBranches = await branchApi.listMy();
    },

    async fetchPossibleTripsList(): Promise<void> {
      const trips = await tripApi.list({
        status: [
          TripStatus.active,
          TripStatus.pending,
          TripStatus.pendingViewed,
          TripStatus.planned,
          TripStatus.notAssigned,
        ],
        dateFrom: new Date(this.date + 'T00:00:00').toISOString(),
        dateTo: new Date(this.date + 'T23:59:59').toISOString(),
        accounts: [this.executorId],
      });

      this.possibleTrips = trips.result.map(trip => ({
        value: trip.id,
        text: String(trip.title || trip.dbId || ''),
      }));
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },

    executorId() {
      this.fetchPossibleTripsList();
    },

    date() {
      this.fetchPossibleTripsList();
    },
  },
});
