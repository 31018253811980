
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import branch from '@/api/branch';
import tripPointsApi from '@/api/tripPoints';
import contractors from '@/api/contractors';

import PointsFilterDto from '@/components/filters/PointsFilterDto';
import DateRangePicker from '@/components/pickers/DateRangePicker.vue';
import TripPointsListExport from '@/components/export/TripPointsListExport.vue';
// @ts-ignore
import BranchAccountPicker from '@/components/pickers/BranchAccountPicker';
import AccountPicker from '@/components/pickers/AccountPicker';
import PointStatusPicker from '@/components/pickers/PointStatusPicker.vue';
import DemandTypePicker from '@/components/pickers/DemandTypePicker.vue';
import OrderPaymentStatusPicker from '@/components/pickers/OrderPaymentStatusPicker.vue';
import NumRangePickerInOneLine from '@/components/pickers/NumRangePickerInOneLine.vue';
import TagsPicker from '@/components/pickers/TagsPicker.vue';
import PointFunctionsItemsPicker from '@/components/pickers/PointFunctionsItemsPicker.vue';
import AutocompleteNoState from '@/components/pickers/AutocompleteNoState';

export default Vue.extend({
  name: 'PointsFilter',

  components: {
    BranchAccountPicker,
    DateRangePicker,
    AccountPicker,
    PointStatusPicker,
    NumRangePickerInOneLine,
    DemandTypePicker,
    OrderPaymentStatusPicker,
    TagsPicker,
    PointFunctionsItemsPicker,
    AutocompleteNoState,
    TripPointsListExport,
  },

  props: {
    form: {
      type: PointsFilterDto,
      required: true,
    },
  },

  data() {
    const showFullFilter =
      this.form &&
      Object.keys(this.form.toQuery()).some(
        k => !['dateFrom', 'dateTo', 'status', 'search', 'page', 'pageSize', 'sort', 'sortDesc'].includes(k),
      );

    return {
      valid: true,
      filter: new PointsFilterDto(this.form),
      responsibles: [] as Array<any>,
      contractors: [] as Array<any>,
      searchText: '',
      contractorsLoading: true,
      contractorsSearch: '',
      showDetails: showFullFilter,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('branches', ['branches']),
    ...mapState('company', ['settings']),
    ...mapState('account', ['id']),
  },

  methods: {
    toggleVisibility() {
      this.showDetails = !this.showDetails;
    },

    reloadClick() {
      this.$emit('reload');
    },

    resetFilter() {
      this.filter = new PointsFilterDto();
    },

    async getResponsibles() {
      const accounts = await branch.responsibles(this.id);
      this.responsibles = [{ accounts }];
    },

    async getContractorsQuery(): Promise<{ id: string; name: string }[]> {
      const params = this.filter.toHttpParams();
      delete params.contractor;

      const result = await tripPointsApi.list({ ...params, rowsPerPage: 0, agg: 'contractors' });

      if (!result.aggregations?.contractors) {
        throw new Error();
      }

      return result.aggregations.contractors;
    },

    async getInitialContractors() {
      try {
        this.contractorsLoading = true;

        let data = await this.getContractorsQuery();

        if (this.filter.contractor.length) {
          const initialData = (await contractors.getContractors({ idList: this.filter.contractor })).result as {
            id: string;
            name: string;
          }[];

          data = initialData.concat(data);
        }

        this.contractors = data.map(item => ({
          text: this.formatTextToMenu(item.name),
          value: item.id,
        }));
      } finally {
        this.contractorsLoading = false;
      }
    },

    async getContractors() {
      try {
        this.contractorsLoading = true;

        const data = await this.getContractorsQuery();

        this.contractors = data.map(item => ({
          text: this.formatTextToMenu(item.name),
          value: item.id,
        }));
      } finally {
        this.contractorsLoading = false;
      }
    },

    searchUpdate() {
      this.filter.search = this.searchText;
    },

    formatTextToMenu(str: string): string {
      return str.length > 59 ? str.slice(0, 59) + '...' : str;
    },
  },

  async created() {
    await this.getResponsibles();

    this.searchText = this.form.search || '';
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.valid) {
          this.$emit('filterUpdate', this.filter);
          this.getContractors();
        }
      },
    },

    valid() {
      if (this.valid) {
        this.$emit('filterUpdate', this.filter);
      }
    },

    searchText(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.searchUpdate();
      }
    },

    contractorsSearch() {
      this.getContractors();
    },
  },
});
