import http from './httpV2';
import { LocationAddress, PointCoordinates, Timezone } from '@/models/Location';

const path = '/geocode';

interface CoordinatesRequest {
  coordinates: PointCoordinates;
}
interface AddressRequest {
  addresses: LocationAddress[];
}

type GeocodeRequest = CoordinatesRequest | AddressRequest;

interface GeocodeResponse {
  coordinates: PointCoordinates;
  addresses: LocationAddress[];
  timezone: Timezone;
}

async function geocode(data: GeocodeRequest): Promise<GeocodeResponse> {
  const res = await http.post<GeocodeResponse>(path, data);
  return res.data;
}

export { geocode };
