import Vue, { CreateElement, VNode } from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CustomHorizontalList extends Vue {
  @Prop({ type: String, default: 'Items' }) label!: string;
  @Prop({ type: Array, default: [] }) items!: string[];

  render(h: CreateElement): VNode {
    return h(
      'div',
      {
        style: { textAlign: 'left' },
      },
      [
        h('strong', `${this.label}: `),
        h(
          'em',
          this.items.map((item, idx) => {
            return idx !== this.items.length - 1 ? `${item}, ` : item;
          }),
        ),
      ],
    );
  }
}
