<template>
  <v-flex id="trips-monitoring-filter">
    <v-form ref="form" v-model="valid">
      <v-layout row align-center>
        <v-flex>
          <v-layout row align-end>
            <v-flex md6 pr-2>
              <TripStatusPicker v-model="filter.status" :items="itemsStatus" ref="status" />
            </v-flex>

            <v-flex md6 pl-2>
              <BranchAccountPicker
                prepend-icon="business"
                :label="$t('Department or user')"
                :items="branches"
                v-model="filter.branches"
              />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex shrink no-wrap>
          <v-btn flat icon @click="reloadClick">
            <v-icon>
              refresh
            </v-icon>
          </v-btn>

          <v-btn flat icon @click="resetFilter">
            <v-icon>
              mdi-filter-remove
            </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex';
import TripsFilterDto from './TripsFilterDto';
import TripStatusPicker from '@/components/pickers/TripStatusPicker';
import BranchAccountPicker from '@/components/pickers/BranchAccountPicker';

export default {
  name: 'TripsMonitoringFilter',

  components: {
    TripStatusPicker,
    BranchAccountPicker,
  },

  props: {
    form: {
      type: TripsFilterDto,
      required: true,
    },
  },

  data() {
    return {
      valid: true,
      filter: new TripsFilterDto(this.form),
      itemsStatus: ['notAssigned', 'planned', 'pending', 'pendingViewed', 'active'],
    };
  },

  computed: {
    ...mapState('branches', ['branches']),
  },

  methods: {
    resetFilter() {
      this.filter = new TripsFilterDto({
        status: ['active'],
      });
      this.$refs.status.clear(['active']);
    },

    reloadClick() {
      this.$emit('reload');
    },
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.valid) {
          this.$emit('filterUpdate', this.filter);
        }
      },
    },

    valid() {
      if (this.valid) {
        this.$emit('update', this.filter);
      }
    },
  },
};
</script>

<style>
#trips-monitoring-filter {
  padding-bottom: 0px !important;
  margin-top: -8px;
  margin-bottom: -8px;
}
</style>
