function route(path, file, name, children, opts) {
  if (typeof name === 'object') {
    opts = name;
    name = undefined;
  }
  if (typeof children === 'object' && !(children instanceof Array)) {
    opts = children;
    children = [];
  }

  if (typeof file === 'string') {
    return {
      exact: true,
      path,
      name,
      children,
      component: require(`../pages/${file}`).default,
      ...opts,
    };
  } else {
    const components = {};
    for (const key in file) {
      components[key] = require(`../pages/${file[key]}`).default;
    }
    return {
      exact: true,
      path,
      name,
      children,
      components,
      ...opts,
    };
  }
}

export default route;
