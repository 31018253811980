import Vue from 'vue';
import { get } from 'lodash';
import VDataTable from 'vuetify/es5/components/VDataTable/VDataTable';
import DataIterable from 'vuetify/es5/mixins/data-iterable';
import { createSimpleFunctional } from 'vuetify/es5/util/helpers';
import ExpandTransitionGenerator from 'vuetify/es5/components/transitions/expand-transition';
import { $t } from '@/i18n';
import DragScroll from '@/directives/DragScroll';
import GridSelectable from './GridSelectable';
import GridScroll from './GridScroll';
import StickyHeaders from './StickyHeaders';
import GridTh from './GridTh';
import GridSelectAll from './GridSelectAll';

export { GridTh, GridSelectAll };

const VTableOverflow = createSimpleFunctional('v-table__overflow');

/**
 * Basic grid component. Extends VDataTable and adds following functions:
 *
 * 1. Default rendering for body
 * 2. Extendable rendering for headers
 */
export default Vue.extend({
  name: 'Grid',
  extends: VDataTable,
  mixins: [GridSelectable, GridScroll, StickyHeaders],

  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    fields: Object,
    totalRelation: String,
    items: Array,
    trClass: Function,
    rowsPerPageItems: {
      type: Array,
      default() {
        return [100, { text: $t('All'), value: -1 }];
      },
    },
    dragscroll: {
      type: Boolean,
      default: false,
    },
    selectedCount: {
      type: Boolean,
      default: false,
    },
    unselectedItems: {
      type: Array,
      default: () => [],
    },
  },

  directives: {
    dragscroll: DragScroll,
  },

  methods: {
    /**
     * Renders table headers
     */
    genTHead() {
      // Exit Early since no headers are needed.
      if (this.hideHeaders) {
        return;
      }

      const children = [];
      const children2 = [];

      const thProps = {};
      for (const key in this.fields) {
        thProps[key] = {
          field: this.fields[key],
          pagination: this.computedPagination,
          sortIcon: this.sortIcon,
          sort: key => this.sort(key),
        };
      }

      thProps.selectAll = this.getSelectAllProps();

      if (this.$scopedSlots.headers) {
        const row = this.$scopedSlots.headers({
          thProps,
          fields: this.fields,
          indeterminate: this.indeterminate,
          all: this.everyItem,
        });

        children.push([this.hasTag(row, 'th') ? this.genTR(row) : row]);
      } else {
        const row = [];

        for (const key in this.fields) {
          let thCell;

          if (this.$scopedSlots['th_' + key]) {
            thCell = this.$scopedSlots['th_' + key]({
              thProps: thProps[key],
            });
          } else {
            thCell = this.$t(this.fields[key].label);
          }

          row.push(
            this.$createElement(
              GridTh,
              {
                key,
                props: thProps[key],
              },
              [thCell],
            ),
          );
        }

        children.push([this.genTR(row)]);
      }

      if (!this.hideActions) {
        children.unshift(
          this.genTR([
            this.$createElement(
              'td',
              {
                attrs: { colspan: '100%' },
              },
              [this.genActionsRow()],
            ),
          ]),
        );
      }

      children.push(this.genTProgress());

      if (this.$scopedSlots.totals) {
        const totalsRow = this.$scopedSlots.totals();
        children2.push(this.hasTag(totalsRow, 'th') ? this.genTR(totalsRow) : totalsRow);
      }

      return [this.$createElement('thead', [children]), this.$createElement('thead', [children2])];
    },

    // Hotfix no-data slot
    genItems() {
      if (!this.itemsLength && !this.items.length) {
        let noData;

        if (this.$scopedSlots['no-data']) {
          noData = this.$scopedSlots['no-data']();
        } else {
          noData = this.$vuetify.t(this.noDataText);
        }

        return [this.genEmptyItems(noData)];
      }

      return DataIterable.methods.genItems.call(this);
    },

    /**
     * Renders table body
     */
    genFilteredItems() {
      const rows = [];

      for (let index = 0, len = this.filteredItems.length; index < len; ++index) {
        const item = this.filteredItems[index];
        const props = this.createProps(item, index);

        const formatted = { ...item };

        for (const key in this.fields) {
          const field = this.fields[key];
          const value = get(props.item, key);
          formatted[key] = field.format ? field.format(value, props.item) : value;
        }

        props.formatted = formatted;

        const row = this.$scopedSlots.items ? this.$scopedSlots.items(props) : this.genTableRow(props);
        const classes = this.trClass ? this.trClass(item, props) : null;

        rows.push(
          this.hasTag(row, 'td')
            ? this.genTR(row, {
                key: this.itemKey ? props.item[this.itemKey] : index,
                attrs: {
                  id: this.itemKey ? props.item[this.itemKey] : index,
                  active: this.isSelected(item),
                },
                class: classes,
                directives: [
                  {
                    name: 'clickable',
                    value: () => this.$emit('click', item, props),
                  },
                ],
              })
            : row,
        );

        if (this.$scopedSlots.expand) {
          const expandRow = this.genExpandedRow(props, index);
          rows.push(expandRow);
        }
      }

      return rows;
    },

    /**
     * Renders table body row
     *
     * For each cell renders one of:
     * 1. Slot with same name that is passed for field
     * 2. Component is specified in field description (paramter `component`)
     * 3. Format function is specified in field description (parameter `format`)
     * 4. Raw data value is used
     */
    genTableRow(props) {
      if (this.$scopedSlots.items) {
        return this.$scopedSlots.items(props);
      }

      const children = [];
      for (const key in this.fields) {
        const field = this.fields[key];
        const value = get(props.formatted, key);
        // value = field.format ? field.format(value, props.item) : value;

        const cellProps = this.createCellProps(props, {
          key,
          value,
          field,
        });

        let cell;

        if (this.$scopedSlots[key] !== undefined) {
          cell = this.$scopedSlots[key](cellProps);
        } else if (field.component) {
          cell = [
            this.$createElement(field.component, {
              props: cellProps,
            }),
          ];
        } else {
          cell = [value];
        }

        const tdData = this.genCellData(field, key);

        if (!cell || !cell[0]) {
          children.push(this.$createElement('td', tdData, []));
        } else if (this.hasTag(cell, 'td')) {
          children.push(cell);
        } else {
          children.push(this.$createElement('td', tdData, cell));
        }
      }

      return children;
    },

    genExpandedRow(props, index) {
      const children = [];

      if (this.isExpanded(props.item)) {
        const expand = this.$createElement(
          'div',
          {
            class: 'v-datatable__expand-content wb-ba',
            key: get(props.item, this.itemKey),
          },
          [this.$scopedSlots.expand(props)],
        );

        children.push(expand);
      }

      const transition = this.$createElement(
        'transition-group',
        {
          class: 'v-datatable__expand-col',
          attrs: { colspan: this.headerColumns },
          props: {
            tag: 'td',
          },
          on: ExpandTransitionGenerator('v-datatable__expand-col--expanded'),
        },
        children,
      );

      return this.genTR([transition], {
        class: 'v-datatable__expand-row',
        key:
          get(props.item, this.itemKey) +
          (index ? `-${index}` : '') +
          (props.item.isMultipoint ? '-MT' : '') +
          '--expand-row',
      });
    },

    genCellData(header, key) {
      const classes = [];
      const data = {
        key,
        attrs: {
          width: header.width || null,
        },
        style: header.style,
      };

      const align = header.align === undefined || header.align === 'left' ? 'left' : header.align;
      classes.push(`text-xs-${align}`);

      if (Array.isArray(header.class)) {
        classes.push(...header.class);
      } else if (header.class) {
        classes.push(header.class);
      }
      data.class = classes;

      return data;
    },

    createCellProps(rowProps, data) {
      const cellProps = this.createProps(rowProps.item, rowProps.index);

      Object.assign(cellProps, data);

      return cellProps;
    },

    genSelectCount() {
      return this.$createElement(
        'div',
        {
          class: ['d-flex', 'align-center'],
          style: {
            zIndex: 1,
            fontSize: '12px',
          },
        },
        [
          this.$createElement(
            'span',
            {
              style: {
                color: 'rgba(0, 0, 0, 0.54)',
              },
              class: ['pr-2'],
            },
            [`${$t('Selected rows')}:`],
          ),
          this.$createElement('span', {}, [`${Object.keys(this.selected).length}`]),
        ],
      );
    },

    genActionsRow() {
      const actionsRow = [];

      if (this.$scopedSlots.actionsHeader) {
        actionsRow.push(
          this.$createElement(
            'div',
            {
              class: ['d-flex', 'align-center'],
            },
            [this.$scopedSlots.actionsHeader()],
          ),
        );
      }

      actionsRow.push(this.$createElement('v-spacer'));

      if (this.selectedCount) {
        actionsRow.push(this.genSelectCount());
      }

      actionsRow.push(this.genActions());

      return this.$createElement(
        'div',
        {
          style: {
            display: 'flex',
          },
        },
        actionsRow,
      );
    },

    genActionsFooter() {
      if (this.hideActions) {
        return null;
      }

      if (this.itemsLength <= this.computedPagination.rowsPerPage) {
        return null;
      }

      return this.$createElement(
        'div',
        {
          class: this.classes,
        },
        this.genActions(),
      );
    },

    genPagination() {
      let pagination = '–';

      if (this.itemsLength) {
        const stop = this.itemsLength < this.pageStop || this.pageStop < 0 ? this.itemsLength : this.pageStop;
        const totalRelation = this.totalRelation === 'gte' ? '⩾ ' : this.totalRelation === 'lte' ? '⩽ ' : '';

        pagination = this.$scopedSlots.pageText
          ? this.$scopedSlots.pageText({
              pageStart: this.pageStart + 1,
              pageStop: stop,
              itemsLength: this.itemsLength,
              totalRelation,
            })
          : this.$vuetify.t(
              '$vuetify.dataIterator.pageText',
              ...[this.pageStart + 1, stop, totalRelation, this.itemsLength].map(n =>
                typeof n === 'number' ? Number(n).toLocaleString(this.$vuetify.lang.current) : n,
              ),
            );
      }

      return this.$createElement(
        'div',
        {
          class: this.actionsPaginationClasses,
        },
        [pagination],
      );
    },
  },

  computed: {
    headerColumns() {
      return this.headersLength || (this.fields && Object.keys(this.fields).length);
    },
  },

  render(h) {
    const tableOverflow = h(
      VTableOverflow,
      {
        directives: this.dragscroll ? [{ name: 'dragscroll' }] : [],
      },
      [
        h(
          'table',
          {
            class: this.classes,
          },
          [this.genTHead(), this.genTBody(), this.genTFoot()],
        ),
      ],
    );

    return h('div', [tableOverflow, this.genActionsFooter()]);
  },
});
