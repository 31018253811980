import Vue from 'vue';
import TypeIcon, { defaultIconProps, IconData } from './TypeIcon';
import Trip, { TripStatus } from '@/models/Trip';
import { VNode } from 'vue/types/umd';

const TRIP_ICONS = {
  [TripStatus.active]: { icon: 'fa-play', tooltip: 'trip_status.active' },
  [TripStatus.finished]: { icon: 'fa-flag-checkered', tooltip: 'trip_status.finished' },
  [TripStatus.cancelled]: { icon: 'fa-times', tooltip: 'trip_status.cancelled' },
  [TripStatus.manuallyFinished]: {
    icon: 'fa-flag-checkered',
    color: 'red darken-2',
    tooltip: 'trip_status.manuallyFinished',
  },
  [TripStatus.pending]: { icon: 'fa-paper-plane', tooltip: 'trip_status.pending' },
  [TripStatus.pendingViewed]: { icon: 'visibility', tooltip: 'trip_status.pendingViewed' },
  [TripStatus.operatorCancelled]: { icon: 'fa-user-times', tooltip: 'trip_status.operatorCanceled' },
  [TripStatus.planned]: { icon: 'alarm', tooltip: 'trip_status.planned' },
  [TripStatus.draft]: { icon: 'fa-pencil', tooltip: 'trip_status.draft' },
  [TripStatus.notAssigned]: { icon: 'mdi-account-clock', tooltip: 'trip_status.notAssigned' },
  [TripStatus.deleted]: { icon: 'fa-trash', tooltip: 'trip_status.deleted' },
};

export default Vue.extend({
  functional: true,

  name: 'TypeStatusIcon',

  props: {
    ...defaultIconProps,
    item: Trip,
    chat: Object,
  },

  render(h, { props, parent }) {
    const trip = props.item;
    const children: VNode[] = [];
    // FIXME RETURN

    let tooltip = null;
    if (trip.status === TripStatus.active && trip.hasChanges) {
      if (props.detailed) {
        children.push(h('v-icon', { class: 'fa-stack-2x icon-note', props: { size: 18 } }, ['fa-pencil']));
      } else {
        children.push(h('v-icon', { class: 'fa-stack-2x icon-note' }, ['fa-pencil']));
      }
      tooltip = parent.$t('trip_status.active') + ' (' + parent.$t('trip_status.hasChanges') + ')';
    } else if (trip.status === TripStatus.cancelled) {
      tooltip = parent.$t('trip_status.cancelled');
      if (trip.cancelReason && !props.detailed) {
        tooltip = tooltip + ' ' + parent.$t(`cancelReason.${trip.cancelReason}`);
      }
    }

    const data: IconData = TRIP_ICONS[trip.status];

    let el = h(
      TypeIcon,
      {
        props: {
          icon: data.icon,
          tooltip: tooltip ? tooltip : data.tooltip,
          color: data.color,
          detailed: props.detailed,
        },
      },
      children,
    );

    if (!props.detailed && props.chat && props.chat.newMessages > 0) {
      const badgeCount = props.chat.newMessages;
      const color = 'amber accent-2';

      el = h(
        'v-badge',
        {
          class: 'chat-badge',
          props: {
            color,
          },
        },
        [
          h(
            'span',
            {
              slot: 'badge',
            },
            [badgeCount],
          ),
          el,
        ],
      );
    }

    return el;
  },
});
