export default {
  namespaced: true,

  state: {
    currentMarker: null,
    isShown: false,
  },

  mutations: {
    showTooltip(state, marker) {
      if (state.currentMarker) {
        state.currentMarker.setActive(false);
      }

      marker.setActive(true);
      state.currentMarker = marker;
      state.isShown = true;
    },

    hideTooltip(state) {
      if (state.currentMarker) {
        state.currentMarker.setActive(false);
        state.currentMarker = null;
        state.isShown = false;
      }
    },
  },
};
