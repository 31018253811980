
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { MetaInfo } from 'vue-meta';

import EventBus from '@/event-bus';

import api, { AccountsResponse } from '@/api/account';
import billingApi from '@/api/billing';
import branch from '@/api/branch';

import { AccountStatus } from '@/models/Account';
import Uuid from '@/models/Uuid';
import Account, { AccountDto } from '@/models/Account';

import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import Grid from '@/components/grid/GridCrud';
import AccountsFilter from '@/components/filters/AccountsFilter.vue';
import AccountName from '@/components/AccountName.vue';
import AccountsFilterDto from '@/components/filters/AccountsFilterDto';
import AccountDeviceInfo from '@/components/AccountDeviceInfo.vue';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import account from '@/api/account';

export default Vue.extend({
  name: 'Accounts',

  metaInfo(): MetaInfo {
    return {
      title: String(this.$i18n.t('Employees')),
    };
  },

  components: {
    Grid,
    GridTh,
    GridSelectAll,
    AccountName,
    AccountsFilter,
    AccountDeviceInfo,
    Checkbox,
    DeleteDialog,
  },

  data() {
    return {
      apiFunction: api.list,
      accountIds: [] as Uuid[],
      unselectedAccountIds: [] as Uuid[],
      filter: new AccountsFilterDto(),
      pagination: {} as {
        page: number;
        rowsPerPage: number;
        sortBy: string;
        descending: boolean;
      },
      deleteDialog: false,
      deleteError: null,
      deleteItem: null as Account | null,
      selected: [] as Uuid[],
      multiDeleteDialog: false,
      multiDeleteError: null,
      error: null,
    };
  },

  async created() {
    this.parseURL();

    await this.getMyBranchList();
    await this.fetchBillingInfo();

    EventBus.$on('accounts-refresh', () => {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    });
  },

  beforeDestroy() {
    EventBus.$off('accounts-refresh');
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapState('account', ['id']),
    ...mapGetters('billing', ['availableSlots', 'availableTrips', 'isActiveBillingStatus']),
    ...mapGetters('auth', ['hasPermission']),

    fields(): object {
      return {
        fullName: {
          label: 'Employee',
          sortable: true,
          defaultSort: true,
        },
        jobTitle: {
          label: 'Job title',
          sortable: true,
          align: 'left',
        },
        email: {
          label: 'E-Mail',
          sortable: true,
          align: 'left',
        },
        phone: {
          label: 'Phone',
          sortable: true,
          align: 'left',
        },
        mobileDeviceInfo: {
          label: 'App version',
          align: 'left',
        },
        ownTransportType: {
          label: 'Own transport type',
          format: (v: string) => {
            return this.$t(`TransportType.${v}`);
          },
          align: 'left',
        },
        'role.name': {
          label: `Account\'s role`,
          align: 'left',
          sortable: false,
        },
        isRegular: {
          label: 'Pay Rate',
          format: (v: string) => {
            return this.$t(`PayRateIsRegular.${v}`);
          },
        },
        status: {
          label: 'Status',
          sortable: true,
          format: (v: string) => {
            return this.$t(`account_status.${v.toLowerCase()}`);
          },
          align: 'left',
        },
        actions: {
          sortable: false,
        },
      };
    },

    deleteItemName(): string {
      const str = this.deleteItem ? ` "${this.deleteItem.fullName}"` : '';
      return this.$t('common_strings.account') + str;
    },
  },

  methods: {
    async getMyBranchList(options?: { inactive: boolean }) {
      await branch.listMy(options);
    },

    filterAccounts() {
      if (typeof localStorage !== 'undefined') {
        const filter = localStorage.getItem('filterAccounts') || '{}';
        return JSON.parse(filter);
      }
    },

    async fetchBillingInfo(): Promise<void> {
      await billingApi.info.infoGetMain();
    },

    details(account: AccountDto, event?: MouseEvent): void {
      const url = this.$route.path + '/' + account.id;

      if (event && (event.metaKey || event.ctrlKey || event.shiftKey)) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },

    filterUpdate(value: AccountsFilterDto): void {
      localStorage.setItem('filterAccounts', JSON.stringify(value));
      this.filter = new AccountsFilterDto(value);
    },

    showMultiDeleteDialog() {
      this.multiDeleteError = null;
      this.multiDeleteDialog = true;
    },

    async multiHandleDelete() {
      try {
        if (this.selected.length) {
          await api.removeAccounts(this.selected);
        }

        (this.$refs.grid as Vue & { getItems: () => void }).getItems();

        await this.fetchBillingInfo();

        this.multiDeleteDialog = false;
      } catch (err) {
        this.multiDeleteError = err;
      } finally {
        this.selected = [];
      }
    },

    showDeleteDialog(item: Account): void {
      this.deleteError = null;
      this.deleteDialog = true;
      this.deleteItem = item;
    },

    async handleDelete(): Promise<void> {
      try {
        if (this.deleteItem?.id) {
          await api.remove(this.deleteItem.id);
        }

        (this.$refs.grid as Vue & { getItems: () => void }).getItems();

        await this.fetchBillingInfo();

        this.deleteDialog = false;
      } catch (err) {
        this.deleteError = err;
      } finally {
        this.selected = this.selected.filter(id => id !== this.deleteItem?.id);
      }
    },

    parseURL(): void {
      if (Object.keys(this.$route.query).length) {
        this.filterUpdate(new AccountsFilterDto().fromQuery(this.$route.query));
      } else if (![null, undefined, ''].includes(localStorage.getItem('filterAccounts'))) {
        this.filter = new AccountsFilterDto(JSON.parse(localStorage.getItem('filterAccounts') || ''));
      }

      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page as string) + 1;
      }

      if (this.$route.query.pageSize) {
        this.pagination.rowsPerPage = parseInt(this.$route.query.pageSize as string);
      }

      if (this.$route.query.sort) {
        this.pagination.sortBy = String(this.$route.query.sort);
      }

      if (this.$route.query.sortDesc) {
        this.pagination.descending = this.$route.query.sortDesc === '1';
      }
    },

    makeQuery() {
      return {
        ...(this.$refs.grid as Vue & { makeQuery: () => any }).makeQuery(),
        ...this.filter.toQuery(),
      };
    },

    refresh() {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    },

    accountsUpdate(data: AccountsResponse) {
      const accountIds = [];
      const unselected = [];

      for (const account of data.result) {
        if (account.canDelete && !this.isInactiveStatus(account.status)) {
          accountIds.push(account.id);
        } else {
          unselected.push(account.id);
        }
      }

      this.accountIds = accountIds;
      this.unselectedAccountIds = unselected;
    },

    selectAllAccounts(value?: boolean) {
      if (!value) {
        return;
      }

      this.selected = this.accountIds;
    },

    isInactiveStatus(status: string): boolean {
      return status === AccountStatus.inactive;
    },

    isRootAccount(id: string): boolean {
      return id === this.id;
    },

    errorUpdate(error: any) {
      this.error = error;
    },
  },

  watch: {
    filter: {
      handler: async function(n, o) {
        await this.$router.replace({ name: 'accounts', query: this.makeQuery() });

        // show inactive accounts
        if (n && o && n.inactive !== o.inactive) {
          await this.getMyBranchList({ inactive: Boolean(n.inactive) });
        }
      },
      deep: true,
    },

    pagination: {
      handler: async function(n, o) {
        await this.$router.replace({ name: 'accounts', query: this.makeQuery() });

        // move to another page in the table
        if (n && o && n.page !== o.page) {
          this.selected = [];
        }
      },
      deep: true,
    },

    accountIds() {
      if (this.selected.length) {
        this.selected = this.accountIds.filter((id: string) => this.selected.includes(id));
      }
    },
  },
});
