
import Vue from 'vue';
import { mapState } from 'vuex';

import Trip, { SummaryIndicators } from '@/models/Trip';

export default Vue.extend({
  name: 'TripSummaryIndicators',

  props: {
    trip: Object as () => Trip,
  },

  computed: {
    ...mapState('chats', ['new_messages_count_by_trip_ids']),

    summary(): SummaryIndicators {
      return this.trip.summaryIndicators;
    },

    getNewMessagesCountByTripId(): any {
      return this.new_messages_count_by_trip_ids[this.trip.id];
    },
  },
});
