import http, { pagingParams, Params } from './httpV2';
import { Role, RoleDto } from '@/models/Role';
import Uuid from '@/models/Uuid';
import { Rule } from '@/models/Rule';

const path = '/roles';

export interface RolesResponse {
  result: Array<Role>;
  total: number;
}

async function getRoles(opts: Params): Promise<RolesResponse> {
  if (opts && opts.filter) {
    if (opts.filter.toHttpParams) {
      opts.filter = opts.filter.toHttpParams();
    }

    opts.filter = {
      'filters[name][$ilike]': '%' + opts.filter + '%',
    };
  }

  const res = await http.get(path, {
    params: pagingParams(opts),
  });

  res.data.result = res.data?.result.map((e: RoleDto) => new Role(e));
  return res.data;
}

async function getRole(id: Uuid): Promise<Role> {
  const res = await http.get(`${path}/${id}`);
  return new Role(res.data);
}

async function createRole(data: any): Promise<any> {
  const res = await http.post(path, data);
  return res.data;
}

async function patchRole(id: Uuid, data: any): Promise<any> {
  const res = await http.patch(`${path}/${id}`, data);
  return res.data;
}

async function deleteRole(id: Uuid): Promise<any> {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function getRules() {
  const res = await http.get(`${path}/rules`);

  // @ts-ignore
  return res.data.map(i => new Rule(i));
}

export default {
  getRoles,
  getRole,
  createRole,
  patchRole,
  deleteRole,
  getRules,
};
