
import Vue from 'vue';
import colors from 'vuetify/lib/util/colors';
// @ts-ignore
import { Compact } from 'vue-color';

const DEFAULT_COLOR = colors.blue.base;

export class ColorData {
  color: string = DEFAULT_COLOR;
}

export default Vue.extend({
  name: 'CustomColorPicker',

  components: { 'chrome-picker': Compact },

  data() {
    return {
      displayPicker: false,
      pickerColor: {
        hex: DEFAULT_COLOR,
      },
      pickerPalette: [
        colors.red.base,
        colors.pink.base,
        colors.purple.base,
        colors.deepPurple.base,
        colors.indigo.base,
        colors.blue.base,
        colors.lightBlue.base,
        colors.cyan.base,
        colors.teal.base,
        colors.green.base,
        colors.lightGreen.base,
        colors.lime.base,
        colors.yellow.base,
        colors.amber.base,
        colors.orange.base,
        colors.deepOrange.base,
        colors.brown.base,
        colors.blueGrey.base,
        colors.grey.base,
      ],
    };
  },

  props: {
    value: String,
  },

  created() {
    this.pickerColor.hex = this.color;
  },

  computed: {
    color(): string {
      return this.value ? (colors as any)[this.value].base : DEFAULT_COLOR;
    },

    colorCodeToName() {
      return Object.fromEntries(
        Object.keys(colors)
          .filter(color => !['shades'].includes(color))
          .map(color => [(colors as any)[color].base, color]),
      );
    },
  },

  methods: {
    showPicker() {
      this.displayPicker = true;
    },

    hidePicker() {
      this.displayPicker = false;
    },

    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },

    updateFromPicker(color: any) {
      this.pickerColor = color;

      if (color.rgba.a == 1) {
        this.$emit('input', this.colorCodeToName[color.hex.toLowerCase()]);
      } else {
        const rgba = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
        this.$emit('input', this.colorCodeToName[rgba.toLowerCase()]);
      }
    },
  },
});
