<script>
import OrgChart from '../../vendor/orgchart.js/orgchart';

OrgChart.prototype._clickChart = function(event) {
  const closestNode = this._closest(event.target, function(el) {
    return el.classList && el.classList.contains('node');
  });

  const focusedNode = this.chart.querySelector('.node.focused');

  if (!closestNode && focusedNode) {
    focusedNode.classList.remove('focused');

    if (this.unselectNode) {
      this.unselectNode(focusedNode);
    }
  }
};
/* eslint-disable vue/require-default-prop */

export default {
  name: 'Orgchart',

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    pan: { type: Boolean, default: false },
    zoom: { type: Boolean, default: false },
    direction: { type: String, default: 't2b' },
    verticalDepth: { type: Number },
    toggleSiblingsResp: { type: Boolean, default: false },
    ajaxURL: { type: Object },
    depth: { type: Number, default: 999 },
    nodeTitle: { type: String, default: 'name' },
    parentNodeSymbol: { type: String, default: '' },
    nodeContent: { type: String },
    nodeId: { type: String, default: 'id' },
    createNode: { type: Function },
    exportButton: { type: Boolean, default: false },
    exportFilename: { type: String },
    chartClass: { type: String, default: '' },
    draggable: { type: Boolean, default: false },
    dropCriteria: { type: Function },
    toggleCollapse: { type: Boolean, default: true },
    selectNode: { type: Function },
    unselectNode: { type: Function },
  },

  data() {
    return {
      orgchart: null,
      defaultOptions: {
        chartContainer: '#chart-container',
      },
    };
  },

  mounted() {
    this.initOrgChart();
  },

  render(h) {
    return h('div', { attrs: { id: 'chart-container' } });
  },

  watch: {
    data() {
      this.orgchart = null;

      const node = document.getElementById('chart-container');

      while (node.firstChild) {
        node.removeChild(node.firstChild);
      }

      this.initOrgChart();
    },
  },

  methods: {
    initOrgChart() {
      const opts = { ...this.defaultOptions, ...this.$props };
      this.orgchart = new OrgChart(opts);

      if (this.selectNode || this.unselectNode) {
        const clickNode = this.orgchart._clickNode.bind(this.orgchart);
        const self = this;

        this.orgchart._clickNode = function(event) {
          const focusedNode = this.chart.querySelector('.focused');
          clickNode(event);

          if (focusedNode && self.unselectNode) {
            self.unselectNode(focusedNode);
          }

          if (self.selectNode) {
            self.selectNode(event.currentTarget);
          }
        };
      }

      if (this.unselectNode) {
        this.orgchart.unselectNode = node => {
          this.unselectNode(node);
        };
      }
    },
  },
};
</script>
