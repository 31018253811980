
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';

import { isSameDay } from '@/lib/date';

import tripsApi from '@/api/tripNew';
import Trip from '@/models/Trip';
import { Params } from '@/api/httpV2';
import chatApi from '@/api/chatNew';

import TripsFilterDto from '../filters/TripsFilterDto';

import Uuid from '@/models/Uuid';

import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import GridCrud from '@/components/grid/GridCrud';
import TripListSingleRow from '@/components/trip/TripListSingleRow.vue';
import TripListFirstRow from '@/components/trip/TripListFirstRow.vue';
import TripListSecondRow from '@/components/trip/TripListSecondRow.vue';

export default Vue.extend({
  name: 'TripsGrid',

  components: {
    GridTh,
    GridCrud,
    TripListSingleRow,
    TripListFirstRow,
    TripListSecondRow,
    GridSelectAll,
  },

  props: {
    filter: {
      type: Object as PropType<TripsFilterDto>,
      default: () => new TripsFilterDto(),
    },
    distribution: {
      type: Boolean,
      default: false,
    },
    hasSettings: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
    },
    selectedCount: {
      type: Boolean,
      default: false,
    },
    hasSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: null,
      data: {
        results: [],
        total: null as number | null,
        aggregations: {
          duration: { value: null as number | null },
          passedWayDistance: { value: null as number | null },
          scheduledCount: { value: null as number | null },
          totalAtClients: { value: null as number | null },
          totalAtNonClients: { value: null as number | null },
          totalInTransit: { value: null as number | null },
        },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    apiFunction(): Function {
      return async (opts: Params) => {
        const data = await tripsApi.list(opts);

        const tripIds = data.result.map(trip => trip.id);
        await this.getNewMessagesCountByTripIds(tripIds);

        this.$emit('statuses-by-trip-ids', new Map(data.result.map(trip => [trip.id, trip.status])));

        return data;
      };
    },

    tripModel(): typeof Trip {
      return Trip;
    },

    fields(): any {
      return {
        dbId: {
          label: 'ID',
          sortable: true,
          align: 'right',
          defaultSort: 1,
        },
        events: {
          label: 'Events',
          sortable: this.filter?.events?.length === 1,
          align: 'center',
          class: 'col-narrow',
          style: {
            width: '182px',
          },
        },
        createdAt: {
          label: 'Creation',
          sortable: true,
        },
        startedAtDate: {
          groupLabel: 'Trip',
          groupColspan: 4,
          label: 'Date',
          align: 'center',
        },
        startedAt: {
          label: 'Start',
          sortable: true,
          align: 'left',
          class: 'col-start',
        },
        finishedAt: {
          label: 'Endpoint',
          sortable: true,
          align: 'left',
        },
        'transport.number': {
          label: 'number',
          sortable: false,
          align: 'center',
        },
        executor: {
          label: 'Employee',
          sortable: this.hasPermission('search'),
        },
        responsible: {
          label: 'Responsible',
          sortable: this.hasPermission('search'),
        },
        'summaryIndicators.passedWayDistance': {
          groupLabel: 'Duration',
          groupColspan: 2,
          sortable: true,
          align: 'right',
          label: 'Path',
          class: 'col-narrow',
        },
        'summaryIndicators.duration': {
          label: 'Trip time',
          sortable: true,
          align: 'right',
          class: 'col-narrow',
        },
        'summaryIndicators.scheduledCount': {
          groupLabel: 'Clients',
          groupColspan: 2,
          sortable: true,
          align: 'right',
          class: 'col-narrow',
          label: this.$t('Amount'),
        },
        'summaryIndicators.totalAtClients': {
          align: 'right',
          label: 'At clients',
          class: 'col-narrow',
          sortable: true,
        },
        'summaryIndicators.totalInTransit': {
          align: 'right',
          label: 'In transit',
          class: 'col-narrow',
          sortable: true,
        },
        'summaryIndicators.totalAtNonClients': {
          align: 'right',
          label: 'At stops',
          class: 'col-narrow',
          sortable: true,
        },
        status: {
          label: 'Status',
          sortable: true,
        },
        actions: {
          label: 'actions',
          sortable: false,
        },
      };
    },

    totals(): any[] {
      return [
        {
          colspan:
            (this.hasPermission('trips delete') || this.hasPermission('trips update') ? 8 : 7) + Number(this.hasSelect),
          align: 'right',
          value: this.$t('Total') + ':',
        },
        {
          align: 'right',
          class: 'col-narrow',
          value:
            this.data.aggregations?.passedWayDistance?.value &&
            this.$n(this.data.aggregations?.passedWayDistance?.value, 'distance'),
        },
        {
          align: 'right',
          class: 'col-narrow',
          value:
            this.data.aggregations?.duration?.value && this.$d(this.data.aggregations?.duration?.value, 'duration'),
        },
        {
          align: 'right',
          class: 'col-narrow',
          value: this.data.aggregations?.scheduledCount?.value,
        },
        {
          align: 'right',
          class: 'col-narrow',
          value:
            this.data.aggregations?.totalAtClients?.value &&
            this.$d(this.data.aggregations?.totalAtClients?.value, 'duration'),
        },
        {
          align: 'right',
          class: 'col-narrow',
          value:
            this.data.aggregations?.totalInTransit?.value &&
            this.$d(this.data.aggregations?.totalInTransit?.value, 'duration'),
        },
        {
          align: 'right',
          class: 'col-narrow',
          value:
            this.data.aggregations?.totalAtNonClients?.value &&
            this.$d(this.data.aggregations?.totalAtNonClients?.value, 'duration'),
        },
        {},
        ...(this.hasSettings ? [{}] : []),
      ];
    },

    isFilterToday(): boolean {
      if (this.filter.date.from && this.filter.date.to) {
        const today = new Date();
        return isSameDay(today, this.filter.date.from) && isSameDay(today, this.filter.date.to);
      } else {
        return false;
      }
    },
  },

  methods: {
    refresh(): void {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    },

    dataUpdate(data: any) {
      this.data = { ...data, results: undefined };
      this.$emit('update:data', data);
    },

    errorUpdate(error: any) {
      this.error = error;
    },

    details(item: any, event: MouseEvent): void {
      this.$emit('details', item, event);
    },

    makeQuery(): any {
      return (this.$refs.grid as Vue & { makeQuery: () => any }).makeQuery();
    },

    async getNewMessagesCountByTripIds(tripIds: Uuid[]): Promise<void> {
      const { result } = await chatApi.getNewMessagesCountByTripIds(tripIds);
      this.$store.commit('chats/setNewMessagesCountByTripIds', result);
    },
  },
});
