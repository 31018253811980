
import { convertDateWithTimeZone } from '@/lib/date';
import Vue from 'vue';
export default Vue.extend({
  props: {
    item: Object,
  },
  methods: {
    convertDateWithTimeZone,
  },
});
