import Uuid from './Uuid';
import { TransportType } from './Transport';
import { PointCoordinates } from './Location';
import { WorkingHours, TripPointType } from './TripPoint';

export enum RoadLocationType {
  normal = 1,
  control = 2,
  raw = 3,
}

export interface WayCoordinates {
  lat: number;
  lng: number;
  type: RoadLocationType;
  timestamp: number;
  accuracy?: number;
}

export interface RouteQueryPoint {
  id: Uuid;
  coordinates: PointCoordinates;
  type: TripPointType;
  stayTime: number | null;
  workingHours: WorkingHours[];
  timezoneOffset: number;
  wayPointId: string | null;
}

export interface RoutingPoint {
  id: Uuid;
  coordinates: PointCoordinates;
  tripPointIds: Uuid[];
}

export interface RouteQuery {
  startAt?: string;
  tripPoints: RouteQueryPoint[];
  versionFormat?: number;
  optimization?: boolean;
  transportType?: TransportType;
}

export interface Leg {
  points: Array<PointCoordinates>;
  summary: {
    arrivalAt?: string;
    departureAt?: string;
    idleTime?: number;
    length: number;
    trafficDelay?: number;
    travelTime: number;
  };
  toTripPointId: string;
}

export interface ActiveRoute {
  legs: Leg[];
  summary: {
    arrivalAt?: string;
    departureAt?: string;
    startAt?: string;
    startTripPointId?: Uuid;
    finishAt?: string | null;
    idleTime?: number;
    length: number;
    trafficDelay?: number;
    travelTime: number;
  };
}

export interface WayBuilderIntermediate {
  buildId: Uuid;
  wayPoints: RoutingPoint[];
}

export interface WayBuilderResponse {
  activeWay: ActiveRoute;
  wayPoints: RoutingPoint[];
  lateness: boolean;
}
