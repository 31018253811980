export default {
  namespaced: true,

  state: {
    select: null,
    loading: false,
  },

  mutations: {
    startLoading(state) {
      state.loading = true;
    },

    stopLoading(state) {
      state.loading = false;
    },

    setSelected(state, select) {
      state.select = select;
    },
  },
};
