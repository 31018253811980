<template>
  <v-layout row>
    <v-flex v-if="executor">
      <div :class="{ 'font-italic': executor.status === accountStatusEnum.inactive }">
        {{ executor.fullName }}
      </div>

      <div
        class="cell-subheader"
        style="max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
      >
        {{ executor.branchNames.map(branch => branch.title).join(', ') }}
      </div>
    </v-flex>
    <v-flex v-else>
      <div>
        {{ '—' }}
      </div>
    </v-flex>

    <v-flex v-if="downtimeCount" xs1>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-avatar color="yellow" size="18" v-on="on">
            <span style="cursor: default">{{ downtimeCount }}</span>
          </v-avatar>
        </template>

        <span>{{ $t('Downtime in trip') }}</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script>
import Account, { AccountStatus } from '@/models/Account';

export default {
  name: 'TripsDriverName',

  props: {
    executor: {
      type: Account,
      default: '',
    },
    downtimeCount: {
      type: Number,
    },
  },

  computed: {
    accountStatusEnum() {
      return AccountStatus;
    },
  },
};
</script>
