import Vue, { PropType } from 'vue';
import { isSameDay } from 'date-fns';

import { convertDateWithTimeZone } from '../lib/date';
import TimeWithOptionalDay, { DisplayStatus, POSSIBLE_STATUSES } from './TimeWithOptionalDay';

export default Vue.extend({
  functional: true,

  name: 'TimeIntervalWithOptionalDay',

  props: {
    /** Beginning of the interval */
    from: Date as PropType<Date>,

    /** End of the interval */
    to: Date as PropType<Date>,

    showDate: Boolean,
    showTimezones: Boolean,
    inOneLine: Boolean,
    timezoneOffset: Number,
    status: String as PropType<DisplayStatus>,
    invalid: Boolean,
  },

  render(h, { props, parent }) {
    let { from, to, showDate, showTimezones, inOneLine, timezoneOffset, status, invalid } = props;

    const fromDate = convertDateWithTimeZone(from, timezoneOffset);
    const toDate = convertDateWithTimeZone(to, timezoneOffset);

    const sameDay = isSameDay(fromDate, toDate);
    if (!sameDay && showDate === null) {
      showDate = true;
    }

    if (POSSIBLE_STATUSES.includes(status)) {
      return h(TimeWithOptionalDay, {
        props: {
          value: from,
          showDate,
          showTimezones,
          inOneLine,
          timezoneOffset,
          status,
          invalid,
        },
      });
    }

    return (
      <v-layout row justify-center>
        <v-flex mr-1 style="flex-grow: 0; text-align: right">
          {h(TimeWithOptionalDay, {
            props: {
              value: from,
              showDate: showDate,
              showTimezones: false,
              inOneLine: inOneLine,
              timezoneOffset: timezoneOffset,
              invalid,
            },
          })}
        </v-flex>
        {'–'}
        <v-flex ml-1 style="flex-grow: 0; text-align: left">
          {h(TimeWithOptionalDay, {
            props: {
              value: to,
              showDate: showDate && (!inOneLine || !sameDay),
              showTimezones: showTimezones,
              inOneLine: inOneLine,
              timezoneOffset: timezoneOffset,
              invalid,
            },
          })}
        </v-flex>
      </v-layout>
    );
  },
});
