
import Vue from 'vue';
import { TripTableRow } from '@/services/TripPageService';

export default Vue.extend({
  name: 'PointNumber',

  props: {
    item: TripTableRow,
  },
});
