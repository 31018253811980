/* global google */

import { extend } from '@/lib/util';
import config from '../config';

/**
 * Draws custom marker like:
 *
 *    SECOND_LABEL
 *
 *       -----
 *      /     \
 *     |       |
 *     | LABEL |
 *      \     /
 *       \   /
 *        \ /
 *         .
 *
 *     DOWN_LABEL
 *
 * Interface loosely conforms google.maps.Marker
 *
 * @param       {[type]} options [description]
 * @constructor
 */
function MapMarker(options) {
  extend(MapMarker, google.maps.OverlayView);

  this.set('active', false);
  this.set('draggable', false);
  this.set('angle', undefined);
  this.set('isCurrent', false);
  this.setValues(options);

  if (!(this.position instanceof google.maps.LatLng)) {
    this.position = new google.maps.LatLng(this.position);
  }
  this.label = new MarkerLabelOptions(this.label);
  this.secondLabel = new SecondLabelOptions(this.secondLabel);
  this.downLabel = new SecondLabelOptions(this.downLabel);

  // markerAnchor = new google.maps.Point(-markerAnchor.x, -markerAnchor.y);

  this.div = document.createElement('div');
  this.div.style.position = 'absolute';

  google.maps.event.addDomListener(this.div, 'click', e => {
    e.stopPropagation && e.stopPropagation();
    if (!this.draggable) {
      google.maps.event.trigger(this, 'click');
    }
  });

  google.maps.event.addDomListener(this.div, 'dblclick', e => {
    e.stopPropagation && e.stopPropagation();
    if (!this.draggable) {
      google.maps.event.trigger(this, 'dblclick');
    }
  });

  this.markerIcon = new MarkerIcon({
    container: this.div,
    marker: this,
  });

  this.markerSecondLabel = new PointLabel({
    map: this.map,
    container: this.div,
    marker: this,
    data: this.secondLabel,
  });

  this.markerDownLabel = new PointLabel({
    map: this.map,
    container: this.div,
    marker: this,
    className: 'down',
    data: this.downLabel,
  });

  if (!this.skipCurrentPositionMarker) {
    this.currentPositionMarker = new MapMarker({
      point: null,
      position: this.position,
      icon: {
        scaledSize: new google.maps.Size(40, 40), // scaled size
        url: config.icons.currentPosition, // url
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(20, 22), // anchor
      },
      map: this.map,
      zindex: 999,
      skipCurrentPositionMarker: true,
    });
  }

  this.update();
}

MapMarker.prototype.setActive = function(value) {
  this.set('active', value);
};

MapMarker.prototype.setDraggable = function(value) {
  this.set('draggable', value);
};

MapMarker.prototype.setIsNext = function(value) {
  this.set('isNext', value);
};

MapMarker.prototype.setIsCurrent = function(isCurrent) {
  this.set('isCurrent', isCurrent);
};

MapMarker.prototype.getMarkerAnchor = function() {
  return this.icon.anchor;
};

MapMarker.prototype.getAnchorPoint = function() {
  const markerAnchor = this.getMarkerAnchor();

  let labelAnchor = -markerAnchor.y;
  if (!this.secondLabel.isEmpty()) {
    labelAnchor +=
      this.secondLabel.getOrigin().y - (this.secondLabel.html && this.secondLabel.html.indexOf('<br') !== -1 ? 31 : 15);
  }

  return new google.maps.Point(0, labelAnchor);
};

MapMarker.prototype.setPosition = function(value) {
  if (!(value instanceof google.maps.LatLng)) {
    value = new google.maps.LatLng(value);
  }
  this.set('position', value);
};

MapMarker.prototype.getPosition = function() {
  if (!(this.position instanceof google.maps.LatLng)) {
    // FIXME remove this
    this.position = new google.maps.LatLng(this.position);
  }
  return this.position;
};

MapMarker.prototype.getShowLabel = function() {
  if (this.get('container')) {
    return this.get('active');
  }
  return true;
};

MapMarker.prototype.onAdd = function() {
  const container = this.get('container') || this.getPanes().overlayMouseTarget;
  container.appendChild(this.div);
  this.update();

  if (this.draggable) {
    this.updateDraggable();
  }

  // this.markerIcon.bindTo('angle', this, 'angle');
  // this.markerSecondLabel && this.markerSecondLabel.bindTo('showLabel', this, 'showLabel');
  // this.markerDownLabel && this.markerDownLabel.bindTo('showLabel', this, 'showLabel');
  this.listeners = [
    google.maps.event.addListener(this, 'container_changed', () => {
      this.updateContainer();
    }),
    google.maps.event.addListener(this.label, 'text_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.label, 'html_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.secondLabel, 'origin_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.secondLabel, 'text_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.secondLabel, 'html_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.secondLabel, 'icon_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'origin_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'text_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'html_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'icon_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'position_changed', () => {
      this.draw();
    }),
    google.maps.event.addListener(this, 'icon_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'active_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'isnext_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'iscurrent_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'angle_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'draggable_changed', () => {
      this.updateDraggable();
    }),
  ];
};

MapMarker.prototype.onRemove = function() {
  this.div.parentNode.removeChild(this.div);

  this.unbindAll();
  // this.markerIcon.unbindAll();
  this.markerSecondLabel && this.markerSecondLabel.unbindAll();
  this.markerDownLabel && this.markerDownLabel.unbindAll();
  for (const listener of this.listeners) {
    google.maps.event.removeListener(listener);
  }
};

/**
 * Called by google maps to draw marker in specific position
 */
MapMarker.prototype.draw = function() {
  const position = this.getProjection().fromLatLngToDivPixel(this.get('position'));
  const container = this.get('container');

  if (container) {
    this.div.style.left = String(position.x - container.style.left.slice(0, -2)) + 'px';
    this.div.style.top = String(position.y - container.style.top.slice(0, -2)) + 'px';
  } else {
    this.div.style.left = position.x + 'px';
    this.div.style.top = position.y + 'px';
  }
};

/**
 * Called when map marker moves inside or outside group
 */
MapMarker.prototype.updateContainer = function() {
  const container = this.get('container') || this.getPanes().overlayMouseTarget;

  this.div.parentNode.removeChild(this.div);
  container.appendChild(this.div);
  this.draw();
};

/**
 * Called when marker state has been changed to update marker
 */
MapMarker.prototype.update = function() {
  this.div.className = 'map-marker' + (!this.get('active') ? '' : ' active') + (!this.get('isNext') ? '' : ' isnext');
  if (this.get('active')) {
    this.div.style.zIndex = 998;
  } else if (this.get('isNext')) {
    this.div.style.zIndex = 997;
  } else {
    this.div.style.zIndex = this.get('zindex');
  }

  this.markerIcon && this.markerIcon.update();
  this.markerSecondLabel && this.markerSecondLabel.update();
  this.markerDownLabel && this.markerDownLabel.update();

  if (this.currentPositionMarker) {
    if (this.isCurrent) {
      this.currentPositionMarker.position = this.position;
      this.currentPositionMarker.setMap(this.map);
    } else {
      this.currentPositionMarker.setMap(null);
    }
  }
};

MapMarker.prototype.updateDraggable = function() {
  const container = this.get('container') || this.getPanes().overlayMouseTarget;

  if (this.draggable) {
    this.draggableListeners = [
      google.maps.event.addDomListener(container, 'mousedown', e => {
        this.dragStartHandler(e);
      }),
      google.maps.event.addDomListener(container, 'mousemove', e => {
        this.dragHandler(e);
      }),
      google.maps.event.addDomListener(this.map.getDiv(), 'mouseleave', () => {
        this.dragStopHandler();
      }),
      google.maps.event.addListener(this.map, 'bounds_changed', () => {
        this.dragStopHandler();
      }),
      google.maps.event.addDomListener(container, 'mouseup', e => {
        this.dragStopHandler();
      }),
    ];
  } else if (Array.isArray(this.draggableListeners) && this.draggableListeners.length) {
    this.dragStopHandler();

    for (const listener of this.draggableListeners) {
      google.maps.event.removeListener(listener);
    }
    this.draggableListeners = [];
  }
};

MapMarker.prototype.dragStartHandler = function(e) {
  if (this.map) {
    this.map.set('gestureHandling', 'none');
    google.maps.event.trigger(this.map, 'calibrateStart');
  }

  this.moveEvent = e;
};

MapMarker.prototype.dragHandler = function(e) {
  const moveEvent = this.moveEvent;

  if (moveEvent) {
    const left = moveEvent.clientX - e.clientX;
    const top = moveEvent.clientY - e.clientY;
    const pos = this.getProjection().fromLatLngToDivPixel(this.get('position'));
    const latLng = this.getProjection().fromDivPixelToLatLng(new google.maps.Point(pos.x - left, pos.y - top));

    this.set('position', latLng);
    // this.draw();

    this.moveEvent = e;
  }
};

MapMarker.prototype.dragStopHandler = function() {
  if (this.map) {
    this.map.set('gestureHandling', 'auto');
    google.maps.event.trigger(this.map, 'calibrateStop');
  }

  this.moveEvent = null;
  google.maps.event.trigger(this, 'calibrate', this.position);
};

/**
 * Draws map marker pin
 * @param       {[type]} options [description]
 * @constructor
 */
function MarkerIcon(options) {
  Object.assign(this, options);
  this.genMarkerIcon(this.container);
}

MarkerIcon.prototype.genMarkerIcon = function(parent) {
  const imageDiv = document.createElement('div');
  this.imageDiv = imageDiv;

  if (this.marker.icon.url) {
    const img = document.createElement('img');
    imageDiv.appendChild(img);
    this.img = img;
  }

  const labelDiv = document.createElement('div');
  labelDiv.className = 'label';
  labelDiv.style.position = 'absolute';
  imageDiv.appendChild(labelDiv);
  this.labelDiv = labelDiv;

  parent.appendChild(imageDiv);
};

MarkerIcon.prototype.update = function() {
  const { icon, label, angle } = this.marker;

  this.imageDiv.removeAttribute('style');
  this.imageDiv.style.position = 'absolute';

  const anchor = icon.anchor;
  if (icon.scaledSize) {
    if (this.img) {
      this.img.width = icon.scaledSize.width;
      this.img.height = icon.scaledSize.height;
    } else {
      this.imageDiv.style.width = icon.scaledSize.width + 'px';
      this.imageDiv.style.height = icon.scaledSize.height + 'px';
    }
  }
  if (icon.url) {
    this.img.src = icon.url;
  }
  if (anchor) {
    this.imageDiv.style.left = -anchor.x + 'px';
    this.imageDiv.style.top = -anchor.y + 'px';
  }
  if (icon.class) {
    this.imageDiv.classList.add(icon.class);
  }
  if (icon.style) {
    Object.assign(this.imageDiv.style, icon.style);
  }

  while (this.labelDiv.firstChild) {
    this.labelDiv.removeChild(this.labelDiv.firstChild);
  }
  if (label) {
    if (label.text) {
      this.labelDiv.appendChild(document.createTextNode(label.text));
    } else if (label.html) {
      this.labelDiv.innerHTML = label.html;
    }
  }
  this.labelDiv.style.left = (icon.labelOrigin ? icon.labelOrigin.x : 0) + 'px';
  this.labelDiv.style.top = (icon.labelOrigin ? icon.labelOrigin.y : 0) + 'px';

  if (angle !== undefined) {
    this.imageDiv.style.transform = `rotate(${angle}deg)`;
    this.imageDiv.style.transformOrigin = `${anchor.x}px ${anchor.y}px`;
  } else {
    this.imageDiv.style.transform = null;
    this.imageDiv.style.transformOrigin = null;
  }
};

/**
 * Draws map marker additional label
 * @param       {[type]} options [description]
 * @constructor
 */
function PointLabel(options) {
  extend(PointLabel, google.maps.MVCObject);

  this.setValues(options);

  // Create the label container
  this.div = document.createElement('div');

  this.container.appendChild(this.div);
}

PointLabel.prototype.update = function() {
  const options = this.data;
  const markerAnchor = this.marker.getMarkerAnchor() || { x: 0, y: 0 };

  if (!options.text && !options.html) {
    this.div.style.display = 'none';
    return;
  }

  const origin = options.getOrigin();

  this.div.className = this.className ? 'map-marker-label ' + this.className : 'map-marker-label';
  this.div.style.position = 'absolute';
  this.div.style.left = origin.x - markerAnchor.x + 'px';
  this.div.style.top = origin.y - markerAnchor.y + 'px';

  if (options.icon) {
    this.div.innerHTML = '<i aria-hidden="true" class="material-icons v-icon">' + options.icon + '</i>';
  } else {
    this.div.innerHTML = '';
  }

  if (options.text) {
    this.div.appendChild(document.createTextNode(options.text));
  } else if (options.html) {
    this.div.innerHTML += options.html;
  }

  if (this.marker.getShowLabel()) {
    this.div.style.display = null;
  } else {
    this.div.style.display = 'none';
  }
};

/**
 * Private interface to store label options as reactive object
 * @private
 * @constructor
 */
function MarkerLabelOptions(options) {
  extend(MarkerLabelOptions, google.maps.MVCObject);
  this.set('text', null);
  this.set('html', null);
  this.setValues(options);
}

/**
 * Private interface to store label options as reactive object
 * @private
 * @constructor
 */
function SecondLabelOptions(options) {
  extend(SecondLabelOptions, google.maps.MVCObject);
  this.setValues(options);
}

SecondLabelOptions.prototype.setValues = function(values) {
  values = values || {};
  this.set('text', values.text || null);
  this.set('html', values.html || null);
  this.set('icon', values.icon || null);
  this.set('origin', values.origin || null);
};

SecondLabelOptions.prototype.getOrigin = function() {
  if (this.origin) {
    return new google.maps.Point(this.origin.x, this.origin.y);
  }
  return new google.maps.Point(0, 0);
};

SecondLabelOptions.prototype.isEmpty = function() {
  return !this.text && !this.html;
};

export { MarkerIcon, PointLabel, SecondLabelOptions, MapMarker };
export default MapMarker;
