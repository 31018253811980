import Vue, { CreateElement } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { getTimeZoneString } from '@/lib/timezone';
import { convertDateWithTimeZone } from '@/lib/date';

// @ts-ignore
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
// @ts-ignore
import { GeoJsonLayer } from '@deck.gl/layers';

import colors from 'vuetify/lib/util/colors';

function convertColor(color: string) {
  return [parseInt(color.substr(1, 2), 16), parseInt(color.substr(3, 2), 16), parseInt(color.substr(5, 2), 16), 255];
}

const COLOR_GREEN = convertColor(colors.green.base);
const COLOR_ORANGE = convertColor(colors.orange.base);
const COLOR_RED = convertColor(colors.red.base);
const COLOR_PURPLE = convertColor(colors.purple.base);
const COLOR_BLACK = convertColor(colors.shades.black);

const props = Vue.extend({
  props: {
    rawPassedWay: {
      type: Object,
      default: () => [],
    },

    timezoneOffset: {
      type: Number,
    },
  },
});

@Component({
  provide: function() {
    const { getMap } = (this as unknown) as { getMap: { (): Promise<google.maps.Map> } };
    return {
      getMap,
    };
  },

  inject: ['getMap'],

  watch: {
    rawPassedWay: {
      handler: function() {
        (this as any).onTripUpdate();
      },
    },
  },
})
export default class TripRawPassedWay extends mixins(props) {
  rawPassedWay!: any;
  deckOverlay = null as GoogleMapsOverlay | null;
  tooltip = document.createElement('div');

  async created() {
    const map: google.maps.Map = await (this as any).getMap();

    document.getElementById('map')?.parentElement?.appendChild(this.tooltip);
    this.tooltip.classList.add('tooltip-absolute');

    await this.drawPoints(map);

    this.$emit('loaded');
  }

  render(h: CreateElement) {
    return h();
  }

  beforeDestroy() {
    if (this.deckOverlay) {
      this.deckOverlay.finalize();
    }
    this.deckOverlay = null;

    if (this.tooltip && this.tooltip.parentElement) {
      this.tooltip.parentElement.removeChild(this.tooltip);
    }
  }

  onTripUpdate() {
    this.drawPoints();
  }

  async drawPoints(map?: google.maps.Map) {
    map = map || (await (this as any).getMap());
    // map.data.addGeoJson(this.rawPassedWay);

    if (this.deckOverlay) {
      this.deckOverlay.finalize();
    }

    this.deckOverlay = new GoogleMapsOverlay({
      layers: [
        new GeoJsonLayer({
          id: 'rawPassedWay',
          data: this.rawPassedWay,

          filled: true,
          pointRadiusMinPixels: 2,
          pointRadiusMaxPixels: 200,
          opacity: 0.4,
          pointRadiusScale: 0.3,
          getRadius: 25,
          getFillColor: this.getFillColor,

          pickable: true,
          autoHighlight: true,
          onHover: this.setTooltip.bind(this),
        }),
      ],

      // getTooltip: (...args: any) => console.log(args),
    });

    this.deckOverlay.setMap(map);
  }

  getFillColor(f: any) {
    const accuracy = f.properties.accuracy;
    let color;

    if (accuracy <= 65) {
      color = COLOR_GREEN;
    } else if (accuracy <= 100) {
      color = COLOR_ORANGE;
    } else if (accuracy <= 300) {
      color = COLOR_RED;
    } else if (accuracy <= 500) {
      color = COLOR_PURPLE;
    } else {
      color = COLOR_BLACK;
    }

    return color;
  }

  setTooltip({ x, y, object }: { x: number; y: number; object: any }) {
    const tooltip = this.tooltip;

    if (object && object.geometry.type == 'Point') {
      const timestamp = new Date(object.properties.timestamp * 1000);
      const timestampWithTimezone = convertDateWithTimeZone(timestamp, this.timezoneOffset);
      const date = this.$d(timestampWithTimezone, 'withSeconds');

      const timezone = getTimeZoneString(this.timezoneOffset);

      const accuracy = this.$n(object.properties.accuracy, 'round');

      // tooltip.style.display = 'block';
      tooltip.style.opacity = '1';
      tooltip.style.left = x + 'px';
      tooltip.style.top = y + 'px';
      tooltip.innerHTML = `${date} ${timezone} (${accuracy} ${this.$t('m')})`;
    } else {
      // tooltip.style.display = 'none';
      tooltip.style.opacity = '0';
    }
  }
}
