<template>
  <v-layout row fill-height justify-center align-center>
    <v-flex md6>
      <v-card v-if="card === 'needLogin'">
        <v-card-title primary-title>
          <div class="headline">
            {{ $t('Login#t') }}
          </div>
        </v-card-title>

        <v-container grid-list-sm class="pa-4">
          <Form :fields="fields" :error="error" v-model="form" @enter="submit" />
        </v-container>

        <v-card-actions>
          <v-btn @click="submit" :disabled="!form.valid" color="primary">
            {{ $t('Login') }}
          </v-btn>

          <debug-only>
            <v-btn @click="submitDebug">
              {{ $t('Sudo') }}
            </v-btn>
          </debug-only>

          <v-spacer />

          <v-btn @click="showRestoreCard" flat>
            {{ $t('Forgot password?') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <RestorePassword
        v-if="card === 'needRestore'"
        :login="form.data.login"
        @back="showLoginCard"
        @multiple="restoreMultiple"
      />

      <SpecifyDomain v-if="card === 'needDomain'" @back="showLoginCard" :form="form" />

      <LoginCompaniesList
        v-if="card === 'needCompanies'"
        :is-restore-password="type === 'restore'"
        @back="showLoginCard"
        :form="type === 'restore' ? { data: { login: restoreLogin } } : form"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import store from '@/store';

import auth from '@/api/auth';
import rules from '@/api/rules';

import RestorePassword from '@/components/RestorePassword';
import SpecifyDomain from '@/components/modals/SpecifyDomain';
import LoginCompaniesList from '@/components/modals/LoginCompaniesList';

export default {
  name: 'Login',

  metaInfo() {
    return {
      title: this.$i18n.t('Login'),
      fillHeight: true,
    };
  },

  components: { RestorePassword, SpecifyDomain, LoginCompaniesList },

  data() {
    return {
      fields: [
        {
          name: 'login',
          label: this.$i18n.t('E-Mail or phone'),
          type: 'text',
          rules: rules.login.required,
        },
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          rules: rules.required('Password'),
        },
      ],
      error: null,
      type: '',
      restoreLogin: '',
      form: {
        valid: true,
        data: {
          login: '',
          password: '',
        },
      },
      card: 'needLogin',
    };
  },

  async beforeRouteEnter(route, from, next) {
    if (route.query.token) {
      try {
        await auth.updateToken(route.query.token);

        next(route.query.redirect || '/');
      } catch (err) {
        console.error(err);
      }
    }

    next();
  },

  methods: {
    async submit() {
      try {
        this.error = null;

        await auth.verify({
          login: this.form.data.login,
          password: this.form.data.password,
        });

        this.$router.replace(this.$route.query.redirect || '/');
      } catch (err) {
        if (err.code === 'MultipleCompaniesFound') {
          this.card = 'needCompanies';
          this.type = 'login';

          if (Array.isArray(err.data) && err.data.length) {
            store.commit('company/setLoginCompanies', err.data);
          }
        } else {
          this.error = err;
        }
      }
    },

    async submitDebug() {
      try {
        this.error = null;

        await auth.verify({
          login: this.form.data.login,
          password: this.form.data.password,
          isDebug: true,
        });

        this.$router.replace(this.$route.query.redirect || '/');
      } catch (err) {
        if (err.code === 'MultipleCompaniesFound') {
          this.card = 'needDomain';
        } else {
          this.error = err;
        }
      }
    },

    showRestoreCard() {
      this.card = 'needRestore';
    },

    showLoginCard() {
      this.card = 'needLogin';
    },

    restoreMultiple(login) {
      this.type = 'restore';
      this.restoreLogin = login;
      this.card = 'needCompanies';
    },
  },
};
</script>
