
import Vue from 'vue';
import TripPoint from '@/models/TripPoint';

export default Vue.extend({
  name: 'PointSummaryIndicators',

  props: {
    point: Object as () => TripPoint,
  },
});
