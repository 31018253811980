import axios from 'axios';
import APIError from './error';

export const baseUrl = '/api/v2';
export const timeout = 30000;

const config = {
  debug: {
    errors: process.env.NODE_ENV === 'development',
  },
};

const http = axios.create({
  baseURL: baseUrl,
  timeout,
  withCredentials: true,
  headers: { 'X-Version': process.env.VUE_APP_VERSION },
});

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response) {
      // Make error visible for error notifier
      const request = error.config;
      if (config.debug.errors && request) {
        setTimeout(() => {
          if (error.response.data) {
            throw new Error(
              [
                request.method.toUpperCase(),
                request.url,
                error.message,
                '\n',
                JSON.stringify(error.response.data, null, '  '),
              ].join(' '),
            );
          }
        }, 0);
      }

      return Promise.reject(APIError.fromResponse(error.response));
    } else {
      return Promise.reject(error);
    }
  },
);

export interface Params {
  rowsPerPage?: number;
  page?: number;
  sortBy?: string;
  descending?: boolean;
  filter?: any;
  select?: any[];
}

export function pagingParams(opts: Params) {
  if (opts) {
    let { rowsPerPage, page, sortBy, descending, filter, ...params } = opts;

    if (typeof filter === 'string') {
      filter = { filter: opts.filter };
    }

    return {
      page: page !== undefined ? page : 0,
      limit: rowsPerPage,
      sort: sortBy,
      sortDir: descending ? 'desc' : 'asc',
      ...filter,
      ...params,
    };
  }
}

export default http;
