import Vue from 'vue';
import { Tag } from '@/models/Order';
import colors from 'vuetify/lib/util/colors';

export default Vue.extend({
  functional: true,

  render(h, { props, data }) {
    const { value } = props as {
      value: Tag;
    };

    const style = data.style || data.staticStyle;

    if (value) {
      return h(
        'div',
        {
          class: data.class,
          staticClass: data.staticClass,
          style: {
            ...(typeof style === 'object' ? style : {}),
            color: (colors as any)[value.color].base,
          },
        },
        ['#' + value.name],
      );
    }
    return h();
  },
});
