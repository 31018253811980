import Vue from 'vue';
import { TripPoint, getStartPoint, getFinishPoint, getActualFinishPoint } from '@/api/trip';
import GroupMarker from './GroupMarker';
import { getIconSettings } from '../config';

export default Vue.extend({
  name: 'StartFinishMarker',
  extends: GroupMarker,
  computed: {
    markerData() {
      const point = this.point;
      if (!point) {
        return;
      }

      let text, downText;

      let startText, finishText;

      const finishPoint = this.actualFinishPoint || this.finishPoint;
      const startPoint = this.startPoint;

      if (startPoint && finishPoint) {
        if (!startPoint.manual && startPoint.checkoutAt) {
          startText = this.$t('Start') + ' ' + this.$d(startPoint.checkoutAt, 'time');
        }

        if (finishPoint.type === TripPoint.TYPE.actualFinish) {
          if (finishPoint.checkinAt && !finishPoint.manual && finishPoint.passed) {
            if (this.trip.containsDifferentDates()) {
              finishText = this.$t('Endpoint') + '<br />' + this.$d(finishPoint.checkinAt, 'long');
            } else {
              finishText = this.$t('Endpoint') + ' ' + this.$d(finishPoint.checkinAt, 'time');
            }
          }
        } else {
          if (finishPoint.checkinAt && !finishPoint.manual && finishPoint.passed) {
            if (this.trip.containsDifferentDates()) {
              finishText = this.$t('Endpoint') + '<br />' + this.$d(finishPoint.checkinAt, 'long');
            } else {
              finishText = this.$t('Endpoint') + ' ' + this.$d(finishPoint.checkinAt, 'time');
            }
          } else if (finishPoint.arrivalPlanAt && !finishPoint.checkinAt) {
            if (this.trip.containsDifferentDates()) {
              finishText =
                this.$t('Endpoint') +
                '<br /><i aria-hidden="true" class="v-icon mdi mdi-tilde"></i> ' +
                this.$d(finishPoint.arrivalPlanAt, 'long');
            } else {
              finishText =
                this.$t('Endpoint') +
                ' <i aria-hidden="true" class="v-icon mdi mdi-tilde"></i> ' +
                this.$d(finishPoint.arrivalPlanAt, 'time');
            }
          }
        }

        if (startText && finishText) {
          downText = startText;
          text = finishText;
        } else {
          text = finishText || startText;
        }

        const lastCheckinPoint = this.lastCheckinPoint;
        if (lastCheckinPoint && !lastCheckinPoint.checkoutAt && !lastCheckinPoint.auto_check) {
          downText = (downText ? downText + '<br />' : '') + this.updatedAtTextOneLine;
        }
      }

      const iconData = getIconSettings('startFinish');

      const result = {
        position: this.position,
        anchor: iconData.anchor,
        secondLabel:
          text && !this.draggable
            ? {
                html: text,
                origin: iconData.secondLabelOrigin,
              }
            : undefined,
        downLabel:
          downText && !this.draggable
            ? {
                html: downText,
                origin: iconData.downLabelOrigin,
              }
            : undefined,
        zindex: point.zindex,
      };

      return result;
    },

    startPoint() {
      return getStartPoint(this.points);
    },

    finishPoint() {
      return getFinishPoint(this.points);
    },

    actualFinishPoint() {
      return getActualFinishPoint(this.points);
    },
  },
});
