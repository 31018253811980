
import Vue from 'vue';
import Trip from '@/models/Trip';
import { TripTableRow } from '@/services/TripPageService';
import PointNumber from './grid/PointNumber.vue';
import PointType from './grid/PointType.vue';
import PoiName from './grid/PoiName.vue';
import DeliveryWindows from './grid/DeliveryWindows';
import CheckinAt from './grid/CheckinAt';
import CheckoutAt from './grid/CheckoutAt';
import WorkTime from './grid/WorkTime';
import PointStayTime from './grid/PointStayTime';
import BatteryInfo from '@/components/BatteryInfo';
import { mapGetters, mapState } from 'vuex';
import { Tag } from '@/models/Order';
import TagComponent from '@/components/Tag';
import { PointFunctionsIcon } from '@/components/icons';

export default Vue.extend({
  inject: ['getComputeColor'],

  components: {
    PointNumber,
    PointType,
    PoiName,
    DeliveryWindows,
    CheckinAt,
    CheckoutAt,
    WorkTime,
    PointStayTime,
    BatteryInfo,
    Tag: TagComponent,
    PointFunctionsIcon,
  },

  props: {
    trip: Trip,
    item: TripTableRow,
    haveWorkTime: Boolean,
    haveDeliveryWindows: Boolean,
    haveTripDetails: Boolean,
    haveOrders: Boolean,
    haveContractors: Boolean,
    havePointFunctions: Boolean,
  },

  computed: {
    ...mapState('map', ['pointSelectId', 'currentSelectRow']),
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('tags', ['tags']),

    computeColor(): string {
      return (this as any).getComputeColor(this.item.status);
    },

    weight(): any {
      let tmp = 0;
      this.item.tripPoint?.orders?.forEach(order => {
        order?.cargos.forEach(cargo => {
          tmp += cargo.capacity.mass;
        });
      });
      return tmp ? tmp.toFixed(2) : '';
    },
    volume(): any {
      let tmp = 0;
      this.item.tripPoint?.orders?.forEach(order => {
        order?.cargos.forEach(cargo => {
          tmp += cargo.capacity.volume;
        });
      });
      return tmp ? tmp.toFixed(2) : '';
    },
  },

  methods: {
    tableRowClick(item: TripTableRow) {
      this.$emit('click', item);
    },

    isRowActive(_item: TripTableRow) {
      if (_item && _item.id === this.currentSelectRow) {
        return true;
      }

      const wayPoint = this.trip.wayPoints.filter(wayPoint => {
        if (wayPoint.id === this.pointSelectId) {
          return wayPoint;
        }
      })[0];

      if (wayPoint && _item && !this.currentSelectRow) {
        if (
          wayPoint.id === _item.id ||
          wayPoint.checkins.some(checkin => checkin.id === _item.id) ||
          wayPoint.tripPointIds.some(tripPointId => _item.tripPoint && tripPointId === _item.tripPoint.id)
        ) {
          return true;
        }
      }
    },

    getTrClass(_item: TripTableRow) {},

    getNumberBorder(top: boolean, bottom: boolean, isMultipoint?: boolean) {
      const style: Partial<CSSStyleDeclaration> = {
        padding: '0 0 0 10px',
        margin: '0',
        width: !isMultipoint ? '10px' : undefined,
      };

      if (top) {
        style.borderTop = '0';
      }

      if (bottom) {
        style.borderBottom = '0';
      }

      if (isMultipoint) {
        style.borderRight = '0';
      }

      return style;
    },

    getItemTags(tagIds: string[]): Tag[] {
      if (tagIds) {
        return this.tags.filter((item: Tag) => tagIds.includes(item.id));
      }

      return [];
    },
  },
});
