<template>
  <v-dialog v-model="showDialog" max-width="320">
    <v-card>
      <error-alert :value="error" />

      <v-card-title class="headline"> {{ $t('finishTrip') }}? </v-card-title>

      <v-card-text>
        {{ $t('finishTripText') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn flat @click.native="hideModal">
          {{ $t('No') }}
        </v-btn>

        <v-btn color="pink darken-2" flat @click.native="handleOk">
          {{ $t('Yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'FinishTripDialog',

  data() {
    return {
      showDialog: true,
    };
  },

  props: {
    error: {
      type: Error,
      default: null,
    },
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },
    handleOk() {
      this.$emit('ok');
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
</script>
