<template>
  <div>
    <h1>{{ $t('Rules for the provision of information services') }}</h1>

    <p>
      Настоящий документ «Правила оказания информационных услуг» представляет собой обязательные условия, на которых
      допускается заключение и исполнение договоров на оказание информационных услуг с использованием Сервиса ООО
      «ХоумСтретч Рус».
    </p>

    <h4>1. Термины и определения</h4>

    <p>
      1.1. В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и
      определения:
    </p>

    <p>
      1.1.1. <b>Правила</b> – текст настоящего документа со всеми приложениями, изменениями и дополнениями к нему,
      размещенный на Сайте и доступный в сети Интернет по адресу:
      <a href="https://co.homestretch.ch/rules">https://co.homestretch.ch/rules</a>.
    </p>
    <p>
      1.1.2. <b>Договор</b> – договор оказания возмездных Услуг, вместе со всеми относящимися к нему Обязательными
      документами, который заключается и исполняется Сторонами в порядке, предусмотренном настоящими Правилами.
    </p>
    <p>
      1.1.3. <b>Услуги</b> – информационные услуги по обеспечению доступа Заказчика к функциональным возможностям
      Сервиса, перечисленные в Тарифах Исполнителя.
    </p>
    <p>1.1.4. <b>Исполнитель</b> – ООО «ХоумСтретч Рус», ОГРН: 1187746678778.</p>
    <p>
      1.1.5. <b>Заказчик</b> – лицо, способное совершить Акцепт на условиях настоящих Правил (применительно к порядку
      заключения Договора) либо совершившее Акцепт Оферты на условиях настоящих Правил (применительно к исполнению
      заключенного Договора).
    </p>
    <p>
      1.1.6. <b>Пользователь</b> - лицо, фактически пользующееся с разрешения Заказчика функциональными возможностями
      Сервиса в назначенной роли.
    </p>
    <p>
      1.1.7. <b>Заказ</b> – совершение Заказчиком действий по выбору и/или самостоятельному определению условий оказания
      Услуг, перечисленных на соответствующей странице Сайта или Панели управления, необходимых для оформления
      отдельного Договора.
    </p>
    <p>
      1.1.8. <b>Оферта</b> – оформляемое на основании Заказа предложение Исполнителя о заключении Договора, выражаемое
      путем выставления счета на оплату Услуг либо направления Заказа на оплату через платежную систему с использованием
      Панели управления Заказчика.
    </p>
    <p>
      1.1.9. <b>Акцепт</b> – полное и безоговорочное принятие Оферты на условиях настоящих Правил путем совершения
      Заказчиком действий, указанных в разделе 9 Правил, создающее Договор между Заказчиком и Исполнителем.
    </p>
    <p>
      1.1.10. <b>Тестовый период</b> – период времени, на который Заказчику первоначально предоставляется доступ к
      Сервису в целях его тестирования и оценки функциональных возможностей. Оплата за Тестовый период не взимается.
      Если иное не оговорено при предоставлении доступа к Сервису или в Обязательных документах, продолжительность
      Тестового периода составляет 30 (Тридцать) календарных дней с момента предоставления доступа.
    </p>
    <p>
      1.1.11. <b>Сайт</b> – автоматизированная информационная система, доступная в сети Интернет по адресу (включая
      поддомены): <a href="https://co.homestretch.ch/">https://co.homestretch.ch/</a>.
    </p>
    <p>
      1.1.12. <b>Мобильное приложение</b> – предназначенная для установки и использования на мобильных устройствах
      программа для ЭВМ “HomeStretch BIZ”, позволяющая получить доступ к Сервису с использованием сети связи.
    </p>
    <p>1.1.13. <b>Приложение</b> — Сайт и Мобильное приложение, на основе которых реализуется Сервис.</p>
    <p>
      1.1.14. <b>Сервис</b> – совокупность информационных служб и связанных с ними функциональных возможностей,
      предоставляемых с использованием Приложения.
    </p>
    <p>
      1.1.15. <b>Учетная запись</b> - персональный раздел Приложения, предназначенный для использования его
      функциональных возможностей Пользователем в рамках назначенной роли, к которому Пользователь получает доступ после
      прохождения регистрации и/или авторизации в Приложении.
    </p>
    <p>
      1.1.16. <b>Панель управления</b> – персональный раздел Приложения, доступный по адресу созданного для Заказчика
      домена третьего уровня (https://customer.co.homestretch.ch/, где customer – идентификатор Заказчика), к которому
      Заказчик получает доступ после прохождения регистрации и/или авторизации в Приложении. Панель управления
      предназначена для хранения контактной и платежной информации Заказчика, создания Учетных записей Пользователей и
      назначения им ролей, контроля действий Пользователей, просмотра и выгрузки отчетов и совершения иных действий.
    </p>
    <p>
      1.1.17. <b>Лицевой счет</b> – информация о внесенных Заказчиком и списанных в рамках исполнения Договоров денежных
      средствах в счет оплаты оказываемых Услуг по указанным Договорам. Доступ к Лицевому счету предоставляется
      Заказчику с использованием его Панели управления.
    </p>
    <p>
      1.2. В настоящих Правилах могут быть использованы термины и определения, не определенные в п.1.1. Правил. В этом
      случае толкование такого термина производится в соответствии с текстом Правил. В случае отсутствия однозначного
      толкования термина или определения в тексте Правил следует руководствоваться его толкованием, определенным: в
      первую очередь – документами, образующими Договор между Сторонами, во вторую очередь - законодательством
      Российской Федерации, и в последующем - обычаями делового оборота и научной доктриной.
    </p>
    <p>
      1.3. Любая ссылка в настоящих Правилах на пункт (раздел Правил) и/или их условия, означает соответствующую ссылку
      на настоящие Правила (их раздел) и/или их условия.
    </p>

    <h4>2. Предмет Договора</h4>

    <p>
      2.1. Исполнитель обязуется при наличии технической возможности оказывать Услуги на основании размещенных Заказов,
      а Заказчик принимать и оплачивать Услуги на условиях настоящих Правил.
    </p>
    <p>
      2.2. Наименование, состав и стоимость Услуг, а также прочие необходимые условия Договора определяются на основании
      сведений и материалов, предоставленных Заказчиком при оформлении Заказа, в соответствии с условиями Обязательных
      документов.
    </p>

    <h4>3. Общие условия оказания Услуг</h4>

    <p>
      3.1. Обязательным условием оказания Исполнителем Услуг является безоговорочное принятие и соблюдение Заказчиком,
      применяемых к отношениям Сторон по Договору требований и положений, определенных следующими документами
      («Обязательные документы»):
    </p>
    <p>
      3.1.1. Пользовательское соглашение, размещенное и/или доступное в сети Интернет по адресу:
      <a href="https://co.homestretch.ch/terms">https://co.homestretch.ch/terms</a> и включающее общие условия
      регистрации в Приложении и использования Сервиса Исполнителя;
    </p>
    <p>
      3.1.2. Политика конфиденциальности, размещенная и/или доступная в сети Интернет по адресу:
      <a href="https://co.homestretch.ch/policy">https://co.homestretch.ch/policy</a>, и содержащая правила
      предоставления и использования персональной информации, включая персональные данные Заказчика.
    </p>
    <p>
      3.1.3. Тарифы, размещенные и/или доступные в сети Интернет по адресу:
      <a href="https://co.homestretch.ch/billing/prices">https://co.homestretch.ch/billing/prices</a>, применяемые
      Исполнителем для расчета стоимости Услуг на дату оформления Заказа и включающие применительно к соответствующему
      виду и составу Услуг сведения о предлагаемом Исполнителем наименовании и стоимости Услуг, а также иные необходимые
      условия их оказания.
    </p>
    <p>
      3.2. Указанные в п.3.1. Правил обязательные для Сторон документы составляют неотъемлемую часть заключаемого в
      соответствии с Правилами Договора.
    </p>
    <p>
      3.3. В целях обеспечения возможности доступа Пользователей к функциональным возможностям Сервиса Исполнитель
      обязуется в течение всего срока действия настоящего Договора в интересах Заказчика обеспечить Пользователям
      возможность использования текущей версии Мобильного приложения на условиях безвозмездной простой
      (неисключительной) лицензии. Экземпляр (дистрибутив) Мобильного приложения передается Пользователям посредством
      предоставления доступа к нему по сети Интернет для самостоятельной загрузки в порядке, установленном его
      правообладателем, который может изменяться по его единоличному усмотрению.
    </p>
    <p>
      3.4. Заказчик обязан предоставлять Исполнителю информацию, необходимую для оказания Услуг по Договору, не позднее
      3 (Трех) рабочих дней с момента согласования относящегося к ним Заказа, но в любом случае до начала оказания по
      нему Услуг. Предоставление информации Исполнителю осуществляется либо с помощью программных средств Сайта, либо
      путем направления информации Исполнителю по электронной почте или с использованием иных средств связи. Аналогичные
      правила применяются в случае внесения изменений в условия Заказа в период оказания по нему Услуг.
    </p>
    <p>
      3.5. Заказчик обязуется обеспечивать достоверность представляемых им сведений и соответствие таких сведений иным
      требованиям законодательства, Договора и поименованных в нем обязательных для Сторон документов.
    </p>
    <p>
      3.6. В случае несвоевременного предоставления необходимой информации, задержки оплаты, или иных случаях
      неисполнения Заказчиком своих обязательств и требований, предусмотренных настоящим Договором, Исполнитель вправе
      не приступать к оказанию Услуг или сократить срок их оказания соразмерно периоду допущенной просрочки.
    </p>
    <p>
      3.7. В случае, если на момент окончания Тестового периода Заказчик не произвел оплату Услуг, доступ Заказчика к
      Сервису приостанавливается до оплаты Услуг Заказчиком.
    </p>

    <h4>4. Права и обязанности Сторон</h4>

    <h4>4.1. Исполнитель обязуется:</h4>
    <p>
      4.1.1. Оказывать Услуги в полном соответствии с Договором, включая Обязательные документы, и требованиями
      законодательства Российской Федерации;
    </p>
    <p>4.1.2. Своевременно извещать Заказчика о ходе оказания Услуг;</p>
    <p>4.1.3. По требованию Заказчика продлить срок оказания Услуг в предусмотренных настоящими Правилами случаях.</p>

    <h4>4.2. Исполнитель вправе:</h4>
    <p>4.2.1. Не приступать к оказанию Услуг до их оплаты;</p>
    <p>4.2.2. Приостанавливать работу Сервиса для проведения профилактических работ;</p>
    <p>
      4.2.3. Приостанавливать оказание Услуг в случае нарушения Заказчиком требований, предусмотренных Договором,
      Обязательными документами, а также в иных случаях, установленных законодательством Российской Федерации;
    </p>
    <p>
      4.2.4. Осуществлять ограничение отдельных действий Заказчика, если такие действия создают угрозу для нормального
      функционирования Сервиса;
    </p>
    <p>
      4.2.5. Изменять в одностороннем порядке Правила и указанные в них Обязательные документы без уведомления Заказчика
      в отношении Услуг, по которым с Заказчиком отсутствует действующий Договор;
    </p>
    <p>
      4.2.6. Отказаться в одностороннем порядке от исполнения Договора в случаях, предусмотренных действующим
      законодательством Российской Федерации и/или настоящими Правилами;
    </p>
    <p>
      4.2.7. Осуществлять иные права, предусмотренные действующим законодательством Российской Федерации, а также
      настоящими Правилами, включая Обязательные документы.
    </p>

    <h4>4.3. Заказчик обязуется:</h4>
    <p>
      4.3.1. Соблюдать требования Договора, включая Обязательные документы, а также законодательства Российской
      Федерации;
    </p>
    <p>4.3.2. Осуществлять проверку Заказа до совершения Акцепта;</p>
    <p>4.3.3. Оплачивать Услуги по Договору в полном объеме в установленные сроки;</p>
    <p>
      4.3.4. Проверять наличие уведомлений Исполнителя в Приложении (в том числе - в Панели управления Заказчика) и по
      электронному адресу, указанному Заказчиком при оформлении Заказа, а также просматривать информацию о ходе оказания
      Услуг.
    </p>
    <p>
      4.3.5. Выполнять иные обязанности, предусмотренные Договором, включая Обязательные документы, а также действующим
      законодательством Российской Федерации.
    </p>

    <h4>4.4. Заказчик вправе:</h4>
    <p>4.4.1. Проверять ход и качество оказания Услуг по размещенным Заказам;</p>
    <p>
      4.4.2. Отказаться в одностороннем порядке от исполнения Договора в случаях, предусмотренных действующим
      законодательством Российской Федерации и/или настоящими Правилами;
    </p>
    <p>
      4.4.3. Осуществлять иные права, предусмотренные Договором, включая Обязательные документы, а также действующим
      законодательством Российской Федерации.
    </p>

    <h4>5. Стоимость Услуг и порядок расчетов</h4>

    <p>
      5.1. Стоимость Услуг по Договору определяется в соответствии с Тарифами Исполнителя, действующими на дату
      оформления касающегося их Заказа, в зависимости от наименования, состава и срока предоставления Услуг.
    </p>
    <p>
      5.2. Оплата Услуг производится Заказчиком путем внесения аванса в размере 100% стоимости соответствующих Услуг.
    </p>
    <p>
      5.3. Расчеты по Договору осуществляются путем безналичных перечислений на расчетный счет Исполнителя либо с
      использованием указанных при размещении Заказа в Приложении платежных сервисов.
    </p>
    <p>
      5.4. Заказчик считается исполнившим свои обязательства по оплате Услуг с момента поступления денежных средств
      в установленном размере на расчетный счет Исполнителя при безналичной форме оплаты, либо с момента предоставления
      информации о совершенном Заказчиком платеже оператором платежного сервиса, действующим на основании договора с
      Исполнителем, а при наличии на Лицевом счете остатка, достаточного для оплаты Услуг по Договору, - с момента
      списания с Лицевого счета соответствующей суммы в счет оплаты Услуг.
    </p>
    <p>
      5.5. Поступившие Исполнителю денежные средства отражаются на Лицевом счете Заказчика в сумме текущего остатка
      согласно показаниям оборудования Исполнителя, учитывающего объем предоставленных Заказчику Услуг.
    </p>

    <h4>6. Сдача Услуг</h4>

    <p>
      6.1. Услуги считаются оказанными Исполнителем с момента обеспечения возможности использования Заказчиком
      соответствующих функциональных возможностей Сервиса посредством открытия и резервирования доступа к ним Заказчика
      на соответствующий срок во внутренней учетной системе Исполнителя.
    </p>
    <p>
      6.2. Заказчик обязан знакомиться в Панели управления с информацией о заказанных и оказанных Услугах, составе и
      сроках предоставления доступа к функциональным возможностям Сервиса, а также внесенных и списанных в рамках
      исполнения Договоров денежных средствах в счет оплаты Услуг по указанным Договорам.
    </p>
    <p>
      6.3. По оказании Услуг согласно п.6.1. Правил Исполнитель составляет акт об оказании услуг. (далее — «Акт»). Акт
      составляется по форме, утвержденной учетной политикой Исполнителя на дату его составления.
    </p>
    <p>
      6.4. Стороны признают и безоговорочно соглашаются, что сведения об оказанных Услугах указываются в Акте на основе
      данных внутренней учетной системы Исполнителя.
    </p>
    <p>
      6.5. Акт направляется Заказчику в электронном виде на адрес электронной почты Заказчика, а также доступен в Панели
      управления Заказчика не позднее 10 (Десять) рабочих дней с даты его составления. Заказчик вправе получить копию
      Акта на бумажном носителе по месту нахождения Исполнителя, указанному настоящих Правилах. По требованию Заказчика
      копия Акта на бумажном носителе может быть направлена Почтой России или курьерской службой по адресу, указанному
      Заказчиком.
    </p>
    <p>
      6.6. Заказчик обязан ознакомиться с Актом в электронной форме в течение 5 (Пяти) рабочих дней с даты его доставки.
      В случае непредставления Заказчиком в течение срока для ознакомления с Актом Исполнителю письменных возражений по
      Акту, Услуги считаются принятыми Заказчиком и подлежат оплате в полном объеме. При этом Акт, подписанный со
      стороны Исполнителя, имеет полную юридическую силу.
    </p>

    <h4>7. Гарантии сторон</h4>

    <p>7.1. Исполнитель гарантирует:</p>
    <p>
      1) принадлежность ему прав на использование Сервиса в объеме, необходимом для надлежащего выполнения обязательств
      по Договору;
    </p>
    <p>2) оказание Услуг по Договору в полном соответствии с настоящими Правилами и Обязательными документами.</p>
    <p>
      7.2. Исполнитель не предоставляет никаких подразумеваемых или выраженных явным образом в Правилах или в Приложении
      гарантий в отношении:
    </p>
    <p>
      7.2.1. соответствия дополнительных функциональных возможностей Сервиса требованиям и ожиданиям Заказчика, их
      пригодности для определенной цели, и отсутствия конструктивных недостатков;
    </p>
    <p>
      7.2.2. предоставления непрерывного доступа к Сервису, отсутствия ошибок в его программном обеспечении, возможности
      и сроков их устранения.
    </p>
    <p>
      7.3. Заказчик гарантирует использование Сервиса в соответствии с условиями настоящих Правил и Обязательных
      документов.
    </p>

    <h4>8. Ответственность сторон</h4>

    <p>
      8.1. За неисполнение или ненадлежащее исполнение принятых по  Договору обязательств Стороны несут ответственность
      в соответствии с действующим законодательством Российской Федерации.
    </p>
    <p>
      8.2. Исполнитель не отвечает за встречное исполнение обязательств по  оказанию Услуг в случае просрочки оплаты
      Услуг, и иных случаях полного или частичного неисполнения Заказчиком обязательств по Договору, а также наличия
      обстоятельств, очевидно свидетельствующих о том, что такое исполнение не будет произведено в установленный срок.
    </p>
    <p>
      8.3. В случае невозможности использования Услуг, Заказчик должен направить Исполнителю письменную претензию с
      подробным указанием обстоятельств и периода отсутствия доступа к дополнительным функциональным возможностям
      Сервиса.
    </p>
    <p>
      8.4. В случае признания претензии обоснованной, доступ Заказчика к соответствующим функциональным возможностям
      Сервиса продлевается Исполнителем на количество обращений или срок использования Сервиса, которые не могли быть
      использованы Заказчиком надлежащим образом по вине Исполнителя.
    </p>
    <p>
      8.5. Ответственность Исполнителя по Договору в любом случае ограничивается возмещением причиненного Заказчику
      реального доказанного ущерба в сумме, не превышающей оплаченную стоимость Услуг по соответствующему Договору.
    </p>
    <p>
      8.6. Заказчик самостоятельно в полном объеме несет ответственность за соответствие размещаемой им информации и
      материалов требованиям законодательства, в том числе за их содержание и правомерность использования в них объектов
      интеллектуальной собственности, наличие необходимых разрешений и лицензий, а также за совершение иных действий
      в рамках предоставленных гарантий.
    </p>
    <p>
      8.7. В случае если оказание Услуг по Договору вследствие нарушения гарантий Заказчика повлекло предъявление
      к Исполнителю претензий, исков и/или предписаний по возмещению убытков (выплате компенсаций) со стороны третьих
      лиц и/или государственных органов либо возбуждение дела об административном правонарушении, Заказчик обязуется
      незамедлительно по требованию Исполнителя предоставить ему всю запрашиваемую информацию, касающуюся предмета
      спора, и содействовать Исполнителю в урегулировании таких претензий, а также возместить все убытки (включая
      судебные расходы, расходы по уплате штрафов), причиненные Исполнителю вследствие предъявления, рассмотрения
      и исполнения таких претензий, исков, предписаний, а равно привлечением к административной ответственности в связи
      с нарушением прав третьих лиц и/или действующего законодательства в результате оказания Услуг.
    </p>
    <p>
      8.8. Сумма убытков может быть удержана Исполнителем из денежных средств Заказчика, поступивших в счет оплаты Услуг
      по Договору, в том числе путем списания с Лицевого счета Заказчика.
    </p>
    <p>
      8.9. В случае нарушения Заказчиком условий Договора, повлекших его досрочное прекращение, оставшаяся по Договору
      сумма удерживается в качестве штрафной неустойки в связи с допущенными нарушениями.
    </p>

    <h4>9. Обстоятельства непреодолимой силы (форс-мажор)</h4>

    <p>
      9.1. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Договору,
      вызванное обстоятельствами непреодолимой силы, возникшими после его заключения. К таким обстоятельствам, в
      частности, Стороны относят: стихийные бедствия; природные и промышленные катастрофы; террористические акты;
      военные действия; гражданские беспорядки; принятие органами государственной власти или органами местного
      самоуправления актов, содержащих запреты или ограничения в отношении деятельности Сторон по Договору; иные
      обстоятельства, которые не могут быть заранее предвидены или предотвращены и делают невозможным исполнение
      обязательств Сторон по Договору.
    </p>
    <p>
      9.2. При наступлении обстоятельств непреодолимой силы, препятствующих исполнению обязательств по Договору, срок
      оказания Сторонами своих обязательств переносится соразмерно времени действия таких обстоятельств, а также
      времени, требуемого для устранения их последствий, но не более 60 (Шестидесяти) календарных дней. В случае если
      обстоятельства непреодолимой силы продолжают действовать свыше указанного срока, либо когда при их наступлении
      обеим Сторонам становится очевидным, что они будут действовать более этого срока, Договор прекращает свое
      действие.
    </p>

    <h4>10. Акцепт Оферты и заключение Договора</h4>

    <p>
      10.1. Акцепт Оферты Заказчиком создает Договор между Заказчиком и Исполнителем (статьи 433, 438 Гражданского
      Кодекса Российской Федерации) на условиях настоящих Правил.
    </p>
    <p>10.2. Акцепт Оферты совершается Заказчиком путем выполнения совокупности указанных ниже действий:</p>
    <p>10.2.1. оформление и направление Заказа с помощью программных средств в Приложении Исполнителя;</p>
    <p>
      10.2.2. внесение авансового платежа за оказание Услуг в сумме, определяемой соответствующим Тарифом на дату
      оплаты.
    </p>
    <p>10.3. Договор считается заключенным с момента получения Исполнителем Акцепта Оферты.</p>
    <p>
      10.4. Во избежание сомнений начало использования Заказчиком Услуг дополнительно свидетельствует о заключении
      Договора на их оказание в соответствии с условиями настоящих Правил.
    </p>
    <p>
      10.5. На основании Правил с Заказчиком может быть заключено неограниченное количество Договоров, в том числе, с
      совпадающими сроками действия.
    </p>

    <h4>11. Срок действия и изменение Правил</h4>

    <p>11.1. Правила вступают в силу с момента размещения на Сайте и действуют до момента их отмены Исполнителем.</p>
    <p>
      11.2. Исполнитель оставляет за собой право внести изменения в условия Правил и/или отменить Правила в любой момент
      по своему усмотрению. Сведения об изменении или отзыве Правил доводятся до Заказчика по выбору Исполнителя
      посредством размещения на Сайте, в Панели управления Заказчика, либо путем направления соответствующего
      уведомления на электронный или почтовый адрес, указанный Заказчиком при заключении Договора или в ходе его
      исполнения.
    </p>
    <p>
      11.3. В случае отзыва Правил или внесения изменений в Правила, указанные решения вступают в силу с момента
      доведения об этом сведений до Заказчика, если иной срок вступления их в силу не определен Правилами или
      дополнительно при таком сообщении.
    </p>
    <p>
      11.4. Указанные в Правилах обязательные для Сторон документы утверждаются, дополняются и изменяются Исполнителем
      по собственному усмотрению и доводятся до сведения Заказчика в порядке, предусмотренном для уведомления Заказчика
      об изменении Правил.
    </p>

    <h4>12. Срок действия, изменение и расторжение Договора</h4>

    <p>
      12.1. Договор вступает в силу с момента Акцепта Правил Заказчиком и действует: а) до момента полного исполнения
      Сторонами обязательств по Договору, либо б) до момента досрочного расторжения Договора.
    </p>
    <p>
      12.2. В случае отмены Правил Исполнителем в течение срока действия Договора, Договор считается действующим на
      условиях Правил в последней редакции со всеми Обязательными документами.
    </p>
    <p>12.3. Договор может быть расторгнут:</p>
    <p>12.3.1. По соглашению Сторон в любое время.</p>
    <p>
      12.3.2. По инициативе любой из Сторон с письменным уведомлением другой Стороны об отказе от исполнения Договора не
      менее чем за 30 (Тридцать) календарных дней.
    </p>
    <p>12.3.3. По иным основаниям, предусмотренным Правилами или действующим законодательством.</p>
    <p>
      12.4. В случае отказа Заказчика от исполнения Договора в части информационных услуг после предоставления Заказчику
      приобретенного доступа к функциональным возможностям Сервиса, стоимость соответствующих Услуг не возвращается.
    </p>

    <h4>13. Условия конфиденциальности и персональные данные</h4>

    <p>
      13.1. Стороны соглашаются сохранять в тайне и считать конфиденциальными условия каждого заключенного Договора,
      а также всю информацию, полученную одной Стороной от другой Стороны при заключении и исполнении такого Договора
      (далее — «Конфиденциальная информация»), и не должны раскрывать, разглашать, обнародовать или иным способом
      не предоставлять такую информацию какой-либо третьей стороне без предварительного письменного разрешения
      передающей эту информацию Стороны.
    </p>
    <p>
      13.2. Каждая из Сторон предпримет все необходимые меры для защиты Конфиденциальной информации как минимум
      с такой же степенью заботливости, с какой она защищает собственную Конфиденциальную информацию. Доступ
      к Конфиденциальной информации будет предоставлен только тем сотрудникам каждой из Сторон, которым он обоснованно
      необходим для выполнения служебных обязанностей по исполнению Договора. Каждая из Сторон обяжет таких своих
      сотрудников принять те же обязательства по обеспечению сохранности Конфиденциальной информации, которые
      предусмотрены настоящими Правилами в отношении Сторон.
    </p>
    <p>
      13.3. Обработка персональных данных Заказчика (при их наличии) осуществляется в соответствии с Политикой
      конфиденциальности Исполнителя.
    </p>
    <p>
      13.4. Исполнитель вправе запрашивать дополнительную информацию, такую как ксерокопии документов, удостоверяющих
      личность или кредитных карт, в случае необходимости проверки личности или в целях предотвращения мошенничества.
      Если такая дополнительная информация представлена Исполнителю, то ее использование и охрана осуществляется в
      соответствии с условиями п.13.3. Правил.
    </p>
    <p>
      13.5. Обязательство по сохранению в тайне Конфиденциальной информации действительно в пределах срока действия
      заключенного Договора и в течение 5 (Пяти) лет после прекращения его действия, если Сторонами отдельно не будет
      оговорено иное.
    </p>
    <p>
      13.6. В рамках оказания Услуг по Договору Заказчик поручает Исполнителю осуществлять обработку персональных данных
      лиц, с которыми он взаимодействует в рамках Сервиса. Разрешенные действия (операции) с персональными данными
      включают: сбор, запись, хранение, накопление, систематизацию, уточнение (обновление, изменение), извлечение,
      использование, передачу (предоставление, доступ) третьим лицам, обезличивание, блокирование, удаление, уничтожение
      в соответствии с правилами использования доступных функциональных возможностей Сервиса.
    </p>
    <p>
      13.7. Обработка персональных данных Исполнителем осуществляется с целью обеспечения Заказчику технической
      возможности заключения и/или исполнения договоров и/или административных функций, стороной которых является
      Заказчик и субъект персональных данных.
    </p>
    <p>
      13.8. Заказчик гарантирует Исполнителю, что им получено соответствующее согласие лица на обработку его
      персональных данных и передачу их третьим лицам, либо что Заказчик не обязан получать такое согласие в
      соответствии с действующим законодательством.
    </p>
    <p>
      13.9. Исполнитель обязуется соблюдать конфиденциальность обрабатываемых им персональных данных и обеспечивать их
      безопасность при обработке в соответствии с положениями настоящего Договора.
    </p>

    <h4>14. Соглашение об аналоге собственноручной подписи</h4>

    <p>
      14.1. Стороны вправе использовать при заключении Договора, оформлении Заказов, а также направлении уведомлений по
      Договору факсимильное воспроизведение подписи или простую электронную подпись.
    </p>
    <p>
      14.2. Допускается обмен документами между Сторонами посредством факсимильной связи или электронной почты. При этом
      документы, переданные в установленном Правилами порядке, имеют полную юридическую силу при условии наличия
      подтверждения о доставке включающего их сообщения получателю.
    </p>
    <p>
      14.3. Простой электронной подписью признается электронная подпись, которая посредством использования логина и
      пароля Пользователя или указанного при использовании Сервиса адреса электронной почты Пользователя (ключ
      электронной подписи) подтверждает факт формирования электронной подписи непосредственно Пользователем.
    </p>
    <p>
      14.4. При использовании Сторонами электронной почты направляемый с ее помощью электронный документ считается
      подписанным простой электронной подписью отправителя, созданной с использованием адреса его электронной почты.
      Получатель электронного документа определяет лицо, подписавшее такой документ, по используемому им адресу
      электронной почты.
    </p>
    <p>
      14.5. При совершении Заказчиком любых действий по использованию Сервиса через Панель управления, Исполнитель
      определяет Заказчика, которому соответствует простая электронная подпись, по используемому Заказчиком логину и
      паролю, указанным при регистрации/или авторизации в Сервисе. Любые действия, совершенные с использованием простой
      электронной подписи определенного Заказчика, считаются совершенными таким Заказчиком.
    </p>
    <p>
      14.6. По соглашению Сторон электронные документы, подписанные простой электронной подписью, признаются
      равнозначными документам на бумажных носителях, подписанным собственноручной подписью.
    </p>
    <p>
      14.7. Заказчик обязуется соблюдать конфиденциальность ключа электронной подписи. В частности, Заказчик не имеет
      права передавать свои логин и пароль или предоставлять доступ к своей электронной почте третьим лицам, и несет
      полную ответственность за их сохранность и индивидуальное использование, самостоятельно выбирая способ их хранения
      и ограничения к ним доступа.
    </p>
    <p>
      14.8. В случае несанкционированного доступа к логину и паролю, их утраты или раскрытия третьим лицам Заказчик
      обязан незамедлительно сообщить об этом Исполнителю путем направления электронного письма с указанного при
      использовании Сервиса адреса электронной почты.
    </p>
    <p>
      14.9. В случае утраты или несанкционированного доступа к электронной почте, адрес которой указан при использовании
      Сервиса, Заказчик обязан незамедлительно заменить такой адрес на новый и сообщить о данном факте Исполнителю.
    </p>

    <h4>15. Заключительные положения</h4>

    <p>
      15.1. Договор, его заключение и исполнение регулируется действующим законодательством Российской Федерации. Все
      вопросы, не урегулированные Правилами или урегулированные не полностью, регулируются в соответствии с материальным
      правом Российской Федерации.
    </p>
    <p>
      15.2. Споры по Правилам и/или Договору разрешаются в предварительном претензионном порядке. В случае недостижения
      Сторонами согласия споры подлежат рассмотрению в суде в соответствии с процессуальным правом Российской Федерации.
    </p>
    <p>
      15.3. Любые уведомления и документы по Договору, если иное не предусмотрено Правилами, могут направляться одной
      Стороной другой Стороне: 1) по электронной почте; 2) электронным уведомлением в Панели управления; 3) по факсу; 4)
      почтой с уведомлением о вручении или курьерской службой с подтверждением доставки.
    </p>
    <p>
      15.4. В случае если одно или более положений Правил или Договора являются по какой-либо причине недействительными,
      не имеющими юридической силы, такая недействительность не оказывает влияния на действительность любого другого
      положения Правил или Договора, которые остаются в силе.
    </p>
    <p>
      15.5. Не вступая в противоречие с условиями Правил, Стороны вправе в любое время оформить заключенный Договор на
      оказание Услуг в форме письменного документа, выражающего содержание действующих на момент его оформления Правил,
      указанных в них Обязательных документов и размещенного Заказа.
    </p>

    <h4>16. Реквизиты Исполнителя</h4>
    <p>
      <i>
        ООО «ХоумСтретч Рус» <br />
        <br />
        ОГРН: 1187746678778 ИНН: 7734414304 <br />
        Адрес: Россия, Москва г., ул. Кулакова, д. 20, стр. 1А, пом. XI, этаж 2, ком. 47 <br />
        Банковские реквизиты: р/сч 40702810202980002329 в АО «АЛЬФА-БАНК», <br />
        к/сч 30101810200000000593 БИК 044525593 <br />
        e-mail: <a href="email:info@homestretch.ch">info@homestretch.ch</a>
      </i>
    </p>
    <p>
      Редакция от 01 октября 2018 г.
    </p>
  </div>
</template>

<script>
/* eslint no-irregular-whitespace: 0 */
export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Rules for the provision of information services'),
    };
  },
};
</script>
