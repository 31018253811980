/* global google */

import Vue from 'vue';
import { mapState } from 'vuex';
import uuid from 'uuid/v4';
import { addDays } from 'date-fns';
import VAutocomplete from 'vuetify/es5/components/VAutocomplete';

import gmap from '@/lib/gmap';
import { debugTimeout } from '@/lib/util';
import { highlight } from '@/lib/text';
import place from '@/api/place';
import { PlaceIcon } from '@/components/icons';
import TripPoint, { TripPointType, TripPointStatus } from '@/models/TripPoint';

import { ONBOARDING_ELEMENT_ZINDEX } from '@/const';
import EventBus from '@/event-bus';
import { getTZInfoByCoordinate } from '@/lib/timezone';

const SearchInput = Vue.extend({
  extends: VAutocomplete,

  methods: {
    blur: function() {
      this.isFocused = false;
    },

    onEnterDown: function() {
      this.$emit('enter');
    },

    setSelectedItems: function() {
      if (!this.internalValue) {
        this.selectedItems = [];
        return;
      }

      let selectedItems = [this.internalValue];
      const values = !this.multiple || !Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue;

      for (const value of values) {
        const index = this.allItems.findIndex(v => this.valueComparator(this.getValue(v), this.getValue(value)));
        if (index > -1) {
          selectedItems = [this.allItems[index]];
          break;
        }
      }

      this.selectedItems = selectedItems;
    },
  },

  computed: {
    selectedItem() {
      return this.internalValue;
    },

    filteredItems() {
      return this.allItems;
    },
  },
});

/**
 * Google maps search with autocomplete
 */
export default Vue.extend({
  name: 'SearchControl',

  inject: ['getMap'],

  data() {
    return {
      map: null,

      error: null,
      search: null,
      searchItems: [],
      autocompleteItems: [],

      marker: null,
      sessionToken: null,
      autocompleteService: null,

      pointSettingsValid: true,

      debug: false,
    };
  },

  props: {
    attach: {
      type: HTMLDivElement,
      default: null,
    },
    startDate: {
      type: Date,
    },
  },

  render(h) {
    return (
      <div class="map-control" style="z-index: 1">
        {this.genInput(h)}
      </div>
    );
  },

  async created() {
    this.reset();

    this.map = await this.getMap();
    this.map.controls[gmap.ControlPosition.TOP_LEFT].push(this.$el);

    this.waitForPositioning();

    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    this.placesService = new google.maps.places.PlacesService(this.map);
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.marker = new google.maps.Marker();
  },

  methods: {
    waitForPositioning() {
      if (this.$el.style.zIndex && parseInt(this.$el.style.zIndex) !== ONBOARDING_ELEMENT_ZINDEX) {
        EventBus.$emit('onboarding-update');
        return;
      }
      setTimeout(() => {
        this.waitForPositioning();
      }, 50);
    },

    // === Google API functions ================================================

    getAutocompleteItems(query) {
      return new Promise((resolve, reject) => {
        this.autocompleteService.getPlacePredictions(
          {
            bounds: this.map.getBounds(),
            input: query,
            sessionToken: this.sessionToken,
          },
          (result, status) => {
            if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              resolve([]);
              return;
            }

            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
              return;
            }

            resolve(
              result.map(place => ({
                name: place.description,
                place_id: place.place_id,
              })),
            );
          },
        );
      });
    },

    getSearchItems(query) {
      /*
      return [
        {
          'address': 'Leninsky Ave, 129 корпус 3, Moskva, Russia, 117513',
          'latitude': 55.64201317010728,
          'longitude': 37.4824228298927,
          'geometry': {
            'location': {
              'lat': 55.64201317010728,
              'lng': 37.4824228298927
            },
            'viewport': {
              'f': {
                'b': 55.64201317010728,
                'f': 55.64471282989273
              },
              'b': {
                'b': 37.47972317010726,
                'f': 37.4824228298927
              }
            }
          },
          'icon': 'https://maps.gstatic.com/mapfiles/place_api/icons/geocode-71.png',
          'name': 'Leninsky Ave, 129 корпус 3',
          'place_id': 'ChIJfzX362RNtUYRkx6LJ2iFwyE',
          'html_attributions': []
        }
      ];
      */

      return new Promise((resolve, reject) => {
        this.placesService.findPlaceFromQuery(
          {
            locationBias: this.map.getBounds(),
            fields: ['place_id', 'formatted_address', 'name', 'geometry', 'icon'],
            query,
          },
          (result, status) => {
            if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
              resolve([]);
              return;
            }

            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
              return;
            }

            resolve(result);
          },
        );
      });
    },

    getDetails(placeId) {
      return new Promise((resolve, reject) => {
        this.placesService.getDetails(
          {
            placeId: placeId,
            fields: ['place_id', 'formatted_address', 'address_components', 'name', 'geometry', 'icon'],
            sessionToken: this.sessionToken,
          },
          (result, status) => {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              reject(status);
              return;
            }

            resolve(result);
          },
        );
      });
    },

    async getPlaceItems(query) {
      const result = await place.list({
        filter: query,
        rowsPerPage: 5,
        sortBy: 'name',
      });
      return result.result;
    },

    // === Render helpers ======================================================

    genInput(h) {
      return h(
        'v-layout',
        {
          attrs: {
            column: true,
            'fill-height': true,
          },
          style: {
            'max-height': this.height + 'px',
          },
        },
        [
          h('v-flex', [
            h(SearchInput, {
              ref: 'autocomplete',
              props: {
                searchInput: this.search,
                items: this.autocompleteItems,
                value: this.select,
                itemText: 'name',
                itemValue: 'place_id',
                returnObject: true,
                loading: this.loading,
                solo: true,
                clearable: true,
                appendIcon: 'search',
                hideNoData: true,
                hideDetails: true,
                attach: this.attach,
                error: this.errorFlag,
                noDataText: this.noDataText,
              },
              on: {
                change: value => {},
                input: value => {
                  this.$store.commit('mapSearch/setSelected', value);
                  // this.onAutocompleteItemClick(value);
                },
                'click:append': ev => {
                  this.onSearch();
                },
                enter: ev => {
                  this.onSearch();
                },
                'update:searchInput': value => {
                  this.search = value;
                },
              },
              scopedSlots: {
                item: props => this.genAutocompleteContent(h, props.item),
              },
            }),
          ]),
          h(
            'v-flex',
            {
              style: {
                'overflow-y': 'auto',
                'margin-top': '1px',
              },
            },
            [this.genContent(h)],
          ),
        ],
      );
    },

    genContent(h) {
      if (this.select && this.select.state !== 'search') {
        return this.genSelectedContent(h);
      } else {
        return this.genSearchContent(h);
      }
    },

    genSelectedContent(h) {
      const item = this.select;
      let address = item.formatted_address || item.address;
      if (item.location) {
        address = item.location.addresses[0].address;
      }
      if (address) {
        return (
          <v-card>
            <v-card-title class="pb-0">
              <v-layout column>
                <v-flex class="headline mb-0">
                  {item.is_favorite && <PlaceIcon />}
                  {item.name ? item.name : item.title}
                </v-flex>
                <v-flex class="subheading">{address}</v-flex>
              </v-layout>
            </v-card-title>
            <v-card-text class="pt-2 pb-0"></v-card-text>
            <v-card-actions>
              <v-btn flat color="primary" disabled={!this.pointSettingsValid} onClick={() => this.onItemAdd(item)}>
                {this.$t('Add')}
              </v-btn>
            </v-card-actions>
          </v-card>
        );
      }
    },

    genSearchContent(h) {
      if (this.searchItems.length > 0) {
        return (
          <v-card>
            <v-list two-line>
              {this.searchItems.map(item => (
                <v-list-tile onClick={() => this.onSearchItemClick(item)}>
                  <v-list-tile-content>
                    <v-list-tile-title>{item.name ? item.name : item.title}</v-list-tile-title>
                    <v-list-tile-sub-title>{item.address}</v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              ))}
            </v-list>
          </v-card>
        );
      }
    },

    genAutocompleteContent(h, item) {
      return [
        <v-list-tile-content>
          <v-list-tile-title domPropsInnerHTML={this.highlight(item.name ? item.name : item.title)} />
          <v-list-tile-sub-title
            domPropsInnerHTML={this.highlight(item.location ? item.location.addresses[0].address : item.address)}
          />
        </v-list-tile-content>,
        item.is_favorite ? (
          <v-list-tile-action>
            <PlaceIcon />
          </v-list-tile-action>
        ) : null,
      ];
    },

    highlight(text) {
      return highlight(this.search, text) || '';
    },

    // === Action helpers ======================================================

    reset() {
      this.autocompleteItems = [];
      this.searchItems = [];
      this.search = null;
      this.$store.commit('mapSearch/setSelected', null);
    },

    onSearchItemClick(item) {
      this.$store.commit('mapSearch/setSelected', item);
    },

    onItemAdd(item) {
      if (!item.is_favorite) {
        const coordinates = {
          lat: item.geometry.location.lat(),
          lng: item.geometry.location.lng(),
        };

        const geoTimezone = getTZInfoByCoordinate(coordinates);
        const timezone = {
          name: geoTimezone.timezoneName,
          offset: geoTimezone.timezoneOffsetInSec,
        };

        this.$emit(
          'create',
          new TripPoint({
            id: uuid(),
            type: TripPointType.scheduled,
            title: null,
            location: {
              id: uuid(),
              googlePlaceId: item.place_id,
              coordinates,
              addresses: [
                {
                  address: item.formatted_address || item.address,
                  addressComponents: item.address_components,
                  locale: gmap.locale,
                },
              ],
              timezone,
            },
            contacts: [],
            placeLink: {
              title: null,
              placeId: null,
              contacts: [],
            },
            stayTime: 0,
            checkins: [],
            comment: null,
            deliveryWindows: item.delivery_windows || [],
            functions: {},
            radius: {
              checkin: 0,
              checkout: 0,
            },
            status: TripPointStatus.planned,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          }),
        );
      } else {
        const coordinates = {
          lat: item.location.coordinates.lat,
          lng: item.location.coordinates.lng,
        };

        const geoTimezone = getTZInfoByCoordinate(coordinates);
        const timezone = {
          name: geoTimezone.timezoneName,
          offset: geoTimezone.timezoneOffsetInSec,
        };

        this.$emit(
          'create',
          new TripPoint({
            id: uuid(),
            type: TripPointType.scheduled,
            title: null,
            location: item.location,
            contacts: [],
            placeLink: {
              title: item.title,
              type: item.type,
              contacts: item.contacts,
              placeId: item.id,
            },
            stayTime: 0,
            checkins: [],
            comment: null,
            deliveryWindows: this.getFavoriteDeliveryWindows(item.workingHours),
            functions: {},
            radius: {
              checkin: item.radius.checkin ? item.radius.checkin : 0,
              checkout: item.radius.checkout ? item.radius.checkout : 0,
            },
            status: TripPointStatus.planned,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          }),
        );
      }

      this.reset();
    },

    updateMarker() {
      if (this.select && this.select.is_favorite) {
        this.marker.setMap(this.map);
        const location = {
          lat: this.select.location.coordinates.lat,
          lng: this.select.location.coordinates.lng,
        };
        this.marker.setPosition(location);
        this.map.setCenter(location);
      } else if (this.select && this.select.geometry) {
        this.marker.setMap(this.map);
        this.marker.setPosition(this.select.geometry.location);
        if (this.select.geometry.viewport) {
          this.map.fitBounds(this.select.geometry.viewport);
        }
        this.map.setCenter(this.select.geometry.location);
      } else {
        this.marker.setMap(null);
      }
    },

    getFavoriteDeliveryWindows(workingHours) {
      if (!workingHours) {
        return [];
      }
      const fromDate = new Date(this.startDate);
      let toDate = new Date(this.startDate);
      const splittedFrom = workingHours[0].from.split(':');
      const splittedTo = workingHours[0].to.split(':');

      fromDate.setHours(parseInt(splittedFrom[0], 10), parseInt(splittedFrom[1], 10), 0, 0);
      toDate.setHours(parseInt(splittedTo[0], 10), parseInt(splittedTo[1], 10), 0, 0);

      if (fromDate > toDate) {
        toDate = addDays(toDate, 1);
      }

      return [{ from: fromDate, to: toDate }];
    },

    // === Actions =============================================================

    onSearch() {
      this.autocompleteItems = [];
      this.$store.commit('mapSearch/setSelected', {
        name: this.search,
        state: 'search',
      });
    },

    async queryAutocomplete() {
      try {
        this.$store.commit('mapSearch/startLoading');
        await debugTimeout(this.debug);
        const [autocompleteData, placesData] = await Promise.all([
          this.getAutocompleteItems(this.search),
          this.getPlaceItems(this.search),
        ]);
        for (const place of placesData) {
          place.is_favorite = true;
        }
        const data = [...placesData, ...autocompleteData];
        this.autocompleteItems = data;
        this.$store.commit('mapSearch/stopLoading');
        // await api.search(this.search);
      } catch (err) {
        this.$store.commit('mapSearch/stopLoading');
        console.error('err', err);
        // this.error = err;
      }
    },

    async querySearch() {
      try {
        this.$store.commit('mapSearch/startLoading');
        await debugTimeout(this.debug);
        const data = await this.getSearchItems(this.select.name);
        this.searchItems = data;
        if (this.searchItems.length === 1) {
          this.$store.commit('mapSearch/setSelected', this.searchItems[0]);
        }
        this.$store.commit('mapSearch/stopLoading');
        // await api.search(this.search);
      } catch (err) {
        this.$store.commit('mapSearch/stopLoading');
        console.error('err', err);
        // this.error = err;
      }
    },

    // click on google poi
    // click on autocomplete
    async queryDetails() {
      try {
        this.$store.commit('mapSearch/startLoading');
        await debugTimeout(this.debug);
        const data = await this.getDetails(this.select.place_id);
        this.$store.commit('mapSearch/setSelected', data);
        this.$store.commit('mapSearch/stopLoading');
      } catch (err) {
        this.$store.commit('mapSearch/stopLoading');
        console.error('err', err);
        // this.error = err;
      }
    },
  },

  computed: {
    ...mapState('mapSearch', ['loading', 'select']),
    height() {
      return this.$parent.height - 46;
    },
    errorFlag() {
      return !!this.error;
    },
    noDataText() {
      return this.error ? this.error.message : this.$t('$vuetify.noDataText');
    },
  },

  watch: {
    search() {
      if (this.search && (!this.select || this.search !== this.select.name)) {
        this.queryAutocomplete();
      } else {
        this.autocompleteItems = [];
      }
    },

    async select() {
      if (this.select) {
        // this.search = this.select.name;
        if (this.select.is_favorite) {
          this.select.name = this.select.title;
        } else if (this.select.place_id) {
          if (!this.select.address_components) {
            this.updateMarker();
            await this.queryDetails();
          }
        } else {
          await this.querySearch();
        }
      } else if (this.select === undefined) {
        this.searchItems = [];
      }
      this.updateMarker();
    },
  },
});

export { SearchInput };
