import PhoneNumber from 'awesome-phonenumber';
import { MIN_RADIUS, MAX_RADIUS } from '@/const';
import { $t } from '@/i18n';

const REG_EXPS = {
  name: /^(?=.*[-a-zA-Zа-яА-ЯёЁ0-9.!#$%&’*+/=?^_`{|}~-\u00C0-\u017FA])[-a-zA-Zа-яА-ЯёЁ0-9.!#$%&’*+/=?^_`{|}~-\u00C0-\u017FA ]+$/,
  email: /^[a-zA-Zа-яА-ЯёЁ0-9.!#$%&’*+/=?^_`{|}~-]+@(?:[a-zA-Zа-яА-ЯёЁ0-9-]+\.)+[a-zA-Zа-яА-Я0-9-]{2,63}$/,
  domain: {
    letters: /^[-0-9a-z]+$/,
    start: /^(?=.*[a-z])[0-9a-z][-0-9a-z]*[0-9a-z]$/,
  },
  password: {
    letters: /^[\x20-\x7E]+$/,
    correct: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\x20-\x7E]{6,32}$/,
    lower: /[a-z]/,
    upper: /[A-Z]/,
    digit: /\d/,
  },
  phone: {
    letters: /^[-+()0-9]*$/,
    plus: /^\+[^+]*$/,
  },
  time: {
    militaryHoursMinutesLeadingZero: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  },
};

const REQUIRED_RULE = name => v =>
  (!(typeof v === 'string' && v.trim() === '') && v !== undefined && v !== null) || $t('rules.required', [$t(name)]);

const STRING_RULE = name => v => (typeof v === 'string' && v.length <= 255) || $t('rules.string', [$t(name)]);

const NAME_RULE = name => v => {
  if (!v) {
    return true;
  }
  return REG_EXPS.name.test(v) || $t('rules.name', [$t(name)]);
};

const EMAIL_RULE = v => {
  if (!v) {
    return true;
  }
  return REG_EXPS.email.test(v) || $t('rules.email');
};

const TIME_RULE = v => {
  if (!v || v === ':') {
    return true;
  }
  return REG_EXPS.time.militaryHoursMinutesLeadingZero.test(v) || $t('rules.time_incorrect');
};

const NUM_FLOAT_RULE = v => (!/^\s*$/.test(v) && !isNaN(v) && isFinite(v)) || $t('rules.invalid_number');

const MIN_RADIUS_RULE = v => parseFloat(v) >= MIN_RADIUS || `${$t('rules.min_value')}: ${MIN_RADIUS}`;
const MAX_RADIUS_RULE = v => parseFloat(v) <= MAX_RADIUS || `${$t('rules.max_value')}: ${MAX_RADIUS}`;

const required = (name, more) => [REQUIRED_RULE(name), ...(more || [])];

const string = name => [STRING_RULE(name)];
string.required = name => [REQUIRED_RULE(name), STRING_RULE(name)];

const name = name => [STRING_RULE(name), NAME_RULE(name)];
name.required = name => [REQUIRED_RULE(name), STRING_RULE(name), NAME_RULE(name)];

const email = [EMAIL_RULE];
email.required = [REQUIRED_RULE('E-mail'), EMAIL_RULE];

const time = [TIME_RULE];

const radius = [NUM_FLOAT_RULE, MIN_RADIUS_RULE, MAX_RADIUS_RULE];

const floatNumber = [NUM_FLOAT_RULE];

const domain = required('Your site address', [
  v => REG_EXPS.domain.letters.test(v) || $t('rules.domain_letters'),
  v => v.length >= 2 || $t('rules.domain_short'),
  v => REG_EXPS.domain.start.test(v) || $t('rules.domain_start'),
]);

const password = required('Password', [
  v => REG_EXPS.password.letters.test(v) || $t('rules.password_letters'),
  v => v.length >= 6 || $t('rules.password_short'),
  v => v.length <= 32 || $t('rules.password_long'),
  v => REG_EXPS.password.lower.test(v) || $t('rules.password_lower'),
  v => REG_EXPS.password.upper.test(v) || $t('rules.password_upper'),
  v => REG_EXPS.password.digit.test(v) || $t('rules.password_digit'),
  v => REG_EXPS.password.correct.test(v) || $t('rules.password_correct'),
]);

const passwordAttrs = {
  hint: 'rules.password_hint',
  persistentHint: true,
  maxlength: '32',
  rules: password,
};

const phone = [
  v => !v || (v && REG_EXPS.phone.letters.test(v)) || $t('rules.phone_letters'),
  v => !v || (v && REG_EXPS.phone.plus.test(v)) || $t('rules.phone_plus'),
  v => {
    if (!v) {
      return true;
    }
    const pn = new PhoneNumber(v);
    return pn.isValid() || $t('rules.phone_incorrect');
  },
];

const phoneLetters = [v => !v || (v && REG_EXPS.phone.letters.test(v)) || $t('rules.phone_letters')];

phone.required = required('Phone', phone);

const login = [
  v => {
    const pn = new PhoneNumber(v);
    const validPhone = pn.isValid() && REG_EXPS.phone.letters.test(v) && REG_EXPS.phone.plus.test(v);

    return (v && REG_EXPS.email.test(v)) || validPhone || $t('rules.login');
  },
];

login.required = required('Login', login);

const foreignId = required('ForeignId', [
  v => (v && v.length <= 48) || $t('rules.foreign_id_long'),
  v => /^[A-Za-z0-9_-]{1,48}$/.test(v) || $t('rules.foreign_id'),
]);

export default {
  required,
  string,
  name,
  email,
  time,
  radius,
  floatNumber,
  domain,
  password,
  passwordAttrs,
  phone,
  phoneLetters,
  login,
  foreignId,
};

export { REG_EXPS };
