import uuid from 'uuid/v4';
import Trip, { TripMode, TripStatus } from '../models/Trip';
import TripPoint, {
  BatteryInfo,
  Checkin,
  DeliveryWindows,
  TripPointCheckin,
  TripPointStatus,
  TripPointType,
  IDeliveryWindowsItem,
} from '../models/TripPoint';
import Uuid from '../models/Uuid';
import { calculateMissDeliveryWindows, getShortAddresses } from '@/services/tripService';
import { calculateETAToPoints, cutActiveWayByPoint } from '@/services/routeService';
import WayPoint, { WayPointCheckin } from '../models/WayPoint';
import { PointCoordinates } from '@/models/Location';

export interface TripPageFilter {
  showPassedRoute: boolean;
  showActiveRoute: boolean;
  deleted: boolean;
}

export class TripTableRow implements IDeliveryWindowsItem {
  id: Uuid;
  number: number | null = null;
  containingNumbers: number[] | null = null;
  tripPoint: TripPoint | null = null;
  wayPoint: WayPoint | null = null;
  checkin: Checkin | null = null;
  workTime: Checkin | null = null;
  deliveryWindows: DeliveryWindows[] | null = null;
  batteryInfo: BatteryInfo | null = null;

  isInMultipoint: boolean = false;
  isMultipoint: boolean = false;
  timeInTransit: number | null = null;
  timeInPoint: number | null = null;
  totalWorkTime: number | null = null;

  missDeliveryWindow: boolean = false;
  isStayTimeExceeded: boolean = false;
  isWorkTimeExceeded: boolean = false;

  shortAddress: string | null = null;
  arrivalPlanAt: Date | null = null;
  plannedAt: Date | null = null;
  startedAt: Date | null = null;
  showDate: boolean = false;

  showTimezone: boolean | null = null;
  timezoneOffset: number | null = null;

  noBorderTop: boolean = false;
  noBorderBottom: boolean = false;
  hasMultipointMargin: boolean | undefined = undefined;

  plan: boolean = false;
  status: TripPointStatus;

  canChangeSettings = false;
  canBeChangedToFinish = false;
  canBeChangedToScheduled = false;
  canDelete = false;
  canUndelete = false;
  canMove = false;

  functionsList: string[] | null = null;

  constructor(data: { id: Uuid; status: TripPointStatus } & Partial<TripTableRow>) {
    this.id = data.id;
    this.status = data.status;
    Object.assign(this, data);
  }
}

export class MapGroupData {
  id: Uuid;
  coordinates: PointCoordinates;
  isDowntime: boolean = false;
  isCurrent: boolean = false;
  isNext: boolean = false;

  constructor(data: MapGroupData) {
    this.id = data.id;
    this.coordinates = data.coordinates;
  }
}

export class MapPointData {
  id: Uuid;
  number: number | null = null;
  tripPoint: TripPoint;
  wayPointId: String | null = null;
  groupId: Uuid | null = null;
  zindex: number | null = null;
  checkin: Checkin | null = null;

  isCurrent: boolean = false;

  timeInPoint: number | null = null;

  missDeliveryWindow: boolean = false;
  isDowntime: boolean = false;
  isNext: boolean = false;
  shortAddress: string | null = null;
  arrivalPlanAt: Date | null = null;

  showDate: boolean = false;

  showTimezone: boolean | null = null;
  timezoneOffset: number | null = null;

  canDelete = false;
  canUndelete = false;
  canCalibrate = false;

  constructor(data: { id: Uuid; tripPoint: TripPoint } & Partial<MapPointData>) {
    this.id = data.id;
    this.tripPoint = data.tripPoint;
    Object.assign(this, data);
  }
}

interface TripPageOptions {
  dontAccountDeparture?: boolean;
  isEditingTrip?: boolean;
}

export default class TripPageService {
  trip: Trip;
  filter: TripPageFilter;
  options: TripPageOptions;

  constructor(trip: Trip, filter?: TripPageFilter, options?: TripPageOptions) {
    this.trip = trip;
    this.filter = filter || {
      showPassedRoute: true,
      showActiveRoute: true,
      deleted: false,
    };
    this.options = {
      dontAccountDeparture: Boolean(options?.dontAccountDeparture),
      isEditingTrip: Boolean(options?.isEditingTrip),
    };
  }

  getWayPointsByTripPointId() {
    return this.trip.getWayPointsByTripPointId();
  }

  getTripPointsById() {
    return this.trip.getTripPointsById();
  }

  getPointNumbers() {
    const result = new Map<Uuid, number>();

    let num = 0;

    if (this.trip.tripPoints.length) {
      const startPoint = this.trip.tripPoints[0];
      if (!startPoint.isStart()) {
        num++;
      }
    }

    this.trip.tripPoints.forEach(tripPoint => {
      if (
        !tripPoint.isDeleted() &&
        !tripPoint.isWaitingForDelete() &&
        !tripPoint.isNotScheduled() &&
        !tripPoint.isActualFinish()
      ) {
        result.set(tripPoint.id, num++);
      }
    });

    return result;
  }

  getShortAddresses(): Map<Uuid, string> {
    return getShortAddresses(this.trip.tripPoints);
  }

  getPointsETA(): Map<Uuid, Date> {
    if (this.options.isEditingTrip) {
      return calculateETAToPoints(this.trip, this.getActiveWay());
    }

    const pointsETA = new Map<Uuid, Date>();
    for (const tripPoint of this.trip.tripPoints) {
      if (tripPoint.arrivalPlanAt) {
        pointsETA.set(tripPoint.id, tripPoint.arrivalPlanAt);
      }
    }

    return pointsETA;
  }

  getActiveWay() {
    const currentPosition = this.trip.getCurrentPosition();
    if (currentPosition && this.trip.way.activeWay) {
      return cutActiveWayByPoint(currentPosition, this.trip.way.activeWay);
    }
    return this.trip.way.activeWay;
  }

  isCreatingTrip(): boolean {
    return !Boolean(this.trip.id);
  }

  getMissDeliveryWindows(): Map<Uuid, boolean> {
    return calculateMissDeliveryWindows(this.trip.tripPoints, this.getPointsETA(), this.trip.plannedAt);
  }

  getTableRowsData() {
    const rows: TripTableRow[] = [];
    const pointNumbers = this.getPointNumbers();
    const shortAddresses = this.getShortAddresses();
    const pointsETA = this.getPointsETA();
    const tripPointsById = this.getTripPointsById();
    const missDeliveryWindows = this.getMissDeliveryWindows();
    const wayPointsByTripPointId = this.getWayPointsByTripPointId();
    let allCheckins: (WayPointCheckin | TripPointCheckin)[] = [];

    const countNotDeletedPoints = this.trip.tripPoints.filter(
      tripPoints => !tripPoints.isWaitingForDelete() && !tripPoints.isDeleted(),
    ).length;

    for (const wayPoint of this.trip.wayPoints) {
      for (const checkin of wayPoint.checkins) {
        if (!checkin.isMarked()) {
          allCheckins.push(checkin);
        }
      }
    }

    for (const tripPoint of this.trip.tripPoints) {
      for (const checkin of tripPoint.checkins) {
        allCheckins.push(checkin);
      }
    }

    // Sort checkins and make sort stable
    const allCheckinsEntries = [...allCheckins.entries()];
    allCheckinsEntries.sort(([i, a], [j, b]) => {
      const cmp = a.compareTo(b);
      return cmp === 0 ? i - j : cmp;
    });
    allCheckins = allCheckinsEntries.map(([i, value]) => value);

    let prevCheckin: WayPointCheckin | TripPointCheckin | null = null;
    const seenCheckins = new Set<Uuid>();
    const seenPoints = new Set<Uuid>();

    for (const checkin of allCheckins) {
      const wayPoint = checkin.getWayPoint();

      if (!wayPoint) {
        const tripPoint = checkin.getTripPoint();

        if (tripPoint && !seenCheckins.has(checkin.id)) {
          seenCheckins.add(checkin.id);
          seenPoints.add(tripPoint.id);

          const num = pointNumbers.get(tripPoint.id);
          const wayPoint = wayPointsByTripPointId.get(tripPoint.id);
          let isInMultipoint = wayPoint ? wayPoint.tripPointIds.length > 1 : false;
          let containingNumbers = (wayPoint?.tripPointIds
            .map(tripPointId => pointNumbers.get(tripPointId))
            .filter(pos => pos !== undefined) as number[]).sort((a, b) => a - b);

          // Row for marked trip point or checkined trippoints without waypoint checkin at the same time(can be bug from mobile)
          rows.push(
            new TripTableRow({
              id: checkin.id,
              number: num !== undefined ? num : null,
              tripPoint,
              status: tripPoint.status,
              checkin: this.trip.tripMode !== TripMode.manual ? checkin : null,
              batteryInfo: checkin.batteryInfo || null,
              isInMultipoint,
              containingNumbers,
              hasMultipointMargin: false,
              timeInTransit: null,
              timeInPoint: null,
              totalWorkTime:
                this.trip.tripMode === TripMode.manual && tripPoint.isScheduled() ? checkin.getDuration() : null,
              isStayTimeExceeded: (tripPoint.stayTimeExceeded || 0) > 0,
              isWorkTimeExceeded: (tripPoint.workTimeExceeded || 0) > 0,
              shortAddress: shortAddresses.get(tripPoint.id) || null,
              plan: this.trip.isInPlan(tripPoint),
              showTimezone: this.trip.containsDifferentTimezones(),
              timezoneOffset: tripPoint.location.timezone.offset,
              missDeliveryWindow: missDeliveryWindows.get(tripPoint.id),
              startedAt: this.trip.startedAt,
              plannedAt: this.trip.plannedAt,
              workTime: this.trip.tripMode === TripMode.manual && tripPoint.isScheduled() ? checkin : null,
              deliveryWindows: tripPoint.deliveryWindows,
              canChangeSettings: this.isCreatingTrip() || tripPoint.isNew(),
              showDate: this.trip.containsDifferentDates(),
              functionsList: tripPoint.getFunctionsList(),
            }),
          );
        }

        continue;
      }

      const timeInTransit = prevCheckin ? checkin.getTransitFrom(prevCheckin) : null;
      prevCheckin = checkin;

      let isInMultipoint = false;
      let containingNumbers = (wayPoint?.tripPointIds
        .map(tripPointId => pointNumbers.get(tripPointId))
        .filter(pos => pos !== undefined) as number[]).sort((a, b) => a - b);

      let wayPointCheckin = checkin as WayPointCheckin;

      if (wayPoint.tripPointIds.length > 1) {
        seenCheckins.add(checkin.id);

        isInMultipoint = true;

        const firstTripPoint = this.trip.tripPoints.find(tripPoint => wayPoint.tripPointIds.includes(tripPoint.id));

        // Row for waypoint summary if it has more than one trippoint (no data about trippoints here)
        rows.push(
          new TripTableRow({
            id: wayPointCheckin.id,
            containingNumbers,
            wayPoint: wayPoint,
            checkin: checkin,
            batteryInfo: checkin.batteryInfo || null,
            showTimezone: this.trip.containsDifferentTimezones(),
            timezoneOffset: firstTripPoint
              ? firstTripPoint.location.timezone.offset
              : this.trip.startTimezone()?.offset,
            status: checkin.outDate ? TripPointStatus.passed : TripPointStatus.current,
            timeInTransit: timeInTransit,
            timeInPoint: wayPointCheckin.getDuration(),
            isMultipoint: true,
            isStayTimeExceeded: (firstTripPoint?.stayTimeExceeded || 0) > 0,
            isWorkTimeExceeded: (firstTripPoint?.workTimeExceeded || 0) > 0,
            startedAt: this.trip.startedAt,
            showDate: this.trip.containsDifferentDates(),
          }),
        );
      }

      const tripPoints = wayPoint.tripPointIds
        .map(id => tripPointsById.get(id))
        .filter(p => p !== undefined) as TripPoint[];

      tripPoints.sort((a, b) => {
        const posA = pointNumbers.get(a.id);
        const posB = pointNumbers.get(b.id);
        return posA !== undefined && posB !== undefined ? posA - posB : 1;
      });

      tripPoints.forEach(tripPoint => {
        tripPoint.checkins
          .filter(tripPointCheckin => tripPointCheckin.isInside(checkin))
          .forEach(checkin => {
            const num = pointNumbers.get(tripPoint.id);
            seenCheckins.add(checkin.id);
            seenPoints.add(tripPoint.id);

            // Get the time spent at the point depending on the trip mode
            let timeInPoint = wayPointCheckin.getDuration();
            const totalWorkTime =
              this.trip.tripMode === TripMode.manual && tripPoint.isScheduled() ? checkin.getDuration() : null;

            if (this.options.dontAccountDeparture && wayPointCheckin.inDate && checkin.outDate) {
              timeInPoint = Checkin.getIntervalDuration(wayPointCheckin.inDate, checkin.outDate);
            }

            let showedCheckin;

            if (this.trip.tripMode !== TripMode.manual) {
              showedCheckin =
                tripPoint.status === TripPointStatus.marked ? checkin : !isInMultipoint ? wayPointCheckin : null;
            } else {
              showedCheckin = wayPointCheckin;
            }

            // Row for normally checkined trip point (autocheckin or work time in checkin)
            rows.push(
              new TripTableRow({
                id: checkin.id,
                number: num !== undefined ? num : null,
                tripPoint,
                timeInPoint: tripPoint.type === TripPointType.finish ? null : timeInPoint,
                totalWorkTime: totalWorkTime,
                checkin: showedCheckin,
                batteryInfo:
                  tripPoint.status === TripPointStatus.marked
                    ? checkin.batteryInfo
                    : wayPointCheckin.batteryInfo || null,
                status:
                  tripPoint.status !== TripPointStatus.current
                    ? tripPoint.status
                    : wayPointCheckin.outDate
                    ? TripPointStatus.passed
                    : TripPointStatus.current,
                isInMultipoint,
                containingNumbers,
                timeInTransit: !isInMultipoint ? timeInTransit : null,
                shortAddress: shortAddresses.get(tripPoint.id) || null,
                plan: this.trip.isInPlan(tripPoint),
                showTimezone: this.trip.containsDifferentTimezones(),
                timezoneOffset: tripPoint.location.timezone.offset,
                missDeliveryWindow: missDeliveryWindows.get(tripPoint.id),
                startedAt: this.trip.startedAt,
                plannedAt: this.trip.plannedAt,
                isStayTimeExceeded: (tripPoint.stayTimeExceeded || 0) > 0,
                isWorkTimeExceeded: (tripPoint.workTimeExceeded || 0) > 0,
                workTime: this.trip.tripMode === TripMode.manual && tripPoint.isScheduled() ? checkin : null,
                deliveryWindows: tripPoint.deliveryWindows,
                canChangeSettings: this.isCreatingTrip() || tripPoint.isNew(),
                showDate: this.trip.containsDifferentDates(),
                functionsList: tripPoint.getFunctionsList(),
              }),
            );
          });
      });

      // check if waypoint was checkined without setting trippoint passed
      if (this.trip.tripMode === TripMode.manual && tripPoints.length == 1) {
        const tripPoint = tripPoints[0];
        const checkinsInside = tripPoint.checkins.filter(tripPointCheckin => tripPointCheckin.isInside(checkin));
        if (!checkinsInside.length) {
          seenCheckins.add(checkin.id);
          seenPoints.add(tripPoint.id);

          const num = pointNumbers.get(tripPoint.id);

          // Row for trip point when waypoint was passed, but trippoint still active (ex. in manual mode)
          rows.push(
            new TripTableRow({
              id: wayPointCheckin.id,
              number: num !== undefined ? num : null,
              tripPoint,
              containingNumbers,
              checkin: checkin,
              batteryInfo: checkin.batteryInfo || null,
              showTimezone: this.trip.containsDifferentTimezones(),
              timezoneOffset: tripPoint.location.timezone.offset,
              status:
                tripPoint.status !== TripPointStatus.current
                  ? tripPoint.status
                  : wayPointCheckin.outDate
                  ? TripPointStatus.passed
                  : TripPointStatus.current,
              timeInTransit: timeInTransit,
              shortAddress: shortAddresses.get(tripPoint.id) || null,
              timeInPoint: wayPointCheckin.getDuration(),
              missDeliveryWindow: missDeliveryWindows.get(tripPoint.id),
              deliveryWindows: tripPoint.deliveryWindows,
              showDate: this.trip.containsDifferentDates(),
              functionsList: tripPoint.getFunctionsList(),
            }),
          );
        }
      }
    }

    for (let i = 1; i < rows.length; i++) {
      if (rows[i].isInMultipoint && rows[i].hasMultipointMargin === undefined) {
        rows[i - 1].noBorderBottom = true;
        rows[i - 1].hasMultipointMargin = true;
        rows[i].noBorderTop = true;
        rows[i].hasMultipointMargin = true;
      }
    }

    for (const tripPoint of this.trip.tripPoints) {
      if (!tripPoint.isFinish() && (tripPoint.isActive() || tripPoint.isWaitingForDelete())) {
        // #TODO: проверку на чекины можно будет убрать когда мобилки перестанут присылать чекин для активной точки с работами меньше 15 секунд
        const checkinLength = tripPoint.checkins.length;

        if (checkinLength > 0) {
          if (seenCheckins.has(tripPoint.checkins[checkinLength - 1].id)) {
            continue;
          }
        }

        if (seenPoints.has(tripPoint.id)) {
          continue;
        }

        const wayPoint = wayPointsByTripPointId.get(tripPoint.id);
        const arrivalPlanAt = pointsETA.get(tripPoint.id) || null;
        let isInMultipoint = wayPoint ? wayPoint.tripPointIds.length > 1 : false;
        let containingNumbers = (wayPoint?.tripPointIds
          .map(tripPointId => pointNumbers.get(tripPointId))
          .filter(pos => pos !== undefined) as number[]).sort((a, b) => a - b);

        const num = pointNumbers.get(tripPoint.id);

        // Row for active(not visited) point
        rows.push(
          new TripTableRow({
            id: tripPoint.id,
            number: num !== undefined ? num : null,
            tripPoint,
            status: tripPoint.status,
            isInMultipoint,
            containingNumbers,
            shortAddress: shortAddresses.get(tripPoint.id) || null,
            arrivalPlanAt,
            plan: this.trip.isInPlan(tripPoint),
            showTimezone: this.trip.containsDifferentTimezones(),
            timezoneOffset: tripPoint.location.timezone.offset,
            canMove: tripPoint.isScheduled() && !tripPoint.isWaitingForDelete(),
            canBeChangedToFinish: tripPoint.isScheduled() && !tripPoint.isWaitingForDelete() && !this.trip.isEnded(),
            canBeChangedToScheduled: tripPoint.isFinish() && this.isCreatingTrip(),
            canChangeSettings: this.isCreatingTrip() || tripPoint.isNew(),
            canDelete:
              (this.isCreatingTrip() || (tripPoint.isActive() && tripPoint.isScheduled())) && countNotDeletedPoints > 1,
            canUndelete: tripPoint.isWaitingForDelete(),
            deliveryWindows: tripPoint.deliveryWindows,
            missDeliveryWindow: missDeliveryWindows.get(tripPoint.id),
            startedAt: this.trip.startedAt,
            plannedAt: this.trip.plannedAt,
            showDate: this.trip.containsDifferentDates(),
            functionsList: tripPoint.getFunctionsList(),
          }),
        );
      }

      if (tripPoint.isCancelled() || (tripPoint.isFinish() && !tripPoint.checkins.length)) {
        if (seenPoints.has(tripPoint.id)) {
          continue;
        }

        const wayPoint = wayPointsByTripPointId.get(tripPoint.id);
        let isInMultipoint = wayPoint ? wayPoint.tripPointIds.length > 1 && !tripPoint.isFinish() : false;
        let containingNumbers = (wayPoint?.tripPointIds
          .map(tripPointId => pointNumbers.get(tripPointId))
          .filter(pos => pos !== undefined) as number[]).sort((a, b) => a - b);

        const num = pointNumbers.get(tripPoint.id);

        // Row for cancelled point including finish
        rows.push(
          new TripTableRow({
            id: tripPoint.id,
            number: num !== undefined ? num : null,
            tripPoint,
            status: tripPoint.status,
            isInMultipoint,
            containingNumbers,
            shortAddress: shortAddresses.get(tripPoint.id) || null,
            arrivalPlanAt: pointsETA.get(tripPoint.id) || null,
            plannedAt: this.trip.plannedAt || null,
            plan: this.trip.isInPlan(tripPoint),
            showTimezone: this.trip.containsDifferentTimezones(),
            timezoneOffset: tripPoint.location.timezone.offset,
            startedAt: this.trip.startedAt,
            ...(!tripPoint.isCancelled()
              ? {
                  canMove: this.isCreatingTrip() && tripPoint.isScheduled(),
                  canBeChangedToFinish: tripPoint.isScheduled() && !this.trip.isEnded(),
                  canBeChangedToScheduled: tripPoint.isFinish() && this.isCreatingTrip(),
                  canChangeSettings: this.isCreatingTrip(),
                  canDelete: this.isCreatingTrip(),
                }
              : {}),
            deliveryWindows: tripPoint.deliveryWindows,
            showDate: this.trip.containsDifferentDates(),
            functionsList: tripPoint.getFunctionsList(),
          }),
        );
      }

      if (tripPoint.isDeleted() && !tripPoint.isFinish() && !tripPoint.isActualFinish()) {
        // #TODO: проверку на чекины можно будет убрать когда мобилки перестанут присылать чекин для активной точки с работами меньше 15 секунд
        const checkinLength = tripPoint.checkins.length;

        if (checkinLength > 0) {
          if (seenCheckins.has(tripPoint.checkins[checkinLength - 1].id)) {
            continue;
          }
        }

        if (seenPoints.has(tripPoint.id)) {
          continue;
        }

        // Row for deleted point
        rows.push(
          new TripTableRow({
            id: tripPoint.id,
            tripPoint,
            status: tripPoint.status,
            shortAddress: shortAddresses.get(tripPoint.id) || null,
            plan: this.trip.isInPlan(tripPoint),
            showTimezone: this.trip.containsDifferentTimezones(),
            timezoneOffset: tripPoint.location.timezone.offset,
            canChangeSettings: this.isCreatingTrip() || tripPoint.isNew(),
            deliveryWindows: tripPoint.deliveryWindows,
            startedAt: this.trip.startedAt,
            plannedAt: this.trip.plannedAt,
            showDate: this.trip.containsDifferentDates(),
            functionsList: tripPoint.getFunctionsList(),
          }),
        );
      }

      const num = pointNumbers.get(tripPoint.id);

      // Row for actual finish point
      if (tripPoint.isActualFinish()) {
        rows.push(
          new TripTableRow({
            id: tripPoint.id,
            checkin: tripPoint.checkins[0] || null,
            batteryInfo: (tripPoint.checkins[0] && tripPoint.checkins[0].batteryInfo) || null,
            number: num !== undefined ? num : null,
            tripPoint,
            status: tripPoint.status,
            shortAddress: shortAddresses.get(tripPoint.id) || null,
            plan: this.trip.isInPlan(tripPoint),
            showTimezone: this.trip.containsDifferentTimezones(),
            timezoneOffset: tripPoint.location.timezone.offset,
            canChangeSettings: this.isCreatingTrip() || tripPoint.isNew(),
            startedAt: this.trip.startedAt,
            plannedAt: this.trip.plannedAt,
            showDate: this.trip.containsDifferentDates(),
            functionsList: tripPoint.getFunctionsList(),
          }),
        );
      }
    }

    const finishPoint = rows.find(rowData => {
      return rowData.tripPoint?.type === TripPointType.finish;
    });

    if (finishPoint) {
      const indexOfFinishPoint = rows.indexOf(finishPoint);
      rows.splice(indexOfFinishPoint, 1);
      rows.push(finishPoint);
    }

    const cancelledPoint = rows.filter(
      row =>
        row.tripPoint?.status === TripPointStatus.cancelled &&
        row.tripPoint?.type !== (TripPointType.actualFinish || TripPointType.finish) &&
        !row.checkin,
    )[0];

    let positionFinish = 0;

    const actualFinish = rows.filter((row, index) => {
      if (row.tripPoint?.type === TripPointType.actualFinish) {
        positionFinish = index;
        return row;
      }
    })[0];

    if (cancelledPoint && actualFinish) {
      const slicedFinish = rows.splice(positionFinish, 1)[0];
      rows.splice(rows.indexOf(cancelledPoint), 0, slicedFinish);
    }

    return rows;
  }

  getMapPointsData() {
    const points: MapPointData[] = [];
    const groups: MapGroupData[] = [];
    const createdGroups = new Set<Uuid>();
    const shortAddresses = this.getShortAddresses();
    const pointsETA = this.getPointsETA();
    const wayPointsByTripPointId = this.getWayPointsByTripPointId();
    const pointNumbers = this.getPointNumbers();
    const missDeliveryWindows = this.getMissDeliveryWindows();

    const countNotDeletedPoints = this.trip.tripPoints.filter(
      tripPoints => !tripPoints.isWaitingForDelete() && !tripPoints.isDeleted(),
    ).length;

    const firstActivePoint = this.trip.tripPoints.find(tripPoint => tripPoint.status == TripPointStatus.active);
    for (const tripPoint of this.trip.tripPoints) {
      const wayPoint = wayPointsByTripPointId.get(tripPoint.id);
      let isInMultipoint = wayPoint ? wayPoint.tripPointIds.length > 1 : false;

      let checkin: Checkin | null;

      const firstWayPointCheckin =
        (wayPoint && wayPoint.checkins.length && wayPoint.checkins[wayPoint.checkins.length - 1]) || null;
      const firstTripPointCheckin =
        (tripPoint && tripPoint.checkins.length && tripPoint.checkins[tripPoint.checkins.length - 1]) || null;
      if (this.trip.isManual() && firstTripPointCheckin) {
        checkin = firstTripPointCheckin;
      } else {
        checkin = firstWayPointCheckin;
      }

      points.push(
        new MapPointData({
          id: tripPoint.id,
          tripPoint: tripPoint,
          wayPointId: wayPoint ? wayPoint.id : uuid(),
          number: pointNumbers.get(tripPoint.id),
          checkin: checkin,
          groupId: wayPoint && isInMultipoint ? wayPoint.id : null,
          missDeliveryWindow: missDeliveryWindows.get(tripPoint.id) || false,
          isNext: this.trip.status === TripStatus.active && tripPoint.id === firstActivePoint?.id && !isInMultipoint,
          showTimezone: this.trip.containsDifferentTimezones(),
          timezoneOffset: tripPoint.location.timezone.offset,
          isDowntime: (tripPoint.stayTimeExceeded || 0) > 0 || (tripPoint.workTimeExceeded || 0) > 0,
          shortAddress: shortAddresses.get(tripPoint.id),
          arrivalPlanAt: pointsETA.get(tripPoint.id),
          isCurrent: wayPoint?.isCurrent() ?? tripPoint.status === TripPointStatus.current,
          canDelete:
            (this.isCreatingTrip() || (tripPoint.isActive() && tripPoint.isScheduled())) && countNotDeletedPoints > 1,
          canUndelete: tripPoint.isWaitingForDelete(),
          canCalibrate:
            this.isCreatingTrip() || (tripPoint.isActive() && (tripPoint.isScheduled() || tripPoint.isFinish())),
          showDate: this.trip.containsDifferentDates(),
        }),
      );

      if (wayPoint && isInMultipoint && !createdGroups.has(wayPoint.id)) {
        const isNext =
          this.trip.status === TripStatus.active &&
          firstActivePoint &&
          wayPoint.tripPointIds.includes(firstActivePoint.id);

        groups.push({
          id: wayPoint.id,
          isNext: isNext ? isNext : false,
          coordinates: wayPoint.coordinates,
          isDowntime: (tripPoint.stayTimeExceeded || 0) > 0 || (tripPoint.workTimeExceeded || 0) > 0,
          isCurrent: wayPoint.isCurrent(),
        });
        createdGroups.add(wayPoint.id);
      }
    }

    return { points, groups };
  }
}
