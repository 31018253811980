
import Vue from 'vue';

export default Vue.extend({
  name: 'AllowQuitPageDialog',

  data() {
    return {
      showDialog: true,
    };
  },

  methods: {
    allowChangePage(): void {
      this.$emit('allow-quit-page');
    },

    hideModal() {
      this.showDialog = false;
      this.$emit('close');
    },
  },

  watch: {
    showDialog(value) {
      this.hideModal();
    },
  },
});
