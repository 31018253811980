<template>
  <v-layout column :style="chatStyles" class="chat-container elevation-2">
    <MessageList
      v-if="!showUserList"
      @close="close"
      :messages="messages"
      :participants="participants"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="messageStyling"
    />

    <UserInput
      v-if="!showUserList && canChat && hasPermission('chats send')"
      :show-emoji="showEmoji"
      :on-submit="onUserInputSubmit"
      :suggestions="getSuggestions()"
      :show-file="showFile"
      :placeholder="placeholder"
      :colors="colors"
    />
  </v-layout>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { isNil } from 'lodash';

import chat from '@/api/chat';

import MessageList from '../../../vendor/vue-beautiful-chat/MessageList';
import UserInput from '../../../vendor/vue-beautiful-chat/UserInput';

import { convertDateWithTimeZone } from '@/lib/date';
import { getTimeZoneString } from '@/lib/timezone';

const FOOTER_HEIGHT = 75;

export default Vue.extend({
  name: 'Chat',

  components: {
    MessageList,
    UserInput,
  },

  props: {
    tripId: {
      type: Number,
      default: null,
    },
    canChat: {
      type: Boolean,
      default: true,
    },
    showTimezone: {
      type: Boolean,
      default: false,
    },
    timezoneOffset: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
      colors: {
        messageList: {
          bg: '#ffffff',
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff',
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222',
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867',
        },
        systemMessage: {
          bg: '#ffffff',
          text: '#ff3131',
        },
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      showUserList: false,
      showEmoji: false,
      showFile: false,
    };
  },

  created() {
    this.fetchData();
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);

      this.timer = null;
    }
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    placeholder() {
      return this.$t('Enter some text to chat');
    },

    messages() {
      return this.messageList.map(msg => ({
        ...msg,
        data: {
          ...msg.data,
          meta:
            this.showTimezone && !isNil(this.timezoneOffset)
              ? this.$d(convertDateWithTimeZone(msg.created_at, this.timezoneOffset), 'date') +
                getTimeZoneString(this.timezoneOffset)
              : this.$d(msg.created_at, 'date'),
        },
      }));
    },

    appPaddingLeft() {
      return this.$vuetify.application.left;
    },

    appPaddingTop() {
      return this.$vuetify.application.top;
    },

    chatWidth() {
      if (this.hide) {
        return '0px';
      }

      return !this.hide && this.full ? '100%' : `calc((100vw - ${this.appPaddingLeft + 8}px)*0.416666 - 24px)`;
    },

    chatHeight() {
      let height;

      if (this.$vuetify.breakpoint.smAndDown) {
        height = `calc(100% - ${+this.$root.$children[0].footerHeight}px - 50px)`;
      } else {
        height = this.height ? this.height + 'px' : `calc(100% - ${this.appPaddingTop + FOOTER_HEIGHT + 48 - 1}px)`;
      }

      return height;
    },

    chatStyles() {
      return {
        position: 'fixed',
        width: this.chatWidth,
        height: this.chatHeight,
        top: '0',
        marginTop: `${this.appPaddingTop + 24}px`,
        marginRight: '24px',
        marginBottom: '24px',
        overflow: 'hidden',
        zIndex: 2,
      };
    },
  },

  methods: {
    async fetchData() {
      const data = await chat.get(this.tripId);

      this.messageList = data.messages;
      this.participants = data.participants;

      this.$emit('update', {
        unreadMessages: data.unreadMessages,
        newMessages: data.newMessages,
        totalMessages: data.totalMessages,
      });
    },

    async onUserInputSubmit(message) {
      // called when the user sends a message
      await chat.sendMessage(this.tripId, message);

      message.created_at = new Date();

      this.messageList = [...this.messageList, message];

      this.$emit('onMessageAdd', message);
    },

    getSuggestions() {
      return this.messages.length > 0 ? this.messages[this.messages.length - 1].suggestions : [];
    },

    close() {
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
@media (max-width: 960px) {
  .chat-container {
    position: fixed !important;
    margin: 0 !important;
    top: 50px !important;
    left: 0;
    width: 100% !important;
  }
}
</style>
