import http, { pagingParams, Params } from './httpV2';
import TripsFilterDto from '@/components/filters/TripsFilterDto';
import { TripDto } from '@/models/Trip';
import Account from '@/models/Account';
import Uuid from '@/models/Uuid';
import TripChange from '@/models/TripChange';
import { TripInstanceDto } from '@/models/Trip';
import { PlaceLink } from '@/models/Place';
import { WayCoordinates } from '@/models/Way';
import Trip from '@/models/Trip';

const path = '/trips';

type TripGetResponse = TripDto & {
  executor: Account;
  responsible?: Account;
};

export interface TripUpdateRequestDto {
  changes: TripChange[];
  sms?: String;
}

export interface TripCreateResponse {
  dbId: number;
}

export interface ImportResultError {
  numberOfRow?: number;
  indexOfRow?: number;
  reason: string;
  currentData?: PlaceLink & Partial<{ comment: String }>;
  passedData?: PlaceLink & Partial<{ comment: String }>;
}

export interface ImportResult {
  trip: TripInstanceDto;
  errors: ImportResultError[];
  hasPlaceConflict: boolean;
  placeCreated: Array<any>;
  placeUpdated: Array<any>;
}

interface IParams {
  location: boolean | number;
}

export interface TripsResponse {
  result: Array<TripDto>;
  total: number;
  aggregations?: any;
}

interface TripsListParams extends Params {
  isFull?: boolean;
  dateFrom?: string;
  dateTo?: string;
  accounts?: any[];
  status?: string[];
}

export interface copyTripPointsParams {
  id: string;
  tripId: string;
  tripDbId: string;
  demandIds?: string[];
}

interface createTripOptionsDto {
  ignoreDemandLocation?: boolean;
}

async function list(opts: TripsListParams = {}): Promise<TripsResponse> {
  if (opts && opts.filter) {
    opts = { ...opts };

    if (!opts.filter.toHttpParams) {
      opts.filter = new TripsFilterDto(opts.filter);
    }

    opts.filter = opts.filter.toHttpParams();
  }

  const res = await http.get(path, {
    params: pagingParams(opts),
  });

  return res.data;
}

async function get(id: number, opts?: IParams): Promise<TripGetResponse> {
  const params = {} as IParams;

  if (opts && opts.location) {
    params.location = 1;
  }

  const res = await http.get<TripGetResponse>(`${path}/${id}`, { params });

  return res.data;
}

async function getTripWithOnePoint(tripId: number, tripPointId: Uuid): Promise<TripGetResponse> {
  const res = await http.get<TripGetResponse>(`${path}/${tripId}/tripPoints/${tripPointId}`);
  return res.data;
}

async function getRawPassedWay(id: number): Promise<WayCoordinates[]> {
  const res = await http.get<WayCoordinates[]>(`${path}/${id}/rawPassedWay`);
  return res.data;
}

async function cancelTrip(id: number) {
  const res = await http.post(`${path}/${id}/cancel`);
  return res.data;
}

async function finishTrip(id: number) {
  const res = await http.post(`${path}/${id}/finish`);
  return res.data;
}

async function createTrip(trip: Trip, options: createTripOptionsDto): Promise<TripCreateResponse> {
  const res = await http.post<TripCreateResponse>(path, { trip, options });
  return res.data;
}

async function updateTrip(id: number, data: TripUpdateRequestDto) {
  const res = await http.post(`${path}/${id}/changes`, data);
  return res.data;
}

async function deleteTrips(data: Uuid[]) {
  const res = await http.delete(path, { data });
  return res.data;
}

async function restoreTrips(data: Uuid[]) {
  const res = await http.post(`${path}/restore`, data);
  return res.data;
}

interface ImportParams {
  rewrite?: boolean | Array<Uuid>;
  executorId?: Uuid | null;
  responsibleId?: Uuid | null;
  title?: string | null;
  plannedAt?: Date | null;
  optimize?: boolean | null;
  transportType?: string | null;
  transportId?: Uuid | null;
  isCreating?: boolean | null;
}

async function importRoute(data: string, params: ImportParams): Promise<ImportResult> {
  const res = await http.post(`${path}/import`, {
    data,
    ...params,
  });
  return res.data;
}

async function getMonitoringData(opts?: any): Promise<any> {
  const res = await http.get(`${path}/monitoring`, {
    params: pagingParams(opts),
  });
  return res.data;
}

async function copyTripPoints(tripPoints: copyTripPointsParams[], newTripId: Uuid) {
  const res = await http.post(`${path}/tripPoints/copy`, {
    tripPoints,
    newTripId,
  });
  return res.data;
}

async function useRawDistance(tripId: Uuid) {
  const res = await http.post(`${path}/${tripId}/useRawDistance`);
  return res.data;
}

export default {
  list,
  get,
  getTripWithOnePoint,
  getRawPassedWay,
  cancelTrip,
  finishTrip,
  createTrip,
  updateTrip,
  deleteTrips,
  restoreTrips,
  importRoute,
  getMonitoringData,
  copyTripPoints,
  useRawDistance,
};
