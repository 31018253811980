import store from '@/store';

function getMainSiteUrl(link) {
  if (!store.state.site.isCompanySite) {
    return link;
  }

  const parts = window.location.hostname.split('.');
  const url = new URL(window.location.href);
  if (parts.length > 0) {
    url.hostname = parts.slice(1).join('.');
  }
  url.pathname = link;
  url.search = '';
  url.hash = '';
  return url.href;
}

export { getMainSiteUrl };
