import GroupMarker from './GroupMarker';
import { getIconSettings } from '../config';
import { ITripMap } from './info/lib';
import { MapGroupOptions } from '../primitives/MarkerGroup';

export default GroupMarker.extend({
  name: 'MultipointMarker',

  extends: GroupMarker,

  computed: {
    markerData(): MapGroupOptions | null {
      let text = '';
      text += this.$tc('{0} points', this.points.length);

      let downText;
      if (this.groupData?.isCurrent) {
        downText = (this.$parent as ITripMap).updatedAtText;
      }

      const iconData = getIconSettings('multiPoi');

      const result = {
        position: this.position,
        anchor: iconData.anchor,
        secondLabel:
          text && !this.draggable
            ? {
                html: text,
                origin: iconData.secondLabelOrigin,
              }
            : undefined,
        downLabel:
          downText && !this.draggable
            ? {
                html: downText,
                origin: iconData.downLabelOrigin,
              }
            : undefined,
        isNext: this.groupData?.isNext,
        isCurrent: this.groupData?.isCurrent,
        // zindex: point.zindex,
      };

      return result;
    },
  },
});
