import axios from 'axios';
import APIError from './error';
import httpV2 from './httpV2';

const config = {
  debug: {
    mock: false,
    errors: process.env.NODE_ENV === 'development',
  },
};

if (config.debug.mock) {
  require('./mock');
}

const http = axios.create({
  baseURL: '/api/v1',
  timeout: 30000,
  withCredentials: true,
  headers: { 'X-Version': process.env.VUE_APP_VERSION },
});

http.interceptors.request.use(
  function(request) {
    return request;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response) {
      // Make error visible for error notifier
      const request = error.config;

      if (config.debug.errors && request) {
        setTimeout(() => {
          if (error.response.data) {
            throw new Error(
              [
                request.method.toUpperCase(),
                request.url,
                error.message,
                '\n',
                JSON.stringify(error.response.data, null, '  '),
              ].join(' '),
            );
          }
        }, 0);
      }

      return Promise.reject(APIError.fromResponse(error.response));
    } else {
      return Promise.reject(error);
    }
  },
);

function setCSRFToken(token) {
  http.defaults.headers.common['X-CSRF-Token'] = token;
  httpV2.defaults.headers.common['X-CSRF-Token'] = token;
}

const pagingParams = opts => {
  if (opts) {
    const { rowsPerPage, page, sortBy, descending } = opts;
    let filter = opts.filter;

    if (typeof filter === 'string') {
      filter = { filter: opts.filter };
    }

    return {
      page: page !== undefined ? page - 1 : 0,
      pageSize: rowsPerPage,
      sort: sortBy,
      sortDesc: descending ? 1 : 0,
      ...filter,
    };
  }
};

export default http;
export { APIError, setCSRFToken, pagingParams };
