
import Vue, { PropType } from 'vue';
import { TransportType } from '@/models/Transport';

export const TRANSPORT_ICONS: Dictionary<string> = {
  car: 'mdi-car',
  truck: 'mdi-truck',
  taxi: 'mdi-taxi',
  bus: 'mdi-bus',
  motorcycle: 'mdi-motorbike',
  bicycle: 'mdi-bike',
  pedestrian: 'mdi-walk',
};

export default Vue.extend({
  name: 'TransportTypePicker',

  props: {
    value: {
      type: String as PropType<TransportType | null>,
      default: null,
    },
    label: {
      type: String,
      default: 'Transport type',
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
    },
    items: {
      type: Array as PropType<TransportType[]>,
      default: () => Object.keys(TransportType),
    },
    prependIcon: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    statuses() {
      return this.items.map(s => ({
        text: this.$t(`TransportType.${s}`),
        icon: TRANSPORT_ICONS[s],
        value: s,
      }));
    },

    icon() {
      if (this.prependIcon === false) {
        return null;
      }
      return this.value ? TRANSPORT_ICONS[this.value] : TRANSPORT_ICONS['car'];
    },
  },

  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
