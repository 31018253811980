import Uuid from '@/models/Uuid';

export enum TransportType {
  car = 'car',
  truck = 'truck',
  taxi = 'taxi',
  bus = 'bus',
  motorcycle = 'motorcycle',
  bicycle = 'bicycle',
  pedestrian = 'pedestrian',
}

export interface Transport {
  id: Uuid;
  name?: string;
  number?: string;
  type: TransportType;
  isDeleted?: boolean;
}
