import TripBuilder from './TripBuilder';

class TripCases {
  empty() {
    return new TripBuilder().getTrip();
  }

  startFinish() {
    return new TripBuilder().addStartAndFinish().getTrip();
  }

  startFinishAndPoint() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .getTrip();
  }

  checkinToStart() {
    return new TripBuilder()
      .addStartAndFinish()
      .checkin(0)
      .getTrip();
  }

  checkoutFromStart() {
    return new TripBuilder()
      .addStartAndFinish()
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .getTrip();
  }

  checkoutFromStartAndPoint() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .getTrip();
  }

  checkinToPoint() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .getTrip();
  }

  checkinToSecondPoint() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(2)
      .getTrip();
  }

  reCheckinToPoint() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(2)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .shiftTime(600)
      .checkout()
      .getTrip();
  }

  finishedTrip() {
    return new TripBuilder()
      .addStartAndFinish()
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .finish()
      .getTrip();
  }

  multipoint12() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addMultipoint(1)
      .getTrip();
  }

  multipoint12Current() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .getTrip();
  }

  multipoint12And34() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addMultipoint(1)
      .addPoint()
      .addMultipoint(3)
      .getTrip();
  }

  checkinMultiPointPrimary() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .getTrip();
  }

  checkinMultiPoint1And3Passed() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .checkout()
      .getTrip();
  }

  multiPointRecheckin() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .getTrip();
  }

  checkinInMT() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .getTrip();
  }

  markedPointInMT() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .mark(3)
      .getTrip();
  }

  markedPointOutMT() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .shiftTime(1200)
      .checkout()
      .shiftTime(1200)
      .mark(3)
      .getTrip();
  }

  multiPointAllTrip() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addMultipoint(1)
      .addMultipoint(1)
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(600)
      .checkout()
      .shiftTime(300)
      .checkin(4)
      .shiftTime(600)
      .checkout()
      .shiftTime(300)
      .checkin(5)
      .shiftTime(600)
      .checkout()
      .finish()
      .getTrip();
  }

  withDeletePoint() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .deletePoint(2)
      .getTrip();
  }

  checkinToPointManualTrip() {
    return new TripBuilder()
      .setManualTripMode()
      .addStartAndFinish()
      .addPoint()
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .shiftTime(300)
      .checkout()
      .getTrip();
  }

  manualTripInMT() {
    return new TripBuilder()
      .setManualTripMode()
      .addStartAndFinish()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(600)
      .checkout()
      .shiftTime(600)
      .checkin(1)
      .shiftTime(300)
      .checkout()
      .getTrip();
  }

  manualTripWithMarked() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .mark(3)
      .getTrip();
  }

  withNotScheduledPoint() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint()
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .setNotScheduled(1)
      .getTrip();
  }

  manualWithRunningWorkTime() {
    return new TripBuilder()
      .setManualTripMode()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .beginWork(1)
      .shiftTime(1200)
      .getTrip();
  }

  manualWithWorkTime() {
    return new TripBuilder()
      .setManualTripMode()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .beginWork(1)
      .shiftTime(1200)
      .endUpWork()
      .getTrip();
  }

  manualWithWorkTimeAndMT() {
    return new TripBuilder()
      .setManualTripMode()
      .addStartAndFinish()
      .addPoint()
      .addPoint()
      .addMultipoint(1)
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .beginWork(1)
      .shiftTime(1200)
      .endUpWork()
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(4)
      .shiftTime(300)
      .beginWork(4)
      .shiftTime(1200)
      .endUpWork()
      .checkout()
      .getTrip();
  }

  manualWithWorkTimeAndMTDowntime() {
    return new TripBuilder()
      .setManualTripMode()
      .addStartAndFinish()
      .addPoint(p => {
        p.setStayTime(300);
      })
      .addPoint()
      .addMultipoint(1)
      .addMultipoint(1)
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(300)
      .beginWork(1)
      .shiftTime(1200)
      .endUpWork()
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(4)
      .shiftTime(300)
      .beginWork(4)
      .shiftTime(1200)
      .endUpWork()
      .checkout()
      .getTrip();
  }

  multipointDowntime() {
    return new TripBuilder()
      .addStartAndFinish()
      .addPoint(p => {
        p.setStayTime(300);
      })
      .addMultipoint(1, p => {
        p.setStayTime(300);
      })
      .addMultipoint(1, p => {
        p.setStayTime(300);
      })
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .shiftTime(300)
      .checkin(1)
      .shiftTime(1800)
      .checkout()
      .getTrip();
  }

  manualWithCheckinWithoutStartWork() {
    return new TripBuilder()
      .setManualTripMode()
      .addStartAndFinish()
      .addPoint()
      .checkin(0)
      .shiftTime(300)
      .checkout()
      .checkin(1)
      .getTrip();
  }
}

export default new TripCases();
