import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gmap from '@/lib/gmap';
import { CreateElement, VNode } from 'vue';

@Component({
  inject: ['getMap'],
})
export default class HideButtonComponent extends Vue {
  map!: google.maps.Map;
  button!: HTMLElement;
  showButton!: HTMLElement;

  @Prop() hide!: boolean;
  @Prop() full!: boolean;
  @Prop() hideMapHandler!: Function;

  async created() {
    this.map = await (this as any).getMap();

    const controlDiv = document.createElement('div');
    this.genButton(controlDiv);

    this.map.controls[(gmap as any).ControlPosition.LEFT_CENTER].push(controlDiv);
  }

  genButton(rootElem: any) {
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '1px solid rgba(0,0,0,0.12)';
    controlUI.style.borderRadius = '0px 27px 27px 0px';
    controlUI.style.display = 'flex';
    controlUI.style.alignItems = 'center';
    controlUI.style.height = '54px';
    controlUI.style.width = '27px';
    controlUI.style.position = 'fixed';
    controlUI.style.marginTop = '-30px';
    controlUI.onclick = this.hideButtonClick.bind(this);

    const icon = document.createElement('i');
    icon.className = 'material-icons v-icon';
    icon.innerText = 'chevron_right';
    controlUI.appendChild(icon);

    this.button = controlUI;

    rootElem.appendChild(controlUI);
  }

  render(h: CreateElement): VNode {
    return h();
  }

  hideButtonClick() {
    this.$emit('hideMapHandler');
  }

  @Watch('hide')
  onHideChange() {
    if (!this.hide) {
      this.button.style.transform = 'rotate(0deg)';
      this.button.style.marginLeft = '0';
    } else {
      this.button.style.transform = 'rotate(180deg)';
      this.button.style.marginLeft = '-11px';
    }
  }

  @Watch('full')
  onFullChange() {
    this.full ? (this.button.style.display = 'none') : (this.button.style.display = 'flex');
  }
}
