
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import api from '@/api';

import { sleep } from '@/lib/util';

import Trip, { TripStatus } from '@/models/Trip';

import AssignResponsibleDialog from '@/components/modals/AssignResponsibleDialog.vue';
import AssignExecutorDialog from '@/components/modals/AssignExecutorDialog.vue';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import RestoreDialog from '@/components/modals/RestoreDialog.vue';

export default Vue.extend({
  name: 'TripSettingsBtn',

  components: {
    AssignResponsibleDialog,
    AssignExecutorDialog,
    DeleteDialog,
    RestoreDialog,
  },

  props: {
    trip: {
      type: Object as () => Trip,
      required: true,
    },
  },

  data: () => ({
    snackbarText: '',
    snackbar: false,
    responsibleDialog: false,
    executorDialog: false,
    deleteDialog: false,
    deleteLoading: false,
    deleteError: null as any,
    restoreDialog: false,
    restoreError: null as any,
    restoreLoading: false,
  }),

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('auth', ['permissions']),

    settings(): Array<any> {
      const result = [];

      if (this.hasPermission('trips update') && this.isTripEditable) {
        if (this.trip.executorId || this.trip.executor) {
          result.push({
            title: this.$t('Assign responsible'),
            onClick: this.assignResponsible,
          });
        } else {
          result.push({
            title: this.$t('Assign executor'),
            onClick: this.assignExecutor,
          });
        }
      }

      if (this.hasPermission('trips delete')) {
        if (this.trip.canDelete || this.trip.canRestore) {
          result.push({
            title: this.trip.isDeleted() ? this.$t('Restore') : this.$t('Delete'),
            onClick: this.trip.isDeleted() ? this.restore : this.delete,
          });
        }
      }

      return result;
    },

    isTripEditable(): boolean {
      return [
        TripStatus.notAssigned,
        TripStatus.planned,
        TripStatus.pending,
        TripStatus.pendingViewed,
        TripStatus.active,
      ].includes(this.trip.status);
    },

    dialogIemName(): string {
      const str = this.trip ? (this.trip.title ? ` ${this.trip.title}` : ` №${this.trip.dbId}`) : '';
      return this.$t('common_strings.trip') + str;
    },
  },

  methods: {
    assignResponsible(): void {
      this.responsibleDialog = true;
    },

    delete(): void {
      this.deleteDialog = true;
    },

    restore(): void {
      this.restoreDialog = true;
    },

    async onDelete(): Promise<void> {
      try {
        this.deleteError = null;

        await api.tripNew.deleteTrips([this.trip.id]);

        this.deleteLoading = true;

        await sleep(2000);

        this.deleteDialog = false;

        this.$emit('update');
      } catch (error) {
        this.deleteError = error;
      } finally {
        this.deleteLoading = false;
      }
    },

    async onRestore(): Promise<void> {
      try {
        this.restoreError = null;

        await api.tripNew.restoreTrips([this.trip.id]);

        this.restoreLoading = true;

        await sleep(2000);

        this.restoreDialog = false;

        this.$emit('update');
      } catch (error) {
        this.restoreError = error;
      } finally {
        this.restoreLoading = false;
      }
    },

    assignExecutor() {
      this.executorDialog = true;
    },

    onResponsibleSelect(): void {
      this.responsibleDialog = false;
      this.snackbarText = String(this.$t('Responsible was appointed'));
      this.snackbar = true;
      this.$emit('update');
    },

    onExecutorSelect() {
      this.executorDialog = false;
      this.snackbarText = String(this.$t('Executor was appointed'));
      this.snackbar = true;
      this.$emit('update');
    },
  },
});
