import http from './httpV2';
const path = '/accounts';

async function changePassword(accountId: string, password: string) {
  const res = await http.post(`${path}/${accountId}/password/change`, {
    password,
  });
  return res.data;
}

async function restorePassword(accountId: string) {
  const res = await http.post(`${path}/${accountId}/password/restore`);
  return res.data;
}

export default {
  changePassword,
  restorePassword,
};
