import Vue from 'vue';
import android from 'android-versions';

export default Vue.extend({
  name: 'MobileDeviceInfo',

  functional: true,

  props: {
    value: {
      type: Object,
    },
  },

  render(h, { data, props, children, parent }) {
    if (!props.value) {
      return;
    }

    const { os, vendor, osVersion, clientVersion, isOutdated } = props.value;

    if (!os || !vendor || !osVersion || !clientVersion) {
      return;
    }

    let osIcon;
    let osDescription = osVersion;

    switch (os) {
      case 'ios':
        osIcon = 'mdi-apple';
        break;
      case 'android':
        osIcon = 'android';

        try {
          const api = parseInt(osVersion.split(' ')[2]);
          const ver = android.get(api);

          if (ver && ver.semver) {
            osDescription = 'Android ' + ver.semver;
          }
        } catch (err) {
          console.error(err);
        }

        break;
    }

    const outdatedVersionWarningIcon = isOutdated
      ? h('v-icon', { props: { small: true, color: 'red darken-2' } }, ['warning'])
      : null;
    const outdatedVersionWarningText = isOutdated ? parent.$t('Client update available') : null;

    return h('v-tooltip', { props: { top: true } }, [
      h('span', { slot: 'activator' }, [
        h('v-icon', { props: { small: true } }, [osIcon]),
        ` ${vendor}, HS${clientVersion}`,
        outdatedVersionWarningIcon,
      ]),
      h('span', [
        `${vendor} (${osDescription})`,
        h('br'),
        `HomeStretch ${clientVersion}`,
        h('br'),
        outdatedVersionWarningText,
      ]),
    ]);
  },
});
