
import Vue from 'vue';
import api from '@/api/restoreNew';
import rules from '@/api/rules';

export default Vue.extend({
  name: 'ChangePasswordForUser',

  data() {
    return {
      showNewPassword: false,
      showDialog: true,
      error: null as Error | null,
      successText: '' as string,
      valid: true,
      newPassword: '',
      repeatPassword: '',
    };
  },

  props: {
    accountId: {
      type: String,
    },
  },

  computed: {
    passwordRules() {
      return rules.password;
    },
  },

  methods: {
    async changePassword() {
      try {
        this.successText = '';
        this.error = null;
        await api.changePassword(this.accountId, this.newPassword);
        this.successText = this.$t('Password changed successfully') as string;
        this.$emit('close', this.successText);
      } catch (e) {
        this.error = e;
      }
    },

    hideModal() {
      this.$emit('close');
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
