<template>
  <div>
    <h1>{{ $t('Privacy Policy') }}</h1>

    <p>
      Настоящий документ «Политика конфиденциальности» (далее по тексту – «Политика») представляет собой правила
      использования Обществом с ограниченной ответственностью «ХоумСтретч Рус» (далее – «Правообладатель») персональной
      информации Пользователя.
    </p>

    <h4>1. Общие положения</h4>
    <p>
      1.1. Настоящая Политика является неотъемлемой частью Пользовательского соглашения (далее – «Соглашение»),
      размещенного и/или доступного в сети Интернет по адресу:
      <a href="https://co.homestretch.ch/policy">https://co.homestretch.ch/policy</a>, а также иных заключаемых с
      Пользователем Договоров, когда это прямо предусмотрено их условиями.
    </p>
    <p>
      1.2. Заключая Соглашение вы свободно, своей волей и в своих интересах  даете письменное согласие на следующие
      способы обработки Персональной информации: сбор, запись, систематизация, хранение, уточнение (обновление,
      изменение), извлечение, использование, передача (предоставление, доступ) третьим лицам, в том числе трансграничная
      передача на территорию иностранных государств, обезличивание, блокирование, удаление, уничтожение в установленных
      настоящей Политикой целях с использованием средств автоматизации или без использования таких средств по усмотрению
      Правообладателя.
    </p>
    <p>
      1.3. К настоящей Политике, включая толкование ее положений и порядок принятия, исполнения, изменения и
      прекращения, подлежит применению законодательство Российской Федерации.
    </p>
    <p>
      1.4. Здесь и далее в Политике используются термины и определения, предусмотренные Соглашением, а также иными
      заключаемыми с Пользователем Договорами, если иное не предусмотрено настоящей Политикой или не вытекает из ее
      существа. В иных случаях толкование применяемого в Политике термина производится в соответствии с действующим
      законодательством Российской Федерации, обычаями делового оборота, или научной доктриной.
    </p>

    <h4>2. Персональная информация</h4>
    <p>2.1. Под персональной информацией в настоящей Политике понимается:</p>
    <p>
      2.1.1. Информация, включая персональные данные Пользователя, которая предоставляется о Пользователе самостоятельно
      либо по его поручению при регистрации/авторизации и дальнейшем использовании Сервиса, а также данные, которые
      передаются в автоматическом режиме в рамках функциональных возможностей Сервиса.
    </p>
    <p>
      2.2. Правообладатель вправе устанавливать требования к составу Персональной информации Пользователя, которая
      должна обязательно предоставляться для использования Сервиса. Если определенная информация не помечена
      Правообладателем как обязательная, ее предоставление или раскрытие осуществляется Пользователем на свое
      усмотрение.
    </p>
    <p>
      2.3. При регистрации Пользователем обязательно указываются фамилия, имя, адрес электронной почты, пароль,
      должность, отдел, наименование организации.
    </p>
    <p>
      Дополнительно с целью заполнения профиля в Сервисе Пользователь может предоставить следующие персональные данные:
      номер телефона, фотографию.
    </p>
    <p>
      Для использования функциональных возможностей Сервиса Пользователь также предоставляет информацию о поездках
      (посещениях): места, которые планирует посетить (координаты на карте), место отправления, место окончания
      маршрута, планируемое количество времени нахождения в той или иной точке.
    </p>
    <p>
      В автоматическом режиме собирается и обрабатывается следующая информация о Пользователе: геолокационная информация
      (местонахождение), данные браузера (тип, версия), данные запроса (время, IP-адрес).
    </p>
    <p>
      2.4. Правообладатель не осуществляет проверку достоверности предоставляемой Персональной информации и наличия у
      Пользователя необходимого согласия на ее обработку в соответствии с настоящей Политикой, полагая, что Пользователь
      действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в
      актуальном состоянии и получению всех необходимых согласий субъектов персональных данных.
    </p>
    <p>
      2.5. Правообладатель не несет ответственность за порядок использования Персональной информации Пользователя
      третьими лицами, с которыми Пользователь самостоятельно взаимодействует в рамках использования Сервиса.
    </p>

    <h4>3. Цели обработки Персональной информации</h4>
    <p>
      3.1. Правообладатель осуществляет обработку, в том числе сбор и хранение только той Персональной информации,
      которая необходима для заключения и исполнения Соглашения и иных договоров с Пользователями.
    </p>
    <p>3.2. Правообладатель и/или Пользователи вправе использовать Персональную информацию в следующих целях:</p>
    <p>
      3.2.1. Заключения Соглашения на использование Сервиса, а также договоров о возмездном оказании информационных
      услуг доступа к Сервису (Договоров).
    </p>
    <p>
      Заключение Соглашения и Договоров осуществляется в дистанционном порядке посредством обмена электронными
      документами, которые подписываются простой электронной подписью. При этом роль ключа простой электронной подписи
      Пользователя могут выполнять пары логин – пароль или адрес электронной почты – пароль. Поэтому эти данные
      запрашиваются при регистрации.
    </p>
    <p>
      3.2.2. Идентификация Пользователя в рамках исполнения обязательств по Соглашению и иным заключенным с ним
      Договорам.
    </p>
    <p>Личный кабинет Пользователя связан с адресом электронной почты Пользователя.</p>
    <p>
      3.2.3. Исполнение обязательств по Соглашению и иным заключенным Договорам, включая предоставление Пользователю
      доступа к Сервису и технической поддержки, использование Пользователем функциональных возможностей Сервиса.
    </p>
    <p>
      В целях реализации соответствующих функциональных возможностей Сервиса, связанных с контролем активности
      сотрудников, действующих вне офиса, Правообладатель хранит, систематизирует и отображает в Сервисе Персональную
      информацию Пользователей, включая данные, собираемые в автоматическом режиме.
    </p>
    <p>
      Доступ к функциональным возможностям Сервиса предоставляется с использованием программных средств Сайта, в т.ч.
      Личного кабинета. Обращения в службу поддержки принимаются через формы на Сайте или с использованием электронной
      почты Пользователя.
    </p>
    <p>
      3.2.4. Нотификация в рамках информационного обслуживания и улучшения качества Сервиса по Соглашению и иным
      заключенным Договорам, в том числе с привлечением третьих лиц.
    </p>
    <p>Для этого при регистрации запрашивается адрес электронной почты Пользователя и абонентский номер телефона.</p>
    <p>
      3.2.5. Проведение статистических и иных исследований на основе обезличенных данных в целях улучшения качества
      предоставляемого Сервиса.
    </p>

    <h4>4. Требования к защите Персональной информации</h4>
    <p>
      4.1. Правообладатель осуществляет хранение Персональной информации и обеспечивает ее охрану от
      несанкционированного доступа и распространения в соответствии с внутренними правилами и регламентами.
    </p>
    <p>
      4.2. В отношении Персональной информации Пользователя сохраняется ее конфиденциальность, за исключением случаев,
      когда технология предоставляемого Сервиса или настройки используемого Пользователем программного обеспечения
      предусматривают предоставление информации иным Пользователями Сервиса.
    </p>
    <p>
      4.3. В целях повышения качества Сервиса, Правообладатель вправе хранить лог-файлы о действиях, совершенных
      Пользователем в рамках использования Сервиса, а также в связи с заключением и исполнением Пользователем Соглашения
      и иных Договоров со своей стороны, в течение 1 (Одного) года.
    </p>
    <p>
      4.4. Пользователи, получившие доступ к персональным данным других Пользователей или третьих лиц в связи с
      использованием Сервиса обязаны соблюдать положения п.4.1. и 4.2. настоящей Политики, а также осуществлять
      обработку полученных персональных данных в соответствии с настоящей Политикой и действующим законодательством.
    </p>

    <h4>5. Передача информации</h4>
    <p>5.1. Правообладатель вправе передать Персональную информацию третьим лицам в следующих случаях:</p>
    <p>
      Пользователь выразил свое согласие на такие действия, включая случаи применения Пользователем настроек
      используемого программного обеспечения, не ограничивающих предоставление определенной информации;
    </p>
    <p>Передача необходима в рамках использования Пользователем функциональных возможностей Сервиса;</p>
    <p>Передача требуется для заключения и исполнения Договоров с использованием Сервиса;</p>
    <p>
      В связи с передачей Сайта во владение, пользование или собственность такого третьего лица, или уступкой прав по
      заключенным с Пользователем договорам в пользу третьего лица;
    </p>
    <p>
      По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством
      процедуры;
    </p>
    <p>
      Для защиты прав и законных интересов Правообладателя в связи с нарушением заключенных с Пользователем Договоров.
    </p>

    <h4>6. Изменение Персональной информации</h4>
    <p>
      6.1. Пользователь вправе в любой момент самостоятельно отредактировать в своем Личном кабинете предоставленную им
      Персональную информацию, за исключением информации, обязательной для регистрации Пользователя.
    </p>
    <p>
      6.2. Изменение информации, обязательной для регистрации Пользователя, а также удаление Личного кабинета
      Пользователя в случае прекращения Соглашения, производится путем направления соответствующего обращения в службу
      поддержки Правообладателя по адресу электронной почты:
      <a href="email:info@homestretch.ch">info@homestretch.ch</a> или Пользователю, имеющему соответствующие права в
      рамках функциональных возможностей Сервиса.
    </p>
    <h4>7. Изменение Политики конфиденциальности</h4>
    <p>
      7.1. Настоящая Политика может быть изменена или прекращена Правообладателем в одностороннем порядке без
      предварительного уведомления Пользователя. Новая редакция Политики вступает в силу с момента ее размещения на
      Сайте, если иное не предусмотрено новой редакцией Политики.
    </p>
    <p>
      7.2. Действующая редакция Политики находится на Сайте в сети Интернет по адресу:
      <a href="https://co.homestretch.ch/policy">https://co.homestretch.ch/policy</a>.
    </p>
    <p>7.3. Реквизиты Правообладателя:</p>
    <p>
      <i>
        ООО «ХоумСтретч Рус» <br />
        <br />
        ОГРН: 1187746678778; ИНН: 7734414304; <br />
        Адрес: Россия, Москва г., ул. Кулакова, д. 20, стр. 1А, пом. XI, этаж 2, ком. 47; <br />
        e-mail: <a href="email:info@homestretch.ch">info@homestretch.ch</a> <br />
      </i>
    </p>
    <p>
      Действующая редакция Политики от 01 октября 2018 г.
    </p>
  </div>
</template>

<script>
/* eslint no-irregular-whitespace: 0 */
export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Privacy Policy'),
    };
  },
};
</script>
