<template>
  <v-layout column>
    <TripsMonitoringFilter :form="filter" @filterUpdate="filterUpdate" @reload="fetchData" />

    <MapFullPageWidget @boundsChanged="boundsChanged" ref="map" :loading="loading">
      <div v-if="monitoringData.features">
        <TripMonitorInfo :monitoringData="monitoringData" />
        <TripMonitor :data="monitoringData" />
      </div>
    </MapFullPageWidget>
  </v-layout>
</template>

<script>
import { isEqual } from 'lodash';

import branch from '@/api/branch';
import tripApi from '@/api/tripNew';

import TripsMonitoringFilter from '@/components/filters/TripsMonitoringFilter';
import TripsFilterDto from '@/components/filters/TripsFilterDto';
import MapFullPageWidget from '@/components/map/MapFullPageWidgetNew';
import TripMonitorInfo from '@/components/map/info/TripMonitorInfo.vue';
import TripMonitor from '@/components/map/TripMonitor';

import { AUTORELOAD_TIME } from '@/const';

export default {
  name: 'TripsMonitoring',

  metaInfo() {
    return {
      title: this.$i18n.t('Trips'),
      fillHeight: true,
      class: 'trips-monitoring',
    };
  },

  components: {
    TripsMonitoringFilter,
    MapFullPageWidget,
    TripMonitorInfo,
    TripMonitor,
  },

  data() {
    return {
      monitoringData: {},
      filter: new TripsFilterDto(),
      timer: null,
      updatedPosition: false,
      boundsChangedTimer: null,
      loading: false,
    };
  },

  async created() {
    this.parseURL();

    await this.fetchData();
    await branch.listMy();

    this.timer = setTimeout(this.fetchData, AUTORELOAD_TIME);
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.boundsChangedTimer) {
      clearTimeout(this.boundsChangedTimer);
      this.boundsChangedTimer = null;
    }
  },

  methods: {
    parseURL() {
      if (Object.keys(this.$route.query).length) {
        this.filterUpdate(new TripsFilterDto().fromQuery(this.$route.query));
      } else if (![null, undefined, ''].includes(localStorage.getItem('filterMonitoring'))) {
        this.filterUpdate(new TripsFilterDto(JSON.parse(localStorage.getItem('filterMonitoring') || '')));
      }
    },

    async fetchData() {
      try {
        this.loading = true;

        const params = this.filter.toHttpParams();

        this.monitoringData = await tripApi.getMonitoringData({
          filter: {
            accounts: params['accounts'],
            branches: params['branches'],
            status: params['status'],
          },
        });
      } catch (err) {
        console.error(err);
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    filterUpdate(value) {
      this.filter = value;
      localStorage.setItem('filterMonitoring', JSON.stringify(value));

      if (!isEqual(this.$route.query, this.filter.toQueryForCheck())) {
        this.$router.replace({ name: 'tripsMonitoring', query: this.filter.toQuery() });
      }

      this.fetchData();
    },

    boundsChanged() {
      if (this.updatedPosition) {
        return;
      }

      this.updatedPosition = true;

      if (this.boundsChangedTimer) {
        clearTimeout(this.boundsChangedTimer);
      }

      this.boundsChangedTimer = setTimeout(() => {
        this.updatedPosition = false;
      }, AUTORELOAD_TIME);
    },
  },
};
</script>

<style>
.trips-monitoring #container {
  padding: 8px;
}
</style>
