import { Dictionary } from 'vue-router/types/router';
import http from './httpV2';

const path = '/demands';

async function assignTags(data: Dictionary<string[]>): Promise<undefined> {
  const res = await http.post(`${path}/tags/assign`, data);
  return;
}

export default {
  assignTags,
};
