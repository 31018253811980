import http from './httpV2';

const path = '/devices';

async function getTripByDeviceId(deviceId: string): Promise<number> {
  const { data } = await http.get(`${path}/${deviceId}/trip`);
  return data.dbId;
}

export default {
  getTripByDeviceId,
};
