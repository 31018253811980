import Vue from 'vue';
import { TripMode } from '@/models/Trip';

import AddPoint from './AddPoint';
import ChangePoint from './ChangePoint';
import DeletePoint from './DeletePoint';
import ReorderPoint from './ReorderPoint';
import CheckoutPoint from './CheckoutPoint';
import ChangeFinish from './ChangeFinish';
import ChangeDroplist from './ChangeDroplist';
import ChangeChecklist from './ChangeChecklist';
import AccessDenied from './AccessDenied';
import LoginEvent from './LoginEvent';
import CancelTrip from './CancelTrip';
import FinishTrip from './FinishTrip.vue';
import TripChangeEvent from './TripChangeEvent';
import CompleteWorkOnPoint from './CompleteWorkOnPoint';
import changeInDateCheckin from './changeInDateCheckin';
import changeOutDateCheckin from './changeOutDateCheckin';
import { CheckinStatus } from '@/models/TripPoint';

export default Vue.extend({
  name: 'TripEventItem',

  functional: true,

  render(h, { parent, props }) {
    const value = props.value;
    const executor = props.executor;
    const tripMode = props.tripMode;
    if (!value) {
      return;
    }
    // const trip = props.trip;
    switch (value.type) {
      case 'addPoint':
        return <AddPoint value={value} />;
      case 'deletePoint':
        return <DeletePoint value={value} />;
      case 'changePoint':
        return <ChangePoint value={value} />;
      case 'reorderPoint':
        return <ReorderPoint value={value} />;
      case 'checkoutPoint':
        if (tripMode === TripMode.manual && !(value.checkin && value.checkin.status === CheckinStatus.marked)) {
          return <CompleteWorkOnPoint value={value} />;
        } else {
          return <CheckoutPoint value={value} />;
        }
      case 'changeFinish':
        return <ChangeFinish value={value} />;
      case 'changeDroplist':
        return <ChangeDroplist value={value} />;
      case 'changeChecklist':
        return <ChangeChecklist value={value} />;
      case 'accessDenied':
        return <AccessDenied value={value} />;
      case 'login':
        return <LoginEvent value={value} />;
      case 'tripCancel':
        return <CancelTrip value={value} />;
      case 'tripFinish':
        return <FinishTrip value={value} />;
      case 'tripChange':
        return <TripChangeEvent value={value} executor={executor} />;
      case 'changeInDateCheckin':
        return <changeInDateCheckin value={value} />;
      case 'changeOutDateCheckin':
        return <changeOutDateCheckin value={value} />;
      default:
        return <div>{props.value.type}</div>;
    }
  },
});
