import Uuid from '@/models/Uuid';

export interface RoleDto {
  id: Uuid;
  companyId: Uuid;
  name: string;
  rules: Array<string>;
  createdBy: Uuid;
  updatedBy: Uuid;
  createdAt: string;
  updatedAt: string;
}

export class Role {
  id: Uuid;
  companyId: Uuid;
  name: string;
  rules: Array<string>;
  createdBy: Uuid;
  updatedBy: Uuid;
  createdAt: string;
  updatedAt: string;

  constructor(role: RoleDto) {
    this.id = role.id;
    this.companyId = role.companyId;
    this.name = role.name;
    this.rules = role.rules;
    this.createdBy = role.createdBy;
    this.updatedBy = role.updatedBy;
    this.createdAt = role.createdAt;
    this.updatedAt = role.updatedAt;
  }
}
