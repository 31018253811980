import uuid from 'uuid/v4';
import Uuid from './Uuid';
import TripPoint, { TripPointDto, BatteryInfoDto, BatteryInfo, getIntervalDuration } from './TripPoint';
import { AssignedShiftsDto } from './Shift';
import WayPoint, { WayPointDto } from './WayPoint';
import { ActiveRoute, WayCoordinates } from './Way';
import { getLocalTimezoneInSeconds } from '@/lib/timezone';
import Account, { AccountDto } from '@/models/Account';
import { Transport, TransportType } from '@/models/Transport';
import { isSameDay } from '@/lib/date';
import { PointCoordinates, Timezone } from './Location';

export enum TripStatus {
  active = 'active',
  finished = 'finished',
  cancelled = 'cancelled',
  manuallyFinished = 'manuallyFinished',
  pending = 'pending',
  pendingViewed = 'pendingViewed',
  operatorCancelled = 'operatorCancelled',
  planned = 'planned',
  draft = 'draft',
  notAssigned = 'notAssigned',
  deleted = 'deleted',
}

export enum TripMode {
  auto = 'auto',
  manual = 'manual',
  legacy = 'legacy',
}

export interface MobileDeviceInfo {
  os: string | null;
  locale: string | null;
  vendor: string | null;
  osVersion: string | null;
  clientVersion: string | null;
  phoneNumber: string | null;
  isOutdated: boolean;
}

interface SummaryIndicatorsDto {
  duration: number | null;
  scheduledCount: number;
  downtimeCount: number;
  totalAtClients: number | null;
  totalAtNonClients: number | null;
  totalInTransit: number | null;
  passedWayDistance: number | null;
  passedRawWayDistance: number | null;
  activeWayDistance: number | null;
  startTimezone: Timezone | null;
  finishTimezone: Timezone | null;
  stayTimeExceededCount: number | null; // число простоев в точке
  workTimeExceededCount: number | null; // число простоев по работам
  missedDeliveryWindowCount: number | null; // число опозданий
  eventsCount: number | null; // число изменений
}

export interface TripSettings {
  permissions?: TripSettingsPermissions | null;
  transportType?: TransportType;
}

export interface TripSettingsPermissions {
  addPoint?: boolean | null;
  deletePoint?: boolean | null;
  optimize?: boolean | null;
  canChangeTransportType?: boolean | null;
  changeOrderPointsInTrip?: boolean | null;
  changeTimeAtPoint?: boolean | null;
  addToFavorite?: boolean | null;
  deleteFromFavorite?: boolean | null;
  movePoint?: boolean | null;
}

export interface TripInstanceDto {
  id: Uuid;
  dbId: number | null;
  title: string | null;
  status: TripStatus;
  cancelReason: string | null;
  hasChanges: Boolean;
  tripMode: TripMode;
  executor: AccountDto | null;
  executorId: Uuid | null;
  responsible: AccountDto | null;
  creatorId: Uuid | null;
  responsibleId: Uuid | null;
  transportId: Uuid | null;
  tripPoints?: TripPointDto[];
  way?: { activeWay: Partial<ActiveRoute> | null; passedWay: WayCoordinates[]; optimize: boolean };
  wayPoints?: WayPointDto[];
  summaryIndicators?: SummaryIndicatorsDto;
  mobileDeviceInfo: MobileDeviceInfo | null;
  batteryInfo: BatteryInfoDto;
  syncAt: string | null;
  plannedAt: string | null;
  createdAt: string;
  updatedAt: string;
  startedAt: string | null;
  finishedAt: string | null;
  finishPlanAt: string | null;
  transport: Transport | null;
  settings: TripSettings | null;
  assignedShifts?: AssignedShiftsDto[];
  isLostSync: boolean;
}

interface TripPermissionsDto {
  canCreate?: boolean | null;
  canUpdate?: boolean | null;
  canDelete?: boolean | null;
  canRestore?: boolean | null;
  canCancel?: boolean | null;
  canFinish?: boolean | null;
  canChat?: boolean | null;
}

export interface SummaryIndicators {
  /** Длительность */
  duration: number | null;

  /** Число клиентов */
  scheduledCount: number;

  /** Число простоев */
  downtimeCount: number;

  /** Общее время у клиентов */
  totalAtClients: number | null;

  /** Общее время на стоянках */
  totalAtNonClients: number | null;

  /** Общее время в пути */
  totalInTransit: number | null;

  /** Пройденное расстояние */
  passedWayDistance: number | null;

  /** Пройденное расстоение по сырым координатам */
  passedRawWayDistance: number | null;

  /** Оставшееся расстояние активного маршрута */
  activeWayDistance: number | null;

  /** Таймзона старта */
  startTimezone: Timezone | null;

  /** Таймзона финига */
  finishTimezone: Timezone | null;

  /** Число простоев в точке */
  stayTimeExceededCount: number;

  /** Число простоев по работам */
  workTimeExceededCount: number;

  /** Число опозданий */
  missedDeliveryWindowCount: number;

  /** Число изменений */
  eventsCount: number;
}

export type TripDto = TripInstanceDto & TripPermissionsDto;

class Trip {
  /** Id поездки */
  id: Uuid;

  /** Id поездки из базы данных */
  dbId: number | null = null;

  /** Заголовок поездки */
  title: string | null = null;

  /** Статус поездки */
  status: TripStatus;

  /** Причина отмены поездки */
  cancelReason: string | null = null;

  /** Были ли изменения в поездке */
  hasChanges: Boolean = false;

  /** Режим поездки (авто/ручной) */
  tripMode: TripMode;

  /** Id исполнителя */
  executorId: Uuid | null = null;

  /** Исполнитель */
  executor: Account | null = null;

  /** Id создателя */
  creatorId: Uuid | null = null;

  /** Id ответственного */
  responsibleId: Uuid | null = null;

  /** Id транспорта */
  transportId: Uuid | null = null;

  /** Ответсвенный */
  responsible: Account | null = null;

  /** Список трип пойнтов */
  tripPoints: TripPoint[] = [];

  /** Маршрут */
  way: { activeWay: ActiveRoute | null; passedWay: WayCoordinates[]; optimize: boolean };

  /** Список вей пойнтов */
  wayPoints: WayPoint[] = [];

  /** Итоговоая информация о поездке */
  summaryIndicators: SummaryIndicators;

  /** Информация о мобильном устройстве */
  mobileDeviceInfo: MobileDeviceInfo;

  /** Информация о батарее */
  batteryInfo: BatteryInfo;

  /** Дата синхронизации */
  syncAt: Date | null = null;

  /** Запланированная дата */
  plannedAt: Date | null = null;

  /** Дата создания */
  createdAt: Date;

  /** Дата обновления */
  updatedAt: Date;

  /** Дата начала поездки */
  startedAt: Date | null = null;

  /** Дата окончания поездки */
  finishedAt: Date | null = null;

  /** Планируемое оканчание поездки */
  finishPlanAt: Date | null;

  /** Можно создать новую поздку */
  canCreate: boolean = true;

  /** Можно обновить новую поздку */
  canUpdate: boolean = true;

  /** Можно удалить поздку */
  canDelete: boolean = true;

  /** Можно восстановить поздку */
  canRestore: boolean = true;

  /** Есть ли право на отмену поездки */
  canCancel: boolean = false;

  /** Есть ли право на отмену поездки */
  canFinish: boolean = false;

  /** Можно ли писать в чат */
  canChat: boolean = false;

  /** Потеряна ли синхронизация */
  isLostSync: boolean = false;

  transport: Transport | null;

  settings: TripSettings;

  assignedShifts: AssignedShiftsDto[] | [];

  constructor(data?: TripDto) {
    if (!data) {
      this.id = uuid();
      this.dbId = null;
      this.status = TripStatus.notAssigned;
      this.hasChanges = false;
      this.tripMode = TripMode.auto;
      this.createdAt = new Date();
      this.updatedAt = new Date();
      this.finishPlanAt = null;
      this.way = {
        activeWay: null,
        passedWay: [],
        optimize: false,
      };
      this.summaryIndicators = {
        duration: null,
        scheduledCount: 0,
        downtimeCount: 0,
        totalAtClients: null,
        totalAtNonClients: null,
        totalInTransit: null,
        passedWayDistance: null,
        passedRawWayDistance: null,
        activeWayDistance: null,
        startTimezone: null,
        finishTimezone: null,
        stayTimeExceededCount: 0,
        workTimeExceededCount: 0,
        missedDeliveryWindowCount: 0,
        eventsCount: 0,
      };
      this.mobileDeviceInfo = {
        os: null,
        locale: null,
        vendor: null,
        osVersion: null,
        clientVersion: null,
        phoneNumber: null,
        isOutdated: false,
      };
      this.batteryInfo = new BatteryInfo();
      this.transport = null;
      this.settings = {};
      this.settings.transportType = TransportType.car;
      this.assignedShifts = [];
      this.isLostSync = false;
      this.canCreate = false;
      this.canUpdate = false;
      this.canDelete = false;
      this.canRestore = false;
      this.canCancel = false;
      this.canFinish = false;
      this.canChat = false;
      return;
    }

    this.id = data.id;
    this.dbId = data.dbId;
    this.title = data.title;
    this.status = data.status;
    this.cancelReason = data.cancelReason;
    this.hasChanges = data.hasChanges;
    this.tripMode = data.tripMode;
    this.executorId = data.executorId;
    this.executor = data.executor ? new Account(data.executor) : null;
    this.creatorId = data.creatorId;
    this.responsibleId = data.responsibleId;
    this.transportId = data.transportId;
    this.responsible = data.responsible ? new Account(data.responsible) : null;

    this.way = data.way
      ? {
          activeWay: null,
          passedWay: data.way.passedWay || [],
          optimize: data.way.optimize,
        }
      : {
          activeWay: null,
          passedWay: [],
          optimize: false,
        };

    let activeWay = data.way ? data.way.activeWay : null;

    this.way.activeWay = activeWay
      ? {
          legs: activeWay.legs || [],
          summary: activeWay.summary || {
            travelTime: 0,
            length: 0,
          },
        }
      : null;

    this.summaryIndicators = {
      duration: (data.summaryIndicators && data.summaryIndicators.duration) || null,
      scheduledCount: (data.summaryIndicators && data.summaryIndicators.scheduledCount) || 0,
      downtimeCount: (data.summaryIndicators && data.summaryIndicators.downtimeCount) || 0,
      totalAtClients: (data.summaryIndicators && data.summaryIndicators.totalAtClients) || null,
      totalAtNonClients: (data.summaryIndicators && data.summaryIndicators.totalAtNonClients) || null,
      totalInTransit: (data.summaryIndicators && data.summaryIndicators.totalInTransit) || null,
      startTimezone: (data.summaryIndicators && data.summaryIndicators.startTimezone) || null,
      finishTimezone: (data.summaryIndicators && data.summaryIndicators.finishTimezone) || null,
      passedWayDistance:
        data.summaryIndicators &&
        data.summaryIndicators.passedWayDistance !== null &&
        data.summaryIndicators.passedWayDistance !== undefined
          ? data.summaryIndicators.passedWayDistance
          : null,
      passedRawWayDistance:
        data.summaryIndicators &&
        data.summaryIndicators.passedRawWayDistance !== null &&
        data.summaryIndicators.passedRawWayDistance !== undefined
          ? data.summaryIndicators.passedRawWayDistance
          : null,
      activeWayDistance:
        data.summaryIndicators &&
        data.summaryIndicators.activeWayDistance !== null &&
        data.summaryIndicators.activeWayDistance !== undefined
          ? data.summaryIndicators.activeWayDistance
          : null,
      stayTimeExceededCount: (data.summaryIndicators && data.summaryIndicators.stayTimeExceededCount) || 0,
      workTimeExceededCount: (data.summaryIndicators && data.summaryIndicators.workTimeExceededCount) || 0,
      missedDeliveryWindowCount: (data.summaryIndicators && data.summaryIndicators.missedDeliveryWindowCount) || 0,
      eventsCount: (data.summaryIndicators && data.summaryIndicators.eventsCount) || 0,
    };
    this.mobileDeviceInfo = data.mobileDeviceInfo
      ? data.mobileDeviceInfo
      : {
          os: null,
          locale: null,
          vendor: null,
          osVersion: null,
          clientVersion: null,
          phoneNumber: null,
          isOutdated: false,
        };
    this.batteryInfo = new BatteryInfo(data.batteryInfo);
    this.syncAt = data.syncAt ? new Date(data.syncAt) : null;
    this.plannedAt = data.plannedAt ? new Date(data.plannedAt) : null;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.startedAt = data.startedAt ? new Date(data.startedAt) : null;
    this.finishedAt = data.finishedAt ? new Date(data.finishedAt) : null;
    this.finishPlanAt = data.finishPlanAt ? new Date(data.finishPlanAt) : null;

    this.canCreate = Boolean(data.canCreate);
    this.canUpdate = Boolean(data.canUpdate);
    this.canDelete = Boolean(data.canDelete);
    this.canRestore = Boolean(data.canRestore);
    this.canCancel = Boolean(data.canCancel);
    this.canFinish = Boolean(data.canFinish);
    this.canChat = Boolean(data.canChat);

    this.wayPoints = data.wayPoints ? data.wayPoints.map(wayPoint => new WayPoint(wayPoint)) : [];
    this.tripPoints = data.tripPoints
      ? data.tripPoints.map(tripPoint => {
          tripPoint.transport = data.transport;
          return new TripPoint(tripPoint);
        })
      : [];

    this.transport = data.transport;

    this.settings = { ...data.settings } || {};
    this.settings.transportType = data.settings?.transportType || TransportType.car;
    this.assignedShifts = data.assignedShifts || [];

    this.isLostSync = data.isLostSync;
  }

  isActive(): boolean {
    return this.status === TripStatus.active;
  }

  isPlanned(): boolean {
    return this.status === TripStatus.planned;
  }

  isNotAssigned(): boolean {
    return this.status === TripStatus.notAssigned;
  }

  isPending(): boolean {
    return [TripStatus.pending, TripStatus.pendingViewed].includes(this.status);
  }

  isPendingOrPlanned(): boolean {
    return [TripStatus.notAssigned, TripStatus.planned, TripStatus.pending, TripStatus.pendingViewed].includes(
      this.status,
    );
  }

  isAuto(): boolean {
    return this.tripMode === TripMode.auto;
  }

  isManual(): boolean {
    return this.tripMode === TripMode.manual;
  }

  isStarted(): boolean {
    return (
      this.startedAt !== null &&
      [
        TripStatus.active,
        TripStatus.finished,
        TripStatus.cancelled,
        TripStatus.manuallyFinished,
        TripStatus.operatorCancelled,
      ].includes(this.status)
    );
  }

  isEnded(): boolean {
    return [
      TripStatus.finished,
      TripStatus.cancelled,
      TripStatus.manuallyFinished,
      TripStatus.operatorCancelled,
    ].includes(this.status);
  }

  isDeleted(): boolean {
    return this.status === TripStatus.deleted;
  }

  getFinish() {
    return this.tripPoints.find(tripPoint => tripPoint.isFinish() && !tripPoint.isDeleted());
  }

  getCurrentTripPoints() {
    return this.tripPoints.filter(tripPoint => tripPoint.isCurrent());
  }

  getFirstActiveTripPoint() {
    return this.tripPoints.find(tripPoint => tripPoint.isActive());
  }

  isInPlan(tripPoint: TripPoint) {
    return !this.startedAt || tripPoint.createdAt < this.startedAt;
  }

  getCurrentPosition(): PointCoordinates | null {
    if (this.way.passedWay.length) {
      return this.way.passedWay[this.way.passedWay.length - 1];
    }
    return null;
  }

  getTripPointsById(): Map<Uuid, TripPoint> {
    const result = new Map<Uuid, TripPoint>();
    this.tripPoints.forEach((tripPoint, idx) => {
      result.set(tripPoint.id, tripPoint);
    });
    return result;
  }

  getTripPointsByWayPointId(): Map<Uuid, TripPoint[]> {
    const result = new Map<Uuid, TripPoint[]>();
    const tripPointsById = this.getTripPointsById();
    this.wayPoints.forEach((wayPoint, idx) => {
      const tripPoints: TripPoint[] = [];
      wayPoint.tripPointIds.forEach(tripPointId => {
        const tripPoint = tripPointsById.get(tripPointId);
        if (tripPoint) {
          tripPoints.push(tripPoint);
        }
      });
      result.set(wayPoint.id, tripPoints);
    });
    return result;
  }

  getWayPointsByTripPointId(): Map<Uuid, WayPoint> {
    const wayPointsByTripPointId = new Map<Uuid, WayPoint>();
    this.wayPoints.forEach(wayPoint => {
      wayPoint.tripPointIds.forEach(tripPointId => {
        wayPointsByTripPointId.set(tripPointId, wayPoint);
      });
    });
    return wayPointsByTripPointId;
  }

  getDuration() {
    return getIntervalDuration({
      startDate: this.startedAt,
      finishDate: this.finishedAt,
    });
  }

  getTripStartDate() {
    return this.startedAt || this.plannedAt || this.createdAt;
  }

  containsDifferentTimezones() {
    if (!this.tripPoints.length) {
      return false;
    }

    const tzOffsets = [
      ...(this.summaryIndicators.startTimezone ? [this.summaryIndicators.startTimezone.offset] : []),
      ...(this.summaryIndicators.finishTimezone ? [this.summaryIndicators.finishTimezone.offset] : []),
      getLocalTimezoneInSeconds(),
    ];

    this.tripPoints.forEach(tripPoint => {
      if (!tripPoint.isDeleted()) {
        tzOffsets.push(tripPoint.location.timezone.offset);
      }
    });

    return tzOffsets.some((val, i, arr) => val !== arr[0]);
  }

  containsDifferentDates() {
    let containsDiffDates = false;

    const startDate = this.startedAt || this.createdAt;

    const finishDate = this.finishedAt || this.finishPlanAt || this.syncAt || new Date();

    this.tripPoints.forEach(tripPoint => {
      if (tripPoint.deliveryWindows?.length) {
        let lastDeliveryWindow = tripPoint.deliveryWindows[tripPoint.deliveryWindows.length - 1];

        if (!isSameDay(lastDeliveryWindow.to, startDate)) {
          containsDiffDates = true;
        }
      }
    });

    return !isSameDay(startDate, finishDate) || !isSameDay(startDate, new Date()) || containsDiffDates;
  }

  startTimezone() {
    if (!this.tripPoints.length) {
      return null;
    }
    return this.tripPoints[0].location.timezone;
  }

  finishTimezone() {
    if (!this.tripPoints.length) {
      return null;
    }

    let finishTimezone: Timezone | undefined = undefined;
    this.tripPoints.forEach(tripPoint => {
      if (!finishTimezone && (tripPoint.isActualFinish() || tripPoint.isFinish())) {
        finishTimezone = tripPoint.location.timezone;
      }
    });

    if (!finishTimezone) {
      finishTimezone = this.tripPoints[this.tripPoints.length - 1].location.timezone;
    }

    return finishTimezone;
  }

  getDistributionTripErrors(): string[] {
    const errors = [];

    if (
      [TripStatus.finished, TripStatus.manuallyFinished, TripStatus.cancelled, TripStatus.operatorCancelled].includes(
        this.status,
      )
    ) {
      errors.push('TripIsNotActual');
    }

    if (!this.assignedShifts.length) {
      errors.push('MissingAssignedShifts');
    }

    if (!this.transport || this.transport.isDeleted) {
      errors.push('MissingTransport');
    }

    return errors;
  }

  get capacityOrders() {
    const ordersMap = this.tripPoints?.reduce((ordersMap, tripPoint) => {
      if (Array.isArray(tripPoint.orders)) {
        for (const order of tripPoint.orders) {
          ordersMap.set(order.id, order);
        }
      }

      return ordersMap;
    }, new Map());

    let mass = 0;
    let volume = 0;
    if (ordersMap.size) {
      for (const order of ordersMap.values()) {
        if (Array.isArray(order.cargos)) {
          for (const cargo of order.cargos) {
            if (typeof cargo.capacity?.mass === 'number') {
              mass += cargo.capacity.mass;
            }

            if (typeof cargo.capacity?.volume === 'number') {
              volume += cargo.capacity.volume;
            }
          }
        }
      }
    }

    return { mass: mass.toFixed(2), volume: volume.toFixed(2) };
  }

  toJSON(): TripDto {
    return {
      id: this.id,
      dbId: this.dbId,
      title: this.title,
      status: this.status,
      cancelReason: this.cancelReason,
      hasChanges: this.hasChanges,
      tripMode: this.tripMode,
      executorId: this.executorId,
      executor: (this.executor && this.executor.toJSON()) || null,
      creatorId: this.creatorId,
      responsibleId: this.responsibleId,
      transportId: this.transportId,
      responsible: (this.responsible && this.responsible.toJSON()) || null,
      tripPoints: this.tripPoints.map(tripPoint => tripPoint.toJSON()),
      way: this.way,
      wayPoints: this.wayPoints.map(wayPoint => wayPoint.toJSON()),
      syncAt: this.syncAt && this.syncAt.toISOString(),
      plannedAt: this.plannedAt && this.plannedAt.toISOString(),
      startedAt: this.startedAt && this.startedAt.toISOString(),
      finishedAt: this.finishedAt && this.finishedAt.toISOString(),
      finishPlanAt: this.finishPlanAt && this.finishPlanAt.toISOString(),
      summaryIndicators: this.summaryIndicators,
      mobileDeviceInfo: this.mobileDeviceInfo,
      batteryInfo: this.batteryInfo,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      canCancel: this.canCancel,
      canFinish: this.canFinish,
      transport: this.transport,
      settings: this.settings,
      assignedShifts: this.assignedShifts,
      isLostSync: this.isLostSync,
    };
  }
}

export default Trip;
