<template>
  <v-layout column>
    <v-flex pb-1>
      <v-layout row>
        <v-flex xs6>
          <h1>{{ $t('Company structure') }}</h1>
        </v-flex>

        <v-spacer />

        <v-btn v-if="can_create" color="primary" v-onboarding.left="$t('helpText.branchAdd')" @click.native="handleAdd">
          <v-icon left>
            add
          </v-icon>

          {{ $t('Add department') }}
        </v-btn>
      </v-layout>
    </v-flex>

    <v-flex xs12>
      <v-layout row>
        <v-flex xs12>
          <OrgChart
            :data="data"
            node-content="name"
            :create-node="createNode"
            :select-node="selectNode"
            :unselect-node="unselectNode"
            :vertical-depth="3"
          >
            <template slot="node" slot-scope="props">
              {{ props.title }}
            </template>
          </OrgChart>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import Vue from 'vue';
import i18n from '@/i18n';
import router from '@/router';

import OrgChart from '@/components/OrgChart';
import CompanyNodeDef from './CompanyNode';
import api from '@/api/branch';

const CompanyNode = Vue.component('CompanyNode', CompanyNodeDef);

export default {
  name: 'Company',

  metaInfo() {
    return {
      title: this.$i18n.t('Company'),
      fillHeight: true,
    };
  },

  components: { OrgChart },

  data() {
    return {
      data: {},
      can_create: false,
    };
  },

  created() {
    this.nodes = [];
    this.fetchData();
  },

  beforeDestroy() {
    for (const n of this.nodes) {
      n.$destroy();
    }
    this.nodes = [];
  },

  methods: {
    async fetchData() {
      const data = await api.list();

      const walk = node => {
        if (node.children && node.children.length > 0) {
          for (const child of node.children) {
            walk(child);
          }
        } else {
          delete node.children;
        }
      };

      walk(data);

      this.data = data;
      this.can_create = data.can_create;
    },

    createNode(node, data) {
      const n = new CompanyNode({
        i18n,
        router,
        propsData: data,
      }).$mount(node.children[1]);
      node.comp = n;
      this.nodes.push(n);
    },

    unselectNode(node) {
      node.comp.value = false;
    },

    selectNode(node, prev) {
      node.comp.value = true;
    },

    handleAdd() {
      this.$router.push('/branches/new');
    },
  },
};
</script>
