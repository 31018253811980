import httpV2 from './httpV2';
import store from '@/store';

const path = '/reviews';

async function create(code, data) {
  const res = await httpV2.post(path + '/' + code, data);
  return res.data;
}

async function update(code, id, data) {
  const res = await httpV2.patch(path + '/' + code + '/' + id, data);
  return res.data;
}

export default {
  create,
  update,
};
