import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { format, differenceInSeconds, setDay, parseISO } from 'date-fns';
import { enUS as en, de, ru, pl } from 'date-fns/locale';
import cookie from '@/lib/cookie';

const locales = ['ru', 'en', 'de', 'pl'];
// const currencies = ['USD', 'EUR', 'RUB', 'CHF'];

Vue.use(VueI18n);

const languages = [
  { locale: 'ru', name: 'Русский' },
  { locale: 'en', name: 'English' },
  { locale: 'de', name: 'Deutsch' },
  { locale: 'pl', name: 'Polski' },
];

const dateLocales = { ru, en, de, pl };

// 0 - sunday, 1 - monday
const startOfWeek = {
  en: 0,
  de: 0,
  ru: 1,
  pl: 1,
};

function parseDurationToSeconds(value) {
  if (value === undefined || value === null) {
    return value;
  }
  const parts = value.split(':');
  const hours = parseInt(parts[0]);
  const minutes = parseInt(parts[1]);
  return hours * 3600 + minutes * 60;
}

function formatDurationSeconds(value) {
  if (value === undefined || value === null) {
    return value;
  }
  let sign = '';
  if (value < 0) {
    value = -value;
    sign = '-';
  }
  const minutes = Math.floor((value % 3600) / 60);
  const hours = Math.floor(value / 3600);

  return `${sign}${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
}

function durationSince(time) {
  if (typeof time === 'string') {
    time = parseISO(time);
  }
  return differenceInSeconds(new Date(), time);
}

function durationUntil(time) {
  if (typeof time === 'string') {
    time = parseISO(time);
  }
  return differenceInSeconds(time, new Date());
}

const dateTimeFormats = {
  en: {
    short: 'd MMM',
    long: 'd MMM HH:mm',
    standard: 'dd/MM/yyyy',
    dayMonth: 'd MMMM',
    monthName: 'MMMM',
    dateonly: 'dd/MM/yyyy',
    date: 'dd/MM/yyyy HH:mm',
    withSeconds: 'dd/MM/yyyy HH:mm:ss',
    time: 'HH:mm',
    durationShort: function(value) {
      if (typeof value === 'number') {
        value = formatDurationSeconds(value);
      }
      return value;
    },
    duration: function(value) {
      if (typeof value === 'number') {
        value = formatDurationSeconds(value);
      }
      let parts = value.split(':').map(v => parseInt(v));
      const hours = parts[0];
      parts = parts.map(n => i18n.n(n, 'number'));
      if (hours === 0) {
        return `${parts[1]} min`;
      } else if (hours < 100) {
        return `${parts[0]} hr ${('0' + parts[1]).substr(-2)} min`;
      }
      return `${parts[0]} hr`;
    },
    durationDays: function(value) {
      if (value > 86400) {
        return i18n.tc('{0} days', Math.floor(value / 86400));
      }
      return i18n.tc('{0} hours', Math.floor(value / 3600));
    },
  },
  de: {
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  ru: {
    short: 'd MMM',
    long: 'd MMM HH:mm',
    standard: 'dd.MM.yyyy',
    dayMonth: 'd MMMM',
    monthName: 'MMMM',
    dateonly: 'dd.MM.yyyy',
    date: 'dd.MM.yyyy HH:mm',
    withSeconds: 'dd.MM.yyyy HH:mm:ss',
    time: 'HH:mm',
    durationShort: function(value) {
      if (typeof value === 'number') {
        value = formatDurationSeconds(value);
      }
      return value;
    },
    duration: function(value) {
      if (typeof value === 'number') {
        value = formatDurationSeconds(value);
      }
      let parts = value.split(':').map(v => parseInt(v));
      const hours = parts[0];
      parts = parts.map(n => i18n.n(n, 'number'));
      if (hours === 0) {
        return `${parts[1]} мин.`;
      } else if (hours < 100) {
        return `${parts[0]} ч. ${('0' + parts[1]).substr(-2)} мин.`;
      }
      return `${parts[0]} ч.`;
    },
  },
};

/* eslint-disable space-infix-ops */
const plurals = {
  en: n => (n !== 1 ? 2 : 1),
  de: n => (n !== 1 ? 2 : 1),
  ru: n => (n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2),
  pl: n => (n === 1 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2),
};
/* eslint-enable space-infix-ops */

const COMMON_NUMBER_FORMATS = {
  plain: {
    style: 'decimal',
    useGrouping: false,
  },
  number: {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
  round: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  currency: {
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  coordinate: {
    style: 'decimal',
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
  },
};

const numberFormats = {
  en: {
    ...COMMON_NUMBER_FORMATS,
    distance: value => {
      if (value === null) {
        return '';
      }
      return i18n.n(value >= 1000000 ? Math.round(value / 1000) : Math.round(value / 100) / 10, 'number') + ' km';
    },
  },
  ru: {
    ...COMMON_NUMBER_FORMATS,
    distance: value => {
      if (value === null) {
        return '';
      }
      return i18n.n(value >= 1000000 ? Math.round(value / 1000) : Math.round(value / 100) / 10, 'number') + ' км';
    },
  },
};

const messages = {};
locales.forEach(v => {
  messages[v] = require(`../locales/${v}`);
});

const countries = {
  en: require(`../locales/countries.json`),
  ru: require(`../locales/countries-ru.json`),
};

const currentLocale = cookie.get('lang') || 'ru';

const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: currentLocale,
  silentTranslationWarn: true,
  dateTimeFormats,
  numberFormats,
  messages,
});

// HACK: warnings otherwise
VueI18n.prototype._getDateTimeFormats = function() {
  return dateTimeFormats;
};

const old$d = i18n._d;
VueI18n.prototype._d = function(value, locale, key) {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return null;
  }
  let fmt;
  if (dateTimeFormats[locale] && dateTimeFormats[locale][key]) {
    fmt = dateTimeFormats[locale][key];
  } else if (dateTimeFormats[i18n.fallbackLocale] && dateTimeFormats[i18n.fallbackLocale][key]) {
    fmt = dateTimeFormats[i18n.fallbackLocale][key];
  } else {
    fmt = key;
  }
  if (typeof fmt === 'function') {
    return fmt(value);
  }
  if (typeof fmt === 'object') {
    return old$d.call(this, new Date(value), locale, key);
  }
  value = typeof value === 'string' ? parseISO(value) : value;
  return format(value, fmt, {
    locale: dateLocales[locale],
  });
};

const old$n = i18n._n;
VueI18n.prototype._n = function(value, _locale, key, options) {
  if (value === null || value === undefined || Number.isNaN(value)) {
    return null;
  }

  let locale = _locale;
  let currency;
  if (key === 'currency') {
    currency = locale;
    locale = this.locale;

    if (!currency) {
      return null;
    }
  }

  let fmt;
  if (numberFormats[locale] && numberFormats[locale][key]) {
    fmt = numberFormats[locale][key];
  } else if (numberFormats[i18n.fallbackLocale] && numberFormats[i18n.fallbackLocale][key]) {
    fmt = numberFormats[i18n.fallbackLocale][key];
  } else {
    return null;
  }
  if (typeof fmt === 'function') {
    return fmt(value);
  }

  if (currency) {
    fmt = Object.assign({}, fmt, { currency });
    return old$n.call(this, value, locale, null, fmt);
  }

  return old$n.call(this, value, locale, key);
};

const old$tc = VueI18n.prototype._tc;
VueI18n.prototype._tc = function(key, locale, messages, host, choice, values) {
  if (!values) {
    values = [choice];
  }
  if (!values['0']) {
    values['0'] = choice;
  }
  return old$tc.call(this, key, locale, messages, host, plurals[locale](choice), values);
};

const $d = i18n.d.bind(i18n);
const $t = i18n.t.bind(i18n);

const capitalizeFirstLetter = s => s.charAt(0).toUpperCase() + s.slice(1);

function getWeekdaysList() {
  const list = [];
  const date = new Date();

  const start = startOfWeek[i18n.locale] || 0;

  for (let i = start; i < start + 7; i++) {
    list.push({
      text: capitalizeFirstLetter(
        format(setDay(date, i), 'EEEE', {
          locale: dateLocales[i18n.locale],
        }),
      ),
      value: ((i + 6) % 7) + 1,
    });
  }
  return list;
}

function getCountriesList() {
  return countries[i18n.locale] || countries['en'];
}

export default i18n;
export { $t, $d };
export { languages, locales };
export {
  formatDurationSeconds,
  parseDurationToSeconds,
  durationSince,
  durationUntil,
  getWeekdaysList,
  getCountriesList,
};
