const AUTORELOAD_TIME = 60000;
const ONE_DAY = 86400;
const THREE_DAYS = 259200;

const ONBOARDING_OVERLAY_ZINDEX = 1000009;
const ONBOARDING_ELEMENT_ZINDEX = 1000010;

const MIN_RADIUS = 50;
const MAX_RADIUS = 1000;

const MULTIPOINT_RADIUS = 200;

const UuidRegExp = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export {
  AUTORELOAD_TIME,
  ONE_DAY,
  THREE_DAYS,
  ONBOARDING_OVERLAY_ZINDEX,
  ONBOARDING_ELEMENT_ZINDEX,
  MIN_RADIUS,
  MAX_RADIUS,
  MULTIPOINT_RADIUS,
  UuidRegExp,
};
