import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import Meta from 'vue-meta';
import auth from '@/api/auth';
import store from '@/store';
import route from '@/lib/route';
import { Route } from 'vue-router/types/router';

const DeviceTrip = () => import('./pages/DeviceTrip.vue');
const DistributionList = () =>
  import(/* webpackChunkName: "distribution" */ './pages/distribution/DistributionList.vue');
const DistributionInfo = () =>
  import(/* webpackChunkName: "distribution" */ './pages/distribution/DistributionInfo.vue');
const DistributionTripsMonitoring = () => import('./pages/distribution/DistributionTripsMonitoring.vue');
const TripDetails = () => import('./pages/TripDetails.vue');
const DistributionCreate = () =>
  import(/* webpackChunkName: "distribution" */ './pages/distribution/DistributionCreate.vue');
const DistributionImport = () =>
  import(/* webpackChunkName: "distribution" */ './pages/distribution/DistributionImport.vue');
const DistributionManual = () =>
  import(/* webpackChunkName: "distribution" */ './pages/distribution/DistributionManual.vue');
const DistributionAdditional = () =>
  import(/* webpackChunkName: "distribution" */ './pages/distribution/DistributionAdditional.vue');
const UnplannedOrdersAnalytics = () =>
  import(/* webpackChunkName: "distribution" */ './pages/distribution/UnplannedOrdersAnalytics.vue');
const Zones = () => import(/* webpackChunkName: "zones" */ './pages/zones/Zones.vue');

Vue.use(Router);
Vue.use(Meta);

let routes: RouteConfig[] = [
  { exact: true, path: '/', redirect: '/trips' },

  route('/login', 'Login', 'login', { meta: { notLoggedIn: true } }),
  route('/registration', 'Register', 'register'),
  route('/confirm/:code', 'registration/Confirm', { meta: { notLoggedIn: true } }),
  route('/restore/:code', 'Restore', 'restore', { meta: { notLoggedIn: true } }),
  route('/invite/:code', 'registration/Invite', 'invite', { meta: { notLoggedIn: true } }),
  route('/app', 'AppRedirect', 'app', { meta: { notLoggedIn: true } }),
  route('/logout', 'Logout', 'logout'),
  route('/license-agreement', 'legal/en/LicenseAgreement', 'licenseAgreement'),
  route('/license-agreement/ru', 'legal/ru/LicenseAgreement', 'licenseAgreementRu'),
  route('/license-agreement-rockwool', 'legal/en/LicenseAgreementRockwool', 'licenseAgreementRockwool'),
  route('/license-agreement-rockwool/ru', 'legal/ru/LicenseAgreementRockwool', 'licenseAgreementRockwoolRu'),
  route('/policy', 'legal/en/Policy', 'policy'),
  route('/rules', 'legal/en/Rules', 'rules'),
  route('/terms', 'legal/en/Terms', 'terms'),
  route('/policy/ru', 'legal/ru/Policy', 'policyRu'),
  route('/rules/ru', 'legal/ru/Rules', 'rulesRu'),
  route('/terms/ru', 'legal/ru/Terms', 'termsRu'),

  route('/accounts', 'Accounts', 'accounts', { meta: { permission: 'accounts get' } }),
  route('/accounts/:id', 'AccountDetails', 'account_details', { meta: { permission: 'accounts get' } }),

  route('/deeplink/register/:code', 'registration/DeeplinkInvite', 'deeplinkInvite', { meta: { notLoggedIn: true } }),
  route('/deeplink/*', 'AppRedirect', 'deeplink', { meta: { notLoggedIn: true } }),

  route('/.well-known/assetlinks.json', 'assetlinks.json'),

  route('/accounts', 'Accounts', 'accounts'),
  route('/accounts/:id', 'AccountDetails', 'account_details'),
  route('/billing/prices', 'billing/Prices', 'billingTariffScale', { meta: { permission: 'billing' } }),
  route('/billing/payment', 'billing/Payment', 'billingPayment', { meta: { permission: 'billing' } }),
  route('/billing/invoices', 'billing/Invoices', 'paymentInvoices', { meta: { permission: 'billing' } }),

  route('/branches', 'Company', 'company', { meta: { permission: 'branches get' } }),
  route('/branches/:id', 'BranchDetails', { meta: { permission: 'branches get' } }),

  route('/trips', 'Trips', 'trips', { meta: { permission: 'trips get' } }),
  route('/trips/create', 'TripEdit', 'createTrip', {
    meta: { permission: 'trips create' },
    props: (route: Route) => ({ templateTrip: route.params.trip }),
  }),
  route('/trips/monitoring', 'TripsMonitoring', 'tripsMonitoring', {
    meta: { permission: 'monitoring get' },
  }),
  route('/trips/:id', 'TripDetails', 'tripDetails', { meta: { permission: 'trips get' } }),
  route('/trips/:tripDbId/tripPoints/:tripPointId', 'TripPointDetails', 'tripPointDetails', {
    meta: { permission: 'trips get' },
  }),
  route('/trips/:id/edit', 'TripEdit', 'updateTrip', {
    meta: { permission: 'trips update' },
    props: (route: Route) => ({ templateTrip: route.params.trip }),
  }),
  route('/points', 'PointsList', 'pointsList', { meta: { permission: 'trips get' } }),

  route('/favorites', 'Places', 'Places', { meta: { permission: 'places get' } }),
  route('/favorites/:id', 'PlaceDetails', 'PlaceDetails', { meta: { permission: 'places get' } }),

  route('/tags', 'Tags', 'tags', { meta: { permission: 'tags get' } }),

  route('/pointfunctions', 'PointFunctionsSettings', 'pointFunctionsSettings', {
    meta: { permission: 'pointFunctions get' },
  }),
  route('/reports', 'Reports', 'reports', { meta: { permission: 'reports get' } }),

  route('/profile', 'Profile', 'profile'),

  route('/support', 'Support', 'support'),

  route('/company/settings', 'Settings', 'settings', { meta: { permission: 'company update' } }),

  route('/company/roles', 'roles/RolesList', 'roles', { meta: { permission: 'roles get' } }),
  route('/company/roles/create', 'roles/RolesCreate', 'createRoles', { meta: { permission: 'roles create' } }),
  route('/company/roles/:id/edit', 'roles/RolesEdit', 'editRoles', { meta: { permission: 'roles get' } }),

  route('/transports', 'resources/transports/Transports', 'transports', { meta: { permission: 'transports get' } }),
  route('/transports/:id', 'resources/transports/TransportDetails', 'transportDetails', {
    meta: { permission: 'transports get' },
  }),

  route('/orders', 'resources/orders/Orders', 'orders', { meta: { permission: 'orders get' } }),
  route('/orders/:id', 'resources/orders/OrderDetails', 'orderDetails', { meta: { permission: 'orders get' } }),

  route('/contractors', 'resources/Contractors', 'contractors', { meta: { permission: 'contractors get' } }),

  {
    exact: true,
    path: '/devices/:id/trip',
    name: 'deviceTrip',
    component: DeviceTrip,
    meta: { permission: 'devices get' },
  },

  {
    exact: true,
    path: '/distributions',
    name: 'distributionList',
    component: DistributionList,
    meta: { permission: 'distributions get' },
  },
  {
    exact: true,
    path: '/distributions/create',
    name: 'distributionCreate',
    component: DistributionCreate,
    meta: { permission: 'distributions create' },
  },
  {
    exact: true,
    path: '/distributions/create/import',
    name: 'distributionImport',
    component: DistributionImport,
    meta: { permission: 'distributions create' },
  },
  {
    exact: true,
    path: '/distributions/create/manual',
    name: 'distributionManual',
    component: DistributionManual,
    meta: { permission: 'distributions create' },
  },
  {
    exact: true,
    path: '/distributions/create/additional',
    name: 'distributionAdditional',
    component: DistributionAdditional,
    meta: { permission: 'distributions create' },
  },
  {
    exact: true,
    path: '/distributions/:id',
    name: 'distributionInfo',
    component: DistributionInfo,
    meta: { permission: 'distributions get' },
  },
  {
    exact: true,
    path: '/distributions/:id/monitoring',
    name: 'distributionMonitoring',
    component: DistributionTripsMonitoring,
    meta: { permission: 'distributions get' },
  },
  {
    exact: true,
    path: '/distributions/:id/analytics',
    name: 'UnplannedOrdersAnalytics',
    component: UnplannedOrdersAnalytics,
    meta: { permission: 'distributions get' },
  },
  {
    exact: true,
    path: '/distributions/:distributionId/trips/:tripId',
    name: 'distributionTrip',
    component: TripDetails,
    meta: { permission: 'distributions get' },
  },

  {
    exact: true,
    path: '/zones',
    name: 'zones',
    component: Zones,
    meta: { fillHeight: true, permission: 'zones get' },
  },
];

if (process.env.NODE_ENV === 'development') {
  routes = [
    ...routes,
    route('/debug', 'test/Debug'),
    route('/debug/time', 'test/Time'),
    route('/debug/trip', 'test/Trip'),
    route('/debug/branchPicker', 'test/BranchPicker'),
    route('/debug/grid', 'test/Grid'),
    route('/debug/select', 'test/Select'),
    route('/debug/contacts', 'test/Contacts'),
    route('/debug/typeIcon', 'test/TypeIcon'),
    route('/shifts', 'resources/Shifts', 'shifts', { meta: { permission: 'shifts get' } }),
  ];
}

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const route = to.matched[to.matched.length - 1];
  if (!route) {
    next({ path: '/' });
    return;
  }

  if (route.meta && !route.meta.notLoggedIn && to.fullPath !== '/registration') {
    if (!store.state.auth.initialized) {
      await auth.init();
    }

    if (!store.state.auth.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
      return;
    } else if (route.meta && route.meta.permission) {
      const hasPermissionToRoute = store.state.auth.permissions[route.meta.permission];
      const hasPermissionToTrips = store.state.auth.permissions['trips get'];

      if (!hasPermissionToRoute && hasPermissionToTrips) {
        next({ path: '/trips' });
        return;
      }

      if (!hasPermissionToRoute) {
        next({ path: '/branches' });
        return;
      }
    }
  }

  next();
});

export default router;
