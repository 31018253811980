
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import EventBus from '@/event-bus';

import api, { OrdersResponse } from '@/api/orders';

import { Demand, DemandType, Order, OrderDto, Tag } from '@/models/Order';
import Uuid from '@/models/Uuid';
import { IDeliveryWindowsItem } from '@/models/TripPoint';

import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import Grid from '@/components/grid/GridCrud';
import OrdersFilter from '@/components/filters/OrdersFilter.vue';
import OrdersFilterDto from '@/components/filters/OrdersFilterDto';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import DeliveryWindows from '@/components/trip/grid/DeliveryWindows';
import TagComponent from '@/components/Tag';
import { isEqual } from 'lodash';

export default Vue.extend({
  name: 'Orders',

  components: {
    DeliveryWindows,
    Grid,
    GridTh,
    GridSelectAll,
    OrdersFilter,
    Checkbox,
    DeleteDialog,
    Tag: TagComponent,
  },

  data() {
    return {
      apiFunction: api.getOrders,
      orderIds: [] as string[],
      filter: new OrdersFilterDto(),
      pagination: {} as {
        page: number;
        rowsPerPage: number;
        sortBy: string;
        descending: boolean;
      },
      deleteDialog: false,
      deleteError: null,
      deleteItem: null as Order | null,
      selected: [] as string[],
      multiDeleteDialog: false,
      multiDeleteError: null,
      error: null,
    };
  },

  async created() {
    this.parseURL();

    await this.updateTags();

    EventBus.$on('orders-refresh', () => {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    });
  },

  beforeDestroy() {
    EventBus.$off('orders-refresh');
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('tags', ['tags']),

    fields(): object {
      return {
        number: {
          label: 'OrdersThs.Number',
          align: 'left',
          sortable: true,
        },

        ownerName: {
          label: 'OrdersThs.OwnerName',
          align: 'left',
          sortable: false,
        },
        ...(this.settings.enableCashBoxPayment
          ? {
              paymentStatus: {
                label: 'OrdersThs.PaymentStatus',
                sortable: true,
                align: 'left',
              },
            }
          : {}),
        pickupTags: {
          label: 'OrdersThs.PickupTags',
          sortable: false,
          align: 'left',
          style: {
            maxWidth: '200px',
          },
        },
        pickupAddress: {
          label: 'OrdersThs.PickupAddress',
          sortable: false,
          align: 'left',
          style: {
            minWidth: '300px',
          },
        },
        pickupDeliveryWindows: {
          label: 'OrdersThs.PickupDeliveryWindows',
          sortable: false,
          align: 'left',
        },
        workTags: {
          label: 'OrdersThs.WorkTags',
          sortable: false,
          align: 'left',
          style: {
            maxWidth: '200px',
          },
        },
        workAddress: {
          label: 'OrdersThs.WorkAddress',
          sortable: false,
          align: 'left',
          style: {
            minWidth: '300px',
          },
        },
        workDeliveryWindows: {
          label: 'OrdersThs.WorkDeliveryWindows',
          sortable: false,
          align: 'left',
        },
        dropTags: {
          label: 'OrdersThs.DropTags',
          sortable: false,
          align: 'left',
          style: {
            maxWidth: '200px',
          },
        },
        dropAddress: {
          label: 'OrdersThs.DropAddress',
          sortable: false,
          align: 'left',
          style: {
            minWidth: '300px',
          },
        },
        dropDeliveryWindows: {
          label: 'OrdersThs.DropDeliveryWindows',
          sortable: false,
          align: 'left',
        },
        mass: {
          label: 'OrdersThs.Mass',
          align: 'left',
          sortable: false,
        },
        volume: {
          label: 'OrdersThs.Volume',
          align: 'left',
          sortable: false,
        },
        name: {
          label: 'OrdersThs.Name',
          align: 'left',
          sortable: false,
        },
        price: {
          label: 'OrdersThs.Price',
          align: 'left',
          sortable: false,
        },
        amount: {
          label: 'OrdersThs.Amount',
          align: 'left',
          sortable: false,
        },
        length: {
          label: 'OrdersThs.Length',
          align: 'left',
          sortable: false,
        },
        width: {
          label: 'OrdersThs.Width',
          align: 'left',
          sortable: false,
        },
        height: {
          label: 'OrdersThs.Height',
          align: 'left',
          sortable: false,
        },
        features: {
          label: 'OrdersThs.Features',
          align: 'left',
          sortable: false,
        },
        restrictions: {
          label: 'OrdersThs.Restrictions',
          align: 'left',
          sortable: false,
        },
        actions: {
          label: 'OrdersThs.Actions',
          align: 'center',
          sortable: false,
        },
      };
    },

    deleteItemName(): string {
      const str = this.deleteItem ? ` "${this.deleteItem.number}"` : '';
      return this.$t('common_strings.order') + str;
    },
  },

  methods: {
    ...mapActions('tags', ['updateTags']),

    routerReplace() {
      const query = this.makeQuery();

      if (!isEqual(this.$route.query, query)) {
        this.$router.replace({ query });
      }
    },

    pickupDemandTags(item: Order): Uuid[] | [] {
      let pickupDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.pickup);

      if (pickupDemands?.length && pickupDemands[0]?.tagIds?.length) {
        return this.tags.filter((item: Tag) => pickupDemands?.length && pickupDemands[0]?.tagIds?.includes(item.id));
      }

      return [];
    },

    pickupDemandAddress(item: Order): string | null {
      let pickupDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.pickup);

      if (pickupDemands?.length && pickupDemands[0]?.location?.addresses.length) {
        return pickupDemands[0].location.addresses[0].address;
      }

      return null;
    },

    pickupDemandDeliveryWindows(item: Order): IDeliveryWindowsItem | null {
      let pickupDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.pickup);

      if (pickupDemands?.length && pickupDemands[0]?.deliveryWindows?.length) {
        return {
          deliveryWindows: pickupDemands[0]?.deliveryWindows,
          missDeliveryWindow: false,
          showDate: true,
          timezoneOffset: null,
        };
      }

      return null;
    },

    dropDemandTags(item: Order): Uuid[] | [] {
      let dropDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.drop);

      if (dropDemands?.length && dropDemands[0]?.tagIds?.length) {
        return this.tags.filter((item: Tag) => dropDemands?.length && dropDemands[0]?.tagIds?.includes(item.id));
      }

      return [];
    },

    dropDemandAddress(item: Order): string | null {
      let dropDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.drop);

      if (dropDemands?.length && dropDemands[0]?.location?.addresses.length) {
        return dropDemands[0].location.addresses[0].address;
      }

      return null;
    },

    dropDemandDeliveryWindows(item: Order): IDeliveryWindowsItem | null {
      let dropDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.drop);

      if (dropDemands?.length && dropDemands[0]?.deliveryWindows?.length) {
        return {
          deliveryWindows: dropDemands[0]?.deliveryWindows,
          missDeliveryWindow: false,
          showDate: true,
          timezoneOffset: null,
        };
      }

      return null;
    },

    workDemandTags(item: Order): Uuid[] | [] {
      let workDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.work);

      if (workDemands?.length && workDemands[0]?.tagIds?.length) {
        return this.tags.filter((item: Tag) => workDemands?.length && workDemands[0]?.tagIds?.includes(item.id));
      }

      return [];
    },

    workDemandAddress(item: Order): string | null {
      let workDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.work);

      if (workDemands?.length && workDemands[0]?.location?.addresses.length) {
        return workDemands[0].location.addresses[0].address;
      }

      return null;
    },

    workDemandDeliveryWindows(item: Order): IDeliveryWindowsItem | null {
      let workDemands: Demand[] | undefined = item.demands?.filter(demand => demand.type === DemandType.work);

      if (workDemands?.length && workDemands[0]?.deliveryWindows?.length) {
        return {
          deliveryWindows: workDemands[0]?.deliveryWindows,
          missDeliveryWindow: false,
          showDate: true,
          timezoneOffset: null,
        };
      }

      return null;
    },

    details(order: OrderDto, event: MouseEvent): void {
      const url = this.$route.path + '/' + order.id;

      if (event && (event.metaKey || event.ctrlKey || event.shiftKey)) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },

    filterUpdate(value: OrdersFilterDto): void {
      localStorage.setItem('filterOrders', JSON.stringify(value));
      this.filter = new OrdersFilterDto(value);
    },

    showMultiDeleteDialog() {
      this.multiDeleteError = null;
      this.multiDeleteDialog = true;
    },

    async multiHandleDelete() {
      try {
        if (this.selected.length) {
          await api.deleteOrdersByIds(this.selected);
        }

        (this.$refs.grid as Vue & { getItems: () => void }).getItems();

        this.multiDeleteDialog = false;
      } catch (err) {
        this.multiDeleteError = err;
      } finally {
        this.selected = [];
      }
    },

    showDeleteDialog(item: Order): void {
      this.deleteError = null;
      this.deleteDialog = true;
      this.deleteItem = item;
    },

    async handleDelete(): Promise<void> {
      try {
        if (this.deleteItem?.id) {
          await api.deleteOrder(this.deleteItem.id);
        }

        (this.$refs.grid as Vue & { getItems: () => void }).getItems();

        this.deleteDialog = false;
      } catch (err) {
        this.deleteError = err;
      } finally {
        this.selected = this.selected.filter(id => id !== this.deleteItem?.id);
      }
    },

    async refresh() {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    },

    parseURL(): void {
      if (Object.keys(this.$route.query).length) {
        this.filter = this.filter.fromQuery(this.$route.query);
      } else if (![null, undefined, ''].includes(localStorage.getItem('filterOrders'))) {
        this.filter = new OrdersFilterDto(JSON.parse(localStorage.getItem('filterOrders') || ''));
      }

      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page as string) + 1;
      }

      if (this.$route.query.pageSize) {
        this.pagination.rowsPerPage = parseInt(this.$route.query.pageSize as string);
      }

      if (this.$route.query.sort) {
        this.pagination.sortBy = String(this.$route.query.sort);
      }

      if (this.$route.query.sortDesc) {
        this.pagination.descending = this.$route.query.sortDesc === '1';
      }
    },

    makeQuery() {
      return {
        ...(this.$refs.grid as Vue & { makeQuery: () => any }).makeQuery(),
        ...this.filter.toQuery(),
      };
    },

    ordersUpdate(data: OrdersResponse) {
      this.orderIds = data.result.map((order: Order) => order.id);
    },

    async selectAllOrders(value?: boolean) {
      if (!value) {
        return;
      }

      this.selected = this.orderIds;
    },

    errorUpdate(error: any) {
      this.error = error;
    },
  },

  watch: {
    filter: {
      handler: async function() {
        await this.routerReplace();
      },
      deep: true,
    },

    pagination: {
      handler: async function(n, o) {
        await this.routerReplace();

        // move to another page in the table
        if (n && o && n.page !== o.page) {
          this.selected = [];
        }
      },
      deep: true,
    },

    orderIds() {
      if (this.selected.length) {
        this.selected = this.orderIds.filter((id: string) => this.selected.includes(id));
      }
    },
  },
});
