import Vue from 'vue';
import { TripTableRow } from '@/services/TripPageService';
import TimeWithOptionalDay from '../../TimeWithOptionalDay';
import Trip from '@/models/Trip';
import { CheckinStatus, TripPointStatus, TripPointType } from '@/models/TripPoint';

export default Vue.extend({
  functional: true,

  name: 'WorkTime',

  props: {
    item: TripTableRow,
    trip: Trip,

    /** startDate or finishDate */
    type: String,
  },

  render(h, { props }) {
    const { item, type, trip } = props;
    const { workTime, tripPoint, timezoneOffset, showDate } = item;
    const workTimeDate = type === 'startDate' ? workTime?.inDate : workTime?.outDate;
    const isMarked = item.status === TripPointStatus.marked;

    const tripPointCancelled =
      tripPoint?.checkins[0]?.status === CheckinStatus.byFinishTrip &&
      tripPoint.checkins[0]?.inDate?.getTime() === tripPoint.checkins[0].outDate?.getTime();

    if (workTime && tripPoint && !tripPointCancelled && [TripPointType.scheduled].includes(tripPoint.type)) {
      return h(TimeWithOptionalDay, {
        props: {
          value: workTimeDate,
          showDate,
          showTimezones: trip.containsDifferentTimezones(),
          timezoneOffset,
          status: isMarked ? item.status : null,
        },
      });
    }

    return h();
  },
});
