export default {
  namespaced: true,

  state: {
    cardInfo: {
      auto_prolongation: false,
      has_id: false,
    },

    invoice: {
      auto_prolongation: false,
    },
  },

  mutations: {
    setCardProlongation(state, data) {
      state.cardInfo.auto_prolongation = data;
    },

    setCardHasId(state, data) {
      state.cardInfo.has_id = data;
    },
  },
};
