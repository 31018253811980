import Vue from 'vue';
import { mapState } from 'vuex';
import { isEqual } from 'lodash';
import { MapMarkerOptions } from '../primitives/MapMarker';
import { PointCoordinates } from '@/models/Location';
import MapMarker from '../primitives/MapMarker';
import { computeSummaryIndicators } from '@/services/tripService';

export interface IBaseMarker extends Vue {}

export default Vue.extend({
  inject: ['getMap'],

  data() {
    return {
      marker: null as MapMarker | null,
    };
  },

  computed: {
    ...mapState('map', ['pointSelectId', 'tableRowSelectId', 'currentSelectRow', 'draggable']),

    markerData(): MapMarkerOptions | null {
      return null;
    },

    position(): PointCoordinates | null {
      return null;
    },

    isActive(): boolean {
      return false;
    },
  },

  async created() {
    await this.createMarker();
  },

  beforeDestroy() {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  },

  methods: {
    async createMarker() {
      if (!this.markerData) {
        return;
      }

      if (!this.marker) {
        const map = await (this as any).getMap();
        this.marker = new MapMarker(this.markerData);
        this.marker.addListener('click', () => this.$emit('click', this));
        this.marker.addListener('calibrate', async (newPosition: google.maps.LatLng) => {
          this.$emit('calibrate', newPosition.toJSON());
        });
        this.marker.setMap(map);
      }
    },
  },

  watch: {
    isActive(value) {
      if (this.marker) {
        this.marker.setActive(value);
      }
    },

    markerData: {
      deep: true,
      handler(newValue, oldValue) {
        if (!newValue && this.marker) {
          this.marker.setMap(null);
          this.marker = null;
          return;
        }

        if (newValue && !this.marker) {
          this.createMarker();
          return;
        }

        if (!this.marker) {
          return;
        }

        if (!isEqual(newValue.icon, oldValue.icon)) {
          this.marker.set('icon', newValue.icon);
        }

        if (!isEqual(newValue.position, oldValue.position)) {
          this.marker.set('position', new google.maps.LatLng(newValue.position));
        }

        const newLabel = newValue.label && newValue.label.text;
        const oldLabel = oldValue.label && oldValue.label.text;
        if (newLabel !== oldLabel) {
          this.marker.label.set('text', newValue.label && newValue.label.text);
        }

        if (newValue.isCurrent !== oldValue.isCurrent) {
          this.marker.setIsCurrent(newValue.isCurrent);
        }

        if (newValue.isNext !== oldValue.isNext) {
          this.marker.setIsNext(newValue.isNext);
        }

        if (!isEqual(newValue.secondLabel, oldValue.secondLabel)) {
          this.marker.secondLabel.setValues(newValue.secondLabel);
        }

        if (!isEqual(newValue.downLabel, oldValue.downLabel)) {
          this.marker.downLabel.setValues(newValue.downLabel);
        }
      },
    },
  },
});
