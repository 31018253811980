import BranchAccountPicker from './BranchAccountPicker';

/**
 * Component to select branches
 */
export default {
  name: 'BranchPicker',

  props: {
    items: Array,
    single: Boolean,
    disabled: Boolean,
    prependIcon: String,
    label: String,
    value: {
      type: [Array, Number],
    },
    branchError: Boolean,
  },

  render(h) {
    return h(BranchAccountPicker, {
      props: this.selectProps,
      on: {
        input: value => {
          if (this.single) {
            if (value.branches.length > 0) {
              this.$emit('input', value.branches[0]);
            } else {
              this.$emit('input', undefined);
            }
          } else {
            this.$emit('input', value.branches);
          }
        },
      },
    });
  },

  computed: {
    selectProps() {
      return {
        label: this.label,
        prependIcon: this.prependIcon,
        disabled: this.disabled,
        items: this.items,
        value: this.pickerValue,
        single: this.single,
        branchesOnly: true,
        branchError: this.branchError,
      };
    },

    pickerValue() {
      if (this.single) {
        return {
          branches: this.value !== undefined ? [this.value] : [],
        };
      } else {
        return {
          branches: this.value,
        };
      }
    },
  },
};
