<template>
  <div v-if="enabled">
    <v-btn class="mr-0 mb-0" color="primary" @click.stop="openDialog">
      <v-icon left>add</v-icon>

      {{ $t('Invite') }}
    </v-btn>

    <v-dialog v-model="dialog" width="800px">
      <v-card class="sm-screen-tabs">
        <v-card-title class="grey lighten-4 py-4 title">
          {{ $t('Invite user') }}
        </v-card-title>

        <v-tabs ref="tabs">
          <v-tab href="#tab-full" :disabled="!canCreate && !formAdd.done">
            {{ $t('Input full data') }}
          </v-tab>

          <v-tab href="#tab-link" :disabled="!canInvite && !formLink.done">
            {{ $t('By link') }}
          </v-tab>

          <v-tabs-items touchless>
            <v-tab-item value="tab-full">
              <template v-if="!formAdd.done">
                <v-container grid-list-sm class="pa-4">
                  <Form :fields="formAddFields" :error="formAdd.error" v-model="formAdd.form">
                    <template slot="country" slot-scope="props">
                      <v-autocomplete
                        :label="$t(props.field.label)"
                        :prepend-icon="props.field.icon"
                        :items="props.field.items"
                        item-text="name"
                        item-value="alpha2"
                        v-model="formAdd.form.data[props.field.name]"
                        :error-messages="props.errors"
                      />
                    </template>

                    <template slot="defaultBranchId" slot-scope="props">
                      <v-select
                        :label="$t(props.field.label)"
                        :prepend-icon="props.field.icon"
                        :items="props.field.items"
                        item-text="name"
                        item-value="id"
                        v-model="formAdd.form.data[props.field.name]"
                        :error-messages="props.errors"
                      />
                    </template>

                    <template slot="roleId" slot-scope="props">
                      <v-select
                        :label="$t(props.field.label)"
                        :prepend-icon="props.field.icon"
                        :items="props.field.items"
                        item-text="name"
                        item-value="id"
                        v-model="formAdd.form.data[props.field.name]"
                        :error-messages="props.errors"
                      />
                    </template>
                  </Form>

                  <div class="title mt-4 mb-2">{{ $t('Contacts') }}</div>

                  <AccountContactsListEditor v-model="formAdd.form.data.contacts" />
                </v-container>

                <v-card-actions>
                  <v-spacer />

                  <v-btn flat color="primary" @click="dialog = false">
                    {{ $t('Cancel') }}
                  </v-btn>

                  <v-btn flat @click="add" :disabled="!formAdd.form.valid">
                    {{ $t('Save') }}
                  </v-btn>
                </v-card-actions>
              </template>

              <template v-if="formAdd.done">
                <v-alert color="success" :value="!!formAdd.done">
                  {{ $t('User has been added') }}
                </v-alert>

                <v-card-actions>
                  <v-spacer />

                  <v-btn flat @click="formAdd.done = false" v-if="canInvite" :loading="sending">
                    {{ $t('Add more') }}
                  </v-btn>

                  <v-btn flat color="primary" @click="dialog = false" :disabled="sending">
                    {{ $t('Close') }}
                  </v-btn>
                </v-card-actions>
              </template>
            </v-tab-item>

            <v-tab-item value="tab-link">
              <error-alert :value="formLink.error" />

              <v-container grid-list-sm class="pa-4">
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-select
                      :label="$t('Select branch')"
                      prepend-icon="business"
                      :items="getMyBranchesWithoutAccounts"
                      item-text="name"
                      item-value="id"
                      v-model="formLink.defaultBranchId"
                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-select
                      :label="$t('Select role')"
                      prepend-icon="mdi-key"
                      :items="roles"
                      item-text="name"
                      item-value="id"
                      v-model="formLink.defaultRoleId"
                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-select
                      prepend-icon="rate"
                      v-if="settings.enablePayedDrivers"
                      :items="rateList"
                      v-model="formLink.isRegular"
                      :label="$t('Pay Rate')"
                    />
                  </v-flex>

                  <v-flex xs12 v-if="!formLink.done">
                    <v-btn color="primary" @click="inviteLink">
                      {{ $t('Get link') }}
                    </v-btn>
                  </v-flex>

                  <template v-if="formLink.done">
                    <v-flex xs12>
                      <v-text-field
                        prepend-icon="mdi-link"
                        v-model="formLink.url"
                        :readonly="true"
                        ref="formLinkInput"
                        :hint="$t('Pass link to new user')"
                      />
                    </v-flex>
                  </template>

                  <template v-if="formLink.done && isDevelopment">
                    <v-flex xs12>
                      <v-text-field
                        prepend-icon="mdi-link"
                        v-model="prodLink"
                        label="Использовать на юните. Ссылка ниже для редиректа на регистрацию в приложении."
                      />
                    </v-flex>
                  </template>
                </v-layout>
              </v-container>

              <v-card-actions>
                <v-spacer />

                <v-btn v-if="!formLink.done" flat color="primary" @click="dialog = false" :disabled="sending">
                  {{ $t('Cancel') }}
                </v-btn>

                <v-btn v-if="formLink.done && canInvite" flat color="grey" @click="inviteLink" :loading="sending">
                  {{ $t('New link') }}
                </v-btn>

                <v-btn v-if="formLink.done" flat color="primary" @click="dialog = false" :disabled="sending">
                  {{ $t('Close') }}
                </v-btn>
              </v-card-actions>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog2" max-width="390">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Account limit reached') }}
        </v-card-title>

        <v-card-text>
          {{ $t('An error occurs if the number of users has reached the limit') }}. <br />

          <p>{{ $t('Solution') }}:</p>

          1) {{ $t('Delete unnecessary users') }}. <br />

          2) {{ $t('Increase the limit (available only to the head of the company)') }}.
        </v-card-text>

        <v-card-actions v-if="isOwner">
          <v-spacer />

          <router-link color="green darken-1" flat="flat" @click.native="dialog2 = false" to="/billing/payment">
            {{ $t('Increase the limit') }}
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* global $ */
import { mapGetters, mapState } from 'vuex';
import EventBus from '@/event-bus';
import { getCountriesList } from '@/i18n';

import api from '@/api';
import rules from '@/api/rules';
import apiRoles from '@/api/roles';

import AccountContactsListEditor from '@/components/inputs/AccountContactsListEditor.vue';

export default {
  name: 'AccountsInvite',

  components: {
    AccountContactsListEditor,
  },

  data() {
    return {
      formLink: {
        done: false,
        isRegular: true,
        url: null,
        error: null,
        defaultBranchId: null,
        defaultRoleId: null,
      },
      formAdd: {
        done: false,
        form: {
          valid: true,
          data: {
            country: null,
            defaultBranchId: null,
            roleId: null,
            contacts: [{ note: '', phone: '' }],
          },
        },
        error: null,
      },
      dialog: false,
      dialog2: false,
      error: null,
      sending: false,
      roles: [],
    };
  },

  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
  },

  async created() {
    this.formAdd.form.data.country = this.company.country;
    this.formAdd.form.data.defaultBranchId = this.settings.defaultBranchId;
    this.formAdd.form.data.roleId = this.settings.defaultRoleId;
    this.formLink.defaultBranchId = this.settings.defaultBranchId;
    this.formLink.defaultRoleId = this.settings.defaultRoleId;

    await this.getRoles();

    await this.fetchBillingInfo();
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapState(['billing', 'company']),
    ...mapState('auth', ['accountId']),
    ...mapState('branches', ['branches']),
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },

    // TODO!! ONLY FOR TESTING USE
    prodLink() {
      if (this.formLink.url) {
        let index = this.formLink.url.indexOf('/invite');
        if (index >= 0) {
          return this.formLink.url.replace(this.formLink.url.substring(0, index), 'https://co.homestretch.ru');
        } else {
          index = this.formLink.url.indexOf('/deeplink/register');
          if (index >= 0) {
            return this.formLink.url.replace(this.formLink.url.substring(0, index), 'https://co.homestretch.ru');
          } else {
            return this.formLink.url;
          }
        }
      }

      return this.formLink.url;
    },

    formAddFields() {
      let fields = [];

      if (this.settings.enablePayedDrivers) {
        fields = {
          name: 'isRegular',
          icon: 'rate',
          label: 'Pay Rate',
          type: 'select',
          items: this.rateList,
          isRequired: true,
        };
      }

      fields = [
        ...[fields],
        [
          {
            name: 'email',
            icon: 'mail',
            label: 'E-Mail',
            type: 'email',
            rules: this.emailRules,
            isRequired: true,
          },
          {
            type: 'tel',
            name: 'phone',
            icon: 'phone',
            label: 'Phone',
            placeholder: '+7(000)0000000',
            rules: rules.phone,
          },
        ],
        [
          {
            name: 'lastName',
            icon: 'person',
            label: 'Last name',
            rules: rules.name.required('Last name'),
            isRequired: true,
          },
          {
            name: 'firstName',
            label: 'Name',
            rules: rules.name.required('Name'),
            isRequired: true,
          },
        ],
        {
          name: 'jobTitle',
          icon: 'mdi-account-details',
          label: 'Job title',
          rules: rules.string.required('Job title'),
          isRequired: true,
        },
        {
          name: 'country',
          label: 'Select country',
          type: 'select',
          items: this.countriesList,
          icon: 'mdi-compass-outline',
        },
        {
          name: 'defaultBranchId',
          label: 'Select branch',
          type: 'select',
          items: this.getMyBranchesWithoutAccounts,
          icon: 'business',
        },
        {
          name: 'roleId',
          label: 'Select role',
          type: 'select',
          items: this.roles,
          icon: 'mdi-key',
        },
      ];

      return fields;
    },

    getMyBranchesWithoutAccounts() {
      return this.branches.map(branch => ({ ...branch, accounts: undefined }));
    },

    emailRules() {
      if (!this.formAdd.form.data.phone && !this.formAdd.form.data.email) {
        return [v => this.$t('Please input email or phone')];
      }

      return rules.email;
    },

    countriesList() {
      return getCountriesList();
    },

    rateList() {
      return [
        { text: this.$t(`PayRateIsRegular.true`), value: true },
        { text: this.$t('PayRateIsRegular.false'), value: false },
      ];
    },

    isOwner() {
      return this.accountId === this.company.owner_id;
    },

    canAddUser() {
      return this.hasPaidSlots || (!this.formAdd.form.data.isRegular && !this.formLink.isRegular);
    },

    hasPaidSlots() {
      return (
        this.billing.status === 'TRIAL' ||
        ((this.billing.status === 'ACTIVE' || this.billing.status === 'CONFIDENCE') &&
          this.billing.paidSlots > this.billing.activeAccounts)
      );
    },

    canInvite() {
      if (this.canAddUser && this.hasPermission('invites create')) {
        // trial
        return true;
      }

      return false;
    },

    canCreate() {
      if (this.canAddUser && this.hasPermission('accounts create')) {
        // trial
        return true;
      }

      return false;
    },
  },

  methods: {
    async openDialog() {
      if (this.canAddUser) {
        this.dialog = true;
        this.dialog2 = false;
      } else {
        this.dialog2 = true;
        this.dialog = false;

        await this.fetchBillingInfo();
      }
    },

    async fetchBillingInfo() {
      await api.billing.info.infoGetMain();

      if (!this.dialog) {
        this.formAdd.form.data.isRegular = this.hasPaidSlots || !this.settings.enablePayedDrivers;
        this.formLink.isRegular = this.hasPaidSlots || !this.settings.enablePayedDrivers;
      }
    },

    async inviteLink() {
      try {
        this.formLink.error = null;
        this.sending = true;

        const data = await api.invite.create({
          isRegular: this.formLink.isRegular,
          defaultBranchId: this.formLink.defaultBranchId,
          defaultRoleId: this.formLink.defaultRoleId,
        });

        this.formLink.done = true;
        this.formLink.url = data.url;

        this.$nextTick(() => {
          this.$refs.formLinkInput.focus();
          const el = this.$refs.formLinkInput.$el;
          $('input', el).select();
        });

        EventBus.$emit('accounts-refresh');

        await this.fetchBillingInfo();
      } catch (err) {
        this.formLink.error = err;
        this.formLink.done = false;
      } finally {
        this.sending = false;
      }
    },

    async add() {
      try {
        this.formAdd.error = null;
        this.sending = true;

        if (!this.formAdd.form.data.phone && !this.formAdd.form.data.email) {
          throw Error(this.$t('Please input email or phone'));
        }

        const data = { ...this.formAdd.form.data };

        data.contacts = data.contacts.filter(c => Boolean(c.phone));

        await api.account.create(data);

        this.formAdd.done = true;
        this.formAdd.form.data.country = this.company.country;
        this.formAdd.form.data.defaultBranchId = this.settings.defaultBranchId;
        this.formAdd.form.data.roleId = this.settings.defaultRoleId;

        EventBus.$emit('accounts-refresh');

        await this.fetchBillingInfo();
      } catch (err) {
        this.formAdd.error = err;
        this.formAdd.done = false;
      } finally {
        this.sending = false;
      }
    },

    reset() {
      this.formLink.error = null;
      this.formLink.done = false;

      this.formAdd.error = null;
      this.formAdd.done = false;
    },

    async getRoles() {
      const data = await apiRoles.getRoles({});

      this.roles = data.result;
    },

    checkRoleIsRegular() {
      if (this.formAdd.form.data.isRegular) {
        this.formAdd.form.data.roleId = this.settings.defaultRoleId;
      } else {
        this.formAdd.form.data.roleId = this.settings.defaultNonRegularRoleId;
      }
      if (this.formLink.isRegular) {
        this.formLink.defaultRoleId = this.settings.defaultRoleId;
      } else {
        this.formLink.defaultRoleId = this.settings.defaultNonRegularRoleId;
      }
    },
  },

  watch: {
    'formLink.isRegular': {
      handler() {
        this.checkRoleIsRegular();
      },
    },
    'formAdd.form.data.isRegular': {
      handler() {
        this.checkRoleIsRegular();
      },
    },
    formAdd: {
      deep: true,
      handler() {
        if (this.dialog) {
          this.openDialog();
        }
      },
    },
    formLink: {
      deep: true,
      handler() {
        if (this.dialog) {
          this.openDialog();
        }
      },
    },
    dialog() {
      this.reset();
      // Render tabs slider after dialog show
      if (this.$refs.tabs) {
        this.$refs.tabs.callSlider();
      }
    },
  },
};
</script>
