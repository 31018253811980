import http, { pagingParams, Params } from './httpV2';
import Uuid from '@/models/Uuid';
import { Order, OrderDto } from '@/models/Order';
import OrdersFilterDto from '@/components/filters/OrdersFilterDto';

const path = '/orders';

export interface OrdersResponse {
  result: Array<Order>;
  total: number;
}

interface OrdersListParams extends Params {
  isFull?: boolean;
}

async function getOrder(id: Uuid): Promise<Order> {
  const res = await http.get(`${path}/${id}`);
  return new Order(res.data);
}

async function getOrders(opts: OrdersListParams = {}): Promise<OrdersResponse> {
  if (opts && opts.filter) {
    opts = { ...opts };

    if (!opts.filter.toHttpParams) {
      opts.filter = new OrdersFilterDto(opts.filter);
    }

    opts.filter = opts.filter.toHttpParams();
  }

  const res = await http.get(path, {
    params: pagingParams(opts),
  });

  res.data.result = res.data.result.map((e: OrderDto) => new Order(e));
  return res.data;
}

async function patchOrder(id: Uuid, data: any): Promise<any> {
  const res = await http.patch(`${path}/${id}`, data);
  return res.data;
}

async function deleteOrder(id: Uuid): Promise<any> {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function deleteOrdersByIds(data: Uuid[]): Promise<any> {
  const res = await http.delete(path, { data });
  return res.data;
}

async function createOrder(data: any): Promise<any> {
  const res = await http.post(path, data);
  return res.data;
}

async function moveOrder(orderId: Uuid, newTripId: Uuid) {
  const res = await http.post(`${path}/${orderId}/move`, {
    tripId: newTripId,
  });
  return res.data;
}

export default {
  getOrders,
  moveOrder,
  patchOrder,
  deleteOrder,
  deleteOrdersByIds,
  createOrder,
  getOrder,
};
