<template>
  <div>
    <div v-for="(item, i) in sanitizedValue" :key="item.id">
      <ContactEditor
        :value="item"
        :max-phone-count="maxPhoneCount"
        :show-name="showName"
        :show-job-title="showJobTitle"
        :show-note="showNote"
        :show-phones="showPhones"
        :show-internal-phone="showInternalPhone"
        :required-phone="requiredPhone"
        :can-delete="canDelete"
        @delete="deleteItem(i)"
        @input="v => input({ value: v, index: i })"
      />
      <v-divider v-if="i < sanitizedValue.length - 1 || canAddContact" :key="i" class="my-1" />
    </div>
    <template v-if="canAddContact">
      <v-input class="mt-4" prepend-icon="phone">
        <a class="mt-1" @click="addItem">
          {{ $t('Add contact') }}
        </a>
      </v-input>
    </template>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import ContactEditor from './ContactEditor';

export default {
  components: { ContactEditor },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    maxCount: {
      type: Number,
      default: 3,
    },

    maxPhoneCount: {
      type: Number,
      default: 3,
    },

    showName: {
      type: Boolean,
      default: true,
    },

    showJobTitle: {
      type: Boolean,
      default: true,
    },

    showNote: {
      type: Boolean,
      default: true,
    },

    showPhones: {
      type: Boolean,
      default: true,
    },

    showInternalPhone: {
      type: Boolean,
      default: false,
    },

    showDummy: {
      type: Boolean,
      default: false,
    },

    requiredPhone: {
      type: Boolean,
      default: true,
    },

    canDelete: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      showDummyItem: false,
    };
  },

  mounted() {
    if (this.showDummy) {
      this.showDummyItem = true;
    }
  },

  computed: {
    sanitizedValue() {
      let value = this.value || [];
      if (this.showDummyItem && value.length < this.maxCount) {
        value = [
          ...value,
          {
            id: uuid(),
            name: '',
            note: '',
            jobTitle: '',
            phones: [],
          },
        ];
      }
      return value;
    },

    canAddContact() {
      return (
        this.sanitizedValue.length === 0 ||
        (this.sanitizedValue.length < this.maxCount &&
          this.isValidItem(this.sanitizedValue[this.sanitizedValue.length - 1]))
      );
    },
  },

  methods: {
    input({ value, index }) {
      const result = [...this.value];
      if (index === this.value.length) {
        this.showDummyItem = false;
      }
      result[index] = value;
      this.$emit('input', result);
    },

    addItem() {
      this.showDummyItem = true;
    },

    deleteItem(index) {
      if (index < this.value.length) {
        const result = [...this.value];
        result.splice(index, 1);
        this.$emit('input', result);
      } else {
        this.showDummyItem = false;
      }
    },

    isValidItem(item) {
      return item.phones.length > 0 && item.phones[0].phone;
    },
  },
};
</script>
