
import Vue from 'vue';
import devices from '@/api/devices';

export default Vue.extend({
  async created() {
    try {
      const tripId = await devices.getTripByDeviceId(this.$route.params.id);
      this.$router.replace({ name: 'tripDetails', params: { id: String(tripId) } });
    } catch (err) {
      if (err.code === 'EntityNotFound') {
        this.$router.replace({ name: 'trips' });
      } else {
        console.error(err);
      }
    }
  },

  render(h) {
    return h();
  },
});
