import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import gmap from '@/lib/gmap';
import { CreateElement, VNode } from 'vue';
import Trip from '@/models/Trip';

@Component({
  inject: ['getMap'],
})
export default class CurrentPositionButtonComponent extends Vue {
  map!: google.maps.Map;
  button!: HTMLElement;

  @Prop() trip!: Trip;

  async created() {
    this.map = await (this as any).getMap();

    const controlDiv = document.createElement('div');
    this.genButton(controlDiv);

    this.map.controls[(gmap as any).ControlPosition.RIGHT_BOTTOM].push(controlDiv);

    this.button.addEventListener('click', () => {
      const currentPosition = this.trip.getCurrentPosition();
      if (currentPosition) {
        this.map.setCenter(currentPosition);
        this.map.setZoom(16);
      }
    });
  }

  genButton(rootElem: any) {
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '1px solid rgba(0,0,0,0.12)';
    controlUI.style.display = 'flex';
    controlUI.style.alignItems = 'center';
    controlUI.style.height = '40px';
    controlUI.style.width = '40px';
    controlUI.style.paddingLeft = '6px';
    controlUI.style.marginBottom = '10px';
    controlUI.style.marginRight = '10px';
    rootElem.appendChild(controlUI);

    // Set CSS for the control interior.
    const icon = document.createElement('i');
    icon.className = 'material-icons v-icon';
    icon.innerText = 'my_location';
    controlUI.appendChild(icon);

    this.button = controlUI;

    rootElem.appendChild(controlUI);
  }

  render(h: CreateElement): VNode {
    return h();
  }
}
