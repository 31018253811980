
import Vue from 'vue';
import { mapGetters } from 'vuex';

import api from '@/api/tripNew';

import TripPoint from '@/models/TripPoint';
import Trip from '@/models/Trip';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import TripPointInfo from '@/components/trip/TripPointInfo.vue';

export default Vue.extend({
  name: 'TripPointDetails',

  components: {
    Breadcrumbs,
    TripPointInfo,
  },

  data() {
    return {
      tripPoint: null as TripPoint | null,
      tripDbId: null as number | null,
      tripPointId: null as string | null,
      trip: null as Trip | null,
    };
  },

  async created() {
    this.tripDbId = Number(this.$route.params.tripDbId);
    this.tripPointId = this.$route.params.tripPointId;

    if (this.tripDtoFromStore) {
      this.trip = new Trip(this.tripDtoFromStore);
      this.tripPoint = this.trip!.tripPoints.find(point => point.id === this.tripPointId)!;
    }

    this.$store.commit('trip/setTripDto', null);

    if (this.tripDbId) {
      await this.updateData();
    }
  },

  computed: {
    ...mapGetters('trip', ['tripDtoFromStore']),

    breadcrumbs(): Array<object> {
      return [
        {
          text: this.$t('Trips'),
          href: '/trips',
        },
        {
          text: this.$t('Trip') + (this.trip && this.trip.title ? `: ${this.trip.title}` : ''),
          href: `/trips/${this.tripDbId}`,
        },
        {
          text: this.$t('Point card'),
          href: '',
          disabled: true,
        },
      ];
    },
  },

  methods: {
    async updateData(): Promise<void> {
      const data = await api.getTripWithOnePoint(this.tripDbId!, this.tripPointId!);

      if (data && data.tripPoints) {
        this.trip = new Trip(data);
        this.tripPoint = new TripPoint(data.tripPoints[0]);
      }
    },
  },
});
