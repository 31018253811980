<template>
  <v-layout column>
    <v-snackbar :timeout="2000" :top="true" v-model="snackbar">
      {{ snackbarText }}
      <v-btn flat color="pink" @click.native="snackbar = false">
        {{ $t('Close') }}
      </v-btn>
    </v-snackbar>
    <!--
    <v-flex>
      <v-card>
        <v-card-title>
          <h3>{{ $t('Bank card') }}</h3>
        </v-card-title>
        <v-card-text>
          <v-layout row wrap align-end>
            <v-flex xs12 sm8>
              <billing-card :card="card" @error="err => (errorCards = err)" :update="fetchCards" />
            </v-flex>
          </v-layout>
          <v-layout row wrap align-end v-if="card.id">
            <v-flex xs12 sm12>
              <v-checkbox
                :disabled="!card.id || !card.default_payment_method"
                :label="$t('Link a card to automatically renew your subscription')"
                @change="changeCardAutoProlongation"
                v-model="card.auto_prolongation"
                hide-details
              />
            </v-flex>
            <v-flex xs12 sm12>
              <v-checkbox
                :disabled="!card.id"
                :label="$t('Method of payment')"
                @change="changeCardDefault"
                v-model="card.default_payment_method"
                hide-details
              />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    -->

    <v-flex>
      <v-card>
        <v-card-title>
          <h3>{{ $t('Requisites for creation of an invoice') }}</h3>
        </v-card-title>
        <v-card-text>
          <div v-if="invoice.company_name && !invoiceEditable">
            <template v-if="country === 'ru'">
              <div>{{ $t('Company name') }}: {{ invoice.company_name }}</div>
              <div>{{ $t('Email for billing') }}: {{ invoice.email }}</div>
              <div v-if="country === 'ru'">ИНН: {{ invoice.itn }}</div>
              <div v-if="country === 'ru'">КПП: {{ invoice.kpp }}</div>
              <div>{{ $t('Date of registration') }}: {{ invoice.date_of_state_registration }}</div>
              <div v-if="country === 'ru'">ФИО директора: {{ invoice.name_of_director }}</div>
              <div>{{ $t('Legal address') }}: {{ invoice.legal_address }}</div>
              <div>{{ $t('Mailing address') }}: {{ invoice.post_address }}</div>
              <div>{{ $t('Bank') }}: {{ invoice.bank_name }}</div>
              <div>{{ $t('Bank code') }}: {{ invoice.bank_bic }}</div>
              <div v-if="country === 'ru'">Р/С: {{ invoice.checking_account }}</div>
              <div>{{ $t('Bank address') }}: {{ invoice.bank_address }}</div>
            </template>
            <template v-else>
              <div v-if="invoice.company_name">{{ $t('Company name') }}: {{ invoice.company_name }}</div>
              <div v-if="invoice.email">{{ $t('Email') }}: {{ invoice.email }}</div>
              <div v-if="invoice.country">{{ $t('Country') }}: {{ invoice.country }}</div>
              <div v-if="invoice.city">{{ $t('City') }}: {{ invoice.city }}</div>
              <div v-if="invoice.street">{{ $t('Street') }}: {{ invoice.street }}</div>
              <div v-if="invoice.zip_code">{{ $t('Zip code') }}: {{ invoice.zip_code }}</div>
              <div v-if="invoice.vat_number">{{ $t('VAT number') }}: {{ invoice.vat_number }}</div>
            </template>
            <v-layout row wrap align-end>
              <v-flex xs12 sm12>
                <v-checkbox
                  :disabled="!invoiceCanClose || !invoice.default_payment_method"
                  :label="$t('Link emails for automatic payment of invoice')"
                  @change="changeInvoiceAutoProlongation"
                  v-model="invoice.auto_prolongation"
                  hide-details
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap align-end>
              <v-flex xs12 sm12>
                <v-checkbox
                  :disabled="!invoiceCanClose"
                  :label="$t('Method of payment')"
                  @change="changeInvoiceDefault"
                  v-model="invoice.default_payment_method"
                  hide-details
                />
              </v-flex>
            </v-layout>
          </div>
          <v-layout row wrap align-end v-if="invoiceEditable">
            <v-flex xs12 sm9>
              <invoice
                :invoice="invoice"
                :invoice-can-close="invoiceCanClose"
                @update="updateInvoice"
                :default-payment-method="invoice.default_payment_method"
                @close="invoiceEditable = false"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="!invoice.company_name && !invoiceEditable" color="error" @click="invoiceEdit">
            {{ $t('Add details') }}
          </v-btn>
          <v-btn v-if="invoice.company_name && !invoiceEditable" color="error" @click="invoiceEdit">
            {{ $t('Edit details') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
// import BillingCard from '@/components/billing/BillingCard';
import Invoice from '@/components/billing/Invoice';
import api from '@/api';
import { mapState } from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Payment details'),
    };
  },

  components: { /* BillingCard, */ Invoice },

  data() {
    return {
      card: {},
      invoice: {},
      errorCards: null,
      invoiceEditable: false,
      invoiceCanClose: false,
      snackbar: false,
      snackbarText: '',
    };
  },

  computed: {
    ...mapState('company', ['country']),
  },

  async created() {
    await this.fetchCards();
    await this.fetchInvoice();
  },

  methods: {
    async fetchCards() {
      try {
        const data = await api.billing.card.list();
        if (data.card.default_payment_method) {
          this.invoice.default_payment_method = false;
        }
        this.card = data.card;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchInvoice() {
      const data = await api.billing.invoice.get();
      this.invoice = data.invoice;
      if (data.invoice.default_payment_method) {
        this.card.default_payment_method = false;
      }
      if (this.invoice.company_name) {
        this.invoiceCanClose = true;
      }
    },

    invoiceEdit() {
      this.invoiceEditable = true;
    },

    updateInvoice(form) {
      this.invoiceEditable = false;
      this.invoiceCanClose = true;
      this.invoice = form;
    },

    async changeAutoProlongationPaymentMethod(type, status) {
      await api.billing.paymentMethod.changeAutoProlongation({ payment_method_type: type, status: status });
    },

    async changeDefaultPaymentMethod(type, status) {
      await api.billing.paymentMethod.changeDefault({ payment_method_type: type, status: status });
    },

    async changeInvoiceAutoProlongation(state) {
      await this.changeAutoProlongationPaymentMethod('BANK', state);
      this.card.auto_prolongation = false;
      this.snackbar = false;
      this.snackbarText = this.$t('Changes saved');
      this.snackbar = true;
    },

    async changeCardAutoProlongation(state) {
      await this.changeAutoProlongationPaymentMethod('CARD', state);
      this.invoice.auto_prolongation = false;

      this.snackbar = false;
      this.snackbarText = this.$t('Changes saved');
      this.snackbar = true;
    },

    async changeCardDefault(state) {
      await this.changeDefaultPaymentMethod('CARD', state);
      await this.changeInvoiceAutoProlongation(false);
      this.invoice.auto_prolongation = false;
      this.invoice.default_payment_method = false;
    },

    async changeInvoiceDefault(state) {
      await this.changeDefaultPaymentMethod('BANK', state);
      await this.changeCardAutoProlongation(false);
      this.card.auto_prolongation = false;
      this.card.default_payment_method = false;
    },
  },
};
</script>
