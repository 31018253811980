
import Vue from 'vue';
import Trip, { TripStatus, TripMode } from '@/models/Trip';
import Grid from '../grid/Grid';
import { GridTh } from '@/components/grid/Grid';
import PointDetailsRow from './PointDetailsRow.vue';
import { mapGetters, mapState } from 'vuex';
import { TripTableRow } from '@/services/TripPageService';
import Uuid from '@/models/Uuid';
import ordersApi from '@/api/orders';

export default Vue.extend({
  name: 'TripPointsDetailsGrid',

  data() {
    return {
      timer: null as number | null,
    };
  },

  props: {
    trip: Trip,
    items: Array,
    haveTripDetails: Boolean,
  },

  components: {
    Grid,
    GridTh,
    PointDetailsRow,
  },

  watch: {
    pointSelectId() {
      if (this.pointSelectId) {
        this.scrollToRow();
      }
    },
    currentSelectRow() {
      if (this.currentSelectRow) {
        this.scrollToRow();
      }
    },
  },

  computed: {
    ...mapState('map', ['pointSelectId', 'currentSelectRow']),
    ...mapGetters('orders', ['companyHasOrders']),
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    appPaddingTop(): number {
      return this.$vuetify.application.top;
    },

    haveWorkTime(): boolean {
      return this.trip.tripMode === TripMode.manual;
    },

    haveDeliveryWindows(): boolean {
      return this.trip.tripPoints.some(tripPoint => tripPoint.deliveryWindows.length);
    },

    haveOrders(): boolean {
      return this.trip.tripPoints.some(tripPoint => tripPoint.orders && tripPoint.orders.length);
    },

    havePointFunctions(): boolean {
      return this.trip.tripPoints.some(
        tripPoint => tripPoint.functions?.checklist?.length || tripPoint.functions?.droplist?.length,
      );
    },

    haveContractors(): boolean {
      return (
        this.trip.tripPoints.some(tripPoint => tripPoint.contractors && tripPoint.contractors.length) &&
        this.hasPermission('contractors get')
      );
    },

    fields(): object {
      return {
        number: {
          label: '№',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        type: {
          label: 'Point Status',
          align: 'center',
          sortable: false,
          style: {
            width: '40px',
            whiteSpace: 'normal',
            paddingRight: '10px',
            paddingLeft: '10px',
          },
        },
        address: {
          label: 'Title and address',
          nowrap: false,
          sortable: false,
          align: 'center',
          style: {
            minWidth: '350px',
            paddingLeft: '10px',
            paddingRight: '10px',
          },
        },
        ...(this.haveOrders
          ? {
              orderNumber: {
                label: 'Order number',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        ...(this.haveOrders
          ? {
              mass_volume: {
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        ...(this.havePointFunctions
          ? {
              pointFunctions: {
                label: 'Point functions',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        ...(this.haveContractors
          ? {
              contractor: {
                label: 'Contractor',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        ...(this.haveOrders && this.settings.enableCashBoxPayment
          ? {
              paymentStatus: {
                label: 'Payment status',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        ...(this.haveDeliveryWindows
          ? {
              deliveryWindows: {
                label: 'Delivery windows',
                align: 'center',
                sortable: false,
              },
            }
          : {}),
        timeInTransit: {
          label: 'In transit',
          align: 'center',
          sortable: false,
          format: (v: number | null) => (v ? Math.abs(v) : ''),
        },
        checkinAt: {
          label: 'Arrival',
          align: 'center',
          sortable: false,
        },
        ...(this.haveWorkTime
          ? {
              startWorkAt: {
                label: 'TripDetails.StartWorkAt',
                align: 'center',
                sortable: false,
                style: {
                  whiteSpace: 'normal',
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  minWidth: '110px',
                },
              },
              endWorkAt: {
                label: 'TripDetails.EndWorkAt',
                align: 'center',
                sortable: false,
                style: {
                  whiteSpace: 'normal',
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  minWidth: '110px',
                },
              },
            }
          : {}),
        checkoutAt: {
          label: 'TripDetails.CheckoutAt',
          align: 'center',
          sortable: false,
        },
        ...(this.haveWorkTime
          ? {
              totalWorkTime: {
                label: 'Work time',
                align: 'center',
                sortable: false,
                style: {
                  whiteSpace: 'normal',
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  minWidth: '110px',
                },
              },
            }
          : {}),
        timeInPoint: {
          label: 'Time in point',
          align: 'center',
          sortable: false,
          style: {
            whiteSpace: 'normal',
            paddingRight: '10px',
            paddingLeft: '10px',
            minWidth: '110px',
          },
        },
        status: {
          label: 'Battery',
          align: 'center',
          sortable: false,
          style: {
            paddingLeft: '15px',
            paddingRight: '15px',
          },
        },
      };
    },
  },

  methods: {
    openCarousel(fileId: number) {
      this.$emit('openCarousel', fileId);
    },

    findOffset(element: HTMLElement): number {
      return (
        element.offsetTop +
        ((element.offsetParent as HTMLElement) ? this.findOffset(element.offsetParent as HTMLElement) : 0)
      );
    },

    scrollToRow(id?: string): void {
      if (!this.$refs.grid) {
        return;
      }

      const thead = $('thead', (this.$refs.grid as Vue).$el)[0];
      const boundingRect = thead.getBoundingClientRect();
      const theadHeight = boundingRect.bottom - boundingRect.top;
      let tr;

      if (!id) {
        let now = Date.now();

        if (this.timer && now - this.timer < 1000) {
          return;
        }

        let currentSelectRow: string = '';
        if (this.pointSelectId) {
          const itemIds = new Set<Uuid>();
          const allTripPointCheckinIds: string[] = [];
          (this.items as TripTableRow[]).forEach(item => itemIds.add(item.id));

          this.trip.tripPoints.forEach(tripPoint => {
            tripPoint.checkins.forEach(checkin => {
              allTripPointCheckinIds.push(checkin.id);
            });
          });

          this.trip.wayPoints.forEach(wayPoint => {
            if (wayPoint.id === this.pointSelectId) {
              for (const checkin of wayPoint.checkins) {
                if (itemIds.has(checkin.id)) {
                  currentSelectRow = checkin.id;
                  return;
                }
              }

              wayPoint.tripPointIds.forEach(tripPointId => {
                if (itemIds.has(tripPointId)) {
                  currentSelectRow = tripPointId;
                } else {
                  for (const checkin of allTripPointCheckinIds) {
                    if (itemIds.has(checkin)) {
                      currentSelectRow = checkin;
                      break;
                    }
                  }
                }
              });
            }
          });
        }

        tr = document.getElementById(currentSelectRow);
      } else {
        tr = document.getElementById(id);
        this.timer = Date.now();
      }

      if (tr) {
        const top = this.findOffset(tr) - theadHeight - this.appPaddingTop;
        window.scrollTo({
          top: top,
          behavior: 'smooth',
        });
      }
    },

    tableRowClick(item: TripTableRow) {
      let wayPointId: string | null = null;

      if (item && item.wayPoint) {
        wayPointId = item.wayPoint.id;
      }

      if (item && item.tripPoint) {
        this.trip.wayPoints.forEach(wayPoint => {
          wayPoint.tripPointIds.forEach(tripPointId => {
            if (tripPointId === (item as any).tripPoint.id) {
              wayPointId = wayPoint.id;
            }
          });
        });
      }

      this.scrollToRow(item.id);

      // map observes for tableRowSelectId and rerender
      this.$store.commit('map/toggleSelection', {
        tableRowSelectId: wayPointId,
        currentSelectRow: item.id,
      });
    },
  },
});
