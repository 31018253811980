
import Vue from 'vue';
import auth from '@/api/auth';

export default Vue.extend({
  name: 'SpecifyDomain',

  data() {
    return {
      domain: '',
      error: null as Error | null,
    };
  },

  props: {
    form: {
      type: Object,
    },
  },

  methods: {
    async login(): Promise<void> {
      try {
        this.error = null;

        await auth.verify({
          login: this.form.data.login,
          password: this.form.data.password,
          domain: this.domain,
          isDebug: true,
        });

        this.$router.replace((this.$route.query.redirect as string) || '/');
      } catch (err) {
        this.error = err;
      }
    },
  },
});
