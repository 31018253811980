import Uuid from './Uuid';
import { TripInstanceDto } from '@/models/Trip';

export interface DistributionAssumptions {
  disableTrafficJams: boolean;
  flightDistance: boolean;
  disableCompatibility: boolean;
  disableCapacity: boolean;
  sameOrderTimeWindow: boolean;
  expandShiftTimeWindow: boolean;
  optimizeInAQuickAndQualitativeWay: boolean;
}

export type DistributionAssumptionsTypes = keyof DistributionAssumptions;

export interface DistributionStrategy {
  reorder: boolean;
  planNewOrders: boolean;
  createNewTrips: boolean;
}

export type DistributionStrategyTypes = keyof DistributionStrategy;

export enum DistributionConfiguration {
  optimizeDistance = 'optimizeDistance',
  optimizeTime = 'optimizeTime',
  optimizeTransports = 'optimizeTransports',
  optimizeCarsThenDistance = 'optimizeCarsThenDistance',
  optimizeCarsThenTime = 'optimizeCarsThenTime',
  visualGrouping = 'visualGrouping',
  optimizeLocalityGrouping = 'optimizeLocalityGrouping',
  optimizeCarsThenLocalityGrouping = 'optimizeCarsThenLocalityGrouping',
  optimizeCarsThenSingleLocationGrouping = 'optimizeCarsThenSingleLocationGrouping',
  optimizeVisualGrouping = 'optimizeVisualGrouping',
  optimizeCarsThenSingleLocationGroupingSequenced = 'optimizeCarsThenSingleLocationGroupingSequenced',
  optimizeMoney = 'optimizeMoney',
  optimizeNothing = 'optimizeNothing',
  killShortRoutes = 'killShortRoutes',
  evenRoutesLength = 'evenRoutesLength',
  assignMostOrders = 'assignMostOrders',
  lowerRoutesCount = 'lowerRoutesCount',
}

export const DistributionDebugConfigurations = [
  DistributionConfiguration.optimizeNothing,
  DistributionConfiguration.killShortRoutes,
  DistributionConfiguration.evenRoutesLength,
  DistributionConfiguration.assignMostOrders,
  DistributionConfiguration.lowerRoutesCount,
];

export enum DistributionStatus {
  waiting = 'waiting',
  inProgress = 'inProgress',
  finishedInTime = 'finishedInTime',
  finishedOutOfTime = 'finishedOutOfTime',
  cancelled = 'cancelled',
  failed = 'failed',
  accepted = 'accepted',
}

export interface DistributionSettings {
  configuration: DistributionConfiguration;
  planningTime: number;
  assumptions: DistributionAssumptions;
  replanStrategy: DistributionStrategy;
  ordersDurationMultiplier: number;
  tripStartTimeStrategy: DistributionStrategy;
}

export interface DistributionInfo {
  id: Uuid;
  companyId: Uuid;
  ordersCount: number;
  settings: DistributionSettings;
  createdAt: string;
  updatedAt: string;
}

export interface Distribution {
  id: Uuid;
  inProgress: boolean;
  info: {
    planningTime: number;
    resultVersion: number;
    status: string;
    waitingTime: number;
  };
  progress: number;
  settings: DistributionSettings;
  statistics: {
    totalStatistics: any;
    tripsStatistics: Array<any>;
  };
  status: string;
  trips: Array<TripInstanceDto>;
  unplannedOrders: Array<any>;
  name: string | null;
  unassignedOrders: Array<any>;
  validations: Array<any>;
  error: any;
}
