<template>
  <div class="d-flex align-end number-picker pr-3">
    <v-btn
      flat
      small
      :ripple="false"
      :disabled="minusDisabled"
      @mousedown="minus"
      @mouseup="minusUp"
      @mouseleave="minusUp"
      ref="minus"
    >
      <v-icon>remove</v-icon>
    </v-btn>
    <v-text-field
      :label="label"
      v-model="curValue"
      menu-props="bottom"
      readonly
      hide-details
      @wheel.native.prevent="wheel"
      @keydown.native.prevent="keydown"
    />
    <v-btn
      flat
      small
      :ripple="false"
      :disabled="plusDisabled"
      @mousedown="plus"
      @mouseup="plusUp"
      @mouseleave="plusUp"
      ref="plus"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'NumberPicker',
  data() {
    return {
      curValue: 1,
      minusTimeout: null,
      minusInterval: null,
      plusTimeout: null,
      plusInterval: null,
    };
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  created() {
    if (this.value !== undefined) {
      this.curValue = this.value;
      if (this.min !== undefined) {
        this.curValue = Math.max(this.curValue, this.min);
      }
      if (this.max !== undefined) {
        this.curValue = Math.min(this.curValue, this.max);
      }
      if (this.curValue !== this.value) {
        this.$emit('input', this.curValue);
      }
    }
  },
  methods: {
    minus() {
      if (this.min === undefined || this.curValue > this.min) {
        this.curValue -= 1;
        if (!this.minusTimeout) {
          this.minusTimeout = setTimeout(() => {
            this.minusInterval = setInterval(this.minus.bind(this), 100);
          }, 500);
        }
      } else {
        this.minusUp();
      }
    },
    minusUp() {
      clearTimeout(this.minusTimeout);
      clearInterval(this.minusInterval);
      this.minusTimeout = null;
      this.minusInterval = null;
      this.$emit('input', this.curValue);
    },
    plus() {
      if (this.max === undefined || this.curValue < this.max) {
        this.curValue += 1;
        if (!this.plusTimeout) {
          this.plusTimeout = setTimeout(() => {
            this.plusInterval = setInterval(this.plus.bind(this), 100);
          }, 500);
        }
      } else {
        this.plusUp();
      }
    },
    plusUp() {
      clearTimeout(this.plusTimeout);
      clearInterval(this.plusInterval);
      this.plusTimeout = null;
      this.plusInterval = null;
      this.$emit('input', this.curValue);
    },

    wheel(ev) {
      if (ev.deltaY > 0) {
        if (this.min === undefined || this.curValue > this.min) {
          this.curValue -= 1;
        }
      } else {
        if (this.max === undefined || this.curValue < this.max) {
          this.curValue += 1;
        }
      }
      this.emitValue();
    },

    keydown(ev) {
      ev = ev || window.event;
      if (ev.keyCode === 38) {
        // up arrow
        if (this.max === undefined || this.curValue < this.max) {
          this.curValue += 1;
        }
        this.emitValue();
      } else if (ev.keyCode === 40) {
        // down arrow
        if (this.min === undefined || this.curValue > this.min) {
          this.curValue -= 1;
        }
        this.emitValue();
      }
    },

    emitValue: _.debounce(function() {
      this.$emit('input', this.curValue);
    }, 550),
  },
  computed: {
    minusDisabled() {
      if (this.min === undefined) {
        return false;
      }
      return this.curValue <= this.min;
    },
    plusDisabled() {
      if (this.max === undefined) {
        return false;
      }
      return this.curValue >= this.max;
    },
  },
  watch: {
    min() {
      if (this.curValue < this.min) {
        this.curValue = this.min;
        this.$emit('input', this.curValue);
      }
    },
    max() {
      if (this.curValue > this.max) {
        this.curValue = this.max;
        this.$emit('input', this.curValue);
      }
    },
    value() {
      if (this.curValue !== this.value) {
        this.curValue = this.value;
        if (this.min !== undefined) {
          this.curValue = Math.max(this.curValue, this.min);
        }
        if (this.max !== undefined) {
          this.curValue = Math.min(this.curValue, this.max);
        }
        if (this.curValue !== this.value) {
          this.$emit('input', this.curValue);
        }
      }
    },
  },
};
</script>

<style>
.number-picker .v-btn {
  display: flex;
  height: auto;
  margin: 0 0 5px 0;
  padding: 0;
  min-width: 24px;
  max-width: 24px;
  z-index: 1;
}

.number-picker .v-input__control {
  margin-left: -24px;
  margin-right: -24px;
  z-index: 0;
}

.number-picker .v-text-field__slot {
  margin-left: 24px;
  margin-right: 24px;
  max-width: calc(100% - 48px);
}

.number-picker .v-input__control input {
  text-align: center;
}
</style>
