<template>
  <div>
    <div v-for="(item, idx) in sanitizedValue" :key="idx">
      <div class="layout">
        <v-text-field
          v-model="item.phone"
          maxlength="12"
          :readonly="!showActions"
          :label="$t('Phone')"
          :rules="phoneRules"
          :prepend-icon="'mdi-phone'"
          @update:error="v => onErrorUpdate({ value: v, index: idx })"
        />

        <v-text-field
          v-model="item.note"
          :label="$t('Note')"
          maxlength="24"
          hide-details
          class="mb-4 ml-4"
          :readonly="!showActions"
          :append-outer-icon="canShowAddButton(idx) ? 'mdi-plus-circle-outline' : null"
          @click:append-outer="addItem(idx)"
          :append-icon="Boolean(item.phone) && showActions ? 'mdi-minus-circle-outline' : null"
          @click:append="deleteItem(idx)"
          @update:error="v => onErrorUpdate({ value: v, index: idx })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import rules from '@/api/rules';

export default Vue.extend({
  name: 'AccountContactsListEditor',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    maxCount: {
      type: Number,
      default: 3,
    },

    showActions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      flags: [],
      valid: [],
    };
  },

  computed: {
    sanitizedValue() {
      const value = this.value;

      if (value.length === 0) {
        return [
          ...this.value,
          {
            note: '',
            phone: '',
          },
        ];
      }

      return value;
    },

    phoneRules() {
      return rules.phoneLetters;
    },
  },

  methods: {
    canShowAddButton(idx) {
      if (!this.showActions) {
        return false;
      }

      const contacts = this.sanitizedValue;
      const contactsTotal = contacts.length;
      const lastContactIndex = contacts.length - 1;
      const lastContact = contacts[lastContactIndex];
      const thereMaxContacts = contactsTotal >= this.maxCount;

      return idx === lastContactIndex && !thereMaxContacts && this.isValidItem(lastContact);
    },

    onErrorUpdate({ value, index }) {
      this.valid[index] = !value;
    },

    addItem() {
      this.value.push({
        note: '',
        phone: '',
      });
    },

    deleteItem(index) {
      if (this.value.length === 1) {
        this.valid.splice(0, this.valid.length);
        this.value.splice(0, this.value.length);
      } else if (index < this.value.length) {
        this.valid.splice(index, 1);
        this.value.splice(index, 1);
      }
    },

    isValidItem(item) {
      return Boolean(item.phone && item.phone.length);
    },
  },
});
</script>
