import { mapState } from 'vuex';
import SingleMarker, { ISingleMarker } from './SingleMarker';
import MapMarker from '../primitives/MapMarker';
import { MapMarkerOptions } from '../primitives/MapMarker';
import { PointCoordinates } from '@/models/Location';
import { getIconSettings } from '@/components/map/config';
import { Place } from '@/models/Place';

export interface IPlaceMarker extends ISingleMarker {
  marker: MapMarker | null;
  place: Place | null;

  setMap(arg0: google.maps.Map): void;
  removeMarker(arg0: IPlaceMarker): void;
}

export default SingleMarker.extend({
  render(h) {
    return h();
  },

  name: 'PlaceMarker',

  data: {
    return: {
      icon: null,
    },
  },

  props: {
    place: {
      type: Object,
    },
  },

  computed: {
    ...mapState('map', ['placeRadius']),

    markerData(): MapMarkerOptions | null {
      if (!this.position) {
        return null;
      }

      return {
        position: this.position,
        icon: getIconSettings('standard'),
      };
    },

    position(): PointCoordinates | null {
      return this.place.location.coordinates;
    },

    isActive(): boolean {
      return true;
    },
  },

  async created() {
    this.updateBounds();
  },

  beforeDestroy() {
    this.$store.commit('map/clearSelection');
    this.$store.commit('map/clearBounds', 'place');
  },

  methods: {
    async createMarker() {
      if (!this.markerData) {
        return;
      }

      if (!this.marker) {
        const map = await (this as any).getMap();
        this.marker = new MapMarker(this.markerData);
        this.marker.addListener('click', () => {
          this.$emit('click', this);
        });
        this.marker.addListener('calibrate', async (newPosition: google.maps.LatLng) => {
          this.$emit('calibrate', newPosition.toJSON());
        });

        this.marker.setMap(map);
        this.$store.commit('map/setCurrentPlace', { marker: this, place: this.place });
      }
    },

    async updateBounds() {
      const map = await (this as any).getMap();

      if (map) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(this.place.location.coordinates);

        this.$store.commit('map/setBounds', { id: 'place', bounds: bounds.toJSON() });
      }
    },
  },

  watch: {
    place(e) {
      this.place;
    },
  },
});
