import Vue from 'vue';

export default Vue.extend({
  name: 'PointStayTime',

  functional: true,

  props: {
    stayTime: {
      type: Number,
      default: null,
    },

    timeInPoint: {
      type: Number,
      default: null,
    },

    isDowntime: {
      type: Boolean,
      default: false,
    },
  },

  render(h, { parent, props }) {
    return (
      <div class={props.isDowntime ? 'red--text text--darken-3' : ''}>
        {props.timeInPoint ? (
          <span>
            <span>{parent.$duration(props.timeInPoint, 'short')}</span>
            <br />
          </span>
        ) : null}
        {Number.isFinite(props.stayTime) && (
          <span>
            <v-icon color={props.isDowntime ? 'red' : ''} style="font-size: 16px">
              timer
            </v-icon>
            <span class="d-inline-flex">{parent.$duration(props.stayTime, 'short')}</span>
          </span>
        )}
      </div>
    );
  },
});
