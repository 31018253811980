<template>
  <div>
    <TripStatusIcon :value="0" :item="{ has_changes: false }" />
    <br />
    <TripStatusIcon :value="0" :item="{ has_changes: true }" />
    <br />
    <TripStatusIcon detailed :value="0" :item="{ has_changes: false }" />
    <br />
    <TripStatusIcon detailed :value="0" :item="{ has_changes: true }" />
    <br />
    <TripStatusIcon :value="1" :item="{}" />
  </div>
</template>

<script>
import { TripStatusIcon } from '@/components/icons';

export default {
  components: {
    TripStatusIcon,
  },

  data() {
    return {};
  },
};
</script>
