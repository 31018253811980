<template>
  <v-layout column>
    <v-flex pb-3>
      <v-layout class="align-end">
        <div>
          <h1>{{ $t('Trips') }}</h1>
        </div>

        <v-spacer />

        <v-flex style="max-width: 350px" mr-2>
          <v-text-field
            :disabled="!hasPermission('search')"
            append-icon="search"
            clearable
            hide-details
            v-model="searchText"
            :placeholder="$t('Search')"
            @blur="searchUpdate"
            @click:append="searchUpdate"
            @keydown.enter="searchUpdate"
          />
        </v-flex>

        <slot />

        <v-btn v-if="hasPermission('trips create')" @click="createTrip" color="primary" class="mr-0 mb-0">
          <v-icon left>add</v-icon>

          {{ $t('Create the trip') }}
        </v-btn>
      </v-layout>
    </v-flex>

    <v-flex>
      <v-card mb-5>
        <v-card-text fill-height>
          <v-form ref="form" v-model="valid">
            <v-layout row wrap align-end>
              <v-flex xs12 md5 style="background: white" v-onboarding.top="$t('helpText.tripsListFilterDate')">
                <DateRangePicker v-model="filter.date" />
              </v-flex>

              <v-spacer />

              <v-flex xs12 md6 style="background: white" v-onboarding.top="$t('helpText.tripsListFilterStatus')">
                <TripStatusPicker v-model="filter.status" ref="status" />
              </v-flex>
            </v-layout>

            <div v-if="showDetails">
              <v-layout row wrap align-end>
                <v-flex xs12 md5>
                  <BranchAccountPicker
                    prepend-icon="business"
                    :label="$t('Department or user')"
                    :items="branches"
                    v-model="filter.branches"
                  />
                </v-flex>

                <v-spacer />

                <v-flex xs12 md6>
                  <AccountPicker
                    prepend-icon="desktop_windows"
                    :label="$t('Responsible')"
                    :items="responsibles"
                    v-model="filter.responsible"
                  />
                </v-flex>
              </v-layout>

              <v-layout row wrap align-end>
                <v-flex xs12 md6>
                  <v-checkbox :label="$t('Show also former employees')" v-model="filter.inactive" />
                </v-flex>
              </v-layout>

              <v-layout row wrap align-end>
                <v-flex xs12 md5>
                  <TripEventsPicker v-model="filter.events" />
                </v-flex>
              </v-layout>
            </div>
          </v-form>

          <v-layout class="text-xs-right" align-end :column="$vuetify.breakpoint.xsOnly">
            <v-btn flat @click="toggleVisibility" v-if="!this.showDetails">
              <v-icon>arrow_downward</v-icon>
              {{ $t('More parameters') }}
            </v-btn>

            <v-btn flat @click="toggleVisibility" v-else>
              <v-icon>arrow_upward</v-icon>
              {{ $t('Less parameters') }}
            </v-btn>

            <v-flex>
              <trips-list-export :filter="filter" />

              <v-btn @click.stop="resetFilter">{{ $t('Clear') }}</v-btn>

              <v-btn @click.stop="reloadClick" :disabled="!valid">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import branch from '@/api/branch';
import { mapState, mapGetters } from 'vuex';
import TripsFilterDto from './TripsFilterDto';
import DateRangePicker from '@/components/pickers/DateRangePicker';
import BranchAccountPicker from '@/components/pickers/BranchAccountPicker';
import AccountPicker from '@/components/pickers/AccountPicker';
import TripStatusPicker from '@/components/pickers/TripStatusPicker';
import TripEventsPicker from '@/components/pickers/TripEventsPicker';
import TripsListExport from '@/components/export/TripsListExport';

export default {
  name: 'TripsFilter',

  components: {
    DateRangePicker,
    BranchAccountPicker,
    AccountPicker,
    TripStatusPicker,
    TripEventsPicker,
    TripsListExport,
  },

  props: {
    form: {
      type: TripsFilterDto,
      required: true,
    },

    show: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      valid: true,
      showDetails: this.show,
      filter: new TripsFilterDto(this.form),
      responsibles: [],
      searchText: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('branches', ['branches']),
    ...mapState('account', ['id']),
  },

  methods: {
    toggleVisibility() {
      this.showDetails = !this.showDetails;
    },

    reloadClick() {
      this.$emit('reload');
    },

    resetFilter() {
      this.filter = new TripsFilterDto();
      this.$refs.status.clear();
    },

    async getResponsibles() {
      const accounts = await branch.responsibles(this.id);
      this.responsibles = [{ accounts }];
    },

    searchUpdate() {
      this.filter.search = this.searchText;
    },

    createTrip() {
      this.$router.push({
        name: 'createTrip',
      });
    },
  },

  created() {
    this.getResponsibles();
    this.searchText = this.form.search || '';
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.valid) {
          this.$emit('filterUpdate', this.filter);
        }
      },
    },

    valid() {
      if (this.valid) {
        this.$emit('filterUpdate', this.filter);
      }
    },

    searchText(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.searchUpdate();
      }
    },
  },
};
</script>
