
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

export default Vue.extend({
  name: 'AccountInfoDetails',

  props: {
    right: Boolean,
    account: {
      type: Object,
    },
    showJobTitle: {
      type: Boolean,
      default: true,
    },
    mini: Boolean,
    showPhones: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    myFullName(): string | TranslateResult | null {
      if (!this.account) {
        return null;
      }

      if (!this.account.id) {
        return this.$t('Unassigned account');
      }

      return this.account.fullName || (this.account as any).full_name;
    },

    myJobTitle(): string | null {
      if (!this.account) {
        return null;
      }

      return this.account.jobTitle || (this.account as any).job_title;
    },

    myId(): string | number | null {
      return this.account && ((this.account as any).external_id || this.account.id);
    },

    contacts(): {}[] {
      const phones = [{ phone: this.account.phone }];

      if (Array.isArray(this.account.contacts)) {
        phones.push(...this.account.contacts);
      }

      return phones;
    },
  },
});
