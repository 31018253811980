<template>
  <div class="content">
    <v-container>
      <div v-if="num_accounts !== undefined">
        <v-divider class="custom-divider" />
        <v-icon color="grey lighen-1" right>
          person
        </v-icon>
        <span class="body-2 grey--text text--darken-1" style="vertical-align: super">{{
          $tc('{0} employees', num_accounts)
        }}</span>
      </div>
    </v-container>
    <template>
      <v-btn small @click="edit" v-onboarding.left="this.id === 0 ? $t('helpText.branchDetails') : null">
        {{ $t('Details') }}
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CompanyNode',
  props: {
    id: {
      type: Number,
      default: null,
    },
    owner: {
      type: Object,
      default: null,
    },
    can_update: { type: Boolean },
    num_accounts: {
      type: Number,
      default: undefined,
    },
  },
  components: {},
  data() {
    return {
      value: false,
    };
  },
  methods: {
    edit() {
      this.$router.push(`/branches/${this.id}`);
    },
  },
};
</script>

<style scoped>
.custom-divider {
  width: 80%;
  margin: 5px auto 0;
}
</style>
