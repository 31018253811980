import Vue from 'vue';
import Component from 'vue-class-component';
import config from './config';
import MapMarker from './primitives/MapMarker';
import Trip from '@/models/Trip';

const props = Vue.extend({
  props: {
    showCurrentPosition: {
      type: Boolean,
      default: true,
    },
  },
});

@Component
export default class MapUpdateCurrentPositionMixin extends props {
  trip!: Trip | null;
  currentPositionMarker = null as any;

  get needCurrentPosition(): boolean {
    return Boolean(this.trip && this.trip.wayPoints.every(wayPoint => !wayPoint.isCurrent()));
  }

  async updateCurrentPositionMarker() {
    if (!this.showCurrentPosition) {
      return;
    }

    const map: google.maps.Map = await (this as any).getMap();

    if (!(this as any).trip.getCurrentPosition()) {
      if (this.currentPositionMarker) {
        this.currentPositionMarker.setMap(null);
        this.currentPositionMarker = null;
      }
      return;
    }

    if (!this.currentPositionMarker) {
      const icon = {
        scaledSize: new google.maps.Size(50, 50), // scaled size
        url: config.icons.currentPosition, // url
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(25, 25), // anchor
      };

      this.currentPositionMarker = new MapMarker({
        position: (this as any).trip.getCurrentPosition(),
        secondLabel: {
          html: (this as any).updatedAtText,
          origin: new google.maps.Point(25, -5),
        },
        icon: icon,
        zindex: 999,
      });
    }

    if (this.needCurrentPosition) {
      this.currentPositionMarker.setMap(map);
      this.currentPositionMarker.setPosition((this as any).trip.getCurrentPosition());
      this.currentPositionMarker.secondLabel &&
        this.currentPositionMarker.secondLabel.set('html', (this as any).updatedAtText);
    } else {
      this.currentPositionMarker.setMap(null);
    }
  }

  clearCurrentPositionMarker() {
    if (this.currentPositionMarker) {
      this.currentPositionMarker.setMap(null);
      this.currentPositionMarker = null;
    }
  }
}
