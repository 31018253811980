import { TripPointStatus } from '@/models/TripPoint';
import { ITripMap } from './lib';
import { getIconSettings, MarkerIconName } from '../../config';
import { MapPointData } from '@/services/TripPageService';
import { MapMarkerOptions } from '../../primitives/MapMarker';

export default function(point: MapPointData, parent: ITripMap): Omit<MapMarkerOptions, 'position'> {
  const { tripPoint } = point;
  const { secondLabelInfoMap } = parent;

  let icon: MarkerIconName = 'start';
  if (tripPoint.status !== TripPointStatus.active) {
    icon = 'startPassed';
  }

  let checkin = tripPoint.checkins[0];

  let text;
  if (checkin && !checkin.isMarked() && checkin.outDate) {
    text = parent.$t('Start') + ' ' + parent.$d(checkin.outDate, 'time');
  }

  let downText;
  if (tripPoint.status === TripPointStatus.current) {
    downText = parent.updatedAtText;
  }

  const iconData = getIconSettings(icon);

  const result = {
    icon: iconData,
    secondLabel:
      text && secondLabelInfoMap
        ? {
            text: text,
            origin: iconData.secondLabelOrigin,
          }
        : undefined,
    downLabel: downText
      ? {
          html: downText,
          origin: iconData.downLabelOrigin,
        }
      : undefined,
    zindex: point.zindex,
    draggable: parent.$store.state.map.draggable,
  };

  return result;
}
