
// @ts-nocheck
import { findIndex, findLastIndex, isNil } from 'lodash';
import { VueConstructor } from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { addDays } from 'date-fns';

import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

import { Route } from 'vue-router/types/router';

import Uuid from '@/models/Uuid';
import Trip, { TripSettingsPermissions, TripStatus } from '@/models/Trip';
import Account from '@/models/Account';
import TripPoint, { TripPointStatus, TripPointType } from '@/models/TripPoint';
import TripChange from '@/models/TripChange';
import { PointCoordinates, PointLocation } from '@/models/Location';

import branchApi from '@/api/branch';
import placeApi from '@/api/place';
import { geocode } from '@/api/geocode';
import tripApi, { ImportResult, ImportResultError, TripUpdateRequestDto } from '@/api/tripNew';

import { combiningPointsToWayPoints } from '@/services/routeService';
import { getTripChanges } from '@/services/changesService';
import { TripTableRow } from '@/services/TripPageService';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import MapWidget from '@/components/map/MapWidgetNew';
import TripRoute from '@/components/map/TripRoute';
import PointInfoWindow from '@/components/map/PointInfoWindow';
import PointCalibrationWindow from '@/components/map/PointCalibrationWindow';
import MapEditDoubleClickHandler from '@/components/map/MapEditDoubleClickHandler';
import OnboardingMapFixedOverlay from '@/components/map/OnboardingMapFixedOverlay.js';
import SearchControl from '@/components/map/SearchControl';
import WayControl from '@/components/map/WayControl';
import TripPointsEditGrid from '@/components/trip/TripPointsEditGrid.vue';
import TripPageMixin from '@/components/trip/TripPageMixin';
import LoadTripDataMixin from '@/components/trip/LoadTripDataMixin';
import PointSettingsDialog from '@/components/trip/PointSettingsDialog.vue';
import PointPlaceCreateDialog from '@/components/trip/PointPlaceCreateDialog.vue';
import WayBuilderMixin from '@/components/trip/WayBuilderMixin';
import AccountPicker from '@/components/pickers/AccountPicker';
import DateTimePicker from '@/components/pickers/timepickers/DateTimePicker.vue';
import TransportTypePicker from '@/components/pickers/TransportTypePicker.vue';
import TransportPicker from '@/components/pickers/TransportPicker.vue';
import WayLatenessDialog from '@/components/modals/WayLatenessDialog.vue';
import AllowChangeWarning from '@/components/modals/AllowChangeWarning.vue';
import OverwritingPlacesDialog from '@/components/modals/OverwritingPlacesDialog.vue';
import AllowQuitPageDialog from '@/components/modals/AllowQuitPageDialog.vue';
import TripImport from '@/components/import/TripImport.vue';
import TripImportResult from '@/components/import/TripImportResult.vue';

import TripDebugger from '../../tests/trip/TripDebugger.vue';

const DEFAULT_STAY_TIME = 1800;

export default TripPageMixin.extend({
  name: 'TripEdit',

  mixins: [LoadTripDataMixin, WayBuilderMixin],

  components: {
    Breadcrumbs,
    TripDebugger,
    TripPointsEditGrid,
    MapWidget,
    TripRoute,
    PointInfoWindow: PointInfoWindow as VueConstructor, // FIXME WTF TS ???
    PointCalibrationWindow: PointCalibrationWindow as VueConstructor, // SAME
    MapEditDoubleClickHandler,
    AccountPicker,
    PointSettingsDialog,
    PointPlaceCreateDialog,
    SearchControl,
    WayControl,
    OnboardingMapFixedOverlay,
    DateTimePicker,
    TripImport,
    TripImportResult,
    WayLatenessDialog,
    AllowChangeWarning,
    AllowQuitPageDialog,
    OverwritingPlacesDialog,
    TransportTypePicker,
    TransportPicker,
  },

  data() {
    return {
      error: null as Error | null,
      trip: new Trip() as Trip,
      originalTrip: new Trip() as Trip,
      calibratedPlaces: new Map<Uuid, PointLocation>(),
      executor: null as Account | null,
      responsible: null as Account | null,

      hideMap: false,
      mapFullScreen: false,
      myBranches: [],
      responsibleAccounts: [] as Array<{ accounts: Array<{ external_id: Uuid; id: number }> }>,
      planTrip: null as Boolean | null,
      plannedAt: null as Date | null,
      wayLateness: false,
      wayDirty: false,
      smsNotification: false,
      smsText: '',
      preventTripWatch: false,
      preventRouteWatch: false,
      pointsStayTime: new Map(),
      tripDate: null,

      showImportDialog: false,
      importResult: null as ImportResult | null,
      savedReadImportFile: '',

      showPlaceDialog: false,
      placeDialogRow: null as TripTableRow | null,
      showSettingsDialog: false,
      settingsDialogRow: null as TripTableRow | null,
      showAllowChangesWarning: false,
      showOverwritingPlacesDialog: false,
      nextPagePath: '',
      isCreating: false,
    };
  },

  async created() {
    await this.fetchAccountsList();
    await this.getResponsibles();

    this.changeDefaultTransportType();
    this.setUnloadListener(this.windowUnloadHandler);
  },

  beforeRouteLeave: async function(to: Route, from, next) {
    if (!this.nextPagePath && this.newChanges.length && !['tripDetails', 'updateTrip'].includes(to.name || '')) {
      this.nextPagePath = to.fullPath;

      return;
    }

    this.clearUnloadListener();

    next();
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('company', ['settings']),

    breadcrumbs(): Array<Object> {
      const tmp: Array<Object> = [
        {
          text: this.$t('Trips'),
          click: () => {
            this.goAction({ name: 'trips' });
          },
        },
      ];

      if (this.isCreatingTrip) {
        tmp.push({
          text: this.$t('Creating trip'),
          disabled: true,
        });
      } else {
        tmp.push({
          text: this.trip && this.trip.title ? this.$t('Trip') + `: ${this.trip.title}` : this.$t('Trip'),
          click: () => {
            this.goAction({ name: 'tripDetails', params: { trip: this.trip } });
          },
        });

        tmp.push({
          text: this.$t('Editing trip'),
          disabled: true,
        });
      }

      return tmp;
    },

    newChanges(): TripChange[] {
      return getTripChanges(this.originalTrip, this.trip, (this as any).pointsETA);
    },

    isCreatingTrip(): boolean {
      return this.$route.name === 'createTrip';
    },

    isPlannedTrip(): boolean {
      return this.trip.isPlanned();
    },

    canSend(): boolean {
      if ((this.planTrip && !this.trip.plannedAt) || (this.isCreatingTrip && this.planTrip === null)) {
        return false;
      }

      if (this.isCreatingTrip) {
        return (!!this.trip.executorId || this.settings.enablePayedDrivers) && this.trip.tripPoints.length > 0;
      }

      return this.newChanges.length > 0;
    },

    sendToUserTitle(): TranslateResult {
      if (!!this.trip.executorId || !this.settings.enablePayedDrivers) {
        return this.$t('Send to user');
      } else {
        return this.$t('Create trip');
      }
    },

    smsMaxLength(): number {
      return 160;
    },

    canChooseResponsible(): boolean {
      return Boolean(this.trip.executorId);
    },

    canChooseExecutor(): boolean {
      return !this.originalTrip.executorId;
    },

    minPlannedDate(): Date {
      const step = 5 * 60 * 1000;
      const date = Math.ceil(Date.now() / step) * step + step;
      const minPlanDate = new Date(date);

      if (!isNil(this.firstPointTimeZoneOffset)) {
        const timeZoneOffset = this.firstPointTimeZoneOffset - this.currentTimeZoneOffset;
        const hoursDifference = Math.floor(timeZoneOffset / 3600);
        const minutesDifference = Math.floor((timeZoneOffset % 3600) / 60);

        minPlanDate.setHours(minPlanDate.getHours() + hoursDifference);
        minPlanDate.setMinutes(minPlanDate.getMinutes() + minutesDifference);
      }

      return minPlanDate;
    },

    correctSms(): boolean {
      if (this.smsNotification && this.smsText) {
        return this.smsText.length > this.smsMaxLength;
      }

      return false;
    },

    fullDistance(): number | undefined {
      return this.isCreatingTrip ? this.trip.way.activeWay?.summary.length : 0;
    },

    placeUpdateConflicts(): Array<ImportResultError> | [] {
      return (
        (this.importResult &&
          this.importResult.errors &&
          this.importResult.errors.filter(error => error.reason === 'PLACE_UPDATE_CONFLICT')) ||
        []
      );
    },

    importHasErrorsWithoutPlaceConflicts(): boolean {
      const importResultErrors = this.importResult && this.importResult.errors;

      return (
        Array.isArray(importResultErrors) && importResultErrors.some(error => error.reason !== 'PLACE_UPDATE_CONFLICT')
      );
    },

    firstPointTimeZoneOffset(): number | null {
      const tripPoint = this.trip.tripPoints[0];

      return tripPoint && tripPoint.location.timezone.offset;
    },

    currentTimeZoneOffset(): number {
      return new Date().getTimezoneOffset() * -60;
    },

    showRecalculateDeliveryWindows(): boolean {
      let pointHasWindows = false;

      this.trip.tripPoints.forEach(point => {
        if (point.deliveryWindows.length) {
          pointHasWindows = true;
        }
      });

      return this.planTrip !== null && pointHasWindows;
    },

    executorFromSelector(): any {
      let executor;

      this.myBranches.some((branch: any) => {
        executor = branch.accounts.find((account: any) => account.external_id === this.trip.executorId);

        return executor;
      });

      return executor;
    },
  },

  methods: {
    ...mapMutations('site', ['setUnloadListener', 'clearUnloadListener']),

    goAction(href: any) {
      if (!this.nextPagePath && this.newChanges.length) {
        this.nextPagePath = href;

        return;
      }

      this.$router.push(href);
    },

    windowUnloadHandler(e: Event) {
      e.preventDefault();

      const warning = 'Вы покидаете страницу, изменения поездки будут утеряны!';

      e.returnValue = true;

      return warning;
    },

    async fetchAccountsList() {
      this.myBranches = await branchApi.listMy();
    },

    async getResponsibles() {
      if (this.trip.executorId) {
        let executorDbId = null;

        if (this.trip.executor) {
          executorDbId = this.trip.executor.dbId;
        } else {
          for (const branch of this.myBranches) {
            // @ts-ignore
            for (const account of branch.accounts) {
              if (account.external_id === this.trip.executorId) {
                executorDbId = account.id;
              }
            }
          }
        }

        if (executorDbId) {
          const accounts = await branchApi.responsibles(executorDbId);

          this.responsibleAccounts = [{ accounts }];
        }
      }
    },

    // The method is called in the LoadTripDataMixin
    async fetchData(): Promise<void> {
      const data = await tripApi.get(parseInt(this.$route.params.id));

      this.trip = new Trip(data);

      this.fixPlannedAt();

      this.executor = new Account(data.executor);
      this.originalTrip = new Trip(data);

      if (this.trip.way.activeWay) {
        (this as any).reorderPoints(this.trip.way.activeWay);
      }
    },

    createPoint(newPointData: TripPoint): void {
      newPointData.stayTime = this.$store.getters['company/defaultStayTime'];
      newPointData.status = this.isCreatingTrip ? TripPointStatus.planned : TripPointStatus.waitingForAdd;

      let insertIndex = findLastIndex(this.trip.tripPoints, tripPoint => tripPoint.isActive());

      if (insertIndex === -1) {
        insertIndex = this.trip.tripPoints.length;
      } else if (!this.trip.tripPoints[insertIndex].isFinish()) {
        insertIndex += 1;
      }

      this.trip.tripPoints.splice(insertIndex, 0, newPointData);
      this.trip.wayPoints = combiningPointsToWayPoints(this.trip.tripPoints, this.trip.wayPoints);
    },

    makeFinish(id: Uuid): void {
      const idx = this.trip.tripPoints.findIndex(tripPoint => tripPoint.id === id);
      const tripPoint = this.trip.tripPoints[idx];
      const finishPoints = this.trip.tripPoints.filter(tripPoint => tripPoint.isFinish());

      for (const finishPoint of finishPoints) {
        if (finishPoint.type === TripPointType.finish) {
          finishPoint.type = TripPointType.scheduled;
          finishPoint.stayTime = this.pointsStayTime.get(finishPoint.id) || DEFAULT_STAY_TIME;
        }
      }

      if (tripPoint) {
        this.pointsStayTime.set(tripPoint.id, tripPoint.stayTime);

        tripPoint.type = TripPointType.finish;
        tripPoint.stayTime = null;

        this.trip.tripPoints.splice(idx, 1);
        this.trip.tripPoints.push(tripPoint);
        this.trip.wayPoints = combiningPointsToWayPoints(this.trip.tripPoints, this.trip.wayPoints);
      }
    },

    makeClient(id: Uuid): void {
      const tripPoint = this.trip.tripPoints.find(tripPoint => tripPoint.id === id);

      if (tripPoint) {
        tripPoint.type = TripPointType.scheduled;
        tripPoint.stayTime = this.pointsStayTime.get(tripPoint.id) || DEFAULT_STAY_TIME;

        this.trip.wayPoints = combiningPointsToWayPoints(this.trip.tripPoints, this.trip.wayPoints);
      }
    },

    deletePoint(id: Uuid): void {
      const idx = this.trip.tripPoints.findIndex(tripPoint => tripPoint.id === id);

      if (this.isCreatingTrip) {
        if (idx !== -1) {
          this.trip.tripPoints.splice(idx, 1);
        }
      } else {
        const tripPoint = this.trip.tripPoints[idx];
        tripPoint.status = TripPointStatus.waitingForDelete;

        this.trip.tripPoints.splice(idx, 1);

        if (this.originalTrip.tripPoints.some(originalTripPoint => originalTripPoint.id === tripPoint.id)) {
          this.trip.tripPoints.push(tripPoint);
        }
      }
    },

    undeletePoint(id: Uuid): void {
      const idx = this.trip.tripPoints.findIndex(tripPoint => tripPoint.id === id);
      const originalIdx = this.originalTrip.tripPoints.findIndex(tripPoint => tripPoint.id === id);

      if (idx !== -1 && originalIdx !== -1) {
        let finishIdx = this.trip.tripPoints.findIndex(
          tripPoint => tripPoint.isFinish() || tripPoint.isDeleted() || tripPoint.isWaitingForDelete(),
        );

        if (finishIdx === -1) {
          finishIdx = this.trip.tripPoints.length - 1;
        }

        const tripPoint = this.trip.tripPoints[idx];
        const originalTripPoint = this.originalTrip.tripPoints[originalIdx];

        if (originalTripPoint.status !== TripPointStatus.waitingForDelete) {
          tripPoint.status = originalTripPoint.status;

          this.trip.tripPoints.splice(idx, 1);
          this.trip.tripPoints.splice(finishIdx, 0, tripPoint);
        }
      }
    },

    async calibrateHandler(id: Uuid, newPosition: PointCoordinates) {
      const tripPoint = this.trip.tripPoints.find(tripPoint => tripPoint.id === id);
      const placeId = tripPoint?.placeLink?.placeId;

      if (
        tripPoint &&
        (tripPoint.location.coordinates.lat !== newPosition.lat ||
          tripPoint.location.coordinates.lng !== newPosition.lng)
      ) {
        tripPoint.location = { ...tripPoint.location };
        tripPoint.location.coordinates = newPosition;

        this.$store.commit('map/setDraggable', false);
        this.$store.commit('map/setCalibrateLoading', true);

        const data = await geocode({ coordinates: tripPoint.location.coordinates });

        this.$store.commit('map/setCalibrateLoading', false);

        if (Array.isArray(data.addresses) && data.addresses.length) {
          tripPoint.location.addresses = data.addresses;
        }

        if (data.timezone) {
          tripPoint.location.timezone = data.timezone;
        }

        if (placeId) {
          if (this.isCreatingTrip) {
            await placeApi.update(placeId, { location: tripPoint.location });
          } else {
            this.calibratedPlaces.set(placeId, tripPoint.location);
          }
        }

        this.trip.wayPoints = combiningPointsToWayPoints(this.trip.tripPoints, this.trip.wayPoints);
      }
    },

    async sendToUser(): Promise<void> {
      try {
        this.error = null;

        if (this.trip.plannedAt && new Date(this.trip.plannedAt).valueOf() < Date.now()) {
          throw new Error('Planned trip date is in past');
        }

        const { dbId } = await tripApi.createTrip(this.trip, {
          ignoreDemandLocation: Boolean(this.$route.params.copyTrip),
        });

        await this.$router.push(`/trips/${dbId}`);
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },

    fixTripPlannedAtTimezone(): void {
      if (this.plannedAt && !isNil(this.firstPointTimeZoneOffset)) {
        const timeZoneOffsetsDifferenceInSeconds = this.firstPointTimeZoneOffset - this.currentTimeZoneOffset;

        this.trip.plannedAt = new Date(this.plannedAt.getTime() - timeZoneOffsetsDifferenceInSeconds * 1000);
      } else if (this.plannedAt) {
        this.trip.plannedAt = this.plannedAt;
      }
    },

    fixPlannedAt() {
      if (this.trip.plannedAt && !isNil(this.firstPointTimeZoneOffset)) {
        this.plannedAt = new Date(
          this.trip.plannedAt.getTime() + (this.firstPointTimeZoneOffset - this.currentTimeZoneOffset) * 1000,
        );
      }
    },

    async sendChanges(): Promise<void> {
      try {
        this.error = null;
        const tripId = parseInt(this.$route.params.id);
        const changes: TripUpdateRequestDto = { changes: this.newChanges };

        if (this.smsNotification) {
          changes.sms = `${this.$t('Trip SMS', { tripTitle: this.trip.title })} \n ${this.smsText}`;
        }

        await tripApi.updateTrip(tripId, changes);
        await this.changePlaces();

        await this.$router.push(`/trips/${tripId}`);
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },

    async changePlaces(): Promise<void> {
      try {
        for (let calibratedPlace of this.calibratedPlaces) {
          await placeApi.update(calibratedPlace[0], { location: calibratedPlace[1] });
        }

        this.calibratedPlaces.clear();
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },

    onTripChange(): void {
      if (this.preventTripWatch) {
        this.preventTripWatch = false;
        return;
      }

      if (this.isCreatingTrip && !this.importHasErrorsWithoutPlaceConflicts) {
        try {
          this.error = null;

          const tripData = (this as any).populateTripDTOWithETA(this.trip.toJSON(), (this as any).pointsETA);

          if (tripData.settings) {
            tripData.settings.permissions = this.settings.tripPermissions;
          } else {
            tripData.settings = {
              ...tripData.settings,
              permissions: this.settings.tripPermissions,
            };
          }
        } catch (err) {
          console.error(err);
          this.error = err;
        }
      }
    },

    openPointSettingsDialog(tableRow: TripTableRow): void {
      this.showPlaceDialog = false;
      this.placeDialogRow = null;

      this.$store.commit('mapTooltip/hideTooltip');

      this.settingsDialogRow = tableRow;
      this.showSettingsDialog = true;
    },

    closeSettingsDialog(): void {
      this.showSettingsDialog = false;
      this.settingsDialogRow = null;
    },

    openPointPlaceCreateDialog(tableRow: TripTableRow): void {
      this.showSettingsDialog = false;
      this.settingsDialogRow = null;

      this.$store.commit('mapTooltip/hideTooltip');

      this.showPlaceDialog = true;
      this.placeDialogRow = tableRow;
    },

    closePlaceDialog(): void {
      this.showPlaceDialog = false;
      this.placeDialogRow = null;
    },

    closeOverwritingPlacesDialog(): void {
      this.showOverwritingPlacesDialog = false;
    },

    saveSettings(newSettings: any): void {
      const points: TripPoint[] = this.trip.tripPoints;
      const modifiedPoint: TripPoint | null | undefined = this.settingsDialogRow?.tripPoint;

      if (modifiedPoint) {
        const idx: number = findIndex(points, point => point.id === modifiedPoint.id);
        // TODO newSettings is table row, not the trippoint, so "newSettings" in next line
        //   should be replaced with newSettings.tripPoint or modifiedPoint.
        //   one more option - do  like in savePointPlace - assign object with only needed fields
        //   one more option - generate tableRowsData for trip edit screen in separate logic with only needed data. i think it's a better one
        //   It would work only fornot-passed points (settings dialog is working with only active points,
        //   so not big problem now)
        //   For passed rows there will be id of checkin, not point itself, so be careful
        //   I don't want to refactor it now due to side-effects, need to be checked very carefuly
        Object.assign(points[idx], newSettings);

        this.showSettingsDialog = false;
        this.settingsDialogRow = null;
      }
    },

    savePointPlace(newSettings: any): void {
      const points: TripPoint[] = this.trip.tripPoints;
      const modifiedPoint: TripPoint | null | undefined = this.placeDialogRow?.tripPoint;

      if (modifiedPoint) {
        const idx: number = findIndex(points, point => point.id === modifiedPoint.id);

        Object.assign(points[idx], {
          placeLink: newSettings.placeLink,
        });

        this.showPlaceDialog = false;
        this.placeDialogRow = null;
      }
    },

    openImportDialog(): void {
      this.showImportDialog = true;
    },

    closeImportDialog(): void {
      this.showImportDialog = false;
    },

    openAllowChangesWarning(): void {
      this.showAllowChangesWarning = true;
    },

    closeAllowChangesWarning(): void {
      this.showAllowChangesWarning = false;
    },

    closeAllowQuitPageDialog(): void {
      this.nextPagePath = '';
    },

    setAllowChanges(permissions: TripSettingsPermissions): void {
      if (!this.trip.settings) {
        this.trip.settings = {};
      }

      this.trip.settings = Object.assign({}, this.trip.settings, {
        permissions: {
          addPoint: permissions.addPoint,
          deletePoint: permissions.deletePoint,
          optimize: permissions.optimize,
          canChangeTransportType: permissions.canChangeTransportType,
          changeOrderPointsInTrip: permissions.changeOrderPointsInTrip,
          changeTimeAtPoint: permissions.changeTimeAtPoint,
          addToFavorite: permissions.addToFavorite,
          deleteFromFavorite: permissions.deleteFromFavorite,
          movePoint: permissions.movePoint,
        },
      });
    },

    setAllowQuitPage(): void {
      if (this.nextPagePath === 'openImportDialog') {
        this.showImportDialog = true;
      } else {
        this.$router.push(this.nextPagePath);
      }

      this.closeAllowQuitPageDialog();
    },

    saveReadImportFile(data: string): void {
      this.savedReadImportFile = data;
    },

    async importWithRewrite(rewrite: boolean | Array<Uuid>): Promise<void> {
      const data = this.savedReadImportFile;

      const result = await tripApi.importRoute(data, {
        rewrite,
        executorId: this.trip.executorId,
        responsibleId: this.trip.responsibleId,
        title: this.trip.title,
        plannedAt: this.trip.plannedAt,
        optimize: this.trip.way.optimize,
        transportType: this.trip.settings.transportType,
        transportId: this.trip.transportId,
        isCreating: this.isCreating,
      });

      await this.saveImportData(result);
    },

    async saveImportData(result: ImportResult): Promise<void> {
      this.showOverwritingPlacesDialog = result.hasPlaceConflict;
      this.importResult = result;
      this.trip.tripPoints = [];

      if (result && !result.hasPlaceConflict && result.trip && Array.isArray(result.trip.tripPoints)) {
        this.savedReadImportFile = '';

        this.preventTripWatch = true;
        this.preventRouteWatch = true;
        (this as any).preventWayBuild = true;

        this.trip = new Trip(result.trip);

        this.planTrip = Boolean(this.trip.plannedAt);

        this.fixPlannedAt();
      }
    },

    recalculateDeliveryWindows(): void {
      this.trip.tripPoints = this.trip.tripPoints.map(tripPoint => {
        let tripStart = this.trip.getTripStartDate();

        if (this.planTrip && !this.plannedAt) {
          tripStart = this.minPlannedDate;
        }

        tripPoint.deliveryWindows = tripPoint.deliveryWindows.map(deliveryWindow => {
          const fromDate = tripStart ? new Date(tripStart) : new Date();
          const toDate = tripStart ? new Date(tripStart) : new Date();

          fromDate.setHours(deliveryWindow.from.getHours(), deliveryWindow.from.getMinutes(), 0, 0);
          toDate.setHours(deliveryWindow.to.getHours(), deliveryWindow.to.getMinutes(), 0, 0);

          const addOneDay = fromDate > toDate;

          deliveryWindow.from = fromDate;
          deliveryWindow.to = addOneDay ? addDays(toDate, 1) : toDate;

          return deliveryWindow;
        });

        return tripPoint;
      });
    },

    changeDefaultTransportType(): void {
      if (this.trip && this.trip.settings && this.settings && this.isCreatingTrip) {
        this.trip.settings.transportType = this.settings.defaultTransportType;
      }
    },

    updateTripStatus(): void {
      if (!this.trip.executorId) {
        this.trip.status = TripStatus.notAssigned;
      } else if (this.trip.plannedAt) {
        this.trip.status = TripStatus.planned;
      } else {
        this.trip.status = TripStatus.pending;
      }
    },
  },

  watch: {
    trip: {
      deep: true,
      handler: function() {
        this.onTripChange();
      },
    },

    'trip.executorId': async function() {
      await this.getResponsibles();
      this.updateTripStatus();

      // подставляем тип транспорта выбранного исполнителя в поле "Транспортное средство" при создании поездки
      if (this.executorFromSelector && !this.trip.transportId && this.isCreatingTrip) {
        this.trip.settings.transportType = this.executorFromSelector.ownTransportType;
      }
    },

    planTrip: function(value) {
      if (!value) {
        this.plannedAt = null;
        this.trip.plannedAt = null;
      }

      this.updateTripStatus();
    },

    firstPointTimeZoneOffset() {
      this.fixTripPlannedAtTimezone();
    },

    plannedAt(value) {
      if (value) {
        this.fixTripPlannedAtTimezone();
      } else {
        this.trip.plannedAt = null;
      }
    },
  },
});
