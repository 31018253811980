import VAutocomplete from 'vuetify/es5/components/VAutocomplete';
import VSelectList from 'vuetify/es5/components/VSelect/VSelectList';
import VDivider from 'vuetify/es5/components/VDivider';

const SelectListHelper = {
  extends: VSelectList,

  methods: {
    hasItem(item) {
      // VSelect -> VMenu -> VSelectList
      return this.$parent.$parent.$parent.hasItem(item);
    },

    genDivider(item) {
      if (item.otherAccounts) {
        return this.$createElement(VDivider, {
          class: {
            'my-4': true,
          },
        });
      } else {
        return this.$createElement(VDivider);
      }
    },
  },
};

export default {
  extends: VAutocomplete,

  props: {
    parsedItemsData: Map,
  },

  methods: {
    filterDuplicates(arr) {
      return arr;
    },

    selectItem(item) {
      this.internalSearch = null;

      if (!this.hasItem(item)) {
        this.$emit('select', item);
      } else {
        this.$emit('unselect', item);
      }

      if (!this.multiple) {
        this.isMenuActive = false;
      }
    },

    hasItem(item) {
      return !!this.parsedItemsData.get(item.value);
    },
  },

  computed: {
    filteredItems() {
      if (!this.isSearching || this.noFilter) {
        return this.allItems;
      }

      let filteredItems = this.allItems.filter(i => {
        if (i.allBranch) {
          return false;
        }

        if (i.divider || i.branchHeader) {
          return true;
        }

        return this.filter(i, this.internalSearch, this.getText(i));
      });

      if (filteredItems.length === 0) {
        return filteredItems;
      }

      // Remove divider after "all branches"
      if (filteredItems[0].divider) {
        filteredItems.splice(0, 1);
      }

      // Remove empty branch sections
      for (let i = 1; i < filteredItems.length; i++) {
        if (filteredItems[i].branchHeader && (i === filteredItems.length - 1 || filteredItems[i + 1].divider)) {
          filteredItems.splice(i - 1, 2);
          i--;
        }
      }

      // Make branch headers non-selectable
      filteredItems = filteredItems.map(i => (i.branchHeader ? { header: i.name } : i));

      return filteredItems;
    },

    staticList() {
      return this.$createElement(SelectListHelper, this.listData);
    },
  },
};
