<template>
  <v-dialog v-model="showDialog" max-width="90%" scrollable>
    <v-card>
      <v-card-text>
        <v-alert :value="!!error" color="error">
          {{ error }}
        </v-alert>
        <v-textarea
          class="textarea-scroll"
          box
          style="font-family: monospace;"
          rows="1"
          v-model="tripString"
          auto-grow
          height="400"
          hide-details
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="grey" @click="closeDialog">
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn color="primary" @click="saveTrip">
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'TripJSONDialog',
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      error: false,
      showDialog: true,
      tripString: '',
    };
  },
  created() {
    if (this.value) this.updateTripString(this.value);
  },
  methods: {
    toJSONTrip(trip) {
      return {
        title: trip.title,
        executorId: trip.executorId,
        creatorId: trip.creatorId,
        responsibleId: trip.responsibleId,
        tripPoints: trip.tripPoints.map(point => this.toJSONTripPoints(point)),
        wayPoints: trip.wayPoints.map(point => this.toJSONWayPoints(point)),
        syncAt: trip.syncAt,
        plannedAt: trip.plannedAt,
        startedAt: trip.startedAt,
        finishedAt: trip.finishedAt,
        canCancel: trip.canCancel,
        id: trip.id,
        status: trip.status,
        hasChanges: trip.hasChanges,
        tripMode: trip.tripMode,
        way: trip.way,
        summaryIndicators: trip.summaryIndicators,
        mobileDeviceInfo: trip.mobileDeviceInfo,
        batteryInfo: trip.batteryInfo,
        createdAt: trip.createdAt,
        updatedAt: trip.updatedAt,
      };
    },
    toJSONTripPoints(point) {
      return {
        title: point.title,
        stayTime: point.stayTime,
        id: point.id,
        type: point.type,
        status: point.status,
        location: point.location,
        placeLink: point.placeLink,
        contacts: point.contacts,
        comment: point.comment,
        workingHours: point.workingHours,
        deliveryWindows: point.deliveryWindows,
        radius: point.radius,
        checkins: point.checkins.map(checkin => this.toJSONcheckins(checkin)),
        workTime: point.workTime,
        functions: point.functions,
        createdAt: point.createdAt,
        updatedAt: point.updatedAt,
      };
    },
    toJSONWayPoints(point) {
      return {
        id: point.id,
        coordinates: point.coordinates,
        radius: point.radius,
        tripPointIds: point.tripPointIds,
        checkins: point.checkins.map(checkin => this.toJSONcheckins(checkin)),
      };
    },
    toJSONcheckins(checkin) {
      return {
        id: checkin.id,
        inDate: checkin.inDate,
        outDate: checkin.outDate,
        status: checkin.status,
        batteryInfo: checkin.batteryInfo,
      };
    },
    closeDialog() {
      this.$emit('close');
    },
    saveTrip() {
      this.error = false;
      try {
        this.$emit('input', JSON.parse(this.tripString));
        this.$emit('close');
      } catch (e) {
        this.error = e.message;
      }
    },
    updateTripString(tripObject = this.value) {
      try {
        this.error = null;
        this.tripString = JSON.stringify(this.toJSONTrip(tripObject), undefined, 2);
      } catch (e) {
        this.error = e.message;
      }
    },
  },
  watch: {
    showDialog(value) {
      if (!value) {
        this.$emit('close');
      }
    },
    value(trip) {
      this.updateTripString(trip);
    },
  },
});
</script>
<style>
.textarea-scroll.v-textarea .v-text-field__slot textarea {
  overflow: auto !important;
}
</style>
