import httpV2 from './httpV2';

const path = '/request';

async function create(data) {
  const res = await httpV2.post(path, data);
  return res.data;
}

async function confirm(code) {
  const res = await httpV2.post(`${path}/${code}/confirm`);
  return res.data;
}

export default {
  create,
  confirm,
};
