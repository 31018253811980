
// @ts-nocheck
import Vue from 'vue';
import { reduce } from 'lodash';
import Grid from '@/components/grid/Grid';
import Tag from '@/components/Tag';
import Checkbox from '@/components/inputs/CheckboxNoState';
import { mapState } from 'vuex';
import { Dictionary } from 'vue-router/types/router';
import colors from 'vuetify/lib/util/colors';

function getIndeterminate(some: Dictionary<boolean>, every: Dictionary<boolean>): Dictionary<boolean> {
  return Object.entries(some).reduce<Dictionary<boolean>>((acc, [key, valueSome]) => {
    acc[key] = valueSome && !every[key];
    return acc;
  }, {});
}

export default Vue.extend({
  name: 'AssignTagsDialog',

  components: {
    Grid,
    Checkbox,
    Tag,
  },

  props: {
    some: Object,
    every: Object,
    loading: Boolean,
    error: {
      type: Error,
      default: null,
    },
  },

  data() {
    return {
      showDialog: true,
      selectedTags: { ...this.every } as Dictionary<boolean>,
      indeterminate: getIndeterminate(this.some, this.every),
    };
  },

  computed: {
    ...mapState('tags', ['tags']),

    colors() {
      return colors;
    },

    changedTags(): Dictionary<boolean> {
      return reduce(
        this.selectedTags,
        (acc, value, tagId) => {
          if (this.some[tagId] && !this.every[tagId] && !this.indeterminate[tagId]) {
            acc[tagId] = value;
          } else if (Boolean(value) !== Boolean(this.every[tagId])) {
            acc[tagId] = value;
          }

          return acc;
        },
        {} as Dictionary<boolean>,
      );
    },
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    handleOk() {
      this.$emit('save', this.changedTags);
    },

    changeTag(tagId: string, value: boolean) {
      if (value && this.some[tagId] && !this.every[tagId]) {
        if (!this.indeterminate[tagId]) {
          this.indeterminate[tagId] = true;
          return;
        } else {
          this.indeterminate[tagId] = false;
        }
      }

      this.selectedTags[tagId] = value;
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
