<template>
  <v-dialog v-model="showDialog" max-width="800">
    <v-card class="sm-screen-tabs">
      <v-card-title class="grey lighten-4 py-2 title">
        {{ $t('Get link') }}
      </v-card-title>
      <error-alert :value="error" />

      <v-tabs v-model="active">
        <v-tab :key="0">
          {{ $t('Point') }}
        </v-tab>
        <v-tab v-if="trip.isPendingOrPlanned() && (!trip.executor || !trip.executor.isRegular)" :key="1">
          {{ $t('Trip') }}
        </v-tab>
        <v-tabs-items touchless>
          <v-tab-item :key="0">
            <v-container grid-list-sm class="pa-4">
              <v-select
                v-model="selectedPointId"
                :label="$t('Select trip point')"
                :items="tripPoints"
                :disabled="!canChangePoint"
              />
              <v-btn v-if="!pointLinkDone" color="primary" @click="getPointLink" :disabled="!selectedPointId">
                {{ $t('Get link') }}
              </v-btn>
              <v-text-field
                v-if="pointLinkDone"
                :value="pointLink"
                :readonly="true"
                hide-details
                @focus="focusAndSelectInput"
                append-outer-icon="content_copy"
                @click:append-outer="copyContent"
                ref="formLinkInput"
              />
            </v-container>
          </v-tab-item>
          <v-tab-item :key="1">
            <v-container grid-list-sm class="pa-4">
              <v-btn v-if="!tripLinkDone" color="primary" @click="getTripLink">
                {{ $t('Get link') }}
              </v-btn>
              <v-text-field
                v-if="tripLinkDone"
                :value="tripLink"
                :readonly="true"
                hide-details
                @focus="focusAndSelectInput"
                append-outer-icon="content_copy"
                @click:append-outer="copyContent"
                ref="formLinkInput"
              />
              <v-text-field
                v-if="tripLinkDone && isDevelopment"
                :value="tripProdLink"
                label="Использовать на юните. Ссылка ниже для редиректа на поездку в приложении."
                :readonly="true"
                hide-details
              />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      <v-card-actions>
        <v-spacer />
        <v-btn flat color="primary" @click="hideModal">
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* global $ */
import Vue from 'vue';
import api from '@/api/trip';
import { TripPointType, TripPointStatus } from '@/models/TripPoint';

export default Vue.extend({
  name: '',
  props: {
    trip: {
      type: Object,
      default: () => ({}),
    },
    selectedPointId: {
      type: String,
      default: null,
    },
    canChangePoint: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: null,
      showDialog: true,
      tripLinkDone: false,
      tripLink: null,
      pointLinkDone: false,
      pointLink: null,
      active: 0,
    };
  },
  created() {
    if (this.selectedPointId) {
      this.getPointLink();
    }
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },

    // TODO!! ONLY FOR TESTING USE
    tripProdLink() {
      if (this.tripLink) {
        let index = this.tripLink.indexOf('/deeplink');
        if (index >= 0) {
          return this.tripLink.replace(this.tripLink.substring(0, index), 'https://co.homestretch.ru');
        } else {
          return this.tripLink;
        }
      }

      return this.tripLink;
    },

    points() {
      const result = [];
      this.trip.tripPoints.forEach(point => {
        result.push(point);
      });
      return result;
    },
    tripPoints() {
      const result = [];

      const showIndex = this.points > 1;

      this.points.forEach((point, index) => {
        // Показываем только запланированные точки и финиш
        if (!point.canBeShared()) {
          return;
        }

        const parts = [];

        if (showIndex) {
          parts.push(`${index}.`);
        }

        if (point.placeLink && point.placeLink.title) {
          parts.push(point.placeLink.title);
        } else if (point.title) {
          parts.push(point.title);
        } else {
          parts.push(point.getAddress());
        }
        result.push({
          value: point.id,
          text: parts.join(' '),
        });
      });
      return result;
    },
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },
    async getPointLink() {
      try {
        this.error = null;
        const data = await api.getLink(this.trip.dbId, {
          pointId: this.selectedPointId,
        });
        this.pointLink = data.url;
        this.pointLinkDone = true;
        this.focusAndSelectInput();
      } catch (err) {
        this.error = err;
      }
    },
    async getTripLink() {
      try {
        const data = await api.getDeeplink(this.trip.dbId);

        this.error = null;
        this.tripLink = data.url;
        this.tripLinkDone = true;

        this.focusAndSelectInput();
      } catch (err) {
        this.error = err;
      }
    },
    focusAndSelectInput() {
      this.$nextTick(() => {
        this.$refs.formLinkInput.focus();
        const el = this.$refs.formLinkInput.$el;
        $('input', el).select();
      });
    },
    copyContent() {
      this.$refs.formLinkInput.focus();
      const el = this.$refs.formLinkInput.$el;
      $('input', el).select();
      document.execCommand('copy');
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },

    selectedPointId() {
      this.pointLinkDone = false;
    },
  },
});
</script>
