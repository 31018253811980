<template>
  <v-menu
    lazy
    :close-on-content-click="false"
    v-model="menu"
    transition="scale-transition"
    offset-y
    full-width
    :nudge-left="-40"
    :nudge-bottom="-24"
    :max-width="maxWidth"
  >
    <v-text-field slot="activator" :label="$t('Date interval')" v-model="text" prepend-icon="date_range" readonly />

    <v-card>
      <date-range :options="options" v-model="internalValue" />

      <v-card-actions>
        <v-spacer />

        <v-btn flat color="primary" @click="cancel">
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn flat color="primary" @click="save">
          {{ $t('OK') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import DateRange from '@/components/DateRange';
import { format, subDays, startOfMonth } from 'date-fns';

export default {
  name: 'DateRangePicker',

  components: { DateRange },

  data() {
    return {
      menu: false,

      internalValue: {
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      },

      options: {
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        startTime: '00:00',
        endTime: '23:59',
        presets: [
          {
            label: this.$t('Today'),
            range: [format(new Date(), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
          },
          {
            label: this.$t('Yesterday'),
            range: [format(subDays(new Date(), 1), 'yyyy-MM-dd'), format(subDays(new Date(), 1), 'yyyy-MM-dd')],
          },
          {
            label: this.$t('Last 7 days'),
            range: [format(subDays(new Date(), 7), 'yyyy-MM-dd'), format(subDays(new Date(), 1), 'yyyy-MM-dd')],
          },
          {
            label: this.$t('Last 30 days'),
            range: [format(subDays(new Date(), 30), 'yyyy-MM-dd'), format(subDays(new Date(), 1), 'yyyy-MM-dd')],
          },
          {
            label: this.$t('This month'),
            range: [format(startOfMonth(new Date()), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
          },
        ],
      },
    };
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      }),
    },
  },

  created() {
    if (this.value && this.value.from && this.value.to) {
      this.options.startDate = format(new Date(this.value.from), 'yyyy-MM-dd');
      this.options.endDate = format(new Date(this.value.to), 'yyyy-MM-dd');
      this.options.startTime = '00:00';
      this.options.endTime = '23:59';
      this.internalValue = this.value;
    }
  },

  methods: {
    save() {
      this.menu = false;
      this.$emit('input', this.internalValue);
    },

    cancel() {
      this.menu = false;
      this.internalValue = this.value;
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.internalValue = this.value;
      },
    },
  },

  computed: {
    text() {
      if (!this.value.from && !this.value.to) {
        return this.$d(new Date(), 'standard');
      }

      return this.value.from !== this.value.to
        ? this.$d(this.value.from, 'standard') + ' — ' + this.$d(this.value.to, 'standard')
        : this.$d(this.value.from, 'standard');
    },

    maxWidth() {
      return this.$vuetify.breakpoint.smAndDown ? 300 : 800;
    },
  },
};
</script>
