import Vue from 'vue';
import TypeIcon, { defaultIconProps, IconData } from './TypeIcon';
import TripPoint, { TripPointType, TripPointStatus } from '@/models/TripPoint';

const POINT_ICONS = {
  [TripPointType.start]: { icon: 'fa-play', tooltip: 'Start' },
  [TripPointType.scheduled]: { icon: 'place', tooltip: 'Client' },
  [TripPointType.notScheduled]: { icon: 'fa-pause-circle-o', tooltip: 'Stop' },
  [TripPointType.finish]: { icon: 'fa-flag-checkered', tooltip: 'Endpoint' },
  [TripPointType.actualFinish]: { icon: 'fa-flag-checkered', color: 'red darken-2', tooltip: 'Real endpoint' },
  [TripPointStatus.waitingForAdd]: { icon: 'mdi-map-marker-plus', tooltip: 'Pending add' },
  [TripPointStatus.waitingForDelete]: { icon: 'mdi-map-marker-off', tooltip: 'Pending delete' },
  [TripPointStatus.current]: { icon: 'mdi-map-marker-radius', tooltip: 'Current' },
  [TripPointStatus.deleted]: { icon: 'mdi-map-marker-remove-variant', tooltip: 'Deleted' },
};

export default Vue.extend({
  functional: true,

  name: 'PointIcon',

  props: {
    ...defaultIconProps,
    item: TripPoint,
    status: String,
    getComputeColor: {
      type: Function,
    },
  },

  render(h, { props, parent }) {
    let {
      item: { type },
      status,
    } = props;
    const getComputeColor = props.getComputeColor;
    const tripPointType = (parent as any).item && (parent as any).item.tripPoint.type;

    let data: IconData;
    let computedColor: string;

    computedColor = getComputeColor(status, type);

    data = POINT_ICONS[type];
    if (type === TripPointType.scheduled) {
      if (status === TripPointStatus.waitingForAdd) {
        data = POINT_ICONS[TripPointStatus.waitingForAdd];
      }
      if (status === TripPointStatus.waitingForDelete) {
        data = POINT_ICONS[TripPointStatus.waitingForDelete];
      }
      if (status === TripPointStatus.deleted) {
        data = POINT_ICONS[TripPointStatus.deleted];
      }
    }

    switch (tripPointType) {
      case TripPointType.notScheduled:
        status = String(parent.$t(`typeNotScheduled.${status}`));
        break;
      case TripPointType.finish:
        status = String(parent.$t(`pointFinish.${status}`));
        break;
      case TripPointType.actualFinish:
        status = String(parent.$t(`pointFactFinish.${status}`));
        break;
      default:
        status = String(parent.$t(`standartPointStatuses.${status}`));
    }

    return h(TypeIcon, {
      props: { icon: data.icon, tooltip: status, color: computedColor, detailed: props.detailed },
    });
  },
});
