import Vue from 'vue';
import TypeIcon, { defaultIconProps, IconData } from './TypeIcon';
import { TripChangeType } from '@/models/TripChange';

const CHANGE_ICON_DATA = {
  [TripChangeType.pointAdd]: { icon: 'mdi-map-marker-plus', tooltip: 'change_type.pointAdd' },
  [TripChangeType.pointDelete]: { icon: 'mdi-map-marker-off', tooltip: 'change_type.pointDelete' },
  [TripChangeType.pointsReorder]: { icon: 'swap_vert', tooltip: 'change_type.pointsReorder' },
  [TripChangeType.tripCancel]: { icon: 'fa-user-times', tooltip: 'change_type.tripCancel' },
  [TripChangeType.pointChange]: { icon: 'timelapse', tooltip: 'change_type.pointChangeDeliveryWindows' },
  [TripChangeType.updateResponsible]: { icon: 'desktop_windows', tooltip: 'change_type.updateResponsible' },
  [TripChangeType.updateExecutor]: { icon: 'desktop_windows', tooltip: 'change_type.updateResponsible' },
  [TripChangeType.updateTransport]: { icon: 'mdi-car-cog', tooltip: 'change_type.updateTransport' },
  [TripChangeType.tripChange]: { icon: 'settings', tooltip: 'change_type.tripChange' },
  [TripChangeType.tripFinish]: { icon: 'settings', tooltip: 'change_type.tripChange' },
};

export default Vue.extend({
  functional: true,

  props: {
    ...defaultIconProps,
  },

  name: 'ChangeIcon',

  render(h, { props }) {
    const data: IconData = CHANGE_ICON_DATA[props.value as TripChangeType];

    return h(TypeIcon, {
      props: { ...props, ...data },
    });
  },
});
