import { computeSummaryIndicators } from '@/services/tripService';
import $ from 'jquery';

export default {
  methods: {
    updateWidth() {
      if (process.env.NODE_ENV !== 'test') {
        const $table = $(this.$el.firstChild.firstChild);
        $table.stickyTableHeaders('updateWidth');
      }
    },
  },

  mounted() {
    if (process.env.NODE_ENV !== 'test') {
      const $el = $(this.$el.firstChild);
      const $table = $(this.$el.firstChild.firstChild);

      $table.stickyTableHeaders({
        zIndex: 1,
        container: $el,
        fixedOffset: this.$vuetify.application.top,
      });
    }

    /*
    this.unwatch = this.$watch(
      function () {
        return this.$vuetify.application.left;
      },
      function () {
        this.$refs.grid.updateWidth();
      }
    );
    */
  },

  beforeDestroy() {
    if (process.env.NODE_ENV !== 'test') {
      const $table = $(this.$el.firstChild.firstChild);
      $table.stickyTableHeaders('destroy');
    }
  },
};
