
import Vue from 'vue';
import Grid from '@/components/grid/Grid';
import { FieldsOptions } from '@/components/grid/Options';

export default Vue.extend({
  name: 'DistributionErrors',

  components: {
    Grid,
  },

  props: {
    label: {
      type: String,
      default: 'Errors',
    },
    validationErrors: {
      type: Array,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    fields(): FieldsOptions {
      return {
        typeError: {
          label: 'ErrorType',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        codeError: {
          label: 'ErrorCode',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        messageError: {
          label: 'ErrorMessage',
          align: 'center',
          sortable: false,
          defaultSort: 1,
        },
        ...(this.showDetails
          ? {
              details: {
                label: 'Details',
                align: 'center',
                sortable: false,
                defaultSort: 1,
              },
            }
          : {}),
      };
    },
  },
});
