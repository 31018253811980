import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    pointSelectId: null,
    tableRowSelectId: null,
    currentSelectRow: null,
    currentMarker: null,
    currentPoint: null, // MapPointData
    currentPlace: null,
    draggable: false,
    calibrateLoading: false,
    placeRadius: false,
    bounds: Vue.observable({}), // Map<Uuid, google.maps.LatLngBoundsLiteral>
  },

  mutations: {
    toggleSelection(state, { marker, point, tableRowSelectId, currentSelectRow }) {
      const selectionId = point && point.wayPointId;

      if (
        state.currentPoint !== point ||
        // state.currentSelectRow !== currentSelectRow ||
        state.pointSelectId !== selectionId
      ) {
        state.tableRowSelectId = tableRowSelectId;
        state.pointSelectId = selectionId;
        state.currentPoint = point;
        state.currentMarker = marker;
        state.currentSelectRow = currentSelectRow;
        state.draggable = false;
      } else {
        state.tableRowSelectId = null;
        state.pointSelectId = null;
        state.currentPoint = null;
        state.currentMarker = null;
        state.currentSelectRow = null;
        state.draggable = false;
      }
    },

    setCurrentPoint(state, point) {
      if (state.currentPoint.id !== point.id) {
        throw new Error('Invalid setCurrentPoint()');
      }
      state.currentPoint = point;
    },

    setCurrentPlace(state, { marker, place }) {
      if (state.currentPlace !== place || state.currentMarker !== marker) {
        state.currentMarker = marker;
        state.currentPlace = place;
      } else {
        state.currentPlace = null;
        state.currentMarker = null;
      }
    },

    setDraggable(state, draggable) {
      state.draggable = draggable;
    },

    setPlaceRadius(state, placeRadius) {
      state.placeRadius = placeRadius;
    },

    clearSelection(state) {
      state.pointSelectId = null;
      state.currentPoint = null;
      state.currentPlace = null;
      state.currentMarker = null;
      state.tableRowSelectId = null;
      state.currentSelectRow = null;
    },

    clearBounds(state, id) {
      Vue.delete(state.bounds, id);
    },

    setBounds(state, { id, bounds }) {
      Vue.set(state.bounds, id, bounds);
    },

    setCalibrateLoading(state, loading) {
      state.calibrateLoading = loading;
    },
  },

  getters: {
    mapBounds(state) {
      const individualBounds = Object.values(state.bounds);

      if (!individualBounds.length) {
        return null;
      }

      const bounds = new google.maps.LatLngBounds();

      for (const data of individualBounds) {
        bounds.extend({
          lat: data.north,
          lng: data.east,
        });
        bounds.extend({
          lat: data.south,
          lng: data.west,
        });
      }

      return bounds.toJSON();
    },
  },
};
