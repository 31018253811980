
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { baseUrl, Params } from '@/api/httpV2';
import ordersApi from '@/api/orders';
import accountsApi from '@/api/account';
import transportsApi from '@/api/transports';
import { createDistributionManual } from '@/api/distributions';
import { ValidationErrorInfo } from '@/api/import';

import { DemandType, Order, Tag } from '@/models/Order';
import { IDeliveryWindowsItem } from '@/models/TripPoint';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import GridCrud, { defaultPagination } from '@/components/grid/GridCrud';
import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import DistributionOptions from '@/components/distribution/DistributionOptions.vue';
import XlsImportErrors from '@/components/import/XlsImportErrors.vue';
import { FieldsOptions } from '@/components/grid/Options';
import DateRangePicker from '@/components/pickers/DateRangePicker.vue';
import DeliveryWindows from '@/components/trip/grid/DeliveryWindows';
import TagComponent from '@/components/Tag';

export default Vue.extend({
  name: 'DistributionManual',

  components: {
    Breadcrumbs,
    Checkbox,
    DateRangePicker,
    DistributionOptions,
    XlsImportErrors,
    GridCrud,
    GridTh,
    GridSelectAll,
    DeliveryWindows,
    Tag: TagComponent,
  },

  data() {
    return {
      loading: false,
      progress: 0,
      error: null,
      validationErrors: [] as ValidationErrorInfo[],
      file: null as File | null,
      planningTime: 20,
      active: null,
      name: '',
      settings: {
        configuration: 'optimizeCarsThenSingleLocationGrouping',
        tripStartTimeStrategy: 'earliestFinish',
        planningTime: 20,
        assumptions: {
          disableTrafficJams: false,
          flightDistance: false,
          disableCompatibility: false,
          disableCapacity: false,
          sameOrderTimeWindow: false,
          expandShiftTimeWindow: false,
          optimizeInAQuickAndQualitativeWay: false,
        },
      },
      filter: {
        date: {
          from: null,
          to: null,
        },
      },
      pagination: {
        orders: { ...defaultPagination },
        accounts: { ...defaultPagination },
        transport: { ...defaultPagination },
      },
      selected: {
        orders: [] as string[],
        accounts: [] as string[],
        transport: [] as string[],
      },
      selectAllLoading: {
        orders: false,
        accounts: false,
        transport: false,
      },
      breadcrumbs: [
        {
          text: this.$t('Planning'),
          href: { name: 'distributionList' },
        },
        {
          text: this.$t('Create planning'),
          href: { name: 'distributionCreate' },
        },
        {
          text: this.$t('Manual planning'),
          href: { name: 'distributionManual' },
          disabled: true,
        },
      ],
    };
  },

  async created() {
    await this.updateTags();
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('tags', ['tags']),

    templateLink(): string {
      return baseUrl + '/distributions/template?locale=' + this.$i18n.locale;
    },

    rulesLink(): string {
      return baseUrl + '/distributions/rules?locale=' + this.$i18n.locale;
    },

    enableRun(): boolean {
      return Boolean(this.selected.orders.length && this.selected.accounts.length && this.selected.transport.length);
    },

    ordersApi(): Function {
      return ordersApi.getOrders;
    },

    ordersFields(): FieldsOptions {
      return {
        number: {
          label: 'OrdersThs.Number',
          align: 'center',
          sortable: true,
        },

        pickupAddress: {
          label: 'OrdersThs.PickupAddress',
          sortable: false,
          format: (_: never, item: Order): string => {
            const demand = item?.demands?.find(
              demand => demand.type === DemandType.pickup || demand.type === DemandType.work,
            );
            const addresses = demand?.location?.addresses;
            const coordinates = demand?.location?.coordinates;
            return Array.isArray(addresses) && addresses.length
              ? addresses[0].address
              : coordinates
              ? `${coordinates.lat}:${coordinates.lng}`
              : '';
          },
        },

        pickupDeliveryWindow: {
          label: 'OrdersThs.PickupDeliveryWindows',
          sortable: false,
          format: (_: never, item: Order): IDeliveryWindowsItem | null => {
            const demand = item?.demands?.find(
              demand => demand.type === DemandType.pickup || demand.type === DemandType.work,
            );

            if (demand?.deliveryWindows?.length) {
              return {
                deliveryWindows: demand.deliveryWindows,
                missDeliveryWindow: false,
                showDate: true,
                timezoneOffset: null,
              };
            }

            return null;
          },
        },

        dropAddress: {
          label: 'OrdersThs.DropAddress',
          sortable: false,
          format: (_: never, item: Order) => {
            const demand = item?.demands?.find(demand => demand.type === DemandType.drop);
            const addresses = demand?.location?.addresses;
            const coordinates = demand?.location?.coordinates;
            return Array.isArray(addresses) && addresses.length
              ? addresses[0].address
              : coordinates
              ? `${coordinates.lat}:${coordinates.lng}`
              : '';
          },
        },

        dropDeliveryWindow: {
          label: 'OrdersThs.DropDeliveryWindows',
          sortable: false,
          format: (_: never, item: Order): IDeliveryWindowsItem | null => {
            const demand = item?.demands?.find(demand => demand.type === DemandType.drop);

            if (demand?.deliveryWindows?.length) {
              return {
                deliveryWindows: demand.deliveryWindows,
                missDeliveryWindow: false,
                showDate: true,
                timezoneOffset: null,
              };
            }

            return null;
          },
        },

        mass: {
          label: 'OrdersThs.Mass',
          sortable: false,
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.capacity?.mass}` : '';
          },
        },

        volume: {
          label: 'OrdersThs.Volume',
          sortable: false,
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.capacity?.volume}` : '';
          },
        },

        length: {
          label: 'OrdersThs.Length',
          sortable: false,
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.length}` : '';
          },
        },

        width: {
          label: 'OrdersThs.Width',
          sortable: false,
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.width}` : '';
          },
        },

        height: {
          label: 'OrdersThs.Height',
          sortable: false,
          format: (_: never, item: Order): string => {
            const cargo = Array.isArray(item?.cargos) && item?.cargos.length ? item?.cargos[0] : null;
            return cargo ? `${cargo?.height}` : '';
          },
        },
      };
    },

    accountsApi(): Function {
      return (opts: Params) => {
        return accountsApi.list({
          ...opts,
          permissionName: 'distributions get',
        });
      };
    },

    accountsFields(): FieldsOptions {
      return {
        fullName: {
          label: 'Employee',
          sortable: true,
          defaultSort: true,
        },

        jobTitle: {
          label: 'Job title',
          sortable: true,
        },

        email: {
          label: 'E-Mail',
          sortable: true,
        },

        phone: {
          label: 'Phone',
          sortable: true,
        },

        status: {
          label: 'Status',
          sortable: true,
          format: (v: string) => {
            return this.$t(`account_status.${v.toLowerCase()}`);
          },
        },
      };
    },

    transportApi(): Function {
      return transportsApi.getTransports;
    },

    transportFields(): FieldsOptions {
      return {
        name: {
          label: 'Name',
          align: 'center',
          sortable: true,
          defaultSort: true,
        },

        number: {
          label: 'Number',
          align: 'center',
          sortable: true,
        },

        type: {
          label: 'Type',
          align: 'center',
          sortable: false,
        },

        mass: {
          label: 'Mass',
          align: 'center',
          sortable: false,
        },

        volume: {
          label: 'Volume',
          align: 'center',
          sortable: false,
        },

        capacityX: {
          label: 'CapacityX',
          align: 'center',
          sortable: false,
        },

        capacityY: {
          label: 'CapacityY',
          align: 'center',
          sortable: false,
        },

        capacityZ: {
          label: 'CapacityZ',
          align: 'center',
          sortable: false,
        },

        length: {
          label: 'Length',
          align: 'center',
          sortable: false,
        },

        height: {
          label: 'Height',
          align: 'center',
          sortable: false,
        },
      };
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),
    ...mapActions('tags', ['updateTags']),

    async runPlan() {
      try {
        this.loading = true;
        this.error = null;
        this.validationErrors = [];

        const data = {
          executors: this.selected.accounts.map(id => ({ id })),
          transports: this.selected.transport.map(id => ({ id })),
          orders: this.selected.orders.map(id => ({ id })),
          settings: this.settings,
          name: this.name,
        };

        const result = await createDistributionManual(data);

        if (result.errors && result.errors.length) {
          this.validationErrors = result.errors;
        } else {
          this.showSnackbar(this.$t('Planning has been created'));
          this.$router.push({ name: 'distributionList' });
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async selectAllOrders(value: boolean) {
      if (value !== true) {
        return;
      }

      try {
        this.selectAllLoading.orders = true;

        const orders = await ordersApi.getOrders({
          select: ['id'],
          filter: this.filter,
          rowsPerPage: -1,
          isFull: true,
        });

        this.selected.orders = orders.result.map(order => order.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading.orders = false;
      }
    },

    async selectAllAccounts(value: boolean) {
      if (value !== true) {
        return;
      }

      try {
        this.selectAllLoading.accounts = true;

        const accounts = await accountsApi.list({
          select: ['id'],
          rowsPerPage: -1,
          permissionName: 'distributions get',
        });

        this.selected.accounts = accounts.result.map(account => account.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading.accounts = false;
      }
    },

    async selectAllTransport(value: boolean) {
      if (value !== true) {
        return;
      }

      try {
        this.selectAllLoading.transport = true;

        const transports = await transportsApi.getTransports({
          select: ['id'],
          rowsPerPage: -1,
          isFull: true,
        });

        this.selected.transport = transports.result.map(transport => transport.id);
      } catch (err) {
        console.error(err);
      } finally {
        this.selectAllLoading.transport = false;
      }
    },

    getItemTags(tagIds: string[]): Tag[] {
      if (tagIds) {
        return this.tags.filter((item: Tag) => tagIds.includes(item.id));
      }

      return [];
    },
  },
});
