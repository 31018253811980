import http, { pagingParams } from './httpV2';

const path = '/pointfunctions';

async function create(data) {
  const res = await http.post(path, data);
  return res.data;
}

async function update(id, data) {
  const res = await http.put(`${path}/${id}`, data);
  return res.data;
}

async function remove(id) {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function list(opts) {
  const res = await http.get(path, {
    params: pagingParams(opts),
  });
  return res.data;
}

const PointFunctions = {};

PointFunctions.FUNCTIONS = [
  { key: 'droplist', label: 'Droplist' },
  { key: 'checklist', label: 'Checklist' },
];

export default {
  create,
  update,
  remove,
  list,
};

export { PointFunctions };
