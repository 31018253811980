import VIcon from 'vuetify/es5/components/VIcon/VIcon';

/**
 * Component to render grid header cell
 */
export default {
  name: 'GridTh',

  props: {
    /**
     * Field description object
     */
    field: {
      type: Object,
    },
    /**
     * Pagination data from grid
     */
    pagination: {
      type: Object,
      default: () => {},
    },
    /**
     * Callback to sort data in grid
     */
    sort: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    /**
     * Icon that is used for sort
     */
    sortIcon: {
      type: String,
      default: '$vuetify.icons.sort',
    },
  },

  render(h) {
    if (!this.$vnode.key) {
      console.warn('Must have key for header cells');
    }

    return h(
      'th',
      ...this.genHeaderData(this.field, this.$slots.default || [this.$t(this.field.label)], this.$vnode.key),
    );
  },

  methods: {
    /**
     * Returns header data for createElement
     */
    genHeaderData(header, children, key) {
      const classes = ['column'];
      const data = {
        key,
        attrs: {
          role: 'columnheader',
          scope: 'col',
          width: header.width || null,
          'aria-label': this.$t(header.label) || '',
          'aria-sort': 'none',
        },
        style: header.style,
      };

      if (header.sortable == null || header.sortable) {
        this.genHeaderSortingData(header, children, key, data, classes);
      } else {
        data.attrs['aria-label'] += this.$t(': Not sorted.');
      }

      const align = header.align === undefined ? 'left' : header.align;
      classes.push(`text-xs-${align}`);

      if (Array.isArray(header.class)) {
        classes.push(...header.class);
      } else if (header.class) {
        classes.push(header.class);
      }
      data.class = classes;

      return [data, children];
    },

    /**
     * Adds sorting data for header
     * @param header Header description object
     * @param children Cell children (text)
     * @param key Field name
     * @param data Cell data for createElement
     * @param classes Cell classes list
     */
    genHeaderSortingData(header, children, key, data, classes) {
      data.attrs.tabIndex = 0;
      data.on = {
        click: () => {
          this.sort(key);
        },
        keydown: e => {
          // check for space
          if (e.keyCode === 32) {
            e.preventDefault();
            this.sort(key);
          }
        },
      };

      classes.push('sortable');

      const icon = this.$createElement(
        VIcon,
        {
          props: {
            small: true,
          },
        },
        this.sortIcon,
      );

      if (!header.align || header.align === 'left') {
        children.push(icon);
      } else {
        children.unshift(icon);
      }

      if (!this.pagination) {
        console.warn('pagination is undefined in header cell');
        return;
      }

      const beingSorted = this.pagination.sortBy === key;
      if (beingSorted) {
        classes.push('active');
        if (this.pagination.descending) {
          classes.push('desc');
          data.attrs['aria-sort'] = 'descending';
          data.attrs['aria-label'] += this.$t(': Sorted descending. Activate to remove sorting.');
        } else {
          classes.push('asc');
          data.attrs['aria-sort'] = 'ascending';
          data.attrs['aria-label'] += this.$t(': Sorted ascending. Activate to sort descending.');
        }
      } else {
        data.attrs['aria-label'] += this.$t(': Not sorted. Activate to sort ascending.');
      }
    },
  },
};
