import http from '@/api/httpV2';
import Uuid from '@/models/Uuid';

const path = '/tripComments';

interface TripCommentParams {
  tripId: number;
  text: string;
}

async function getTripComments(tripId: number) {
  const res = await http.get(`${path}/${tripId}`);
  return res.data;
}

async function getCommentsCount(tripId: number) {
  const res = await http.get(`${path}/${tripId}/count`);
  return res.data;
}

async function sendCommentToTrip(data: TripCommentParams) {
  const res = await http.post(path, data);
  return res.data;
}

async function removeCommentFromTrip(tripId: number, commentId: Uuid) {
  const res = await http.delete(`${path}/${commentId}/trips/${tripId}`);
  return res.data;
}

export default {
  getTripComments,
  getCommentsCount,
  sendCommentToTrip,
  removeCommentFromTrip,
};
