
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UuidRegExp } from '@/const';

import { PointsListItem, PointsListItemDto } from '@/api/tripPoints';

import TripPoint, { TripPointStatus } from '@/models/TripPoint';
import Trip from '@/models/Trip';
import { Demand, Tag } from '@/models/Order';
import WayPoint from '@/models/WayPoint';
import { PointFunctionType } from '@/models/PointFunctions';

import DeliveryWindows from '@/components/trip/grid/DeliveryWindows';
import TimeWithOptionalDay from '@/components/TimeWithOptionalDay';
import PointSummaryIndicators from '@/components/trip/PointSummaryIndicators.vue';
import PointStayTime from '@/components/trip/grid/PointStayTime';
import Checkpoints from '@/components/trip/grid/Checkpoints';
import PointSettingsBtn from '@/components/trip/PointSettingsBtn.vue';
import TagComponent from '@/components/Tag';
import { PointFunctionsIcon } from '@/components/icons';
import Checkbox from '@/components/inputs/CheckboxNoState';

export default Vue.extend({
  name: 'PointsListRow',

  components: {
    DeliveryWindows,
    PointStayTime,
    Checkpoints,
    PointSummaryIndicators,
    TimeWithOptionalDay,
    PointSettingsBtn,
    Tag: TagComponent,
    PointFunctionsIcon,
    Checkbox,
  },

  props: {
    value: {
      type: Boolean,
    },
    item: {
      type: PointsListItem,
    },
  },

  data() {
    return {
      tripPointStatus: TripPointStatus,
    };
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('tags', ['tags']),

    tripPoint(): TripPoint | undefined {
      return this.item.tripPoint;
    },

    tripPointFunctions(): PointFunctionType[] {
      return this.tripPoint?.getFunctionsList() || [];
    },

    wayPoint(): WayPoint | undefined {
      return this.item.wayPoint;
    },

    trip(): Trip | undefined {
      return this.item.trip;
    },

    demand(): Demand | undefined {
      return this.item.demand;
    },

    deliveryWindowsItem(): any {
      return this.tripPoint
        ? {
            deliveryWindows: this.tripPoint.deliveryWindows
              ? this.tripPoint.deliveryWindows
              : this.demand
              ? this.demand.deliveryWindows
              : [],
            status: this.tripPoint.status,
            missDeliveryWindow:
              typeof this.tripPoint.missDeliveryWindowTime === 'number' && this.tripPoint.missDeliveryWindowTime > 0,
            timezoneOffset: this.tripPoint.location.timezone.offset,
          }
        : null;
    },

    itemTags(): string[] {
      if (this.demand?.tagIds) {
        return this.tags.filter((item: Tag) => this.demand?.tagIds?.includes(item.id));
      }

      return [];
    },
  },

  methods: {
    details(item: PointsListItemDto, event: Event) {
      this.$emit('details', item, event);
    },

    isUuidV4(str: string) {
      return UuidRegExp.test(str);
    },
  },
});
