import Vue from 'vue';
import VCheckbox from 'vuetify/es5/components/VCheckbox/VCheckbox';

/**
 * Component to render grid header cell with "select all" checkbox
 */
export default Vue.extend({
  name: 'GridSelectAll',

  inject: ['toggle'],

  props: {
    loading: { type: Boolean },
    dark: { type: Boolean },
    light: { type: Boolean },
    color: { type: String },
    inputValue: { type: [Boolean, Number] },
    indeterminate: { type: Boolean },
    disabled: { type: Boolean, default: false },
  },

  render(h) {
    return h(VCheckbox, {
      props: {
        loading: this.loading,
        dark: this.dark,
        light: this.light,
        color: this.color,
        hideDetails: true,
        inputValue: this.inputValue,
        indeterminate: this.indeterminate,
        onIcon: this.loading ? 'fa-spinner fa-spin' : undefined,
        offIcon: this.loading ? 'fa-spinner fa-spin' : undefined,
        indeterminateIcon: this.loading ? 'fa-spinner fa-spin' : undefined,
        disabled: this.disabled,
      },
      on: {
        change: value => {
          this.toggle(value);
          this.$emit('change', value);
        },
      },
    });
  },
});
