<template>
  <div class="date-range">
    <div class="date-range__presets" v-if="!noPresets">
      <v-list>
        <v-list-tile
          v-model="isPresetActive[index]"
          ripple
          v-for="(preset, index) in presets"
          :key="index"
          @click="onPresetSelect(index)"
        >
          <v-list-tile-content>
            {{ preset.label }}
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </div>

    <div class="date-range__pickers">
      <div class="date-range__picker date-range__pickers--start">
        <v-layout class="align-end">
          <v-flex class="mr-2">
            <v-text-field
              name="startDate"
              :label="$t('Start date')"
              class="date-range__pickers-input"
              v-model="formattedStartDate"
              readonly
            />
          </v-flex>

          <v-flex>
            <time-input
              v-model="startTime"
              @input="onDateRangeChange"
              inputWidth="160px"
              :minValue="allowedStartTimes.min"
              :maxValue="allowedStartTimes.max"
            />
          </v-flex>
        </v-layout>

        <v-date-picker
          @change="onDateRangeChange"
          :min="allowedStartDates.min"
          :max="allowedStartDates.max"
          no-title
          :first-day-of-week="1"
          :locale="locale"
          v-model="startDate"
        />
      </div>

      <div class="date-range__picker date-range__picker--end">
        <v-layout class="align-end">
          <v-flex class="mr-2">
            <v-text-field
              name="endDate"
              :label="$t('End date')"
              v-model="formattedEndDate"
              class="date-range__pickers-input"
              readonly
            />
          </v-flex>

          <v-flex>
            <time-input
              v-model="endTime"
              @input="onDateRangeChange"
              inputWidth="160px"
              :minValue="allowedEndTimes.min"
              :maxValue="allowedEndTimes.max"
            />
          </v-flex>
        </v-layout>

        <v-date-picker
          @change="onDateRangeChange"
          :min="allowedEndDates.min"
          :max="allowedEndDates.max"
          no-title
          :first-day-of-week="1"
          :locale="locale"
          v-model="endDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { format, isSameDay } from 'date-fns';
import TimeInput from '@/components/inputs/TimeInput';

export default {
  components: { TimeInput },

  props: {
    options: {
      type: Object,
      required: true,
    },
    noPresets: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
  },

  data() {
    return {
      startDate: this.options.startDate,
      endDate: this.options.endDate,
      startTime: this.options.startTime,
      endTime: this.options.endTime,
      presets: this.options.presets,
    };
  },

  computed: {
    formattedStartDate() {
      return this.$d(this.startDate, 'standard');
    },

    formattedEndDate() {
      return this.$d(this.endDate, 'standard');
    },

    isPresetActive() {
      return this.presets.map(preset => preset.range[0] === this.startDate && preset.range[1] === this.endDate);
    },

    allowedStartDates() {
      return {
        min: this.options.minDate,
        max: this.endDate,
      };
    },

    allowedEndDates() {
      return {
        min: this.startDate,
        max: null,
      };
    },

    allowedStartTimes() {
      return {
        min: this.options.minDate === this.startDate ? this.options.minTime : null,
        max: this.startDate === this.endDate ? this.endTime : null,
      };
    },

    allowedEndTimes() {
      return {
        min: this.options.minDate === this.endDate ? this.startTime : null,
        max: null,
      };
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  watch: {
    value() {
      if (this.value && this.value.from && this.value.to) {
        this.startDate = format(new Date(this.value.from), 'yyyy-MM-dd');
        this.endDate = format(new Date(this.value.to), 'yyyy-MM-dd');
        this.startTime = format(new Date(this.value.from), 'HH:mm');
        this.endTime = format(new Date(this.value.to), 'HH:mm');
      } else {
        this.startDate = format(new Date(), 'yyyy-MM-dd');
        this.endDate = format(new Date(), 'yyyy-MM-dd');
        this.startTime = '00:00';
        this.endTime = '23:59';
      }
    },

    startDate() {
      this.onDateRangeChange();
    },

    endDate() {
      this.onDateRangeChange();
    },
  },

  methods: {
    onPresetSelect(presetIndex) {
      this.startDate = this.presets[presetIndex].range[0];
      this.endDate = this.presets[presetIndex].range[1];
    },

    onDateRangeChange() {
      this.$emit('input', {
        from: new Date(`${this.startDate}T${this.startTime}:00`).toISOString(),
        to: new Date(`${this.endDate}T${this.endTime}:00`).toISOString(),
      });
    },
  },
};
</script>

<style scoped>
.date-range {
  display: flex;
}

.date-range__presets {
  margin-right: 1rem;
}

.date-range__pickers {
  display: flex;
}

.date-range__picker {
  padding: 0 1rem;
}
</style>
