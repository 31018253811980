
import Vue from 'vue';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Trip from '@/models/Trip';

export default Vue.extend({
  name: 'TripExport',

  props: {
    trip: {
      type: Trip,
      default: null,
    },
  },

  methods: {
    async exportTrip(): Promise<void> {
      if (!this.trip) {
        return;
      }

      const workbook = XLSX.utils.book_new();
      const sheet = [];
      workbook.Props = {
        Title: 'Homestretch trip',
        Author: 'HomeStretch AG',
      };

      workbook.SheetNames.push('Sheet');
      sheet.push([
        this.$t('Client'),
        this.$t('Address'),
        // this.$t('Contact'),
        this.$t('Latitude'),
        this.$t('Longitude'),
        this.$t('Review rating'),
        this.$t('Review comment'),
      ]);

      for (const tripPoint of this.trip.tripPoints) {
        const {
          location: { coordinates, addresses },
        } = tripPoint;

        const address = addresses[0].address;
        const placeName = tripPoint.placeLink?.title || tripPoint.title || '';

        sheet.push([
          placeName,
          address,
          // point.contact,
          coordinates.lat,
          coordinates.lng,
          tripPoint.review ? tripPoint.review.rating : '',
          tripPoint.review ? tripPoint.review.comment : '',
        ]);
      }

      workbook.Sheets['Sheet'] = XLSX.utils.aoa_to_sheet(sheet);
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' });
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `homestretch_trip_${this.trip.id}.xlsx`);
    },
  },
});
