
import Vue from 'vue';

export default Vue.extend({
  name: 'TraceCodeDialog',

  data() {
    return {
      showDialog: true,
      alert: null as string | null,
    };
  },

  props: {
    traceCode: {
      type: String,
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        this.hideModal();
      }
    },
  },

  methods: {
    hideModal(): void {
      this.$emit('close');
    },

    async copyTraceCode() {
      const input = this.$refs.inputTraceCode as any;
      input.focus();
      const el = input.$el;
      $('input', el).trigger('select');
      document.execCommand('copy');
      this.alert = 'success';
    },

    focusAndSelectInput() {
      this.$nextTick(() => {
        const input = this.$refs.inputTraceCode as any;
        input.focus();
        const el = input.$el;
        $('input', el).trigger('select');
      });
    },
  },
});
