
import Vue from 'vue';
import { MetaInfo } from 'vue-meta';

import RolesForm from '@/components/roles/RolesForm.vue';

export default Vue.extend({
  name: 'RolesCreate',

  components: {
    RolesForm,
  },

  metaInfo(): MetaInfo {
    return {
      title: String(this.$i18n.t('roles.create.title')),
    };
  },
});
