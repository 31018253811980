<template>
  <div>
    <v-btn round @click.native.stop="openDialog" @click="updateCost">
      {{ $t('Add account') }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px" min-width="900px">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Subscription') }}
        </v-card-title>
        <v-card-text>
          <v-layout row wrap align-end>
            <v-flex xs12 sm5> {{ $t('How many accounts should be added') }}: </v-flex>
            <v-flex xs12 sm3 class="text-sm-left">
              <number-picker v-model="numSlots" :min="minSlots" @input="changedNumSlots" />
            </v-flex> </v-layout
          ><br />
          <v-layout row wrap align-end>
            <v-flex xs12 sm5>
              <h3>{{ $t('Remaining amount of days before the end of the subscription') }}:</h3>
            </v-flex>
            <v-flex xs12 sm6>
              <h3>
                <b>{{ $d(paidLeft, 'durationDays') }}</b>
              </h3>
            </v-flex>
          </v-layout>
          <br /><v-divider /><br />
          <v-layout row wrap align-end>
            <div style="color: red">
              {{ $t('activating_additional_accounts') }}
            </div>
            <ul style="padding-left: 20px">
              <li>{{ $t('payment_individually_price_list') }}</li>
              <li>{{ $t('additional_reaching_new_tariff') }}</li>
            </ul>
          </v-layout>
          <br />
          <v-layout row wrap align-end v-if="dialog">
            <v-flex xs12 sm12>
              <payment-methods
                :invoice-url="invoiceUrl"
                :show-paid-action="showPaidAction"
                @createInvoice="createInvoice"
                @sendEmailInvoice="sendEmailInvoice"
                @changeAutoProlongationPaymentMethod="changeAutoProlongationPaymentMethod"
                ref="paymentMethods"
                :change-invoice="changeInvoice"
              />
            </v-flex>
          </v-layout>
          <v-divider /><br />
          <v-layout row wrap align-end>
            <v-flex xs12 sm12> {{ $t('Amount of additional accounts') }}: {{ numSlots }} </v-flex>
          </v-layout>
          <v-layout row wrap align-end>
            <v-flex xs12 sm12> {{ $t('Tariff') }}: {{ userPlan(plan) }} </v-flex>
          </v-layout>
          <v-layout row wrap align-end>
            <v-flex xs12 sm12>
              {{ $t('Remains before subscription ends') }}: {{ $d(paidLeft, 'durationDays') }}
            </v-flex>
          </v-layout>
          <v-layout row wrap align-end>
            <v-flex xs12 sm12>
              {{ $t('The cost for 1 account per month') }}: {{ $n(costPerSlotByMonth, 'currency', currency) }}
            </v-flex>
          </v-layout>
          <br />
          <v-layout row wrap align-end>
            <v-flex xs12 sm12>
              <b>{{ $t('Total') }}: {{ $n(totalCost, 'currency', currency) }} </b>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions v-if="showPaid">
          <v-spacer />
          <v-btn color="green darken-1" flat="flat" :disabled="!canPay" @click="buySlots">
            {{ $t('Pay') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NumberPicker from '@/components/pickers/NumberPicker';
import Common from '@/components/billing/Common';
import PaymentMethods from '@/components/billing/PaymentMethods';
import _ from 'lodash';
import api from '@/api';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    NumberPicker,
    PaymentMethods,
  },

  mixins: [Common],

  props: {
    invoiceUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dialog: false,
      numSlots: 0,
      minSlots: 1,
      plan: '',
      costPerSlotByMonth: 0,
      currency: '',
      totalCost: 0,
      showPaid: true,
      changeInvoice: false,
    };
  },
  async created() {
    await this.updateCost();
  },
  computed: {
    ...mapState('payment', ['cardInfo']),
    ...mapState(['billing']),
    ...mapGetters('billing', ['paidLeft']),
    canPay() {
      return this.cardInfo.has_id;
    },
  },
  methods: {
    buySlots() {
      this.$emit('buySlots', this.numSlots);
      this.dialog = false;
    },
    sendEmailInvoice() {
      this.$emit('sendEmailInvoice');
    },
    createInvoice() {
      this.$emit('createInvoice', this.numSlots, this.numMonth, 'SLOT');
    },
    showPaidAction(val) {
      this.showPaid = val === 'card';
    },
    updateCost: _.debounce(async function() {
      try {
        this.$emit('error', null);
        const data = await api.billing.calculate.slots(this.numSlots);
        Object.assign(this, data);
      } catch (err) {
        this.$emit('error', err);
      }
    }, 500),
    changeAutoProlongationPaymentMethod(type, status) {
      this.$emit('changeAutoProlongationPaymentMethod', type, status);
    },
    changedNumSlots() {
      this.changeInvoice = true;
      this.updateCost();
    },
    openDialog() {
      this.dialog = true;
      this.showPaid = true;
    },
  },
};
</script>
