
import Vue from 'vue';
import { mapState } from 'vuex';
import Tag from '@/components/Tag';
import colors from 'vuetify/lib/util/colors';

export default Vue.extend({
  name: 'TagsPicker',

  components: {
    Tag,
  },

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Tags',
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: 'mdi-pound',
    },
  },

  computed: {
    ...mapState('tags', ['tags']),

    colors() {
      return colors;
    },
  },

  methods: {
    change(value: string) {
      this.$emit('input', value);
    },
  },
});
