<template>
  <div>
    <error-alert :value="error" />
    <v-card>
      <v-card-text>
        {{ result }}
      </v-card-text>
    </v-card>
    <v-btn to="/debug/trip" @click="create">
      Create trip
    </v-btn>
    <TripDebugger />
  </div>
</template>

<script>
import api from '@/api/debug';
import TripDebugger from '@/../tests/trip/TripDebugger';

export default {
  components: { TripDebugger },
  data() {
    return {
      result: null,
      error: null,
    };
  },
  methods: {
    async create() {
      try {
        this.error = null;
        this.result = await api.debugQuery('trip/create', {});
      } catch (err) {
        this.error = err;
        this.result = null;
      }
    },
  },
};
</script>
