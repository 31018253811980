import uuid from 'uuid';
import Uuid from './Uuid';
import { PointFunctions, PointFunctionsDto, PointFunctionType, StayTime } from '@/models/PointFunctions';
import { OrderDto, Demand, DemandDto, Order } from '@/models/Order';
import { PlaceDto, PlaceLink } from '@/models/Place';
import { Transport } from '@/models/Transport';
import { LocationAddress, LocationAddressDto, PointLocation, PointLocationDto } from './Location';
import { Contractor } from '@/models/Contractor';
import Review, { ReviewDto } from '@/models/Review';
import { Contact } from '@/models/Contact';

export enum TripPointType {
  start = 'start',
  scheduled = 'scheduled',
  notScheduled = 'notScheduled',
  finish = 'finish',
  actualFinish = 'actualFinish',
}

export enum TripPointStatus {
  active = 'active',
  passed = 'passed',
  deleted = 'deleted',
  current = 'current',
  marked = 'marked',
  waitingForAdd = 'waitingForAdd',
  waitingForDelete = 'waitingForDelete',
  cancelled = 'cancelled',
  planned = 'planned',
}

export enum BatteryInfoState {
  unknown = 'Unknown',
  unplugged = 'Unplugged',
  charging = 'Charging',
  full = 'Full',
}

export enum CheckinStatus {
  auto = 'auto',
  manually = 'manually',
  marked = 'marked',
  current = 'current',
  byFinishTrip = 'byFinishTrip',
}

export interface BatteryInfoDto {
  level: number | null;
  state: string;
  savePowerModeEnabled: boolean;
}

export class BatteryInfo {
  level: number | null = null;
  state: BatteryInfoState = BatteryInfoState.unknown;
  savePowerModeEnabled: boolean = false;

  constructor(data?: BatteryInfoDto) {
    if (data) {
      this.level = data.level;
      this.state = data.state as BatteryInfoState;
      this.savePowerModeEnabled = data.savePowerModeEnabled;
    }
  }
}

export interface CheckinDto {
  id: Uuid;
  inDate: string | null;
  outDate: string | null;
  status: CheckinStatus;
  batteryInfo: BatteryInfoDto;
}

interface Interval {
  startDate: Date | null;
  finishDate: Date | null;
}

/**
 * Is `first` interval inside `second`
 * @param first First interval
 * @param second Second interval
 */
export function isInside(first: Interval, second: Interval): boolean {
  if (!first.startDate || !second.startDate) {
    return false;
  }

  if (!first.finishDate && !second.finishDate) {
    return first.startDate >= second.startDate;
  }

  if (!first.finishDate) {
    return false;
  }

  if (!second.finishDate) {
    return first.startDate >= second.startDate;
  }

  return first.startDate >= second.startDate && first.finishDate <= second.finishDate;
}

export function getIntervalDuration(interval: Interval): number | null {
  if (!interval.startDate) {
    return null;
  }

  if (!interval.finishDate) {
    return (new Date().getTime() - interval.startDate.getTime()) / 1000;
  }

  return (interval.finishDate.getTime() - interval.startDate.getTime()) / 1000;
}

export class Checkin {
  /** Id */
  id: Uuid;

  /** Время въезда */
  inDate: Date | null;

  /** Время выезда */
  outDate: Date | null;

  /** Чекин вручную */
  status: CheckinStatus;

  /** Информация о батарее в чекине */
  batteryInfo: BatteryInfo;

  constructor(data: CheckinDto) {
    this.id = data.id;
    this.inDate = data.inDate ? new Date(data.inDate) : null;
    this.outDate = data.outDate ? new Date(data.outDate) : null;
    this.status = data.status;
    this.batteryInfo = new BatteryInfo(data.batteryInfo);
  }

  isInside(other: Checkin) {
    return isInside(
      {
        startDate: this.inDate,
        finishDate: this.outDate,
      },
      {
        startDate: other.inDate,
        finishDate: other.outDate,
      },
    );
  }

  compareTo(other: Checkin): number {
    if (!this.inDate && !other.inDate && !this.outDate && !other.outDate) {
      return 0;
    }

    if (!this.inDate || !other.inDate) {
      return 1; // unknown
    }

    return this.inDate < other.inDate ? -1 : this.inDate.getTime() === other.inDate.getTime() ? 0 : 1;
  }

  isMarked() {
    return this.status === CheckinStatus.marked;
  }

  getDuration() {
    if (this.isMarked()) {
      return null;
    }

    return getIntervalDuration({
      startDate: this.inDate,
      finishDate: this.outDate,
    });
  }

  getTransitFrom(other: Checkin) {
    if (!this.inDate || !other.outDate) {
      return null;
    }

    return (this.inDate.getTime() - other.outDate.getTime()) / 1000;
  }

  static getIntervalDuration(inDate: Date, outDate: Date): number | null {
    return getIntervalDuration({
      startDate: inDate,
      finishDate: outDate,
    });
  }

  toJSON(): CheckinDto {
    return {
      id: this.id,
      inDate: this.inDate && this.inDate.toISOString(),
      outDate: this.outDate && this.outDate.toISOString(),
      status: this.status,
      batteryInfo: this.batteryInfo,
    };
  }
}

export class TripPointCheckin extends Checkin {
  protected tripPoint: TripPoint;

  constructor(data: CheckinDto, parent: TripPoint) {
    super(data);
    this.tripPoint = parent;
  }

  getTripPoint() {
    return this.tripPoint;
  }

  getWayPoint() {
    return null;
  }
}

export interface PointRadius {
  checkin: number;
  checkout: number;
}

export interface WorkingHours {
  from: string;
  to: string;
}

export interface DeliveryWindowsDto {
  from: string;
  to: string;
}

export class DeliveryWindows {
  from: Date;
  to: Date;

  constructor(data: DeliveryWindowsDto) {
    this.from = new Date(data.from);
    this.to = new Date(data.to);
  }

  toJSON(): DeliveryWindowsDto {
    return {
      from: this.from && this.from.toISOString(),
      to: this.to && this.to.toISOString(),
    };
  }
}

export interface IDeliveryWindowsItem {
  deliveryWindows: DeliveryWindows[] | null;
  missDeliveryWindow: boolean;
  showDate: boolean;
  timezoneOffset: number | null;
}

export interface SmsNotification {
  text: string;
  type: string;
  minutesToArrival: number;
  status: string;
  contact: string;
}

export interface AttachFile {
  id: any;
  attachType: any;
  fileUri: {
    original: string;
    preview: string;
  };
  createdAt: any;
  updatedAt: any;
}

export interface TripPointDto {
  id: Uuid;
  title: string | null;
  type: string;
  status: string;
  position?: number | null;
  stayTime: StayTime;
  location: PointLocationDto;
  placeLink: PlaceLink | null;
  checkins: CheckinDto[];
  contacts: Contact[];
  comment: string | null;
  workingHours: WorkingHours[];
  deliveryWindows: DeliveryWindowsDto[];
  smsNotification?: SmsNotification | null;
  attachFiles?: AttachFile[];
  functions: PointFunctionsDto | null;
  radius: PointRadius;
  createdAt: string;
  updatedAt: string;
  duration?: number;
  orders?: OrderDto[];
  contractors?: Contractor[];
  arrivalPlanAt?: string | null;
  arrivalPredictAt?: string | null;
  place?: PlaceDto | null;
  demandIds?: Uuid[] | null;
  demands?: DemandDto[] | null;
  transport: Transport | null;

  maxStayTime?: number | null;
  plannedWorkTime?: number | null;
  timeInPoint?: number | null;
  totalWorkTime?: number | null;
  stayTimeExceeded?: number | null; // простой в точке в секундах
  workTimeExceeded?: number | null; // простой по работам в секундах
  missDeliveryWindowTime?: number | null; // опоздание в секундах
  activePointsCount?: number | null; // количество точек до доставки в секундах
  activePointsStayTime?: number | null; // общее время активных клиентов

  arrivalDuration?: number | null;
  departureDuration?: number | null;
  transportRestrictions?: string[] | null;
  review: ReviewDto | null;
}

export default class TripPoint {
  /** ID точки */
  id: Uuid;

  /** Название */
  title: string | null = null;

  /** Тип точки */
  type: TripPointType;

  /** Статус точки */
  status: TripPointStatus;

  /** Номер точки */
  position: number | null = null;

  /** Запланированное время пребывания в точке */
  stayTime: StayTime = null;

  /** Location */
  location: PointLocation;

  /** Связь точки с избранной */
  placeLink: PlaceLink | null;

  /** Чекины */
  checkins: TripPointCheckin[];

  /** Контакты */
  contacts: Contact[];

  /** Комментарий */
  comment: string | null;

  /** Время работы */
  workingHours: WorkingHours[];

  /** Окно доставки */
  deliveryWindows: DeliveryWindows[];

  /* Смс оповещения */
  smsNotification: SmsNotification | null;

  /* Прикрепленные файлы */
  attachFiles: AttachFile[];

  /** Функции маршрутной точки */
  functions: PointFunctions;

  /** Радиусы чекина-чекаута */
  radius: PointRadius;

  /** Дата создания */
  createdAt: Date;

  /** Дата создания */
  updatedAt: Date;

  /** Дата прибытия */
  arrivalPlanAt: Date | null = null;

  /** Предполагаемая дата прибытия */
  arrivalPredictAt: Date | null = null;

  maxStayTime: number | null = null;

  plannedWorkTime: number | null = null;

  /** Заказ */
  orders: Order[] | null;

  /** Контрагент */
  contractors: Contractor[] | null;

  demandIds: Uuid[] | null = null;

  demands: Demand[] | null = null;

  place: PlaceDto | null = null;

  transport: Transport | null;

  timeInPoint: number | null = null;
  totalWorkTime: number | null = null;
  stayTimeExceeded: number | null = null; // простой в точке в секундах
  workTimeExceeded: number | null = null; // простой по работам в секундах
  missDeliveryWindowTime: number | null = null; // опоздание в секундах

  activePointsCount: number | null = null; // количество точек до доставки в секундах
  activePointsStayTime: number | null = null; // общее время активных клиентов

  /** Время подъезда к точке” */
  arrivalDuration?: number | null = null;

  /** Время выезда из точки */
  departureDuration?: number | null = null;

  /** Требования к транспорту */
  transportRestrictions?: string[] | null = null;

  /** Отзыв */
  review: Review | null;

  constructor(data: Partial<TripPointDto>) {
    this.id = data.id || uuid();
    this.title = data.title !== undefined ? data.title : null;
    this.type = data.type as TripPointType;
    this.status = data.status as TripPointStatus;
    this.position = typeof data.position === 'number' ? data.position : null;
    this.stayTime = data.stayTime || null;
    this.transport = null;
    this.location = (data.location as PointLocation) || {
      id: uuid(),
      googlePlaceId: null,
      coordinates: {
        lat: 0,
        lng: 0,
      },
      timezone: {
        name: '',
        offset: null,
      },
      addresses: [
        {
          locale: '',
          address: '',
          addressComponents: null,
        },
      ],
      arrivalDuration: null,
      departureDuration: null,
      transportRestrictions: [],
    };
    this.placeLink = data.placeLink as PlaceLink;
    this.contacts = data.contacts || [];
    this.comment = data.comment !== undefined ? data.comment : null;
    this.workingHours = data.workingHours || [];
    this.deliveryWindows = data.deliveryWindows
      ? data.deliveryWindows.map(deliveryWindow => new DeliveryWindows(deliveryWindow))
      : [];
    this.smsNotification = data.smsNotification || null;
    this.attachFiles = data.attachFiles || [];
    this.radius = data.radius || {
      checkin: 0,
      checkout: 0,
    };

    this.checkins = data.checkins ? data.checkins.map(checkin => new TripPointCheckin(checkin, this)) : [];
    this.functions = new PointFunctions(data.functions);

    this.createdAt = data.createdAt ? new Date(data.createdAt) : new Date();
    this.updatedAt = data.updatedAt ? new Date(data.updatedAt) : new Date();

    this.arrivalPlanAt = data.arrivalPlanAt ? new Date(data.arrivalPlanAt) : null;
    this.arrivalPredictAt = data.arrivalPredictAt ? new Date(data.arrivalPredictAt) : new Date();

    this.place = data.place || null;
    this.orders = (data.orders && data.orders.map(order => new Order(order))) || null;
    this.contractors = data.contractors !== undefined ? data.contractors : null;
    this.demandIds = data.demandIds || null;
    this.demands = (data.demands && data.demands.map(demand => new Demand(demand))) || null;

    this.transport = data.transport || null;

    this.maxStayTime = data.maxStayTime !== undefined ? data.maxStayTime : null;
    this.plannedWorkTime = data.plannedWorkTime !== undefined ? data.plannedWorkTime : null;

    this.timeInPoint = data.timeInPoint !== undefined ? data.timeInPoint : null;
    this.totalWorkTime = data.totalWorkTime !== undefined ? data.totalWorkTime : null;
    this.stayTimeExceeded = data.stayTimeExceeded !== undefined ? data.stayTimeExceeded : null;
    this.workTimeExceeded = data.workTimeExceeded !== undefined ? data.workTimeExceeded : null;
    this.missDeliveryWindowTime = data.missDeliveryWindowTime !== undefined ? data.missDeliveryWindowTime : null;

    this.activePointsCount = data.activePointsCount !== undefined ? data.activePointsCount : null;
    this.activePointsStayTime = data.activePointsStayTime !== undefined ? data.activePointsStayTime : null;
    this.review = data.review ? new Review(data.review) : null;
  }

  isStart() {
    return this.type === TripPointType.start;
  }

  isNotScheduled() {
    return this.type === TripPointType.notScheduled;
  }

  isScheduled() {
    return this.type === TripPointType.scheduled;
  }

  isFinish() {
    return this.type === TripPointType.finish;
  }

  isActualFinish() {
    return this.type === TripPointType.actualFinish;
  }

  isActive() {
    return [TripPointStatus.active, TripPointStatus.planned, TripPointStatus.waitingForAdd].includes(this.status);
  }

  isCancelled() {
    return this.status === TripPointStatus.cancelled;
  }

  isCurrent() {
    return this.status === TripPointStatus.current;
  }

  isDeleted() {
    return this.status === TripPointStatus.deleted;
  }

  isPassed() {
    return this.status === TripPointStatus.passed;
  }

  isWaitingForDelete() {
    return this.status === TripPointStatus.waitingForDelete;
  }

  isUpdatable() {}

  getAddress(): string | null {
    return this.getLocationAddress()?.address || null;
  }

  getLocationAddress(): LocationAddress | null {
    if (!this.location.addresses.length) {
      return null;
    }

    return this.location.addresses[0];
  }

  getFunctionsList(): PointFunctionType[] {
    let functionsList: PointFunctionType[] = [];
    if (
      (this.contacts && this.contacts.length) ||
      (this.placeLink && this.placeLink.contacts && this.placeLink.contacts.length)
    ) {
      functionsList.push('contacts');
    }
    if (this.comment) {
      functionsList.push('comment');
    }
    if (this.functions) {
      if (this.functions.checklist && this.functions.checklist.length) {
        functionsList.push('checklist');
      }
      if (this.functions.droplist && this.functions.droplist.length) {
        functionsList.push('droplist');
      }
      if (this.smsNotification) {
        functionsList.push('smsNotification');
      }
      if (this.attachFiles && this.attachFiles.length) {
        functionsList.push('attach');
      }
    }

    return functionsList;
  }

  getCoordinates() {
    return this.location.coordinates;
  }

  isNew() {
    return this.type === TripPointType.scheduled && this.status === TripPointStatus.waitingForAdd;
  }

  canBeShared() {
    if (![TripPointType.scheduled, TripPointType.finish].includes(this.type)) {
      return false;
    }

    if (![TripPointStatus.active, TripPointStatus.planned].includes(this.status)) {
      return false;
    }

    return true;
  }

  get massOrders(): number {
    return (
      this.orders?.reduce(
        (acc, order) => acc + order.cargos.reduce((acc2, cargo) => acc2 + cargo.capacity.mass, 0),
        0,
      ) || 0
    );
  }

  get volumeOrders(): number {
    return (
      this.orders?.reduce(
        (acc, order) => acc + order.cargos.reduce((acc2, cargo) => acc2 + cargo.capacity.volume, 0),
        0,
      ) || 0
    );
  }

  toJSON(): TripPointDto {
    return {
      title: this.title,
      stayTime: this.stayTime,
      id: this.id,
      type: this.type,
      status: this.status,
      location: this.location,
      placeLink: this.placeLink,
      contacts: this.contacts,
      comment: this.comment,
      workingHours: this.workingHours,
      deliveryWindows: this.deliveryWindows.map(
        // TODO change to deliveryWindow.toJSON then DateTimeRangeCompactPicker fixed
        deliveryWindow => new DeliveryWindows((deliveryWindow as any) as DeliveryWindowsDto).toJSON(),
      ),
      smsNotification: this.smsNotification,
      attachFiles: this.attachFiles,
      radius: this.radius,
      checkins: this.checkins.map(checkin => checkin.toJSON()),
      functions: this.functions,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      arrivalPlanAt: this.arrivalPlanAt && this.arrivalPlanAt.toISOString(),
      arrivalPredictAt: this.arrivalPredictAt && this.arrivalPredictAt.toISOString(),
      demandIds: this.demandIds,
      demands: this.demands && this.demands.map(demand => demand.toJSON()),
      transport: this.transport,
      arrivalDuration: this.arrivalDuration,
      departureDuration: this.departureDuration,
      transportRestrictions: this.transportRestrictions,
      review: this.review && this.review.toJSON(),
    };
  }
}
