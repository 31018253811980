<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('TransportPage.title') }}: {{ data.number }}</h1>
    </v-flex>

    <v-flex>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <error-alert :value="error" />

            <v-alert color="success" :value="!!successText">
              {{ successText }}
            </v-alert>

            <v-layout row wrap>
              <v-flex lg6 xs12>
                <div class="title">{{ $t('Main parameters') }}</div>

                <div class="ml-4">
                  <AccountPicker :rules="rules.owner" :items="owners" v-model="data.ownerId" single uuid>
                    <template #label>
                      <strong class="red--text">* </strong>
                      {{ $t('TransportPage.owner') }}
                    </template>
                  </AccountPicker>

                  <v-text-field
                    v-model.trim="data.name"
                    :rules="rules.name"
                    :error-messages="fieldErrors.name"
                    @keydown.prevent.enter="enter"
                    autocomplete="no"
                  >
                    <template #label>
                      <strong class="red--text">* </strong>
                      {{ $t('TransportPage.name') }}
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model.trim="data.number"
                    :label="$t('TransportPage.number')"
                    :rules="rules.number"
                    :error-messages="fieldErrors.number"
                    @keydown.prevent.enter="enter"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.trim="data.comment"
                    :label="$t('TransportPage.comment')"
                    @keydown.prevent.enter="enter"
                    autocomplete="no"
                  />

                  <TransportTypePicker v-model="data.type" :prepend-icon="false" />
                </div>

                <div class="subheading">{{ $t('TransportPage.dimensionsTitle') }}</div>

                <div class="ml-4">
                  <v-text-field
                    v-model.number="firstBox.length"
                    :label="$t('TransportPage.length')"
                    :rules="rules.length"
                    :error-messages="fieldErrors.length"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.number="firstBox.width"
                    :label="$t('TransportPage.width')"
                    :rules="rules.width"
                    :error-messages="fieldErrors.width"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.number="firstBox.height"
                    :label="$t('TransportPage.height')"
                    :rules="rules.height"
                    :error-messages="fieldErrors.height"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.number="firstBox.capacity.capacityY"
                    :label="$t('TransportPage.maxLength')"
                    :rules="rules.maxLength"
                    :error-messages="fieldErrors.maxLength"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.number="firstBox.capacity.capacityX"
                    :label="$t('TransportPage.maxWidth')"
                    :rules="rules.maxWidth"
                    :error-messages="fieldErrors.maxWidth"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.number="firstBox.capacity.capacityZ"
                    :label="$t('TransportPage.maxHeight')"
                    :rules="rules.maxHeight"
                    :error-messages="fieldErrors.maxHeight"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.number="firstBox.capacity.mass"
                    :label="$t('TransportPage.mass')"
                    :rules="rules.mass"
                    :error-messages="fieldErrors.mass"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />

                  <v-text-field
                    v-model.number="firstBox.capacity.volume"
                    :label="$t('TransportPage.volume')"
                    :rules="rules.volume"
                    :error-messages="fieldErrors.volume"
                    @keydown.prevent.enter="enter"
                    type="number"
                    autocomplete="no"
                  />
                </div>
              </v-flex>

              <v-spacer style="max-width: 24px" class="lg-only" />
              <v-spacer style="max-width: 64px" class="xl-only" />

              <v-flex lg5 xs12>
                <DefaultShiftParameters v-model="data" unit="km" />

                <div class="title">{{ $t('Additional parameters') }}</div>

                <div class="ml-4">
                  <ArrayParameterInput v-model="data" path="features" :label="$t('Features')" />

                  <ArrayParameterInput
                    v-model="data"
                    path="executorRestrictions"
                    :label="$t('Executor restrictions')"
                  />

                  <ArrayParameterInput v-model="data" path="boxFeatures" :label="$t('Box features')" />
                </div>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container py-0 pb-1 class="text-xs-right">
            <v-btn wrap @click="back">
              {{ $t('Back') }}
            </v-btn>

            <v-btn @click="save" :disabled="!isEnableSaveButton" color="primary">
              {{ $t('Save') }}
            </v-btn>
          </v-container>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '@/api/transports';
import rules from '@/api/rules';
import { collectAllErrors } from '@/api/utils';
import DefaultShiftParameters from '@/components/DefaultShiftParameters.vue';
import ArrayParameterInput from '@/components/inputs/ArrayParameterInput.vue';
import TransportTypePicker from '@/components/pickers/TransportTypePicker.vue';
import AccountPicker from '@/components/pickers/AccountPicker';
import branch from '@/api/branch';

function getDefaultBox() {
  return {
    capacity: {},
  };
}

export default {
  name: 'TransportDetails',

  components: {
    DefaultShiftParameters,
    ArrayParameterInput,
    TransportTypePicker,
    AccountPicker,
  },

  metaInfo() {
    return {
      title: this.$i18n.t('Transport'),
    };
  },

  data() {
    return {
      id: null,
      data: {},
      valid: false,
      error: null,
      firstBox: getDefaultBox(),
      showChangePasswordDialog: false,
      showRestoreNotification: false,
      successText: '',
      owners: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    isEnableSaveButton() {
      return (
        ((!this.id && this.hasPermission('transports create')) ||
          (this.id && this.hasPermission('transports update'))) &&
        this.valid
      );
    },

    rules() {
      return {
        name: rules.required('TransportPage.name'),
        owner: rules.required('TransportPage.owner'),
      };
    },

    fieldErrors() {
      if (this.error && this.error.errors) {
        return collectAllErrors(this.error.errors);
      }

      return {};
    },

    boxNotEmpty() {
      return (
        this.firstBox.length ||
        this.firstBox.width ||
        this.firstBox.height ||
        this.firstBox.capacity.maxLength ||
        this.firstBox.capacity.maxWidth ||
        this.firstBox.capacity.maxHeight ||
        this.firstBox.capacity.mass ||
        this.firstBox.capacity.volume ||
        (Array.isArray(this.data.boxFeatures) && this.data.boxFeatures.length)
      );
    },

    outputData() {
      const data = { ...this.data };

      if (!data.type) {
        data.type = 'car';
      }

      delete data.dbId;
      delete data.updatedAt;
      delete data.createdAt;
      delete data.updatedBy;
      delete data.createdBy;
      delete data.createdAt;
      delete data.companyId;
      delete data.owner;

      if (this.firstBox && this.boxNotEmpty) {
        data.boxes[0] = this.firstBox;
        data.boxes[0].features = data.boxFeatures;
      } else {
        data.boxes = [];
      }

      delete data.boxFeatures;

      return data;
    },
  },

  async created() {
    if (this.$route.params.id !== 'new') {
      this.id = this.$route.params.id;

      await this.fetchData();
    }

    this.prepareDefaults();

    await this.fetchAccountsList();
  },

  methods: {
    async fetchData() {
      try {
        this.data = await api.getTransport(this.id);
      } catch (err) {
        this.error = err;
      }
    },

    async fetchAccountsList() {
      try {
        this.owners = await branch.listMy();
      } catch (err) {
        this.error = err;
      }
    },

    prepareDefaults() {
      if (!this.data.boxes || !this.data.boxes.length) {
        this.data.boxes = [getDefaultBox()];
      }

      if (!this.data.features) {
        this.data.features = [];
      }

      if (!this.data.defaultShift) {
        this.data.defaultShift = {};
      }

      if (!this.data.executorRestrictions) {
        this.data.executorRestrictions = [];
      }

      if (!this.data.boxFeatures) {
        if (this.data.boxes && this.data.boxes.length) {
          this.$set(this.data, 'boxFeatures', this.data.boxes[0].features);
        } else {
          this.$set(this.data, 'boxFeatures', []);
        }
      }

      this.firstBox = this.data.boxes[0];
      this.data.type = this.data.type || 'car';
    },

    async save() {
      try {
        this.error = null;

        if (this.id) {
          await api.patchTransport(this.data.id, this.outputData);
        } else {
          await api.createTransport(this.outputData);
        }

        this.back();
      } catch (err) {
        console.error(err);
        this.error = err;
      }
    },

    back() {
      this.$router.push(this.$route.params.back || '/transports');
    },

    enter() {
      if (this.valid) {
        this.save();
      }
    },
  },
};
</script>
