
import Vue from 'vue';
import Vue2Timepicker from './Vue2Timepicker.vue';

export default Vue.extend({
  name: 'TimeInput',

  components: { Vue2Timepicker },

  props: {
    value: { type: [Number, String], default: null },

    label: { type: String, default: null },
    prependIcon: { type: String, default: null },

    disabled: { type: Boolean, default: false },
    manualInput: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },

    minValue: { type: [Number, String], default: 0 },
    maxValue: { type: [Number, String], default: null },

    inputWidth: { type: String },
    rules: { type: Array },

    duration: { type: Boolean },
  },

  data() {
    return {
      time: {
        HH: '00',
        mm: '00',
      },
    };
  },

  created() {
    this.fetchData(this.value);
  },

  computed: {
    isMobile(): boolean {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent,
      );
    },

    minValueInSeconds(): number | null {
      if (this.minValue) {
        if (this.duration) {
          return Number(this.minValue);
        } else {
          const convertedMinTime = this.convertStringTimeToTime(this.minValue as any);

          if (convertedMinTime) {
            return this.convertTimeToNumber(convertedMinTime);
          }
        }
      }

      return null;
    },

    maxValueInSeconds(): number | null {
      if (this.maxValue) {
        if (this.duration) {
          return Number(this.maxValue);
        } else {
          const convertedMaxTime = this.convertStringTimeToTime(this.maxValue as any);

          if (convertedMaxTime) {
            return this.convertTimeToNumber(convertedMaxTime);
          }
        }
      }

      return null;
    },

    hoursRange(): number[] | null {
      if (!this.minValue && !this.maxValue) {
        return null;
      }

      const minValueInSeconds: number = this.minValueInSeconds || 0;
      const maxValueInSeconds: number = this.maxValueInSeconds || 24 * 3600;

      let hours: number[] = [];

      let chosenMinutesInSeconds = Number(this.time.mm) * 60;

      for (let i = 0; i < 24; i++) {
        let computedValue = i.valueOf() * 3600 + chosenMinutesInSeconds;
        if (computedValue >= minValueInSeconds && computedValue <= maxValueInSeconds) {
          hours.push(i);
        }
      }

      return hours;
    },

    minutesRange(): number[] | null {
      if (!this.minValue && !this.maxValue) {
        return null;
      }

      const minValueInSeconds: number = this.minValueInSeconds || 0;
      const maxValueInSeconds: number = this.maxValueInSeconds || 24 * 3600;

      let minutes: number[] = [];

      let chosenHoursInSeconds = Number(this.time.HH) * 3600;

      for (let i = 0; i < 60; i++) {
        let computedValue = chosenHoursInSeconds + i * 60;
        if (computedValue >= minValueInSeconds && computedValue <= maxValueInSeconds) {
          minutes.push(i);
        }
      }

      return minutes;
    },
  },

  methods: {
    fetchData(value: any | null) {
      if (value === '' || value === ':') {
        this.time = { HH: '', mm: '' };
      } else if (value === null || value === undefined) {
        return;
      } else if (this.duration) {
        this.time = this.convertNumberToTime(value.valueOf()) || { HH: '00', mm: '00' };
      } else {
        this.time = this.convertStringTimeToTime(value) || { HH: '00', mm: '00' };
      }
    },

    convertNumberToTime(value: number): { HH: string; mm: string } | null {
      if (value >= 0 && value <= 24 * 3600) {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        return {
          HH: String(hours >= 10 ? hours : '0' + hours),
          mm: String(minutes >= 10 ? minutes : '0' + minutes),
        };
      } else {
        return null;
      }
    },

    convertTimeToNumber(time: { HH: string; mm: string }): number {
      const { HH, mm } = time;
      return Number(HH) * 3600 + Number(mm) * 60;
    },

    convertStringTimeToTime(value: String): { HH: string; mm: string } | null {
      const splittedTime = value.split(':');
      if (splittedTime.length > 1) {
        const hours = splittedTime[0];
        const minutes = splittedTime[1];
        return {
          HH: hours,
          mm: minutes,
        };
      } else {
        return null;
      }
    },

    convertTimeToStringTime(time: { HH: string; mm: string }): string {
      return `${time.HH}:${time.mm}`;
    },
  },

  watch: {
    time: {
      deep: true,
      handler(value) {
        if (this.duration) {
          this.$emit('input', this.convertTimeToNumber(value));
        } else {
          this.$emit('input', this.convertTimeToStringTime(value));
        }
      },
    },
    value(newValue, oldValue) {
      if (newValue !== null && oldValue !== undefined && newValue !== oldValue) {
        this.fetchData(newValue);
      }
    },
  },
});
