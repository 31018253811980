<template>
  <div>
    <error-alert :value="error" />
    <v-form ref="form" v-model="valid">
      <template v-if="country === 'ru'">
        <v-text-field v-model="form.company_name" :rules="companyNameRules" :label="$t('Company name')" required />

        <v-text-field v-model="form.email" :rules="emailRules" :label="$t('Email for billing')" required />
        <v-layout row wrap align-end>
          <v-flex sm5>
            <v-text-field
              v-model="form.itn"
              :rules="itnRules"
              :mask="mask.itn"
              label="ИНН"
              :counter="itnCounter"
              required
            />
          </v-flex>
          <v-flex sm2 />
          <v-flex sm5>
            <v-text-field v-model="form.kpp" :rules="kppRules" :mask="mask.kpp" label="КПП" :counter="9" required />
          </v-flex>
          <v-flex sm12>
            <v-menu
              lazy
              :close-on-content-click="false"
              v-model="menu"
              ref="menu"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <v-text-field
                slot="activator"
                :label="$t('Date of registration')"
                v-model="form.date_of_state_registration"
                :rules="dateOfStateRegistrationRules"
                prepend-icon="event"
                readonly
                required
              />
              <v-date-picker
                v-model="form.date_of_state_registration"
                :first-day-of-week="1"
                no-title
                scrollable
                actions
                :locale="$i18n.locale"
              >
                <v-spacer />
                <v-btn flat color="primary" @click="menu = false">
                  {{ $t('Cancel') }}
                </v-btn>
                <v-btn flat color="primary" @click="$refs.menu.save(date)">
                  {{ $t('Save') }}
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-text-field v-model="form.name_of_director" :rules="nameOfDirectorRules" label="ФИО директора" required />

        <v-text-field v-model="form.legal_address" :rules="legalAddressRules" :label="$t('Legal address')" required />

        <v-text-field v-model="form.post_address" :rules="postAddressRules" :label="$t('Mailing address')" required />

        <v-text-field v-model="form.bank_name" :rules="bankNameRules" :label="$t('Bank')" required />

        <v-layout row wrap align-end>
          <v-flex sm5>
            <v-text-field
              v-model="form.bank_bic"
              :rules="bankBicRules"
              :counter="9"
              :mask="mask.bic"
              :label="$t('Bank code')"
              required
            />
          </v-flex>
          <v-flex sm2 />
          <v-flex sm5>
            <v-text-field
              v-model="form.checking_account"
              :rules="checkingAccountRules"
              :mask="mask.checkingAccount"
              label="Р/С"
              :counter="20"
              required
            />
          </v-flex>
        </v-layout>

        <v-text-field v-model="form.bank_address" :rules="bankAddressRules" :label="$t('Bank address')" required />
      </template>
      <template v-else>
        <v-text-field v-model="form.company_name" :rules="companyNameRules" :label="$t('Company name')" required />

        <v-text-field v-model="form.email" :rules="emailRules" :label="$t('Email for billing')" required />

        <v-autocomplete
          :label="$t('Country')"
          :items="countriesList"
          item-text="name"
          item-value="name"
          v-model="form.country"
        />

        <v-text-field v-model="form.city" :label="$t('City')" required />

        <v-text-field v-model="form.street" :label="$t('Street')" required />

        <v-text-field v-model="form.zip_code" :label="$t('Zip code')" required />

        <!-- <v-text-field
          v-model="form.legal_address"
          :rules="legalAddressRules"
          :label="$t('Address')"
          required /> -->

        <v-text-field v-model="form.vat_number" :label="$t('VAT number')" required />
      </template>

      <v-btn :disabled="!valid" @click="submit">
        {{ $t('Save') }}
      </v-btn>
      <v-btn v-if="invoiceCanClose" @click.stop="closeForm">
        {{ $t('Close') }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import api from '@/api';
import { mapState } from 'vuex';
import { getCountriesList } from '@/i18n';

export default {
  props: {
    invoiceCanClose: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      default: null,
    },
    defaultPaymentMethod: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        email: this.invoice.email,
        company_name: this.invoice.company_name,
        country: this.invoice.country || null,
        city: this.invoice.city || null,
        street: this.invoice.street || null,
        zip_code: this.invoice.zip_code || null,
        itn: this.invoice.itn,
        kpp: this.invoice.kpp,
        date_of_state_registration: this.invoice.date_of_state_registration,
        name_of_director: this.invoice.name_of_director,
        legal_address: this.invoice.legal_address,
        post_address: this.invoice.post_address,
        bank_name: this.invoice.bank_name,
        bank_bic: this.invoice.bank_bic,
        checking_account: this.invoice.checking_account,
        bank_address: this.invoice.bank_address,
        auto_prolongation: this.invoice.auto_prolongation,
        default_payment_method: this.defaultPaymentMethod,
        vat_number: this.invoice.vat_number || null,
      },

      error: null,

      mask: {
        itn: '############',
        kpp: '#########',
        bic: '#########',
        checkingAccount: '####################',
      },

      valid: false,
      companyNameRules: [v => !!v || this.$t('Company name required')],

      itnRules: [
        v => !!v || 'ИНН обязателен',
        v => (v && (v.length === 10 || v.length === 12)) || 'ИНН должен содержать 10 или 12 знаков',
      ],

      kppRules: [v => !!v || 'КПП обязателен', v => (v && v.length === 9) || 'КПП должен содержать 9 знаков'],

      dateOfStateRegistrationRules: [
        v => !!v || this.$t('Date of registration is required'),
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],

      nameOfDirectorRules: [v => !!v || 'ФИО директора обязательно'],

      legalAddressRules: [v => !!v || this.$t('Legal address is required')],

      postAddressRules: [v => !!v || this.$t('Mailing address is required')],

      bankNameRules: [v => !!v || this.$t('Bank name required')],

      bankBicRules: [v => !!v || this.$t('Bank code is required'), v => (v && v.length === 9) || this.$t('bank_code')],

      checkingAccountRules: [
        v => !!v || 'Р/С обязателен',
        v => (v && v.length === 20) || 'Р/С должен содержать 20 знаков',
      ],

      bankAddressRules: [v => !!v || this.$t('Bank address is required')],

      emailRules: [
        v => !!v || this.$t('Email mandatory'),
        v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || this.$t('Please enter a valid email');
        },
      ],
      date: null,
      menu: false,
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          const form = this.form;
          const data = await api.billing.invoice.update({ ...form });
          if (!data.error) {
            this.$emit('update', form);
          }
        }
      } catch (e) {
        this.errors = e;
        console.error(e);
      }
    },
    closeForm() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapState('company', ['country']),

    itnCounter() {
      return this.form.itn.length > 10 ? 12 : 10;
    },

    countriesList() {
      return getCountriesList();
    },
  },
  watch: {
    '$i18n.locale'() {
      this.$refs.form.validate();
    },
  },
};
</script>
