
import Vue from 'vue';
import OverwritingPlace from '@/components/modals/OverwritingPlace.vue';
import { ImportResultError } from '@/api/tripNew';
import Uuid from '@/models/Uuid';

export default Vue.extend({
  name: 'OverwritingPlacesDialog',

  components: { OverwritingPlace },

  data() {
    return {
      placesForOverwriting: [] as Array<Uuid>,
      showDialog: true,
      conflicts: [] as Array<ImportResultError>,
    };
  },

  props: {
    value: {
      type: Array as () => Array<ImportResultError>,
      default: [],
    },
  },

  created() {
    this.conflicts = [...this.value];
  },

  methods: {
    overwritePlace(id: Uuid): void {
      this.conflicts = this.conflicts.filter(conflict => conflict.currentData!.id !== id);
      this.placesForOverwriting.push(id);
    },
    keepCurrent(id: Uuid): void {
      this.conflicts = this.conflicts.filter(conflict => conflict.currentData!.id !== id);
    },
    overwirteAll(): void {
      this.$emit('rewrite', true);
      this.hideModal();
    },
    keepAll(): void {
      this.$emit('rewrite', []);
      this.hideModal();
    },
    hideModal(): void {
      this.$emit('close');
    },
  },

  watch: {
    conflicts(value, oldValue) {
      if (value.length === 0 && oldValue.length !== 0) {
        this.$emit('rewrite', this.placesForOverwriting);
        this.hideModal();
      }
    },
    showDialog() {
      if (!this.showDialog) {
        this.hideModal();
      }
    },
  },
});
