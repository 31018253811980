import Vue, { PropType } from 'vue';
import { mapState, mapMutations } from 'vuex';
import Component from 'vue-class-component';

import { groupMarkerFactory, pointMarkerFactory, radarMarkerFactory } from './PointMarkerFactory';
import Uuid from '@/models/Uuid';
import MapMarker from './primitives/MapMarker';
import { MapGroupData, MapPointData } from '@/services/TripPageService';
import { IPointMarker } from './markers/PointMarker';
import { IGroupMarker } from './markers/GroupMarker';
import { PointCoordinates } from '@/models/Location';

type IMarker = IPointMarker | IGroupMarker;

const props = Vue.extend({
  computed: {
    ...mapState('map', ['currentMarker', 'currentPoint', 'tableRowSelectId']),
  },

  methods: {
    ...mapMutations('map', ['setCurrentPoint']),
  },

  props: {
    points: {
      type: Array as PropType<MapPointData[]>,
      default: () => [],
    },
    groups: {
      type: Array as PropType<MapGroupData[]>,
      default: () => [],
    },
    isRadar: {
      type: Boolean,
      default: false,
    },
    showMarker: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    points: {
      deep: true,
      handler: function() {
        (this as any).updateMarkers();
      },
    },
    groups: {
      deep: true,
      handler: function() {
        (this as any).updateMarkers();
      },
    },
  },
});

@Component
export default class MapUpdateMarkersMixin extends props {
  getMap!: { (): Promise<google.maps.Map> };

  pointMarkers = new Map<Uuid, IMarker>();
  updatedPosition = false;
  trip!: any;

  get markers(): MapMarker[] {
    const result = [];
    for (const point of this.pointMarkers.values()) {
      if (point.marker) {
        result.push(point.marker);
      }
    }
    return result;
  }

  getPointMarkerFactory(): (point: MapPointData, parent: Vue) => IPointMarker {
    if (this.isRadar) {
      return radarMarkerFactory;
    }

    return pointMarkerFactory;
  }

  async updateMarkers() {
    if (!this.showMarker) {
      return;
    }

    const map = await this.getMap();

    const createdMarkers = new Set<Uuid>();
    const newPointMarkers = new Map<Uuid, IMarker>();
    const oldPointMarkers: Map<Uuid, IMarker> = this.pointMarkers;

    for (const point of this.points) {
      if (this.currentPoint && point.id === this.currentPoint.id && point !== this.currentPoint) {
        this.setCurrentPoint(point);
      }

      let oldMarker = oldPointMarkers.get(point.id);
      let oldGroup = oldPointMarkers.get(oldMarker?.point?.groupId as any) as IGroupMarker | null;

      if (oldGroup && oldMarker?.point && oldMarker.point.groupId !== point.groupId) {
        oldGroup.removeMarker(oldMarker);
      }

      let marker = oldMarker;

      if (!marker) {
        const markerFactory = this.getPointMarkerFactory();

        marker = markerFactory(point, this);

        (marker as any).$on('click', (marker: Vue) => (this as any).markerClick(marker));
        (marker as any).$on('calibrate', (newPosition: PointCoordinates) => {
          this.$emit('calibrate', point.id, newPosition);
        });
        createdMarkers.add(point.id);
      } else {
        marker.point = point;
      }

      let group: IGroupMarker | undefined;
      if (groupMarkerFactory && point.groupId) {
        let groupId = point.groupId;
        group = (oldPointMarkers.get(groupId) || newPointMarkers.get(groupId)) as IGroupMarker;

        const groupData = this.groups.find(g => g.id === groupId);
        if (groupData) {
          if (!group) {
            const points = this.points.filter(p => p.groupId === groupId);
            group = groupMarkerFactory(groupData, points, this);
          } else {
            group.groupData = groupData;
          }
        }

        newPointMarkers.set(groupId, group);
      }

      if (group) {
        group.addMarker(marker);
      }

      newPointMarkers.set(point.id, marker);
    }

    for (const entry of newPointMarkers) {
      oldPointMarkers.delete(entry[0]);
    }

    for (const entry of oldPointMarkers) {
      const point = entry[1].point;
      if (point?.groupId) {
        const group = newPointMarkers.get(point.groupId);
        group?.removeMarker(entry[1]);
      }

      entry[1].$destroy();
    }

    if (oldPointMarkers.size > 0 || createdMarkers.size > 0) {
      this.updateBounds();
    }

    this.pointMarkers = newPointMarkers;

    // if (this.updateSelection) {
    //   this.updateSelection();
    // }
  }

  clearMarkers() {
    for (const point of this.pointMarkers.values()) {
      point.$destroy();
    }
  }

  updateBounds() {}
}
