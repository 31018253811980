import http from './httpV2';
import { Shift } from '@/models/Shift';

const path = '/shifts';

interface ShiftsResponse {
  result: Array<Shift>;
  total: number;
}

async function getShifts(): Promise<ShiftsResponse> {
  const res = await http.get(path);
  return res.data;
}

export default {
  getShifts,
};
