
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Grid, { defaultPagination } from '@/components/grid/GridCrud';
import tags from '@/api/tags';
import { Tag } from '@/models/Order';
import { AUTORELOAD_TIME } from '@/const';
import CreateTag from '@/components/modals/tags/CreateTag.vue';
import EditTag from '@/components/modals/tags/EditTag.vue';
import TagDeletionWarning from '@/components/modals/tags/TagDeletionWarning.vue';
import TagColorGridField from '@/components/tags/TagColorGridField.vue';

export default Vue.extend({
  name: 'Tags',

  components: { Grid, CreateTag, EditTag, TagDeletionWarning },

  data() {
    return {
      timer: null as number | null,
      error: null,
      tagToEdit: null as Tag | null,
      showCreateTagDialog: false,
      deletionWarning: false,
      deletionTag: null as Tag | null,
      pagination: { ...defaultPagination },
      apiFunction: (opts: any) => tags.getTags(opts),
      click: () => {},
      perPageItems: [10, 100, 150, { text: this.$t('All'), value: -1 }],
      perPage: 100,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    fields(): object {
      return {
        color: {
          label: 'TagsThs.Color',
          component: TagColorGridField,
          align: 'center',
          sortable: false,
          defaultSort: 1,
          width: 80,
        },
        name: {
          label: 'TagsThs.Name',
          align: 'left',
          sortable: false,
          defaultSort: 1,
        },
        actions: {
          label: 'TagsThs.Actions',
          align: 'left',
          sortable: false,
          width: 150,
        },
      };
    },
  },

  async created(): Promise<void> {
    await this.fetchData();
    this.timer = setInterval(this.fetchData, AUTORELOAD_TIME);
  },

  beforeDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  methods: {
    async fetchData(): Promise<void> {
      try {
        if (this.$refs.grid) {
          // @ts-ignore
          this.$refs.grid.refresh();
        }
      } catch (err) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        this.error = err;
      }
    },

    setTagToEdit(tag: Tag): void {
      this.tagToEdit = tag;
    },

    closeEditTag(): void {
      this.tagToEdit = null;
      this.fetchData();
    },

    openCreateTagDialog(): void {
      this.showCreateTagDialog = true;
    },

    closeCreateTagDialog(): void {
      this.showCreateTagDialog = false;
      this.fetchData();
    },

    deleteTag(tag: Tag): void {
      this.deletionTag = tag;
      this.deletionWarning = true;
    },

    closeWarning(): void {
      this.deletionWarning = false;
      this.deletionTag = null;
      this.fetchData();
    },
  },
});
