
import Vue from 'vue';

export default Vue.extend({
  name: 'AllowChangeWarning',

  data() {
    return {
      showDialog: true,
      allChecked: false,
    };
  },

  props: {
    value: Object,
  },

  created() {
    if (!Object.values(this.value).some(value => !value)) {
      this.allChecked = true;
    }
  },

  methods: {
    allowChangeTrip(): void {
      this.$emit('allow-changes', this.value);
      this.$emit('close');
    },
    hideModal() {
      this.$emit('close');
    },
    allCheckboxClicked(): void {
      this.value.addPoint = this.allChecked;
      this.value.deletePoint = this.allChecked;
      this.value.optimize = this.allChecked;
      this.value.canChangeTransportType = this.allChecked;
      this.value.changeOrderPointsInTrip = this.allChecked;
      this.value.changeTimeAtPoint = this.allChecked;
      this.value.addToFavorite = this.allChecked;
      this.value.deleteFromFavorite = this.allChecked;
      this.value.movePoint = this.allChecked;

      this.$forceUpdate();
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
