import Vue from 'vue';

export default Vue.extend({
  name: 'BatteryInfo',

  functional: true,

  props: {
    value: {
      type: Object,
    },
    detailed: Boolean,
  },

  render(h, { data, props, children, parent }) {
    if (!props.value) {
      return;
    }

    const level = props.value.level;
    const state = props.value.state;
    const detailed = props.detailed;
    let icon, color, text, shade;
    let detailedText = '';

    if (!state || state === 'Unknown' || typeof level !== 'number') {
      icon = 'mdi-battery-unknown';
    } else {
      let iconVariant;

      if (level < 0.1) {
        iconVariant = '-outline';
      } else if (level >= 0.1 && level < 1) {
        iconVariant = '-' + Math.floor(level * 10) + '0';
      } else if (level === 1) {
        if (state === 'Charging') {
          iconVariant = '-100';
        } else {
          iconVariant = '';
        }
      }

      if (level < 0.21) {
        color = 'red';
        shade = 'darken-2';
      } else if (level >= 0.21 && level < 0.41) {
        color = 'orange';
        shade = 'lighten-1';
      } else if (level >= 0.41 && level < 0.61) {
        color = 'amber';
        shade = 'lighten-1';
      } else if (level >= 0.61 && level < 0.81) {
        color = 'light-green';
        shade = 'darken-1';
      } else {
        color = 'green';
        shade = 'darken-2';
      }

      if (state === 'Charging') {
        icon = 'mdi-battery-charging' + iconVariant;

        if (detailed) {
          detailedText = parent.$t('charging');
        }
      } else if (state === 'Full') {
        icon = 'mdi-battery';
      } else if (state === 'Unplugged') {
        icon = 'mdi-battery' + iconVariant;
      }

      text = ' ' + Math.floor(level * 100) + '%' + ' ' + detailedText;
    }

    return h('span', { class: `${color}--text` + ' ' + `text--${shade}` }, [
      h('v-icon', { props: { color: color + ' ' + shade, size: 18 } }, [icon]),
      text,
    ]);
  },
});
