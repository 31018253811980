export default {
  namespaced: true,

  state: {
    new_messages_count_by_trip_ids: 0,
  },

  mutations: {
    setNewMessagesCountByTripIds(state, data) {
      state.new_messages_count_by_trip_ids = data;
    },
  },
};
