import http, { pagingParams, Params } from './httpV2';
import Uuid from '@/models/Uuid';

const path = '/transports';

export interface Transport {
  id: Uuid;
  name: string;
  boxes: {
    id: Uuid;
    width: number;
    height: number;
    length: number;
    capacity?: {
      mass: number;
      volume: number;
      capacityX: number;
      capacityY: number;
      capacityZ: number;
    };
  }[];
}

interface TransportsResponce {
  result: Array<Transport>;
  total: number;
}

interface TransportsListParams extends Params {
  isFull?: boolean;
}

async function getTransport(id: Uuid): Promise<Transport> {
  const res = await http.get(`${path}/${id}`);
  return res.data;
}

async function getTransports(params: TransportsListParams = {}): Promise<TransportsResponce> {
  const res = await http.get(path, {
    params: {
      ...pagingParams(params),
    },
  });
  return res.data;
}

async function patchTransport(id: Uuid, data: any): Promise<any> {
  const res = await http.patch(`${path}/${id}`, data);
  return res.data;
}

async function deleteTransport(id: Uuid): Promise<any> {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function createTransport(data: {}): Promise<any> {
  const res = await http.post(path, data);
  return res.data;
}

export default {
  getTransport,
  getTransports,
  patchTransport,
  deleteTransport,
  createTransport,
};
