
import Vue from 'vue';
import { baseUrl } from '@/api/httpV2';

export default Vue.extend({
  name: 'TripPointsListExport',

  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    exportLink(this: any): string {
      return `${baseUrl}/tripPoints/exports/xlsx?locale=${this.$i18n.locale}&${this.filter.toQueryStringParams()}`;
    },
  },
});
