<template>
  <v-layout row align-baseline>
    <v-flex xs6 class="filter-range-title subheading">
      {{ $t(labelTitle) }}
    </v-flex>

    <v-flex xs6>
      <v-input :prepend-icon="icon" v-model="value" :rules="rangeRules" ref="input">
        <span>{{ $t(labelFrom) }}</span>

        <v-text-field
          v-model="internalValue.from"
          @input="updateFrom"
          @keydown="keyDownFrom"
          ref="from"
          class="input-picker pt-0 mt-0"
          hide-details
          placeholder="0"
          clearable
          style="min-width: 60px"
        />

        <span>{{ $t(labelTo) }}</span>

        <v-text-field
          v-model="internalValue.to"
          @input="updateTo"
          @keydown="keyDownTo"
          ref="to"
          class="input-picker pt-0 mt-0"
          hide-details
          placeholder="0"
          clearable
          style="min-width: 60px"
        />
        <span>{{ $t(labelUnit) }}</span>
      </v-input>
    </v-flex>
  </v-layout>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
  name: 'NumRangePicker',

  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    icon: {
      type: String,
      default: '',
    },
    labelFrom: {
      type: String,
      default: 'from',
    },
    labelTo: {
      type: String,
      default: 'to',
    },
    labelUnit: {
      type: String,
      default: 'min',
    },
    labelTitle: {
      type: String,
      default: 'Choose interval',
    },
    allowFloat: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      internalValue: {
        from: this.format(this.value.from),
        to: this.format(this.value.to),
      },
    };
  },

  computed: {
    allowedKeys() {
      if (this.allowFloat) {
        return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
      }

      return ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    },

    rangeRules() {
      return [
        v => {
          if (v && !_.isNil(v.from) && !_.isNil(v.to)) {
            return v.from <= v.to || this.$t('rules.range', [this.$t(this.labelFrom), this.$t(this.labelTo)]);
          }

          return true;
        },
      ];
    },
  },

  mounted() {
    this.$refs.input.validate(true);
  },

  methods: {
    parse(value) {
      if (typeof value === 'string') {
        value = value.replace(',', '.');
      }

      return _.isNil(value) || value === '' ? null : parseFloat(value) * this.multiple;
    },

    format(value) {
      return _.isNil(value) ? '' : this.$n(parseFloat(value) / this.multiple, 'plain');
    },

    updateFrom(value) {
      this.$emit('input', {
        to: this.value.to,
        from: this.parse(value),
      });
    },

    updateTo(value) {
      this.$emit('input', {
        to: this.parse(value),
        from: this.value.from,
      });
    },

    keyDownFrom(e) {
      this.checkFormat(e, this.internalValue.from, this.$refs.from.$refs.input);
    },

    keyDownTo(e) {
      this.checkFormat(e, this.internalValue.to, this.$refs.to.$refs.input);
    },

    checkFormat(e, value, input) {
      // console.log(e.key, e.charCode, e.keyCode, input.selectionStart);
      if (e.key.length !== 1) {
        return;
      }

      if (!this.allowedKeys.includes(e.key)) {
        e.preventDefault();
        return;
      }

      if (this.allowFloat) {
        const pos = input.selectionStart;
        const newValue = input.value.substr(0, pos) + e.key + input.value.substr(pos);

        if (!/^[0-9]+(|[.,]|[.,][0-9])$/.test(newValue)) {
          e.preventDefault();
        }
      }
    },
  },

  watch: {
    'value.from'() {
      if (this.parse(this.internalValue.from) !== this.value.from) {
        this.internalValue.from = this.format(this.value.from);
      }
    },

    'value.to'() {
      if (this.parse(this.internalValue.to) !== this.value.to) {
        this.internalValue.to = this.format(this.value.to);
      }
    },

    '$i18n.locale'() {
      this.internalValue.from = this.format(this.value.from);
      this.internalValue.to = this.format(this.value.to);
      this.$refs.input.validate();
    },
  },
});
</script>
