import _ from 'lodash';
import Grid from './Grid';

export const defaultPagination = {
  page: 1,
  rowsPerPage: 100,
  sortBy: null,
  descending: false,
};

export default {
  name: 'GridCrud',

  props: {
    api: {
      type: Function,
      default: null,
    },
    mapper: {
      type: Function,
      default: null,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: [Object, String],
    },
    rowsPerPageItems: {
      type: Array,
      default: function() {
        return [100, 200, 300];
      },
    },
    fields: {
      type: Object,
    },
    value: {
      type: Array,
    },
    pagination: Object,
    selectedCount: {
      type: Boolean,
      default: false,
    },
    unselectedItems: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      items: [],
      totalItems: 0,
      totalRelation: '',
      myPagination: {},
      loading: false,
      error: null,
    };
  },

  render() {
    if (!this.error) {
      return this.$createElement(Grid, {
        ref: 'grid',
        props: {
          fields: this.fields,
          items: this.items,
          totalItems: this.totalItems,
          totalRelation: this.totalRelation,
          mustSort: true,
          pagination: this.myPagination,
          rowsPerPageItems: this.rowsPerPageItems,
          loading: this.loading,
          hideActions: this.hideActions,
          value: this.value,
          selectedCount: this.selectedCount, // show selected count in table header
          unselectedItems: this.unselectedItems, // used in GridSelectable.js in method everyItem()
        },
        scopedSlots: this.$scopedSlots,
        on: {
          'update:pagination': pagination => {
            this.myPagination = pagination;
          },
          click: item => {
            this.details(item);
          },
          input: value => {
            this.$emit('input', value);
          },
        },
      });
    } else {
      return this.$createElement('error-alert', {
        props: {
          value: this.error,
        },
      });
    }
  },

  created() {
    // We create debounced function here so it will be unique for each component
    this.getItemsAsync = _.debounce(this.getItemsAsync.bind(this), 100);

    const defaultSort = {
      descending: false,
    };

    for (const key in this.fields) {
      const field = this.fields[key];
      if (field.defaultSort === -1) {
        defaultSort.descending = true;
        defaultSort.sortBy = key;
        break;
      } else if (field.defaultSort) {
        defaultSort.sortBy = key;
        break;
      }
    }

    if (!defaultSort.sortBy) {
      defaultSort.sortBy = Object.keys(this.fields)[0];
    }

    if (defaultPagination.rowsPerPage === 100 && this.rowsPerPageItems[0] !== 100) {
      defaultSort.rowsPerPage = this.rowsPerPageItems[0];
    }

    this.myPagination = {
      ...defaultPagination,
      ...defaultSort,
      ...this.pagination,
    };
  },

  async mounted() {
    if (!this.api) {
      console.warn('Grid has no source');
      return;
    }
    await this.getItems();
  },

  methods: {
    getItemsAsync: async function() {
      try {
        const pagingParams = {
          page: this.myPagination.page,
          rowsPerPage: this.myPagination.rowsPerPage,
          sortBy: this.myPagination.sortBy,
          descending: this.myPagination.descending,
        };

        if (this.filter) {
          pagingParams.filter = this.filter;
        }

        const data = await this.api(pagingParams);
        this.error = null;

        let results = data.result || data.results;
        let total = data.total;
        if (Array.isArray(data)) {
          results = data;
          total = data.length;
        }

        if (data.total > 0 && results.length === 0 && this.myPagination.page !== 1) {
          this.myPagination.page = 1;
          // this.$emit('update:pagination', this.myPagination);
          await this.getItemsAsync();
        } else {
          /*
          if (data.total === 0 && this.myPagination.page !== 1) {
            console.log('page=1');
            this.myPagination.page = 1;
            this.$emit('update:pagination', this.myPagination);
          }
          */
          const dataCanCreate = 'canCreate' in data ? data.canCreate : data.can_create;

          this.$emit('update:can-create', dataCanCreate);
          this.totalItems = total;
          this.totalRelation = data.totalRelation;
          this.myCanCreate = this.canCreate && dataCanCreate;
          this.items = this.mapper ? results.map(item => new this.mapper(item)) : results;
          this.$emit('update:data', data);
        }
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    getItems: async function() {
      this.loading = true;
      await this.getItemsAsync();
    },

    makeQuery() {
      const query = {};
      if (this.myPagination.page !== defaultPagination.page) {
        query.page = String(this.myPagination.page - 1);
      }
      if (this.myPagination.rowsPerPage !== defaultPagination.rowsPerPage) {
        query.pageSize = String(this.myPagination.rowsPerPage);
      }
      if (this.myPagination.sortBy !== defaultPagination.sortBy) {
        query.sort = this.myPagination.sortBy;
      }
      if (this.myPagination.descending !== defaultPagination.descending) {
        query.sortDesc = this.myPagination.descending ? '1' : '0';
      }
      return query;
    },

    async refresh() {
      await this.getItems();
    },

    getRouteParams(id) {
      if (this.route) {
        return {
          name: this.route,
          params: {
            id: id,
            back: this.$route.path,
          },
        };
      }
      if (this.url) {
        return this.url(id);
      }
      return this.$route.path + '/' + id;
    },

    details(item) {
      if (this.$listeners.click) {
        this.$emit('click', item);
        return;
      }
      if (!this.api) {
        return false;
      }
      if (!this.hideDetailsAction) {
        this.$router.push(this.getRouteParams(item.id));
      }
    },
  },

  watch: {
    async myPagination() {
      await this.getItems();
      this.$emit('update:pagination', this.myPagination);
    },

    filter: {
      async handler() {
        await this.getItems();
      },
      deep: true,
    },
  },
};
