import _ from 'lodash';

function highlight(searchInput, text) {
  if (!text) {
    return text;
  }

  if (searchInput) {
    const index = text.toLowerCase().indexOf(searchInput);
    let start, middle, end;
    if (index >= 0) {
      start = text.slice(0, index);
      middle = text.slice(index, index + searchInput.length);
      end = text.slice(index + searchInput.length);

      return `${_.escape(start)}<span class="v-list__tile__mask">${_.escape(middle)}</span>${_.escape(end)}`;
    }
  }

  return _.escape(text);
}

export { highlight };
