import httpV2, { pagingParams } from './httpV2';

const pathMulti = '/places';

async function list(opts) {
  const res = await httpV2.get(pathMulti, {
    params: pagingParams(opts),
  });
  return res.data;
}

async function create(data) {
  const res = await httpV2.post(pathMulti, data);
  return res.data;
}

async function get(id) {
  const res = await httpV2.get(`${pathMulti}/${id}`);
  return res.data;
}

async function getPlacesMap() {
  const res = await httpV2.get(`${pathMulti}/map`);
  return res.data;
}

async function update(id, data) {
  data = { ...data }; // clone

  delete data.canDelete;
  delete data.canUpdate;
  delete data.createdAt;
  delete data.updatedAt;
  delete data.rating;
  delete data.companyId;
  delete data.foreignId;
  delete data.dbId;

  const res = await httpV2.patch(`${pathMulti}/${id}`, data);
  return res.data;
}

async function remove(id) {
  const res = await httpV2.delete(`${pathMulti}/${id}`);
  return res.data;
}

const COMPANY_TYPES = ['client', 'office', 'technical'];

export default {
  COMPANY_TYPES,
  list,
  create,
  get,
  update,
  remove,
  getPlacesMap,
};
