import { TripPointStatus, TripPointType } from '@/models/TripPoint';
import { ITripMap } from './lib';
import { getIconSettings, MarkerIconName } from '../../config';
import { MapPointData } from '@/services/TripPageService';
import { MapMarkerOptions } from '../../primitives/MapMarker';

export default function(point: MapPointData, parent: ITripMap): Omit<MapMarkerOptions, 'position'> {
  const { tripPoint } = point;
  const { secondLabelInfoMap } = parent;

  let icon: MarkerIconName = 'finish';
  if (tripPoint.type === TripPointType.actualFinish) {
    icon = 'finishActual';
  }

  let text = '';
  if (tripPoint.status === TripPointStatus.passed || tripPoint.status === TripPointStatus.marked) {
    const checkin = tripPoint.checkins[0];
    if (checkin && checkin.inDate) {
      if (parent.showDate()) {
        text = parent.$t('Endpoint') + '<br />' + parent.$d(checkin.inDate, 'long');
      } else {
        text = parent.$t('Endpoint') + ' ' + parent.$d(checkin.inDate, 'time');
      }
    }
  } else if (
    [TripPointStatus.active, TripPointStatus.planned, TripPointStatus.waitingForAdd].includes(tripPoint.status) &&
    point.arrivalPlanAt
  ) {
    if (tripPoint.status !== TripPointStatus.waitingForAdd) {
      text += parent.$t('Endpoint');
    }
    text +=
      ' <i aria-hidden="true" class="v-icon mdi mdi-tilde"></i> ' +
      parent.formatTimeAndDateOneLine(point.arrivalPlanAt, point.showDate, point.showTimezone, point.timezoneOffset);
  }

  const iconData = getIconSettings(icon);

  const result = {
    icon: iconData,
    secondLabel:
      text && secondLabelInfoMap
        ? {
            html: text,
            origin: iconData.secondLabelOrigin,
          }
        : undefined,
    isNext: point.isNext,
    zindex: point.zindex,
    draggable: parent.$store.state.map.draggable,
  };

  return result;
}
