
import Vue from 'vue';
import AccountInfoDetails from './AccountInfoDetails.vue';
import account from '@/api/account';

export default Vue.extend({
  name: 'AccountInfo',

  components: { AccountInfoDetails },

  props: {
    right: Boolean,
    account: {
      type: Object,
    },
    showJobTitle: {
      type: Boolean,
      default: true,
    },
    mini: Boolean,
    showLink: {
      type: Boolean,
      default: true,
    },
    showPhones: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    myId(): string | number | null {
      return this.account && ((this.account as any).external_id || this.account.id);
    },
  },
});
