import http, { pagingParams, Params } from './httpV2';
import Uuid from '@/models/Uuid';

const path = '/chats';

export interface ChatsResponse {
  chat: any;
  systemChat: any;
}

async function getMessagesCount(id: number): Promise<ChatsResponse> {
  const res = await http.get(`${path}/${id}/messages/count`);
  return res.data;
}

async function getNewMessagesCountByTripIds(ids: Uuid[]): Promise<any> {
  const res = await http.post(`${path}/trips/newMassages/count`, ids);
  return res.data;
}

export default {
  getMessagesCount,
  getNewMessagesCountByTripIds,
};
