
import Vue from 'vue';
import RowLink from './RowLink.vue';
import { ResizeObserver } from '@juggle/resize-observer';

export default RowLink.extend({
  name: 'RowProgress',

  props: {
    progress: Number,
  },

  methods: {
    fixWidthCallback(targetEl: Element, width: number, height: number) {
      const elem = this.$el.firstElementChild as HTMLElement | null;
      if (elem) {
        const progress = this.progress ? Number(this.progress) / 100 : 0;
        elem.style.width = width * progress + 'px';
        elem.style.height = height + 'px';
      }
    },
  },

  watch: {
    progress() {
      const rowEl = this.$el.closest('tr');
      if (rowEl) {
        this.fixWidth(rowEl);
      }
    },
  },
});
