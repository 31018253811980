import MapWidget from './MapWidgetNew';

export default class MapFullPageWidget extends MapWidget {
  get mapStyles(): Partial<CSSStyleDeclaration> {
    return {
      position: 'relative',
      width: '100%',
      height: '100%',
    };
  }
}
