// @ts-nocheck
import Vue from 'vue';
import { isEqual, isEmpty } from 'lodash';
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';
import gmap from '@/lib/gmap';
import MapInitMixin from './MapInitMixin';
import MapStylesMixin from './MapStylesMixin';

const props = Vue.extend({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('map', ['mapBounds']),
  },

  watch: {
    mapBounds(newValue, oldValue) {
      if (!isEqual(oldValue, newValue)) {
        if (!isEmpty(newValue)) {
          (this as any).boundsChanged(newValue);
        }
      }
    },
  },
});

@Component
export default class MapWidget extends mixins(props, MapInitMixin, MapStylesMixin) {
  render() {
    return this.$createElement(
      'div',
      {
        style: this.mapStyles,
      },
      [
        this.$createElement(
          'div',
          {
            attrs: {
              id: 'map',
            },
            class: {
              'fill-height': true,
            },
          },
          this.mapLoaded ? [this.$scopedSlots.default ? this.$scopedSlots.default({}) : this.$slots.default] : [],
        ),
        ...(this.loading
          ? [
              this.$createElement('v-progress-circular', {
                attrs: {
                  indeterminate: true,
                  size: 64,
                  color: 'blue-grey lighten-5',
                },
                style: {
                  position: 'absolute',
                  top: 'calc(50% - 32px)',
                  left: 'calc(50% - 32px)',
                  'z-index': 100,
                },
              }),
              this.$createElement('div', {
                class: {
                  'v-overlay': true,
                  'v-overlay--active': true,
                  'v-overlay--absolute': true,
                  'theme--light': true,
                },
              }),
            ]
          : []),
      ],
    );
  }

  boundsChanged(bounds: google.maps.LatLngBoundsLiteral) {
    if (bounds.east === -180 && bounds.north === 1 && bounds.south === -1 && bounds.west === 180) {
      return;
    }

    if (Math.abs(bounds.north - bounds.south) < 0.01) {
      bounds.north += 0.01;
      bounds.south -= 0.01;
    }
    if (Math.abs(bounds.east - bounds.west) < 0.01) {
      bounds.east += 0.01;
      bounds.west -= 0.01;
    }
    this.map?.fitBounds(bounds);
  }

  beforeMount() {
    gmap.init();
  }

  async mounted() {
    /* eslint-disable no-console */
    console.log('mointed');
    /* eslint-enable no-console */
    await this.initMap();
  }
}
