import store from '@/store';

import http, { pagingParams, Params } from './httpV2';

import Uuid from '@/models/Uuid';
import Account, { AccountDto } from '@/models/Account';

const path = '/accounts';

export interface AccountsResponse {
  result: Account[];
  canCreate: boolean;
  total: number;
}

interface AccountsListParams extends Params {
  permissionName?: string;
}

interface InactiveParam {
  inactive: boolean;
}

async function list(opts: AccountsListParams): Promise<AccountsResponse> {
  if (opts && opts.filter && opts.filter.toHttpParams) {
    opts.filter = opts.filter.toHttpParams();
  }

  const res = await http.get(path, {
    params: pagingParams(opts),
  });

  res.data.result = res.data?.result.map((e: AccountDto) => new Account(e));
  return res.data;
}

async function create(data: Account) {
  if (data.email === '') {
    data.email = null;
  }

  if (data.phone === '') {
    data.phone = null;
  }

  const res = await http.post(path, data);
  return res.data;
}

async function get(id: number, opts?: InactiveParam) {
  const params = {} as InactiveParam;

  if (opts && opts.inactive) {
    params.inactive = opts.inactive;
  }

  const res = await http.get(`${path}/${id}`, { params });
  return res.data;
}

async function update(id: Uuid, data: Account) {
  const res = await http.patch(`${path}/${id}`, data);

  if (id === store.state.auth.accountId) {
    store.commit('account/setAccountData', res.data);
  }

  return res.data;
}

async function remove(id: Uuid) {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function removeAccounts(data: Uuid[]): Promise<any> {
  const res = await http.delete(path, { data });
  return res.data;
}

async function updateRole(id: Uuid, roleId: any): Promise<any> {
  const res = await http.patch(`${path}/${id}/role`, { roleId });
  return res.data;
}

export default {
  list,
  create,
  get,
  update,
  remove,
  removeAccounts,
  updateRole,
};

export { Account };
