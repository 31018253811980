<template>
  <div>
    <h1>{{ $t('Privacy Policy') }}</h1>

    <h4>1. General Provisions</h4>
    <p>
      In its normal course of business HomeStretch Rus Limited Liability Company (OGRN 1187746678778; INN 7734414304; 
      Russia, Moscow, Kulakova str., 20, block 1А, premises XI, 2 floor, office 47) processes Personal data including
      processing with involvement of information systems.
    </p>
    <p>
      Personal data protection is a key principal of HomeStretch Rus business philosophy. Privacy Policy and data
      protection methods are aimed at Personal data duly and legal processing, transmission and storage in order to
      provide their confidentiality, integrity and accessibility.
    </p>
    <p>
      HomeStretch Rus collects and processes Personal data exclusively for the purpose of agreements execution,
      rendering services and improving its activities connected therewith. Personal data processing is carried out as
      provided by Federal Law No. 152-FZ dd  27.07.2006 “On personal data” and other regulations.
    </p>
    <p>
      This Privacy Policy is HomeStretch Rus LLC bylaw approved in accordance with Federal Law No. 152-FZ dd  27.07.2006
      “On personal data”.
    </p>

    <p><b>Personal data processing principles</b></p>
    <p>HomeStretch Rus Privacy Policy is based on the following principles:</p>
    <ul>
      <li>
        Personal data collection shall be carried out only for specific legal purposes and such data may not be
        processed for other purposes;
      </li>
      <li>Personal data processing shall be carried out legally and validly;</li>
      <li>
        HomeStretch Rus assumes the data provided to them reliable and assumes that the person providing personal data
        of a third party has obtained a consent of that third party for their processing;
      </li>
      <li>Personal data shall be deleted or corrected without delay if necessary;</li>
      <li>
        Personal data shall be stored in a form allowing to identify the data subject during a period of time necessary
        for the purposes for which such data were collected;
      </li>
      <li>
        Personal data processing shall be carried out in a manner providing Personal data security by means of adequate
        technical and organizational measures, including protection against unauthorized or illegal processing or
        accidental loss, damage or erasure;
      </li>
      <li>
        Personal data subjects shall have a right to access their Personal data, change them or delete them, restrict
        their processing, object to such processing and a right to transmit the data.
      </li>
    </ul>

    <h4>2. Personal data</h4>
    <p>HomeStretch Rus carries out processing of the following Personal data of Client’s/User’s employees:</p>
    <ul>
      <li>Last name and first name;</li>
      <li>E-mail;</li>
      <li>Position;</li>
      <li>Telephone number;</li>
      <li>Photo (optional).</li>
    </ul>

    <h4>3. Automatically collected data</h4>
    <p>
      HomeStretch Rus may collect and process information which is not considered Personal data, such as the data
      obtained in the course of interaction of users and HomeStretch Rus website (geolocation information (user’s
      location), browser data (type, version), query data (time, IP address), web protocols, cookies, web tags). Such
      data shall be processed the same way as the Personal data.
    </p>

    <h4>4. Personal data transmission and time of processing</h4>
    <p>
      Personal data may be transmitted to third parties (cross-border data transmission included) under the following
      circumstances:
    </p>
    <ul>
      <li>
        to HomeStretch Rus business partners and persons who form part of HomeStretch Group if such transmission is
        necessary either to use functional capabilities of HomeStretch Rus services or to improve HomeStretch Rus
        activities;
      </li>
      <li>universal or singular succession under agreements executed with clients/users;</li>
      <li>upon request of a court or any other public authority as part of a procedure as established by law;</li>
      <li>within a legal procedure commenced by HomeStretch Rus to protect their rights and interests.</li>
    </ul>

    <p>
      This being said HomeStretch Rus guarantees that in case of transmission such Personal data shall be protected
      under agreement (if applicable) as much as established by this Privacy Policy and the Russian Law.
    </p>
    <p>
      Period of Personal data processing (storage) shall be determined according to the purposes of such processing,
      respective agreements validity periods, legal requirements, requirements of Personal data controller who instructs
      HomeStretch Rus to process Personal data, main regulations of corporate archives, limitation of action periods.
    </p>

    <h4>5. Amendment of Privacy Policy</h4>
    <p>
      This Privacy Policy may be amended by HomeStretch Rus at any time. New edition of Privacy Policy shall be valid
      from the moment of its publication at the website if otherwise not provided by such new edition.
    </p>
    <p>
      You can find current edition of HomeStretch Rus Privacy Policy at HomeStretch Rus website following the link:
      <a href="https://co.homestretch.ch/policy">https://co.homestretch.ch/policy</a>.
    </p>

    <h4>6. English version</h4>
    <p>
      This Privacy Policy is an English version. In case of discrepancy of Russian and English version, English version
      shall prevail.
    </p>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Privacy Policy'),
    };
  },
};
</script>
