import { isNil } from 'lodash';

const moment = require('moment');
const geoTz = require('geo-tz');

require('moment-timezone');

/**
 * Return timezone data for passed coordinates
 *
 * @param {Object} coordinates lat/lng object
 *
 * @return {{date: String, timezoneOffset: String, timezoneOffsetInSec: Number, timezoneName: String}}
 */
function getTZInfoByCoordinate(coordinates) {
  try {
    const timezoneNames = geoTz(coordinates.lat, coordinates.lng);
    const timezone = moment.tz(timezoneNames[0]);

    return {
      timezoneOffsetInSec: timezone.utcOffset() * 60,
      timezoneOffset: timezone.format('Z'),
      timezoneName: timezoneNames[0],
    };
  } catch (e) {
    return {
      timezoneOffsetInSec: getLocalTimezoneInSeconds(),
      timezoneOffset: 'Z',
      timezoneName: '',
    };
  }
}

/**
 * Return timezone offset of local machine in seconds
 */
function getLocalTimezoneInSeconds() {
  return new Date().getTimezoneOffset() * -60;
}

/**
 * Return timezone string of timezone offset in format like "+03 GMT"
 *
 * @param {number | null} timeZoneOffset timezone offset in seconds
 *
 * @return {String} timezone in format like "+03 GMT"
 */
function getTimeZoneString(timeZoneOffset) {
  if (!isNil(timeZoneOffset)) {
    const timeZoneHours = timeZoneOffset / 3600;
    return timeZoneHours < 0 ? ` UTC ${timeZoneHours}` : ` UTC +${timeZoneHours}`;
  }
  return '';
}

export { getTZInfoByCoordinate, getLocalTimezoneInSeconds, getTimeZoneString };
