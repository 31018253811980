
import Vue from 'vue';
import Trip from '@/models/Trip';
import { baseUrl } from '@/api/httpV2';

export default Vue.extend({
  name: 'TripsListExport',

  props: {
    trip: {
      type: Trip,
      default: null,
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    exportLink(this: any): string {
      return `${baseUrl}/trips/exports/xlsx?locale=${this.$i18n.locale}&${this.filter.toQueryStringParams()}`;
    },
  },
});
