import BaseInfoWindow from './BaseInfoWindow';
import { CreateElement, VNode } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { MapPointData } from '@/services/TripPageService';
import TripPoint from '@/models/TripPoint';
import { PlaceIcon } from '@/components/icons';
import CustomHorizontalList from '@/components/list/CustomHorizontalList';

const mapState = namespace('map');

@Component
export default class PointCalibrationWindow extends mixins(BaseInfoWindow) {
  @mapState.State('calibrateLoading') readonly calibrateLoading!: boolean;
  @mapState.State('currentPoint') readonly currentPoint!: MapPointData | null;
  @mapState.State('draggable') readonly draggable!: boolean;
  show = false;

  genContent(tripPoint: TripPoint) {
    return (
      <div class="map-marker-tooltip">
        <div class="map-marker-tooltip-sizer" />
        {this.genCommonContent(tripPoint)}
        {!this.draggable && this.genEditButtons(tripPoint)}
      </div>
    );
  }

  genCommonContent(tripPoint: TripPoint) {
    return (
      <div>
        {this.calibrateLoading ? (
          <v-progress-circular indeterminate size={16} width={2} color="grey" />
        ) : (
          <div>
            {this.genPointName(tripPoint)}
            {this.genOrderNumber(tripPoint)}
            {this.genAddress(tripPoint)}
          </div>
        )}
        {this.draggable && (
          <div class="d-flex align-center" style="height: 40px">
            <div class="d-flex shrink" style="max-width: 25px">
              <v-icon class="d-inline">mdi-arrow-all</v-icon>
            </div>
            <div class="d-flex ml-2 grow">{this.$t('You can move the marker')}</div>
            <v-spacer />
          </div>
        )}
      </div>
    );
  }

  genPointName(tripPoint: TripPoint) {
    if (tripPoint.placeLink?.placeId) {
      return (
        <div>
          <strong>{this.$t('Point name')}: </strong>
          <PlaceIcon />
          <em>{tripPoint.placeLink.title}</em>
        </div>
      );
    } else if (tripPoint.title) {
      return (
        <div>
          <strong>{this.$t('Point name')}: </strong>
          <em>{tripPoint.title}</em>
        </div>
      );
    }
  }

  genOrderNumber(tripPoint: TripPoint) {
    if (Array.isArray(tripPoint.orders) && tripPoint.orders.length) {
      const orderNumbers = [];

      for (const order of tripPoint.orders) {
        if ('number' in order && order.number) {
          orderNumbers.push(order.number);
        }
      }

      // @ts-ignore
      return <CustomHorizontalList label={this.$t('Order number') as string} items={orderNumbers as string[]} />;
    }
  }

  genAddress(tripPoint: TripPoint) {
    if (tripPoint.location && Array.isArray(tripPoint.location.addresses) && tripPoint.location.addresses.length) {
      return (
        <div>
          <strong>{this.$t('Address')}: </strong>
          <em>{tripPoint.location.addresses[0].address}</em>
        </div>
      );
    }
  }

  genEditButtons(tripPoint: TripPoint) {
    const buttons = [];

    if (this.currentPoint?.canDelete) {
      buttons.push(
        <v-tooltip bottom v-model={this.show}>
          <v-btn slot="activator" icon flat small onClick={this.deleteHandler}>
            <v-icon>delete</v-icon>
          </v-btn>
          <span>{this.$t('Delete')}</span>
        </v-tooltip>,
      );
    }

    if (this.currentPoint?.canUndelete) {
      buttons.push(
        <v-tooltip bottom>
          <v-btn slot="activator" icon flat small onClick={this.undeleteHandler}>
            <v-icon>mdi-delete-restore</v-icon>
          </v-btn>
          <span>{this.$t('Restore')}</span>
        </v-tooltip>,
      );
    }

    if (this.currentPoint?.canCalibrate) {
      buttons.push(
        <v-tooltip bottom>
          <v-btn slot="activator" icon flat small onClick={this.calibrateHandler}>
            <v-icon>mdi-map-marker-radius</v-icon>
          </v-btn>
          <span>{this.$t('Calibrate')}</span>
        </v-tooltip>,
      );
    }

    return <div>{buttons}</div>;
  }

  deleteHandler() {
    this.show = !this.show;
    this.$emit('deletePoint', this.tripPoint?.id);
  }

  undeleteHandler() {
    this.$emit('undeletePoint', this.tripPoint?.id);
  }

  calibrateHandler() {
    this.$store.commit('map/setDraggable', true);
  }

  get tripPoint() {
    return this.currentPoint?.tripPoint;
  }

  render(h: CreateElement): VNode {
    if (!this.tripPoint) {
      return h();
    }

    return this.genContent(this.tripPoint);
  }
}
