import PointMarker, { IPointMarker } from './markers/PointMarker';
import StartFinishMarker from './markers/StartFinishMarker';
import MultipointMarker from './markers/MultipointMarker';
import { MapPointData, MapGroupData } from '@/services/TripPageService';
import { TripPointType } from '@/models/TripPoint';
import { IGroupMarker } from './markers/GroupMarker';
import RadarMarker from '@/components/map/markers/RadarMarker';

function pointMarkerFactory(point: MapPointData, parent: Vue): IPointMarker {
  const obj = new PointMarker({
    parent,
    i18n: parent.$i18n,
  });
  obj.point = point;
  return obj as any;
}

function groupMarkerFactory(group: MapGroupData, points: MapPointData[], parent: Vue): IGroupMarker {
  let obj: any;

  if (
    points.every(point =>
      [TripPointType.start, TripPointType.actualFinish, TripPointType.actualFinish].includes(point.tripPoint.type),
    )
  ) {
    obj = new StartFinishMarker({
      parent,
      i18n: parent.$i18n,
      data: {
        groupData: group,
        points,
      },
    });
  } else {
    obj = new MultipointMarker({
      parent,
      i18n: parent.$i18n,
      data: {
        groupData: group,
        points,
      },
    });
  }
  return obj;
}

function radarMarkerFactory(point: MapPointData, parent: Vue): IPointMarker {
  const obj = new RadarMarker({
    parent,
    i18n: parent.$i18n,
  });
  obj.point = point;
  return obj as any;
}

export { pointMarkerFactory, groupMarkerFactory, radarMarkerFactory };
