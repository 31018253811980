function toJSONTrip(trip) {
  return {
    title: trip.title,
    executorId: trip.executorId,
    creatorId: trip.creatorId,
    responsibleId: trip.responsibleId,
    tripPoints: trip.tripPoints.map(point => toJSONTripPoints(point)),
    wayPoints: trip.wayPoints.map(point => toJSONWayPoints(point)),
    syncAt: trip.syncAt,
    plannedAt: trip.plannedAt,
    startedAt: trip.startedAt,
    finishedAt: trip.finishedAt,
    canCancel: trip.canCancel,
    id: trip.id,
    status: trip.status,
    tripMode: trip.tripMode,
    way: trip.way,
    summaryIndicators: trip.summaryIndicators,
    mobileDeviceInfo: trip.mobileDeviceInfo,
    batteryInfo: trip.batteryInfo,
    createdAt: trip.createdAt,
    updatedAt: trip.updatedAt,
  };
}

function toJSONTripPoints(point) {
  return {
    title: point.title,
    stayTime: point.stayTime,
    id: point.id,
    type: point.type,
    status: point.status,
    location: point.location,
    placeLink: point.placeLink,
    contacts: point.contacts,
    comment: point.comment,
    workingHours: point.workingHours,
    deliveryWindows: point.deliveryWindows,
    radius: point.radius,
    checkins: point.checkins.map(checkin => toJSONcheckins(checkin)),
    workTime: point.workTime,
    functions: point.functions,
    createdAt: point.createdAt,
    updatedAt: point.updatedAt,
  };
}

function toJSONWayPoints(point) {
  return {
    id: point.id,
    coordinates: point.coordinates,
    radius: point.radius,
    tripPointIds: point.tripPointIds,
    checkins: point.checkins.map(checkin => toJSONcheckins(checkin)),
  };
}

function toJSONcheckins(checkin) {
  return {
    id: checkin.id,
    inDate: checkin.inDate,
    outDate: checkin.outDate,
    status: checkin.status,
    batteryInfo: checkin.batteryInfo,
  };
}

export default toJSONTrip;
