import _ from 'lodash';
import http, { pagingParams } from './http';
import httpV2 from './httpV2';

const path = '/trip';
const pathMulti = '/trips';

async function get(id, opts) {
  const params = {};
  if (opts.location) {
    params.location = 1;
  }
  const res = await http.get(`${path}/${id}`, { params });
  return res.data;
}

async function updateTrip(id, data) {
  const res = await httpV2.post(`${pathMulti}/${id}/changes`, data);
  return res.data;
}

async function importXls(data) {
  const res = await http.post(`${path}/importRoute`, data, {
    timeout: 120000,
  });
  return res.data;
}

async function cancelTrip(id) {
  const res = await http.post(`${path}/${id}/cancelTrip`, {});
  return res.data;
}

async function getLink(id, data) {
  let path = `${pathMulti}/${id}`;

  path = data && data.pointId ? `${path}/tripPoints/${data.pointId}/link` : `${path}/link`;

  const res = await httpV2.get(path, data);
  return res.data;
}

async function getDeeplink(id, data) {
  const res = await httpV2.get(`${pathMulti}/${id}/deeplink`);
  return res.data;
}

async function getEvents(id) {
  const res = await httpV2.get(`${pathMulti}/${id}/events`);
  return res.data;
}

const Trip = {};

Trip.STATUS = {
  active: 0,
  finished: 1,
  canceled: 2,
  manuallyFinished: 3,
  pending: 4,
  pendingViewed: 5,
  operatorCanceled: 7,
  planned: 8,
};

const TripPoint = {};

TripPoint.TYPE = {
  default: 0,
  start: 1,
  scheduled: 2,
  notScheduled: 3,
  finish: 4,
  actualFinish: 5,
};

TripPoint.TYPE_INVERT = {
  1: 'start',
  2: 'scheduled',
  3: 'not_scheduled',
  4: 'finish',
  5: 'actual_finish',
};

TripPoint.TYPE.isStartFinishType = type =>
  [TripPoint.TYPE.start, TripPoint.TYPE.finish, TripPoint.TYPE.actualFinish].includes(type);

TripPoint.STATUS = {
  active: 'active',
  deleted: 'deleted',
  current: 'current',
  marked: 'marked',
  waitingForAdd: 'waitingForAdd',
  waitingForDelete: 'waitingForDelete',
};

function getLastCheckinPoint(points) {
  let result;
  for (const point of points) {
    if (point.status === TripPoint.STATUS.current || point.status === TripPoint.STATUS.passed) {
      result = point;
    }
  }
  return result;
}

function getStartPoint(points) {
  return _.find(points, point => point.type === TripPoint.TYPE.start && point.status !== TripPoint.STATUS.deleted);
}

function getFinishPoint(points) {
  return _.find(points, point => point.type === TripPoint.TYPE.finish && point.status !== TripPoint.STATUS.deleted);
}

function getActualFinishPoint(points) {
  return _.find(
    points,
    point => point.type === TripPoint.TYPE.actualFinish && point.status !== TripPoint.STATUS.deleted,
  );
}

export default {
  get,
  updateTrip,
  importXls,
  cancelTrip,
  getLink,
  getDeeplink,
  getEvents,
};
export { Trip, TripPoint };
export { getLastCheckinPoint, getStartPoint, getFinishPoint, getActualFinishPoint };
