<template>
  <div>
    <v-snackbar :timeout="2000" :top="true" v-model="snackbar">
      {{ snackbarText }}
      <v-btn flat color="pink" @click.native="snackbar = false">
        {{ $t('Close') }}
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="dialog" max-width="1100px" min-width="1100px">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Sms and radar') }}
        </v-card-title>
        <v-card-text>
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12> {{ $t('Total SMS') }}: {{ totalSms }} </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12>
              <b> {{ $t('Total for payment') }}: {{ $n(totalCost, 'currency', 'RUB') }} </b>
            </v-flex>
          </v-layout>
          <br /><v-divider /><br />
          <v-layout row wrap align-end>
            <v-flex xs12 sm12>
              <PaymentMethods
                :invoice-url="invoiceUrl"
                :show-paid-action="showPaidAction"
                :send-email-btn-locked="true"
                :change-invoice="changeInvoice"
                @createInvoice="createInvoice"
                @sendEmailInvoice="sendEmailInvoice"
                @changeAutoProlongationPaymentMethod="changeAutoProlongationPaymentMethod"
                ref="paymentMethods"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions v-if="showPaid">
          <v-spacer />
          <v-btn color="green darken-1" dark :disabled="!canPay" @click="buySmsPackages">
            {{ $t('Pay') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api/billing';
import { mapState } from 'vuex';

import PaymentMethods from '@/components/billing/PaymentMethods';

export default {
  components: { PaymentMethods },

  props: {
    packages: {
      type: Array,
      required: true,
    },

    invoiceUrl: {
      type: String,
      default: '',
    },

    smsPlan: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    invoiceUrl: '',
    invoiceNumber: 0,
    dialog: true,
    snackbar: false,
    snackbarText: '',
    changeInvoice: false,
    showPaid: true,
  }),

  computed: {
    ...mapState('payment', ['cardInfo']),
    ...mapState(['billing']),

    canPay() {
      return this.cardInfo.has_id;
    },

    totalCost() {
      return this.packages.reduce((acc, pack) => acc + this.smsPlan[pack.pack] * pack.count, 0);
    },

    totalSms() {
      return this.packages.reduce((acc, pack) => acc + pack.pack * pack.count, 0);
    },
  },

  methods: {
    async buySmsPackages(numSlots, numMonth) {
      await api.purchase.buySmsPackages({ packages: this.packages });
      await api.info.getCountOfPaidSms();
      this.$emit('buy-packages');
      this.dialog = false;
    },

    async createInvoice() {
      const data = await api.invoice.create({ packages: this.packages, type: 'SMS' });

      this.invoiceUrl = data.url;
      this.invoiceNumber = data.invoice_number;

      this.$emit('create-invoice', this.invoiceNumber);
    },

    async sendEmailInvoice() {
      await api.invoice.sendEmail({ invoiceNumber: this.invoiceNumber });

      this.snackbar = false;
      this.snackbarText = this.$t('Invoice sent to email');
      this.snackbar = true;
    },

    showPaidAction(val) {
      this.showPaid = val === 'card';
    },

    async changeAutoProlongationPaymentMethod(type, status) {
      await api.paymentMethod.changeAutoProlongation({ payment_method_type: type, status: status });

      this.snackbar = false;
      this.snackbarText = this.$t('Changes saved');
      this.snackbar = true;
    },
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
