
import Vue from 'vue';
import PointSettings from './PointSettings.vue';
import { TripTableRow } from '@/services/TripPageService';
import { TripStatus } from '@/models/Trip';

export default Vue.extend({
  name: 'PointSettingsDialog',
  components: { PointSettings },
  data() {
    return {
      showDialog: true,
      valid: true,
      internalValue: {},
    };
  },
  props: {
    value: TripTableRow,
    tripStatus: String as () => TripStatus,
  },
  methods: {
    hideModal(): void {
      this.$emit('close');
    },

    handleOk(): void {
      if ((this.$refs.pointSettings as Vue & { checkContent: () => boolean }).checkContent()) {
        this.$emit('input', this.internalValue);
      }
    },

    updateInternalValue(newValue: any): void {
      this.internalValue = newValue;
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
