<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Support') }}</h1>
    </v-flex>

    <v-flex>
      <div style="background: rgb(250, 250, 250)" v-onboarding.right="$t('helpText.support')">
        <div class="mb-2">
          {{ $t('supportPage.line1') }}
        </div>

        <div class="mb-2">
          {{ $t('supportPage.line2') }}
        </div>

        <ul v-if="$i18n.locale === 'ru'" class="mb-2">
          <li>
            <i18n path="supportPage.line3">
              <a place="link" href="/static/manual/homestretch_manual_web.pdf" target="_blank">{{ $t('Download') }}</a>
            </i18n>
          </li>

          <li>
            <i18n path="supportPage.line4">
              <a place="link" href="/static/manual/homestretch_manual_android.pdf" target="_blank">
                {{ $t('Download') }}
              </a>
            </i18n>
          </li>

          <li>
            <i18n path="supportPage.line5">
              <a place="link" href="/static/manual/homestretch_manual_ios.pdf" target="_blank">{{ $t('Download') }}</a>
            </i18n>
          </li>
        </ul>

        <ul>
          <li>
            <i18n path="supportPage.line6">
              <a place="link" href="mailto:support@homestretch.ch">support@homestretch.ch</a>
            </i18n>
          </li>

          <li>
            <i18n path="supportPage.line7">
              <a place="link" href="tel:+74951980476" class="text-no-wrap">+7 495 198-04-76</a>
            </i18n>
          </li>
        </ul>

        <div class="mt-2 caption">
          {{ $t('supportPage.line8') }}
        </div>
      </div>
    </v-flex>

    <v-flex mt-3 v-if="$vuetify.breakpoint.mdAndUp && canStartOnboarding">
      <h2>
        <a @click="showOnboarding">
          {{ $t('View interface tips') }}
        </a>
      </h2>
    </v-flex>

    <v-flex mt-3 v-if="locale === 'ru'">
      <h2>
        {{ $t('Watch the video on program use') }}
      </h2>

      <ol>
        <li class="mb-2">
          <a href="https://www.youtube.com/watch?v=1MTkswAeb9s" target="_new">
            Возможность создавать задания для сотрудников в точке
          </a>
        </li>

        <li class="mb-2">
          <a href="https://www.youtube.com/watch?v=Q_v82nuWGmw" target="_new">
            Возможность прикладывать фотографии к точкам
          </a>
        </li>

        <li class="mb-2">
          <a href="https://www.youtube.com/watch?v=7yim7Gu79-8" target="_new">
            Возможность добавлять избранные точки (клиенты/заказы)
          </a>
        </li>

        <li class="mb-2">
          Дополнительные настройки программы - адаптация под ваш бизнес: <br />

          <ul>
            <li>
              <a
                href="https://www.youtube.com/watch?v=MHH5dZkxEN8&list=PLYFw7B_HFPLsV_t05gJx6uF-g9O5IHfwn&index=8"
                target="_new"
              >
                Настройка push уведомлений
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/watch?v=mp_DDXLFuJc&list=PLYFw7B_HFPLsV_t05gJx6uF-g9O5IHfwn&index=9"
                target="_new"
              >
                Выбор навигатора по умолчанию
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/watch?v=y9_8F2o8WnQ&list=PLYFw7B_HFPLsV_t05gJx6uF-g9O5IHfwn&index=14"
                target="_new"
              >
                Сообщение об ошибке (отправка логов)
              </a>
            </li>
          </ul>
        </li>

        <li class="mb-2">
          <a
            href="https://www.youtube.com/watch?v=G0zVCZEMAXM&list=PLYFw7B_HFPLsV_t05gJx6uF-g9O5IHfwn&index=13"
            target="_new"
          >
            Возможность отметить финиш вручную
          </a>
        </li>
      </ol>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Support',

  metaInfo() {
    return {
      title: this.$i18n.t('Support'),
    };
  },

  computed: {
    ...mapGetters('account', ['canStartOnboarding']),

    locale() {
      return this.$i18n.locale;
    },
  },

  methods: {
    showOnboarding() {
      this.$store.commit('site/startFirstTimeGuide', true);
    },
  },
};
</script>
