import Vue, { CreateElement } from 'vue';
import Component from 'vue-class-component';

import { convertDateWithTimeZone } from '@/lib/date';
import { getLocalTimezoneInSeconds, getTimeZoneString } from '@/lib/timezone';
// @ts-ignore
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
// @ts-ignore
import { GeoJsonLayer } from '@deck.gl/layers';
import { FeatureCollection } from 'geojson';

import OutlinedPathLayer from './layers/OutlinedPathLayer';
import OutlinedIconLayer from './layers/OutlinedIconLayer';
import IconMapping from './markers/mapping';

import { TripPointDto } from '@/models/TripPoint';
import { RawLocation } from 'vue-router';

@Component({
  props: {
    data: {
      type: Object,
      default: () => ({ type: 'FeatureCollection', features: [] }),
    },
  },

  provide: function() {
    const { getMap } = (this as unknown) as { getMap: { (): Promise<google.maps.Map> } };
    return {
      getMap,
    };
  },

  inject: ['getMap'],

  watch: {
    data: {
      handler: function() {
        (this as any).onTripsUpdate();
      },
    },
  },
})
export default class TripMonitor extends Vue {
  // readonly data!: FeatureCollection;

  deckOverlay = null as GoogleMapsOverlay | null;
  tooltip = document.createElement('div');
  showTimezone = false;

  async created() {
    const map: google.maps.Map = await (this as any).getMap();

    document.getElementById('map')?.parentElement?.appendChild(this.tooltip);
    this.tooltip.classList.add('tooltip-absolute');

    this.drawPoints(map);
  }

  render(h: CreateElement) {
    return h();
  }

  beforeDestroy() {
    if (this.deckOverlay) {
      this.deckOverlay.finalize();
    }
    this.deckOverlay = null;

    if (this.tooltip && this.tooltip.parentElement) {
      this.tooltip.parentElement.removeChild(this.tooltip);
    }

    this.$store.commit('map/clearBounds', 'monitor');
  }

  onTripsUpdate() {
    this.drawPoints();
  }

  async drawPoints(map?: google.maps.Map) {
    let isEgg = Number(this.$route.query.egg) === 1;

    map = map || (await (this as any).getMap());
    // map.data.addGeoJson(this.rawPassedWay);

    if (this.deckOverlay) {
      this.deckOverlay.finalize();
    }

    this.deckOverlay = new GoogleMapsOverlay({
      layers: [
        new GeoJsonLayer({
          id: 'tripsMonitoring',
          data: this.data,

          filled: true,
          opacity: 1,
          lineWidthUnits: 'pixels',
          getLineWidth: 5,
          getLineColor: (d: any) => d.properties.color,

          pickable: true,
          autoHighlight: true,
          onHover: this.setTooltip.bind(this),
          onClick: this.clickHandler.bind(this),

          _subLayerProps: {
            'line-strings': {
              type: OutlinedPathLayer,
            },

            points: {
              type: OutlinedIconLayer,
              iconAtlas: '/static/img/icon-atlas.png',
              iconMapping: IconMapping,
              getIcon: (d: any) => {
                switch (d.__source.object.properties.type) {
                  case 'current':
                    if (isEgg) {
                      return 'ghost';
                    }
                    switch (d.__source.object.properties.transportType) {
                      case 'car':
                        return 'car';
                      case 'truck':
                        return 'truck';
                      case 'taxi':
                        return 'taxi';
                      case 'bus':
                        return 'bus';
                      case 'motorcycle':
                        return 'motorcycle';
                      case 'bicycle':
                        return 'bicycle';
                      case 'pedestrian':
                        return 'pedestrian';
                      default:
                        return 'person';
                    }
                  case 'finish':
                    return 'finish';
                  default:
                    return 'marker';
                }
              },
              getColor: (d: any) => d.__source.object.properties.color,
              getSize: (d: any) => {
                switch (d.__source.object.properties.type) {
                  case 'current':
                    return 48;
                  case 'finish':
                    return 64;
                  default:
                    return 32;
                }
              },
              updateTriggers: {
                // getIcon: triggerValue,
              },
            },
          },
        }),
      ],

      // getTooltip: (...args: any) => console.log(args),
    });

    this.deckOverlay.setMap(map);
    this.updateBounds();
  }

  setTooltip({ x, y, object }: { x: number; y: number; object: any }, event: any) {
    const tooltip = this.tooltip;
    if (object && ['Point', 'LineString'].includes(object.geometry.type)) {
      tooltip.style.opacity = '1';
      tooltip.style.left = x + 'px';
      tooltip.style.top = y + 'px';

      let html = '';

      if (object.geometry.type == 'Point' && object.properties.type !== 'current') {
        const tripPoint: TripPointDto & { tripId: number } = object.properties;

        const pointName = tripPoint.title || tripPoint.placeLink?.title;
        const pointOrderNumber =
          Array.isArray(tripPoint.demands) && tripPoint.demands.length && tripPoint.demands[0].order.number;
        const arrivalPlanAt = tripPoint.arrivalPlanAt
          ? new Date(tripPoint.arrivalPlanAt)
          : tripPoint.arrivalPredictAt
          ? new Date(tripPoint.arrivalPredictAt)
          : null;

        if (pointOrderNumber) {
          html += `<span><b>${this.$t('ShareTrip.tooltips.OrderNumber')}</b> ${pointOrderNumber}</span><br />`;
        }
        if (arrivalPlanAt) {
          let utcDate = this.$d(
            convertDateWithTimeZone(arrivalPlanAt, tripPoint.location.timezone.offset) as any,
            'date',
          );
          if (this.showTimezone) {
            utcDate += ` ${getTimeZoneString(tripPoint.location.timezone.offset)}`;
          }
          html += `<span><b>${this.$t('ShareTrip.tooltips.ArrivalPlan')}</b> ${utcDate}</span><br />`;
        }
        if (tripPoint?.location?.addresses[0]) {
          html += `<span><b>${this.$t('ShareTrip.tooltips.Address')}</b> ${
            tripPoint?.location?.addresses[0].address
          }</span><br />`;
        }
        if (Array.isArray(tripPoint.deliveryWindows) && tripPoint.deliveryWindows.length) {
          const deliveryWindow = tripPoint.deliveryWindows[0];
          const from = new Date(deliveryWindow.from);
          const to = new Date(deliveryWindow.to);

          html += `<span><b>${this.$t('ShareTrip.tooltips.TimeWindow')}</b> ${this.$d(from, 'date')} — ${this.$d(
            to,
            'date',
          )}</span><br />`;
        }
        if (tripPoint.transport && (tripPoint.transport.name || tripPoint.transport.number)) {
          html += `<span><b>${this.$t('Transport')}:</b> ${tripPoint.transport.name || ''} ${tripPoint.transport
            .number || ''}</span><br />`;
        }
      } else {
        html += `<span><b>${this.$t('Executor')}:</b> ${object.properties.executor?.fullName || ''}</span><br />`;
        if (object.properties.transport && (object.properties.transport.name || object.properties.transport.number)) {
          html += `<span><b>${this.$t('Transport')}:</b> ${object.properties.transport.name || ''} ${object.properties
            .transport.number || ''}</span><br />`;
        }
      }

      tooltip.innerHTML = html;
    } else {
      // tooltip.style.display = 'none';
      tooltip.style.opacity = '0';
    }
  }

  clickHandler({ object }: { object: any }, event: any) {
    let route: RawLocation | undefined;

    if (this.$route.name === 'distributionMonitoring' && object?.properties?.tripId) {
      route = {
        name: 'distributionTrip',
        params: { distributionId: this.$route.params.id, tripId: object.properties.tripId },
      };
    } else if (object?.properties?.tripDbId) {
      route = `/trips/${object.properties.tripDbId}`;
    }

    if (route) {
      if (event?.srcEvent?.domEvent?.ctrlKey) {
        const { href } = this.$router.resolve(route);
        window.open(href, '_blank');
      } else {
        this.$router.push(route);
      }
    }
  }

  updateBounds() {
    const fc = (this.data as any) as FeatureCollection;
    const bounds = new google.maps.LatLngBounds();

    for (const feature of fc.features) {
      if (feature.type === 'Feature' && feature.geometry.type === 'Point') {
        const [lng, lat] = feature.geometry.coordinates;
        bounds.extend({ lat, lng });
      } else if (feature.type === 'Feature' && feature.geometry.type === 'LineString') {
        for (const [lng, lat] of feature.geometry.coordinates) {
          bounds.extend({ lat, lng });
        }
      }
    }

    this.$store.commit('map/setBounds', { id: 'monitor', bounds: bounds.toJSON() });
    this.containsDifferentTimezones();
  }

  containsDifferentTimezones() {
    const self = <any>this;
    const tzOffsets: any = [getLocalTimezoneInSeconds()];
    self.data.features.forEach((el: any) => {
      if (el.geometry.type == 'Point' && el.properties.type !== 'current') {
        const tripPoint: TripPointDto & { tripId: number } = el.properties;
        tzOffsets.push(tripPoint.location.timezone.offset);
      }
    });
    this.showTimezone = tzOffsets.some((val: number, i: number, arr: number[]) => val !== arr[0]);
  }
}
