<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Profile') }}</h1>
    </v-flex>

    <v-flex>
      <v-layout row wrap>
        <v-flex sm12 md3>
          <v-card :class="$vuetify.breakpoint.smAndDown ? 'mb-3' : 'mr-3'">
            <v-card-text>
              <AccountInfo :account="account" :show-phones="false" />
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex sm12 md9>
          <v-card>
            <v-tabs ref="tabs">
              <v-tab href="#tab-1">
                {{ $t('Personal info') }}
              </v-tab>

              <v-tab href="#tab-2">
                {{ $t('Change password') }}
              </v-tab>

              <v-tab href="#tab-3">
                {{ $t('Change phone') }}
              </v-tab>

              <v-tabs-items touchless>
                <v-tab-item id="tab-1">
                  <v-container grid-list-sm class="pa-4">
                    <Form :fields="fields" :error="error" v-model="form" ref="form">
                      <template slot="country" slot-scope="props">
                        <v-autocomplete
                          :label="$t(props.field.label)"
                          :prepend-icon="props.field.icon"
                          :items="props.field.items"
                          :rules="props.field.rules"
                          item-text="name"
                          item-value="alpha2"
                          v-model="form.data[props.field.name]"
                        />
                      </template>
                    </Form>

                    <template>
                      <div class="title mt-4 mb-2">{{ $t('Contacts') }}</div>

                      <AccountContactsListEditor v-model="account.contacts" />
                    </template>
                  </v-container>

                  <v-card-actions>
                    <v-btn @click="save" :disabled="!form.valid" color="primary">
                      {{ $t('Save') }}
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>

                <v-tab-item id="tab-2">
                  <v-container grid-list-sm class="pa-4">
                    <Form :fields="formPass.fields" :error="formPass.error" v-model="formPass.form" ref="formPass" />
                  </v-container>

                  <v-card-actions>
                    <v-btn @click="savePass" :disabled="!formPass.form.valid" color="primary">
                      {{ $t('Save') }}
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>

                <v-tab-item id="tab-3">
                  <v-container grid-list-sm class="pa-4">
                    <Form
                      :fields="formPhone.fields"
                      :error="formPhone.error"
                      v-model="formPhone.form"
                      ref="formPhone"
                    />

                    <Form v-if="codeSent" :fields="formCode.fields" v-model="formCode.form" ref="formCode" />
                  </v-container>

                  <v-card-actions>
                    <v-btn v-if="!codeSent" @click="requestPhoneCode" :disabled="!formPhone.form.valid" color="primary">
                      {{ $t('Get code') }}
                    </v-btn>

                    <v-btn
                      v-if="codeSent"
                      @click="savePhone"
                      :disabled="!formPhone.form.valid || !formCode.form.valid"
                      color="primary"
                    >
                      {{ $t('Save') }}
                    </v-btn>
                  </v-card-actions>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import api from '@/api/auth';
import rules from '@/api/rules';
import AccountInfo from '@/components/AccountInfo';
import AccountContactsListEditor from '@/components/inputs/AccountContactsListEditor.vue';
import { mapState } from 'vuex';
import { getCountriesList } from '@/i18n';

export default {
  name: 'Profile',

  metaInfo() {
    return {
      title: this.$i18n.t('Profile'),
    };
  },

  components: { AccountInfo, AccountContactsListEditor },

  data() {
    return {
      form: {
        valid: true,
        data: {},
      },
      error: null,
      formPass: {
        form: {
          valid: true,
          data: {},
        },
        fields: [
          {
            name: 'current_password',
            label: 'Current password',
            type: 'password',
            icon: 'lock',
            rules: rules.required('Password'),
            isRequired: true,
          },
          {
            name: 'password',
            label: 'New password',
            type: 'password',
            icon: 'lock',
            ...rules.passwordAttrs,
            isRequired: true,
          },
        ],
        error: null,
      },
      formPhone: {
        form: {
          valid: true,
          data: {
            current_phone: '',
            phone: '',
          },
        },
        fields: [
          {
            name: 'current_phone',
            label: 'Current phone number',
            type: 'tel',
            icon: 'phone',
            disabled: true,
          },
          {
            name: 'phone',
            label: 'New phone number',
            type: 'tel',
            icon: 'phone',
            placeholder: '+00000000000',
            rules: [...rules.phone.required, v => this.isPhoneChanged || this.$t('Phone has not changed')],
            isRequired: true,
          },
        ],
        error: null,
      },
      codeSent: false,
      formCode: {
        form: {
          valid: true,
          data: {
            code: '',
          },
        },
        fields: [
          {
            name: 'code',
            label: 'Verification code',
            icon: 'dialpad',
            rules: rules.required('Code', [v => (v && /^[0-9]{6}$/.test(v)) || this.validCodeText]),
          },
        ],
        error: null,
      },
    };
  },

  computed: {
    ...mapState(['account']),

    fields() {
      return [
        {
          name: 'email',
          icon: 'mail',
          label: 'E-Mail',
          disabled: true,
        },
        [
          {
            name: 'last_name',
            icon: 'person',
            label: 'Last name',
            rules: rules.name.required('Last name'),
            isRequired: true,
          },
          {
            name: 'first_name',
            label: 'Name',
            rules: rules.name.required('Name'),
            isRequired: true,
          },
        ],
        {
          name: 'job_title',
          icon: 'mdi-account-details',
          label: 'Job title',
          rules: rules.string.required('Job title'),
          isRequired: true,
        },
        {
          type: 'tel',
          name: 'phone',
          icon: 'phone',
          label: 'Phone',
          disabled: true,
        },
        {
          name: 'country',
          label: 'Select country',
          type: 'select',
          items: this.countriesList,
          icon: 'mdi-compass-outline',
          rules: rules.required('Country'),
        },
      ];
    },

    countriesList() {
      return getCountriesList();
    },

    validCodeText() {
      return this.$t('rules.valid_code');
    },

    isPhoneChanged() {
      return this.formPhone.form.data.phone !== this.formPhone.form.data.current_phone;
    },
  },

  mounted() {
    this.initData(this.account);
  },

  methods: {
    initData(account) {
      if (!account.contacts || !account.contacts.length) {
        account.contacts = [{ note: '', phone: '' }];
      }

      this.$refs.form.setFormData(account);
      this.formPhone.form.data.current_phone = account.phone;
    },

    async save() {
      try {
        this.error = null;

        let data = {
          ...this.form.data,
        };

        delete data.email;
        delete data.phone;

        if (this.account.contacts && this.account.contacts.length) {
          data.contacts = this.account.contacts.filter(c => Boolean(c.phone));
        }

        data = await api.updateProfile(data);

        await api.profile();

        this.initData(data);
      } catch (err) {
        this.error = err;
      }
    },

    async savePass() {
      try {
        this.formPass.error = null;

        await api.updatePassword(this.formPass.form.data);
        this.$refs.formPass.reset();
      } catch (err) {
        this.formPass.error = err;
      }
    },

    async requestPhoneCode() {
      try {
        this.formPhone.error = null;

        await api.requestPhoneCode({
          phone: this.formPhone.form.data.phone,
        });

        this.codeSent = true;
        this.$set(this.formPhone.fields[1], 'disabled', true);
      } catch (err) {
        this.formPhone.error = err;
      }
    },

    async savePhone() {
      try {
        this.formPhone.error = null;

        const data = await api.updatePhone({
          phone: this.formPhone.form.data.phone,
          code: this.formCode.form.data.code,
        });

        this.codeSent = false;
        this.$refs.formPhone.reset();
        this.$refs.formCode.reset();
        this.formPhone.fields[1].disabled = false;
        this.$store.commit('account/setPhone', data.phone);
        this.form.data.phone = data.phone;
        this.formPhone.form.data.current_phone = data.phone;
      } catch (err) {
        this.formPhone.error = err;
      }
    },
  },
};
</script>
