<template>
  <v-dialog v-model="dialog" max-width="320">
    <v-card>
      <error-alert :value="error" />
      <v-card-title class="headline"> {{ $t('Cancel') }}? </v-card-title>
      <v-card-text v-if="item">
        {{ $t('common_strings.cancel_query_item', [item]) }}
      </v-card-text>
      <v-card-text v-else>
        {{ $t('common_strings.cancel_query') }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn flat @click.native="hideModal">
          {{ $t('No') }}
        </v-btn>
        <v-btn color="pink darken-2" flat @click.native="handleAction">
          {{ $t('Yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',
  data() {
    return {
      dialog: true,
    };
  },
  props: {
    error: {
      type: Error,
      default: null,
    },
    item: {
      type: String,
      default: 'an item',
    },
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },
    handleAction() {
      this.$emit('action');
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
