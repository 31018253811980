<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('OrderPage.title') }}: {{ data.number }}</h1>
    </v-flex>

    <v-flex>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <error-alert :value="error" />

            <v-layout row wrap>
              <v-flex lg6 xs12>
                <div class="subheading mb-2">{{ $t('Main parameters') }}</div>

                <div class="ml-4">
                  <AccountPicker :label="$t('OrderPage.owner')" :items="owners" v-model="data.ownerId" single uuid />

                  <v-text-field
                    v-model.trim="data.number"
                    :rules="rules.number"
                    :error-messages="fieldErrors.number"
                    @keydown.prevent.enter="enter"
                    autocomplete="no"
                  >
                    <template #label>
                      <strong class="red--text">* </strong>
                      {{ $t('OrderPage.number') }}
                    </template>
                  </v-text-field>

                  <v-autocomplete
                    v-if="settings.enableCashBoxPayment"
                    v-model="data.paymentStatus"
                    :items="paymentStatuses"
                    :rules="rules.paymentStatus"
                    :error-messages="fieldErrors.paymentStatus"
                  >
                    <template #label>
                      <strong class="red--text">* </strong>
                      {{ $t('OrderPage.paymentStatus') }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    :label="$t('OrderPage.comment')"
                    v-model.trim="data.comment"
                    :error-messages="fieldErrors.comment"
                    @keydown.prevent.enter="enter"
                    autocomplete="no"
                  />
                </div>

                <v-layout row align-center>
                  <div class="subheading">{{ $t('OrderPage.demandsTitle') }}</div>

                  <v-btn flat fab small @click="addDemand">
                    <v-icon color="success">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-layout>

                <div />

                <div v-for="(demand, key) in data.demands" :key="key">
                  <div class="ml-4">
                    <div class="subheading mt-3">
                      {{ $t('OrderPage.demandTitle') }}

                      <v-btn flat fab small @click="removeDemand(key)">
                        <v-icon color="error">mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </div>

                    <div class="ml-4">
                      <div>
                        <v-autocomplete
                          v-model="demand.type"
                          :items="demandTypes"
                          :rules="rules.type"
                          :error-messages="fieldErrors.type"
                        >
                          <template #label>
                            <strong class="red--text">* </strong>
                            {{ $t('Type') }}
                          </template>
                        </v-autocomplete>

                        <div v-if="demand.type">
                          <AddressInputAutocomplete
                            :value="demand"
                            @input="value => updateDemand(key, value)"
                            path="location"
                            :label="$t(`OrderPage.address.${demand.type}`)"
                            :rules="rules[`address_${demand.type}`]"
                            :error-messages="fieldErrors.address"
                            :isRequired="true"
                          />

                          <div class="subheading">{{ $t(`OrderPage.deliveryWindows.${demand.type}`) }}</div>

                          <div v-if="demand.deliveryWindows && demand.deliveryWindows.length">
                            <DateTimeRangeCompactPicker
                              :value="demand.deliveryWindows[0]"
                              manual-time-input
                              ref="deliveryWindows"
                              return-iso
                              :rules="rules.deliveryWindows"
                              :error-messages="fieldErrors.deliveryWindows"
                            />
                          </div>

                          <v-layout>
                            <v-flex class="mr-4">
                              <TimeInput
                                v-model="demand.location.arrivalDuration"
                                :label="$t(`OrderPage.arrivalDuration.${demand.type}`)"
                                :error-messages="fieldErrors.startArrivalDuration"
                                manual-input
                                duration
                                style="width: auto"
                              />
                            </v-flex>

                            <v-flex>
                              <TimeInput
                                v-model="demand.duration"
                                :label="$t(`OrderPage.duration.${demand.type}`)"
                                :error-messages="fieldErrors.startArrivalDuration"
                                manual-input
                                duration
                                style="width: auto"
                              />
                            </v-flex>
                          </v-layout>

                          <div>
                            <ArrayParameterInput
                              :value="demand"
                              @input="value => updateDemand(key, value)"
                              path="location.transportRestrictions"
                              :label="$t('Transport restrictions')"
                            />
                          </div>

                          <div>
                            <v-text-field
                              :value="demand.precedenceInTrip"
                              @input="value => updatePrecedence(key, value)"
                              :label="$t('OrderPage.precedenceInTrip')"
                              @keydown.prevent.enter="enter"
                              type="number"
                              autocomplete="no"
                              :clearable="true"
                            />
                          </div>

                          <div v-if="hasPermission('tags get') && hasPermission('demands tags assign')">
                            <TagsPicker icon v-model="demand.tagIds" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-flex>

              <v-spacer style="max-width: 64px" class="lg-only" />

              <v-flex lg5 xs12>
                <div v-if="showCargoOptions" class="subheading">
                  {{ $t('OrderPage.dimensionsTitle') }}
                </div>

                <div v-if="showCargoOptions" class="ml-4">
                  <div>
                    <v-text-field
                      v-model.number="firstCargo.name"
                      :label="$t('OrderPage.cargo.name')"
                      @keydown.prevent.enter="enter"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.price"
                      :label="$t('OrderPage.cargo.price')"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.amount"
                      :label="$t('OrderPage.cargo.amount')"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-autocomplete
                      v-model="firstCargo.unit"
                      :items="cargoUnitTypes"
                      :rules="rules.unit"
                      :error-messages="fieldErrors.unit"
                    >
                      <template #label>
                        <strong class="red--text">* </strong>
                        {{ $t('OrderPage.cargo.unit') }}
                      </template>
                    </v-autocomplete>

                    <v-text-field
                      v-model.number="firstCargo.length"
                      :label="$t('OrderPage.length')"
                      :rules="rules.length"
                      :error-messages="fieldErrors.length"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.width"
                      :label="$t('OrderPage.width')"
                      :rules="rules.width"
                      :error-messages="fieldErrors.width"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.height"
                      :label="$t('OrderPage.height')"
                      :rules="rules.height"
                      :error-messages="fieldErrors.height"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.capacity.capacityY"
                      :label="$t('OrderPage.maxLength')"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.capacity.capacityX"
                      :label="$t('OrderPage.maxWidth')"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.capacity.capacityZ"
                      :label="$t('OrderPage.maxHeight')"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.capacity.mass"
                      :label="$t('OrderPage.mass')"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <v-text-field
                      v-model.number="firstCargo.capacity.volume"
                      :label="$t('OrderPage.volume')"
                      @keydown.prevent.enter="enter"
                      type="number"
                      autocomplete="no"
                    />

                    <ArrayParameterInput v-model="firstCargo" path="restrictions" :label="$t('Cargo restrictions')" />
                  </div>
                </div>

                <div class="subheading mt-2 mb-2">{{ $t('Additional parameters') }}</div>

                <div class="ml-4">
                  <ArrayParameterInput v-model="data" path="features" :label="$t('Features')" />

                  <ArrayParameterInput v-model="data" path="restrictions" :label="$t('Restrictions')" />

                  <ArrayParameterInput
                    v-model="data"
                    path="executorRestrictions"
                    :label="$t('Executor restrictions')"
                  />
                </div>
              </v-flex>
            </v-layout>
          </v-container>

          <v-container py-0 pb-1 class="text-xs-right">
            <v-btn wrap @click="back">
              {{ $t('Back') }}
            </v-btn>

            <v-btn
              @click="save"
              :disabled="!hasPermission('orders update') || !canSave"
              color="primary"
              :loading="loading"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-container>
        </v-card>
      </v-form>
    </v-flex>
  </v-layout>
</template>

<script>
import { isBefore } from 'date-fns';
import { mapActions, mapState, mapGetters } from 'vuex';
import uuid from 'uuid/v4';
import { sleep } from '@/lib/util';

import api from '@/api/orders';
import rules from '@/api/rules';
import { collectAllErrors } from '@/api/utils';
import branch from '@/api/branch';

import { Demand, DemandType } from '@/models/Order';
import { DeliveryWindows } from '@/models/TripPoint';
import { PaymentStatus, UnitType } from '@/models/Order';

import AddressInputAutocomplete from '@/components/inputs/AddressInputAutocomplete.vue';
import TimeInput from '@/components/inputs/TimeInput.vue';
import DateTimeRangeCompactPicker from '@/components/pickers/timepickers/DateTimeRangeCompactPicker.vue';
import ArrayParameterInput from '@/components/inputs/ArrayParameterInput.vue';
import AccountPicker from '@/components/pickers/AccountPicker';
import TagsPicker from '@/components/pickers/TagsPicker';

function defaultCargo() {
  return {
    id: uuid(),
    capacity: {},
  };
}

export default {
  name: 'OrderDetails',

  components: {
    AddressInputAutocomplete,
    DateTimeRangeCompactPicker,
    TimeInput,
    ArrayParameterInput,
    AccountPicker,
    TagsPicker,
  },

  metaInfo() {
    return {
      title: this.$i18n.t('Order'),
    };
  },

  data() {
    return {
      id: null,
      data: {
        demands: [],
      },
      valid: false,
      error: null,
      firstCargo: defaultCargo(),
      owners: [],
      loading: false,
    };
  },

  async created() {
    if (this.$route.params.id !== 'new') {
      this.id = this.$route.params.id;
      await this.fetchData();
    }

    this.prepareDefaults();

    await this.fetchAccountsList();
    await this.updateTags();
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    showCargoOptions() {
      return this.demands.some(demand => demand.type === 'drop' || demand.type === 'pickup');
    },

    rules() {
      return {
        deliveryWindows: rules.required('OrderPage.deliveryWindowsField'),
        address_pickup: rules.required('OrderPage.address.pickup'),
        address_drop: rules.required('OrderPage.address.drop'),
        address_work: rules.required('OrderPage.address.work'),
        type: rules.required('Type'),
        length: this.cargoNotEmpty ? rules.required('OrderPage.length') : undefined,
        width: this.cargoNotEmpty ? rules.required('OrderPage.width') : undefined,
        height: this.cargoNotEmpty ? rules.required('OrderPage.height') : undefined,
        number: rules.required('OrderPage.number'),
        paymentStatus: rules.required('OrderPage.paymentStatus'),
        unit: this.cargoNotEmpty ? rules.required('OrderPage.cargo.unit') : undefined,
      };
    },

    fieldErrors() {
      return this.error?.errors ? collectAllErrors(this.error.errors) : {};
    },

    cargoNotEmpty() {
      return (
        this.firstCargo.length ||
        this.firstCargo.width ||
        this.firstCargo.height ||
        this.firstCargo.capacity.maxLength ||
        this.firstCargo.capacity.maxWidth ||
        this.firstCargo.capacity.maxHeight ||
        this.firstCargo.capacity.mass ||
        this.firstCargo.capacity.volume ||
        (Array.isArray(this.data.cargoFeatures) && this.data.boxFeatures.length)
      );
    },

    cargoNotEmptyPaymentData() {
      return this.firstCargo.amount;
    },

    demandTypes() {
      return [
        { value: DemandType.drop, text: this.$i18n.t('OrderPage.type.drop') },
        { value: DemandType.pickup, text: this.$i18n.t('OrderPage.type.pickup') },
        { value: DemandType.work, text: this.$i18n.t('OrderPage.type.work') },
      ];
    },

    paymentStatuses() {
      return [
        { value: PaymentStatus.cancelled, text: this.$i18n.t('OrderPaymentStatus.cancelled') },
        { value: PaymentStatus.payed, text: this.$i18n.t('OrderPaymentStatus.payed') },
        { value: PaymentStatus.payedByBill, text: this.$i18n.t('OrderPaymentStatus.payedByBill') },
        { value: PaymentStatus.payedByCard, text: this.$i18n.t('OrderPaymentStatus.payedByCard') },
        { value: PaymentStatus.payedByCash, text: this.$i18n.t('OrderPaymentStatus.payedByCash') },
        { value: PaymentStatus.payedByCredit, text: this.$i18n.t('OrderPaymentStatus.payedByCredit') },
        { value: PaymentStatus.readyForCredit, text: this.$i18n.t('OrderPaymentStatus.readyForCredit') },
        { value: PaymentStatus.readyForPayment, text: this.$i18n.t('OrderPaymentStatus.readyForPayment') },
        { value: PaymentStatus.refund, text: this.$i18n.t('OrderPaymentStatus.refund') },
        { value: PaymentStatus.undefined, text: this.$i18n.t('OrderPaymentStatus.undefined') },
      ];
    },

    cargoUnitTypes() {
      return [
        { value: UnitType.kg, text: this.$i18n.t('kg') },
        { value: UnitType.pieces, text: this.$i18n.t('pieces') },
      ];
    },

    canSave() {
      return this.valid && this.demands.length;
    },

    demands: {
      get() {
        return this.data.demands;
      },

      set(value) {
        this.$set(this.data, 'demands', value);
      },
    },
  },

  methods: {
    ...mapActions('tags', ['updateTags']),

    async fetchAccountsList() {
      try {
        this.owners = await branch.listMy();
      } catch (err) {
        this.error = err;
      }
    },

    async fetchData() {
      try {
        this.data = await api.getOrder(this.id);
      } catch (err) {
        this.error = err;
      }
    },

    prepareDefaults() {
      if (!this.data.cargos || !this.data.cargos.length) {
        this.data.cargos = [defaultCargo()];
      }

      if (!this.data.features) {
        this.data.features = [];
      }

      if (!this.data.restrictions) {
        this.data.restrictions = [];
      }

      if (!this.data.executorRestrictions) {
        this.data.executorRestrictions = [];
      }

      if (!this.data.demands) {
        this.data.demands = [];
      }

      if (!this.demands.length) {
        this.addDemand();
      }

      for (const demand of this.demands) {
        if (!demand.deliveryWindows || !demand.deliveryWindows.length) {
          demand.deliveryWindows = [{}];
        }
      }

      this.firstCargo = this.data.cargos[0];
    },

    checkEmptyValues(obj) {
      Object.keys(obj).forEach(item => {
        if (!obj[item]) {
          obj[item] = ['name'].includes(item) ? '' : 0;
        } else if (typeof obj[item] === 'object') {
          this.checkEmptyValues(obj[item]);
        }
      });
    },

    prepareOutputData() {
      const data = { ...this.data };

      delete data.dbId;
      delete data.updatedAt;
      delete data.createdAt;
      delete data.createdAt;
      delete data.companyId;
      delete data.owner;

      if (data.demands.some(demand => ['drop', 'pickup'].includes(demand.type))) {
        this.checkEmptyValues(this.firstCargo);

        data.cargos[0] = this.firstCargo;
      } else {
        data.cargos = [];
      }

      for (const demand of data.demands) {
        delete demand.orderId;

        demand.deliveryWindows = demand.deliveryWindows.map(deliveryWindow => {
          if (isBefore(deliveryWindow.to, deliveryWindow.from)) {
            throw new Error(this.$t('waypointPropertiesErrors.deliveryWindowFromEarlierThanTo').toString());
          }

          return new DeliveryWindows(deliveryWindow);
        });

        if (data.cargos && data.cargos.length && demand.type !== 'work') {
          demand.targetCargos = data.cargos.map(cargo => cargo.id);
        } else {
          demand.targetCargos = [];
        }

        if (!demand.tagIds) {
          demand.tagIds = [];
        }
      }

      return data;
    },

    addDemand() {
      const newDemand = new Demand({
        location: {
          addresses: [],
          arrivalDuration: 0,
          departureDuration: undefined,
          transportRestrictions: [],
        },
      });
      newDemand.deliveryWindows = [{}];

      this.data.demands.unshift(newDemand);
    },

    async removeDemand(index) {
      this.data.demands.splice(index, 1);
    },

    updateDemand(idx, value) {
      this.$set(this.demands, idx, value);
    },

    updatePrecedence(idx, value) {
      this.$set(this.demands[idx], 'precedenceInTrip', value ? Number(value) : 0);
    },

    async save() {
      try {
        this.error = null;
        this.loading = true;

        if (this.id) {
          await api.patchOrder(this.data.id, this.prepareOutputData());
        } else {
          await api.createOrder(this.prepareOutputData());
          await sleep(5000); // crutch
        }

        this.back();
      } catch (err) {
        console.error(err);
        this.error = err;
        this.$vuetify.goTo(0);
      } finally {
        this.loading = false;
      }
    },

    back() {
      this.$router.push(this.$route.params.back || '/orders');
    },

    enter() {
      if (this.canSave) {
        this.save();
      }
    },
  },
};
</script>
