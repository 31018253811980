<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="$t(label)"
        :value="getValueStatuses"
        prepend-icon="insert_chart"
        class="ts-text-field ellipsis"
        readonly
        v-on="on"
      />
    </template>

    <v-expansion-panel expand class="overflow-y-auto" style="max-height: 395px; display: block">
      <v-expansion-panel-content v-for="group in statuses" :key="group.name">
        <template v-slot:header>
          <v-layout row wrap align-center>
            <v-flex shrink @click.stop>
              <v-checkbox
                :indeterminate="checkIndeterminate(group.key)"
                :value="checkGroup(group.key)"
                @change="selectGroup(group.key)"
                hide-details
              />
            </v-flex>

            <v-flex>
              <span>{{ group.title }}</span>
            </v-flex>
          </v-layout>
        </template>

        <v-list class="pl-5 pa-0" v-if="group.items.length > 1">
          <v-list-tile v-for="status in group.items" :key="status.value">
            <v-list-tile-content style="height: 51px">
              <v-checkbox style="padding: 11px 0px 0px 12px" v-model="localValue" :value="status.value" hide-details>
                <template #label>
                  <span style="font-size: 14px">{{ status.title }}</span>
                </template>
              </v-checkbox>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>

        <template v-slot:actions v-if="group.items.length < 2">
          <v-icon style="display: none" />
        </template>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-menu>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'MultiSelectablePicker',

  data() {
    return {
      localValue: [],
      groups: {
        planned: ['planned', 'pending', 'pendingViewed', 'notAssigned'],
        active: ['active'],
        deleted: ['deleted'],
        finished: ['finished', 'manuallyFinished'],
        canceled: [
          'cancelled:byOperator',
          'cancelled:byStartNewTrip',
          'cancelled:byExecutor',
          'cancelled:byNoSync',
          'cancelled:byRelogin',
          'cancelled:byLogout',
          'cancelled:byCompanyBlocked',
          'cancelled:byPublicApi',
          'cancelled:byDeletedTransport',
          'cancelled:byDeletedAccount',
          'cancelled:undefined',
        ],
      },
    };
  },

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Trip status',
    },
    disabled: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [
        'planned',
        'pending',
        'pendingViewed',
        'notAssigned',
        'active',
        'finished',
        'manuallyFinished',
        'cancelled:byOperator',
        'cancelled:byStartNewTrip',
        'cancelled:byExecutor',
        'cancelled:byNoSync',
        'cancelled:byRelogin',
        'cancelled:byLogout',
        'cancelled:byCompanyBlocked',
        'cancelled:byPublicApi',
        'cancelled:byDeletedTransport',
        'cancelled:byDeletedAccount',
        'cancelled:undefined',
        'deleted',
      ],
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    this.localValue = [...this.value];
  },

  computed: {
    values() {
      return this.localValue.map(status => this.getStatusName(status)).join(', ');
    },

    statuses() {
      const tmp = [];

      for (const group of Object.keys(this.groups)) {
        const statuses = this.groups[group].filter(status => this.items.includes(status));

        if (statuses.length) {
          const groupItem = {
            key: group,
            title: this.$t('trip_status_group.' + group),
            items: statuses.map(status => ({
              title: this.getStatusName(status),
              value: status,
            })),
          };
          tmp.push(groupItem);
        }
      }

      return tmp;
    },

    getValueStatuses() {
      return this.value
        .map(s =>
          s.startsWith('cancelled:')
            ? this.$t(`trip_status.cancelled`) + ' ' + this.$t(`cancelReason.${s.replace('cancelled:', '')}`)
            : this.$t(`trip_status.${s}`),
        )
        .join(', ');
    },
  },

  methods: {
    selectGroup(groupKey) {
      const statusesGroup = this.getStatusesGroup(groupKey);
      const statusesGroupValues = statusesGroup.map(status => status.value);
      const selectedStatusesGroup = this.getSelectedStatusesGroup(groupKey);

      if (statusesGroup.length !== selectedStatusesGroup.length) {
        this.localValue = [...this.localValue, ...statusesGroupValues];
      } else {
        this.localValue = this.localValue.filter(value => !statusesGroupValues.includes(value));
      }
    },

    clear(unclearedStatuses) {
      if (unclearedStatuses && Array.isArray(unclearedStatuses) && unclearedStatuses.length) {
        this.localValue = unclearedStatuses;
      } else {
        this.localValue = [];
      }
    },

    checkGroup(groupKey) {
      return this.getSelectedStatusesGroup(groupKey).length === this.getStatusesGroup(groupKey).length;
    },

    checkIndeterminate(groupKey) {
      const statuses = this.getSelectedStatusesGroup(groupKey);
      return statuses.length ? statuses.length !== this.getStatusesGroup(groupKey).length : false;
    },

    getStatusesGroup(groupKey) {
      const group = this.statuses.find(group => group.key === groupKey);
      return group ? group.items : [];
    },

    getSelectedStatusesGroup(groupKey) {
      return this.groups[groupKey].filter(status => this.localValue.includes(status));
    },

    getStatusName(status) {
      return status.startsWith('cancelled:')
        ? this.$t(`trip_status.cancelled`) + ' ' + this.$t(`cancelReason.${status.replace('cancelled:', '')}`)
        : this.$t(`trip_status.${status}`);
    },
  },

  watch: {
    localValue(newVal) {
      this.$emit('input', newVal);
    },
  },
});
</script>

<style>
.ts-text-field .v-input__append-inner {
  display: none;
}

.ellipsis .v-input__control .v-input__slot .v-text-field__slot input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
