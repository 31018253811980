<template>
  <v-layout column>
    <v-flex pb-1>
      <v-layout row>
        <v-flex shrink>
          <h1>{{ $t('PointFunctionsSettings') }}</h1>
        </v-flex>

        <v-spacer />

        <v-flex shrink v-if="hasPermission('pointFunctions create')">
          <v-btn class="mr-0" color="primary" @click.stop="showEditDialog(null)">
            <v-icon left>add</v-icon>

            {{ $t('Add') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex>
      <v-card>
        <NewGrid :api="apiFunction" :fields="gridFields" ref="grid" @click="e => e">
          <template slot="functions" slot-scope="slotProps">
            <div>
              <template v-for="item in functions">
                <v-checkbox
                  v-if="isEnabled(slotProps.item.functions[item.key])"
                  :input-value="true"
                  :label="$t(item.label)"
                  disabled
                  hide-details
                  :key="item.key"
                />
              </template>
            </div>
          </template>

          <template slot="actions" slot-scope="slotProps">
            <td class="col-narrow" style="width: 70px">
              <v-layout row>
                <v-tooltip top v-if="hasPermission('pointFunctions update')">
                  <v-btn flat icon small class="d-flex" slot="activator" @click.stop="showEditDialog(slotProps.item)">
                    <v-icon small>
                      mode_edit
                    </v-icon>
                  </v-btn>

                  <span>{{ $t('Edit') }}</span>
                </v-tooltip>

                <v-tooltip top v-if="hasPermission('pointFunctions delete')">
                  <v-btn flat icon small class="d-flex" slot="activator" @click.stop="showDeleteDialog(slotProps.item)">
                    <v-icon small>delete</v-icon>
                  </v-btn>

                  <span>{{ $t('Delete') }}</span>
                </v-tooltip>
              </v-layout>
            </td>
          </template>
        </NewGrid>
      </v-card>
    </v-flex>

    <PointFunctionsEditDialog v-if="editDialog" :value="editData" @ok="handleEditSuccess" @close="editDialog = false" />

    <DeleteDialog
      v-if="deleteDialog"
      @delete="handleDelete"
      :delete-item="deleteItemName"
      :delete-error="deleteError"
      @close="deleteDialog = false"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import api, { PointFunctions } from '@/api/pointFunctions';
import NewGrid from '@/components/grid/GridCrud';
import DeleteDialog from '@/components/modals/DeleteDialog';
import PointFunctionsEditDialog from '@/components/modals/PointFunctionsEditDialog';

export default {
  name: 'PointTypeSettings',

  components: { NewGrid, DeleteDialog, PointFunctionsEditDialog },

  data() {
    return {
      functions: PointFunctions.FUNCTIONS,
      editDialog: false,
      editData: null,

      deleteDialog: false,
      deleteError: null,
      apiFunction: null,
    };
  },

  created() {
    this.apiFunction = api.list;
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    api() {
      return api;
    },

    gridFields() {
      return {
        name: {
          label: this.$t('TitleName'),
        },
        functions: {
          label: this.$t('Added functions'),
          sortable: false,
        },
        actions: {
          sortable: false,
        },
      };
    },

    deleteItemName() {
      return this.editData.name;
    },
  },

  methods: {
    isEnabled(value) {
      return !!value;
    },

    showEditDialog(item) {
      this.editDialog = true;

      if (!item) {
        item = {
          foreign_id: '',
          name: '',
          functions: {},
          can_update: true,
        };

        this.functions.forEach(func => {
          item.functions[func.key] = null;
        });
      }
      this.editData = item;
    },

    handleEditSuccess(item) {
      if (item.id) {
        Object.assign(this.editData, item);
      } else {
        this.$refs.grid.getItems();
      }
      this.editDialog = false;
      this.editData = null;
    },

    showDeleteDialog(item) {
      this.deleteError = null;
      this.deleteDialog = true;
      this.editData = item;
    },

    async handleDelete() {
      try {
        await api.remove(this.editData.id);
        this.$refs.grid.getItems();
        this.deleteDialog = false;
      } catch (err) {
        this.deleteError = err;
      }
    },
  },
};
</script>
