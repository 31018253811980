<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Settings reports') }}</h1>
    </v-flex>

    <v-flex>
      <v-card class="sm-screen-tabs">
        <v-progress-linear :indeterminate="true" :height="2" :active="loading" />

        <error-alert :value="error" />

        <v-tabs v-model="tabActive" ref="tabs">
          <v-tab href="#tab-1" class="reports-tab">
            {{ $t('Daily') }}
          </v-tab>
          <v-tab href="#tab-2" class="reports-tab">
            {{ $t('Weekly') }}
          </v-tab>
          <v-tab href="#tab-3" class="reports-tab">
            {{ $t('Monthly') }}
          </v-tab>

          <v-tabs-items touchless class="pt-4">
            <v-tab-item id="tab-1">
              <v-checkbox
                class="pl-4 pb-4"
                hide-details
                :label="$t('Enable daily reports')"
                :disabled="loading || !hasPermission('reports update')"
                v-model="daily.enabled"
              />

              <v-divider />

              <v-container class="pa-4">
                <v-layout column>
                  <v-flex>
                    <time-input
                      :label="$t('Time to send report')"
                      v-model="daily.time"
                      prepend-icon="access_time"
                      :disabled="loading || !daily.enabled || !hasPermission('reports update')"
                      class="ma-0 pa-0"
                      inputWidth="200px"
                    />
                  </v-flex>

                  <v-flex>
                    <BranchPicker
                      prepend-icon="business"
                      :label="$t('Department')"
                      :items="myBranches"
                      v-model="daily.branches"
                      :branch-error="branchError"
                      :disabled="loading || !hasPermission('reports update')"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>

            <v-tab-item id="tab-2">
              <v-checkbox
                class="pl-4 pb-4"
                hide-details
                :label="$t('Enable weekly reports')"
                :disabled="loading || !hasPermission('reports update')"
                v-model="weekly.enabled"
              />

              <v-divider />

              <v-container class="pa-4">
                <v-layout column>
                  <v-flex>
                    <time-input
                      :label="$t('Time to send report')"
                      v-model="weekly.time"
                      prepend-icon="access_time"
                      :disabled="loading || !weekly.enabled || !hasPermission('reports update')"
                      class="ma-0 pa-0"
                      inputWidth="200px"
                    />
                  </v-flex>

                  <v-flex>
                    <v-select
                      :label="$t('Get report at')"
                      prepend-icon="event"
                      :disabled="loading || !weekly.enabled || !hasPermission('reports update')"
                      v-model="weekly.dayOfWeek"
                      :items="weekdays"
                    />
                  </v-flex>

                  <v-layout>
                    <v-flex>
                      <v-select
                        :label="$t('Select period')"
                        prepend-icon="date_range"
                        :disabled="loading || !weekly.enabled || !hasPermission('reports update')"
                        v-model="weekly.currentWeek"
                        :items="weekPeriods"
                      />
                    </v-flex>

                    <v-spacer />

                    <v-flex shrink align-center d-flex>
                      <v-checkbox
                        hide-details
                        :label="$t('Including weekend')"
                        :disabled="
                          loading ||
                            !weekly.enabled ||
                            (weekly.currentWeek && weekly.dayOfWeek < 6) ||
                            !hasPermission('reports update')
                        "
                        v-model="weekly.weekend"
                      />
                    </v-flex>
                  </v-layout>

                  <v-flex>
                    <BranchPicker
                      prepend-icon="business"
                      :label="$t('Department')"
                      :items="myBranches"
                      v-model="weekly.branches"
                      :branch-error="branchError"
                      :disabled="loading || !hasPermission('reports update') || !hasPermission('reports update')"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>

            <v-tab-item id="tab-3">
              <v-checkbox
                class="pl-4 pb-4"
                hide-details
                :label="$t('Enable monthly reports')"
                :disabled="loading || !hasPermission('reports update')"
                v-model="monthly.enabled"
              />

              <v-divider />

              <v-container class="pa-4">
                <v-layout column>
                  <v-flex>
                    <time-input
                      v-model="monthly.time"
                      :label="$t('Time to send report')"
                      :disabled="loading || !monthly.enabled || !hasPermission('reports update')"
                      prepend-icon="access_time"
                      class="ma-0 pa-0"
                      inputWidth="200px"
                    />
                  </v-flex>

                  <v-flex class="pl-3 pb-3 mb-2 cyan lighten-4" v-if="isDevelopment">
                    <v-slider
                      v-model.number="monthly.customDay"
                      :label="`${$t('Custom day')}: ${monthly.customDay}`"
                      :disabled="loading || !monthly.enabled || !hasPermission('reports update')"
                      :max="getDaysInMonth"
                      :hint="$t('customDayInfo')"
                      persistent-hint
                      min="0"
                      thumb-label
                      color="blue"
                      style="max-width: 400px"
                    />
                  </v-flex>

                  <v-flex>
                    <v-select
                      v-model="monthly.firstDay"
                      :label="$t('Get report at')"
                      :disabled="loading || !monthly.enabled || !hasPermission('reports update')"
                      :items="monthDays"
                      prepend-icon="event"
                    />
                  </v-flex>

                  <v-flex>
                    <BranchPicker
                      prepend-icon="business"
                      :label="$t('Department')"
                      :items="myBranches"
                      v-model="monthly.branches"
                      :branch-error="branchError"
                      :disabled="loading || !hasPermission('reports update')"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import _ from 'lodash';
import { getDaysInMonth } from 'date-fns';

import { getWeekdaysList } from '@/i18n';

import api from '@/api/report';
import branch from '@/api/branch';

import BranchPicker from '@/components/pickers/BranchPicker';
import TimeInput from '@/components/inputs/TimeInput';

import { getLocalTimezoneInSeconds } from '../lib/timezone';
import { mapGetters } from 'vuex';

const reportHandlers = {
  daily: (data, ctx) => {
    if (!data) {
      ctx.daily.enabled = false;
      return;
    }

    const enabled = data.enabled;
    const parameters = data.parameters;

    if (parameters) {
      const branches = parameters.branches.filter(
        myBranchId => _.find(ctx.myBranches, curBranch => myBranchId === curBranch.id) !== undefined,
      );

      Object.assign(ctx.daily, {
        time: parameters.time,
        offset: parameters.offset,
        branches,
        enabled,
      });
    }
  },

  weekly: (data, ctx) => {
    if (!data) {
      ctx.weekly.enabled = false;
      return;
    }

    const enabled = data.enabled;
    const parameters = data.parameters;

    if (parameters) {
      const branches = parameters.branches.filter(
        myBranchId => _.find(ctx.myBranches, curBranch => myBranchId === curBranch.id) !== undefined,
      );

      Object.assign(ctx.weekly, {
        time: parameters.time,
        offset: parameters.offset,
        currentWeek: parameters.currentWeek,
        weekend: parameters.weekend,
        dayOfWeek: parameters.dayOfWeek,
        branches,
        enabled,
      });
    }
  },

  monthly: (data, ctx) => {
    if (!data) {
      ctx.monthly.enabled = false;
      return;
    }

    const enabled = data.enabled;
    const parameters = data.parameters;

    if (parameters) {
      const branches = parameters.branches.filter(
        myBranchId => _.find(ctx.myBranches, curBranch => myBranchId === curBranch.id) !== undefined,
      );

      Object.assign(ctx.monthly, {
        time: parameters.time,
        offset: parameters.offset,
        firstDay: parameters.firstDay,
        customDay: parameters.customDay,
        branches,
        enabled,
      });
    }
  },
};

export default {
  name: 'Reports',

  metaInfo() {
    return {
      title: this.$i18n.t('#Reports'),
    };
  },

  components: { BranchPicker, TimeInput },

  data() {
    return {
      myBranches: [],
      branchError: false,
      tabActive: null,
      error: null,
      loading: false,
      daily: {
        enabled: false,
        time: '10:00',
        offset: 0,
        branches: [],
      },
      weekly: {
        enabled: false,
        time: '20:00',
        offset: 0,
        currentWeek: false,
        weekend: false,
        dayOfWeek: 4,
        branches: [],
      },
      monthly: {
        enabled: false,
        time: '20:00',
        offset: 0,
        firstDay: true,
        customDay: 0,
        branches: [],
      },
    };
  },

  async created() {
    this.loading = true;

    await this.fetchData();

    this.$nextTick(() => {
      this.loading = false;
    });
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },

    getDaysInMonth() {
      return getDaysInMonth(new Date());
    },

    weekdays() {
      return getWeekdaysList();
    },

    weekPeriods() {
      return [
        { text: this.$t('Previous week'), value: false },
        { text: this.$t('Current week'), value: true },
      ];
    },

    monthDays() {
      return [
        { text: this.$t('1st day of next month'), value: true },
        { text: this.$t('Last day of current month'), value: false },
      ];
    },
  },

  methods: {
    async fetchData() {
      try {
        this.myBranches = await branch.listMy({
          accounts: false,
        });

        const data = await api.get();

        if (data) {
          const reportTypes = Object.keys(data);

          for (const reportType of reportTypes) {
            if (reportHandlers[reportType]) {
              reportHandlers[reportType](data[reportType], this);
            }
          }
        }
      } catch (err) {
        this.error = err;
      }
    },

    async saveDaily() {
      try {
        this.error = null;
        this.loading = true;

        const data = {};
        data.daily = {
          enabled: this.daily.enabled,
          parameters: {
            time: this.daily.time,
            offset: getLocalTimezoneInSeconds(),
            branches: this.daily.branches,
          },
        };

        if (!this.daily.branches.length) {
          this.$nextTick(() => {
            this.daily.enabled = false;
          });

          this.branchError = true;
          data.daily.enabled = false;
        } else {
          this.branchError = false;

          await api.update(data);
        }

        this.loading = false;
      } catch (err) {
        this.error = err;
        this.loading = false;
      }
    },

    async saveWeekly() {
      try {
        this.error = null;
        this.loading = true;

        const data = {};
        data.weekly = {
          enabled: this.weekly.enabled,
          parameters: {
            time: this.weekly.time,
            offset: getLocalTimezoneInSeconds(),
            currentWeek: this.weekly.currentWeek,
            weekend: this.weekly.weekend,
            dayOfWeek: this.weekly.dayOfWeek,
            branches: this.weekly.branches,
          },
        };

        if (!this.weekly.branches.length) {
          this.$nextTick(() => {
            this.weekly.enabled = false;
          });

          this.branchError = true;
          data.weekly.enabled = false;
        } else {
          this.branchError = false;

          await api.update(data);
        }

        this.loading = false;
      } catch (err) {
        this.error = err;
        this.loading = false;
      }
    },

    saveMonthly: _.debounce(async function() {
      try {
        this.error = null;
        this.loading = true;

        const data = {};
        data.monthly = {
          enabled: this.monthly.enabled,
          parameters: {
            time: this.monthly.time,
            offset: getLocalTimezoneInSeconds(),
            firstDay: this.monthly.firstDay,
            branches: this.monthly.branches,
            customDay: this.monthly.customDay,
          },
        };

        if (!this.monthly.branches.length) {
          this.$nextTick(() => {
            this.monthly.enabled = false;
          });

          this.branchError = true;
          data.monthly.enabled = false;
        } else {
          this.branchError = false;

          await api.update(data);
        }

        this.loading = false;
      } catch (err) {
        this.error = err;
        this.loading = false;
      }
    }, 100),
  },

  watch: {
    tabActive() {
      this.branchError = false;
    },

    daily: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.saveDaily();
        }
      },
    },

    weekly: {
      deep: true,
      handler() {
        if (this.weekly.currentWeek && this.weekly.dayOfWeek < 6) {
          this.weekly.weekend = false;
        }

        if (!this.loading) {
          this.saveWeekly();
        }
      },
    },

    monthly: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.saveMonthly();
        }
      },
    },
  },
};
</script>
