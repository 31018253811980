import Vue from 'vue';
import Component from 'vue-class-component';
import config from './config';
import { PointCoordinates } from '@/models/Location';
import { ActiveRoute, WayCoordinates } from '@/models/Way';
import Trip, { TripStatus } from '@/models/Trip';

const CLEAR_ACTIVE_WAY_STATUSES = [
  TripStatus.finished,
  TripStatus.cancelled,
  TripStatus.manuallyFinished,
  TripStatus.operatorCancelled,
];

const props = Vue.extend({
  props: {
    filter: {
      type: Object,
      default: null,
    },
    wayDirty: {
      type: Boolean,
      default: false,
    },

    showLine: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    activeRoute: {
      deep: true,
      handler: function() {
        (this as any).updateLines();
      },
    },

    passedRoute: {
      deep: true,
      handler: function() {
        (this as any).updateLines();
      },
    },

    wayDirty: {
      handler: function() {
        (this as any).updateLines();
      },
    },

    filter: {
      deep: true,
      handler: function() {
        (this as any).updateLines();
      },
    },
  },
});

interface LineStyle {
  borderColor: string;
  borderWeight: number;
  strokeColor: string;
  strokeWeight: number;
}

@Component
export default class MapUpdateLinesMixin extends props {
  trip!: Trip | null;
  activeWay!: ActiveRoute | null;
  passedWay!: WayCoordinates[];
  lines = {
    activeRoute: [] as google.maps.Polyline[],
    passedRoute: [] as google.maps.Polyline[],
  };

  clearAllLines() {
    this.clearLines(this.lines.activeRoute);
    this.clearLines(this.lines.passedRoute);
  }

  clearLines(lines: google.maps.Polyline[]) {
    for (const line of lines) {
      line.setMap(null);
    }
    lines.splice(0, lines.length);
  }

  drawLines(map: google.maps.Map, path: PointCoordinates[], lines: google.maps.Polyline[], style: LineStyle) {
    this.clearLines(lines);

    let line = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: style.borderColor,
      strokeWeight: style.borderWeight,
      strokeOpacity: 1.0,
      map: map,
      zIndex: -1,
    });
    lines.push(line);

    line = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: style.strokeColor,
      strokeWeight: style.strokeWeight,
      strokeOpacity: 1.0,
      map: map,
      zIndex: 0,
    });
    lines.push(line);
  }

  decodeWayData(way: ActiveRoute): PointCoordinates[] {
    if (way.legs) {
      const legPoints = way.legs.map(leg => leg.points);
      return legPoints.reduce((result, points) => {
        return result.concat(points);
      }, []);
    }
    return [];
  }

  async updateLines() {
    if (!this.showLine) {
      return;
    }

    const map: google.maps.Map = await (this as any).getMap();

    let needActiveRoute = this.filter ? this.filter.showActiveRoute : true;
    const needPassedRoute = this.filter ? this.filter.showPassedRoute : true;

    if (this.trip && CLEAR_ACTIVE_WAY_STATUSES.includes(this.trip.status)) {
      needActiveRoute = false;
    }

    if (!needActiveRoute || !this.activeWay) {
      this.clearLines(this.lines.activeRoute);
    }

    if (!needPassedRoute) {
      this.clearLines(this.lines.passedRoute);
    }

    if (needActiveRoute && this.activeWay) {
      const path = this.decodeWayData(this.activeWay);
      this.drawLines(map, path, this.lines.activeRoute, {
        borderColor: config.color.border,
        borderWeight: 9.0,
        strokeColor: this.wayDirty || this.trip?.isLostSync ? config.color.dirty : config.color.active,
        strokeWeight: 7.0,
      });
    }

    if (needPassedRoute && this.passedWay) {
      this.drawLines(map, this.passedWay, this.lines.passedRoute, {
        borderColor: config.color.border,
        borderWeight: 9.0,
        strokeColor: config.color.passed,
        strokeWeight: 7.0,
      });
    }
  }
}
