<template>
  <div>
    <v-btn round @click.native.stop="dialog = true">
      {{ $t('Delete account') }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="900px" min-width="900px">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Subscription') }}
        </v-card-title>
        <v-card-text>
          <v-layout row wrap align-end class="text-xs-center">
            <v-flex xs12 sm12> {{ $t('Number of paid accounts') }}: {{ billing.paidSlots }} </v-flex>
          </v-layout>
          <br />
          <v-divider />
          <br />
          <v-layout row wrap align-end class="text-xs-center">
            <v-flex xs12 sm6> {{ $t('Inactive accounts') }}: {{ freeSlots }} </v-flex>
            <v-flex xs12 sm6> {{ $t('Active accounts') }}: {{ billing.activeAccounts }} </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-xs-center">
            <v-flex xs12 sm6>
              <v-layout row wrap align-end class="text-xs-center">
                <v-flex xs12 sm6>
                  {{ $t('Delete accounts') }}
                </v-flex>
                <v-flex xs12 sm6>
                  <number-picker v-model="numSlots" :min="minSlots" :max="freeSlots" />
                </v-flex>
              </v-layout>
              <v-layout row wrap align-end class="text-xs-center">
                <v-flex xs12 sm12>
                  <v-btn color="error" round :disabled="!canDeleteSlot" @click="sellSlots">
                    {{ $t('Delete account') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm6>
              <v-layout row wrap align-end class="text-xs-center">
                <v-flex xs12 sm12>
                  <v-btn to="/accounts" round>
                    {{ $t('Delete employee') }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <br />
          <v-divider />
          <br />
          <div style="color: red">
            <h3>{{ $t('Important') }}!</h3>
          </div>
          <div>{{ $t('Paid accounts consist of') }}:</div>
          <ul style="padding-left: 20px">
            <li>{{ $t('Active account, which means a account allocated to an employee') }}.</li>
            <li>{{ $t('Inactive account are accounts, which are not allocated to an employee') }}</li>
          </ul>
          <br />
          <div>{{ $t('Information on deleting accounts') }}:</div>
          <ul style="padding-left: 20px">
            <li>{{ $t('only inactive accounts can be deleted') }}</li>
            <li>{{ $t('delete_active_accounts_moved_page') }}</li>
          </ul>
          <br />
          <div style="color: red">
            <h3>{{ $t('When you remove paid accounts, no refunds are made!') }}</h3>
          </div>
        </v-card-text>
        <v-card-actions />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NumberPicker from '@/components/pickers/NumberPicker';
import { mapState } from 'vuex';

export default {
  components: {
    NumberPicker,
  },
  data() {
    return {
      dialog: false,
      numSlots: 0,
      minSlots: 0,
      remainingSubscriptionPeriod: 10,
      canDeleteSlot: false,
      plan: '',
      costPerSlotByMonth: 0,
      currency: '',
      totalCost: 0,
    };
  },
  methods: {
    sellSlots() {
      this.$emit('sellSlots', this.numSlots);
      this.dialog = false;
    },
  },
  computed: {
    ...mapState(['billing']),
    freeSlots() {
      return this.billing.paidSlots - this.billing.activeAccounts;
    },
    activeAccounts() {
      return this.billing.activeAccounts;
    },
    paidSlots() {
      return this.billing.paidSlots;
    },
  },
  watch: {
    numSlots(val) {
      this.canDeleteSlot = val > 0;
    },
  },
};
</script>
