import XLSX from 'xlsx';

function makeTemplateData(workbook) {
  workbook.SheetNames.push('Sheet');
  workbook.Sheets['Sheet'] = XLSX.utils.aoa_to_sheet([
    [
      'Foreign ID',
      'ID Транспорта',
      // 'Комментарий ТС',
      'Город',
      'Адрес',
      'Широта',
      'Долгота',
      'Клиент',
      'Телефон для SMS',
      'Имя контакта 1',
      'Телефон контакта 1',
      'Имя контакта 2',
      'Телефон контакта 2',
      'Имя контакта 3',
      'Телефон контакта 3',
      'Дата начала поездки',
      'Время начала поездки',
      'Окно доставки дата с',
      'Окно доставки время с',
      'Окно доставки дата по',
      'Окно доставки время по',
      'Время у клиента',
      'SMS',
      'Комментарий',
      'Задание',
      'Время подъезда к точке',
      'Время выезда из точки',
      'Требования к транспорту',
    ],
  ]);
  workbook.Sheets['Sheet']['!cols'] = [];
  for (let i = 0; i < 27; i += 1) {
    workbook.Sheets['Sheet']['!cols'].push({ wpx: 150 });
  }
}

export { makeTemplateData };
