/* global google $ */

import i18n from '@/i18n';

const gmap = { init, waitInit, locale: '' };

let gmapResolve;
let isScriptLoading = false;

const googleMapPromise = new Promise(resolve => {
  gmapResolve = resolve;
});

function loadScript(cb) {
  gmap.locale = i18n.locale;

  const scriptUrl = `https://maps.googleapis.com/maps/api/js?v=3.49&key=AIzaSyCC4r7K0-4WMlEanpQdGVC6T-t78kQRqm4&libraries=geometry,places,drawing&language=${i18n.locale}`;
  $.getScript(scriptUrl, cb);

  isScriptLoading = true;
}

function init() {
  if (!global.google && !isScriptLoading) {
    loadScript(() => {
      gmapResolve();
      gmapUpdate();
    });
  } else if (!isScriptLoading) {
    gmapUpdate();
  }
}

async function waitInit() {
  return googleMapPromise;
}

function gmapUpdate() {
  for (const property in google.maps) {
    gmap[property] = google.maps[property];
  }
}

export default gmap;
