<template>
  <v-input class="contact-editor" prepend-icon="phone" :rules="rules" :value="value">
    <div style="width: 100%">
      <v-text-field
        :value="value.name"
        :label="$t('Name')"
        maxlength="50"
        hide-details
        clearable
        append-outer-icon="mdi-minus-circle-outline"
        @click:append-outer="onDelete"
        @input="updateName"
      />
      <v-text-field
        :value="value.jobTitle"
        :label="$t('Job title')"
        maxlength="20"
        hide-details
        @input="updateJobTitle"
      />
      <v-text-field :value="value.note" :label="$t('Note')" maxlength="50" hide-details @input="updateNote" />
      <PhoneListEditorNew :value="value.phones" @input="updatePhones" @updatePhoneValid="updatePhoneValid" />
    </div>
  </v-input>
</template>

<script>
import PhoneListEditorNew from '@/components/inputs/PhoneListEditorNew';

export default {
  components: { PhoneListEditorNew },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    rules() {
      return [
        v => {
          if (v.phones.length === 0 || !v.phones[0].phone) {
            return this.$t('rules.required', [this.$t('Phone')]);
          }
          return true;
        },
      ];
    },
  },

  methods: {
    updateName(value) {
      this.$emit('input', { ...this.value, name: value });
    },

    updateJobTitle(value) {
      this.$emit('input', { ...this.value, jobTitle: value });
    },

    updateNote(value) {
      this.$emit('input', { ...this.value, note: value });
    },

    updatePhones(value) {
      this.$emit('input', { ...this.value, phones: value });
    },

    onDelete() {
      this.$emit('delete');
    },

    updatePhoneValid(value) {
      this.$emit('updatePhoneValid', value);
    },
  },
};
</script>
