
import Vue from 'vue';
import { AccountStatus } from '@/models/Account';

export default Vue.extend({
  name: 'AccountStatusPicker',

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Account Status',
    },
    disabled: {
      type: Boolean,
    },
  },

  computed: {
    statuses() {
      return Object.keys(AccountStatus).map(acc => ({
        text: this.$t(`account_status.${acc}`),
        value: acc,
      }));
    },
  },

  methods: {
    selectStatus(value: Array<any>) {
      this.$emit('input', value);
    },
  },
});
