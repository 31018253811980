import Vue, { CreateElement } from 'vue';
import Component from 'vue-class-component';
import { Inject, Watch } from 'vue-property-decorator';
import { FeatureCollection } from 'geojson';
import MapLabel from '../../../vendor/maplabel/maplabel';
import zones from '@/api/zones';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
})
export default class ZonesEditor extends Vue {
  @Inject('getMap') readonly getMap!: { (): Promise<google.maps.Map> };

  map: google.maps.Map | null = null;
  labels = new Map<google.maps.Data.Feature, MapLabel>();
  dirty = false;

  hasPermission!: (permission: string) => any;

  render(h: CreateElement) {
    return h();
  }

  async created() {
    const data = await zones.getZones();
    await this.initZones(data);
  }

  async initZones(initialData: FeatureCollection) {
    const map = await this.getMap();
    this.map = map;

    if (this.hasPermission('zones update')) {
      map.data.addListener('click', ({ feature }: { feature: google.maps.Data.Feature }) => {
        this.$emit('edit', feature.getProperty('name'));
      });

      const drawingManager = new google.maps.drawing.DrawingManager({
        // drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
      });

      drawingManager.setMap(map);

      google.maps.event.addListener(drawingManager, 'polygoncomplete', (polygon: google.maps.Polygon) => {
        this.dirty = true;

        const path = polygon.getPath().getArray();

        map.data.add({
          geometry: new google.maps.Data.Polygon([path]),
          properties: {
            name: this.findFreeZoneName(),
          },
        });

        polygon.setMap(null);

        drawingManager.setOptions({
          drawingMode: null,
        });
      });

      map.data.setStyle({
        editable: true,
      });
    }

    map.data.addListener('addfeature', ({ feature }: { feature: google.maps.Data.Feature }) => {
      this.addLabel(feature);
    });

    map.data.addListener('removefeature', ({ feature }: { feature: google.maps.Data.Feature }) => {
      this.removeLabel(feature);
    });

    map.data.addListener(
      'setgeometry',
      ({ feature }: { feature: google.maps.Data.Feature; newGeometry: google.maps.Data.Geometry }) => {
        this.dirty = true;
        this.updateLabel(feature);
      },
    );

    map.data.addGeoJson(initialData);

    const boundingBox = getBoundingBox(initialData);

    if (!boundingBox.isEmpty()) {
      map.fitBounds(boundingBox);
    }
  }

  async save() {
    const data = await new Promise<FeatureCollection>((resolve, reject) => this.map?.data.toGeoJson(resolve as any));

    await zones.putZones(data);

    this.dirty = false;
  }

  findFreeZoneName() {
    const idList = new Set<string>();
    this.map?.data.forEach(feature => idList.add(feature.getProperty('name')));

    let i = 1;
    while (idList.has(`ZONE${i}`)) {
      i++;
    }

    return `ZONE${i}`;
  }

  changeFeatureName(oldName: string, newName: string) {
    this.map?.data.forEach(feature => {
      if (feature.getProperty('name') === oldName) {
        this.dirty = true;
        feature.setProperty('name', newName);
      }

      this.updateLabel(feature);
    });
  }

  deleteFeature(name: string) {
    let foundFeature;

    this.map?.data.forEach(feature => {
      if (feature.getProperty('name') === name) {
        foundFeature = feature;
      }
    });

    if (foundFeature) {
      this.dirty = true;
      this.map?.data.remove(foundFeature);
    }
  }

  getCenterPosition(geometry: google.maps.Data.Geometry): google.maps.LatLng {
    if (geometry.getType() === 'Polygon') {
      const path = (geometry as google.maps.Data.Polygon).getArray()[0].getArray();
      const bounds = new google.maps.LatLngBounds();

      for (const coord of path) {
        bounds.extend(coord);
      }

      return bounds.getCenter();
    }

    return new google.maps.LatLng(0, 0);
  }

  addLabel(feature: google.maps.Data.Feature) {
    const mapLabel = new MapLabel({
      text: feature.getProperty('name'),
      position: this.getCenterPosition(feature.getGeometry()),
      map: this.map as google.maps.Map,
      fontSize: 20,
      align: 'center',
    });

    this.labels.set(feature, mapLabel);
  }

  updateLabel(feature: google.maps.Data.Feature) {
    const mapLabel = this.labels.get(feature);

    if (mapLabel) {
      // @ts-ignore
      mapLabel.set('position', this.getCenterPosition(feature.getGeometry()));
      // @ts-ignore
      mapLabel.set('text', feature.getProperty('name'));
    }
  }

  removeLabel(feature: google.maps.Data.Feature) {
    const mapLabel = this.labels.get(feature);

    if (mapLabel) {
      // @ts-ignore
      mapLabel.setMap(null);
    }
  }

  @Watch('dirty')
  onDirtyChange() {
    this.$emit('update:dirty', this.dirty);
  }
}

function getBoundingBox(data: GeoJSON.FeatureCollection): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();

  for (let i = 0; i < data.features.length; i++) {
    const geometry = data.features[i].geometry;

    if (geometry.type === 'Polygon') {
      const coordinates = (geometry as GeoJSON.Polygon).coordinates;

      for (const [lng, lat] of coordinates[0]) {
        bounds.extend({ lat, lng });
      }
    }
  }
  return bounds;
}
