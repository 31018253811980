
import Vue from 'vue';
import { mapMutations } from 'vuex';
import MapFullPageWidget from '@/components/map/MapFullPageWidgetNew';
import ZonesEditor from '@/components/map/ZonesEditor';
import ZoneTitleDialog from '@/components/modals/ZoneTitleDialog.vue';

export default Vue.extend({
  name: 'Zones',

  components: {
    MapFullPageWidget,
    ZonesEditor,
    ZoneTitleDialog,
  },

  data() {
    return {
      showEditDialog: false,
      dirty: false,
      editFeatureName: null as string | null,
    };
  },

  created() {
    this.setUnloadListener(this.windowUnloadHandler);
  },

  beforeDestroy() {
    this.clearUnloadListener(this.windowUnloadHandler);
  },

  beforeRouteLeave(to, from, next) {
    if (this.dirty) {
      ((this as any).saveZones() as Promise<void>).then(() => {
        next();
      });
    } else {
      next();
    }
  },

  methods: {
    ...mapMutations('site', ['setUnloadListener', 'clearUnloadListener']),

    windowUnloadHandler(e: Event) {
      if (this.dirty) {
        e.preventDefault();
        e.returnValue = true;
      }
    },

    openEditDialog(featureName: string) {
      this.showEditDialog = true;
      this.editFeatureName = featureName;
    },

    closeEditDialog() {
      this.showEditDialog = false;
    },

    changeFeatureName(newName: string) {
      (this.$refs as any)?.zones.changeFeatureName(this.editFeatureName, newName);
      this.showEditDialog = false;
    },

    deleteFeature(newName: string) {
      (this.$refs as any)?.zones.deleteFeature(this.editFeatureName);
      this.showEditDialog = false;
    },

    saveZones() {
      return (this.$refs as any)?.zones.save();
    },
  },

  watch: {
    dirty() {
      this.saveZones();
    },
  },
});
