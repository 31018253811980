import http from './httpV2';
import { TripDto } from '@/models/Trip';

type RadarGetResponse = TripDto & {
  executor: Account;
  error: { code: string } | null;
};

const path = '/radar';

async function get(id: string): Promise<RadarGetResponse> {
  const res = await http.get<RadarGetResponse>(`${path}/${id}`);
  return res.data;
}

export default {
  get,
};
