
import Vue from 'vue';
import TimeWithOptionalDay from '@/components/TimeWithOptionalDay';
import TimeIntervalWithOptionalDay from '@/components/TimeIntervalWithOptionalDay';
import { getLocalTimezoneInSeconds } from '@/lib/timezone';

export default Vue.extend({
  components: {
    TimeWithOptionalDay,
    TimeIntervalWithOptionalDay,
  },

  data() {
    return {
      time1: new Date(),
      time2: new Date(new Date().getTime() + 86400 * 1000),
      time3: new Date(),
      showDateAlways: false,
      showDateNull: false,
      showTimezonesAlways: false,
      showTimezonesNull: false,
      inOneLine: false,
      invalid: false,
      tilde: false,
      localZone: getLocalTimezoneInSeconds(),
      status: null,
    };
  },

  computed: {
    showDate(): boolean | null {
      return this.showDateNull ? null : this.showDateAlways;
    },

    showTimezones(): boolean | null {
      return this.showTimezonesNull ? null : this.showTimezonesAlways;
    },

    option(): string | null {
      return this.tilde ? 'tilde' : null;
    },
  },
});
