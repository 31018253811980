export default {
  namespaced: true,

  state: {
    branches: [],
  },

  mutations: {
    setBranches(state, data) {
      state.branches = data;
    },
  },
};
