<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Employee') }}: {{ data.email }}</h1>
    </v-flex>

    <error-alert :value="error" />

    <v-flex>
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-container>
            <v-alert color="success" :value="!!successText" class="mb-4">
              {{ successText }}
            </v-alert>

            <v-layout row wrap>
              <v-flex lg6 xs12>
                <v-layout column>
                  <v-flex>
                    <div class="title mb-2">
                      {{ $t('Main parameters') }}
                    </div>

                    <v-text-field v-model="data.foreignId" :rules="foreignIdRules" prepend-icon="mdi-key">
                      <template #label>
                        <strong class="red--text">* </strong>
                        {{ $t('foreignId') }}
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model.trim="data.email"
                      :rules="emailRules"
                      prepend-icon="mail"
                      type="email"
                      :error-messages="fieldErrors.email"
                      :readonly="!canUpdate"
                      @keydown.prevent.enter="enter"
                      autocomplete="no"
                    >
                      <template #label>
                        <strong class="red--text">* </strong>
                        {{ $t('E-Mail') }}
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model.trim="data.lastName"
                      prepend-icon="person"
                      :rules="rules.lastName"
                      :error-messages="fieldErrors.lastName"
                      :readonly="!canUpdate"
                      @keydown.prevent.enter="enter"
                      autocomplete="no"
                    >
                      <template #label>
                        <strong class="red--text">* </strong>
                        {{ $t('Last name') }}
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model.trim="data.firstName"
                      :rules="rules.firstName"
                      :error-messages="fieldErrors.firstName"
                      :readonly="!canUpdate"
                      @keydown.prevent.enter="enter"
                      autocomplete="no"
                      style="margin-left: 33px"
                    >
                      <template #label>
                        <strong class="red--text">* </strong>
                        {{ $t('Name') }}
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model.trim="data.jobTitle"
                      :rules="rules.jobTitle"
                      :readonly="!canUpdate"
                      prepend-icon="mdi-account-details"
                      :error-messages="fieldErrors.jobTitle"
                      @keydown.prevent.enter="enter"
                      autocomplete="no"
                    >
                      <template #label>
                        <strong class="red--text">* </strong>
                        {{ $t('Job title') }}
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model.trim="data.phone"
                      :label="$t('Phone')"
                      :rules="rules.phone"
                      :error-messages="fieldErrors.phone"
                      :readonly="!canUpdate"
                      prepend-icon="phone"
                      type="tel"
                      placeholder="+00000000000"
                      @keydown.prevent.enter="enter"
                      autocomplete="no"
                    />

                    <v-autocomplete
                      v-model="data.country"
                      :label="$t('Country')"
                      :rules="rules.country"
                      prepend-icon="mdi-compass-outline"
                      :items="this.countriesList"
                      :disabled="!canUpdate"
                      item-text="name"
                      item-value="alpha2"
                    />

                    <TransportTypePicker
                      v-model="data.ownTransportType"
                      label="Own transport type"
                      :disabled="!canUpdate"
                    />

                    <v-text-field
                      v-if="showRoleField"
                      v-model="data.role.name"
                      type="text"
                      prepend-icon="mdi-key"
                      :disabled="true"
                    >
                      <template #label>
                        {{ $t(`Account\'s role`) }}
                      </template>
                    </v-text-field>
                  </v-flex>

                  <v-flex>
                    <div class="title">
                      {{ $t('Contacts') }}
                    </div>

                    <AccountContactsListEditor v-model="data.contacts" :show-actions="canUpdate" />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-spacer style="max-width: 64px" class="lg-only" />

              <v-flex lg5 xs12 v-if="hasPermission('distributions create')">
                <v-layout column>
                  <DefaultShiftParameters v-model="data" unit="min" :readonly="!canUpdate" />

                  <v-flex>
                    <div class="title">
                      {{ $t('Additional parameters') }}
                    </div>

                    <div class="ml-4">
                      <ArrayParameterInput
                        v-model="data"
                        path="features"
                        :label="$t('Features')"
                        :readonly="!canUpdate"
                      />

                      <ArrayParameterInput
                        v-model="data"
                        path="transportRestrictions"
                        :label="$t('Transport restrictions')"
                        :readonly="!canUpdate"
                      />
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>

          <v-card-actions>
            <v-container py-0>
              <v-btn v-if="canUpdateRole" @click="openChangeRolesDialog">
                {{ $t('Change role') }}
              </v-btn>

              <template>
                <v-btn
                  v-if="isAvailable && hasPermission('accounts password')"
                  @click="openChangePasswordDialog"
                  :disabled="sending"
                >
                  {{ $t('Change password') }}
                </v-btn>

                <v-btn
                  v-if="isAvailable && hasPermission('accounts password restore')"
                  @click="restorePassword"
                  :disabled="sending"
                >
                  {{ $t('Restore password') }}
                </v-btn>

                <v-btn v-if="canUpdate" @click="save" :disabled="!valid" :loading="sending" color="primary">
                  {{ $t('Save') }}
                </v-btn>
              </template>

              <v-btn @click="back">
                {{ $t('Back') }}
              </v-btn>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-flex>

    <ChangePasswordForUser v-if="showChangePasswordDialog" :account-id="data.id" @close="closeChangePasswordDialog" />

    <ChangeRolesForUser
      v-if="showChangeRolesDialog"
      :account="data"
      @close="closeChangeRolesDialog"
      @roleChanged="name => (data.role.name = name)"
    />

    <RestorePasswordNotification
      v-if="showRestoreNotification"
      :email="data.email"
      :phone="data.phone"
      @close="closeRestoreNotification"
    />
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import api from '@/api/account';
import restoreApi from '@/api/restoreNew';
import rules from '@/api/rules';
import { collectAllErrors } from '@/api/utils';

import DefaultShiftParameters from '@/components/DefaultShiftParameters.vue';
import ArrayParameterInput from '@/components/inputs/ArrayParameterInput.vue';
import ChangePasswordForUser from '@/components/modals/ChangePasswordForUser.vue';
import ChangeRolesForUser from '@/components/modals/ChangeRolesForUser.vue';
import RestorePasswordNotification from '@/components/modals/RestorePasswordNotification';
import TransportTypePicker from '@/components/pickers/TransportTypePicker.vue';
import AccountContactsListEditor from '@/components/inputs/AccountContactsListEditor.vue';

import { getCountriesList } from '@/i18n';
import { AccountStatus } from '@/models/Account';

export default {
  name: 'AccountDetails',

  components: {
    DefaultShiftParameters,
    ArrayParameterInput,
    ChangePasswordForUser,
    ChangeRolesForUser,
    RestorePasswordNotification,
    TransportTypePicker,
    AccountContactsListEditor,
  },

  metaInfo() {
    return {
      title: this.$i18n.t('Employees'),
    };
  },

  data() {
    return {
      id: null,
      data: {
        role: {
          name: '',
        },
        contacts: [],
      },
      valid: false,
      sending: false,
      error: null,
      showChangePasswordDialog: false,
      showRestoreNotification: false,
      showChangeRolesDialog: false,
      successText: '',
      additionalPhones: [],
      maxAdditionalPhones: 2,
    };
  },

  async created() {
    this.id = !isNaN(this.$route.params.id) ? parseInt(this.$route.params.id) : this.$route.params.id;

    await this.fetchData();
  },

  computed: {
    ...mapState('company', ['country', 'settings', 'ownerId']),
    ...mapGetters('account', ['getCurrentAccountId']),
    ...mapGetters('auth', ['hasPermission']),

    rules() {
      return {
        firstName: rules.name.required('Name'),
        lastName: rules.name.required('Last name'),
        jobTitle: rules.name.required('Job title'),
        phone: rules.phone,
        country: rules.required('Country'),
      };
    },

    emailRules() {
      if (!this.data.phone && !this.data.email) {
        return [() => this.$t('Please input email or phone')];
      }
      return rules.email;
    },

    foreignIdRules() {
      return rules.foreignId;
    },

    fieldErrors() {
      if (this.error && this.error.errors) {
        return collectAllErrors(this.error.errors);
      }

      return {};
    },

    countriesList() {
      return getCountriesList();
    },

    canUpdate() {
      return this.data.canUpdate;
    },

    isAvailable() {
      return this.data.isAvailable;
    },

    canUpdateRole() {
      const isCompanyOwner = this.data.dbId === this.ownerId;
      const isCurrentAccount = this.getCurrentAccountId === this.data.id;

      return (
        this.data.isAvailable && !isCompanyOwner && !isCurrentAccount && this.hasPermission('accounts roles update')
      );
    },

    showRoleField() {
      return this.data.dbId !== this.ownerId;
    },

    isActive() {
      return this.data.status !== AccountStatus.inactive;
    },
  },

  methods: {
    async fetchData() {
      try {
        const data = await api.get(this.id, { inactive: true });
        this.external_id = data.external_id;

        if (!data.role) {
          data.role = {};
        }

        if (!data.contacts.length) {
          data.contacts.push({ note: '', phone: '' });
        }

        this.$set(this, 'data', data);
      } catch (err) {
        this.error = err;
      }
    },

    async save() {
      try {
        this.error = null;
        this.sending = true;

        if (this.id) {
          const data = {
            foreignId: this.data.foreignId,
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            jobTitle: this.data.jobTitle,
            email: this.data.email || null,
            phone: this.data.phone || null,
            contacts: this.data.contacts.filter(c => Boolean(c.phone)),
            country: this.data.country,
            features: this.data.features,
            transportRestrictions: this.data.transportRestrictions,
            ownTransportType: this.data.ownTransportType,
            defaultShift: this.data.defaultShift,
          };

          await api.update(this.data.id, data);
        }

        this.back();
      } catch (err) {
        this.error = err;
      } finally {
        this.sending = false;
      }
    },

    back() {
      this.$router.push(this.$route.params.back || '/accounts');
    },

    async restorePassword() {
      try {
        this.error = null;
        await restoreApi.restorePassword(this.data.id);
        this.openRestoreNotification();
      } catch (e) {
        this.error = e;
      }
    },

    openChangePasswordDialog() {
      this.showChangePasswordDialog = true;
    },

    closeChangePasswordDialog(msg) {
      this.showChangePasswordDialog = false;

      if (msg) {
        this.successText = msg;
        setTimeout(() => {
          this.successText = '';
        }, 4000);
      }
    },

    openRestoreNotification() {
      this.showRestoreNotification = true;
    },

    closeRestoreNotification() {
      this.showRestoreNotification = false;
    },

    openChangeRolesDialog() {
      this.showChangeRolesDialog = true;
    },

    closeChangeRolesDialog(msg) {
      this.showChangeRolesDialog = false;

      if (msg) {
        this.successText = msg;
        setTimeout(() => {
          this.successText = '';
        }, 4000);
      }
    },

    enter() {
      if (this.valid && this.data.status !== AccountStatus.inactive) {
        this.save();
      }
    },

    updateContacts(value) {
      this.data.contacts = value;
    },
  },
};
</script>
