import { TripPointStatus } from '@/models/TripPoint';
import { ITripMap } from './lib';
import { getIconSettings, MarkerIconName } from '../../config';
import { MapPointData } from '@/services/TripPageService';
import { MapMarkerOptions } from '../../primitives/MapMarker';

export default function(point: MapPointData, parent: ITripMap): Omit<MapMarkerOptions, 'position'> {
  let icon: MarkerIconName = 'active';
  let label = point.number ? String(point.number) : '';

  const { tripPoint, checkin, isCurrent } = point;
  const { secondLabelInfoMap } = parent;

  let text = '';
  let downText;

  if ([TripPointStatus.planned, TripPointStatus.active, TripPointStatus.waitingForAdd].includes(tripPoint.status)) {
    icon = point.isDowntime || point.missDeliveryWindow ? 'activeMiss' : 'active';

    if (point.arrivalPlanAt) {
      text +=
        '<i aria-hidden="true" class="v-icon mdi mdi-tilde"></i> ' +
        parent.formatTimeAndDateOneLine(point.arrivalPlanAt, point.showDate, point.showTimezone, point.timezoneOffset);
    }
  } else if ([TripPointStatus.current, TripPointStatus.passed].includes(tripPoint.status)) {
    icon = point.isDowntime || point.missDeliveryWindow ? 'passedMiss' : 'passed';

    if (checkin) {
      const outDate = checkin?.outDate || new Date();
      const duration = checkin.getDuration();

      text += parent.formatTimeAndDateOneLine(checkin.inDate, point.showDate, point.showTimezone, point.timezoneOffset);
      text +=
        ' – ' + parent.formatTimeAndDateOneLine(outDate, point.showDate, point.showTimezone, point.timezoneOffset);
      text += ' (' + parent.$duration(duration, 'duration') + ')';
    }

    if (tripPoint.status === TripPointStatus.current) {
      downText = parent.updatedAtText;
    }
  } else if (tripPoint.status === TripPointStatus.marked) {
    icon = 'passed';
    if (checkin) {
      text += parent.formatTimeAndDateOneLine(checkin.inDate, point.showDate, point.showTimezone, point.timezoneOffset);
    }
  } else if (
    [TripPointStatus.deleted, TripPointStatus.cancelled, TripPointStatus.waitingForDelete].includes(tripPoint.status)
  ) {
    icon = 'deleted';
    label = '';
  }

  if (tripPoint.stayTime) {
    if (text) {
      text += '<br/>';
    }
    text +=
      '<i aria-hidden="true" class="material-icons v-icon">timer</i> ' + parent.$duration(tripPoint.stayTime, 'short');
  }

  const iconData = getIconSettings(icon);

  const result = {
    icon: iconData,
    label: { text: label },
    secondLabel:
      text && secondLabelInfoMap
        ? {
            html: text,
            origin: iconData.secondLabelOrigin,
          }
        : undefined,
    downLabel: downText
      ? {
          html: downText,
          origin: iconData.downLabelOrigin,
        }
      : undefined,
    isCurrent,
    isNext: point.isNext,
    zindex: point.zindex,
  };

  return result;
}
