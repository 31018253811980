
import Vue from 'vue';
import Trip from '@/models/Trip';
import TripListMixin from './TripListMixin';

export default Vue.extend({
  name: 'TripListRow',

  mixins: [TripListMixin],

  props: {
    item: Trip,
  },
});
