import Vue from 'vue';
import Component from 'vue-class-component';
import { convertDateWithTimeZone } from '@/lib/date';
import { getLocalTimezoneInSeconds, getTimeZoneString } from '@/lib/timezone';
import Trip, { TripStatus } from '@/models/Trip';
import { Timezone } from '@/models/Location';

@Component
export default class TripListMixin extends Vue {
  item!: Trip;

  get activeStatuses(): string[] {
    return [
      TripStatus.notAssigned,
      TripStatus.planned,
      TripStatus.pending,
      TripStatus.pendingViewed,
      TripStatus.active,
    ];
  }

  details(item: Trip, event: Event) {
    this.$emit('details', item, event);
  }

  updateTripSettings() {
    this.$emit('updateTripSettings');
  }

  get containsDifferentTimezones(): boolean {
    return this.item.containsDifferentTimezones();
  }

  get containsDifferentDates(): boolean {
    return this.item.containsDifferentDates();
  }

  formatTimeAndDate(
    value: Date,
    showDate: boolean,
    containsDifferentTimezones: boolean | null,
    timeZone: Timezone | null,
    oneLine?: boolean,
  ): string {
    if (containsDifferentTimezones === null && timeZone) {
      containsDifferentTimezones = getLocalTimezoneInSeconds() !== timeZone.offset;
    }

    if (showDate) {
      if (oneLine) {
        return this.formatTimeWithTimezones(value, containsDifferentTimezones, timeZone, 'long');
      } else {
        const formattedTime = this.formatTimeWithTimezones(value, containsDifferentTimezones, timeZone, 'time');
        const formattedDate = this.formatDateWithTimezones(
          value,
          containsDifferentTimezones as boolean,
          timeZone,
          'short',
        );

        return formattedTime + '<br /><span class="cell-subheader">' + formattedDate + '</span>';
      }
    } else {
      return this.formatTimeWithTimezones(value, containsDifferentTimezones, timeZone, 'time');
    }
  }

  formatTimeAndDateWithTimezones(
    value: Date,
    showDate: boolean,
    containsDifferentTimezones: boolean,
    timezone: Timezone | null,
  ): string {
    return this.formatTimeAndDate(value, showDate, containsDifferentTimezones, timezone, true);
  }

  formatTimeWithTimezones(
    this: Vue,
    value: Date,
    containsDifferentTimezones: boolean | null,
    timeZone: Timezone | null,
    showOption: string,
  ): string {
    const $d = this.$d.bind(this);

    if (containsDifferentTimezones === null && timeZone) {
      containsDifferentTimezones = getLocalTimezoneInSeconds() !== timeZone.offset;
    }

    if (containsDifferentTimezones && timeZone) {
      return (
        $d(convertDateWithTimeZone(value, timeZone.offset) as any, showOption) + getTimeZoneString(timeZone.offset)
      );
    } else {
      if (typeof value === 'string') {
        value = new Date(value);
      }

      return $d(value, showOption);
    }
  }

  formatDateWithTimezones(
    this: Vue,
    value: Date,
    containsDifferentTimezones: boolean,
    timeZone: Timezone | null,
    showOption: string,
  ): string {
    const $d = this.$d.bind(this);

    if (containsDifferentTimezones && timeZone) {
      return $d(convertDateWithTimeZone(value, timeZone.offset) as any, showOption);
    } else {
      if (typeof value === 'string') {
        value = new Date(value);
      }

      return $d(value, showOption);
    }
  }
}
