import { createLocalVue, shallowMount, Wrapper, MountOptions } from '@vue/test-utils';
import { expect } from 'chai';
import sinon, { SinonStub } from 'sinon';

import TripDetails from '@/pages/TripDetails.vue';
import Trip from '@/models/Trip';
import tripApi from '@/api/tripNew';

describe('pages/TripDetails.vue', () => {
  type Instance = InstanceType<typeof TripDetails>;
  let mountFunction: (options?: MountOptions<Instance>) => Wrapper<Instance>;
  let tripApiStub: SinonStub;

  beforeEach(() => {
    mountFunction = (options?: MountOptions<Instance>) => {
      tripApiStub = sinon.stub(tripApi, 'get');

      const localVue = createLocalVue();

      return shallowMount(TripDetails, {
        localVue,
        propsData: {
          trip: new Trip(),
          items: [],
        },
        mocks: {
          $route: {
            params: {},
          },
          $store: {
            _modulesNamespaceMap: {
              'map/': {
                context: {
                  getters: {},
                  state: [{}],
                },
              },
              'company/': {
                context: {
                  getters: {},
                  state: [{}],
                },
              },
            },
            getters: {},
            state: [{}],
          },
          $vuetify: {
            lang: {
              current: 'en',
            },
            breakpoint: {},
            t: (val: string) => val,
          },
          $t: (val: string) => val,
          $d: (val: any) => val,
          $duration: (val: any) => val,
        },
        stubs: {
          'error-alert': true,
          'debug-only': true,
        },
        sync: false,
        ...options,
      });
    };
  });

  afterEach(() => {
    tripApiStub.restore();
  });

  it.skip('should request trip', () => {
    mountFunction();
    expect(tripApiStub.calledOnce).to.be.true;
  });
});
