<template>
  <div>
    <v-btn round @click.native.stop="openDialog" v-if="noSubscription" @click="updateCost">
      {{ $t('Go to subscription') }}
    </v-btn>
    <v-btn round @click.native.stop="openDialog" v-if="annualSubscription" @click="updateCost">
      {{ $t('Go to an annual subscription') }}
    </v-btn>
    <v-btn round @click.native.stop="openDialog" v-if="noProlongSubscription" @click="updateCost">
      {{ $t('Renew a subscription') }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="1100px" min-width="1100px">
      <v-card>
        <v-card-title class="headline" v-if="!annualSubscription">
          {{ $t('Subscription') }}
        </v-card-title>
        <v-card-title class="headline" v-else>
          {{ $t('Renew a subscription for one year') }}
        </v-card-title>
        <v-card-text>
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm5> {{ $t('Specify the number of accounts') }}: </v-flex>
            <v-flex xs12 sm6>
              <number-picker v-model="numSlots" :min="billing.activeAccounts" @input="changedNumSlotsOrMonths" />
            </v-flex>
            <v-flex xs11 text-xs-right mt-2 pr-3 caption>
              {{ $t('Number of accounts cannot be smaller than number of employees') }}
            </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left" v-if="!annualSubscription">
            <v-flex xs12 sm5> {{ $t('Select a subscription period') }}: </v-flex>
            <v-flex xs12 sm6>
              <v-select
                :items="numMonthListSelect"
                v-model="numMonth"
                menu-props="bottom"
                hide-details
                style="text-align: center"
                class="pr-3"
                @input="changedNumSlotsOrMonths"
              />
            </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left" v-if="annualSubscription">
            <v-flex xs12 sm5> {{ $t('Select a subscription period') }}: </v-flex>
            <v-flex xs12 sm6>
              <b>{{ $tc('{0} months', 12) }}</b>
            </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left" v-if="annualSubscription">
            <v-flex xs12 sm5> {{ $t('The savings are') }}: </v-flex>
            <v-flex xs12 sm6>
              <b>{{ $t('more than') }} 15%</b>
            </v-flex>
          </v-layout>
          <br /><v-divider /><br />
          <v-layout row wrap align-end v-if="dialog">
            <v-flex xs12 sm12>
              <payment-methods
                :invoice-url="invoiceUrl"
                :act-url="actUrl"
                :show-paid-action="showPaidAction"
                @createInvoice="createInvoice"
                @sendEmailInvoice="sendEmailInvoice"
                ref="paymentMethods"
                @changeAutoProlongationPaymentMethod="changeAutoProlongationPaymentMethod"
                :change-invoice="changeInvoice"
              />
            </v-flex>
          </v-layout>
          <br /><v-divider /><br />
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12>
              <b>{{ $t('Total for payment') }}: {{ $n(totalCost, 'currency', currency) }}</b>
            </v-flex>
          </v-layout>
          <br />
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12> {{ $t('Number of accounts') }}: {{ numSlots }} </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12> {{ $t('Tariff') }}: {{ userPlan(plan) }} </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12> {{ $t('Subscription period') }}: {{ $tc('{0} months', numMonth) }} </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12>
              {{ $t('Subscription fee per month') }}: {{ $n(costPerMonth, 'currency', currency) }}
            </v-flex>
          </v-layout>
          <v-layout row wrap align-end class="text-sm-left">
            <v-flex xs12 sm12>
              {{ $t('The cost is indicated for 1 account per month') }}:
              {{ $n(costPerSlotByMonth, 'currency', currency) }}
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions v-if="showPaid">
          <v-spacer />
          <v-btn color="green darken-1" flat="flat" :disabled="!canPay" @click="paySubscription">
            {{ $t('Pay') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NumberPicker from '@/components/pickers/NumberPicker';
import PaymentMethods from '@/components/billing/PaymentMethods';
import _ from 'lodash';
import api from '@/api';
import { mapState } from 'vuex';
import Common from '@/components/billing/Common';

export default {
  components: {
    NumberPicker,
    PaymentMethods,
  },
  mixins: [Common],
  props: {
    invoiceUrl: {
      type: String,
      default: '',
    },
    actUrl: {
      type: String,
      default: '',
    },
    annualSubscription: { type: Boolean },
  },
  data() {
    return {
      tabs: ['tab-1', 'tab-2', 'tab-3'],
      minSlots: 1,
      numSlots: 1,
      numMonthList: [1, 12],
      numMonth: this.annualSubscription ? 12 : 1,
      dialog: false,
      active: null,
      currency: '',
      totalCost: 0,
      plan: '',
      costPerMonth: 0,
      costPerSlotByMonth: 0,
      errorCards: null,
      showPaid: true,
      changeInvoice: false,
    };
  },
  computed: {
    ...mapState('payment', ['cardInfo']),
    ...mapState(['billing']),
    canPay() {
      return this.cardInfo.has_id;
    },
    numMonthListSelect() {
      return [
        {
          text: this.$tc('{0} months', 1),
          value: 1,
        },
        {
          text: this.$tc('{0} months', 12),
          value: 12,
        },
      ];
    },
    trialStatus() {
      return this.billing.status === 'TRIAL';
    },
    noSubscription() {
      return !this.annualSubscription && this.trialStatus;
    },
    noProlongSubscription() {
      return !this.annualSubscription && !this.trialStatus;
    },
  },
  methods: {
    updateCost: _.debounce(async function() {
      try {
        this.$emit('error', null);
        const data = await api.billing.calculate.subscriptionPlan(this.numSlots, this.numMonth);
        Object.assign(this, data);
      } catch (err) {
        this.$emit('error', err);
      }
    }, 500),
    paySubscription() {
      this.$emit('paySubscription', this.numSlots, this.numMonth);
      this.dialog = false;
    },
    sendEmailInvoice() {
      this.$emit('sendEmailInvoice');
    },
    createInvoice() {
      this.$emit('createInvoice', this.numSlots, this.numMonth, 'SUBSCRIPTION');
      this.changeInvoice = false;
    },
    showPaidAction(val) {
      this.showPaid = val === 'card';
    },
    changeAutoProlongationPaymentMethod(type, status) {
      this.$emit('changeAutoProlongationPaymentMethod', type, status);
    },
    changedNumSlotsOrMonths() {
      this.changeInvoice = true;
      this.updateCost();
    },
    openDialog() {
      this.dialog = true;
      this.showPaid = true;
    },
  },
};
</script>
