import { getCountriesList } from '@/i18n';
import api from '@/api/auth';

const getDefaultState = () => {
  return {
    id: null,
    job_title: null,
    full_name: null,
    initials: null,
    phone: null,
    last_name: null,
    first_name: null,
    email: null,
    country: null,
    language: null,
    agree_terms: null,
    role: null,
    firstTimeGuide: null,
    contacts: [],
    roleId: null,
  };
};

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setAccountData(state, data) {
      Object.keys(state).forEach(key => {
        if (data.hasOwnProperty(key)) {
          state[key] = data[key];
        }
      });
    },

    setJobTitle(state, data) {
      state.job_title = data;
    },

    setFullName(state, data) {
      state.full_name = data;
    },

    setPhone(state, data) {
      state.phone = data;
    },

    setLastName(state, data) {
      state.last_name = data;
    },

    setFirstName(state, data) {
      state.first_name = data;
    },

    setEmail(state, data) {
      state.email = data;
    },

    setCountry(state, data) {
      state.country = data;
    },

    setLanguage(state, data) {
      state.language = data;
    },

    setAgreeTerms(state, data) {
      state.agree_terms = data;
    },

    setFirstTimeGuide(state, data) {
      state.first_time_guide = data;
    },

    setContacts(state, data) {
      state.contacts = data;
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  getters: {
    findCountryInfo: state => {
      const countryList = getCountriesList();
      return countryList.find(item => item.alpha2 === state.country);
    },

    initials: state => {
      // TODO вообще может не надо инициалов во vuex
      let str = '';

      if (state.first_name && state.last_name) {
        str = `${state.first_name[0] + state.last_name[0]}`;
      }

      return str;
    },

    getCurrentAccountId: state => {
      return state.id;
    },

    canStartOnboarding: (state, getters, rootState) => {
      return rootState.auth.permissions['dashboard create link'];
    },
  },

  actions: {
    async finishFirstTimeGuide({ commit }) {
      await api.updateFirstTimeGuide(true);
      commit('setFirstTimeGuide', true);
      commit('site/finishFirstTimeGuide', null, { root: true });
    },
  },
};
