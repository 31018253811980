import { isEmpty } from 'lodash';
import Uuid from '@/models/Uuid';
import { PlaceDto } from '@/models/Place';
import { DeliveryWindows, DeliveryWindowsDto } from '@/models/TripPoint';
import { PointLocation, PointLocationDto } from '@/models/Location';
import { AccountDto } from '@/models/Account';
import { Contractor } from '@/models/Contractor';

export interface Tag {
  id: Uuid;
  foreignId: string;
  name: string;
  color: string;
  createdBy: Uuid;
  updatedBy: Uuid;
  updatedAt: Date;
  createdAt: Date;
}

export interface OrderDto {
  id: Uuid;
  number: string;
  paymentStatus: PaymentStatus;
  comment: string;
  cargos: Cargo[];
  demands?: DemandDto[];
  features: string[];
  restrictions: string[];
  executorRestrictions: string[];
  ownerId: string;
  owner: AccountDto;
}

export class Order {
  id: Uuid;
  number: string;
  paymentStatus: PaymentStatus;
  comment: string;
  cargos: Cargo[];
  demands: Demand[];
  features: string[];
  restrictions: string[];
  executorRestrictions: string[];
  ownerId: string;
  owner: AccountDto;

  constructor(order: OrderDto) {
    this.id = order.id;
    this.number = order.number;
    this.paymentStatus = order.paymentStatus;
    this.comment = order.comment;
    this.cargos = order.cargos;
    this.demands = order.demands ? order.demands.map(demandDto => new Demand(demandDto)) : [];
    this.features = order.features;
    this.restrictions = order.restrictions;
    this.executorRestrictions = order.executorRestrictions;
    this.ownerId = order.ownerId;
    this.owner = order.owner;
  }

  toJSON(): OrderDto {
    return {
      id: this.id,
      number: this.number,
      paymentStatus: this.paymentStatus,
      comment: this.comment,
      cargos: this.cargos,
      demands: this.demands.map(demand => demand.toJSON()),
      features: this.features,
      restrictions: this.restrictions,
      executorRestrictions: this.executorRestrictions,
      ownerId: this.ownerId,
      owner: this.owner,
    };
  }

  getOrderErrors(): string[] {
    const errors = [];

    if (!this.cargos.length) {
      errors.push('MissingCargo');
    }

    this.demands.map(demand => {
      demand.deliveryWindows.map(deliveryWindow => {
        if (isEmpty(deliveryWindow)) {
          errors.push('MissingDeliveryWindows');
        }
      });
    });

    return errors;
  }
}

export enum PaymentStatus {
  readyForPayment = 'readyForPayment',
  readyForCredit = 'readyForCredit',
  payedByCash = 'payedByCash',
  payedByCredit = 'payedByCredit',
  payedByCard = 'payedByCard',
  payedByBill = 'payedByBill',
  payed = 'payed',
  cancelled = 'cancelled',
  refund = 'refund',
  undefined = 'undefined',
}

export enum UnitType {
  pieces = 'pieces',
  kg = 'kg',
}

export enum DemandType {
  pickup = 'pickup',
  drop = 'drop',
  work = 'work',
  'null' = 'null',
}

export interface DemandDto {
  id: Uuid;
  cargos: Cargo[];
  orderId: string;
  type: DemandType;
  place: PlaceDto;
  location?: PointLocationDto;
  order: Order;
  deliveryWindows?: DeliveryWindowsDto[];
  duration: number;
  tagIds: string[] | null;
  contractor?: Contractor | null;
  targetCargos: string[] | null;
  precedenceInTrip?: number | null;
}

export class Demand {
  id: Uuid;
  cargos: Cargo[] = [];
  orderId: string;
  type: DemandType;
  place: PlaceDto;
  location: PointLocation;
  order: Order;
  duration: number;
  deliveryWindows: DeliveryWindows[] = [];
  tagIds: string[] | null;
  contractor?: Contractor | null;
  targetCargos: string[] | null;
  precedenceInTrip?: number | null;

  constructor(demand: DemandDto) {
    this.id = demand.id;
    this.cargos = demand.cargos;
    this.orderId = demand.orderId;
    this.type = demand.type;
    this.place = demand.place;
    this.location = demand.location as PointLocation;
    this.order = demand.order;
    this.duration = demand.duration;
    this.tagIds = demand.tagIds;
    this.contractor = demand.contractor;
    this.targetCargos = demand.targetCargos;
    this.deliveryWindows = demand.deliveryWindows
      ? demand.deliveryWindows.map(deliveryWindow => new DeliveryWindows(deliveryWindow))
      : [];
    this.precedenceInTrip = demand.precedenceInTrip;
  }

  toJSON(): DemandDto {
    return {
      id: this.id,
      cargos: this.cargos,
      orderId: this.orderId,
      type: this.type,
      place: this.place,
      location: this.location,
      order: this.order,
      duration: this.duration,
      tagIds: this.tagIds,
      contractor: this.contractor,
      targetCargos: this.targetCargos,
      deliveryWindows: this.deliveryWindows
        ? this.deliveryWindows.map(deliveryWindow => deliveryWindow.toJSON())
        : undefined,
      precedenceInTrip: this.precedenceInTrip,
    };
  }
}

interface Cargo {
  id: string;
  name: string;
  amount: number;
  price: number;
  width: number;
  height: number;
  length: number;
  unit: UnitType;
  restrictions: string[];
  capacity: {
    mass: number;
    volume: number;
  };
}
