
import Vue from 'vue';
import _ from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import rules from '@/api/rules';
import DateTimeRangeCompactPicker from '../pickers/timepickers/DateTimeRangeCompactPicker.vue';
import PointSettingsMixin from '@/components/trip/PointSettingsMixin';
import ContactsInfoForPointSettings from '@/components/ContactsInfoForPointSettings.vue';
import ContactListEditorNew from '@/components/inputs/ContactListEditorNew.vue';
import TimeInput from '@/components/inputs/TimeInput.vue';
import {
  DeliveryWindows,
  DeliveryWindowsDto,
  SmsNotification,
  TripPointDto,
  TripPointStatus,
  TripPointType,
} from '@/models/TripPoint';
import { Contact } from '@/models/Contact';
import { TripTableRow } from '@/services/TripPageService';
import { PointFunctions, StayTime } from '@/models/PointFunctions';
import TripPageMixin from './TripPageMixin';
import { TripStatus } from '@/models/Trip';
import { format } from 'date-fns';
import { PlaceLink } from '@/models/Place';
import { convertDateWithTimeZone } from '@/lib/date';
import { getLocalTimezoneInSeconds, getTimeZoneString } from '@/lib/timezone';

export default Vue.extend({
  name: 'PointSettings',

  components: {
    DateTimeRangeCompactPicker,
    ContactsInfoForPointSettings,
    ContactListEditorNew,
    TimeInput,
  },
  mixins: [PointSettingsMixin, TripPageMixin],

  props: {
    value: TripTableRow,
    tripStatus: String as () => TripStatus,
  },

  data() {
    return {
      showContactList: false,
      valid: true,
      disableWatch: false,
      pointFunctionsData: null as String | null | undefined,
      enableSmsNotification: true,
      contacts: [] as Contact[],
      internalValue: {
        functions: null as PointFunctions | null | undefined,
        smsNotification: null as SmsNotification | null | undefined,
        deliveryWindows: null as DeliveryWindows[] | null,
        plannedAt: null as Date | null,
        startedAt: null as Date | null,
        title: null as String | null,
        comment: null as String | null,
        contacts: [] as Contact[],
        placeLink: null as PlaceLink | null,
        stayTime: null as StayTime | null,
        arrivalPlanAt: null as Date | null,
        errorText: null as String | null,
      },
      enableSettings: {
        deliveryWindows: false,
        pointFunctions: false,
      },
    };
  },

  computed: {
    ...mapState('pointFunctions', ['pointFunctionsList']),
    ...mapGetters('auth', ['hasPermission']),

    canChangeStayTime(): boolean {
      return this.$store.getters['company/canChangeStayTime'];
    },

    isFinish(): Boolean {
      return this.value.tripPoint?.type === TripPointType.finish;
    },

    maxContactsCount(): any {
      return 3 + (this.value.tripPoint?.placeLink?.contacts?.length || 0);
    },

    phoneRules(): any {
      return rules.phone;
    },

    pointTypeEnum(): any {
      return TripPointType;
    },

    pointFunctionsSelected(): boolean {
      const selectedDroplist = this.value.tripPoint!.functions.droplist.find(el => el.selected);
      const selectedChecklist = this.value.tripPoint!.functions.checklist.find(el => el.selected);
      return Boolean(selectedChecklist || selectedDroplist);
    },

    timeZoneInString(): string {
      const timeZoneOffset = this.value?.tripPoint?.location.timezone.offset || 0;
      return getLocalTimezoneInSeconds() !== timeZoneOffset ? getTimeZoneString(timeZoneOffset) : '';
    },
  },

  async created(): Promise<void> {
    if (this.hasPermission('pointFunctions get')) {
      await this.updatePointFunctions();
    }

    this.updateInternalValue();
  },

  updated(): void {
    if (this.disableWatch) {
      this.disableWatch = false;
    }
  },

  methods: {
    ...mapActions('pointFunctions', ['updatePointFunctions']),

    updateInternalValue(): void {
      this.disableWatch = true;

      let outerTripPoint = this.value.tripPoint;

      this.internalValue.plannedAt = this.value.plannedAt;
      this.internalValue.startedAt = this.value.startedAt;

      if (outerTripPoint && outerTripPoint.functions) {
        this.internalValue.functions = outerTripPoint.functions;
        this.pointFunctionsData = outerTripPoint.functions.id;
      }

      if (!outerTripPoint || !outerTripPoint.smsNotification) {
        this.internalValue.smsNotification = null;
        this.enableSmsNotification = false;
      } else {
        this.internalValue.smsNotification = _.cloneDeep(outerTripPoint.smsNotification);
        this.enableSmsNotification = Boolean(outerTripPoint.smsNotification);
      }

      if (!outerTripPoint || !outerTripPoint.deliveryWindows) {
        this.internalValue.deliveryWindows = null;
      } else {
        this.internalValue.deliveryWindows = _.cloneDeep(outerTripPoint.deliveryWindows!);
      }

      if (!this.value || !this.value.arrivalPlanAt) {
        this.internalValue.arrivalPlanAt = null;
      } else {
        this.internalValue.arrivalPlanAt = this.value.arrivalPlanAt;
      }

      if (outerTripPoint) {
        if (outerTripPoint.title) {
          this.internalValue.title = outerTripPoint.title;
        }

        if (outerTripPoint.comment) {
          this.internalValue.comment = outerTripPoint.comment;
        }

        let contacts: Contact[] = [];

        if (outerTripPoint.contacts) {
          contacts = contacts.concat(outerTripPoint.contacts);
          this.internalValue.contacts = _.cloneDeep(contacts);
        }

        if (outerTripPoint.placeLink) {
          this.internalValue.placeLink = _.cloneDeep(outerTripPoint.placeLink);

          if (outerTripPoint.placeLink.contacts) {
            contacts = contacts.concat(outerTripPoint.placeLink.contacts);
          }
        }

        this.contacts = _.cloneDeep(contacts);

        if (outerTripPoint.stayTime) {
          this.internalValue.stayTime = outerTripPoint.stayTime;
        } else {
          this.internalValue.stayTime = null;
        }

        if (outerTripPoint.deliveryWindows) {
          this.internalValue.deliveryWindows = _.cloneDeep(outerTripPoint.deliveryWindows!);
          this.enableSettings.deliveryWindows = !(
            Array.isArray(this.internalValue.deliveryWindows) && this.internalValue.deliveryWindows.length === 0
          );
        } else {
          this.enableSettings.deliveryWindows = false;
          this.internalValue.deliveryWindows = null;
        }

        if (outerTripPoint.functions) {
          this.enableSettings.pointFunctions = true;
        }
      }
      // For the case where values have not changed - but we need to trigger
      // updated() to clear disableWatch flag
      this.$forceUpdate();
    },

    setPointFunctionsData(newData: any): any {
      this.disableWatch = true;

      if (!newData) {
        this.pointFunctionsData = null;
        this.internalValue.functions = null;
        return;
      }

      this.pointFunctionsData = newData.id;

      this.internalValue.functions = new PointFunctions({
        checklist: newData.functions.checklist,
        droplist: newData.functions.droplist,
        id: newData.id,
        name: newData.name,
      });
    },

    checkContent(): boolean {
      return this.checkDeliveryWindows();
    },

    checkDeliveryWindows(): boolean {
      const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');
      const tripDate = this.internalValue.startedAt || this.internalValue.plannedAt || new Date();
      const deliveryWindows = this.internalValue.deliveryWindows;

      if (Array.isArray(deliveryWindows)) {
        for (const deliveryWindow of deliveryWindows) {
          if (deliveryWindow.to < deliveryWindow.from) {
            this.internalValue.errorText = this.$t(
              'waypointPropertiesErrors.deliveryWindowFromEarlierThanTo',
            ).toString();

            return false;
          } else if (formatDate(deliveryWindow.from) < formatDate(tripDate)) {
            this.internalValue.errorText = this.$t(
              'waypointPropertiesErrors.deliveryWindowFromEarlierThanTripStart',
            ).toString();

            return false;
          }
        }
      }

      return true;
    },

    closeContactList(): void {
      this.checkContacts();
      this.showContactList = false;
    },

    checkContacts(): void {
      // add new contacts to trippoint, check if contact does not exist in trippoint and in tripopint placelink
      for (const updatedContact of this.contacts) {
        // check if contact exist in trippoint contacts
        let contactAlreadyExist = this.internalValue.contacts.find(contact => contact.id === updatedContact.id);

        if (!contactAlreadyExist) {
          // check if contact exist in trippoint's placelink contacts
          contactAlreadyExist = this.internalValue.placeLink?.contacts?.find(
            contact => contact.id === updatedContact.id,
          );

          if (!contactAlreadyExist) {
            this.internalValue.contacts.push(updatedContact);
          }
        }
      }

      // update trippoint contacts. if contact not appears in this.contacts, it was removed
      const updatedTripPointContacts: Contact[] = [];
      for (let oldPointContact of this.internalValue.contacts) {
        const updatedPointContact = this.contacts.find(contact => contact.id === oldPointContact.id);

        if (updatedPointContact) {
          updatedTripPointContacts.push(updatedPointContact);
        }
      }
      this.internalValue.contacts = updatedTripPointContacts;

      // update trippoint's place contacts. if contact not appears in this.contacts, it was removed
      if (this.internalValue.placeLink && this.internalValue.placeLink.contacts) {
        const updatedTripPointPlaceContacts: Contact[] = [];

        for (let oldPlacePointContact of this.internalValue.placeLink.contacts) {
          const updatedPointContact = this.contacts.find(contact => contact.id === oldPlacePointContact.id);

          if (updatedPointContact) {
            updatedTripPointPlaceContacts.push(updatedPointContact);
          }
        }

        this.internalValue.placeLink.contacts = updatedTripPointPlaceContacts;
      }
    },

    isChangeable(param: keyof TripPointDto): boolean {
      const tripPointStatus = this.value.tripPoint!.status;
      const tripPointType = this.value.tripPoint!.type;

      if (tripPointType === TripPointType.notScheduled) {
        return param === 'comment';
      }

      if (
        ![
          TripStatus.notAssigned,
          TripStatus.planned,
          TripStatus.pending,
          TripStatus.pendingViewed,
          TripStatus.active,
        ].includes(this.tripStatus)
      ) {
        return false;
      }

      if (
        [TripPointStatus.deleted, TripPointStatus.cancelled, TripPointStatus.waitingForDelete].includes(tripPointStatus)
      ) {
        return false;
      }

      if (param === 'functions') {
        return !this.pointFunctionsSelected;
      }

      if ([TripPointStatus.planned, TripPointStatus.active, TripPointStatus.waitingForAdd].includes(tripPointStatus)) {
        return true;
      } else {
        return !['smsNotification', 'stayTime'].includes(param); // if point is passed we should not change it
      }

      return false;
    },
  },

  watch: {
    enableSmsNotification() {
      if (this.disableWatch) {
        return;
      }

      if (this.enableSmsNotification) {
        this.$set(this.internalValue, 'smsNotification', {
          // this.internalValue.smsNotification = {
          ...PointSettingsMixin.computed.defaultSmsNotificationData(),
          text: '',
          contact: '',
          status: 'ready',
        });
      } else {
        this.internalValue.smsNotification = null;
      }
    },

    valid() {
      this.$emit('update:valid', this.valid);
    },

    value() {
      this.updateInternalValue();
    },

    internalValue: {
      deep: true,
      handler(value) {
        if (!value.deliveryWindows) {
          this.internalValue.deliveryWindows = [] as DeliveryWindows[];
        }
        this.$emit('input', this.internalValue);
      },
    },

    'enableSettings.deliveryWindows'(value) {
      if (this.disableWatch) {
        return;
      }

      if (!value) {
        this.internalValue.deliveryWindows = null;
      } else if (this.value.tripPoint?.deliveryWindows?.length) {
        this.internalValue.deliveryWindows = _.cloneDeep(this.value.tripPoint.deliveryWindows);
      } else {
        const offsetPoint = this.value?.tripPoint?.location.timezone.offset || 0;
        this.internalValue.deliveryWindows = PointSettingsMixin.computed.defaultDeliveryWindows(
          offsetPoint - getLocalTimezoneInSeconds(),
        ) as DeliveryWindows[];
      }
    },

    'enableSettings.pointFunctions'(value) {
      if (!value) {
        this.internalValue.functions = null;
        this.pointFunctionsData = null;
      }
    },
  },
});
