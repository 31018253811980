import Trip, { SummaryIndicators, TripMode } from '@/models/Trip';
import TripPoint, { TripPointDto, TripPointType } from '@/models/TripPoint';
import Uuid from '@/models/Uuid';
import { getPathLength } from 'geolib';
import WayPoint, { WayPointCheckin } from '@/models/WayPoint';
import { toTimestamp } from '@/lib/timestamp';
import { parseISO, addDays } from 'date-fns';
import TripChange from '@/models/TripChange';
import { isEqual } from 'lodash';
import uuid from 'uuid/v4';
import { ActiveRoute } from '@/models/Way';

export function getShortAddresses(tripPoints: TripPoint[]): Map<Uuid, string> {
  const checkComponents = [
    'country',
    'locality',
    'administrative_area_level_3',
    'administrative_area_level_2',
    'administrative_area_level_1',
  ];
  const mapShortAddresses = new Map<Uuid, string>();
  const mapTypesComponent = new Map<Uuid, string>();
  const mapAddressComponents = new Map<Uuid, string>();
  const pointHasAddressComponents: string[] = [];
  const sameTypes: any = {};

  tripPoints.forEach(point => {
    const { id } = point;

    point.location.addresses.forEach(addressItem => {
      if (addressItem.addressComponents) {
        if (!addressItem.address) {
          return;
        }

        mapShortAddresses.set(id, addressItem.address);

        if (!addressItem.addressComponents.length) {
          return;
        } else {
          pointHasAddressComponents.push(id);
        }

        addressItem.addressComponents.forEach(addressComponent => {
          const type = addressComponent.types.filter(t => t !== 'political')[0];
          const name =
            type === 'country'
              ? addressComponent.long_name === 'United States'
                ? 'USA'
                : addressComponent.long_name
              : addressComponent.short_name;

          mapAddressComponents.set(type, name);

          if (!sameTypes[name]) {
            sameTypes[name] = 1;
          } else {
            sameTypes[name] = ++sameTypes[name];
          }
        });

        checkComponents.forEach(type => {
          const nameType = mapAddressComponents.get(type);
          if (nameType) {
            mapTypesComponent.set(type, nameType);
          }
        });
      }
    });

    if (mapAddressComponents.has('postal_code')) {
      const replaceString = mapShortAddresses
        .get(id)
        ?.replace(new RegExp(`\\s*\\b${mapAddressComponents.get('postal_code')}\\b\\s*`), '');
      if (replaceString) {
        mapShortAddresses.set(id, replaceString);
      }
    }
  });

  Object.keys(sameTypes).forEach(name => {
    if (sameTypes[name] === pointHasAddressComponents.length) {
      tripPoints.forEach(point => {
        const { id } = point;
        // replaces only if addressComponents not empty in point
        if (pointHasAddressComponents.includes(id)) {
          mapTypesComponent.forEach(nameComponent => {
            if (typeof nameComponent === 'string') {
              const replaceString = mapShortAddresses.get(id)?.replace(new RegExp(`,\\s*${name}(,|$)`), '$1');
              if (replaceString) {
                mapShortAddresses.set(id, replaceString);
              }
            }
          });
        }
        const replaceString = mapShortAddresses.get(id)?.replace(/\s*,+(,|$)/, '$1');
        if (replaceString) {
          mapShortAddresses.set(id, replaceString);
        }
      });
    }
  });

  return mapShortAddresses;
}

interface WayPointWithCheckin {
  checkin: WayPointCheckin;
  wayPoint: WayPoint;
}

export function computeSummaryIndicators(trip: Trip): SummaryIndicators {
  let summaryIndicators: SummaryIndicators = {
    duration: null,
    scheduledCount: 0,
    downtimeCount: 0,
    totalAtClients: null,
    totalAtNonClients: null,
    totalInTransit: null,
    passedWayDistance: null,
    passedRawWayDistance: null,
    activeWayDistance: null,
    startTimezone: null,
    finishTimezone: null,
    stayTimeExceededCount: 0,
    workTimeExceededCount: 0,
    missedDeliveryWindowCount: 0,
    eventsCount: 0,
  };

  summaryIndicators.duration = trip.getDuration();
  if (trip.way.activeWay) {
    summaryIndicators.activeWayDistance = trip.way.activeWay.summary.length;
  }
  summaryIndicators.passedWayDistance = getPathLength(trip.way.passedWay);

  trip.tripPoints.forEach(tripPoint => {
    if (tripPoint.isScheduled() && (tripPoint.isActive() || tripPoint.isCurrent() || tripPoint.isPassed())) {
      summaryIndicators.scheduledCount = (summaryIndicators.scheduledCount as number) + 1;
    }
  });

  if (trip.isStarted()) {
    summaryIndicators.totalAtClients = 0;
    summaryIndicators.totalAtNonClients = 0;
    summaryIndicators.totalInTransit = 0;

    const tripPointsById = trip.getTripPointsById();
    const allWayPointsCheckins: WayPointWithCheckin[] = [];

    for (const wayPoint of trip.wayPoints) {
      for (const checkin of wayPoint.checkins) {
        if (!checkin.isMarked()) {
          allWayPointsCheckins.push({ wayPoint, checkin });
        }
      }
    }

    allWayPointsCheckins.sort((a, b) => a.checkin.compareTo(b.checkin));

    let prevCheckin: WayPointCheckin | null = null;

    for (const { wayPoint, checkin } of allWayPointsCheckins) {
      const timeInTransit = prevCheckin ? checkin.getTransitFrom(prevCheckin) : null;
      summaryIndicators.totalInTransit =
        (summaryIndicators.totalInTransit as number) + (timeInTransit ? Math.abs(timeInTransit) : 0);
      prevCheckin = checkin;

      const tripPoints = wayPoint.tripPointIds
        .map(id => tripPointsById.get(id))
        .filter(p => p !== undefined) as TripPoint[];
      const isScheduled = tripPoints.every(tripPoint => tripPoint.isScheduled());
      const isNotScheduled = tripPoints.every(tripPoint => tripPoint.isNotScheduled());
      const duration = checkin.getDuration();

      if (isScheduled && duration) {
        summaryIndicators.totalAtClients = (summaryIndicators.totalAtClients as number) + duration;
      } else if (isNotScheduled && duration) {
        summaryIndicators.totalAtNonClients = (summaryIndicators.totalAtNonClients as number) + duration;
      }
    }
  }

  return summaryIndicators;
}

/**
 * Метод для расчета опозданий
 */
export const calculateMissDeliveryWindows = (
  tripPoints: TripPoint[],
  pointsETA: Map<Uuid, Date>,
  plannedAt: Date | null = null,
): Map<Uuid, boolean> => {
  const mapMissDeliveryWindows = new Map();
  const referenceTs = plannedAt?.getTime() || Date.now();

  tripPoints.forEach(tripPoint => {
    const arrivalPlanAt = pointsETA.get(tripPoint.id);
    const checkins = Array.isArray(tripPoint.checkins) ? tripPoint.checkins : [];

    if ((arrivalPlanAt || checkins) && tripPoint.deliveryWindows) {
      let arrivalTimestamps: Number[];
      if (arrivalPlanAt != null) {
        arrivalTimestamps = [arrivalPlanAt.getTime()];
      } else {
        arrivalTimestamps = checkins.map(checkin => (checkin.inDate ? checkin.inDate.getTime() : 0));
      }

      tripPoint.deliveryWindows.forEach(({ from, to }) => {
        const fromTs = toTimestamp(from, null, referenceTs);
        let toTs = toTimestamp(to, null, referenceTs);

        if (fromTs > toTs) {
          toTs += 86400000; // add one day
        }

        const result = !arrivalTimestamps.some(arrivalTs => arrivalTs > 0 && arrivalTs <= toTs && arrivalTs >= fromTs);

        mapMissDeliveryWindows.set(tripPoint.id, result);
      });
    }
  });

  return mapMissDeliveryWindows;
};

/**
 * Метод для перестановки tripPoint'ов в нужном порядке в соответствии с путем
 */
export function reorderPointsByWay(trip: Trip, activeWay: ActiveRoute): TripPoint[] {
  const placeholder = '__placeholder';
  let newTripPoints: (TripPoint | string)[] = [...trip.tripPoints];

  const tripPointIndexById = new Map<Uuid, number>();
  trip.tripPoints.forEach((tripPoint, index) => {
    tripPointIndexById.set(tripPoint.id, index);
  });

  const newTripPointsOrder = [];

  if (activeWay.summary.startTripPointId) {
    const idx = tripPointIndexById.get(activeWay.summary.startTripPointId);

    if (idx !== undefined) {
      newTripPointsOrder.push(trip.tripPoints[idx]);
      newTripPoints[idx] = placeholder;
    }
  }

  for (const leg of activeWay.legs) {
    const idx = tripPointIndexById.get(leg.toTripPointId);

    if (idx !== undefined) {
      newTripPointsOrder.push(trip.tripPoints[idx]);
      newTripPoints[idx] = placeholder;
    }
  }

  let j = 0;
  for (let i = 0; i < newTripPoints.length; i++) {
    if (newTripPoints[i] === placeholder) {
      newTripPoints[i] = newTripPointsOrder[j++];
    }
  }

  return newTripPoints.filter(p => typeof p !== 'string') as TripPoint[];
}
