import http from './httpV2';

export interface ValidationErrorInfo {
  index: number;
}

interface ImportResponse {
  errors?: ValidationErrorInfo[];
}

async function importAccounts(file: File): Promise<ImportResponse> {
  const data = new FormData();
  data.append('file', file, file.name);

  const res = await http.post<ImportResponse>(`/accounts/import`, data);
  return res.data;
}

async function importOrders(file: File): Promise<ImportResponse> {
  const data = new FormData();
  data.append('file', file, file.name);

  const res = await http.post<ImportResponse>(`/orders/import`, data);
  return res.data;
}

async function importTransports(file: File): Promise<ImportResponse> {
  const data = new FormData();
  data.append('file', file, file.name);

  const res = await http.post<ImportResponse>(`/transports/import`, data);
  return res.data;
}

export { importAccounts, importOrders, importTransports };
