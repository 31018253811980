<template>
  <v-layout column :style="styles" class="chat-container elevation-2">
    <MessageList
      @close="close"
      :messages="comments"
      :participants="[]"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="styling"
      :disabled="disabled"
      @deleteComment="deleteComment"
    />

    <UserInput
      :on-submit="onUserInputSubmit"
      :placeholder="placeholder"
      :colors="colors"
      :disabled="disabled"
      message-type="comment"
    />
  </v-layout>
</template>

<script>
import Vue from 'vue';

import MessageList from '../../../vendor/vue-beautiful-chat/MessageList';
import UserInput from '../../../vendor/vue-beautiful-chat/UserInput';

import commentApi from '@/api/tripComment';

const FOOTER_HEIGHT = 75;

export default Vue.extend({
  name: 'TripComments',

  components: { MessageList, UserInput },

  props: {
    tripId: {
      type: Number,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      comments: [],
      colors: {
        messageList: {
          bg: '#ffffff',
        },
        commentMessage: {
          bg: '#e8e8e8',
          text: '#222222',
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867',
        },
      },
      alwaysScrollToBottom: true,
      styling: true,
    };
  },

  async created() {
    await this.fetchData();
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);

      this.timer = null;
    }
  },

  computed: {
    _paddingLeft() {
      return this.$vuetify.application.left;
    },

    _paddingTop() {
      return this.$vuetify.application.top;
    },

    _width() {
      if (this.hide) {
        return '0px';
      }

      return !this.hide && this.full ? '100%' : `calc((100vw - ${this._paddingLeft + 8}px)*0.416666 - 24px)`;
    },

    _height() {
      let height;

      if (this.$vuetify.breakpoint.smAndDown) {
        height = `calc(100% - ${+this.$root.$children[0].footerHeight}px - 50px)`;
      } else {
        height = this.height ? this.height + 'px' : `calc(100% - ${this._paddingTop + FOOTER_HEIGHT + 48 - 1}px)`;
      }

      return height;
    },

    styles() {
      return {
        position: 'fixed',
        width: this._width,
        height: this._height,
        top: '0',
        marginTop: `${this._paddingTop + 24}px`,
        marginRight: '24px',
        marginBottom: '24px',
        overflow: 'hidden',
        zIndex: 2,
      };
    },

    placeholder() {
      return this.$t('Enter comment');
    },
  },

  methods: {
    async fetchData() {
      const comments = await commentApi.getTripComments(this.tripId);

      if (comments.length) {
        this.comments = comments.map(comment => ({
          type: 'comment',
          data: {
            id: comment.external_id,
            text: comment.text,
          },
        }));

        this.$emit('update', { count: comments.length });
      }
    },

    async onUserInputSubmit(comment) {
      const data = {
        tripId: this.tripId,
        id: comment.data.id,
        text: comment.data.text,
      };

      await commentApi.sendCommentToTrip(data);

      this.comments = [...this.comments, comment];

      this.$emit('onCommentAdd', comment);
    },

    async deleteComment(commentId) {
      if (commentId) {
        await commentApi.removeCommentFromTrip(this.tripId, commentId);

        this.comments = this.comments.filter(comment => comment.data.id !== commentId);

        this.$emit('onCommentRemove', commentId);
      }
    },

    close() {
      this.$emit('close');
    },
  },
});
</script>

<style scoped>
@media (max-width: 960px) {
  .chat-container {
    position: fixed !important;
    margin: 0 !important;
    top: 50px !important;
    left: 0;
    width: 100% !important;
  }
}
</style>
