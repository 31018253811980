
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';

import { isEqual } from 'lodash';

import Uuid from '@/models/Uuid';
import { baseUrl, Params } from '@/api/httpV2';
import { AUTORELOAD_TIME } from '@/const';
import {
  getDistributionList,
  deleteDistribution,
  GetDistributionListResponse,
  deleteDistributions,
} from '@/api/distributions';
import { Distribution } from '@/models/Distribution';
import { FieldsOptions } from '@/components/grid/Options';
import DistributionsFilter from '@/components/filters/DistributionsFilter.vue';
import DistributionsFilterDto from '@/components/filters/DistributionsFilterDto';
import { GridTh, GridSelectAll } from '@/components/grid/Grid';
import GridCrud from '@/components/grid/GridCrud';
import TraceCodeDialog from '@/components/modals/TraceCodeDialog.vue';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import CancelDialog from '@/components/modals/CancelDialog.vue';
import RowProgress from '@/components/RowProgress.vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import TripsFilterDto from '@/components/filters/TripsFilterDto';

export default Vue.extend({
  name: 'DistributionList',

  components: {
    DistributionsFilter,
    GridTh,
    GridSelectAll,
    GridCrud,
    TraceCodeDialog,
    DeleteDialog,
    CancelDialog,
    RowProgress,
    Checkbox,
  },

  data() {
    return {
      filter: new DistributionsFilterDto(),
      showFilter: false as boolean,
      timer: null as number | null,
      cancelDialog: false as boolean,
      deleteDialog: false as boolean,
      deleteError: null as Error | null,
      deleteItem: null as Uuid | null,
      traceCode: null as string | null,
      pagination: {} as {
        page: number | null;
        rowsPerPage: number | null;
        sortBy: string;
        descending: boolean | null;
      },

      selected: [] as Uuid[],
      multiDeleteDialog: false,
      multiDeleteError: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),

    isDevelopment(): boolean {
      return process.env.NODE_ENV === 'development';
    },

    fields(): FieldsOptions {
      return {
        createdAt: {
          label: 'Date',
          align: 'center',
          sortable: true,
          defaultSort: -1,
          style: {
            width: '130px',
          },
        },
        owner: {
          label: 'Owner',
          align: 'center',
          sortable: false,
          style: {
            maxWidth: '150px',
          },
        },
        name: {
          label: 'DistributionName',
          align: 'center',
          sortable: true,
          style: {
            maxWidth: '150px',
          },
        },
        ordersCount: {
          label: 'TotalOrdersCount',
          align: 'center',
          sortable: false,
          style: {
            maxWidth: '150px',
          },
        },
        configuration: {
          label: 'Configuration',
          align: 'center',
          sortable: false,
        },
        tripsCount: {
          label: 'TripsCount',
          align: 'center',
          sortable: false,
          style: {
            maxWidth: '150px',
          },
        },
        unplannedOrdersCount: {
          label: 'Unplanned orders',
          align: 'center',
          sortable: false,
          style: {
            maxWidth: '150px',
          },
        },
        validationsCount: {
          label: 'ErrorsCount',
          align: 'center',
          sortable: false,
          style: {
            maxWidth: '150px',
          },
        },
        status: {
          label: 'Status',
          align: 'center',
          sortable: true,
        },
        actions: {
          sortable: false,
        },
      };
    },
  },

  created(): void {
    this.parseURL();

    this.timer = setInterval(() => {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    }, AUTORELOAD_TIME);
  },

  beforeDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },

  methods: {
    filterDistributions() {
      if (typeof localStorage !== 'undefined') {
        const filter = localStorage.getItem('filterDistributions') || '{}';
        return JSON.parse(filter);
      }
    },

    parseURL() {
      if (Object.keys(this.$route.query).length) {
        this.filterUpdate(new DistributionsFilterDto().fromQuery(this.$route.query));
      } else if (![null, undefined, ''].includes(this.filterDistributions())) {
        this.filter = new DistributionsFilterDto(this.filterDistributions());
      }

      this.showFullFilter(this.filter);

      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page as string) + 1;
      }

      if (this.$route.query.pageSize) {
        this.pagination.rowsPerPage = parseInt(this.$route.query.pageSize as string);
      }

      if (this.$route.query.sort) {
        this.pagination.sortBy = String(this.$route.query.sort);
      }

      if (this.$route.query.sortDesc) {
        this.pagination.descending = this.$route.query.sortDesc === '1';
      }
    },

    filterUpdate(value: DistributionsFilterDto) {
      this.filter = value;
      localStorage.setItem('filterDistributions', JSON.stringify(value));
    },

    reloadClick() {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    },

    showFullFilter(query: any) {
      this.showFilter = Boolean(
        query.configurations?.length || query.owners?.length || query?.statuses?.length || query.unplannedOrders,
      );
    },

    makeQuery() {
      return {
        ...(this.$refs.grid as Vue & { makeQuery: () => any }).makeQuery(),
        ...this.filter.toQueryForCheck(),
      };
    },

    async showTraceCodesDialog(traceCode: string): Promise<void> {
      this.traceCode = traceCode;
    },

    apiFunction(opts: Params): Promise<GetDistributionListResponse> {
      return getDistributionList(opts);
    },

    getJsonLink(id: string, type: string, deanonymize?: boolean): string {
      return baseUrl + '/distributions/' + id + '/json?type=' + type + (deanonymize ? '&deanonymize=true' : '');
    },

    exportRequestLink(id: string): string {
      return baseUrl + '/distributions/' + id + '/exportRequest?locale=' + this.$i18n.locale;
    },

    details(distribution: Distribution, event: MouseEvent): void {
      const url = this.$route.path + '/' + distribution.id;

      if (event && (event.metaKey || event.ctrlKey || event.shiftKey)) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },

    async deleteDistribution({ id, status }: { id: Uuid; status: string }): Promise<void> {
      if (['waiting', 'inProgress'].includes(status)) {
        this.cancelDialog = true;
      } else {
        this.deleteDialog = true;
      }

      this.deleteError = null;
      this.deleteItem = id;
    },

    async handleDelete(): Promise<void> {
      try {
        if (this.deleteItem) {
          await deleteDistribution(this.deleteItem);
          (this.$refs.grid as Vue & { refresh: () => void }).refresh();
        }

        this.deleteDialog = false;
        this.cancelDialog = false;
      } catch (err) {
        this.deleteError = err;
      }
    },

    async multiHandleDelete() {
      try {
        if (this.selected.length) {
          await deleteDistributions(this.selected);
        }

        (this.$refs.grid as Vue & { refresh: () => void }).refresh();

        this.multiDeleteDialog = false;
        this.selected = [];
      } catch (err) {
        this.multiDeleteError = err;
      }
    },

    showMultiDeleteDialog() {
      this.multiDeleteError = null;
      this.multiDeleteDialog = true;
    },
  },

  watch: {
    filter: {
      handler() {
        if (!isEqual(this.$route.query, this.makeQuery())) {
          this.$router.replace({ name: 'distributionList', query: this.makeQuery() });
        }
      },
      deep: true,
    },

    pagination() {
      if (!isEqual(this.$route.query, this.makeQuery())) {
        this.$router.replace({ name: 'distributionList', query: this.makeQuery() });
      }
    },
  },
});
