import Vue from 'vue';
import Component from 'vue-class-component';
import gmap from '@/lib/gmap';

@Component({
  provide: function() {
    const { getMap } = (this as unknown) as { getMap: { (): Promise<google.maps.Map> } };
    return {
      getMap,
    };
  },
})
export default class MapInitMixin extends Vue {
  map = null as google.maps.Map | null;
  mapLoaded = false;
  mapOptions!: google.maps.MapOptions;

  async initMap(): Promise<any> {
    await gmap.waitInit();

    const el = document.getElementById('map');
    if (!el) {
      console.error('Cannot find map element');
      return;
    }

    this.map = new google.maps.Map(el, this.mapOptions);

    google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
      this.mapLoaded = true;
      this.$emit('loaded');
    });

    google.maps.event.addListener(this.map, 'bounds_changed', () => {
      if (this.mapLoaded) {
        this.$emit('boundsChanged');
      }
    });
  }

  async getMap() {
    await gmap.waitInit();

    let _resolve: { (arg0?: google.maps.Map): void };

    const promise = new Promise<google.maps.Map>(resolve => {
      _resolve = resolve;
    });

    const checkForMap = () => {
      this.map && this.mapLoaded ? _resolve(this.map) : setTimeout(checkForMap, 50);
    };

    checkForMap();

    return promise;
  }
}
