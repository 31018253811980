// @ts-nocheck
import Vue, { CreateElement } from 'vue';
import Component, { mixins } from 'vue-class-component';

import MapUpdateLinesMixin from '@/components/map/MapUpdateLinesMixin';
import MapUpdateMarkersMixin from '@/components/map/MapUpdateMarkersMixin';
import MapUpdateCurrentPositionMixin from '@/components/map/MapUpdateCurrentPositionMixin';
import { MapPointData, MapGroupData } from '@/services/TripPageService';
import Trip from '@/models/Trip';
import UpdatedAtTextMixin from './UpdatedAtTextMixin';
import { cutActiveWayByPoint } from '@/services/routeService';
import Uuid from '@/models/Uuid';

interface IPointMarker {
  point: MapPointData;
}

const props = Vue.extend({
  mixins: [UpdatedAtTextMixin],

  props: {
    trip: {
      type: Trip,
    },

    points: {
      type: Array,
      default: () => [],
    },

    groups: {
      type: Array,
      default: () => [],
    },

    filter: {
      type: Object,
    },

    secondLabelInfoMap: {
      type: Boolean,
      default: true,
    },
  },
});

@Component({
  provide: function() {
    const { getMap } = (this as unknown) as { getMap: { (): Promise<google.maps.Map> } };
    return {
      getMap,
    };
  },

  inject: ['getMap'],

  watch: {
    trip: {
      deep: true,
      handler: async function(newValue, oldValue) {
        await (this as any).onTripUpdate(newValue, oldValue);
      },
    },
  },
})
export default class TripRoute extends mixins(
  props,
  MapUpdateMarkersMixin,
  MapUpdateLinesMixin,
  MapUpdateCurrentPositionMixin,
) {
  trip!: Trip;
  points!: MapPointData[];
  groups!: MapGroupData[];

  async created() {
    await this.updateMarkers();
    await this.updateCurrentPositionMarker();
    await this.updateLines();
    await this.updateBounds();

    this.$emit('loaded');
  }

  render(h: CreateElement) {
    return h();
  }

  beforeDestroy() {
    this.clearAll(this.trip.id);
  }

  markerClick(marker: IPointMarker) {
    this.$store.commit('map/toggleSelection', { marker: marker, point: marker.point });
  }

  clearAll(tripId: Uuid) {
    this.clearMarkers();
    this.clearCurrentPositionMarker();
    this.clearAllLines();

    this.$store.commit('map/clearBounds', tripId);
  }

  async onTripUpdate(newValue?: Trip, oldValue?: Trip) {
    if (newValue && oldValue && newValue.id !== oldValue.id) {
      this.clearAll(oldValue.id);
    }

    await this.updateMarkers();
    await this.updateCurrentPositionMarker();
    await this.updateLines();
  }

  setMapPosition() {
    this.$emit('setMapPosition');
  }

  updateBounds() {
    const bounds = new google.maps.LatLngBounds();

    for (const point of this.points) {
      bounds.extend(point.tripPoint.location.coordinates);
    }

    const currentPosition = this.trip.getCurrentPosition();
    if (currentPosition) {
      bounds.extend(currentPosition);
    }

    this.$store.commit('map/setBounds', { id: this.trip.id, bounds: bounds.toJSON() });
  }

  get activeWay() {
    const currentPosition = this.trip.getCurrentPosition();
    if (currentPosition && this.trip.way.activeWay) {
      return cutActiveWayByPoint(currentPosition, this.trip.way.activeWay);
    }
    return this.trip.way.activeWay;
  }

  get passedWay() {
    return this.trip.way.passedWay;
  }

  get currentPosition() {
    return this.trip.getCurrentPosition();
  }
}
