
import Vue from 'vue';
import { format, isSameDay } from 'date-fns';

import DateRange from '@/components/DateRange.vue';

export default Vue.extend({
  name: 'DateRangeDialog',

  components: {
    DateRange,
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      }),
    },
    minDate: Date,
  },

  data() {
    return {
      showDialog: true,
      internalValue: {
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      },
      options: {
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        startTime: '00:00',
        endTime: '23:59',
        minDate: format(new Date(), 'yyyy-MM-dd'),
        minTime: '00:00',
      },
    };
  },

  created() {
    if (this.value && this.value.from && this.value.to) {
      this.options.startDate = format(new Date(this.value.from), 'yyyy-MM-dd');
      this.options.endDate = format(new Date(this.value.to), 'yyyy-MM-dd');
      this.options.startTime = format(new Date(this.value.from), 'HH:mm');
      this.options.endTime = format(new Date(this.value.to), 'HH:mm');

      this.internalValue = this.value;
    }

    if (this.minDate) {
      this.options.minDate = format(new Date(this.minDate), 'yyyy-MM-dd');
      this.options.minTime = format(new Date(this.minDate), 'HH:mm');
    }
  },

  methods: {
    save() {
      this.showDialog = false;
      this.$emit('save', this.internalValue);
    },

    cancel() {
      this.showDialog = false;
      this.internalValue = this.value;
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
