import { render, staticRenderFns } from "./DistributionCreate.vue?vue&type=template&id=956c97ca"
import script from "./DistributionCreate.vue?vue&type=script&lang=ts"
export * from "./DistributionCreate.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports