import http from './httpV2';
import { FeatureCollection } from 'geojson';

const path = '/zones';

async function getZones(): Promise<FeatureCollection> {
  const res = await http.get<FeatureCollection>(path);
  return res.data;
}

async function putZones(data: FeatureCollection): Promise<never> {
  const res = await http.put<never>(path, data);
  return res.data;
}

export default {
  getZones,
  putZones,
};
