// @ts-ignore
import { setCSRFToken } from './http';
import http from './httpV2';
import cookie from '@/lib/cookie';
import store from '@/store';
import billing from './billing';

interface VerifyListParams {
  login?: string;
  password?: string;
  domain?: string;
  companyId?: string;
  isDebug?: boolean;
}

async function verify(params: VerifyListParams) {
  const res = await http.post(params.isDebug ? '/auth/login/debug' : '/auth/login', {
    login: params.login,
    password: params.password,
    domain: params.domain,
    companyId: params.companyId,
  });

  let csrfToken;
  if ((csrfToken = cookie.get('csrf-token'))) {
    setCSRFToken(csrfToken);
  }

  await profile();

  store.commit('auth/setLoggedIn', true);

  return res.data;
}

async function reset(code: any, password: any) {
  const res = await http.post(`/restore/${code}`, {
    password,
  });

  let csrfToken;
  if ((csrfToken = cookie.get('csrf-token'))) {
    setCSRFToken(csrfToken);
  }

  await profile();
  store.commit('auth/setLoggedIn', true);
  return res.data;
}

async function logout() {
  const res = await http.post('/auth/logout');

  store.commit('auth/resetState');
  store.commit('account/resetState');

  return res.data;
}

async function init() {
  if (store.state.auth.initialized) {
    return;
  }

  let csrfToken;
  if ((csrfToken = cookie.get('csrf-token'))) {
    setCSRFToken(csrfToken);
  }

  try {
    await profile();
    store.commit('auth/setLoggedIn', true);
  } catch (error) {
    store.commit('auth/setLoggedIn', false);
  }
  store.commit('auth/setInitialized', true);
}

async function updateToken(token: any) {
  return http.post(
    '/auth/token/refresh',
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );
}

async function profile() {
  const res = await http.get('/profile');

  store.commit('company/setCompanyData', res.data.company);
  store.commit('auth/setPermissions', res.data.permissions);
  store.commit('auth/setAccountId', res.data.id);

  const accountData = { ...res.data };
  delete accountData.company;
  delete accountData.permissions;

  if (accountData.basicRole !== 'user') {
    try {
      await billing.info.infoGetMain();
    } catch (e) {}
  }
  store.commit('account/setAccountData', accountData);

  return res.data;
}

async function updateProfile(data: any) {
  const res = await http.put('/profile', data);
  store.commit('account/setAccountData', res.data);
  return res.data;
}

async function updateFirstTimeGuide(value: any) {
  const res = await http.put('/profile', {
    first_time_guide: value,
  });
  return res.data;
}

async function updatePassword(data: any) {
  const res = await http.post('/profile/password', data);
  return res.data;
}

async function updatePhone(data: any) {
  const res = await http.post('/profile/phone', data);
  return res.data;
}

async function requestPhoneCode(data: any) {
  const res = await http.post('/profile/phone/request', data);
  return res.data;
}

async function resetAuthCode() {
  const res = await http.post('/profile/reset_app');
  return res.data;
}

export default {
  verify,
  reset,
  logout,
  init,
  updateToken,
  profile,
  updateProfile,
  updatePassword,
  updatePhone,
  updateFirstTimeGuide,
  requestPhoneCode,
  resetAuthCode,
};
