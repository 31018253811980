import Vue from 'vue';
import { TripTableRow } from '@/services/TripPageService';
import { TripPointType, TripPointStatus } from '@/models/TripPoint';
import TimeWithOptionalDay from '../../TimeWithOptionalDay';
import Trip from '@/models/Trip';

export default Vue.extend({
  functional: true,

  name: 'CheckoutAt',

  props: {
    item: TripTableRow,
    trip: Trip,
  },

  render(h, { props, parent }) {
    const { item, trip } = props;
    const { tripPoint, checkin, timezoneOffset, showDate } = item;

    if (
      checkin &&
      ((tripPoint && ![TripPointType.finish, TripPointType.actualFinish].includes(tripPoint.type)) || !tripPoint)
    ) {
      return h(TimeWithOptionalDay, {
        props: {
          value: checkin.outDate,
          showDate,
          status: trip.tripMode !== 'manual' && item.status !== 'marked' ? item.status : '',
          showTimezones: trip.containsDifferentTimezones(),
          timezoneOffset,
        },
      });
    }

    return h();
  },
});
