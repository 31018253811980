import { MarkerIconOptions } from './primitives/MapMarker';

const config = {
  color: {
    active: '#3989EC',
    passed: '#A3CDFF',
    border: '#3B5D87',
    dirty: '#CCCCCC',
    activeFont: '#fff',
    passedFont: '#000',
  },
  lineColors: [
    '#3989EC',
    '#8BC34A',
    '#F44336',
    '#CDDC39',
    '#E91E63',
    '#FFEB3B',
    '#9C27B0',
    '#FFC107',
    '#673AB7',
    '#FF9800',
    '#3F51B5',
    '#FF5722',
    '#2196F3',
    '#795548',
    '#607D8B',
    '#009688',
  ],
  icons: {
    standard: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless2.png',
    currentPosition: '/static/img/current_position.png',
    deleted: '/static/img/poi_deleted.png',
    current: '/static/img/poi_current.png',
    passed: '/static/img/poi_passed.png',
    marked: '/static/img/poi_passed.png',
    active: '/static/img/poi_active.png',
    activeMiss: '/static/img/poi_active_miss.png',
    passedMiss: '/static/img/poi_passed_miss.png',
    currentMiss: '/static/img/poi_current_miss.png',
    stop: '/static/img/poi_stop.png',
    stopCurrent: '/static/img/poi_stop_current.png',
    start: '/static/img/start.png',
    startPassed: '/static/img/start_passed.png',
    finish: '/static/img/finish.png',
    finishActual: '/static/img/finish_actual.png',
    startFinish: '/static/img/start_finish.png',
    startPassedFinish: '/static/img/start_passed_finish.png',
    startPassedFinishActual: '/static/img/start_passed_finish_actual.png',
  },
  iconData: {
    standard: {
      scaledSize: [30, 46],
      anchor: [15, 46],
      // anchor: [13, 41],
      labelOrigin: [13, 15],
    },
    start: {
      scaledSize: [28, 28],
      anchor: [14, 14],
      secondLabelOrigin: [14, -3],
      downLabelOrigin: [14, 33],
    },
    poi: {
      scaledSize: [78, 104],
      anchor: [39, 84],
      labelOrigin: [39, 30],
      secondLabelOrigin: [39, -3],
      downLabelOrigin: [39, 100],
    },
    stop: {
      scaledSize: [43, 57],
      anchor: [22, 27],
      secondLabelOrigin: [22, 8],
      downLabelOrigin: [22, 46],
    },
    finish: {
      scaledSize: [52, 67],
      anchor: [22, 66],
      secondLabelOrigin: [27, -5],
    },
    multiPoi: {
      anchor: [0, 84],
      secondLabelOrigin: [0, -3],
      downLabelOrigin: [0, 100],
    },
    multiStop: {
      anchor: [0, 27],
      secondLabelOrigin: [0, 8],
      downLabelOrigin: [0, 46],
    },
    startFinish: {
      anchor: [0, 67],
      secondLabelOrigin: [5, -4],
      downLabelOrigin: [0, 86],
    },
  },
  defaultCoordinate: {
    lat: 50.102223,
    lng: 9.254419,
  },
};

export type MarkerIconName = keyof typeof config.icons | 'multiPoi';
type StandardMarkerIconName = keyof typeof config.iconData;

function getIconSettings(icon: MarkerIconName): MarkerIconOptions {
  let stdIcon: StandardMarkerIconName;
  if (icon in config.iconData) {
    stdIcon = icon as StandardMarkerIconName;
  } else if (icon.startsWith('start')) {
    stdIcon = 'start';
  } else if (icon.startsWith('stop')) {
    stdIcon = 'stop';
  } else if (icon.startsWith('finish')) {
    stdIcon = 'finish';
  } else {
    stdIcon = 'poi';
  }
  const data = config.iconData[stdIcon];

  const iconData: MarkerIconOptions = {
    anchor: new google.maps.Point(data.anchor[0], data.anchor[1]),
  };
  if (icon !== 'multiPoi') {
    iconData.url = config.icons[icon];
  }
  if ('labelOrigin' in data) {
    iconData.labelOrigin = new google.maps.Point(data.labelOrigin[0], data.labelOrigin[1]);
  }
  if ('scaledSize' in data) {
    iconData.scaledSize = new google.maps.Size(data.scaledSize[0], data.scaledSize[1]);
  }
  if ('secondLabelOrigin' in data) {
    iconData.secondLabelOrigin = new google.maps.Point(data.secondLabelOrigin[0], data.secondLabelOrigin[1]);
  }
  if ('downLabelOrigin' in data) {
    iconData.downLabelOrigin = new google.maps.Point(data.downLabelOrigin[0], data.downLabelOrigin[1]);
  }
  return iconData;
}

export default config;
export { getIconSettings };
