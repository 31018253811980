import Vue from 'vue';

import { apiError } from '../api/utils';

export default Vue.extend({
  functional: true,

  name: 'error-alert',

  props: {
    value: null,
    back: null,
    color: {
      type: String,
      default: 'error',
    },
  },

  render(h, { props, parent }) {
    const error = props.value;
    const $i18n = parent.$i18n;

    if (!(error instanceof Error)) {
      return;
    }

    let codeMsg = null;
    if (error.code) {
      const localeMessages = $i18n.getLocaleMessage($i18n.locale);
      if (localeMessages && localeMessages.error && localeMessages.error[error.code]) {
        codeMsg = `error.${error.code}`;
      }
    }

    const errors = apiError.collect(error, parent);
    const message = codeMsg ? parent.$t(codeMsg, error.data) : errors.length ? errors.join('. ') : error.message;

    return (
      <div>
        <v-alert color={props.color} value={true}>
          {message}
          {props.back && (
            <v-btn flat small color="white" to={props.back} class="mt-0 mb-1" name="error-alert-back-btn">
              {parent.$t('Back')}
            </v-btn>
          )}
        </v-alert>
      </div>
    );
  },
});
