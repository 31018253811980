<template>
  <v-container>
    <SearchInput
      -search-input
      v-model="select"
      :items="items"
      item-text="state"
      item-value="abbr"
      label="Select"
      persistent-hint
      return-object
      single-line
      ref="select"
    />
    <div>{{ select }}</div>
    <div>internalValue {{ this.$refs.select && this.$refs.select.internalValue }}</div>
    <div>internalSearch {{ this.$refs.select && this.$refs.select.internalSearch }}</div>
    <div>lazyValue {{ this.$refs.select && this.$refs.select.lazyValue }}</div>
    <div>searchInput {{ this.$refs.select && this.$refs.select.searchInput }}</div>
    <div>selectedItem {{ this.$refs.select && this.$refs.select.selectedItem }}</div>
    <v-btn @click="onClick">
      clear items
    </v-btn>
  </v-container>
</template>

<script>
import { SearchInput } from '@/components/map/SearchControl';

export default {
  components: { SearchInput },

  data() {
    return {
      select: { state: 'Florida', abbr: 'FL' },
      items: [
        { state: 'Florida', abbr: 'FL' },
        { state: 'Georgia', abbr: 'GA' },
        { state: 'Nebraska', abbr: 'NE' },
        { state: 'California', abbr: 'CA' },
        { state: 'New York', abbr: 'NY' },
      ],
    };
  },

  methods: {
    onClick() {
      this.items = [];
    },
  },
};
</script>
