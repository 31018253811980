<template>
  <v-layout row fill-height justify-center align-center>
    <v-flex md6>
      <v-card v-if="!done">
        <v-progress-linear v-if="!confirmAgain" indeterminate color="primary" />
        <template v-if="confirmAgain">
          <v-card-text>
            <error-alert :value="error" />
            {{ $t('Confirm your e-mail') }}
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="error && error.status !== 404" @click="confirm" color="primary" name="confirm-try-again-btn">
              {{ $t('Try again') }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
      <v-card v-else>
        <v-card-text>
          {{ $t('Registration.ConfirmDone') }}
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import request from '@/api/request';

export default {
  name: 'Confirm',
  metaInfo() {
    return {
      title: this.$i18n.t('Confirm'),
    };
  },
  data() {
    return {
      confirmAgain: false,
      done: false,
    };
  },

  mounted() {
    this.confirm();
  },

  methods: {
    async confirm() {
      try {
        await request.confirm(this.$route.params.code);
        this.done = true;
      } catch (err) {
        this.confirmAgain = true;
        this.error = err;
      }
    },
  },
};
</script>
