import Vue from 'vue';
import VCheckbox from 'vuetify/es5/components/VCheckbox/VCheckbox';

export default Vue.extend({
  extends: VCheckbox,

  methods: {
    onChange() {
      if (this.isDisabled) return;

      const value = this.value;
      let input = this.internalValue;

      if (value) {
        input = this.valueComparator(input, value) ? null : value;
      } else {
        input = !input;
      }

      this.validate(true, input);
      this.$emit('change', input);
    },
  },
});
