import { IconLayer } from '@deck.gl/layers';

/**
 * This icon layer mixes texture color and passed color property
 */
export default class OutlinedIconLayer extends IconLayer {
  getShaders() {
    const shaders = super.getShaders();
    shaders.fs = `
#define SHADER_NAME icon-layer-fragment-shader

precision highp float;

uniform float opacity;
uniform sampler2D iconsTexture;
uniform float alphaCutoff;

varying float vColorMode;
varying vec4 vColor;
varying vec2 vTextureCoords;
varying vec2 uv;

void main(void) {
  geometry.uv = uv;

  vec4 texColor = texture2D(iconsTexture, vTextureCoords);

  vec3 color = texColor.rgb * vColor.rgb;
  float a = texColor.a * opacity * vColor.a;

  if (a < alphaCutoff) {
    discard;
  }

  gl_FragColor = vec4(color, a);
  DECKGL_FILTER_COLOR(gl_FragColor, geometry);
}
`;
    return shaders;
  }
}
