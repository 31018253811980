import { mapState, mapGetters } from 'vuex';
import store from '@/store';
import EventBus from '@/event-bus';
import { languages } from '@/i18n';
import { ONBOARDING_ELEMENT_ZINDEX } from '@/const';
import AccountInfo from '@/components/AccountInfo';
// import SmsCounter from '@/components/billing/SmsCounter';
import { getMainSiteUrl } from '@/lib/url';
import OnboardingFixedOverlay from '@/components/onboarding/OnboardingFixedOverlay';

const MainToolbar = {
  name: 'MainToolbar',

  props: {
    backTo: String,
  },

  render() {
    const $vuetify = this.$vuetify;
    const currentLocale = this.$i18n.locale;
    const logo = this.logo;
    const logoColor = this.logoColor;

    const helpDirectives = [];
    if (this.firstTimeGuide && this.firstTimeGuideCurrentPage === 4) {
      helpDirectives.push({
        name: 'onboarding',
        value: {
          text: this.$t('helpText.hint'),
          zIndex: ONBOARDING_ELEMENT_ZINDEX + 3,
        },
        modifiers: {
          bottom: true,
        },
      });
    }

    return (
      <v-toolbar color="white" fixed clipped-left app>
        {this.showBackButton && (
          <v-toolbar-title>
            <v-btn icon onClick={this.backHandler}>
              <v-icon>arrow_back</v-icon>
            </v-btn>
          </v-toolbar-title>
        )}

        {this.showSideButton && this.$vuetify.breakpoint.name === 'xs' && (
          <v-toolbar-title>
            <v-toolbar-side-icon onClick={this.clickHandler}></v-toolbar-side-icon>
          </v-toolbar-title>
        )}

        <v-flex shrink>
          <router-link to="/">
            <img src={logo} height={$vuetify.breakpoint.mdAndUp ? 36 : 24} style="display: block" />
          </router-link>
        </v-flex>

        <v-spacer />

        {this.loggedIn && (
          <v-flex
            class={{
              'font-weight-medium': true,
              headline: $vuetify.breakpoint.mdAndUp,
              subheading: $vuetify.breakpoint.smAndDown,
            }}
            style={{ color: logoColor }}
          >
            {this.company.title}
          </v-flex>
        )}

        <v-toolbar-items>
          {$vuetify.breakpoint.mdAndUp && this.hasHelpAvailable && (
            <v-btn icon flat class="showhelp" onClick={this.startHelp} {...{ directives: helpDirectives }}>
              <v-icon color="primary">help</v-icon>
            </v-btn>
          )}
          {/* <v-icon v-badge="{ value: '99', overlap: true }">sms</v-icon> */}
          {/* {$vuetify.breakpoint.mdAndUp && this.loggedIn && this.showSmsCounter && <SmsCounter />} */}
          {!this.loggedIn && (
            <v-flex style={{ display: 'flex', alignItems: 'center' }}>
              <router-link
                to={this.$route.name === 'login' ? '/registration' : '/login'}
                style={{ fontWeight: 'bold', fontSize: '1.45rem', textDecoration: 'none' }}
              >
                {this.$route.name === 'login' ? this.$t('Register company') : this.$t('Login')}
              </router-link>
            </v-flex>
          )}

          <v-menu bottom offset-y left attach>
            <v-btn slot="activator" flat style={{ minWidth: '48px', zIndex: ONBOARDING_ELEMENT_ZINDEX + 5 }}>
              <img src={`/static/flags/${currentLocale}.png`} width="26px" />
            </v-btn>

            <v-list light>
              {languages.map(language => (
                <v-list-tile avatar onClick={() => this.changeLocale(language.locale)}>
                  <v-list-tile-avatar tile size="24px">
                    <img src={`/static/flags/${language.locale}.png`} width="24px" />
                  </v-list-tile-avatar>

                  <v-list-tile-title>{language.name}</v-list-tile-title>
                </v-list-tile>
              ))}
            </v-list>
          </v-menu>
        </v-toolbar-items>

        {this.loggedIn && $vuetify.breakpoint.mdAndUp && (
          <v-flex shrink style="color: #000">
            <AccountInfo account={this.account} mini right show-phones={false} />
          </v-flex>
        )}

        <OnboardingFixedOverlay zIndex={ONBOARDING_ELEMENT_ZINDEX + 3} />

        <OnboardingFixedOverlay zIndex={ONBOARDING_ELEMENT_ZINDEX + 5} transparent={true} />
      </v-toolbar>
    );
  },

  methods: {
    clickHandler(e) {
      this.$emit('click');
    },
    startHelp() {
      this.$store.commit('site/showHelp');
    },
    backHandler(e) {
      this.$store.commit('site/hideBackButton');
      EventBus.$emit('back');
    },
    changeLocale(to) {
      this.$i18n.locale = to;
      this.$vuetify.lang.current = this.$i18n.locale;
      document.cookie = `lang=${to};path=/`;
      this.$parent.$parent.handlerFooterHeight();
    },
  },

  computed: {
    ...mapState(['account', 'company']),
    ...mapState('auth', ['loggedIn']),
    ...mapState('site', [
      'showBackButton',
      'showHelp',
      'hasHelpAvailable',
      'firstTimeGuide',
      'firstTimeGuideCurrentPage',
    ]),
    ...mapGetters('site', ['logo', 'logoColor']),
    ...mapGetters('billing', ['showSmsCounter']),

    showSideButton() {
      if (!this.loggedIn) {
        return false;
      }
      return !this.showBackButton;
    },
  },
};

const MainFooter = {
  functional: true,
  props: {
    hideBrand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['company', 'country']),
  },

  getUrl(link, lang) {
    if (lang === 'ru') {
      return getMainSiteUrl(link) + '/ru';
    } else {
      return getMainSiteUrl(link);
    }
  },

  renderAgreementLinks(h, { parent, country }) {
    const locale = parent.$i18n.locale;

    if ((country && country === 'ru') || (!country && locale === 'ru')) {
      return (
        <ul class="mb-0 footer-links">
          <li>
            <a href="https://homestretch.ru/policy/ru" target="_blank">
              {parent.$t('Privacy Policy')}
            </a>
          </li>
          <li>
            <a href="https://homestretch.ru/policy" target="_blank">
              {parent.$t('Privacy Policy (Eng)')}
            </a>
          </li>
          <li>
            <a href="https://homestretch.ru/terms" target="_blank">
              {parent.$t('User Agreement')}
            </a>
          </li>
        </ul>
      );
    }

    if ((country && country !== 'ru') || (!country && locale !== 'ru')) {
      return (
        <ul class="mb-0 footer-links">
          <li>
            <a href="http://homestretch.ch/cookie_policy" target="_blank">
              {parent.$t('Cookie Policy')}
            </a>
          </li>
          <li>
            <a href="http://homestretch.ch/user_license_agreement" target="_blank">
              {parent.$t('End User License Agreement')}
            </a>
          </li>
          <li>
            <a href="http://homestretch.ch/privacy_policy" target="_blank">
              {parent.$t('Privacy Policy')}
            </a>
          </li>
          <li>
            <a href="http://homestretch.ch/license_agreement" target="_blank">
              {parent.$t('License Agreement')}
            </a>
          </li>
          <li>
            <a href="http://homestretch.ch/terms_of_use_biz" target="_blank">
              {parent.$t('Terms of Use')}
            </a>
          </li>
          <li>
            <a href="http://homestretch.ch/terms_of_use_user" target="_blank">
              {parent.$t('Terms of Use (Individual User)')}
            </a>
          </li>
        </ul>
      );
    }
  },

  render: function(h, { data, props, children, parent }) {
    const version = parent.$t('version', [process.env.VUE_APP_VERSION]);
    let build =
      process.env.NODE_ENV === 'development' ? '(' + parent.$t('build', [process.env.VUE_APP_BUILD_NUMBER]) + ')' : '';

    const cardActions = !props.hideBrand ? (
      <v-card-actions class="grey darken-1 justify-center">
        &copy;&nbsp;2018–{process.env.VUE_APP_YEAR}&nbsp;HomeStretch, {version} {build}
      </v-card-actions>
    ) : (
      ''
    );

    return (
      <v-footer app dark ref="footer" height={'auto'} class="v-toolbar">
        <v-card class="flex" flat tile>
          <v-card-title class="grey lighten-4 py-2" style="justify-content: center;">
            {MainFooter.renderAgreementLinks(h, {
              parent,
              country: store.state.company.country,
            })}
          </v-card-title>
          {cardActions}
        </v-card>
      </v-footer>
    );
  },
  methods: {
    getUrl(link) {
      if (this.country === 'ru') {
        return getMainSiteUrl(link) + '/ru';
      } else {
        return getMainSiteUrl(link);
      }
    },
  },
};

export { MainToolbar, MainFooter };
