import http, { pagingParams } from './httpV2';
import store from '@/store';

const path = '/branches';

async function list() {
  const res = await http.get(path);
  return res.data;
}

async function listMy(opts) {
  const res = await http.get(`${path}/my`, {
    params: {
      accounts: opts && opts.accounts === false ? 0 : opts && opts.accounts === true ? 1 : undefined,
      inactive: opts && opts.inactive === false ? 0 : opts && opts.inactive === true ? 1 : undefined,
      withoutBranchIds: opts && Array.isArray(opts.withoutBranchIds) ? opts.withoutBranchIds : [],
    },
  });

  store.commit('branches/setBranches', res.data.result);

  return res.data.result;
}

async function create(data) {
  const res = await http.post(path, data);
  return res.data;
}

async function get(branchId) {
  const res = await http.get(`${path}/${branchId}`);
  return res.data;
}

async function update(branchId, data) {
  const res = await http.put(`${path}/${branchId}`, data);
  return res.data;
}

async function remove(branchId) {
  const res = await http.delete(`${path}/${branchId}`);
  return res.data;
}

async function getAccountsFromBranch(branchId, opts) {
  const res = await http.get(`${path}/${branchId}/accounts`, {
    params: pagingParams(opts),
  });

  return res.data;
}

async function accounts(branchId) {
  const params = {
    withoutAccountsFromBranchId: branchId,
  };

  const res = await http.get(`${path}/accounts`, { params });

  for (const item of res.data) {
    const tmp = [];

    if (item.last_name) {
      tmp.push(item.last_name);
    }

    if (item.first_name) {
      tmp.push(item.first_name);
    }

    item.full_name = tmp.join(' ');
  }

  return res.data;
}

async function parents(branchId) {
  const params = {
    branch_id: branchId,
  };

  const res = await http.get(`${path}/parents`, { params });
  return res.data;
}

async function responsibles(accountId) {
  const res = await http.get(`${path}/accounts/${accountId}/responsibles`);
  return res.data;
}

async function addAccount(branchId, accountId) {
  const res = await http.post(`${path}/${branchId}/accounts`, { account_id: accountId });
  return res.data;
}

async function moveAccountToBranch(branchId, data) {
  const res = await http.post(`${path}/${branchId}/moveAccount`, data);
  return res.data;
}

export default {
  list,
  listMy,
  create,
  get,
  update,
  remove,
  accounts,
  getAccountsFromBranch,
  addAccount,
  parents,
  moveAccountToBranch,
  responsibles,
};
