<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Invoices') }}</h1>
    </v-flex>
    <v-flex>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="events"
          :pagination.sync="pagination"
          :custom-sort="applySort"
          :must-sort="true"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.invoice_number }}</td>
            <td>{{ props.item.created_at }}</td>
            <td>{{ props.item.service }}</td>
            <td>{{ props.item.status }}</td>
            <td>
              <v-btn round color="error" :href="props.item.invoiceUrl" download>
                {{ $t('Download invoice') }}
              </v-btn>
              <v-btn v-if="showActUrl(props.item)" round color="error" :href="props.item.actUrl" download>
                {{ $t('Download act') }}
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { format } from 'date-fns';
import _ from 'lodash';
import api from '@/api';
import { mapState } from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Invoices'),
    };
  },
  data() {
    return {
      pagination: {
        sortBy: '#',
      },
      events: [],
      info: {
        list: [],
      },
    };
  },
  computed: {
    ...mapState('company', ['country']),

    headers() {
      return [
        { text: '#', align: 'center', value: 'invoice_number' },
        { text: this.$t('Date'), align: 'center', value: 'created_at' },
        { text: this.$t('Services'), align: 'center', value: 'service' },
        { text: this.$t('Status'), align: 'center', value: 'status' },
        { text: this.$t('Document'), align: 'center', value: 'url' },
      ];
    },

    dateFormat() {
      return this.$i18n.locale === 'ru' ? 'dd.MM.yyyy HH:mm' : 'dd/MM/yyyy HH:mm';
    },
  },
  async created() {
    await this.fetchInvoiceList();
  },
  watch: {
    '$i18n.locale'() {
      this.applyData();
    },
  },
  methods: {
    async fetchInvoiceList() {
      this.info = await api.billing.info.getInvoiceList();
      this.applyData();
    },

    getService(type) {
      const action = {
        SUBSCRIPTION: this.$t('Subscription renewal'),
        SLOT: this.$t('Activating additional accounts'),
        SMS: this.$t('Buying sms packages'),
      };

      return action[type];
    },

    getStatus(status, paidFor) {
      const details = {
        PENDING: this.$t('Pending payment'),
        PAID: this.$t('Paid to {0}', [format(new Date(paidFor * 1000), this.dateFormat)]),
        CANCELED: this.$t('Canceled'),
      };

      return details[status];
    },

    applySort(items) {
      if (this.pagination.sortBy === 'created_at') {
        return items.sort((a, b) => {
          if (this.pagination.descending) {
            return a.timestamp - b.timestamp;
          } else {
            return b.timestamp - a.timestamp;
          }
        });
      }
      return items.sort();
    },

    applyData() {
      const data = _.map(this.info.list, invoice => {
        return {
          invoice_number: invoice.invoice_number,
          timestamp: invoice.created_at,
          created_at: format(new Date(invoice.created_at * 1000), this.dateFormat),
          service: this.getService(invoice.type),
          status: this.getStatus(invoice.status, invoice.paid_for),
          invoiceUrl: invoice.url,
          actUrl: invoice.actUrl,
          type: invoice.type,
        };
      });

      this.events = data;
    },

    showActUrl(item) {
      return this.country === 'ru' && !['SMS', 'SLOT'].includes(item.type);
    },
  },
};
</script>
