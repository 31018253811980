
import Vue from 'vue';
import { ResizeObserver } from '@juggle/resize-observer';

export default Vue.extend({
  name: 'RowLink',

  props: {
    to: {},
  },

  data() {
    return {
      resizeObserver: null as ResizeObserver | null,
    };
  },

  mounted() {
    const rowEl = this.$el.closest('tr');

    if (rowEl) {
      const resizeObserver = new ResizeObserver((entries, observer) => {
        entries.forEach((entry, index) => {
          const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
          this.fixWidthCallback(entry.target, width, height);
        });
      });

      resizeObserver.observe(rowEl);
      this.fixWidth(rowEl);
    }
  },

  beforeDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },

  methods: {
    fixWidth(rowEl: HTMLElement) {
      this.fixWidthCallback(rowEl, rowEl.offsetWidth, rowEl.offsetHeight);
    },

    fixWidthCallback(targetEl: Element, width: number, height: number) {
      const elem = this.$el.firstElementChild as HTMLElement | null;
      if (elem) {
        elem.style.width = width + 'px';
        elem.style.height = height + 'px';
      }
    },
  },
});
