import Vue from 'vue';
import { isNil } from 'lodash';
import { isSameDay, convertDateWithTimeZone } from '@/lib/date';
import { getTimeZoneString } from '@/lib/timezone';

export default Vue.extend({
  computed: {
    updatedAtText() {
      if (!this.trip) {
        return;
      }

      return (
        '<i aria-hidden="true" class="material-icons v-icon">sync</i> ' +
        this.formatTimeAndDate(
          this.trip.syncAt,
          false,
          this.trip.containsDifferentTimezones(),
          this.trip.startTimezone().offset,
        )
      );
    },

    updatedAtTextOneLine() {
      if (!this.trip) {
        return;
      }

      return (
        '<i aria-hidden="true" class="material-icons v-icon">sync</i> ' +
        this.formatTimeAndDateOneLine(
          this.trip.syncAt,
          false,
          this.trip.containsDifferentTimezones(),
          this.trip.startTimezone().offset,
        )
      );
    },
  },

  methods: {
    showDate() {
      return this.trip.containsDifferentDates();
    },

    formatTimeAndDate(value, showDate, showTimezone, timezoneOffset) {
      let timeZonePostfix = '';
      let correctedValueWithTimezone = value;
      if (showTimezone && !isNil(timezoneOffset)) {
        correctedValueWithTimezone = convertDateWithTimeZone(value, timezoneOffset);
        timeZonePostfix = getTimeZoneString(timezoneOffset);
      }
      let timeValue;
      if (showDate) {
        timeValue =
          this.$d(correctedValueWithTimezone, 'time') +
          timeZonePostfix +
          '<br />' +
          this.$d(correctedValueWithTimezone, 'short');
      } else {
        timeValue = this.$d(correctedValueWithTimezone, 'time') + timeZonePostfix;
      }
      return timeValue;
    },

    formatTimeAndDateOneLine(value, showDate, showTimezone, timezoneOffset) {
      let timeFormat = showDate ? 'long' : 'time';
      if (showTimezone && !isNil(timezoneOffset)) {
        return this.$d(convertDateWithTimeZone(value, timezoneOffset), timeFormat) + getTimeZoneString(timezoneOffset);
      } else {
        return this.$d(value, timeFormat);
      }
    },
  },
});
