
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

export default Vue.extend({
  name: 'PointFunctionsItemsPicker',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Point functions',
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.updatePointFunctions();
  },
  computed: {
    ...mapState('pointFunctions', ['pointFunctionsList']),
    items(): any[] {
      const items = [];
      for (const [index, pointFunction] of this.pointFunctionsList.entries()) {
        if (index !== 0) {
          items.push({
            divider: true,
          });
        }
        items.push({
          header: pointFunction.name,
        });
        for (const item of pointFunction.functions.checklist || []) {
          items.push({
            pointFunctionId: pointFunction.id,
            id: `${pointFunction.id}:c:${item.id}`,
            text: item.text,
          });
        }
        for (const item of pointFunction.functions.droplist || []) {
          items.push({
            pointFunctionId: pointFunction.id,
            id: `${pointFunction.id}:d:${item.id}`,
            text: item.text,
          });
        }
      }
      return items;
    },
  },
  methods: {
    ...mapActions('pointFunctions', ['updatePointFunctions']),
    change(value: string) {
      this.$emit('input', value);
    },
  },
});
