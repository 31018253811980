import Vue from 'vue';
import Component from 'vue-class-component';
import config from './config';
import { mapGetters } from 'vuex';
import { Prop } from 'vue-property-decorator';

const FOOTER_HEIGHT = 138;

@Component({
  computed: {
    ...mapGetters('account', ['findCountryInfo']),
  },
})
export default class MapStylesMixin extends Vue {
  @Prop({ type: Boolean, default: false }) full!: boolean;
  @Prop({ type: Boolean, default: false }) hide!: boolean;

  height: number | null = null;
  findCountryInfo!: { latitude: number; longitude: number };

  get appPaddingLeft(): number {
    return this.$vuetify.application.left;
  }

  get appPaddingTop(): number {
    return this.$vuetify.application.top;
  }

  get mapWidth() {
    if (this.hide) {
      return '0px';
    }
    return !this.hide && this.full ? '100%' : `calc((100vw - ${this.appPaddingLeft + 8}px)*0.416666)`;
  }

  get mapHeight() {
    return this.height ? this.height + 'px' : `calc(100% - ${FOOTER_HEIGHT}px)`;
  }

  get defaultFocus() {
    const findCountryInfo = this.findCountryInfo;
    if (findCountryInfo && findCountryInfo.latitude !== undefined && findCountryInfo.longitude !== undefined) {
      return {
        lat: findCountryInfo.latitude,
        lng: findCountryInfo.longitude,
      };
    }

    return config.defaultCoordinate;
  }

  get mapOptions(): google.maps.MapOptions {
    return {
      zoom: 9,
      center: this.defaultFocus,
      gestureHandling: 'greedy',
      mapTypeControl: false,
      streetViewControl: false,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      clickableIcons: false,
      styles: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    };
  }

  get mapStyles(): Partial<CSSStyleDeclaration> {
    return {
      position: 'fixed',
      width: this.mapWidth,
      height: this.mapHeight,
      top: `${this.appPaddingTop}px`,
      paddingTop: '',
      zIndex: '1',
    };
  }
}
