<template>
  <div>
    <v-form v-model="formValid">
      <v-text-field
        v-for="(item, i) in sanitizedValue"
        :key="item.id"
        :value="item.phone"
        :label="getLabel(i)"
        :append-icon="getIcon(i)"
        :rules="rules"
        @input="v => input({ value: v, index: i })"
        @update:error="v => onErrorUpdate({ value: v, index: i })"
        @click:append="addOrDeleteItem(i)"
      />
    </v-form>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import rules from '@/api/rules';
import _ from 'lodash';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    maxCount: {
      type: Number,
      default: 3,
    },
  },

  data() {
    return {
      formValid: false,
      showDummyItem: false,
      valid: [],
    };
  },

  computed: {
    rules() {
      return rules.phone;
    },

    sanitizedValue() {
      const value = this.value || [];
      if (this.showDummyItem || value.length === 0) {
        return [
          ...this.value,
          {
            id: uuid(),
            phone: '',
          },
        ];
      }
      return value;
    },
  },

  methods: {
    addOrDeleteItem(i) {
      if (this.sanitizedValue[i].phone) {
        this.showDummyItem = true;
      } else {
        const result = [...this.sanitizedValue];
        result.splice(i, 1);
        this.valid.splice(i, 1);
        this.$emit('input', result);
      }
    },

    input({ value, index }) {
      const result = [...this.sanitizedValue];
      if (index === this.value.length) {
        this.showDummyItem = false;
      }
      result[index].phone = value;
      this.$emit('input', result);
    },

    getLabel(i) {
      if (i === 0) {
        return this.$t('Phone');
      }
    },

    getIcon(i) {
      if (this.sanitizedValue[i].phone) {
        if (i === this.sanitizedValue.length - 1 && this.sanitizedValue.length < this.maxCount) {
          return 'mdi-plus-circle-outline';
        }
      } else {
        if (i > 0 && i === this.sanitizedValue.length - 1) {
          // return 'mdi-close-circle-outline';
        }
      }
    },

    onErrorUpdate({ value, index }) {
      this.valid[index] = !value;
    },
  },

  watch: {
    value: {
      deep: true,
      handler(n, o) {
        const emptyIndex = _.findIndex(this.value, item => !item.phone);
        if (emptyIndex !== -1) {
          const result = [...this.value];
          result.splice(emptyIndex, 1);
          this.valid.splice(emptyIndex, 1);
          this.$emit('input', result);
        }
      },
    },
    formValid(value) {
      this.$emit('updatePhoneValid', value);
    },
  },
};
</script>
