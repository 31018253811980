import Vue from 'vue';
import OnboardingFixedOverlay from '@/components/onboarding/OnboardingFixedOverlay';

export default Vue.extend({
  name: 'OnboardingMapFixedOverlay',
  extends: OnboardingFixedOverlay,
  inject: ['getMap'],

  data() {
    return {
      map: null,
    };
  },

  methods: {
    async show() {
      this.map = await this.getMap();

      OnboardingFixedOverlay.methods.show.call(this);

      const div = this.map.getDiv();
      const container = div.firstChild.firstChild;
      this.overlay.parentNode.removeChild(this.overlay);
      container.appendChild(this.overlay);
    },
  },
});
