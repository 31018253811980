import Uuid from './Uuid';
import { MobileDeviceInfo } from './Trip';
import { TransportType } from './Transport';
import { Role } from './Role';
import { Contact } from '@/models/Contact';

export interface Branch {
  id?: number | null;
  title?: string;
  isOwner?: boolean;
}

export interface AccountDto {
  id: Uuid;
  dbId: number | null;
  email: string | null;
  phone: string | null;
  contacts: Contact[];
  country: string | null;
  jobTitle: string | null;
  firstName: string | null;
  lastName: string | null;
  isRegular: boolean | null;
  mobileDeviceInfo: MobileDeviceInfo;
  mobileClientInfo: object;
  status: AccountStatus;
  ownTransportType: TransportType;
  branchNames?: Array<Branch>;
  canDelete: boolean | undefined;
  canUpdate: boolean | undefined;
  roleId: string | undefined;
  role: Role | undefined | {};
}

export enum AccountStatus {
  new = 'new',
  active = 'active',
  inactive = 'inactive',
}

/** @deprecated */
export enum AccountNumericStatus {
  new = 0,
  active = 1,
  inactive = 2,
}

export default class Account {
  id: Uuid;

  dbId: number | null;

  email: string | null;

  phone: string | null;

  contacts: Contact[];

  country: string | null;

  jobTitle: string | null;

  firstName: string | null;

  lastName: string | null;

  isRegular: boolean | null;

  mobileDeviceInfo: MobileDeviceInfo;

  mobileClientInfo: object;

  status: AccountStatus;

  ownTransportType: TransportType;

  branchNames: Array<Branch> = [];

  canDelete: boolean | undefined;

  canUpdate: boolean | undefined;

  roleId: string | undefined;

  role: Role | undefined | {};

  constructor(data?: AccountDto) {
    this.id = data?.id || '';
    this.dbId = data?.dbId || null;
    this.email = data?.email || null;
    this.phone = data?.phone || null;
    this.contacts = data?.contacts || [];
    this.country = data?.country || null;
    this.jobTitle = data?.jobTitle || null;
    this.firstName = data?.firstName || null;
    this.lastName = data?.lastName || null;
    this.isRegular = data?.isRegular || false;
    this.mobileDeviceInfo = data?.mobileDeviceInfo || {
      os: null,
      locale: null,
      vendor: null,
      osVersion: null,
      clientVersion: null,
      phoneNumber: null,
      isOutdated: false,
    };
    this.mobileClientInfo = data?.mobileClientInfo || {};
    this.status = data?.status || AccountStatus.new;
    this.ownTransportType = data?.ownTransportType || TransportType.car;
    this.branchNames = data?.branchNames || [];
    this.canDelete = data?.canDelete;
    this.canUpdate = data?.canUpdate;
    this.roleId = data?.roleId;
    this.role = data?.role || {};
  }

  get fullName(): string {
    const res = [];
    if (this.lastName) {
      res.push(this.lastName);
    }
    if (this.firstName) {
      res.push(this.firstName);
    }
    return res.join(' ');
  }

  get initials(): string | null {
    if (this.firstName && this.lastName) {
      return `${this.lastName[0].toUpperCase()}${this.firstName[0].toUpperCase()}`;
    }
    return null;
  }

  toJSON(): AccountDto {
    return {
      id: this.id,
      dbId: this.dbId,
      email: this.email,
      phone: this.phone,
      contacts: this.contacts,
      country: this.country,
      jobTitle: this.jobTitle,
      firstName: this.firstName,
      lastName: this.lastName,
      isRegular: this.isRegular,
      mobileDeviceInfo: this.mobileDeviceInfo,
      mobileClientInfo: this.mobileClientInfo,
      status: this.status,
      ownTransportType: this.ownTransportType,
      branchNames: this.branchNames,
      canDelete: this.canDelete,
      canUpdate: this.canUpdate,
      roleId: this.roleId,
      role: this.role || {},
    };
  }
}
