
import Vue from 'vue';
import Grid, { GridTh } from '@/components/grid/Grid';
import { AUTORELOAD_TIME } from '@/const';
import WhereMyTruckRow from '@/components/trip/WhereMyTruckRow.vue';
import whereMyTruckApi from '@/api/dashboard';

export default Vue.extend({
  name: 'WhereMyTruck',

  components: {
    Grid,
    GridTh,
    WhereMyTruckRow,
  },

  data() {
    return {
      code: null as string | null,
      timer: null as number | null,
      error: null,
      items: [] as Array<any>,
      updateTime: null as string | null,
    };
  },

  computed: {
    fields(): object {
      return {
        date: {
          label: 'Date',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        clientCode: {
          label: 'Client code',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        clientName: {
          label: 'Client name',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        docNumber: {
          label: 'Document number',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        warehouse: {
          label: 'Warehouse',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        workingHours: {
          label: 'Working hours',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        arrivalPlan: {
          label: 'Arrival plan',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        arrivalPrediction: {
          label: 'Arrival prediction',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        arrivalActual: {
          label: 'Arrival actual',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        stayTimePlan: {
          label: 'Stay time plan',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        stayTimeActual: {
          label: 'Stay time actual',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
      };
    },
  },

  async created(): Promise<void> {
    if (window.location.pathname.indexOf('/wheremytruck/') === 0) {
      this.code = window.location.pathname.substr(14);
      await this.fetchData();
      this.timer = setInterval(this.fetchData, AUTORELOAD_TIME);
    }
  },

  beforeDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  methods: {
    async fetchData(): Promise<void> {
      if (!this.code) {
        return;
      }
      try {
        this.error = null;
        this.items = await whereMyTruckApi.getDashboard(this.code);
      } catch (err) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        this.error = err;
      } finally {
        this.updateTime = this.$d(new Date(), 'date');
      }
    },
  },
});
