const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, mask: false },
  person: { x: 128, y: 0, width: 128, height: 128, mask: false },
  point: { x: 256, y: 0, width: 128, height: 128, anchorY: 118, mask: false },
  finish: { x: 384, y: 0, width: 128, height: 128, anchorY: 118, mask: false },
  ghost: { x: 512, y: 0, width: 128, height: 128, mask: false },
  car: { x: 640, y: 0, width: 128, height: 128, anchorY: 113, mask: false },
  truck: { x: 768, y: 0, width: 128, height: 128, anchorY: 108, mask: false },
  taxi: { x: 896, y: 0, width: 128, height: 128, anchorY: 113, mask: false },
  bus: { x: 1024, y: 0, width: 128, height: 128, anchorY: 113, mask: false },
  motorcycle: { x: 1152, y: 0, width: 128, height: 128, anchorY: 103, mask: false },
  bicycle: { x: 1280, y: 0, width: 128, height: 128, anchorY: 118, mask: false },
  pedestrian: { x: 1408, y: 0, width: 128, height: 128, anchorY: 118, mask: false },
};

export default ICON_MAPPING;
