
import Vue from 'vue';
import {
  DistributionAssumptions,
  DistributionAssumptionsTypes,
  DistributionStrategy,
  DistributionStrategyTypes,
  DistributionSettings,
  DistributionConfiguration,
  DistributionDebugConfigurations,
} from '@/models/Distribution';

export default Vue.extend({
  name: 'DistributionOptions',

  props: {
    value: Object as () => DistributionSettings,
  },

  computed: {
    isDistributionAdditional(): boolean {
      return this.$route.name === 'distributionAdditional';
    },

    configurations(): { value: string; text: string; debug: boolean }[] {
      const keys = Object.keys(DistributionConfiguration);

      return keys.map(key => ({
        value: key,
        text: this.$t(`Distribution.Configuration.${key}`) as string,
        debug: DistributionDebugConfigurations.includes(key as DistributionConfiguration),
      }));
    },

    nonDebugConfigurations(): { value: string; text: string; debug: boolean }[] {
      return this.configurations.filter(configuration => !configuration.debug);
    },

    assumptions(): { value: string; text: string }[] {
      const keys = Object.keys((this.$i18n.messages.en?.Distribution as any)?.Settings);

      return keys.map(key => ({
        value: key,
        text: this.$t(`Distribution.Settings.${key}`) as string,
      }));
    },

    replanStrategy(): { value: string; text: string }[] {
      const keys = Object.keys((this.$i18n.messages.en?.Distribution as any)?.ReplanStrategy);
      return keys.map(key => ({
        value: key,
        text: this.$t(`Distribution.ReplanStrategy.${key}`) as string,
      }));
    },

    configurationValue: {
      get(): DistributionConfiguration {
        return this.value.configuration;
      },
      set(value: DistributionConfiguration) {
        this.settings = {
          ...this.settings,
          configuration: value,
        };
      },
    },

    assumptionsValue: {
      get(): DistributionAssumptionsTypes[] {
        const keys = Object.keys(this.value.assumptions) as DistributionAssumptionsTypes[];
        return keys.filter(key => this.value.assumptions[key]);
      },
      set(value: DistributionAssumptionsTypes[]) {
        const keys = Object.keys(this.value.assumptions) as DistributionAssumptionsTypes[];
        const newAssumptions = {} as DistributionAssumptions;

        for (const key of value) {
          newAssumptions[key] = true;
        }

        for (const key of keys) {
          newAssumptions[key] = newAssumptions[key] || false;
        }

        this.settings = {
          ...this.settings,
          assumptions: newAssumptions,
        };
      },
    },

    strategyValue: {
      get(): DistributionStrategyTypes[] {
        const keys = Object.keys(this.value.replanStrategy) as DistributionStrategyTypes[];
        return keys.filter(key => this.value.replanStrategy[key]);
      },
      set(value: DistributionStrategyTypes[]) {
        const keys = Object.keys(this.value.replanStrategy) as DistributionStrategyTypes[];
        const newStrategy = {} as DistributionStrategy;
        for (const key of value) {
          newStrategy[key] = true;
        }
        for (const key of keys) {
          newStrategy[key] = newStrategy[key] || false;
        }
        this.settings = {
          ...this.settings,
          replanStrategy: newStrategy,
        };
      },
    },

    planningTime: {
      get: function(): number {
        return this.value.planningTime;
      },
      set: function(value: string) {
        this.settings = {
          ...this.settings,
          planningTime: Number(value),
        };
      },
    },

    settings: {
      get: function(): DistributionSettings {
        return this.value;
      },
      set: function(value: DistributionSettings) {
        this.$emit('input', value);
      },
    },
  },
});
