<template>
  <v-select
    :label="$t(label)"
    prepend-icon="insert_chart"
    :value="value"
    :items="items"
    @input="select"
    multiple
    :clearable="clearable"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: 'TripEventsPicker',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default: 'Events',
    },
    disabled: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      return [
        {
          text: this.$t('Changes'),
          value: 'events',
        },
        {
          text: this.$t('Delays'),
          value: 'missedDeliveryWindows',
        },
        {
          text: this.$t('Downtimes on point'),
          value: 'stayTimeExceeded',
        },
        {
          text: this.$t('Downtimes at work'),
          value: 'workTimeExceeded',
        },
      ];
    },
  },
  methods: {
    select(value) {
      this.$emit('input', value);
    },
  },
};
</script>
