
import Vue from 'vue';
import Uuid from '@/models/Uuid';
import Trip from '@/models/Trip';
import { TripTableRow } from '@/services/TripPageService';
import PointNumber from './grid/PointNumber.vue';
import PointType from './grid/PointType.vue';
import PoiName from './grid/PoiName.vue';
import DeliveryWindows from './grid/DeliveryWindows';
import PointStayTime from './grid/PointStayTime';
import TimeWithOptionalDay from '../TimeWithOptionalDay';
import { TripPointType } from '@/models/TripPoint';
import { mapState } from 'vuex';

export default Vue.extend({
  components: {
    PointNumber,
    PointType,
    DeliveryWindows,
    PoiName,
    PointStayTime,
    TimeWithOptionalDay,
  },

  props: {
    trip: Trip,
    item: TripTableRow,
    haveDeliveryWindows: Boolean,
    haveOrders: Boolean,
    havePointFunctions: Boolean,
  },

  computed: {
    ...mapState('map', ['pointSelectId', 'currentSelectRow']),

    haveWorkTime() {
      return false;
    },

    typeEnum() {
      return TripPointType;
    },
  },

  methods: {
    tableRowClick(_item: TripTableRow) {
      let wayPointId: string | null = null;
      let currentSelectRow: string | null = null;
      if (_item && _item.wayPoint) {
        wayPointId = _item.wayPoint.id;
      }

      if (_item && _item.tripPoint) {
        this.trip.wayPoints.forEach(wayPoint => {
          wayPoint.tripPointIds.forEach(tripPointId => {
            if (tripPointId === (_item as any).tripPoint.id) {
              wayPointId = wayPoint.id;
            }
          });
        });
      }

      // map observes for tableRowSelectId and rerender
      this.$store.commit('map/toggleSelection', {
        tableRowSelectId: wayPointId,
        currentSelectRow: _item.id,
      });
    },

    isRowActive(_item: TripTableRow) {
      if (_item && _item.id === this.currentSelectRow) {
        return true;
      }

      const wayPoint = this.trip.wayPoints.filter(wayPoint => {
        if (wayPoint.id === this.pointSelectId) {
          return wayPoint;
        }
      })[0];

      if (wayPoint && _item && !this.currentSelectRow) {
        if (
          wayPoint.id === _item.id ||
          wayPoint.checkins.some(checkin => checkin.id === _item.id) ||
          wayPoint.tripPointIds.some(tripPointId => _item.tripPoint && tripPointId === _item.tripPoint.id)
        ) {
          return true;
        }
      }
    },

    getTrClass(_item: TripTableRow) {},

    getNumberBorder(top: boolean, bottom: boolean, isMultipoint?: boolean) {
      const style: Partial<CSSStyleDeclaration> = {
        padding: '0',
        margin: '0',
        width: !isMultipoint ? '10px' : undefined,
      };

      if (top) {
        style.borderTop = '0';
      }

      if (bottom) {
        style.borderBottom = '0';
      }

      if (isMultipoint) {
        style.borderRight = '0';
      }

      return style;
    },

    makeFinish(id: Uuid) {
      this.$emit('makeFinish', id);
    },

    makeClient(id: Uuid) {
      this.$emit('makeClient', id);
    },

    deletePoint(id: Uuid) {
      this.$emit('deletePoint', id);
    },

    undeletePoint(id: Uuid) {
      this.$emit('undeletePoint', id);
    },

    openPointSettingsDialog(tableRow: TripTableRow) {
      this.$emit('openPointSettingsDialog', tableRow);
    },

    openPointPlaceCreateDialog(tableRow: TripTableRow) {
      this.$emit('openPointPlaceCreateDialog', tableRow);
    },
  },
});
