
import Vue from 'vue';
import tags from '@/api/tags';
import { Tag } from '@/models/Order';

export default Vue.extend({
  name: 'TagDeletionWarning',
  data() {
    return {
      showDialog: true,
      error: null,
    };
  },
  props: {
    tag: Object as () => Tag,
  },
  methods: {
    async deleteTag(): Promise<void> {
      try {
        await tags.deleteTag(this.tag.id);
        this.$emit('close', true);
      } catch (e) {
        this.error = e;
      }
    },
    hideModal() {
      this.$emit('close');
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
