import escapeRegExp from 'lodash/escapeRegExp';
import http, { pagingParams, Params } from './httpV2';
import Uuid from '@/models/Uuid';
import { Tag } from '@/models/Order';

const path = '/tags';

export interface TagsResponse {
  result: Array<Tag>;
  total: number;
}

async function getTags(params: Params = {}): Promise<TagsResponse> {
  const res = await http.get(path, {
    params: {
      ...pagingParams(params),
    },
  });
  return res.data;
}

async function patchTag(id: Uuid, data: any): Promise<any> {
  const res = await http.patch(`${path}/${id}`, data);
  return res.data;
}

async function deleteTag(id: Uuid): Promise<any> {
  const res = await http.delete(`${path}/${id}`);
  return res.data;
}

async function createTag(data: { name: string; color: string }): Promise<any> {
  const res = await http.post(path, data);
  return res.data;
}

export default {
  getTags,
  patchTag,
  deleteTag,
  createTag,
};
