<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Department') }}: {{ form.data.name }}</h1>
    </v-flex>

    <v-flex>
      <v-card class="mb-3">
        <v-container class="pa-4" grid-list-sm>
          <v-layout column>
            <v-flex>
              <div class="subheading mb-2">
                {{ $t('Main parameters') }}
              </div>

              <div class="ml-4">
                <Form ref="form" v-model="form" :error="error" :fields="fields" />
              </div>
            </v-flex>

            <v-flex>
              <div class="subheading">
                {{ $t('Notification settings.Title') }}
              </div>

              <div class="ml-4">
                <v-form v-model="notifications.valid">
                  <v-container>
                    <v-layout row wrap class="pt-2">
                      <v-flex lg6 xs12>
                        <span class="subheading grey--text text--darken-1">
                          {{ $t('Notification settings.Notification about changing the order of points in the trip') }}
                        </span>

                        <v-layout class="ma-0">
                          <span class="grey--text text--darken-1 pt-2">
                            {{ $t('Notification settings.Emails') }}
                          </span>

                          <v-flex ml-2>
                            <v-menu
                              bottom
                              origin="center center"
                              transition="scale-x-transition"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on: menu }">
                                <v-tooltip bottom>
                                  <template #activator="{ on: tooltip }">
                                    <v-text-field
                                      readonly
                                      class="mt-0 pt-0"
                                      :rules="emailsRules"
                                      v-on="{ ...tooltip, ...menu }"
                                      id="notification-emails-input"
                                      @click="focusTextArea('notification-emails-textarea-first')"
                                      v-model="reorderTripPoints"
                                    />
                                  </template>

                                  <span>
                                    {{ $t('Enter the addresses, separated by commas') }}
                                  </span>
                                </v-tooltip>
                              </template>

                              <v-textarea
                                solo
                                auto-grow
                                hide-details
                                style="background-color: white"
                                id="notification-emails-textarea-first"
                                v-model="reorderTripPoints"
                              />
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex />

                      <v-flex lg5 xs12>
                        <span class="subheading grey--text text--darken-1">
                          {{ $t('Notification settings.Notification of the end of the trip') }}
                        </span>

                        <v-layout class="ma-0">
                          <span class="grey--text text--darken-1 pt-2">
                            {{ $t('Notification settings.Emails') }}
                          </span>

                          <v-flex ml-2>
                            <v-menu
                              bottom
                              origin="center center"
                              transition="scale-y-transition"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on: menu }">
                                <v-tooltip bottom>
                                  <template #activator="{ on: tooltip }">
                                    <v-text-field
                                      readonly
                                      class="mt-0 pt-0"
                                      :rules="emailsRules"
                                      v-on="{ ...tooltip, ...menu }"
                                      id="notification-emails-input"
                                      @click="focusTextArea('notification-emails-textarea-second')"
                                      :value="goToFinish"
                                    />
                                  </template>

                                  <span>
                                    {{ $t('Enter the addresses, separated by commas') }}
                                  </span>
                                </v-tooltip>
                              </template>

                              <v-textarea
                                solo
                                auto-grow
                                hide-details
                                style="background-color: white"
                                id="notification-emails-textarea-second"
                                v-model="goToFinish"
                              />
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

        <v-card-actions right>
          <v-spacer />

          <v-btn @click.native="back">
            {{ $t('Back') }}
          </v-btn>

          <v-btn
            v-if="canUpdate || isNew"
            :disabled="!form.valid || !notifications.valid"
            color="primary"
            @click.native="save"
          >
            {{ $t('Save') }}
          </v-btn>

          <v-btn v-if="canDelete" color="pink" dark @click.native="deleteDialog = true">
            {{ $t('Delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="!isNew">
        <Grid :pagination.sync="pagination" :api="apiFunction" :fields="gridFields" ref="grid">
          <template slot="actionsHeader">
            <v-flex style="max-width: 350px" class="align-end" id="add_new_accounts" v-show="false">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn small v-on="on" @click="openAddDialog" color="primary">
                    {{ $t('Add') }}
                  </v-btn>
                </template>
              </v-menu>
            </v-flex>
          </template>

          <template slot="items" slot-scope="props">
            <tr style="background: white" class="table-row" v-clickable="$event => details(props.item, $event)">
              <td class="text-no-wrap">
                {{ props.item.full_name }}
              </td>

              <td class="text-no-wrap">
                {{ props.item.job_title }}
              </td>

              <td class="text-no-wrap">
                {{ props.item.email }}
              </td>

              <td class="text-no-wrap">
                {{ props.item.phone }}
              </td>

              <td @click.prevent.stop>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn flat icon small class="d-flex" v-on="on" @click.stop>
                      <v-icon small>
                        menu
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-tile @click="details(props.item)">
                      <v-list-tile-title>
                        {{ $t('Details') }}
                      </v-list-tile-title>
                    </v-list-tile>

                    <v-list-tile v-if="props.item.can_update" @click="showMoveDialog(props.item)">
                      <v-list-tile-title>
                        {{ $t('Move') }}
                      </v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
        </Grid>
      </v-card>
    </v-flex>

    <v-dialog v-model="addDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ $t('Add employee to department') }}
        </v-card-title>

        <v-container class="pa-4">
          <AccountPicker
            v-model="addForm.account_ids"
            :items="this.accountsPickerData"
            :label="$t('Employee')"
            prepend-icon="person"
          />
        </v-container>

        <v-card-actions>
          <v-spacer />

          <v-btn color="" flat="flat" @click.native="addDialog = false">
            {{ $t('Cancel') }}
          </v-btn>

          <v-btn
            :disabled="!addForm.account_ids || !addForm.account_ids.length"
            color="primary"
            flat="flat"
            @click.native="handleAdd"
          >
            {{ $t('Add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      :delete-error="deleteError"
      :append-text="deleteAppendText"
      :delete-item="deleteItemName"
      :supplement-text="deleteSupplementText"
      :max-width="deleteAppendText ? '400' : '320'"
      @close="deleteDialog = false"
      @delete="handleDeleteBranch"
    />

    <MoveDialog
      v-if="moveDialog"
      :move-error="moveAccountError"
      :append-text="moveAppendText"
      :move-item="moveItemName"
      :move-branches="true"
      :withoutBranchIds="[id]"
      :max-width="moveAppendText ? '400' : '320'"
      @close="moveDialog = false"
      @move="moveAccountToBranch"
    />
  </v-layout>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

import { get } from 'lodash';

import branch from '@/api/branch';
import rules, { REG_EXPS } from '@/api/rules';

import AccountName from '@/components/AccountName';
import DeleteDialog from '@/components/modals/DeleteDialog';
import MoveDialog from '@/components/modals/MoveDialog';
import AccountPicker from '@/components/pickers/AccountPicker';
import Grid, { defaultPagination } from '@/components/grid/GridCrud';

export default Vue.extend({
  name: 'BranchDetails',

  components: { DeleteDialog, MoveDialog, AccountPicker, Grid },

  metaInfo() {
    return {
      title: this.$i18n.t('Branches'),
    };
  },

  data() {
    return {
      pagination: { ...defaultPagination, sortBy: 'full_name' },
      id: null,
      branchData: {},
      form: {
        valid: true,
        data: {},
      },
      notifications: {
        valid: true,
        emails: {
          reorderTripPoints: [],
          goToFinish: [],
        },
      },
      error: null,
      accountsPickerData: [],
      accounts: [],
      branchParentsList: [],
      availableAccountsList: [],
      addDialog: false,
      addForm: {
        account_ids: [],
      },
      addError: null,
      deleteDialog: false,
      deleteError: null,
      canDelete: false,
      canUpdate: false,
      moveDialog: false,
      moveAccountError: null,
      accountIdToMove: null,
      accountNameToMove: null,
    };
  },

  created() {
    if (this.$route.params.id !== 'new') {
      this.id = parseInt(this.$route.params.id, 10);
    }

    this.fetchData();
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('company', ['settings']),

    isNew() {
      return this.id === null;
    },

    fields() {
      const result = [];

      result.push({
        name: 'name',
        label: 'TitleName',
        rules: rules.string.required('TitleName'),
        disabled: !(this.canUpdate || this.isNew),
        isRequired: true,
      });

      if (this.isNew || (this.canUpdate && this.canDelete)) {
        result.push({
          name: 'parent_id',
          label: 'Superior department',
          type: 'select',
          item_value: 'id',
          item_text: 'name',
          items: this.branchParentsList,
          isRequired: true,
        });
      } else {
        result.push({
          name: 'parent_name',
          label: 'Superior department',
          disabled: true,
        });
      }

      result.push({
        name: 'canSeeEachOther',
        label: 'Can see each other',
        type: 'checkbox',
        disabled: !this.isNew && !this.canUpdate,
      });

      return result;
    },

    gridFields() {
      return {
        full_name: {
          label: 'Employee',
          component: AccountName,
          sortable: true,
          defaultSort: true,
        },
        job_title: {
          label: 'Job title',
          sortable: true,
        },
        email: {
          label: 'E-Mail',
          sortable: true,
        },
        phone: {
          label: 'Phone',
          sortable: true,
        },
        actions: {
          label: 'Actions',
          sortable: false,
        },
      };
    },

    moveAppendText() {
      return get(this.branchData, 'notifications.reorderTripPoints', []).length ||
        get(this.branchData, 'notifications.goToFinish', []).length
        ? this.$t('common_strings.notifications_account_will_stop_coming')
        : '';
    },

    moveItemName() {
      return this.accountNameToMove
        ? this.$t('common_strings.account') + ' "' + this.accountNameToMove + '"'
        : this.$t('common_strings.this_account');
    },

    deleteAppendText() {
      return get(this.branchData, 'notifications.reorderTripPoints', []).length ||
        get(this.branchData, 'notifications.goToFinish', []).length
        ? this.$t('common_strings.notifications_accounts_will_stop_coming')
        : '';
    },

    deleteItemName() {
      return this.$t('common_strings.branch') + ' "' + this.$refs.form.value.data.name + '"';
    },

    deleteSupplementText() {
      return Array.isArray(this.accounts) && this.accounts.length
        ? this.$t('common_strings.accounts_moved_to_default_branch')
        : '';
    },

    apiFunction() {
      return async () => {
        return branch.getAccountsFromBranch(this.id, this.pagination);
      };
    },

    // use in watch
    canAccountsUpdate() {
      return Boolean(this.branchData.can_accounts_update && this.hasPermission('branches accounts update'));
    },

    reorderTripPoints: {
      get() {
        return this.notifications.emails.reorderTripPoints.join(', ');
      },

      set(value) {
        if (value) {
          this.notifications.emails.reorderTripPoints = value
            .split(',')
            .map(i => i.trim())
            .filter(Boolean);
        } else {
          this.notifications.emails.reorderTripPoints = [];
        }
      },
    },

    goToFinish: {
      get() {
        return this.notifications.emails.goToFinish.join(', ');
      },

      set(value) {
        if (value) {
          this.notifications.emails.goToFinish = value
            .split(',')
            .map(i => i.trim())
            .filter(Boolean);
        } else {
          this.notifications.emails.goToFinish = [];
        }
      },
    },

    emailsRules() {
      return [
        v => {
          const emails = v
            .split(',')
            .map(i => i.trim())
            .filter(Boolean);

          if (emails.length) {
            return (
              emails.every(email => REG_EXPS.email.test(email)) ||
              this.$t('Notification settings.Check that the entered mailing addresses are correct')
            );
          }

          return true;
        },
      ];
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),

    async fetchData() {
      if (!this.isNew) {
        await this.fetchBranchData();
        await this.fetchAccountsData();
      }

      if (this.isNew || (this.canUpdate && this.canDelete)) {
        await this.fetchBranchParents();
      }
    },

    async fetchBranchData() {
      const data = await branch.get(this.id);

      data.parent_name = data.parent && data.parent.name;

      this.$refs.form.setFormData(data);

      if (get(data, 'notifications.reorderTripPoints', []).length) {
        this.notifications.emails.reorderTripPoints = data.notifications.reorderTripPoints;
      }

      if (get(data, 'notifications.goToFinish', []).length) {
        this.notifications.emails.goToFinish = data.notifications.goToFinish;
      }

      this.branchData = data;
      this.accounts = data.accounts;
      this.canUpdate = data.can_update;
      this.canDelete = data.can_delete;
    },

    async fetchAccountsData() {
      try {
        this.availableAccountsList = await branch.accounts(this.id);

        this.accountsPickerData = [
          {
            id: -1,
            accounts: this.availableAccountsList,
          },
        ];
      } catch (e) {
        this.error = e;
      }
    },

    async fetchBranchParents() {
      try {
        let id;

        if (!this.isNew) {
          id = this.id;
        }

        this.branchParentsList = await branch.parents(id);

        if (this.branchData.parent_id !== undefined) {
          this.form.data.parent_id = this.branchData.parent_id;
        }
      } catch (err) {
        this.error = err;
      }
    },

    async save() {
      try {
        this.error = null;

        if (!this.isNew) {
          await this.updateBranch();
        } else {
          await this.createBranch();
        }
      } catch (err) {
        this.error = err;
      }
    },

    async createBranch() {
      try {
        const res = await branch.create({
          notifications: { ...this.notifications.emails },
          ...this.form.data,
        });

        if (res) {
          this.back();
        }
      } catch (err) {
        if (err.code === 'DuplicateError' && err.data.name) {
          this.error = new Error($t('error.DuplicateErrorElement', [err.data.name, $t('Department')]));
        } else {
          this.error = err;
        }
      }
    },

    async updateBranch() {
      try {
        const data = {
          name: this.form.data.name,
          canSeeEachOther: this.form.data.canSeeEachOther,
          notifications: {
            reorderTripPoints: this.notifications.emails.reorderTripPoints,
            goToFinish: this.notifications.emails.goToFinish,
          },
        };

        if (this.isNew || (this.canUpdate && this.canDelete)) {
          data.parent_id = this.form.data.parent_id !== 0 ? this.form.data.parent_id : null;
        }

        const res = await branch.update(this.id, data);

        if (res) {
          this.back();
        }
      } catch (err) {
        this.error = err;
      }
    },

    back() {
      this.$router.push('/branches');
    },

    openAddDialog() {
      this.addError = null;
      this.addDialog = true;
    },

    showMoveDialog(account) {
      this.moveDialog = true;
      this.accountIdToMove = account.id;
      this.accountNameToMove = account.full_name;
    },

    async handleAdd() {
      try {
        for (const account_id of this.addForm.account_ids) {
          await branch.addAccount(this.id, account_id);
        }

        await this.fetchData();

        this.addDialog = false;
        this.addForm = {
          account_ids: [],
        };

        await this.$refs.grid.refresh();
      } catch (err) {
        this.addError = err;
      }
    },

    async handleDeleteBranch() {
      try {
        await branch.remove(this.id);

        await this.$router.push('/branches');
      } catch (err) {
        this.deleteError = err;
      }
    },

    async moveAccountToBranch(newBranchId) {
      try {
        if (newBranchId) {
          await branch.moveAccountToBranch(this.id, {
            accountId: this.accountIdToMove,
            newBranchId,
          });
        }

        await this.fetchData();
        await this.$refs.grid.refresh();

        this.showSnackbar(this.$t('Account has been moved'));
        this.moveDialog = false;
      } catch (err) {
        this.moveAccountError = err;
      }
    },

    details(account, event) {
      const url = '/accounts/' + account.id;

      if (event && (event.metaKey || event.ctrlKey || event.shiftKey)) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },

    focusTextArea(id) {
      const el = document.getElementById(id);

      if (el) {
        setTimeout(() => {
          el.focus();
        }, 500);
      }
    },
  },

  watch: {
    canAccountsUpdate(newValue) {
      const el = document.getElementById('add_new_accounts');

      if (newValue) {
        el.style.display = 'block';
      } else {
        el.style.display = 'none';
      }
    },
  },
});
</script>

<style>
#notification-emails-input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#notification-emails-textarea-first,
#notification-emails-textarea-second {
  margin-top: 0px;
}
</style>
