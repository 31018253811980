<template>
  <v-dialog v-model="deleteDialog" :max-width="maxWidth">
    <v-card>
      <error-alert :value="deleteError" />

      <v-card-title class="headline">{{ deleteTitle || this.$t('Delete') }}?</v-card-title>

      <v-card-text>
        <span v-if="appendText">
          {{ appendText }}
        </span>

        <span v-if="deleteItem">
          {{ $t('common_strings.delete_query_item', [deleteItem]) }}
        </span>

        <span v-else>
          {{ $t('common_strings.delete_query') }}
        </span>

        <br />

        <span v-if="supplementText" style="color: green; font-size: 13px">
          {{ supplementText }}
        </span>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn flat @click.native="hideModal">
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn color="pink darken-2" flat @click.native="handleDelete" :disabled="Boolean(deleteError)">
          {{ $t('Delete') }}
        </v-btn>
      </v-card-actions>

      <v-progress-linear :indeterminate="true" :height="2" :active="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',

  data() {
    return {
      deleteDialog: true,
    };
  },

  props: {
    maxWidth: {
      type: String,
      default: '320',
    },
    deleteError: {
      type: Error,
      default: null,
    },
    deleteTitle: {
      type: String,
      default: null,
    },
    deleteItem: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    supplementText: String,
    appendText: String,
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    handleDelete() {
      this.$emit('delete');
    },
  },

  watch: {
    deleteDialog() {
      if (!this.deleteDialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
