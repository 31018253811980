<template>
  <v-dialog v-model="showDialog" scrollable max-width="600">
    <v-card>
      <error-alert :value="error" />

      <v-card-title>
        {{ $t('import.rules') }}

        <v-spacer />

        <v-btn icon small class="ma-0" @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <TripImportRules />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <upload-btn
          :title="$t('import.title')"
          :loading="true"
          :disabled="importInProgress"
          :accept="'.xls,.xlsx,.xlsm'"
          :file-changed-callback="readImportFile"
          ref="uploadButton"
        />

        <xls-template />
      </v-card-actions>

      <v-progress-linear v-if="importInProgress" :indeterminate="true" />
    </v-card>
  </v-dialog>
</template>

<script>
/* global $ */
import Vue from 'vue';
import api from '@/api/tripNew';
import UploadButton from '../../../vendor/vuetify-upload-button/UploadButton';
import TripImportRules from './TripImportRules';
import XlsTemplate from './XlsTemplate';

export default Vue.extend({
  components: {
    XlsTemplate,
    TripImportRules,
    'upload-btn': UploadButton,
  },

  props: {
    trip: Object,
    isCreating: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      error: null,
      importInProgress: false,
      importResult: null,
      showDialog: true,
    };
  },

  methods: {
    closeDialog() {
      this.$emit('close');
    },

    async saveImportAndUpdateGrid(data) {
      try {
        const result = await api.importRoute(data, {
          executorId: this.trip.executorId,
          responsibleId: this.trip.responsibleId,
          title: this.trip.title,
          plannedAt: this.trip.plannedAt,
          optimize: this.trip.way.optimize,
          transportType: this.trip.settings.transportType,
          transportId: this.trip.transportId,
          isCreating: this.isCreating,
        });

        if (data) {
          this.$emit('read-file', data);
        }

        this.importResult = result;
        this.$emit('update:result', result);
        this.importInProgress = false;
        this.$emit('close');
      } catch (error) {
        this.importInProgress = false;
        this.error = error;
      }
    },

    async readImportFile(file) {
      this.error = null;
      this.importInProgress = true;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const str = reader.result.split(';');
        const data = str[1].replace(new RegExp('base64,', 'g'), '');
        await this.saveImportAndUpdateGrid(data);
      };

      const $input = $('input[type=file]', this.$refs.uploadButton.$el);

      if (/MSIE/.test(navigator.userAgent)) {
        $input.replaceWith($input.clone(true));
      } else {
        $input.val('');
      }
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
</script>
