<template>
  <v-dialog v-model="isOpen" persistent max-width="400">
    <v-card class="review-form">
      <v-card-text>
        <v-layout class="mb-2" justify-space-between>
          <h2 class="title mb-0">
            {{ review ? $t('review.title.complete') : $t('review.title.new') }}
          </h2>
          <v-icon :disabled="isSend" @click="close">
            mdi-close
          </v-icon>
        </v-layout>
        <template v-if="!review || canCreateOrUpdate">
          <error-alert :value="error" />
          <v-rating
            v-model="form.rating"
            background-color="grey lighten-2"
            color="warning"
            hover
            length="5"
            size="40"
          />
          <div class="body-2 mb-1">{{ $t('review.field.comment') }}:</div>
          <v-textarea
            v-model="form.comment"
            class="mt-1"
            background-color="grey lighten-2"
            :placeholder="$t('review.placeholder.comment')"
            solo
            flat
            no-resize
            maxlength="500"
            counter
          >
          </v-textarea>
          <v-btn
            class="ma-0 mt-3"
            :color="review ? 'success' : 'primary'"
            :disabled="!form.rating"
            :loading="isSend"
            @click="send()"
          >
            {{ review ? $t('review.button.save') : $t('review.button.send') }}
          </v-btn>
        </template>
        <template v-else>
          <v-rating
            v-model="review.rating"
            class="flat"
            background-color="grey lighten-2"
            color="warning"
            hover
            length="5"
            size="40"
            :readonly="true"
          />
          <div v-if="review.comment" class="body-1 mt-1">{{ review.comment }}</div>
          <v-btn class="ma-0 mt-3" color="primary" @click="close()">
            {{ $t('review.button.close') }}
          </v-btn>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api/reviews';
import Review from '@/models/Review';

export default {
  name: 'ReviewForm',
  props: {
    trip: {
      type: Object,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    review: {
      type: Object,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    onComplete: {
      type: Function,
      required: true,
    },
  },
  computed: {
    canCreateOrUpdate() {
      return !this.trip.finishedAt || Date.now() - new Date(this.trip.finishedAt).getTime() < 24 * 60 * 60 * 1000;
    },
  },
  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        if (this.review) {
          this.form.rating = this.review.rating;
          this.form.comment = this.review.comment;
        }
      } else {
        this.form.rating = 0;
        this.form.comment = null;
      }
    },
  },
  data() {
    return {
      error: null,
      isSend: false,
      form: {
        rating: 0,
        comment: null,
      },
    };
  },
  mounted() {
    if (this.review) {
      this.form.rating = this.review.rating;
      this.form.comment = this.review.comment;
    }
  },
  methods: {
    async send() {
      this.error = null;
      this.isSend = true;

      try {
        let data = null;
        if (this.review?.id) {
          data = await api.update(this.code, this.review.id, this.form);
        } else {
          data = await api.create(this.code, this.form);
        }

        const review = new Review(data);

        this.isSend = false;

        this.onComplete(review);

        this.close();
      } catch (error) {
        this.error = error;
        this.isSend = false;
      }
    },
    close() {
      this.$emit('update:isOpen', false);
    },
  },
};
</script>
