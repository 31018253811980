
import Vue from 'vue';
export default Vue.extend({
  name: 'RestorePasswordNotification',
  data() {
    return {
      showDialog: true,
    };
  },
  props: {
    email: {
      type: String,
    },
    phone: {
      type: String,
    },
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },
    successMessage() {
      if (this.email) {
        return this.$t('Link to change password was sent to email', [this.email]);
      } else {
        return this.$t('Link to change password was sent to phone', [this.phone]);
      }
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
