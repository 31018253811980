
import Vue from 'vue';
import rules from '@/api/rules';

export default Vue.extend({
  name: 'ZoneTimeDialog',

  props: {
    title: {
      type: String,
    },
  },

  data() {
    return {
      showDialog: true,
      internalName: null as string | null,
    };
  },

  created() {
    this.internalName = this.title;
  },

  computed: {
    internalNameRules() {
      return rules.required('Title');
    },
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    save() {
      this.$emit('change', this.internalName);
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
