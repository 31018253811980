const configBiz = {
  logo: '/static/logo/HomeStretchBusiness.png',
  logoColor: '#1974d0',
  favicon: '/static/favicon/FaviconBusiness.png',
  appstoreLink: 'itms-apps://itunes.apple.com/app/homestretch/id1278862254',
  googleplayLink: 'https://play.google.com/store/apps/details?id=ch.homestretch.hsbiz',
};

export default {
  namespaced: true,

  state: {
    isCompanySite: window.ENTRY_POINT !== 'register',
    windowInnerWidth: 600,
    windowInnerHeight: 600,
    footerHeight: 75,
    showBackButton: false,
    hasHelpAvailable: false,
    showHelp: false,
    firstTimeGuide: false,
    firstTimeGuideCurrentPage: null,
    snackbar: false,
    snackbarText: '',
    snackbarColor: '',
    unloadListener: null,
  },

  mutations: {
    setWindowInnerWidth(state, data) {
      state.windowInnerWidth = data;
    },

    setWindowInnerHeight(state, data) {
      state.windowInnerHeight = data;
    },

    setFooterHeight(state, data) {
      state.footerHeight = data;
    },

    showBackButton(state) {
      state.showBackButton = true;
    },

    hideBackButton(state) {
      state.showBackButton = false;
    },

    showHelp(state) {
      state.showHelp = true;
    },

    hideHelp(state) {
      state.showHelp = false;
    },

    startFirstTimeGuide(state) {
      state.showHelp = true;
      state.firstTimeGuide = true;
      state.firstTimeGuideCurrentPage = 0;
    },

    setFirstTimeGuidePage(state, data) {
      state.firstTimeGuideCurrentPage = data;
    },

    finishFirstTimeGuide(state) {
      state.showHelp = false;
      state.firstTimeGuide = false;
    },

    setHasHelpAvailable(state, data) {
      state.hasHelpAvailable = data;
    },

    showSnackbar(state, data) {
      /**
       *  Use setSnackbarData function
       */
      setSnackbarData(state, typeof data === 'string' ? { text: data } : data);
    },

    showErrorSnackbar(state, text) {
      /**
       *  Use setSnackbarData function
       */
      setSnackbarData(state, { text, color: 'error' });
    },

    hideSnackbar(state) {
      state.snackbar = false;
      state.snackbarText = '';
      state.snackbarColor = '';
    },

    setUnloadListener(state, f) {
      window.addEventListener('beforeunload', f);
      state.unloadListener = f;
    },

    clearUnloadListener(state) {
      window.removeEventListener('beforeunload', state.unloadListener);
      state.unloadListener = null;
    },
  },

  getters: {
    favicon(state) {
      return configBiz.favicon;
    },

    logo(state) {
      return configBiz.logo;
    },

    logoColor(state) {
      return configBiz.logoColor;
    },

    appstoreLink(state) {
      return configBiz.appstoreLink;
    },

    googleplayLink(state) {
      return configBiz.googleplayLink;
    },
  },
};

/**
 *  Set snackbar data in vuex state
 *
 * @param {Object} state - vuex state
 * @param {String} text - snackbar text
 * @param {String} color - snackbar background color
 */
function setSnackbarData(state, { text, color }) {
  if (text) {
    state.snackbar = true;
    state.snackbarText = text;
    state.snackbarColor = color || '';
  } else {
    state.snackbar = false;
    state.snackbarText = '';
  }
}
