
import Vue, { VNode } from 'vue';
import ResourceImport from './ResourceImport.vue';
import { importOrders } from '@/api/import';

export default Vue.extend({
  functional: true,

  render(h, { parent, listeners }): VNode {
    return h(ResourceImport, {
      props: {
        title: parent.$t('Orders import'),
        url: '/orders',
        api: importOrders,
      },
      on: listeners,
    });
  },
});
