import Uuid from '@/models/Uuid';

export interface ReviewDto {
  id: Uuid;
  tripId: number;
  tripPointId: number;
  rating: number;
  comment: string | null;
}

export default class Review {
  id: Uuid;
  tripId: number;
  tripPointId: number;
  rating: number;
  comment: string | null;

  constructor(data: ReviewDto) {
    this.id = data.id;
    this.tripId = data.tripId;
    this.tripPointId = data.tripPointId;
    this.rating = data.rating;
    this.comment = data.comment;
  }

  toJSON(): ReviewDto {
    return {
      id: this.id,
      tripId: this.tripId,
      tripPointId: this.tripPointId,
      rating: this.rating,
      comment: this.comment,
    };
  }
}
