
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { MetaInfo } from 'vue-meta';
import { sleep } from '@/lib/util';

import { isEqual } from 'lodash';

import branch from '@/api/branch';
import tripsApi, { TripsResponse } from '@/api/tripNew';

import Uuid from '@/models/Uuid';
import { TripDto, TripStatus } from '@/models/Trip';

import TripsGrid from '@/components/trip/TripsGrid.vue';
import TripsFilter from '@/components/filters/TripsFilter.vue';
import TripsFilterDto from '@/components/filters/TripsFilterDto';
import ShareTripsDialog from '@/components/modals/ShareTripsDialog.vue';
import DeleteDialog from '@/components/modals/DeleteDialog.vue';
import RestoreDialog from '@/components/modals/RestoreDialog.vue';

import { AUTORELOAD_TIME } from '@/const';

export default Vue.extend({
  name: 'Trips',

  metaInfo(): MetaInfo {
    return {
      title: String(this.$i18n.t('Trips')),
    };
  },

  components: {
    TripsGrid,
    TripsFilter,
    ShareTripsDialog,
    DeleteDialog,
    RestoreDialog,
  },

  data() {
    return {
      data: {
        results: [],
        total: null as number | null,
        aggregations: {
          duration: { value: null as number | null },
          passedWayDistance: { value: null as number | null },
          scheduledCount: { value: null as number | null },
          totalAtClients: { value: null as number | null },
          totalAtNonClients: { value: null as number | null },
          totalInTransit: { value: null as number | null },
        },
      },
      tripIds: [] as string[],
      pagination: {} as {
        page: number;
        rowsPerPage: number;
        sortBy: string;
        descending: boolean;
      },
      filter: new TripsFilterDto(),
      showFilter: false,
      showShareTripsDialog: false,
      timer: null as number | null,

      selected: [] as string[],
      statuses: new Map(),

      multiDeleteDialog: false,
      multiDeleteLoading: false,
      multiDeleteError: null,

      multiRestoreDialog: false,
      multiRestoreLoading: false,
      multiRestoreError: null,

      error: null,
    };
  },

  async created(): Promise<void> {
    this.parseURL();

    await this.getMyBranchList();

    this.timer = setInterval(() => {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    }, AUTORELOAD_TIME);
  },

  beforeDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),

    canDeleteTrips(): boolean {
      return Boolean(this.selected.every((id: string) => this.statuses.get(id) !== TripStatus.deleted));
    },

    canRestoreTrips(): boolean {
      return Boolean(this.selected.every((id: string) => this.statuses.get(id) === TripStatus.deleted));
    },
  },

  methods: {
    filterTrips() {
      if (typeof localStorage !== 'undefined') {
        const filter = localStorage.getItem('filterTrips') || '{}';
        return JSON.parse(filter);
      }
    },

    async getMyBranchList(options?: { inactive: boolean }) {
      await branch.listMy(options);
    },

    routerReplace() {
      const query = this.makeQuery();

      if (!isEqual(this.$route.query, query)) {
        this.$router.replace({ query });
      }
    },

    parseURL() {
      if (Object.keys(this.$route.query).length) {
        this.filterUpdate(new TripsFilterDto().fromQuery(this.$route.query));
      } else if (![null, undefined, ''].includes(this.filterTrips())) {
        this.filter = new TripsFilterDto(this.filterTrips());
      }

      this.showFullFilter(this.filter);

      if (this.$route.query.page) {
        this.pagination.page = parseInt(this.$route.query.page as string) + 1;
      }

      if (this.$route.query.pageSize) {
        this.pagination.rowsPerPage = parseInt(this.$route.query.pageSize as string);
      }

      if (this.$route.query.sort) {
        this.pagination.sortBy = String(this.$route.query.sort);
      }

      if (this.$route.query.sortDesc) {
        this.pagination.descending = this.$route.query.sortDesc === '1';
      }
    },

    makeQuery() {
      return {
        ...(this.$refs.grid as Vue & { makeQuery: () => any }).makeQuery(),
        ...this.filter.toQueryForCheck(),
      };
    },

    filterUpdate(value: TripsFilterDto) {
      localStorage.setItem('filterTrips', JSON.stringify(value));
      this.filter = value;
    },

    reloadClick() {
      (this.$refs.grid as Vue & { refresh: () => void }).refresh();
    },

    showFullFilter(query: any) {
      this.showFilter = Boolean(
        query.branches?.branches?.length ||
          query.branches?.accounts?.length ||
          query?.events?.length ||
          query.inactive ||
          query?.responsible?.length,
      );
    },

    details(item: any, event: MouseEvent): void {
      const url = this.$route.path + '/' + item.dbId;

      if (event && (event.metaKey || event.ctrlKey || event.shiftKey)) {
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },

    openShareTripsDialog(): void {
      this.showShareTripsDialog = true;
    },

    closeShareTripsDialog(): void {
      this.showShareTripsDialog = false;
    },

    tripsUpdate(data: TripsResponse) {
      this.tripIds = data.result.map((trip: TripDto) => trip.id);
    },

    async selectAllTrips(value?: boolean) {
      if (!value) {
        return;
      }

      this.selected = this.tripIds;
    },

    showMultiDeleteDialog() {
      this.multiDeleteError = null;
      this.multiDeleteDialog = true;
    },

    showMultiRestoreDialog() {
      this.multiRestoreError = null;
      this.multiRestoreDialog = true;
    },

    disableElementById(value: boolean, id: Uuid) {
      const el = document.getElementById(id);

      if (value) {
        el?.classList.remove('v-list__tile--disabled');
        el?.parentElement?.classList.remove('v-list--disabled');
      } else {
        el?.classList.add('v-list__tile--disabled');
        el?.parentElement?.classList.add('v-list--disabled');
      }
    },

    async multiHandleDelete() {
      try {
        this.multiDeleteError = null;

        if (this.selected.length) {
          await tripsApi.deleteTrips(this.selected);
        }

        this.multiDeleteLoading = true;

        await sleep(this.selected.length * 1000);

        (this.$refs.grid as Vue & { refresh: () => void }).refresh();

        this.multiDeleteDialog = false;
      } catch (err) {
        this.multiDeleteError = err;
      } finally {
        this.multiDeleteLoading = false;
        this.selected = [];
      }
    },

    async multiHandleRestore() {
      try {
        this.multiRestoreError = null;

        if (this.selected.length) {
          await tripsApi.restoreTrips(this.selected);
        }

        this.multiRestoreLoading = true;

        await sleep(this.selected.length * 1000);

        (this.$refs.grid as Vue & { refresh: () => void }).refresh();

        this.multiRestoreDialog = false;
      } catch (err) {
        this.multiRestoreError = err;
      } finally {
        this.multiRestoreLoading = false;
        this.selected = [];
      }
    },
  },

  watch: {
    filter: {
      handler: async function(n, o) {
        await this.routerReplace();

        // show trips of inactive accounts
        if (n && o && n.inactive !== o.inactive) {
          await this.getMyBranchList({ inactive: Boolean(n.inactive) });
        }
      },
      deep: true,
    },

    pagination: {
      handler: async function(n, o) {
        await this.routerReplace();

        // move to another page in the table
        if (n && o && n.page !== o.page) {
          this.selected = [];
        }
      },
      deep: true,
    },

    tripIds() {
      if (this.selected.length) {
        this.selected = this.tripIds.filter((id: string) => this.selected.includes(id));
      }
    },

    canDeleteTrips(newValue) {
      this.disableElementById(newValue, 'delete-trips-tile');
    },

    canRestoreTrips(newValue) {
      this.disableElementById(newValue, 'restore-trips-tile');
    },
  },
});
