<template>
  <v-container>
    <v-checkbox label="Single" v-model="single" />
    <v-select :items="items" v-model="cls" />

    <component :is="cls" :items="myBranches" v-model="value" :single="single" />

    <div>value= {{ value ? value : Object.prototype.toString.call(value) }}</div>
    <div>items= {{ myBranches ? myBranches : Object.prototype.toString.call(myBranches) }}</div>
  </v-container>
</template>

<script>
import branch from '@/api/branch';
import AccountPicker from '@/components/pickers/AccountPicker';
import BranchAccountPicker from '@/components/pickers/BranchAccountPicker';
import BranchPicker from '@/components/pickers/BranchPicker';

export default {
  components: {
    /* eslint-disable vue/no-unused-components */
    BranchAccountPicker,
    BranchPicker,
    AccountPicker,
  },

  data() {
    return {
      items: ['BranchAccountPicker', 'BranchPicker', 'AccountPicker'],
      cls: 'BranchAccountPicker',
      myBranches: null,
      single: false,
      value: undefined,
    };
  },

  async created() {
    this.myBranches = await branch.listMy();
  },

  watch: {
    async cls() {
      this.value = undefined;
      if (this.cls === 'BranchPicker') {
        this.myBranches = await branch.listMy({
          accounts: false,
        });
      } else {
        this.myBranches = await branch.listMy();
      }
    },
  },
};
</script>
