
import Vue from 'vue';
import apiAccount from '@/api/account';
import apiRoles from '@/api/roles';
import { Role } from '@/models/Role';

export default Vue.extend({
  name: 'ChangeRolesForUser',

  data() {
    return {
      showDialog: true,
      error: null as Error | null,
      isValid: false,
      isLoading: false,
      isSending: false,
      roles: [] as Array<Role>,
      form: {
        role: [],
      },
    };
  },

  props: {
    account: {
      type: Object,
    },
  },

  computed: {},

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },

  methods: {
    async loadRoles(): Promise<void> {
      this.isLoading = true;

      try {
        const data = await apiRoles.getRoles({});

        this.roles = data.result;
      } catch (e) {
        this.error = e;
      } finally {
        this.isLoading = false;
      }
    },

    async save() {
      try {
        this.error = null;
        this.isSending = true;

        await apiAccount.updateRole(this.account.id, this.form.role);

        this.account.roleId = this.form.role;

        this.$emit('close', String(this.$t('Role changed successfully')));
        this.$emit('roleChanged', this.roles.find(r => String(r.id) === String(this.form.role))?.name || '');
      } catch (e) {
        this.error = e;
      } finally {
        this.isSending = false;
      }
    },

    hideModal() {
      this.$emit('close');
    },
  },

  async created() {
    await this.loadRoles();

    this.form.role = this.account.roleId;
  },
});
