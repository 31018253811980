import http from './httpV2';
import store from '@/store';
import { Company, CompanySettings } from '@/models/Company';

const path = '/company';

async function update(data: Company) {
  const res = await http.patch(path, data);
  store.commit('company/setCompanyData', res.data);
  return res.data;
}

async function updateSettings(data: CompanySettings) {
  const res = await http.patch(`${path}/settings`, data);
  store.commit('company/setCompanyData', {
    settings: res.data,
  });
  return res.data;
}

async function get() {
  const res = await http.get<Company>(path);
  store.commit('company/setCompanyData', res.data);
  return res.data;
}

export default {
  update,
  updateSettings,
  get,
};
