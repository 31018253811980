import c from '@/lib/coerce';
import { Dictionary } from 'vue-router/types/router';

class PointsFilterDto {
  date = {
    from: null as string | null,
    to: null as string | null,
  };

  branches = {
    branches: [] as string[],
    accounts: [] as string[],
  };

  responsible = [] as string[];

  contractor = [] as string[];

  inactive = false;
  status: string[] = [];
  demandType: string[] = [];
  orderPaymentStatus: string[] = [];
  tags: string[] = [];
  functions: string[] = [];

  search: string | null = null;

  missDeliveryWindows = {
    from: null as number | null,
    to: null as number | null,
  };

  downtime = {
    from: null as number | null,
    to: null as number | null,
  };

  constructor(data?: Partial<PointsFilterDto>) {
    Object.assign(this, data);
  }

  fromQuery(query: Dictionary<string | (string | null)[]>) {
    const data = {
      date: {
        from: query.dateFrom,
        to: query.dateTo,
      },
      branches: {
        branches: c(query.branches)
          .array()
          .nullStr()
          .int()
          .coerce(),
        accounts: c(query.accounts)
          .array()
          .int()
          .coerce(),
      },
      responsible: c(query.responsible)
        .array()
        .int()
        .coerce(),
      contractor: c(query.contractor)
        .array()
        .coerce(),
      inactive: c(query.inactive)
        .boolean()
        .coerce(),
      status: c(query.status)
        .array()
        .coerce(),
      demandType: c(query.demandType)
        .array()
        .coerce(),
      orderPaymentStatus: c(query.orderPaymentStatus)
        .array()
        .coerce(),
      tags: c(query.tags)
        .array()
        .coerce(),
      functions: c(query.functions)
        .array()
        .coerce(),
      search: query.search,
      missDeliveryWindows: {
        from: c(query.missDeliveryWindowsFrom)
          .int()
          .coerce(),
        to: c(query.missDeliveryWindowsTo)
          .int()
          .coerce(),
      },
      downtime: {
        from: c(query.downtimeFrom)
          .int()
          .coerce(),
        to: c(query.downtimeTo)
          .int()
          .coerce(),
      },
    };

    Object.assign(this, data);

    return this;
  }

  toQuery(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      dateFrom: typeof this.date.from === 'string' ? new Date(this.date.from).toISOString() : null,
      dateTo: typeof this.date.to === 'string' ? new Date(this.date.to).toISOString() : null,
      branches: this.branches.branches.map(v => (v === null ? 'null' : `${v}`)),
      accounts: this.branches.accounts.map(v => `${v}`),
      responsible: this.responsible.map(v => `${v}`),
      contractor: this.contractor.map(v => `${v}`),
      inactive: this.inactive ? 'true' : null,
      status: this.status.map(v => `${v}`),
      demandType: this.demandType.map(v => `${v}`),
      orderPaymentStatus: this.orderPaymentStatus.map(v => `${v}`),
      tags: this.tags.map(v => `${v}`),
      functions: this.functions.map(v => `${v}`),
      search: this.search,
      missDeliveryWindowsFrom:
        typeof this.missDeliveryWindows.from === 'number' ? `${this.missDeliveryWindows.from}` : null,
      missDeliveryWindowsTo: typeof this.missDeliveryWindows.to === 'number' ? `${this.missDeliveryWindows.to}` : null,
      downtimeFrom: typeof this.downtime.from === 'number' ? `${this.downtime.from}` : null,
      downtimeTo: typeof this.downtime.to === 'number' ? `${this.downtime.to}` : null,
    };

    return this.queryWithoutEmptyValuesForPoints(query);
  }

  queryWithoutEmptyValuesForPoints(query: Dictionary<string | string[] | null>): Dictionary<string | string[]> {
    for (const i in query) {
      if (query[i] === null || query[i] === undefined || (query[i] instanceof Array && query[i]?.length === 0)) {
        delete query[i];
      }
    }

    return query as Dictionary<string | string[]>;
  }

  toQueryForCheck(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      dateFrom: typeof this.date.from === 'string' ? new Date(this.date.from).toISOString() : null,
      dateTo: typeof this.date.to === 'string' ? new Date(this.date.to).toISOString() : null,
      branches:
        this.branches.branches.length === 1
          ? String(this.branches.branches[0])
          : this.branches.branches.map(v => (v === null ? 'null' : `${v}`)),
      accounts:
        this.branches.accounts.length === 1
          ? String(this.branches.accounts[0])
          : this.branches.accounts.map(v => `${v}`),
      responsible: this.responsible.length === 1 ? String(this.responsible[0]) : this.responsible.map(v => `${v}`),
      contractor: this.contractor.map(v => `${v}`),
      inactive: this.inactive ? 'true' : null,
      status: this.status.length === 1 ? String(this.status[0]) : this.status.map(v => `${v}`),
      demandType: this.demandType.map(v => `${v}`),
      orderPaymentStatus: this.orderPaymentStatus.map(v => `${v}`),
      tags: this.tags.map(v => `${v}`),
      functions: this.functions.map(v => `${v}`),
      search: this.search,
      missDeliveryWindowsFrom:
        typeof this.missDeliveryWindows.from === 'number' ? `${this.missDeliveryWindows.from}` : null,
      missDeliveryWindowsTo: typeof this.missDeliveryWindows.to === 'number' ? `${this.missDeliveryWindows.to}` : null,
      downtimeFrom: typeof this.downtime.from === 'number' ? `${this.downtime.from}` : null,
      downtimeTo: typeof this.downtime.to === 'number' ? `${this.downtime.to}` : null,
    };

    return this.queryWithoutEmptyValuesForPoints(query);
  }

  toHttpParams() {
    const query = this.toQuery();

    typeof query.missDeliveryWindowsFrom === 'string' &&
      (query.missDeliveryWindowsFrom = `${parseInt(query.missDeliveryWindowsFrom) * 60}`);
    typeof query.missDeliveryWindowsTo === 'string' &&
      (query.missDeliveryWindowsTo = `${parseInt(query.missDeliveryWindowsTo) * 60}`);
    typeof query.downtimeFrom === 'string' && (query.downtimeFrom = `${parseInt(query.downtimeFrom) * 60}`);
    typeof query.downtimeTo === 'string' && (query.downtimeTo = `${parseInt(query.downtimeTo) * 60}`);

    query['tripPoint.status'] = query.status;
    delete query.status;

    query['demand.type'] = query.demandType;
    delete query.demandType;

    query['order.paymentStatus'] = query.orderPaymentStatus;
    delete query.orderPaymentStatus;

    query['demand.tagIds'] = query.tags;
    delete query.tags;

    if (typeof query.dateFrom === 'string') {
      query.dateFrom = new Date(query.dateFrom).toISOString();
    } else {
      const dateFrom = new Date();

      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
      dateFrom.setMilliseconds(0);

      query.dateFrom = dateFrom.toISOString();
    }

    if (typeof query.dateTo === 'string') {
      query.dateTo = new Date(query.dateTo).toISOString();
    } else {
      const dateTo = new Date();

      dateTo.setDate(dateTo.getDate() + 1);
      dateTo.setHours(0);
      dateTo.setMinutes(0);
      dateTo.setSeconds(0);
      dateTo.setMilliseconds(0);

      query.dateTo = dateTo.toISOString();
    }

    return query;
  }

  toQueryStringParams() {
    const data = this.toHttpParams();

    if (Object.keys(data).length) {
      let queryString = new URLSearchParams();
      for (const [fieldName, value] of Object.entries(data)) {
        if (Array.isArray(value) && value.length) {
          for (const item of value) {
            queryString.append(fieldName + '[]', item);
          }
        }

        if (['boolean', 'string', 'number'].includes(typeof value)) {
          queryString.append(fieldName, String(value));
        }
      }

      return queryString.toString();
    }

    return '';
  }
}

export default PointsFilterDto;
