import Sortable from 'sortablejs';

function index(el, selector) {
  let index = 0;

  if (!el || !el.parentNode) {
    return -1;
  }

  while (el && (el = el.previousElementSibling)) {
    if (el.nodeName.toUpperCase() !== 'TEMPLATE' && el.matches(selector)) {
      index++;
    }
  }

  return index;
}

const GridSortable = {
  bind(el, binding, vnode) {
    const sortableElement = el.getElementsByTagName('tbody')[0];

    let oldIndex, newIndex;
    let sortable;
    let dragEl, expandRow;

    const options = {
      handle: '.sortHandle',
      filter: '.nonSortable',
      draggable: 'tr:not(.v-datatable__expand-row)',
      animation: 150,
      onStart: function(event) {
        dragEl = event.item;
        oldIndex = index(event.item, this.options.draggable);
        expandRow = null;
        const nextEl = event.item.nextElementSibling;
        if (nextEl && nextEl.matches('.v-datatable__expand-row')) {
          expandRow = nextEl;
        } else {
          expandRow = null;
        }
        Sortable.utils.toggleClass(sortableElement, sortable.options.dragClass, true);
      },
      onEnd: function(event) {
        Sortable.utils.toggleClass(sortableElement, sortable.options.dragClass, false);

        // onUpdate() is not called because expand row is moved with el
        // So we must emit sorted event from onEnd()
        vnode.child.$emit('sorted', { oldIndex, newIndex });
      },
      onMove: function(event) {
        if (binding.value.isMoveAllowed) {
          const _newIndex = index(event.related, this.options.draggable);
          const res = binding.value.isMoveAllowed(_newIndex, oldIndex);
          if (res) {
            newIndex = _newIndex;
          }
          return res;
        }
      },
      onChange: function(event) {
        if (dragEl.nextElementSibling && dragEl.nextElementSibling.matches('.v-datatable__expand-row')) {
          event.item.parentNode.insertBefore(dragEl.nextElementSibling, event.item.nextElementSibling);
        }
        if (expandRow) {
          event.item.parentNode.insertBefore(expandRow, dragEl.nextElementSibling);
        }
      },
    };

    sortable = Sortable.create(sortableElement, options);
    vnode.context.sortable = sortable;
  },

  unbind(el, binding, vnode) {
    vnode.context.sortable.destroy();
  },
};

export default GridSortable;
