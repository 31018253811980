import http from './httpV2';
import Uuid from '@/models/Uuid';

const path = '/dashboard';

interface DashboardLinkResponce {
  link: string;
}

async function createLinkToDashboard(id: Uuid): Promise<DashboardLinkResponce> {
  const res = await http.post(`${path}/link`, {
    contractorId: id,
  });
  return res.data;
}

async function getDashboard(code: Uuid): Promise<Array<any>> {
  const res = await http.get(`${path}/${code}`);
  return res.data;
}

export default {
  createLinkToDashboard,
  getDashboard,
};
