import BaseInfoWindow from './BaseInfoWindow';
import { CreateElement, VNode } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { MapPointData } from '@/services/TripPageService';
import TripPoint from '@/models/TripPoint';
import CustomHorizontalList from '@/components/list/CustomHorizontalList.tsx';
import { PlaceIcon } from '@/components/icons';

const mapState = namespace('map');

@Component
export default class PointInfoWindow extends mixins(BaseInfoWindow) {
  @mapState.State('currentPoint') readonly currentPoint!: MapPointData | null;

  render(h: CreateElement): VNode {
    const point = this.currentPoint;
    const tripPoint = point?.tripPoint;

    if (!point || !tripPoint) {
      return h();
    }

    return (
      <div class="map-marker-tooltip">
        <div class="map-marker-tooltip-sizer" />
        <div>
          {this.genPointName(tripPoint)}
          {this.genOrderNumber(tripPoint)}
          {this.genAddress(tripPoint)}
        </div>
      </div>
    );
  }

  genPointName(tripPoint: TripPoint) {
    if (tripPoint.placeLink?.placeId) {
      return (
        <div>
          <strong>{this.$t('Point name')}: </strong>
          <PlaceIcon />
          <em>{tripPoint.placeLink.title}</em>
        </div>
      );
    } else if (tripPoint.title) {
      return (
        <div>
          <strong>{this.$t('Point name')}: </strong>
          <em>{tripPoint.title}</em>
        </div>
      );
    }
  }

  genOrderNumber(tripPoint: TripPoint) {
    if (Array.isArray(tripPoint.orders) && tripPoint.orders.length) {
      const orderNumbers = [];

      for (const order of tripPoint.orders) {
        if ('number' in order && order.number) {
          orderNumbers.push(order.number);
        }
      }

      // @ts-ignore
      return <CustomHorizontalList label={this.$t('Order number') as string} items={orderNumbers as string[]} />;
    }
  }

  genAddress(tripPoint: TripPoint) {
    if (tripPoint.location && Array.isArray(tripPoint.location.addresses) && tripPoint.location.addresses.length) {
      return (
        <div>
          <strong>{this.$t('Address')}: </strong>
          <em>{tripPoint.location.addresses[0].address}</em>
        </div>
      );
    }
  }
}
