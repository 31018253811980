import Vue, { CreateElement, VNode } from 'vue';
import uuid from 'uuid';

import { Checkin, CheckinStatus } from '../../../models/TripPoint';

import TimeIntervalWithOptionalDay from '../../TimeIntervalWithOptionalDay';

import DateRangeDialog from '@/components/modals/DateRangeDialog.vue';

interface Dates {
  from: Date;
  to: Date;
}

export default Vue.extend({
  name: 'Checkpoints',

  props: {
    checkins: {
      type: Array as () => Array<Checkin>,
      default() {
        return [];
      },
    },
    showDate: Boolean,
    showTimezones: Boolean,
    inOneLine: Boolean,
    timezoneOffset: Number,
    status: String,
    invalid: Boolean,
    canEdit: {
      type: Boolean,
      default: false,
    },
    tripStartedAt: Date,
  },

  data() {
    return {
      showDialog: false,
    };
  },

  methods: {
    genContent(h: CreateElement, checkin: Checkin | null) {
      return h('v-layout', {}, [
        this.genDate(h, checkin),
        this.canEdit && this.genBtn(h),
        this.showDialog && this.genDateChangeDialog(h, checkin),
      ]);
    },

    genBtn(h: CreateElement) {
      return h('v-flex', { class: { 'mr-4': true } }, [
        h(
          'v-btn',
          {
            attrs: { icon: true },
            class: { 'ma-0': true },
            style: { width: '21px', height: '21px' },
            on: {
              click: () => {
                this.showDialog = true;
              },
            },
          },
          [h('v-icon', { attrs: { size: '18' } }, 'edit')],
        ),
      ]);
    },

    genDate(h: CreateElement, checkin: Checkin | null) {
      return h('v-flex', { class: { 'mr-2': true, shrink: true } }, [
        checkin
          ? h(TimeIntervalWithOptionalDay, {
              props: {
                from: checkin.inDate,
                to: checkin.outDate,
                showDate: this.showDate,
                showTimezones: this.showTimezones,
                inOneLine: this.inOneLine,
                timezoneOffset: this.timezoneOffset,
                status: this.status,
                invalid: this.invalid,
              },
            })
          : this.canEdit && '\u2014',
      ]);
    },

    genDateChangeDialog(h: CreateElement, checkin: Checkin | null) {
      let props = {};

      if (checkin) {
        props = {
          value: {
            from: checkin.inDate,
            to: checkin.outDate,
          },
        };
      }

      return h(DateRangeDialog, {
        props: {
          ...props,
          minDate: this.tripStartedAt,
        },
        on: {
          close: () => (this.showDialog = false),
          save: (newDates: Dates) => this.$emit('save', this.getNewCheckinData(checkin, newDates)),
        },
      });
    },

    getNewCheckinData(checkin: Checkin | null, newDates: Dates) {
      return {
        id: checkin?.id || uuid(),
        inDate: new Date(newDates.from),
        outDate: new Date(newDates.to),
        status: checkin?.status || CheckinStatus.manually,
        batteryInfo: checkin?.batteryInfo || {},
      };
    },
  },

  render(h: CreateElement): VNode {
    const elems = [] as VNode[];

    if (Array.isArray(this.checkins) && this.checkins.length) {
      for (const checkin of this.checkins) {
        elems.push(this.genContent(h, checkin));
      }
    } else {
      elems.push(this.genContent(h, null));
    }

    return h('div', {}, elems);
  },
});
