
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { baseUrl } from '@/api/httpV2';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import UploadButton from '../../../vendor/vuetify-upload-button/UploadButton.vue';
import { createDistribution, validateXlsx } from '@/api/distributions';
import { ValidationErrorInfo } from '@/api/import';
import DistributionOptions from '@/components/distribution/DistributionOptions.vue';
import XlsImportErrors from '@/components/import/XlsImportErrors.vue';

export default Vue.extend({
  name: 'DistributionImport',

  components: {
    Breadcrumbs,
    'upload-btn': UploadButton,
    DistributionOptions,
    XlsImportErrors,
  },

  data() {
    return {
      loading: false,
      progress: 0,
      error: null,
      validationErrors: [] as ValidationErrorInfo[],
      file: null as File | null,
      planningTime: 20,

      name: '',
      settings: {
        configuration: 'optimizeCarsThenSingleLocationGrouping',
        tripStartTimeStrategy: 'earliestFinish',
        planningTime: 20,
        assumptions: {
          disableTrafficJams: false,
          flightDistance: false,
          disableCompatibility: false,
          disableCapacity: false,
          sameOrderTimeWindow: false,
          expandShiftTimeWindow: false,
          optimizeInAQuickAndQualitativeWay: false,
        },
      },
      breadcrumbs: [
        {
          text: this.$t('Planning'),
          href: { name: 'distributionList' },
        },
        {
          text: this.$t('Create planning'),
          href: { name: 'distributionCreate' },
        },
        {
          text: this.$t('Planning from file'),
          href: { name: 'distributionImport' },
          disabled: true,
        },
      ],
    };
  },

  computed: {
    templateLink(): string {
      return baseUrl + '/distributions/template?locale=' + this.$i18n.locale;
    },

    rulesLink(): string {
      return baseUrl + '/distributions/rules?locale=' + this.$i18n.locale;
    },

    enableRun(): boolean {
      return Boolean(this.file && !this.validationErrors.length && this.settings.configuration);
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),

    async runPlan() {
      try {
        if (this.file) {
          // Check if file is still readable
          try {
            await this.file.slice(0, 1).arrayBuffer();
          } catch (err) {
            if (err.name === 'NotReadableError') {
              this.file = null;
              return;
            }
          }

          this.loading = true;
          this.error = null;
          this.validationErrors = [];

          const result = await createDistribution(this.file, this.settings, this.name);

          if (result.errors && result.errors.length) {
            this.validationErrors = result.errors;
          } else {
            this.showSnackbar(this.$t('Planning has been created'));
            this.$router.push({ name: 'distributionList' });
          }
        }
      } catch (e) {
        if (e.status === 400 && e.errors && e.errors.length) {
          this.validationErrors = e.errors;
          this.file = null;
        } else {
          this.error = e;
          console.error(e);
        }
      } finally {
        this.loading = false;

        if (this.$refs.uploadButton) {
          const $input = $('input[type=file]', (this.$refs.uploadButton as any).$el);

          if (/MSIE/.test(navigator.userAgent)) {
            $input.replaceWith($input.clone(true));
          } else {
            $input.val('');
          }
        }
      }
    },

    async readFile(file: File) {
      try {
        this.loading = true;
        this.error = null;
        this.validationErrors = [];
        this.file = null;

        const result = await validateXlsx(file);

        if (result.errors && result.errors.length) {
          this.validationErrors = result.errors;
        } else {
          this.file = file;
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;

        if (this.$refs.uploadButton) {
          const $input = $('input[type=file]', (this.$refs.uploadButton as any).$el);

          if (/MSIE/.test(navigator.userAgent)) {
            $input.replaceWith($input.clone(true));
          } else {
            $input.val('');
          }
        }
      }
    },
  },
});
