
import Vue from 'vue';
import debounce from 'lodash/debounce';

import contractors from '@/api/contractors';
import dashboard from '@/api/dashboard';

import Uuid from '@/models/Uuid';
import { Contractor } from '@/models/Contractor';

export default Vue.extend({
  name: 'ShareTripsDialog',

  data() {
    return {
      showDialog: true,
      selectedContractor: null as string | null,
      isLink: false,
      link: '',
      items: [] as Array<{ text: string; value: Uuid }>,
      error: null,
      isLoading: false,
      search: '',
    };
  },

  props: {
    outerContractor: {
      type: String,
    },
  },

  async created(): Promise<void> {
    if (!this.outerContractor) {
      await this.getItems();
    } else {
      this.selectedContractor = this.outerContractor;
      await this.generateLink();
    }
  },

  methods: {
    async getItems(): Promise<void> {
      try {
        this.error = null;

        const data = await contractors.getContractors({ filter: { search: this.search } });

        this.items = data.result.map((item: Contractor) => {
          return {
            text: this.formatTextToMenu(item.name),
            value: item.id,
          };
        });
      } catch (e) {
        this.error = e;
      }
    },

    async generateLink(): Promise<void> {
      try {
        this.error = null;

        const data = await dashboard.createLinkToDashboard(this.selectedContractor!);

        this.link = data.link;
        this.focusAndSelectInput();
      } catch (e) {
        this.error = e;
      }
    },

    focusAndSelectInput(): void {
      this.$nextTick(() => {
        (this.$refs.formLinkInput as Vue & { focus: () => void }).focus();
        const el = (this.$refs.formLinkInput as Vue & { $el: HTMLElement }).$el;
        $('input', el).trigger('select');
      });
    },

    copyContent(): void {
      (this.$refs.formLinkInput as Vue & { focus: () => void }).focus();
      const el = (this.$refs.formLinkInput as Vue & { $el: HTMLElement }).$el;
      $('input', el).trigger('select');
      document.execCommand('copy');
    },

    hideModal(): void {
      this.$emit('close');
    },

    formatTextToMenu(str: string): string {
      return str.length > 59 ? str.slice(0, 59) + '...' : str;
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },

    search: debounce(async function(this: any, val) {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getItems();
        this.isLoading = false;
      }
    }, 200),
  },
});
