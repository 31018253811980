/* global google */

import { extend } from '@/lib/util';
import config from '../config';
import { PointLabel, SecondLabelOptions, MapMarker } from './MapMarker';

/**
 * Marker group. Each marker in group has same position and rotated
 * @param       {[type]} options [description]
 * @constructor
 */
function MarkerGroup(options) {
  extend(MarkerGroup, google.maps.OverlayView);

  this.set('isCurrent', false);
  this.setValues(options);

  if (!(this.get('position') instanceof google.maps.LatLng)) {
    this.set('position', new google.maps.LatLng(this.get('position')));
  }
  this.secondLabel = new SecondLabelOptions(this.secondLabel);
  this.downLabel = new SecondLabelOptions(this.downLabel);

  this.div = document.createElement('div');
  this.div.style.position = 'absolute';

  this.markers = [];

  this.markerSecondLabel = new PointLabel({
    map: this.map,
    container: this.div,
    marker: this,
    data: this.secondLabel,
  });

  this.markerDownLabel = new PointLabel({
    map: this.map,
    container: this.div,
    marker: this,
    className: 'down',
    data: this.downLabel,
  });

  this.currentPositionMarker = new MapMarker({
    point: null,
    position: this.position,
    icon: {
      scaledSize: new google.maps.Size(40, 40), // scaled size
      url: config.icons.currentPosition, // url
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(20, 22), // anchor
    },
    map: this.map,
    zindex: 999,
  });
}

MarkerGroup.prototype.getMarkerAnchor = function() {
  return this.anchor;
};

MarkerGroup.prototype.getAnchorPoint = function() {
  const markerAnchor = this.anchor;

  let labelAnchor = -markerAnchor.y;
  if (!this.secondLabel.isEmpty()) {
    labelAnchor +=
      this.secondLabel.getOrigin().y - (this.secondLabel.html && this.secondLabel.html.indexOf('<br') !== -1 ? 31 : 15);
  }

  return new google.maps.Point(0, labelAnchor);
};

MarkerGroup.prototype.addMarker = function(marker) {
  if (!this.markers.includes(marker)) {
    this.markers.push(marker);

    marker.setMap(null);
    marker.setMap(this.getMap());
  }

  marker.set('container', this.div);
  if (marker.get('originalPosition') == undefined) {
    marker.set('originalPosition', marker.get('position'));
  }
  marker.set('position', this.position);
  this.updateMarker();
};

MarkerGroup.prototype.removeMarker = function(marker) {
  const idx = this.markers.indexOf(marker);
  if (idx === -1) {
    return;
  }

  this.markers.splice(idx, 1);
  marker.set('container', null);
  // marker.set('position', marker.get('originalPosition'));
  marker.set('originalPosition', undefined);
  marker.set('angle', undefined);
  this.updateMarker();
};

MarkerGroup.prototype.updateMarker = function() {
  const groupCount = this.markers.length;
  const dispersionAngle = Math.min(15 * groupCount, 75);

  for (let i = 0; i < groupCount; i++) {
    const angle =
      groupCount > 1 ? dispersionAngle / 2 - (dispersionAngle / (groupCount - 1)) * (groupCount - 1 - i) : 0;

    this.markers[i].set('angle', angle);
  }

  if (this.isCurrent) {
    this.currentPositionMarker.position = this.position;
    this.currentPositionMarker.setMap(this.map);
  } else {
    this.currentPositionMarker.setMap(null);
  }
};

MarkerGroup.prototype.onAdd = function() {
  this.getPanes().overlayMouseTarget.appendChild(this.div);

  this.listeners = [
    google.maps.event.addListener(this.secondLabel, 'origin_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.secondLabel, 'text_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.secondLabel, 'html_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.secondLabel, 'icon_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'origin_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'text_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'html_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this.downLabel, 'icon_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'position_changed', () => {
      this.updatePosition();
      this.draw();
    }),
    google.maps.event.addListener(this, 'active_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'isnext_changed', () => {
      this.update();
    }),
    google.maps.event.addListener(this, 'iscurrent_changed', () => {
      this.updateMarker();
    }),
  ];
  for (const marker of this.markers) {
    this.listeners.push(
      google.maps.event.addListener(marker, 'active_changed', () => {
        this.update();
      }),
    );
    this.listeners.push(
      google.maps.event.addListener(marker, 'isnext_changed', () => {
        this.update();
      }),
    );
    this.listeners.push(
      google.maps.event.addListener(marker, 'click', () => {
        google.maps.event.trigger(this, 'click');
      }),
    );
  }

  this.update();
};

MarkerGroup.prototype.onRemove = function() {
  this.div.parentNode.removeChild(this.div);
  this.currentPositionMarker.setMap(null);

  this.unbindAll();
  for (const listener of this.listeners) {
    google.maps.event.removeListener(listener);
  }
};

MarkerGroup.prototype.updatePosition = function() {
  for (const marker of this.markers) {
    marker.set('position', this.position);
  }
};

MarkerGroup.prototype.draw = function() {
  const position = this.getProjection().fromLatLngToDivPixel(this.get('position'));

  this.div.style.left = position.x + 'px';
  this.div.style.top = position.y + 'px';
};

MarkerGroup.prototype.update = function() {
  this.div.className =
    'map-marker-group' + (!this.get('active') ? '' : ' active') + (!this.get('isNext') ? '' : ' isnext');
  if (this.get('active')) {
    this.div.style.zIndex = 998;
  } else if (this.get('isNext')) {
    this.div.style.zIndex = 997;
  } else {
    this.div.style.zIndex = this.get('zindex');
  }

  this.markerSecondLabel && this.markerSecondLabel.update();
  this.markerDownLabel && this.markerDownLabel.update();
};

MarkerGroup.prototype.setActive = function(value) {
  this.set('active', value);
};

MarkerGroup.prototype.setIsNext = function(value) {
  this.set('isNext', value);
};

MarkerGroup.prototype.setIsCurrent = function(isCurrent) {
  this.set('isCurrent', isCurrent);
};

MarkerGroup.prototype.getShowLabel = function() {
  for (const marker of this.markers) {
    if (marker.get('active')) {
      return false;
    }
  }
  return true;
};

export default MarkerGroup;
