class Coerce {
  constructor(value) {
    this.converters = [];
    this.value = value;
    this.map = false;
  }

  _add(converter) {
    this.converters.push(converter);
  }

  coerce() {
    for (const converter of this.converters) {
      if (this.map && this.value instanceof Array) {
        this.value = this.value.map(value => converter(value));
      } else {
        this.value = converter.call(this, this.value);
      }
    }
    return this.value;
  }

  array() {
    this._add(function(value) {
      this.map = true;
      if (value === undefined) {
        return [];
      }
      return !(value instanceof Array) ? [value] : value;
    });
    return this;
  }

  int() {
    this._add(value => {
      const num = parseInt(value);
      return isNaN(num) ? value : num;
    });
    return this;
  }

  float() {
    this._add(value => {
      const num = parseFloat(value);
      return isNaN(num) ? value : num;
    });
    return this;
  }

  nullStr() {
    this._add(value => {
      return value === 'null' ? null : value;
    });
    return this;
  }

  boolean() {
    this._add(value => {
      return value === '1' || value === 'true';
    });
    return this;
  }
}

export default function(value) {
  return new Coerce(value);
}
