import Vue, { CreateElement, VNode } from 'vue';
import Component from 'vue-class-component';

@Component({
  inject: ['getMap'],
})
export default class EditPlaceWithDoubleClick extends Vue {
  getMap!: { (): Promise<google.maps.Map> };
  geocoder?: google.maps.Geocoder;

  async created() {
    const map = await this.getMap();
    this.geocoder = new google.maps.Geocoder();

    google.maps.event.addListener(map, 'dblclick', e => {
      this.onDoubleClick(e);
    });
  }

  render(h: CreateElement): VNode {
    return h();
  }

  geocode(location: google.maps.LatLng): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve, reject) => {
      if (!this.geocoder) {
        return reject(new Error('No geocoder'));
      }
      this.geocoder.geocode({ location }, (results, status) => {
        if (status === 'OK' && results.length) {
          resolve(results[0]);
        } else {
          reject(new Error('Geocoder error'));
        }
      });
    });
  }

  async onDoubleClick(e: google.maps.MouseEvent) {
    e.stop();
    this.$store.commit('mapSearch/startLoading');

    const result = await this.geocode(e.latLng);

    const coordinates = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    this.$emit('create', {
      location: {
        coordinates,
        addresses: [
          {
            address: result.formatted_address,
          },
        ],
      },
    });

    this.$store.commit('mapSearch/stopLoading');
  }
}
