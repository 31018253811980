<template>
  <v-flex v-if="payments.length > 0" text-xs-center>
    <v-card>
      <v-card-text class="px-0">
        <h4>{{ $t('made_advanced_payment') }}.</h4>
      </v-card-text>
      <v-data-table :headers="headers" :items="payments" :must-sort="true">
        <template slot="items" slot-scope="props">
          <td>
            {{ $d(props.item.current_period_start * 1000, 'standard') }} -
            {{ $d(props.item.current_period_end * 1000, 'standard') }}
          </td>
          <td>{{ props.item.paid_slots }}</td>
        </template>
      </v-data-table>
    </v-card>
  </v-flex>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      payments: [],
    };
  },
  created() {
    this.fetchAdvancePayments();
  },
  computed: {
    headers() {
      return [
        { text: this.$t('Renewal period'), align: 'center', value: 'current_period_start' },
        { text: this.$t('Number of paid accounts'), align: 'center', value: 'paid_slots' },
      ];
    },
  },
  methods: {
    async fetchAdvancePayments() {
      const info = await api.billing.info.getAdvancePayments();
      this.payments = info.payments;
    },
  },
};
</script>
