import VTextField from 'vuetify/es5/components/VTextField/VTextField';

export default {
  name: 'TextField',
  extends: VTextField,
  methods: {
    onKeyDown(e) {
      if (e.keyCode === 13) {
        this.$emit('enter');
      }
    },
  },
};
