import { cloneDeep } from 'lodash';

import Vue from 'vue';
import { Route } from 'vue-router';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import uuid from 'uuid/v4';

import Account from '@/models/Account';
import Trip from '@/models/Trip';
import TripPoint, { TripPointType, TripPointStatus } from '@/models/TripPoint';
import { CompanySettings } from '@/models/Company';

import { combiningPointsToWayPoints } from '@/services/routeService';

@Component
export default class LoadTripDataMixin extends Vue {
  trip!: Trip;
  executor!: Account | null;
  originalTrip!: Trip;
  responsible!: Account | null;
  newChanges!: never[];
  settings!: CompanySettings;

  isCreatingTrip!: boolean;
  planTrip!: boolean | null;
  importSuccess!: any;

  preventTripWatch!: boolean;
  preventRouteWatch!: boolean;

  async created() {
    window.addEventListener('beforeunload', this.windowUnloadHandler);
    await this.loadTripData();
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.windowUnloadHandler);
  }

  windowUnloadHandler(e: Event) {
    if (this.isCreatingTrip) {
      e.preventDefault();
    }

    if (!this.isCreatingTrip && this.newChanges.length > 0) {
      e.preventDefault();
    }
  }

  async loadTripData() {
    this.preventTripWatch = true;

    // Editing trip
    if (this.$route.params.id) {
      if (this.$route.params.editTrip && this.$route.params.trip) {
        this.loadEditedTripData((this.$route.params.trip as unknown) as Trip);
      } else {
        await (this as any).fetchData();
      }

      // Copying or creating trip
    } else {
      if (this.$route.params.copyTrip && this.$route.params.trip) {
        this.loadCopiedTripData((this.$route.params.trip as unknown) as Trip);
      } else {
        this.resetTrip();
      }
    }

    this.planTrip = this.trip.plannedAt === null ? null : Boolean(this.trip.plannedAt);
  }

  loadCopiedTripData(templateTrip: Trip) {
    this.trip = new Trip();

    this.trip.tripPoints = templateTrip.tripPoints
      .filter(
        tripPoint =>
          ![TripPointStatus.deleted, TripPointStatus.waitingForDelete].includes(tripPoint.status) &&
          [TripPointType.scheduled, TripPointType.finish].includes(tripPoint.type),
      )
      .map(tripPoint => {
        const newTripPoint = new TripPoint(tripPoint.toJSON());

        newTripPoint.id = uuid();
        newTripPoint.status = TripPointStatus.planned;
        newTripPoint.checkins = [];

        if (newTripPoint.functions) {
          if (newTripPoint.functions.droplist) {
            newTripPoint.functions.droplist.forEach(element => {
              element.selected = false;
            });
          }

          if (newTripPoint.functions.checklist) {
            newTripPoint.functions.checklist.forEach(element => {
              element.selected = false;
            });
          }
        }

        if (newTripPoint.comment) {
          newTripPoint.comment = null;
        }

        if (newTripPoint.attachFiles.length) {
          newTripPoint.attachFiles = [];
        }

        return newTripPoint;
      });

    this.trip.wayPoints = combiningPointsToWayPoints(this.trip.tripPoints, []);
    this.trip.tripMode = this.settings.tripMode;
  }

  loadEditedTripData(templateTrip: Trip) {
    if (templateTrip.tripPoints) {
      this.trip = templateTrip;
      this.executor = templateTrip.executor;
      this.responsible = templateTrip.responsible;
      this.originalTrip = cloneDeep(this.trip);
    }
  }

  resetBuildWay!: Function;

  resetTrip() {
    this.trip = new Trip();
    this.originalTrip = new Trip();
    this.trip.tripMode = this.settings.tripMode;
    this.originalTrip.tripMode = this.settings.tripMode;
    (this as any).importResult = null;
    this.planTrip = false;
    this.resetBuildWay();
  }

  @Watch('$route')
  onRouteChange(newRoute: Route, oldRoute: Route) {
    if (this.preventRouteWatch) {
      this.preventRouteWatch = false;
      return;
    }

    if (this.preventTripWatch) {
      this.preventTripWatch = false;
      return;
    }

    this.preventTripWatch = true;
    this.resetTrip();
    this.loadTripData();
  }
}
