
import Vue from 'vue';
import Checkbox from '@/components/inputs/CheckboxNoState';
import Trip from '@/models/Trip';
import TripListMixin from './TripListMixin';
import TripsDriverName from '@/components/trip/TripsDriverName.vue';
import TripStatusIcon from '@/components/icons/TripStatusIcon';
import TripSettingsBtn from '@/components/trip/TripSettingsBtn.vue';
import TripSummaryIndicators from '@/components/trip/TripSummaryIndicators.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'TripListRow',

  mixins: [TripListMixin],

  components: { Checkbox, TripsDriverName, TripStatusIcon, TripSettingsBtn, TripSummaryIndicators },

  props: {
    value: Boolean,
    index: Number,
    item: Trip,
    hasSelect: Boolean,
    hasSettings: Boolean,
    distribution: Boolean, // trips in distribution
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
});
