<template>
  <v-dialog v-model="showDialog" ref="dialog" :max-width="this.$vuetify.breakpoint.thresholds.xs">
    <v-card>
      <!-- {{ internalValue }} -->
      <error-alert :value="error" />

      <v-card-title primary-title>
        <div class="headline ma-2">
          {{ $t('AddingPointFunction') }}
        </div>
      </v-card-title>

      <v-container grid-list-sm class="pa-4">
        <v-form v-model="valid" @submit.prevent>
          <v-layout column>
            <v-flex>
              <v-layout row>
                <v-flex md7>
                  <v-text-field
                    v-if="value.foreign_id"
                    :rules="foreignIdRules"
                    v-model="internalValue.foreign_id"
                    :disabled="!canUpdate"
                  >
                    <template #label>
                      <strong class="red--text">* </strong>
                      {{ $t('foreignId') }}
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex>
              <v-layout row>
                <v-flex md7>
                  <v-text-field :rules="typeNameRules" v-model="internalValue.name" :disabled="!canUpdate">
                    <template #label>
                      <strong class="red--text">* </strong>
                      {{ $t('TitleName') }}
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex>
              <v-checkbox
                :label="$t('Droplist')"
                v-model="internalValue.functions.droplist.enabled"
                :disabled="!canUpdate"
              />

              <template v-if="internalValue.functions.droplist.enabled">
                <v-layout row>
                  <v-flex md6>
                    <div style="max-width: 250px">
                      <TextFunctionsEditor :label="$t('Droplist')" v-model="internalValue.functions.droplist.data" />
                    </div>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="internalValue.functions.droplist.data && internalValue.functions.droplist.data.length"
                  >
                    <div style="max-width: 250px">
                      <div class="d-flex mb-2">{{ $t('Example') }}:</div>
                      <div style="position: relative" ref="droplistEl" />
                      <v-select solo :attach="$refs.droplistEl" :items="internalValue.functions.droplist.data" />
                    </div>
                  </v-flex>
                </v-layout>

                <v-divider />
              </template>
            </v-flex>

            <v-flex>
              <v-checkbox
                :label="$t('Checklist')"
                v-model="internalValue.functions.checklist.enabled"
                :disabled="!canUpdate"
              />

              <template v-if="internalValue.functions.checklist.enabled">
                <v-layout row>
                  <v-flex md6>
                    <div style="max-width: 250px">
                      <TextFunctionsEditor :label="$t('Checklist')" v-model="internalValue.functions.checklist.data" />
                    </div>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="internalValue.functions.checklist.data && internalValue.functions.checklist.data.length"
                  >
                    <div style="max-width: 250px">
                      <div class="d-flex mb-2">{{ $t('Example') }}:</div>

                      <v-checkbox
                        v-for="item in internalValue.functions.checklist.data"
                        :key="item.id"
                        :label="item.text"
                        class="pt-0"
                        hide-details
                      />
                    </div>
                  </v-flex>
                </v-layout>

                <v-divider />
              </template>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>

      <v-card-actions>
        <v-spacer />

        <v-btn color="grey" flat @click.native="hideModal">
          {{ $t('Cancel') }}
        </v-btn>

        <v-btn color="primary" flat :disabled="!valid" @click.native="handleOk">
          {{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* global $ */
import rules from '@/api/rules';
import api, { PointFunctions } from '@/api/pointFunctions';
import TextFunctionsEditor from '@/components/inputs/TextFunctionsEditor';
import PointSettingsMixin from '@/components/trip/PointSettingsMixin';

export default {
  name: 'PointTypeEditDialog',

  components: { TextFunctionsEditor },

  mixins: [PointSettingsMixin],

  data() {
    return {
      valid: true,
      showDialog: true,
      error: null,
      internalValue: {},
    };
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  created() {
    if (this.value) {
      this.internalValue = this.fromServerJson(this.value);
      const stayTime = this.internalValue.functions.stay_time;

      if (stayTime in this.internalValue.functions && !stayTime.data) {
        this.$set(stayTime, 'data', 1800);
      }
    }
  },

  computed: {
    canUpdate() {
      return (
        this.internalValue &&
        this.internalValue.can_update &&
        (this.internalValue.can_update === true || this.internalValue.can_update.status === 'allowed')
      );
    },

    typeNameRules() {
      return rules.required('TitleName');
    },

    foreignIdRules() {
      return rules.foreignId;
    },

    serverJson() {
      const data = this.internalValue.functions;
      const result = {};

      for (const key in data) {
        if (data[key] && data[key].enabled) {
          result[key] = data[key].data;
        } else {
          result[key] = null;
        }
      }

      return {
        foreign_id: this.internalValue.foreign_id,
        name: this.internalValue.name,
        functions: result,
      };
    },
  },

  methods: {
    fromServerJson(data) {
      const result = {};

      for (const func of PointFunctions.FUNCTIONS) {
        if (data.functions[func.key]) {
          result[func.key] = {
            enabled: true,
            data: data.functions[func.key],
          };
        } else {
          result[func.key] = {
            enabled: false,
          };
        }
      }

      return {
        ...data,
        functions: result,
      };
    },

    hideModal() {
      this.$emit('close');
    },

    async handleOk() {
      try {
        for (const key in this.$refs) {
          if (this.$refs[key] && this.$refs[key].save && this.$refs[key].validate) {
            this.$refs[key].save();

            this.$nextTick(() => {
              this.$refs[key].validate();

              this.$nextTick(() => {
                if (!this.valid) {
                  return false;
                }
              });
            });
          }
        }

        const newValue = this.serverJson;

        if (this.value.id) {
          await api.update(this.value.id, newValue);
        } else {
          await api.create(newValue);
        }

        this.$emit('ok', newValue);
      } catch (error) {
        this.error = error;
        const el = this.$refs.dialog.$refs.content.firstChild;
        $(el).scrollTop(0);
      }
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },

    value() {
      this.internalValue = this.fromServerJson(this.value);
    },

    internalValue: {
      deep: true,
      handler(value) {},
    },
  },
};
</script>
