<template>
  <div>
    <h1>{{ $t('License Agreement for Mobile Application') }}</h1>
    <v-layout row wrap>
      <v-flex md6 xs12 pr-2>
        <p>
          Настоящий документ «Лицензионное соглашение» представляет собой предложение ХоумСтретч АГ (далее –
          «Правообладатель») заключить договор на изложенных ниже условиях.
        </p>

        <p>
          Перед использованием Мобильного приложения, пожалуйста, ознакомьтесь с условиями настоящего лицензионного
          соглашения. Использование Мобильного приложения на иных условиях не допускается.
        </p>

        <h4>1. Термины и определения</h4>
        <p>
          1.1. Используемые в настоящем Лицензионном соглашении слова и выражения имеют следующие значения, если иное
          прямо не определено далее по тексту:
        </p>
        <p>
          а) <b>Лицензия (Соглашение)</b> – текст настоящего документа со всеми приложениями, изменениями и дополнениями
          к нему, размещенный в дистрибутиве Мобильного приложения, а также на Сайте по адресу:
          <a href="https://co.homestretch.ch/license-agreement">https://co.homestretch.ch/license-agreement</a>
        </p>
        <p>
          б) <b>Пользователь</b> – лицо, фактически пользующееся с разрешения Заказчика функциональными возможностями
          Сервиса в назначенной роли.
        </p>
        <p>
          в) <b>Заказчик</b> – лицо, заключившее Договор о предоставлении Сервиса, в интересах которого Пользователи
          пользуются функциональными возможностями Сервиса.
        </p>
        <p>
          г) <b>Договор о предоставлении Сервиса</b> – договор, которым определяются коммерческие условия использования
          Сервиса Заказчиком и связанным с ним Пользователями, в том числе количество Учетных записей (разрешенных
          Пользователей), срок и стоимость использования Сервиса.
        </p>
        <p>
          д) <b>Мобильное приложение</b> – предназначенная для установки и использования на Устройстве программа для ЭВМ
          Правообладателя “HomeStretch BIZ”, позволяющая Пользователю получить доступ к Сервису с использованием сети
          связи.
        </p>
        <p>
          е) <b>Сайт</b> – автоматизированная информационная система, доступная в сети Интернет по сетевому адресу
          (включая поддомены):
          <a href="https://co.homestretch.ch/">https://co.homestretch.ch/</a>
        </p>
        <p>
          ж) <b>Сервис</b> – совокупность информационных служб и связанных с ними функциональных возможностей,
          предоставляемых Пользователям с использованием Сайта и Мобильного приложения.
        </p>
        <p>
          и) <b>Устройство</b> – планшет, мобильный телефон, коммуникатор, смартфон, иное устройство, позволяющее
          использовать Мобильное приложение по его функциональному назначению.
        </p>
        <p>
          к) <b>Учетная запись</b> - персональный раздел Сервиса, предназначенный для использования его функциональных
          возможностей Пользователем в рамках назначенной роли, к которому Пользователь получает доступ после
          прохождения регистрации и/или авторизации.
        </p>

        <h4>2. Лицензия</h4>
        <p>
          2.1. Правообладатель предоставляет Пользователю право использования Мобильного приложения на условиях простой
          (неисключительной) лицензии на территории всех стран мира в течение установленного срока.
        </p>
        <p>
          2.2. Для заключения настоящего Соглашения на изложенных в нем условиях достаточно совершения Пользователем
          любого из указанных ниже действий, если иное прямо не предусмотрено Договором о предоставлении Сервиса:
        </p>
        <p>воспроизведение (запись) или запуск Мобильного приложения на Устройстве,</p>
        <p>начало использования Сервиса на Устройстве в любой форме с использованием Мобильного приложения.</p>
        <p>
          Выполнение указанных выше действий подтверждает ознакомление и полное безоговорочное принятие Пользователем
          условий, изложенных в настоящем документе, и создает между Пользователем и Правообладателем договор на
          приведенных условиях в соответствии с положениями ст.437 и 438 Гражданского кодекса Российской Федерации.
        </p>
        <p>
          2.3. Пользователь вправе использовать Мобильное приложение в соответствии с условиями настоящего Соглашения
          следующими способами:
        </p>
        <p>2.3.1 Воспроизведение (запись) Мобильного приложения на Устройстве;</p>
        <p>
          2.3.2. Применение Мобильного приложения по его функциональному назначению для доступа к Сервису в рамках одной
          Учетной записи.
        </p>
        <p>
          2.4. Права и способы использования Мобильного приложения, в явном виде не предоставленные/ не разрешенные
          Пользователю по настоящему Соглашению, считаются непредоставленными/ запрещенными Правообладателем.
        </p>
        <p>
          2.5. Доступ к функциональным возможностям Сервиса требует наличия у Пользователя Учетной записи,
          предоставленной в рамках Договора о предоставлении Сервиса.
        </p>
        <p>2.6. Cрок действия Лицензии определяется на основе Договора о предоставлении Сервиса.</p>

        <h4>3. Ограничения использования</h4>
        <p>3.1. Пользователь не имеет права самостоятельно или с привлечением третьих лиц:</p>
        <p>
          3.1.1. Копировать (воспроизводить) в любой форме и способом входящие в состав Сервиса и /или Мобильного
          приложения Правообладателя программы для ЭВМ и базы данных, включая любые их элементы, без получения
          предварительного письменного согласия их владельца.
        </p>
        <p>
          3.1.2. Вскрывать технологию, эмулировать, декомпилировать, дизассемблировать, дешифровать, и производить иные
          аналогичные действия с Мобильным приложением и/или Сервисом.
        </p>
        <p>
          3.1.3. Извлекать из баз данных, включаемых в состав Мобильного приложения и/или Сервисов, любые
          справочно-информационные материалы и осуществлять их последующее использование в любой форме и любым способом.
        </p>
        <p>
          3.1.4. Создавать программные или справочно-информационные продукты и/или сервисы с использованием Мобильного
          приложения и/или Сервиса, а также включаемых в их состав баз данных или извлеченных (извлекаемых) из них
          справочно-информационных материалов.
        </p>
        <p>
          3.1.5. Удалять или любым способом изменять товарные знаки и уведомления об авторских и любых иных правах,
          включенные в Мобильное приложение и/или Сервис.
        </p>

        <h4>4. Ответственность по Лицензии</h4>
        <p>
          4.1. Ввиду предоставления в рамках Лицензии права использования Мобильного приложения в соответствующей части
          на безвозмездной основе к отношениям сторон по Лицензии не применимы положения законодательства о защите прав
          потребителей.
        </p>
        <p>
          4.2. Мобильное приложения предоставляется на условиях «как есть», в связи с чем Пользователю не
          предоставляются какие-либо гарантии, что: оно будет соответствовать требованиям Пользователя; предоставляться
          непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с его использованием, будут
          точными и надежными; все ошибки будут исправлены.
        </p>
        <p>
          4.3. Поскольку Мобильное приложение и/или Сервис находятся на стадии постоянного дополнения и обновления новых
          функциональных возможностей, форма и характер Мобильного приложения и/или Сервиса и предоставляемых на их
          основе услуг могут время от времени меняться без предварительного уведомления Пользователя. Правообладатель
          вправе по собственному усмотрению прекратить (временно или окончательно) предоставление или поддержку
          Мобильного приложения (или каких-либо отдельных частей Мобильного приложения или функций Сервиса)
          Пользователям, а также изменить или отозвать Лицензию без предварительного уведомления.
        </p>
        <p>
          4.4. Пользователь несет ответственность за любое нарушение обязательств, установленных настоящим Соглашением и
          (или) применимым правом, а также за все последствия таких нарушений (включая любые убытки, которые может
          понести Правообладатель и иные третьи лица).
        </p>
        <p>
          4.5. Правообладатель оставляет за собой право преследования нарушителей исключительных прав на Мобильное
          приложение в соответствии с гражданским, административным и уголовным законодательством по своему усмотрению.
        </p>
        <p>
          4.6. Совокупная ответственность Правообладателя по Соглашению в любом случае ограничивается причиненным
          Пользователю документально подтвержденным ущербом на сумму, не превышающую 1000 рублей, и возлагается на него
          при наличии его доказанной вины в причинении такого ущерба.
        </p>

        <h4>5. Обработка персональной информации</h4>
        <p>
          5.1. Обработка предоставленной Пользователем при использовании Мобильного приложения и/или Сервиса информации
          о себе и/или третьих лицах, а равно информации, полученной при таком использовании в автоматическом режиме,
          осуществляется в соответствии с Политикой конфиденциальности, размещенной или доступной по адресу:
          <a href="https://co.homestretch.ch/privacy_policy">https://co.homestretch.ch/privacy_policy</a>.
        </p>

        <h4>6. Заключительные положения</h4>
        <p>
          6.1. Настоящее Соглашение, порядок его заключения и исполнения, а также вопросы, не урегулированные настоящим
          Соглашением, регулируются действующим законодательством Российской Федерации.
        </p>
        <p>
          6.2. Все споры по Соглашению или в связи с ним подлежат рассмотрению в суде по месту нахождения
          Правообладателя в соответствии с действующим процессуальным правом Российской Федерации.
        </p>
        <p>
          6.3. Настоящее Соглашение может быть изменено или прекращено Правообладателем в одностороннем порядке без
          предварительного уведомления Пользователя и без выплаты какой-либо компенсации в связи с этим.
        </p>

        <p>6.4. Реквизиты Правообладателя:</p>
        <p>
          <i>
            ХоумСтретч АГ <br />
            Адрес: Санкт Андреас 13, 6330 Хам, Швейцария <br />
            email: <a href="email:info@homestretch.ch">info@homestretch.ch</a>
          </i>
        </p>
        <p>
          Редакция от «01» октября 2018 г.
        </p>
      </v-flex>
      <v-flex md6 xs12>
        <p>
          This License Agreement constitues an offer of the HomeStretch AG (hereinafter "Rightholder") to conclude the
          agreement upon the terms and conditions stated below.
        </p>

        <p>
          Please view the terms and conditions of the license agreement before using the Mobile application. Using the
          Mobile application on other conditions is not allowed.
        </p>

        <h4>1. Definitions</h4>
        <p>
          1.1. Words and expressions used in the License Agreement shall have the following meanings unless otherwise is
          expressly specified hereinafter:
        </p>
        <p>
          а) <b>License (Agreement)</b> means the text of the given document with all its schedules, amendments and
          addendums published in the Mobile application distribution package and also on the Website at:
          <a href="https://co.homestretch.ch/license-agreement">https://co.homestretch.ch/license-agreement</a>
        </p>
        <p>
          b) <b>User</b> means a person actually applying functionality of the Service within designated functional
          status upon authorization of the Customer.
        </p>
        <p>
          c) <b>Customer</b> - a person who entered into the Service agreement and for the benefit of whom Users apply
          the functionality of the Service.
        </p>
        <p>
          d) <b>Service agreement</b> – an agreement which stipulates commercial conditions of use of the Service by
          Customer and related Users, including quantity of Accounts (authorized Users), period and cost of use of the
          Service.
        </p>
        <p>
          e) <b>Mobile application</b> means the application software of the Rightholder “HomeStretch BIZ” intended for
          installation and use on the Device and allowing the User to get access to the Service using communication
          network.
        </p>
        <p>
          f) <b>Website</b> means automated information system available on the Internet network address in the
          following domains (including subdomains): <a href="https://co.homestretch.ch/">https://co.homestretch.ch/</a>
        </p>
        <p>
          g) <b>Service</b> means a set of information services and related functionality provided to Users with the aid
          of the Website and Mobile application.
        </p>
        <p>
          h) <b>Device</b> means any tablet, mobile phone, communicator, smartphone or other device which allow using
          the Mobile application due to its functional purpose.
        </p>
        <p>
          i) <b>Account</b> - personal area of the Service designed for use of its functionality by the User within
          designated functional status that the User accesses after registration and/or authorization.
        </p>

        <h4>2. License</h4>
        <p>
          2.1. The Rightholder grants the User with the right to use the Mobile application on the terms of a common
          (non-exclusive) license worldwide during the set term.
        </p>
        <p>
          2.2. To conclude the Agreement under the terms and conditions provided hereby the User is sufficient to
          perform any of the actions stated below unless otherwise is stipulated by the Service agreement:
        </p>
        <p>Mobile application reproduction (installation) or launch on the Device,</p>
        <p>First start of the Service on the Device in any manner using the Application.</p>
        <p>
          The above mentioned actions confirm that the User has reviewed and agrees to the full extent the terms set
          forth herein, and creates the agreement under the given conditions between the User and the Rightholder due to
          the provisions of Article 437 and 438 of the Civil Code of the Russian Federation.
        </p>
        <p>
          2.3. The User shall have the right to use the Mobile application due to the terms and conditions of the
          Agreement in the following ways:
        </p>
        <p>2.3.1 Mobile application reproduction (installation) on the Device;</p>
        <p>
          2.3.2. Using the Mobile application due to its functional purpose for access to the Service within one
          Account.
        </p>
        <p>
          2.4. Any rights and ways of use of the Mobile application not expressly granted by the Agreement shall be
          deemed non-granted/prohibited by the Rightholder.
        </p>
        <p>
          2.5. Access to the functionality of the Service requires Account of the User provided under the Service
          agreement.
        </p>
        <p>2.6. Term of the License shall be defined based on the Service agreement.</p>

        <h4>3. License Restrictions</h4>
        <p>3.1. The User is not allowed thereof independently or employing any third parties to do the following:</p>
        <p>
          3.1.1. In any manner copy (reproduce) application software or databases being a part of the Service and/or
          Mobile application of the Rightholder, including any of their elements without prior written consent of their
          owner.
        </p>
        <p>
          3.1.2. Reveal the techniques, emulate, decompile, disassemble, decode and make any other similar actions in
          relation to the Mobile application and/or the Service.
        </p>
        <p>
          3.1.3. Extract any reference and information materials from the databases included into the structure of the
          Mobile application and/or the Services, and subsequently use them in any form and manner.
        </p>
        <p>
          3.1.4. Create software or reference products and/or services using the Mobile application and/or the Service
          and also databases included in their structure or the reference materials having been taken (being taken) from
          them.
        </p>
        <p>
          3.1.5. Remove or in any way alter trademarks and copyright notices or other proprietary notices included in
          the Mobile application and/or the Service.
        </p>

        <h4>4. Liability under the License</h4>
        <p>
          4.1. In view of granting the right of use of the Mobile application in a certain part on a free-of-charge
          basis under the License the provisions of the Consumer Protection Legislation shall not be applicable to the
          relations of the parties with respect to the License.
        </p>
        <p>
          4.2. The Mobile application is provided "as is", and in this connection the User shall be granted no further
          warranties that: it will conform with the requirements of the User; it will be provided continuously, quickly,
          reliably and without errors; the results which can be obtained by its use will be accurate and reliable; all
          the errors will be corrected.
        </p>
        <p>
          4.3. Since functional capabilities of the Mobile application and/or the Service are constantly supplemented
          and updated, the form and the nature of the Mobile application and/or the Service and services provided on
          their basis can change from time to time without prior notice of the User. The Rightholder shall have the
          right at his own discretion to stop (temporarily or finally) providing or supporting the Mobile application
          (or any separate parts of the Mobile application or the Service functions) in favor of the Users, and also to
          alter or withdraw the License without prior notice.
        </p>
        <p>
          4.4. The User is responsible for any violation of the obligations provided by the Agreement and (or) an
          applicable legislation, and also for all the consequences of such violations (including any damages which the
          Rightholder and other third parties can suffer).
        </p>
        <p>
          4.5. The Rightholder reserves its right to prosecute at his own discretion violators of the exclusive rights
          in relation to the Mobile application due to the civil, administrative and criminal legislation.
        </p>
        <p>
          4.6. Cumulative liability of the Rightholder under the Agreement in any event is limited by the documentary
          confirmed damage suffered by the User in the amount which does not exceed RUB 1000, and the Rightholder shall
          be deemed responsible for it if there is his evidenced fault in causing such damage.
        </p>

        <h4>5. Personal data processing</h4>
        <p>
          5.1. Processing of information about User and/or third parties provided by User when using Mobile application
          and/or the Service as well as information collected automatically shall be conducted according to the Privacy
          policy available at:
          <a href="https://co.homestretch.ch/policy">https://co.homestretch.ch/policy</a>
        </p>

        <h4>6. Final provisions</h4>
        <p>
          6.1. The Agreement, procedure for its concluding and executing and also any issues not provided herein shall
          be governed by the applicable law of the Russian Federation.
        </p>
        <p>
          6.2. Any dispute arising from the Agreement or in connection with it shall be subject to settlement at the
          location of the Rightholder in accordance with the applicable procedural law of the Russian Federation.
        </p>
        <p>
          6.3. The Agreement can be altered or terminated by the Rightholder unilaterally and without prior notification
          of the User without payment of any compensation in this regard.
        </p>

        <p>6.4. The Rightholder’s details:</p>
        <p>
          <i>
            HomeStretch AG <br />
            Address: St. Andreas 13, 6330 Cham, Switzerland <br />
            email: <a href="email:info@homestretch.ch">info@homestretch.ch</a>
          </i>
        </p>
        <p>
          Dated «01» October 2018
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
/* eslint no-irregular-whitespace: 0 */
export default {
  metaInfo() {
    return {
      title: this.$i18n.t('License Agreement for Mobile Application'),
    };
  },
};
</script>
