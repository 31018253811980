import Uuid from './Uuid';

export type PointFunctionType =
  | 'comment'
  | 'contacts'
  | 'smsNotification'
  | 'deliveryWindows'
  | 'checklist'
  | 'droplist'
  | 'attach';

/** Запланированное время пребывания в точке */
export type StayTime = number | null;

/** Окно доставки */

export type Droplist = {
  id: number;
  text: string;
  selected: boolean;
}[];

export type Checklist = {
  id: number;
  text: string;
  selected: boolean;
}[];

export interface PointFunctionsDto {
  id?: Uuid | null;
  name?: string | null;
  droplist?: Droplist;
  checklist?: Checklist;
}

export class PointFunctions {
  id: Uuid | null;
  name: string | null;
  droplist: Droplist;
  checklist: Checklist;

  constructor(data?: PointFunctionsDto | null) {
    if (!data) {
      this.id = null;
      this.name = null;
      this.droplist = [];
      this.checklist = [];
      return;
    }

    this.id = data.id || null;
    this.name = data.name || null;
    this.droplist = data.droplist || [];
    this.checklist = data.checklist || [];
  }

  toDTO(): PointFunctionsDto | null {
    return this.id
      ? {
          id: this.id,
          name: this.name,
          droplist: this.droplist,
          checklist: this.checklist,
        }
      : null;
  }
}
