<template>
  <v-card class="my-3 pa-0" color="cyan lighten-4" v-if="isDevelopment">
    <v-card-actions class="pa-0">
      <span class="mx-3">{{ $t('Client ID') }}:</span>
      <span>{{ value.id }}</span>
      <v-spacer />
      <v-btn flat color="light-blue" class="open-point-details-btn" target="_blank" download :href="downloadGPXLink()">
        Lockito GPX
      </v-btn>
      <v-spacer />
      <span v-if="!showCard">open debugger</span>
      <v-btn icon @click="showCard = !showCard">
        <v-icon>{{ showCard ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-slide-y-transition>
      <v-card-text class="px-2 py-0" v-show="showCard">
        <v-alert :value="!!error" color="error">
          {{ error }}
        </v-alert>
        <v-container style="padding: 0">
          <v-layout align-baseline justify-center>
            <v-flex xs6>
              <v-select :items="getCases" :label="'Select case'" v-model="currentTripCase" hide-details class="pa-0" />
            </v-flex>
            <v-flex xs1 class="ml-auto">
              <v-icon @click="showDialog = true">
                mdi-message-text-outline
              </v-icon>
            </v-flex>
          </v-layout>
          <v-layout class="my-2" align-baseline>
            <v-flex> {{ $t('Time') }}: {{ this.builder.timeString }}</v-flex>
            <v-flex>
              <v-btn small @click="shiftTime(60 * 10)">+10 min</v-btn>
              <v-btn small @click="shiftTime(60 * 60)">+1 H</v-btn>
              <v-btn small @click="shiftTime(60 * 60 * 24)">+1 D</v-btn>
            </v-flex>
          </v-layout>
          <v-layout class="my-2" align-baseline>
            <v-flex>
              <v-btn small :disabled="tripHasStart || tripHasFinish" @click="addStartAndFinish">Add start-finish</v-btn>
              <v-btn small @click="addPoint">Add point</v-btn>
            </v-flex>
          </v-layout>
          <v-tabs v-model="tab" color="cyan lighten-4">
            <v-tab v-for="(tabItem, index) in tabs" :key="index">
              {{ tabItem }}
            </v-tab>
            <v-tab-item>
              <div>Add points to the point</div>
              <template v-for="(point, index) in builder.trip.tripPoints">
                <v-btn
                  small
                  v-if="indexesMultipointPossible.includes(index)"
                  @click="addMultipoint(index)"
                  :key="index"
                >
                  {{ index }}
                </v-btn>
              </template>
            </v-tab-item>
            <v-tab-item>
              <v-layout class="my-2" align-baseline>
                <v-btn small :disabled="!tripHasCurrent" @click="checkout">Checkout</v-btn>
              </v-layout>
              <v-layout class="my-2" align-baseline>
                <v-flex xs2>
                  <div>Checkin point</div>
                </v-flex>
                <v-flex xs10>
                  <template v-for="(point, index) in builder.trip.tripPoints">
                    <v-btn small v-if="indexesCheckinPossible.includes(index)" @click="checkin(index)" :key="index"
                      >{{ index }}
                    </v-btn>
                  </template>
                </v-flex>
              </v-layout>
              <v-layout class="my-2" align-baseline>
                <v-btn small :disabled="!tripCanFinish" @click="finish">Finish Trip</v-btn>
              </v-layout>
            </v-tab-item>
            <v-tab-item>
              <div>Mark point</div>
              <template v-for="(point, index) in builder.trip.tripPoints">
                <v-btn small v-if="activePoints.includes(index)" @click="mark(index)" :key="index">
                  {{ index }}
                </v-btn>
              </template>
            </v-tab-item>
            <v-tab-item>
              <div>Delete point</div>
              <template v-for="(point, index) in builder.trip.tripPoints">
                <v-btn small v-if="activePoints.includes(index)" @click="deletePoint(index)" :key="index">
                  {{ index }}
                </v-btn>
              </template>
            </v-tab-item>
            <v-tab-item>
              <div>Add point functions to the point</div>
              <template v-for="(point, index) in builder.trip.tripPoints">
                <v-btn small @click="addFunctionsToPoint(index)" :key="index">
                  {{ index }}
                </v-btn>
              </template>
            </v-tab-item>
            <v-tab-item>
              <v-select
                :items="zones"
                :label="'Select timezone'"
                v-model="timezone"
                clearable
                hide-details
                class="pa-0"
              />
            </v-tab-item>
          </v-tabs>
        </v-container>
      </v-card-text>
    </v-slide-y-transition>
    <TripJSONDialog v-if="showDialog" v-model="value" @close="closeDialog" />
  </v-card>
</template>

<script>
import Vue from 'vue';
import TripBuilder from '../unit/seeds/TripBuilder';
import tripCases from '../unit/seeds/tripCases';
import TripJSONDialog from './TripJSONDialog.vue';
import Trip from '@/models/Trip';
import _ from 'lodash';
import { TripPointType, TripPointStatus } from '@/models/TripPoint';
import toJSONTrip from './toJSONTrip';
import { baseUrl } from '@/api/httpV2';

export default Vue.extend({
  name: 'TripDebbuger',
  components: { TripJSONDialog },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      dirty: false,
      tab: null,
      tabs: ['MT', 'Check', 'Mark', 'Delete', 'Functions', 'TZ'],
      showCard: false,
      error: false,
      currentTripCase: null,
      showDialog: false,
      builder: null,
      timezone: null,
    };
  },
  created() {
    this.init();
  },
  computed: {
    isDevelopment() {
      return process.env.NODE_ENV === 'development';
    },
    indexOfTripPointById() {
      let result = {};
      this.builder.trip.tripPoints.forEach((tripPoint, index) => {
        result[tripPoint.id] = index;
      });
      return result;
    },
    getCases() {
      const tripCasesProto = Object.getPrototypeOf(tripCases);
      return Object.getOwnPropertyNames(tripCasesProto).filter(method => method !== 'constructor');
    },
    tripHasStart() {
      let startIndex = this.builder.trip.tripPoints.findIndex(p => p.type === TripPointType.start);
      return startIndex !== -1;
    },
    tripHasFinish() {
      let finishIndex = this.builder.trip.tripPoints.findIndex(p => p.type === TripPointType.finish);
      return finishIndex !== -1;
    },
    tripHasCurrent() {
      return this.builder.trip.tripPoints.filter(tripPoint => tripPoint.status === TripPointStatus.current).length > 0;
    },
    indexesMultipointPossible() {
      const trip = this.builder.trip;
      let result = [];

      trip.wayPoints.forEach(wayPoint => {
        const minIndexInWayPoint = this.findMinTripPointIndexInArray(wayPoint.tripPointIds);
        const firstPointInWayPoint = trip.tripPoints[minIndexInWayPoint];
        if (firstPointInWayPoint && firstPointInWayPoint.type === TripPointType.scheduled) {
          result.push(minIndexInWayPoint);
        }
      });
      return result;
    },
    indexesCheckinPossible() {
      const trip = this.builder.trip;
      let result = [];

      // Если поездка окончена или есть текущая точка, не показываем checkins
      if (trip.finishedAt || this.tripHasCurrent) return result;

      // Если поездка не начата, то показываем только стартовую точку
      if (!trip.startedAt) {
        const startPoint = trip.tripPoints.filter(tripPoint => tripPoint.type === TripPointType.start)[0];
        if (startPoint) {
          result.push(this.indexOfTripPointById[startPoint.id]);
          return result;
        }
      } else {
        trip.wayPoints.forEach(wayPoint => {
          const minIndexInWayPoint = this.findMinTripPointIndexInArray(wayPoint.tripPointIds);
          const firstPointInWayPoint = trip.tripPoints[minIndexInWayPoint];
          if (
            firstPointInWayPoint &&
            wayPoint.tripPointIds.length > 0 &&
            (firstPointInWayPoint.status === TripPointStatus.active ||
              firstPointInWayPoint.status === TripPointStatus.passed) &&
            firstPointInWayPoint.type !== TripPointType.start
          ) {
            result.push(minIndexInWayPoint);
          }
        });

        // Чекин финиша заменен на метод finish()
        let deleteIndex = -1;
        result.forEach((pointIndex, index) => {
          if (trip.tripPoints[pointIndex].type === TripPointType.finish) deleteIndex = index;
        });

        if (deleteIndex !== -1) {
          result.splice(deleteIndex, 1);
        }
      }
      return result;
    },
    activePoints() {
      let result = [];

      this.builder.trip.tripPoints.forEach(point => {
        if (point.status === TripPointStatus.active) {
          result.push(this.indexOfTripPointById[point.id]);
        }
      });
      return result;
    },
    tripCanFinish() {
      if (this.builder.trip.finishedAt) return false;

      // Финиш возможен только если все предыдущие точки уже пройдены

      // Кол-во посещенных точек
      let passedCount = 0;
      this.builder.trip.tripPoints.forEach(point => {
        if (point.status === TripPointStatus.passed) passedCount++;
      });

      // Кол-во посещенных точек должно быть равно кол-ву всех точек, за вычетом финишной
      return passedCount >= this.builder.trip.tripPoints.length - 1;
    },
    zones() {
      return Array(24)
        .fill()
        .map((v, i) => i - 12)
        .map(tz => ({
          value: tz,
          text: `UTC ${tz >= 0 ? '+' : ''}${tz}`,
        }));
    },
  },
  methods: {
    init() {
      if (this.value) {
        this.builder = new TripBuilder(this.value);
      } else {
        this.builder = new TripBuilder(tripCases.empty());
        this.currentTripCase = 'empty';
      }
    },
    closeDialog() {
      this.showDialog = false;
    },
    findMinTripPointIndexInArray(idsOfPoints) {
      return Math.min(...idsOfPoints.map(id => this.indexOfTripPointById[id]));
    },
    shiftTime(secs) {
      this.dirty = true;
      this.builder.shiftTime(secs);
      this.$forceUpdate();
    },
    downloadGPXLink() {
      return baseUrl + '/trips/' + this.builder.trip.id + '/gpx';
    },
    addPoint() {
      this.dirty = true;
      this.builder.addPoint();
    },
    addStartAndFinish() {
      this.dirty = true;
      this.builder.addStartAndFinish();
    },
    addMultipoint(index) {
      this.dirty = true;
      this.builder.addMultipoint(index);
    },
    checkin(index) {
      this.dirty = true;
      this.builder.checkin(index);
    },
    checkout() {
      this.dirty = true;
      this.builder.checkout();
    },
    mark(index) {
      this.dirty = true;
      this.builder.mark(index);
    },
    deletePoint(index) {
      this.dirty = true;
      this.builder.deletePoint(index);
    },
    finish() {
      this.dirty = true;
      this.builder.finish();
    },
    addFunctionsToPoint(index) {
      this.dirty = true;
      this.builder.addFunctionsToPoint(index);
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newValue, oldValue) {
        if (!this.dirty && !_.isEqual(toJSONTrip(newValue), toJSONTrip(oldValue))) {
          this.builder = new TripBuilder(newValue);
        }
      },
    },
    'builder.trip': {
      deep: true,
      handler() {
        this.$emit('input', new Trip(this.builder.trip));
      },
    },
    dirty(value) {
      this.$emit('update:dirty', value);
    },
    currentTripCase(value) {
      this.dirty = true;
      if (value) {
        this.error = false;
        try {
          this.builder = new TripBuilder(tripCases[value]());
          this.builder.setTimezone(this.timezone);
        } catch (e) {
          this.error = e.message;
          this.builder = null;
        }
      }
    },
    timezone(value) {
      this.builder.setTimezone(value);
    },
  },
});
</script>
