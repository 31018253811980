<template>
  <v-dialog v-model="showDialog" max-width="520">
    <v-card>
      <v-card-title class="headline">
        <v-layout row justify="top">
          {{ $t('Appointment of an executor for the trip') }}
          <v-spacer />
          <v-btn icon small class="close-btn" @click="$emit('close')">
            <v-icon small>
              close
            </v-icon>
          </v-btn>
        </v-layout>
      </v-card-title>

      <v-card-text>
        <AccountPicker prepend-icon="person" :label="$t('Executor')" :items="branches" v-model="value" uuid single />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn flat text @click="$emit('close')">
          {{ $t('Cancel') }}
        </v-btn>
        <btn-async color="primary" text @click="assignExecutor">
          {{ $t('Assign executor') }}
        </btn-async>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/api';
import uuid from 'uuid/v4';
import AccountPicker from '@/components/pickers/AccountPicker';
import branch from '@/api/branch';

export default {
  name: 'AssignExecutorDialog',

  components: { AccountPicker },

  props: {
    tripId: {
      type: Number,
      required: true,
    },

    executorId: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    showDialog: true,
    value: null,
    branches: [],
  }),

  async created() {
    this.value = this.executorId;
    await this.fetchAccountsList();
  },

  methods: {
    async assignExecutor() {
      await api.trip.updateTrip(this.tripId, {
        changes: [
          {
            external_id: uuid(),
            type: 'updateExecutor',
            info: {
              executorId: this.value || null,
            },
          },
        ],
      });
      this.$emit('select');
    },

    async fetchAccountsList() {
      this.branches = await api.branch.listMy();
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
