import http from '@/api/http';

const getDefaultState = () => {
  return {
    accountId: null,
    permissions: {},
    loggedIn: false,
    initialized: false,
  };
};

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    setPermissions(state, data) {
      state.permissions = data.reduce((hash, ruleName) => {
        hash[ruleName] = true;

        return hash;
      }, Object.create(null));
    },

    setAccountId(state, data) {
      state.accountId = data;
    },

    setLoggedIn(state, data) {
      state.loggedIn = data;
    },

    setInitialized(state, data) {
      state.initialized = data;
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },

  getters: {
    hasPermission: state => permissions => {
      if (Array.isArray(permissions)) {
        return permissions.some(p => state.permissions[p]);
      } else {
        return Boolean(state.permissions[permissions]);
      }
    },

    agreeWithTerms(state, getters, rootState) {
      if (rootState.company.owner_id === state.accountId) {
        return rootState.company.agree_rules && rootState.company.agree_terms && rootState.account.agree_terms;
      }

      return rootState.account.agree_terms;
    },
  },

  actions: {
    hasPermission: state => permissions => {
      if (Array.isArray(permissions)) {
        return permissions.some(p => state.permissions[p]);
      } else {
        return Boolean(state.permissions[permissions]);
      }
    },

    async agreeWithTerms({ commit }, data) {
      const res = await http.post('/agree', data);

      if (res.data.agree_terms) {
        commit('company/setAgreeTerms', res.data.agree_terms, { root: true });
        commit('account/setAgreeTerms', res.data.agree_terms, { root: true });
      }

      if (res.data.agree_rules) {
        commit('company/setAgreeRules', res.data.agree_rules, { root: true });
      }
    },
  },
};
