import Vue, { VNode } from 'vue';
import { DeliveryWindows } from '../../../models/TripPoint';
import TimeIntervalWithOptionalDay from '../../TimeIntervalWithOptionalDay';

export default Vue.extend({
  functional: true,

  name: 'DeliveryWindows',

  props: {
    deliveryWindows: Array,
    missDeliveryWindow: Boolean,
    showDate: Boolean,
    showTimezones: Boolean,
    inOneLine: Boolean,
    timezoneOffset: Number,
  },

  render(h, { props }) {
    const { missDeliveryWindow, timezoneOffset, showDate, showTimezones, inOneLine } = props;
    const deliveryWindows = props.deliveryWindows as DeliveryWindows[];

    if (!Array.isArray(deliveryWindows)) {
      return h();
    }

    const elems = [] as VNode[];

    for (const [i, deliveryWindow] of deliveryWindows.entries()) {
      elems.push(
        h(TimeIntervalWithOptionalDay, {
          props: {
            from: deliveryWindow.from,
            to: deliveryWindow.to,
            showDate,
            showTimezones,
            inOneLine,
            timezoneOffset,
            status,
            invalid: missDeliveryWindow,
          },
        }),
      );

      if (i !== deliveryWindows.length - 1) {
        elems.push(<span>{', '}</span>);
      }
    }

    return elems;
  },
});
