import { AxiosResponse } from 'axios';

export default class APIError extends Error {
  status?: number;

  constructor(data: any) {
    super(data.message || 'API Error');
    delete data.message;
    Object.assign(this, data);
  }

  static fromResponse(response: AxiosResponse) {
    const error = new APIError(response.data);
    error.status = response.status;
    return error;
  }
}
