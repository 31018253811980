
import Vue from 'vue';
import tags from '@/api/tags';
import CustomColorPicker from '@/components/modals/CustomColorPicker.vue';
import rules from '@/api/rules';
import { mapMutations } from 'vuex';

const DEFAULT_COLOR = 'blue';

export default Vue.extend({
  name: 'CreateTag',

  components: { CustomColorPicker },

  data() {
    return {
      showDialog: true,
      error: null,
      name: '',
      color: DEFAULT_COLOR,
    };
  },

  computed: {
    checkValid(): boolean {
      return Boolean(this.name && this.color);
    },

    nameRules() {
      return rules.required('Tag name');
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),

    async createTag(): Promise<void> {
      try {
        this.error = null;

        await tags.createTag({
          name: this.name,
          color: this.color,
        });

        this.$emit('fetch');
        this.showDialog = false;

        this.showSnackbar(this.$t('Success tag create'));
      } catch (e) {
        this.error = e;
      }
    },

    colorChanged(color: any) {
      this.color = color;
    },

    hideModal(): void {
      this.$emit('close');
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
