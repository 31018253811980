import http, { timeout, pagingParams, Params } from './httpV2';
import { DistributionInfo, Distribution } from '@/models/Distribution';
import Uuid from '@/models/Uuid';
import { ValidationErrorInfo } from './import';
import { TripInstanceDto } from '@/models/Trip';
import DistributionsFilterDto from '@/components/filters/DistributionsFilterDto';

const path = '/distributions';

interface GetDistributionListResponse {
  result: Array<DistributionInfo>;
  total: number;
}

interface DistributionValidationResponse {
  errors?: ValidationErrorInfo[];
}

async function createDistribution(file: File, settings: any, name: any): Promise<DistributionValidationResponse> {
  const data = new FormData();
  data.append('file', file, file.name);
  data.append('settings', JSON.stringify(settings));
  data.append('name', name);

  const res = await http.post<DistributionValidationResponse>(path, data, {
    timeout: 120000,
  });
  return res.data;
}

async function createDistributionManual(data: any): Promise<DistributionValidationResponse> {
  const res = await http.post<DistributionValidationResponse>(`${path}/json`, data, {
    timeout: 120000,
  });
  return res.data;
}

async function validateXlsx(file: File): Promise<DistributionValidationResponse> {
  const data = new FormData();
  data.append('file', file, file.name);

  const res = await http.post<DistributionValidationResponse>(`${path}/validate`, data, {
    timeout: 120000,
  });
  return res.data;
}

async function getTripResources(tripIds: Uuid[]): Promise<any> {
  const res = await http.post<any>(`${path}/tripResources`, {
    tripIds,
  });
  return res.data;
}

async function getDistributionList(opts: Params): Promise<GetDistributionListResponse> {
  if (opts && opts.filter) {
    opts = { ...opts };

    if (!opts.filter.toHttpParams) {
      opts.filter = new DistributionsFilterDto(opts.filter);
    }

    opts.filter = opts.filter.toHttpParams();
  }

  const res = await http.get<GetDistributionListResponse>(path, { params: pagingParams(opts) });
  return res.data;
}

async function getDistribution(id: Uuid): Promise<Distribution> {
  const res = await http.get<Distribution>(`${path}/${id}`);
  return res.data;
}

async function analyzeUnplanned(id: Uuid): Promise<any> {
  const res = await http.post(
    `${path}/${id}/analyzeUnplanned`,
    {},
    {
      timeout: 120000,
    },
  );
  return res.data;
}

async function getDistributionTrip(distributionId: Uuid, tripId: string): Promise<any> {
  const res = await http.get(`${path}/${distributionId}/trips/${tripId}`);
  return res.data;
}

async function getDistributionMonitoring(id: Uuid): Promise<any> {
  const res = await http.get(`${path}/${id}/monitoring`);
  return res.data;
}

async function acceptDistribution(id: Uuid, data: { tripIds: string[] }): Promise<TripInstanceDto[]> {
  const res = await http.post(`${path}/${id}/accept`, data, {
    timeout: timeout * 2,
  });
  return res.data;
}

async function deleteDistribution(id: Uuid): Promise<void> {
  await http.delete<void>(`${path}/${id}`);
  return;
}

async function deleteDistributions(data: Uuid[]): Promise<void> {
  await http.delete<void>(path, { data });
  return;
}

export {
  createDistribution,
  createDistributionManual,
  validateXlsx,
  getDistribution,
  getDistributionList,
  deleteDistribution,
  deleteDistributions,
  getDistributionMonitoring,
  getDistributionTrip,
  acceptDistribution,
  analyzeUnplanned,
  getTripResources,
  GetDistributionListResponse,
};
