<template>
  <v-input class="contact-editor mt-2" prepend-icon="phone" :rules="rules" :value="value">
    <div style="width: 100%">
      <v-text-field
        v-if="showName"
        :value="value.name"
        :label="$t('Name')"
        maxlength="20"
        hide-details
        @click:append-outer="onDelete"
        @input="updateName"
        class="mb-4"
      />

      <v-text-field
        v-if="showJobTitle"
        :value="value.jobTitle"
        :label="$t('Job title')"
        maxlength="20"
        hide-details
        @click:append-outer="onDelete"
        @input="updateJobTitle"
        class="mb-4"
      />

      <v-text-field
        v-if="showNote && !showInternalPhone"
        :value="value.note"
        :label="$t('Note')"
        maxlength="50"
        hide-details
        @input="updateNote"
        class="mb-4"
      />

      <PhoneListEditor v-if="showPhones" :max-count="maxPhoneCount" :value="value.phones" @input="updatePhones" />

      <v-text-field
        v-if="showInternalPhone"
        class="mt-3"
        :value="value.note"
        :label="$t('Phone Internal')"
        hide-details
        @input="updateNote"
      />

      <div v-if="canDelete" class="mt-3">
        <a @click="onDelete">
          {{ $t('Remove contact') }}
        </a>
      </div>
    </div>
  </v-input>
</template>

<script>
import PhoneListEditor from './PhoneListEditor';

export default {
  components: { PhoneListEditor },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    maxPhoneCount: {
      type: Number,
      default: 3,
    },

    showName: {
      type: Boolean,
      default: true,
    },

    showJobTitle: {
      type: Boolean,
      default: true,
    },

    showNote: {
      type: Boolean,
      default: true,
    },

    showPhones: {
      type: Boolean,
      default: true,
    },

    showInternalPhone: {
      type: Boolean,
      default: false,
    },

    requiredPhone: {
      type: Boolean,
      default: true,
    },

    canDelete: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    rules() {
      return [
        v => {
          if (this.requiredPhone && (v.phones.length === 0 || !v.phones[0].phone)) {
            return this.$t('rules.required', [this.$t('Phone')]);
          }
          return true;
        },
      ];
    },
  },

  methods: {
    updateName(value) {
      this.$emit('input', { ...this.value, name: value });
    },

    updateJobTitle(value) {
      this.$emit('input', { ...this.value, jobTitle: value });
    },

    updateNote(value) {
      this.$emit('input', { ...this.value, note: value });
    },

    updatePhones(value) {
      this.$emit('input', { ...this.value, phones: value });
    },

    onDelete() {
      this.$emit('delete');
    },
  },
};
</script>
