import { differenceInSeconds, parseISO } from 'date-fns';
import { parseDurationToSeconds } from '@/i18n';

function extend(self, object) {
  for (const property in object.prototype) {
    if (!self.prototype.hasOwnProperty(property)) {
      self.prototype[property] = object.prototype[property];
    }
  }
  return self;
}

const sleep = ms => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), ms);
  });
};

const debugTimeout = debug => {
  if (debug) {
    return sleep(2000);
  }
};

export { extend, sleep, debugTimeout };
