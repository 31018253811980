/* eslint-disable */
import colors from 'vuetify/es5/util/colors';

import BaseInfoWindow from './BaseInfoWindow';
import { CreateElement, VNode } from 'vue';
import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Place } from '@/models/Place';
import { Watch } from 'vue-property-decorator';

const mapState = namespace('map');

const MIN_RADIUS = 50;
const MAX_RADIUS = 1000;

@Component
export default class PlaceCalibrationWindow extends mixins(BaseInfoWindow) {
  @mapState.State('calibrateLoading') readonly calibrateLoading!: boolean;
  @mapState.State('currentPlace') readonly currentPlace!: Place | null;
  @mapState.State('draggable') readonly draggable!: boolean;
  @mapState.State('placeRadius') readonly placeRadius!: boolean;

  checkinRadiusCircle = null as google.maps.Circle | null;
  checkoutRadiusCircle = null as google.maps.Circle | null;

  render(h: CreateElement): VNode {
    if (!this.place) {
      return h();
    }
    return this.genContent(this.place);
  }

  beforeDestroy() {
    this.$store.commit('map/setPlaceRadius', false);
  }

  genContent(place: Place) {
    return (
      <div class="map-marker-tooltip">
        <div class="map-marker-tooltip-sizer" />
        {this.genCommonContent(place)}
        {!this.draggable && !this.placeRadius && this.genEditButtons()}
      </div>
    );
  }

  genCommonContent(place: Place) {
    return (
      <div>
        {this.calibrateLoading ? (
          <v-progress-circular indeterminate size={16} width={2} color="grey" />
        ) : (
          <div>{place.getAddress()}</div>
        )}
        {this.draggable && (
          <div class="d-flex align-center" style="height: 40px">
            <div class="d-flex shrink" style="max-width: 25px">
              <v-icon class="d-inline">mdi-arrow-all</v-icon>
            </div>
            <div class="d-flex ml-2 grow">{this.$t('You can move the marker')}</div>
            <v-spacer />
          </div>
        )}
        {this.placeRadius && (
          <div class="d-flex align-center" style="height: 40px">
            <div>
              {this.$t('Confirm')}
              <v-btn icon small onClick={this.reset}>
                <v-icon>check</v-icon>
              </v-btn>
            </div>
          </div>
        )}
      </div>
    );
  }

  genEditButtons() {
    const buttons = [];

    buttons.push(
      <v-tooltip bottom>
        <v-btn slot="activator" icon flat small onClick={this.radiusEditHandler}>
          <v-icon>my_location</v-icon>
        </v-btn>
        <span>{this.$t('Edit checkin and checkout radiuses')}</span>
      </v-tooltip>,
    );

    buttons.push(
      <v-tooltip bottom>
        <v-btn slot="activator" icon flat small onClick={this.calibrateHandler}>
          <v-icon>mdi-map-marker-radius</v-icon>
        </v-btn>
        <span>{this.$t('Calibrate')}</span>
      </v-tooltip>,
    );

    buttons.push(
      <v-tooltip bottom>
        <v-btn slot="activator" icon flat small onClick={this.deleteHandler}>
          <v-icon>delete</v-icon>
        </v-btn>
        <span>{this.$t('Delete')}</span>
      </v-tooltip>,
    );

    return <div>{buttons}</div>;
  }

  async showCircles() {
    const map = await (this as any).getMap();

    this.checkinRadiusCircle = new google.maps.Circle({
      strokeColor: colors.red.base,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: colors.red.base,
      fillOpacity: 0.35,
      map: map,
      center: this.place?.location.coordinates,
      radius: this.place?.radius.checkin,
      draggable: false,
      editable: true,
    });

    google.maps.event.addListener(this.checkinRadiusCircle, 'radius_changed', () => {
      if (!this.checkinRadiusCircle) {
        return;
      }
      if (this.checkinRadiusCircle.getRadius() < MIN_RADIUS) {
        this.checkinRadiusCircle.setRadius(MIN_RADIUS);
      }
      if (this.checkinRadiusCircle.getRadius() > MAX_RADIUS) {
        this.checkinRadiusCircle.setRadius(MAX_RADIUS);
      }
      if (this.place) {
        this.place.radius.checkin = +this.checkinRadiusCircle.getRadius().toFixed(2);
      }
    });

    this.checkoutRadiusCircle = new google.maps.Circle({
      strokeColor: colors.indigo.base,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: colors.indigo.base,
      fillOpacity: 0.35,
      map: map,
      center: this.place?.location.coordinates,
      radius: this.place?.radius.checkout,
      draggable: false,
      editable: true,
    });

    google.maps.event.addListener(this.checkoutRadiusCircle, 'radius_changed', () => {
      if (!this.checkoutRadiusCircle) {
        return;
      }
      if (this.checkoutRadiusCircle.getRadius() < MIN_RADIUS) {
        this.checkoutRadiusCircle.setRadius(MIN_RADIUS);
      }
      if (this.checkoutRadiusCircle.getRadius() > MAX_RADIUS) {
        this.checkoutRadiusCircle.setRadius(MAX_RADIUS);
      }
      if (this.place) {
        this.place.radius.checkout = +this.checkoutRadiusCircle.getRadius().toFixed(2);
      }
    });

    this.$store.commit('map/setPlaceRadius', true);
  }

  async zoomMap() {
    const map = await (this as any).getMap();

    if (this.place) {
      const radius = Math.max(this.place.radius.checkin, this.place.radius.checkout);
      const latlng = new google.maps.LatLng(this.place.location.coordinates);
      const north = google.maps.geometry.spherical.computeOffset(latlng, radius, 0);
      const east = google.maps.geometry.spherical.computeOffset(latlng, radius, 90);
      const south = google.maps.geometry.spherical.computeOffset(latlng, radius, 180);
      const west = google.maps.geometry.spherical.computeOffset(latlng, radius, 270);

      map.fitBounds({
        east: east.lng(),
        west: west.lng(),
        north: north.lat(),
        south: south.lat(),
      });
    }
  }

  reset() {
    if (this.checkinRadiusCircle && this.checkoutRadiusCircle) {
      this.checkinRadiusCircle.setMap(null);
      this.checkoutRadiusCircle.setMap(null);
      this.checkinRadiusCircle = null;
      this.checkoutRadiusCircle = null;

      this.$store.commit('map/setPlaceRadius', false);
    }
  }

  radiusEditHandler() {
    this.reset();
    this.showCircles();
    this.zoomMap();
  }

  calibrateHandler() {
    this.$store.commit('map/setDraggable', true);
  }

  deleteHandler() {
    this.$emit('deletePoint');
  }

  get place(): Place | null {
    return this.currentPlace;
  }

  @Watch('place.radius', { deep: true }) onRadiusChanged() {
    if (this.checkinRadiusCircle && this.checkoutRadiusCircle) {
      this.radiusEditHandler();
    }
  }
}
