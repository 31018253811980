import Vue, { CreateElement, VNode } from 'vue';
import Component from 'vue-class-component';
import { Inject, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IPlaceMarker } from './markers/PlaceMarker';
import { IPointMarker } from './markers/PointMarker';

const mapState = namespace('map');

@Component
export default class BaseInfoWindow extends Vue {
  map?: google.maps.Map;
  infoWindow?: google.maps.InfoWindow;

  @Inject('getMap') readonly getMap!: { (): Promise<google.maps.Map> };
  @mapState.State('currentMarker') readonly currentMarker!: IPointMarker | null;

  async created() {
    await this.onCreated();
  }

  beforeDestroy() {
    this.$store.commit('map/clearSelection');
  }

  async onCreated() {
    this.map = await this.getMap();

    this.infoWindow = new google.maps.InfoWindow({
      content: '<div id="map-info-window"></div>',
      maxWidth: this.maxWidth,
    });

    this.infoWindow.addListener('domready', () => {
      this.mountedInfoWindow();

      if (this.$route.name === 'PlaceDetails') {
        $('.gm-ui-hover-effect').hide();
      }
    });

    this.infoWindow.addListener('closeclick', () => {
      this.$store.commit('map/clearSelection');
    });

    google.maps.event.addListener(this.map, 'calibrateStart', () => {
      this.hideInfoWindow();
    });

    google.maps.event.addListener(this.map, 'calibrateStop', () => {
      if (this.currentMarker) {
        this.showInfoWindow(this.currentMarker);
      }
    });

    // TODO: remove hack
    if ((this.currentMarker as IPlaceMarker | null)?.place) {
      this.update();
    }
  }

  mountedInfoWindow() {
    const mountElement = document.getElementById('map-info-window');
    mountElement?.appendChild(this.$el);
  }

  showInfoWindow(marker: IPointMarker) {
    if (marker.marker) {
      this.infoWindow?.open(this.map, marker.marker);
    }
  }

  hideInfoWindow() {
    this.infoWindow?.close();
  }

  update() {
    if (this.currentMarker) {
      this.showInfoWindow(this.currentMarker);
    } else {
      this.hideInfoWindow();
    }
  }

  render(h: CreateElement): VNode {
    return h();
  }

  get maxWidth() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return 200;
    }
    return 350;
  }

  @Watch('currentMarker')
  onCurrentMarkerUpdate() {
    this.update();
  }
}
