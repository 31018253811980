<template functional>
  <v-layout
    v-if="$options.methods.getContacts(props) && $options.methods.getContacts(props).length"
    v-bind="data.attrs"
    column
  >
    <v-layout row mb-2 v-for="contact in $options.methods.getContacts(props)" :key="contact.id">
      <v-flex xs3>
        {{ contact.name }}
      </v-flex>
      <v-flex xs9>
        <template v-for="phoneNumber in contact.phones">
          <div :key="phoneNumber.id">
            {{ phoneNumber.phone }}
          </div>
        </template>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    withIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getContacts(props) {
      let result = [];
      if (props && props.value && Array.isArray(props.value)) {
        result = props.value;
      }
      return result;
    },
  },
};
</script>
