
import Vue from 'vue';
import { MetaInfo } from 'vue-meta';

import api from '@/api/roles';

import RolesForm from '@/components/roles/RolesForm.vue';
import { Role } from '@/models/Role';

export default Vue.extend({
  name: 'RolesEdit',

  components: {
    RolesForm,
  },

  metaInfo(): MetaInfo {
    return {
      title: String(this.$i18n.t('roles.edit.title')),
    };
  },

  data() {
    return {
      isLoading: false,
      item: null as Role | null,
    };
  },

  methods: {
    async loadRole(): Promise<void> {
      this.isLoading = true;

      try {
        this.item = await api.getRole(this.$route.params.id);
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.loadRole();
  },
});
