
import Vue from 'vue';
import transports from '@/api/transports';
import { Transport } from '@/models/Transport';

export default Vue.extend({
  name: 'TransportDeletionWarning',
  data() {
    return {
      showDialog: true,
      error: null,
    };
  },
  props: {
    transport: Object as () => Transport,
  },
  methods: {
    async deleteTransport(): Promise<void> {
      try {
        await transports.deleteTransport(this.transport.id);
        this.$emit('close', true);
      } catch (e) {
        this.error = e;
      }
    },
    hideModal() {
      this.$emit('close');
    },
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
