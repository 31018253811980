import tags from '@/api/tags';

export default {
  namespaced: true,

  state: {
    tags: [],
    lastUpdate: 0,
  },

  mutations: {
    setTags(state, data) {
      if (Array.isArray(data)) {
        state.tags = data;
        state.lastUpdate = new Date().valueOf();
      }
    },
  },

  actions: {
    async updateTags({ state, commit, rootGetters }) {
      const hasPermission = rootGetters['auth/hasPermission']('tags get');

      if (state.lastUpdate < new Date().valueOf() + 60000 && hasPermission) {
        const data = (await tags.getTags()).result;
        commit('setTags', data);
      }
    },
  },
};
