
import Vue from 'vue';
import { isEmpty } from 'lodash';
import { TRANSPORT_ICONS } from '@/components/pickers/TransportTypePicker.vue';

export default Vue.extend({
  data() {
    return {
      panel: 0,
    };
  },

  props: {
    monitoringData: {
      type: Object,
      default: () => ({ type: 'FeatureCollection', features: [] }),
    },
  },

  computed: {
    countTransportTypes(): Array<Object> {
      return this.monitoringData?.summaryIndicators?.transportType;
    },

    numberOfClients(): number {
      return this.monitoringData?.summaryIndicators?.tripPointsCount;
    },

    activeWayDistance(): number {
      return this.monitoringData?.summaryIndicators?.activeWayDistance;
    },

    missedDeliveryWindowCount(): number {
      return this.monitoringData?.summaryIndicators?.missedDeliveryWindowCount;
    },
  },

  methods: {
    getIconByTransportType(type: string): string {
      return TRANSPORT_ICONS[type];
    },

    isNotEmpty(arr: Array<Object>): boolean {
      return !isEmpty(arr);
    },
  },
});
