import http from './httpV2';

const path = '/reports';

async function get() {
  const res = await http.get(path);
  return res.data;
}

async function update(data) {
  const res = await http.patch(path, data);
  return res.data;
}

export default {
  get,
  update,
};
