
import Vue from 'vue';
import { mapGetters } from 'vuex';

import ContractorsFilterDto from '@/components/filters/ContractorsFilterDto';

export default Vue.extend({
  name: 'ContractorsFilter',

  props: {
    form: {
      type: ContractorsFilterDto,
      required: true,
    },
  },

  data() {
    return {
      valid: true,
      filter: new ContractorsFilterDto(this.form),
      searchText: '',
    };
  },

  async created() {
    this.searchText = this.form.search || '';
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },

  methods: {
    searchUpdate() {
      this.filter.search = this.searchText;
    },

    openCreateContractorDialog() {
      this.$emit('createDialog');
    },
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        if (this.valid) {
          this.$emit('filterUpdate', this.filter);
        }
      },
    },

    valid() {
      if (this.valid) {
        this.$emit('filterUpdate', this.filter);
      }
    },

    searchText(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.searchUpdate();
      }
    },
  },
});
