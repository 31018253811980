<template>
  <v-card>
    <v-card-title primary-title>
      <div class="headline">
        {{ $t('Restore password') }}
      </div>
    </v-card-title>

    <v-alert color="success" :value="!!restoreDone">
      {{ successText }}
    </v-alert>

    <v-container grid-list-sm class="pa-4" v-if="!restoreDone">
      <Form :fields="restoreFields" :error="restoreError" v-model="restoreForm" @enter="restore" />
    </v-container>

    <v-card-actions>
      <v-btn
        @click="restore"
        :disabled="!restoreForm.valid"
        name="restore-send-btn"
        color="primary"
        v-if="!restoreDone"
      >
        {{ $t('Send password') }}
      </v-btn>

      <v-btn @click="$emit('back')" flat name="restore-back-btn">
        {{ $t('Back') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import store from '@/store';

import api from '@/api/restore';
import rules, { REG_EXPS } from '@/api/rules';

export default {
  name: 'RestorePassword',

  props: {
    login: {
      type: String,
      default: '',
    },
    show: Boolean,
  },

  data() {
    return {
      restoreFields: [
        {
          name: 'login',
          label: 'E-Mail or phone',
          type: 'text',
          rules: rules.login.required,
        },
      ],
      restoreError: null,
      restoreForm: {
        valid: false,
        data: {
          login: this.login,
        },
      },
      restoreDone: false,
    };
  },

  computed: {
    successText() {
      return REG_EXPS.email.test(this.restoreForm.data.login)
        ? this.$t('common_strings.password_recovery_sent')
        : this.$t('common_strings.password_recovery_sent_phone');
    },
  },

  methods: {
    async restore() {
      try {
        this.restoreDone = false;
        this.restoreError = null;

        await api.create({ login: this.restoreForm.data.login });

        this.restoreDone = true;
      } catch (err) {
        if (err.code === 'MultipleCompaniesFound') {
          this.$emit('multiple', this.restoreForm.data.login);

          if (Array.isArray(err.data) && err.data.length) {
            store.commit('company/setLoginCompanies', err.data);
          }
        } else {
          this.restoreError = err;
        }
      }
    },
  },
};
</script>
