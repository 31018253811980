
import Vue from 'vue';
import { mapGetters } from 'vuex';
import GridCrud, { defaultPagination } from '@/components/grid/GridCrud';
import { GridTh } from '@/components/grid/Grid';
import { AUTORELOAD_TIME } from '@/const';
import { Transport } from '@/models/Transport';
import transports from '@/api/transports';
import TransportDeletionWarning from '@/components/modals/transports/TransportDeletionWarning.vue';
import { FieldsOptions } from '@/components/grid/Options';
import TransportsImport from '@/components/import/TransportsImport.vue';

export default Vue.extend({
  name: 'Transports',

  components: { GridCrud, GridTh, TransportDeletionWarning, TransportsImport },

  data() {
    return {
      error: null,
      deletionWarning: false,
      deletionTransport: null as Transport | null,
      pagination: { ...defaultPagination },
      apiFunction: (opts: any) => transports.getTransports(opts),
      click: () => {},
      perPageItems: [10, 100, 150, { text: this.$t('All'), value: -1 }],
      perPage: 100,

      updateTime: null as string | null,
      timer: null as number | null,
    };
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    fields(): FieldsOptions {
      return {
        ownerName: {
          label: 'OwnerName',
          align: 'center',
          sortable: false,
        },
        name: {
          label: 'Name',
          align: 'center',
          sortable: true,
          defaultSort: true,
        },
        number: {
          label: 'Number',
          align: 'center',
          sortable: true,
        },
        type: {
          label: 'Type',
          align: 'center',
          sortable: true,
        },
        mass: {
          label: 'Mass',
          align: 'center',
          sortable: false,
        },
        volume: {
          label: 'Volume',
          align: 'center',
          sortable: false,
        },
        capacityX: {
          label: 'CapacityX',
          align: 'center',
          sortable: false,
        },
        capacityY: {
          label: 'CapacityY',
          align: 'center',
          sortable: false,
        },
        capacityZ: {
          label: 'CapacityZ',
          align: 'center',
          sortable: false,
        },
        length: {
          label: 'Length',
          align: 'center',
          sortable: false,
        },
        height: {
          label: 'Height',
          align: 'center',
          sortable: false,
        },
        actions: {
          label: 'TagsThs.Actions',
          align: 'center',
          sortable: false,
          width: 150,
        },
      };
    },
  },

  async created(): Promise<void> {
    await this.fetchData();
    this.timer = setInterval(this.fetchData, AUTORELOAD_TIME);
  },

  beforeDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  methods: {
    async fetchData(): Promise<void> {
      try {
        if (this.$refs.grid) {
          // @ts-ignore
          this.$refs.grid.refresh();
        }

        this.error = null;
      } catch (err) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }

        this.error = err;
      } finally {
        this.updateTime = this.$d(new Date(), 'date');
      }
    },

    createTransport() {
      this.$router.push({
        name: 'transportDetails',
        params: {
          id: 'new',
          back: this.$route.path,
        },
      });
    },

    editTransport(transport: Transport) {
      this.$router.push({
        name: 'transportDetails',
        params: {
          id: transport.id,
          back: this.$route.path,
        },
      });
    },

    deleteTransport(transport: Transport): void {
      this.deletionTransport = transport;
      this.deletionWarning = true;
    },

    closeWarning(): void {
      this.deletionWarning = false;
      this.deletionTransport = null;
      this.fetchData();
    },

    importDone(): void {
      this.fetchData();
    },
  },
});
