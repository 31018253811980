export default {
  namespaced: true,

  state: {
    ordersNumber: null,
  },

  getters: {
    companyHasOrders(state) {
      return state.ordersNumber > 0;
    },
  },

  mutations: {
    setOrdersNumber(state, data) {
      state.ordersNumber = data;
    },
  },
};
