<template>
  <v-flex>
    <v-card>
      <v-card-title
        ><h3>{{ $t('History of activities') }}</h3></v-card-title
      >
      <v-data-table :headers="headers" :items="events" class="text-xs-center" :must-sort="true">
        <template slot="items" slot-scope="props">
          <td>{{ $d(props.item.date * 1000, 'standard') }}</td>
          <td>{{ props.item.action }}</td>
          <td>{{ props.item.detail }}</td>
        </template>
      </v-data-table>
    </v-card>
  </v-flex>
</template>

<script>
import api from '@/api';
import Common from '@/components/billing/Common';

const formats = {
  current_period_start: (v, ctx) => ctx.$d(v * 1000, 'standard'),
  current_period_end: (v, ctx) => ctx.$d(v * 1000, 'standard'),
  slots: (v, ctx) => ctx.$tc('{0} accounts', v),
  count_of_date: (v, ctx) => ctx.$tc('{0} days', v),
  current_plan: (v, ctx) => ctx.userPlan(v),
  new_plan: (v, ctx) => ctx.userPlan(v),
  active_accounts: v => v,
  paid_slots: v => v,
};

export default {
  mixins: [Common],
  data() {
    return {
      info: {},
    };
  },

  created() {
    this.fetchEventLog();
  },

  methods: {
    async fetchEventLog() {
      this.info = await api.billing.info.getEventLog();
    },

    getAction(type, args) {
      return this.$t(`billing_event.${type}`, [this.$n(args.value, 'currency', args.currency)]);
    },

    formatArgs(args) {
      const result = {};
      for (const key in args) {
        result[key] = formats[key] ? formats[key](args[key], this) : args[key];
      }
      return result;
    },

    getDetails(type, args) {
      return this.$t(`billing_event_details.${type}`, this.formatArgs(args));
    },
  },

  computed: {
    headers() {
      return [
        { text: this.$t('Date'), align: 'center', value: 'date' },
        { text: this.$t('Action'), align: 'center', value: 'action' },
        { text: this.$t('Details'), align: 'center', value: 'detail' },
      ];
    },

    events() {
      if (!this.info || !this.info.log || !this.info.log.length) {
        return;
      }

      return this.info.log.map(event => ({
        date: event.created_at,
        action: this.getAction(event.event_type, event.event_type_field_variables),
        detail: this.getDetails(event.event_type, event.event_detail_field_variables),
      }));
    },
  },
};
</script>
