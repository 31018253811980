<template>
  <v-layout column>
    <v-flex pb-1 shrink>
      <v-layout row>
        <v-flex>
          <Breadcrumbs :items="breadcrumbs" />
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex>
      <MapFullPageWidget @boundsChanged="boundsChanged" ref="map" :loading="loading">
        <div v-if="monitoringData.features">
          <TripMonitor :data="monitoringData" />
        </div>
      </MapFullPageWidget>
    </v-flex>
  </v-layout>
</template>

<script>
import branch from '@/api/branch';
import { getDistributionMonitoring } from '@/api/distributions';

import Breadcrumbs from '@/components/Breadcrumbs';
import MapFullPageWidget from '@/components/map/MapFullPageWidgetNew';
import TripMonitor from '@/components/map/TripMonitor';

import { AUTORELOAD_TIME } from '@/const';

export default {
  name: 'DistributionMonitoring',

  metaInfo() {
    return {
      title: this.$i18n.t('Trips'),
      fillHeight: true,
    };
  },

  components: {
    Breadcrumbs,
    MapFullPageWidget,
    TripMonitor,
  },

  data() {
    return {
      monitoringData: {},
      timer: null,
      updatedPosition: false,
      boundsChangedTimer: null,
      loading: false,
    };
  },

  async created() {
    await this.fetchData();
    await branch.listMy();

    this.timer = setTimeout(this.fetchData, AUTORELOAD_TIME);
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.boundsChangedTimer) {
      clearTimeout(this.boundsChangedTimer);
      this.boundsChangedTimer = null;
    }
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('Planning'),
          href: { name: 'distributionList' },
        },
        {
          text: this.$t('Planning'),
          href: { name: 'distributionInfo', params: { id: this.$route.params.id } },
        },
        {
          text: this.$t('Map'),
          href: '',
          bold: true,
        },
      ];
    },
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;

        this.monitoringData = await getDistributionMonitoring(this.$route.params.id);
      } catch (err) {
        console.error(err);
        this.error = err;
      } finally {
        this.loading = false;
      }
    },

    boundsChanged() {
      if (this.updatedPosition) {
        return;
      }

      this.updatedPosition = true;

      if (this.boundsChangedTimer) {
        clearTimeout(this.boundsChangedTimer);
      }

      this.boundsChangedTimer = setTimeout(() => {
        this.updatedPosition = false;
      }, AUTORELOAD_TIME);
    },
  },
};
</script>
