
import Vue from 'vue';
import contractors from '@/api/contractors';
import rules from '@/api/rules';
import { Contractor } from '@/models/Contractor';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'EditContractor',

  data() {
    return {
      showDialog: true,
      error: null,
      internalContractor: null as Contractor | null,
    };
  },

  props: {
    outerContractor: {
      type: Object as () => Contractor,
    },
  },

  created(): void {
    this.internalContractor = { ...this.outerContractor };
  },

  computed: {
    checkValid(): boolean {
      return (
        Boolean(this.internalContractor!.name && this.internalContractor!.name !== this.outerContractor!.name) ||
        Boolean(
          this.internalContractor!.foreignId && this.internalContractor!.foreignId !== this.outerContractor!.foreignId,
        )
      );
    },

    typeNameRules() {
      return rules.required('Contractor name');
    },

    foreignIdRules() {
      return rules.foreignId;
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),

    async changeName(): Promise<void> {
      this.error = null;

      try {
        await contractors.patchContractor(this.outerContractor.id, {
          name: this.internalContractor!.name,
          foreignId: this.internalContractor!.foreignId,
        });

        this.$emit('fetch');
        this.showDialog = false;

        this.showSnackbar(this.$t('Success edit'));
      } catch (e) {
        this.error = e;
      }
    },

    hideModal(): void {
      this.$emit('close');
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
