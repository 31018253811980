
import Vue from 'vue';
import { mapGetters } from 'vuex';
import api from '@/api/radar';
import MapFullPageWidget from '@/components/map/MapFullPageWidgetNew';
import RadarPageMixin from '@/components/trip/RadarPageMixin';
import TripRoute from '@/components/map/TripRoute';
import ReviewForm from '@/components/review/ReviewForm.vue';
import { AUTORELOAD_TIME } from '@/const';
import Trip, { TripStatus } from '@/models/Trip';
import Review from '@/models/Review';

export default Vue.extend({
  name: 'TripShare',

  mixins: [RadarPageMixin],

  components: {
    MapFullPageWidget,
    TripRoute,
    ReviewForm,
  },

  data() {
    return {
      code: null as string | null,
      errorNote: false,
      error: null as any | null,
      tripError: null as { code: string } | null,
      timer: null as number | null,
      trip: new Trip(),
      review: null as Review | null,
      isShowReview: false,
    };
  },

  created() {
    if (window.location.pathname.indexOf('/share/') === 0) {
      this.code = window.location.pathname.substr(7);
      this.fetchData();
      this.createTimer();
    }
  },

  computed: {
    ...mapGetters('site', ['logo']),

    showAlert(): any {
      return this.error || this.tripError;
    },

    showReviewForm(): any {
      return Boolean(!this.error && this.trip.startedAt && this.reviewButtonTitle);
    },

    reviewButtonTitle(): any {
      if (!this.trip.finishedAt || Date.now() - new Date(this.trip.finishedAt).getTime() < 24 * 60 * 60 * 1000) {
        return this.review ? this.$t('review.button.edit') : this.$t('review.button.add');
      } else if (this.review) {
        return this.$t('review.button.complete');
      }

      return null;
    },

    showShareMap(): boolean {
      return Boolean(this.trip.id);
    },

    hideBrand(): boolean {
      return this.$route.name === 'trip-share';
    },
  },

  methods: {
    createTimer() {
      this.timer = setInterval(this.fetchData, AUTORELOAD_TIME, true);
    },

    async fetchData(isRefetch = false): Promise<void> {
      if (!this.code) {
        return;
      }

      try {
        this.errorNote = false;
        this.error = null;

        const data = await api.get(this.code);

        this.trip = new Trip(data);
        this.tripError = data.error || null;

        if (Array.isArray(this.trip.tripPoints) && this.trip.tripPoints.length) {
          if (!this.review && this.trip.tripPoints[0].review) {
            this.review = this.trip.tripPoints[0].review;
          }
        }

        if (
          [
            TripStatus.cancelled,
            TripStatus.finished,
            TripStatus.manuallyFinished,
            TripStatus.operatorCancelled,
          ].includes(this.trip.status)
        ) {
          this.errorNote = true;
          this.tripError = {
            code: 'RadarTripIsFinished',
          };
        }

        if (this.tripError) {
          if (isRefetch) {
            const mapWidget = this.$children.find(c => c.$options.name === 'MapWidget');
            if (mapWidget && mapWidget.$children[0]) {
              // @ts-ignore
              mapWidget.$children[0].clearMarkers();
              // @ts-ignore
              mapWidget.$children[0].clearCurrentPositionMarker();
              // @ts-ignore
              mapWidget.$children[0].clearAllLines();
            }
          }

          this.errorNote = true;

          if (!this.timer) {
            this.createTimer();
          }
        }
      } catch (err) {
        this.trip = new Trip();

        if (err.status === 422) {
          this.errorNote = true;
        }

        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }

        this.error = err;

        console.error(err);
      }
    },

    reviewComplete(review: Review): void {
      this.review = review;
    },

    showReview(): void {
      this.isShowReview = true;
    },
  },
});
