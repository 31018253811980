import gmap from '@/lib/gmap';

export default {
  name: 'WayControl',
  inject: ['getMap'],
  props: {
    state: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      map: null,
    };
  },
  async created() {
    this.map = await this.getMap();
    this.map.controls[gmap.ControlPosition.TOP_RIGHT].push(this.$el);
  },
  methods: {
    getIcon(h) {
      switch (this.state) {
        case 'loading_optimize':
          return (
            <v-icon size={40} color="green darken-2" class="custom-loader">
              sync
            </v-icon>
          );
        case 'optimize':
          return (
            <v-icon size={40} color="green darken-2">
              sync
            </v-icon>
          );
        case 'loading':
          return (
            <v-icon size={40} class="custom-loader">
              sync
            </v-icon>
          );
        case 'error':
          return (
            <v-tooltip left color="red darken-2">
              <span>{this.$t('Unable to build route')}</span>
              <v-btn slot="activator" flat icon onClick={this.handleErrorClick}>
                <v-icon size={40} color="red darken-2">
                  fa-exclamation-circle
                </v-icon>
              </v-btn>
            </v-tooltip>
          );
      }
    },

    handleErrorClick() {
      this.$emit('update');
    },
  },
  render(h) {
    return <div class="way-control">{this.map && <div>{this.getIcon(h)}</div>}</div>;
  },
};
