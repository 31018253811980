<template>
  <v-app>
    <v-content>
      <v-layout row fill-height justify-center align-center>
        <v-flex md6>
          <v-card>
            <v-card-title primary-title>
              <div class="headline">
                {{ $t('Restore password') }}
              </div>
            </v-card-title>

            <v-alert color="error" :value="notFound">
              {{ $t('Password reset link has been expired or invalid') }}
            </v-alert>

            <v-container grid-list-sm class="pa-4" v-if="!notFound">
              <Form :fields="fields" :error="error" v-model="form" @enter="submit" />
            </v-container>

            <v-card-actions v-if="!notFound">
              <v-btn @click="submit" :disabled="!form.valid" color="primary">
                {{ $t('Save new password') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>

<script>
import restore from '@/api/restore';
import rules from '@/api/rules';

export default {
  name: 'Restore',

  metaInfo() {
    return {
      title: this.$i18n.t('Reset password'),
    };
  },

  data() {
    return {
      fields: [
        {
          name: 'password',
          label: 'Password',
          type: 'password',
          ...rules.passwordAttrs,
          isRequired: true,
        },
      ],
      error: null,
      form: {
        valid: true,
        data: {
          password: '',
        },
      },
      notFound: false,
    };
  },

  created() {
    this.fetchRestore();
  },

  methods: {
    async fetchRestore() {
      try {
        this.error = null;

        await restore.get(this.$route.params.code);
      } catch (err) {
        this.error = err;
        if (err.status === 404) {
          this.notFound = true;
        }
      }
    },

    async submit() {
      try {
        this.error = null;

        await restore.reset(this.$route.params.code, this.form.data.password);

        this.$router.push(this.$route.query.redirect || '/');
      } catch (err) {
        this.error = err;
      }
    },
  },
};
</script>
