<template>
  <v-layout row align-end>
    <v-flex :xs8="!wide" mr-4>
      <v-menu
        ref="datePickerMenu"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
        v-model="showPicker"
        :close-on-content-click="false"
        :nudge-right="40"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            @input="updateTextDate"
            :style="{ minWidth: wide ? '200px' : '' }"
            :value="dateStr"
            :label="$t(label)"
            :prepend-icon="icon"
            :disabled="disabled"
            :placeholder="$t('datePlaceholder')"
            :error-messages="errorMessages || textDateError"
            :rules="rules"
            :readonly="showPicker"
            v-on="on"
          />
        </template>

        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :min="minDate"
          :max="maxDate"
          :locale="$i18n.locale"
          :first-day-of-week="1"
          @input="update"
          :disabled="disabled"
        />
      </v-menu>
    </v-flex>

    <v-flex :xs4="!wide" mr-2>
      <TimeInput
        :label="timeLabel"
        :disabled="(notDisableTime ? false : !this.date) || disabled"
        v-model="time"
        :min-value="minTime"
        :max-value="maxTime"
        :manual-input="manualTimeInput"
        @input="update"
        style="width: auto"
      />
    </v-flex>

    <v-flex v-if="shouldShowTimeZone" mb-4>
      {{ getTimeZoneString }}
    </v-flex>
  </v-layout>
</template>

<script>
import { format, isBefore, isSameDay, parse, parseISO, isValid, toDate } from 'date-fns';
import { isNil } from 'lodash';
import { getLocalTimezoneInSeconds, getTimeZoneString } from '@/lib/timezone';
import TimeInput from '@/components/inputs/TimeInput.vue';
import { convertDateWithTimeZone } from '@/lib/date';

const DATE_SPLIT_SYMBOL = /[\/|.]/;
const VALID_DATE_FORMAT = /^\d{4}\-\d{2}\-\d{2}$/;

export default {
  name: 'DatetimePicker',

  model: {
    prop: 'value',
    event: 'input',
  },

  components: { TimeInput },

  props: {
    value: {
      type: [Date, String],
      default: '',
    },
    returnIso: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    timeLabel: {
      type: String,
      default: '',
    },
    wide: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    min: {
      type: [Date, String],
      default: undefined,
    },
    max: {
      type: [Date, String],
      default: undefined,
    },
    allowToday: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notDisableTime: {
      type: Boolean,
      default: false,
    },
    manualTimeInput: {
      type: Boolean,
      default: false,
    },
    showTimeZone: {
      type: Boolean,
      default: false,
    },
    timeZoneOffset: {
      type: Number,
      default: 0,
    },
    errorMessages: Object,
    rules: Array,
  },

  data: () => ({
    offset: 0,
    showPicker: false,
    date: '',
    time: '',
    textDateError: null,
  }),

  async created() {
    if (this.notDisableTime) {
      this.time = null;
    }

    this.offset = getLocalTimezoneInSeconds();

    let value = this.value;

    if (this.timeZoneOffset !== null && !this.min) {
      this.offset += this.offset - this.timeZoneOffset;
      value = convertDateWithTimeZone(this.value, this.timeZoneOffset);
    }

    if (this.value) {
      this.date = format(this.parseISO(value), 'yyyy-MM-dd');
      this.time = format(this.parseISO(value), 'HH:mm');
    }
  },

  computed: {
    dateStr() {
      return this.date ? this.$d(this.date, 'dateonly') : '';
    },

    minDate() {
      if (!this.min) {
        return undefined;
      }

      const minDate = this.parseISO(this.min);

      if (this.allowToday || this.time >= format(this.parseISO(this.min), 'HH:mm')) {
        return new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()).toISOString();
      } else {
        return new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() + 1).toISOString();
      }
    },

    maxDate() {
      if (!this.max) {
        return undefined;
      }

      const maxDate = this.parseISO(this.max);

      if (this.allowToday || this.time > format(this.parseISO(this.max), 'HH:mm')) {
        return new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate()).toISOString();
      } else {
        return new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate() + 1).toISOString();
      }
    },

    minTime() {
      if (!this.min || (this.date && !isSameDay(parse(this.date, 'yyyy-MM-dd', new Date()), this.parseISO(this.min)))) {
        return undefined;
      }

      return format(this.parseISO(this.min), 'HH:mm');
    },

    maxTime() {
      if (!this.max || (this.date && !isSameDay(parse(this.date, 'yyyy-MM-dd', new Date()), this.parseISO(this.max)))) {
        return undefined;
      }

      return format(this.parseISO(this.max), 'HH:mm');
    },

    shouldShowTimeZone() {
      return this.showTimeZone && !isNil(this.timeZoneOffset);
    },

    getTimeZoneString() {
      return getTimeZoneString(this.timeZoneOffset);
    },
  },

  methods: {
    update() {
      this.textDateError = null;

      if (!this.date || !this.time) {
        return;
      }

      let time = this.time;
      const date = this.date;

      if (!time || isBefore(parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date()), this.parseISO(this.min))) {
        this.time = time = this.minTime;
      }

      let datetime = parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date());

      datetime = convertDateWithTimeZone(datetime, this.offset);

      const value = this.returnIso ? datetime.toISOString() : datetime;
      this.$emit('input', value);
    },

    parseISO(date) {
      return this.returnIso ? parseISO(date) : date;
    },

    updateTextDate(date) {
      const reversedDate = date
        .split(DATE_SPLIT_SYMBOL)
        .reverse()
        .join('-');

      if (reversedDate.match(VALID_DATE_FORMAT) && isValid(parse(reversedDate, 'yyyy-MM-dd', new Date()))) {
        this.date = reversedDate;

        this.update();
      } else {
        this.textDateError = this.$t('Date format should be correct');
      }
    },
  },

  watch: {
    min(minValue) {
      if (this.value instanceof Date && minValue && this.value.getTime() < minValue.getTime()) {
        this.date = format(this.parseISO(minValue), 'yyyy-MM-dd');
        this.time = format(this.parseISO(minValue), 'HH:mm');
        this.$emit('input', minValue);
      } else {
        this.date = format(this.parseISO(this.value), 'yyyy-MM-dd');
        this.time = format(this.parseISO(this.value), 'HH:mm');
      }
    },
  },
};
</script>
