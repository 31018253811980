<template functional>
  <div>
    <v-icon
      class="current-color"
      :style="'background-color:' + $options.methods.getColorCode(props.item.color)"
      align-center
    />
  </div>
</template>

<script>
import Vue from 'vue';
import colors from 'vuetify/lib/util/colors';

export default Vue.extend({
  name: 'TagColorGridField',

  methods: {
    getColorCode(color) {
      if (color) {
        return colors[color].base;
      }
    },
  },
});
</script>

<style>
.current-color {
  width: 30px;
  height: 30px;
}
</style>
