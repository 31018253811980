
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';

import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default Vue.extend({
  name: 'DistributionCreate',

  components: {
    Breadcrumbs,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('Planning'),
          href: { name: 'distributionList' },
        },
        {
          text: this.$t('Create planning'),
          href: { name: 'distributionCreate' },
          disabled: true,
        },
      ],
    };
  },

  computed: {
    ...mapState('company', ['settings']),
    ...mapGetters('auth', ['hasPermission']),
  },
});
