import { Dictionary } from 'vue-router/types/router';
import c from '@/lib/coerce';

class AccountsFilterDto {
  search: string | null = null;
  status: string[] = [];
  jobTitle: string | null = null;
  branches = {
    branches: [] as string[],
    accounts: [] as string[],
  };
  ownTransportType: string[] = [];
  inactive = false;

  constructor(data?: Partial<AccountsFilterDto>) {
    Object.assign(this, data);
  }

  fromQuery(query: Dictionary<string | (string | null)[]>) {
    const data = {
      search: query.search,
      status: c(query.status)
        .array()
        .coerce(),
      jobTitle: query.jobTitle,
      branches: {
        branches: c(query.branches)
          .array()
          .nullStr()
          .int()
          .coerce(),
        accounts: c(query.accounts)
          .array()
          .int()
          .coerce(),
      },
      ownTransportType: c(query.ownTransportType)
        .array()
        .coerce(),
      inactive: c(query.inactive)
        .boolean()
        .coerce(),
    };

    Object.assign(this, data);

    return this;
  }

  toQuery(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      search: this.search,
      status: this.status.map(v => `${v}`),
      jobTitle: this.jobTitle,
      branches: this.branches.branches.map(v => (v === null ? 'null' : `${v}`)),
      accounts: this.branches.accounts.map(v => `${v}`),
      ownTransportType: this.ownTransportType.map(v => `${v}`),
      inactive: this.inactive ? 'true' : null,
    };

    for (const i in query) {
      if (query[i] === null || query[i] === undefined || (query[i] instanceof Array && query[i]?.length === 0)) {
        delete query[i];
      }
    }

    return query as Dictionary<string | string[]>;
  }

  toHttpParams() {
    return this.toQuery();
  }
}

export default AccountsFilterDto;
