/* eslint-disable */
import MarkerBase, { IBaseMarker } from './MarkerBase';

export interface ISingleMarker extends IBaseMarker {}

export default MarkerBase.extend({
  render(h) {
    return h();
  },

  name: 'SingleMarker',

  watch: {
    async draggable(value) {
      if (this.marker) {
        if (value) {
          if (this.isActive) {
            this.marker.setDraggable(value);
          } else {
            this.marker.setMap(null);
          }
        } else {
          const map = await (this as any).getMap();
          this.marker.setDraggable(value);
          this.marker.setMap(map);
        }
      }
    },
  },
});
