import { mapGetters } from 'vuex';

export default {
  name: 'DeeplinkInvite',
  computed: {
    ...mapGetters('site', ['appstoreLink', 'googleplayLink']),
  },
  created() {
    if (this.appstoreLink.length > 0 && this.googleplayLink.length > 0) {
      const clickedAt = Date.now();
      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        setTimeout(() => {
          if (Date.now() - clickedAt < 2000) {
            window.location.href = this.googleplayLink;
          }
        }, 500);
      } else {
        this.$router.push({
          name: 'invite',
          params: {
            code: this.$route.params.code,
          },
        });
      }
    }
  },
  render() {},
};
