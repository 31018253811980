
import Vue from 'vue';
import { ImportResultError } from '@/api/tripNew';
import { PlaceLink } from '@/models/Place';
import ContactsInfo from '@/components/ContactsInfo.vue';

export default Vue.extend({
  name: 'OverwritingPlace',

  components: { ContactsInfo },

  props: {
    value: {
      type: Object as () => ImportResultError,
      required: true,
    },
    index: Number,
    amount: Number,
  },

  computed: {
    currentDataText(): string {
      return this.placeDataToString(this.value.currentData!);
    },
    passedDataText(): string {
      return this.placeDataToString(this.value.passedData!);
    },
  },

  methods: {
    placeDataToString(placeData: PlaceLink): string {
      return (
        (placeData.title ? this.$t('TitleName') + ': ' + placeData.title + '<br />' : '') +
        (placeData.location!.addresses[0] && placeData.location!.addresses[0].address
          ? this.$t('Address') + ': ' + placeData.location!.addresses[0].address + '<br />'
          : '') +
        (placeData.location?.coordinates
          ? this.$t('Coordinates') +
            ': ' +
            placeData.location!.coordinates.lat +
            ' ' +
            placeData.location!.coordinates.lng +
            '<br />'
          : '')
      );
    },
    overwrite(): void {
      this.$emit('overwrite', this.value.currentData!.id);
    },
    keepCurrent(): void {
      this.$emit('keep-current', this.value.currentData!.id);
    },
  },
});
