import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['company']),

    smsNotifyTimeItems() {
      return [
        {
          text: this.$tc('{0} minutes', 15),
          value: 15,
        },
        {
          text: this.$tc('{0} minutes', 30),
          value: 30,
        },
        {
          text: this.$tc('{0} hours', 1),
          value: 60,
        },
      ];
    },

    defaultSmsNotificationData() {
      return {
        type: 'time',
        text: '',
        minutesToArrival: 60,
      };
    },

    defaultDeliveryWindows(offset) {
      let from = new Date();
      from.setHours(9, 0, 0, 0);
      let to = new Date();
      to.setHours(19, 0, 0, 0);

      if (offset) {
        from.setSeconds(from.getSeconds() - offset);
        to.setSeconds(to.getSeconds() - offset);
      }

      return [
        {
          from: from,
          to: to,
        },
      ];
    },
  },
};
