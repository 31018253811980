<template functional>
  <div>
    <i18n path="Registration.agree_terms">
      <a
        v-for="link in $options.methods.getLinks(parent.$i18n.locale)"
        :place="link.place"
        :href="link.href"
        target="_blank"
        :key="link.place"
        @click.stop
        >{{ parent.$t(link.text) }}</a
      >
    </i18n>
  </div>
</template>

<script>
export default {
  methods: {
    getLinks(locale) {
      const key = locale === 'ru' ? 'ru' : 'others';
      const links = {
        ru: [
          {
            place: 'policy',
            href: 'http://homestretch.ru/policy/ru',
            text: 'instrumental_case.privacy_policy',
          },
          {
            place: 'policy_eng',
            href: 'http://homestretch.ru/policy',
            text: 'instrumental_case.privacy_policy_eng',
          },
          {
            place: 'terms',
            href: 'http://homestretch.ru/terms',
            text: 'instrumental_case.user_agreement',
          },
        ],
        others: [
          {
            place: 'cookie',
            href: 'http://homestretch.ch/cookie_policy',
            text: 'instrumental_case.cookie',
          },
          {
            place: 'user_agreement',
            href: 'http://homestretch.ch/user_license_agreement',
            text: 'instrumental_case.user_agreement',
          },
          {
            place: 'privacy_policy',
            href: 'http://homestretch.ch/privacy_policy',
            text: 'instrumental_case.privacy_policy',
          },
          {
            place: 'license_agreement',
            href: 'http://homestretch.ch/license_agreement',
            text: 'instrumental_case.license_agreement',
          },
          {
            place: 'terms_biz',
            href: 'http://homestretch.ch/terms_of_use_biz',
            text: 'instrumental_case.terms_biz',
          },
          {
            place: 'terms_user',
            href: 'http://homestretch.ch/terms_of_use_user',
            text: 'instrumental_case.terms_user',
          },
        ],
      };
      return links[key];
    },
  },
};
</script>
