<template>
  <v-text-field
    v-model.trim="location"
    :error-messages="errorMessages"
    :rules="rules"
    :disabled="disabled"
    :readonly="readonly"
    @keydown.prevent.enter="preventEnter"
    :autocomplete="autocomplete"
    :hint="coordinates"
    persistent-hint
  >
    <template #label>
      <strong v-if="isRequired" class="red--text">* </strong>
      {{ label }}
    </template>
  </v-text-field>
</template>

<script>
import Vue from 'vue';
import { get, set, unset, cloneDeep } from 'lodash';

export default Vue.extend({
  name: 'AddressInput',

  props: {
    value: Object,
    path: {
      type: String,
      default: 'location',
    },
    label: String,
    errorMessages: Object,
    rules: Array,
    preventEnter: {
      type: Function,
      default: () => {},
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    location: {
      get() {
        return get(this.value, `${this.path}.addresses[0].address`);
      },

      set(value) {
        if (value) {
          const newValue = {
            addresses: [
              {
                address: value,
                locale: this.$i18n.locale,
                addressComponents: null,
              },
            ],
            arrivalDuration: get(this.value, `${this.path}.arrivalDuration`, 0),
            departureDuration: get(this.value, `${this.path}.departureDuration`, undefined),
            transportRestrictions: get(this.value, `${this.path}.transportRestrictions`, []),
          };
          this.$emit('input', set(cloneDeep(this.value), this.path, newValue));
        } else {
          const outputValue = cloneDeep(this.value);
          unset(outputValue, this.path);
          this.$emit('input', outputValue);
        }
      },
    },

    coordinates() {
      const data = get(this.value, `${this.path}.coordinates`, null);

      return data && data.lat && data.lng
        ? `lat: ${this.$n(data.lat, 'coordinate')}, lng: ${this.$n(data.lng, 'coordinate')}`
        : undefined;
    },
  },
});
</script>
