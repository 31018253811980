
import Vue from 'vue';
import Grid, { GridTh } from '@/components/grid/Grid';
import shifts from '@/api/shifts';
import { AUTORELOAD_TIME } from '@/const';
import { Shift } from '@/models/Shift';
export default Vue.extend({
  name: 'Shifts',

  components: { Grid, GridTh },

  data() {
    return {
      items: [] as Array<any>,
      updateTime: null as string | null,
      timer: null as number | null,
      error: null,
    };
  },

  computed: {
    fields(): object {
      return {
        name: {
          label: 'Name',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        type: {
          label: 'Type',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        workingTimeFrom: {
          label: 'WorkingTimeFrom',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
        workingTimeTo: {
          label: 'WorkingTimeTo',
          align: 'center',
          sortable: true,
          defaultSort: 1,
        },
      };
    },
  },

  async created(): Promise<void> {
    await this.fetchData();
    this.timer = setInterval(this.fetchData, AUTORELOAD_TIME);
  },

  beforeDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },

  methods: {
    async fetchData(): Promise<void> {
      try {
        this.error = null;
        const shiftsResponceResult = (await shifts.getShifts()).result;
        this.items = await Promise.all(
          shiftsResponceResult.map(async (shift: Shift) => {
            return {
              name: shift.name,
              type: shift.type,
              workingTimeFrom: shift.workingTime && shift.workingTime.from,
              workingTimeTo: shift.workingTime && shift.workingTime.to,
            };
          }),
        );
      } catch (err) {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        this.error = err;
      } finally {
        this.updateTime = this.$d(new Date(), 'date');
      }
    },
    isIsoDate(str: string): boolean {
      const isoDateReg = /(?:^\d\d\d\d-[0-1]\d-[0-3]\d[t\s](?:[0-2]\d:[0-5]\d:[0-5]\d|23:59:60)(?:\.\d+)?(?:z|[+-]\d\d(?::?\d\d)?)$)/i;
      return isoDateReg.test(str);
    },
  },
});
