<template>
  <v-dialog v-model="restoreDialog" max-width="320">
    <v-card>
      <error-alert :value="restoreError" />

      <v-card-title class="headline">{{ restoreTitle || this.$t('Restore') }}?</v-card-title>

      <v-card-text>
        <span v-if="restoreItem">
          {{ $t('common_strings.restore_query_item', [restoreItem]) }}
        </span>

        <span v-else>
          {{ $t('common_strings.restore_query') }}
        </span>

        <br />

        <span v-if="supplementText" style="color: green; font-size: 13px">
          {{ supplementText }}
        </span>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn flat @click.native="hideModal">
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn color="green darken-2" flat @click.native="handleRestore" :disabled="Boolean(restoreError)">
          {{ $t('Restore') }}
        </v-btn>
      </v-card-actions>

      <v-progress-linear :indeterminate="true" :height="2" :active="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RestoreDialog',

  data() {
    return {
      restoreDialog: true,
    };
  },

  props: {
    restoreError: {
      type: Error,
      default: null,
    },
    restoreTitle: {
      type: String,
      default: null,
    },
    restoreItem: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    supplementText: String,
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    handleRestore() {
      this.$emit('restore');
    },
  },

  watch: {
    restoreDialog() {
      if (!this.restoreDialog) {
        this.$emit('close');
      }
    },
  },
};
</script>
