
import Vue from 'vue';
import tags from '@/api/tags';
import { Tag } from '@/models/Order';
import CustomColorPicker from '@/components/modals/CustomColorPicker.vue';
import rules from '@/api/rules';
import { mapMutations } from 'vuex';

export default Vue.extend({
  name: 'EditTag',

  components: { CustomColorPicker },

  data() {
    return {
      showDialog: true,
      error: null,
      internalTag: null as Tag | null,
    };
  },

  props: {
    outerTag: {
      type: Object as () => Tag,
    },
  },

  created(): void {
    this.internalTag = { ...this.outerTag };
  },

  computed: {
    checkValid(): boolean {
      const nameChanged = Boolean(this.internalTag!.name && this.internalTag!.name !== this.outerTag!.name);
      const colorChanged = Boolean(this.internalTag!.color && this.internalTag!.color !== this.outerTag!.color);
      const foreignIdChanged = Boolean(
        this.internalTag!.foreignId && this.internalTag!.foreignId !== this.outerTag!.foreignId,
      );

      return nameChanged || colorChanged || foreignIdChanged;
    },

    nameRules() {
      return rules.required('Tag name');
    },

    foreignIdRules() {
      return rules.foreignId;
    },
  },

  methods: {
    ...mapMutations('site', ['showSnackbar']),

    async changeTag(): Promise<void> {
      this.error = null;

      try {
        await tags.patchTag(this.outerTag.id, {
          name: this.internalTag!.name,
          color: this.internalTag!.color,
          foreignId: this.internalTag!.foreignId,
        });

        this.$emit('fetch');
        this.showDialog = false;

        this.showSnackbar(this.$t('Success edit'));
      } catch (e) {
        this.error = e;
      }
    },

    hideModal(): void {
      this.$emit('close');
    },

    colorChanged(color: any) {
      this.internalTag!.color = color;
    },
  },

  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.$emit('close');
      }
    },
  },
});
