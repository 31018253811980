import Vue from 'vue';
import Component from 'vue-class-component';
import Trip from '@/models/Trip';
import TripPageService from '@/services/TripPageService';

@Component
export default class RadarPageMixin extends Vue {
  trip!: Trip;
  unwatch!: Function;

  readonly dontAccountDeparture!: boolean;

  get tripPageService() {
    return new TripPageService(this.trip, undefined, {
      dontAccountDeparture: this.dontAccountDeparture,
    });
  }

  get mapPointsData() {
    return this.tripPageService.getMapPointsData();
  }
}
