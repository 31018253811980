export interface RuleDto {
  name: string;
  group: string;
  default: boolean;
  disabled: boolean;
  visible: boolean;
  deps: null | Array<string>;
}

export class Rule {
  name: string;
  group: string;
  default: boolean;
  disabled: boolean;
  visible: boolean;
  deps: null | Array<string>;

  constructor(rule: RuleDto) {
    this.name = rule.name;
    this.group = rule.group;
    this.default = rule.default;
    this.disabled = rule.disabled;
    this.visible = rule.visible;
    this.deps = rule.deps;
  }
}
