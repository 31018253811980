const raf = window.requestAnimationFrame || window.setImmediate || (c => setTimeout(c, 0));

export default {
  data() {
    return {
      scrollContainer: null,
      scrollTable: null,
      scrollThumbTopWrapper: null,
      scrollThumbTop: null,
      scrollThumbBottom: null,
      scrollResizeObserver: null,
      scrollThumbTopInitialY: 0,
      scrollRatio: 0,
      scrollLastX: 0,
    };
  },

  methods: {
    createScrollThumb(position) {
      const el = document.createElement('div');
      el.classList.add('scroll-thumb', position);
      el.addEventListener('mousedown', this.scrollDrag);
      return el;
    },

    moveScrollbar() {
      const scrollWidth = this.scrollContainer.scrollWidth;
      const clientWidth = this.scrollContainer.clientWidth;

      this.scrollRatio = clientWidth / scrollWidth;

      if (this.scrollRatio < 1) {
        raf(() => {
          const width = Math.max(this.scrollRatio * 100, 10) + '%';
          const left = (this.scrollContainer.scrollLeft / scrollWidth) * 100 + '%';

          this.scrollThumbTop.style.width = width;
          this.scrollThumbTop.style.left = left;

          this.scrollThumbBottom.style.width = width;
          this.scrollThumbBottom.style.left = left;
        });
      }

      const style = this.scrollRatio >= 1 ? 'none' : 'block';

      this.scrollThumbTopWrapper.style.display = style;
      this.scrollThumbBottom.style.display = style;
    },

    scrollDrag(e) {
      this.scrollLastX = e.pageX;

      document.addEventListener('mousemove', this.scrollDragMove);
      document.addEventListener('mouseup', this.scrollDragStop);

      this.scrollContainer.classList.add('scroll-drag');
    },

    scrollDragMove(e) {
      const delta = e.pageX - this.scrollLastX;

      this.scrollLastX = e.pageX;

      raf(() => {
        this.scrollContainer.scrollLeft += delta / this.scrollRatio;
      });
    },

    scrollDragStop() {
      document.removeEventListener('mousemove', this.scrollDragMove);
      document.removeEventListener('mouseup', this.scrollDragStop);

      this.scrollContainer.classList.remove('scroll-drag');
    },

    windowScroll() {
      const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const header = this.$el.querySelector('.tableFloatingHeaderOriginal');
      const containerRect = this.scrollContainer.getBoundingClientRect();

      setTimeout(() => {
        if (header.style.position == 'fixed') {
          this.scrollThumbTopWrapper.classList.add('sticky');
          this.scrollThumbTopWrapper.style.left = containerRect.left + 'px';
          this.scrollThumbTopWrapper.style.right = windowWidth - containerRect.left - containerRect.width + 'px';
        } else {
          this.scrollThumbTopWrapper.classList.remove('sticky');
          this.scrollThumbTopWrapper.style.left = 0;
          this.scrollThumbTopWrapper.style.right = 0;
        }
      }, 100);
    },
  },

  mounted() {
    const cardTitle = this.$el.parentElement ? this.$el.parentElement.querySelector('.v-card__title') : null;
    const cardLayout = this.$el.parentElement ? this.$el.parentElement.querySelector('.layout') : null;

    this.scrollContainer = this.$el.querySelector('.v-table__overflow');
    if (!this.scrollContainer) {
      return;
    }

    this.scrollContainer.classList.add('scroll');
    this.scrollContainer.addEventListener('scroll', this.moveScrollbar);

    this.scrollThumbTopWrapper = document.createElement('div');
    this.scrollThumbTopWrapper.classList.add('scroll-thumb-wrapper');
    this.scrollContainer.parentElement.appendChild(this.scrollThumbTopWrapper);

    this.scrollThumbTop = this.createScrollThumb('top');
    this.scrollThumbTopWrapper.appendChild(this.scrollThumbTop);

    this.scrollThumbBottom = this.createScrollThumb('bottom');
    this.scrollContainer.parentElement.appendChild(this.scrollThumbBottom);

    if (cardTitle) {
      this.scrollThumbTopWrapper.style.top = cardTitle.clientHeight + 'px';
    } else if (cardLayout) {
      const updateTop = () => {
        this.scrollThumbTopWrapper.style.top = cardLayout.clientHeight + 'px';
      };

      if (typeof ResizeObserver === 'function') {
        const observer = new MutationObserver(function(mutations) {
          mutations.forEach(function(mutation) {
            if (mutation.type === 'attributes') {
              updateTop();
            }
          });
        });
        observer.observe(this.$el.parentElement.parentElement, { attributes: true });
      }

      updateTop();
    }

    this.scrollThumbTopInitialY = this.scrollThumbTop.offsetTop;

    const table = this.$el.querySelector('.v-table');

    if (typeof ResizeObserver === 'function' && table) {
      this.scrollResizeObserver = new ResizeObserver(this.moveScrollbar);
      this.scrollResizeObserver.observe(table);
    }

    this.moveScrollbar();

    window.addEventListener('resize', this.moveScrollbar);
    window.addEventListener('scroll', this.windowScroll);
  },

  beforeDestroy() {
    this.scrollContainer.removeEventListener('scroll', this.moveScrollbar);

    if (this.scrollResizeObserver) {
      this.scrollResizeObserver.disconnect();
    }

    this.scrollThumbTopWrapper.remove();
    this.scrollThumbBottom.remove();

    window.removeEventListener('resize', this.moveScrollbar);
    window.removeEventListener('scroll', this.windowScroll);
  },
};
