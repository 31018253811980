import { Dictionary } from 'vue-router/types/router';
import c from '@/lib/coerce';

class OrdersFilterDto {
  date = {
    from: null as string | null,
    to: null as string | null,
  };
  search: string | null = null;
  paymentStatus: string[] = [];
  ownerIds = [] as string[];
  distributionName: string | null = null;
  price = {
    from: null as number | null,
    to: null as number | null,
  };
  amount = {
    from: null as number | null,
    to: null as number | null,
  };
  mass = {
    from: null as number | null,
    to: null as number | null,
  };
  volume = {
    from: null as number | null,
    to: null as number | null,
  };
  length = {
    from: null as number | null,
    to: null as number | null,
  };
  width = {
    from: null as number | null,
    to: null as number | null,
  };
  height = {
    from: null as number | null,
    to: null as number | null,
  };
  features: string[] = [];
  restrictions: string[] = [];
  demandType: string[] = [];
  tagIds: string[] = [];

  constructor(data?: Partial<OrdersFilterDto>) {
    Object.assign(this, data);
  }

  fromQuery(query: Dictionary<string | (string | null)[]>) {
    const data = {
      date: {
        from: query.dateFrom,
        to: query.dateTo,
      },
      search: query.search,
      paymentStatus: c(query.paymentStatus)
        .array()
        .coerce(),
      ownerIds: c(query.ownerIds)
        .array()
        .int()
        .coerce(),
      distributionName: query.distributionName,
      price: {
        from: c(query.priceFrom)
          .float()
          .coerce(),
        to: c(query.priceTo)
          .float()
          .coerce(),
      },
      amount: {
        from: c(query.amountFrom)
          .float()
          .coerce(),
        to: c(query.amountTo)
          .float()
          .coerce(),
      },
      mass: {
        from: c(query.massFrom)
          .float()
          .coerce(),
        to: c(query.massTo)
          .float()
          .coerce(),
      },
      volume: {
        from: c(query.volumeFrom)
          .float()
          .coerce(),
        to: c(query.volumeTo)
          .float()
          .coerce(),
      },
      length: {
        from: c(query.lengthFrom)
          .float()
          .coerce(),
        to: c(query.lengthTo)
          .float()
          .coerce(),
      },
      width: {
        from: c(query.widthFrom)
          .float()
          .coerce(),
        to: c(query.widthTo)
          .float()
          .coerce(),
      },
      height: {
        from: c(query.heightFrom)
          .float()
          .coerce(),
        to: c(query.heightTo)
          .float()
          .coerce(),
      },
      features: c(query.transportFeatures)
        .array()
        .coerce(),
      restrictions: c(query.executorRestrictions)
        .array()
        .coerce(),
      demandType: c(query.demandType)
        .array()
        .coerce(),
      tagIds: c(query['demands.tagIds'])
        .array()
        .coerce(),
    };

    Object.assign(this, data);

    return this;
  }

  toQuery(): Dictionary<string | string[]> {
    const query: Dictionary<string | string[] | null> = {
      dateFrom: typeof this.date.from === 'string' ? new Date(this.date.from).toISOString() : null,
      dateTo: typeof this.date.to === 'string' ? new Date(this.date.to).toISOString() : null,
      search: this.search,
      paymentStatus: this.paymentStatus.map(v => `${v}`),
      ownerIds: this.ownerIds.map(v => `${v}`),
      distributionName: this.distributionName,
      priceFrom: typeof this.price.from === 'number' ? `${this.price.from}` : null,
      priceTo: typeof this.price.to === 'number' ? `${this.price.to}` : null,
      amountFrom: typeof this.amount.from === 'number' ? `${this.amount.from}` : null,
      amountTo: typeof this.amount.to === 'number' ? `${this.amount.to}` : null,
      massFrom: typeof this.mass.from === 'number' ? `${this.mass.from}` : null,
      massTo: typeof this.mass.to === 'number' ? `${this.mass.to}` : null,
      volumeFrom: typeof this.volume.from === 'number' ? `${this.volume.from}` : null,
      volumeTo: typeof this.volume.to === 'number' ? `${this.volume.to}` : null,
      lengthFrom: typeof this.length.from === 'number' ? `${this.length.from}` : null,
      lengthTo: typeof this.length.to === 'number' ? `${this.length.to}` : null,
      widthFrom: typeof this.width.from === 'number' ? `${this.width.from}` : null,
      widthTo: typeof this.width.to === 'number' ? `${this.width.to}` : null,
      heightFrom: typeof this.height.from === 'number' ? `${this.height.from}` : null,
      heightTo: typeof this.height.to === 'number' ? `${this.height.to}` : null,
      features: this.features.map(v => `${v}`),
      restrictions: this.restrictions.map(v => `${v}`),
      demandType: this.demandType.map(v => `${v}`),
      'demands.tagIds': this.tagIds.map(v => `${v}`),
    };

    for (const i in query) {
      if (query[i] === null || query[i] === undefined || (query[i] instanceof Array && query[i]?.length === 0)) {
        delete query[i];
      }
    }

    return query as Dictionary<string | string[]>;
  }

  toHttpParams() {
    const query = this.toQuery();

    if (typeof query.dateFrom === 'string') {
      query.dateFrom = new Date(query.dateFrom).toISOString();
    } else {
      const dateFrom = new Date();

      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
      dateFrom.setMilliseconds(0);

      query.dateFrom = dateFrom.toISOString();
    }

    if (typeof query.dateTo === 'string') {
      query.dateTo = new Date(query.dateTo).toISOString();
    } else {
      const dateTo = new Date();

      dateTo.setDate(dateTo.getDate() + 1);
      dateTo.setHours(0);
      dateTo.setMinutes(0);
      dateTo.setSeconds(0);
      dateTo.setMilliseconds(0);

      query.dateTo = dateTo.toISOString();
    }

    return query;
  }
}

export default OrdersFilterDto;
