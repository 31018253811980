<template>
  <v-layout column>
    <v-flex pb-1>
      <h1>{{ $t('Price list') }}</h1>
    </v-flex>
    <v-flex>
      <v-card>
        <v-card-text class="text-xs-center">
          <h3>{{ $t('The cost is indicated for 1 account per month') }}</h3>
        </v-card-text>
        <v-data-table :headers="headers" :items="numSlotsList" hide-actions>
          <template slot="items" slot-scope="props">
            <td>{{ userPlan(props.item) }}</td>
            <td v-for="(slots, i) in numMonthList" :key="i">
              {{ getPlanCell(props.item, slots) }}
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Common from '@/components/billing/Common';

export default {
  metaInfo() {
    return {
      title: this.$i18n.t('Price list'),
    };
  },

  mixins: [Common],

  data() {
    return {
      numSlotsList: ['1-10', '11-50', '51-100', '101-1000000'],
      numMonthList: [1, 12],
      prices: {
        '1-10': { 1: 900, 12: 690 },
        '11-50': { 1: 810, 12: 660 },
        '51-100': { 1: 750, 12: 630 },
        '101-1000000': { 1: 690, 12: 600 },
      },
    };
  },

  methods: {
    getPlanCell(numOfMonth, numSlots) {
      const price = this.prices && this.prices[numOfMonth][numSlots];
      return this.$n(price, 'currency', 'RUB');
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('tariff\\subscription period'),
          align: 'left',
          sortable: false,
        },
        {
          text: this.$tc('{0} months', 1),
          align: 'left',
          sortable: false,
          class: 'test',
        },
        {
          text: this.$tc('{0} months', 12),
          align: 'left',
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
