
import { convertDateWithTimeZone } from '@/lib/date';
import { getTimeZoneString, getLocalTimezoneInSeconds } from '@/lib/timezone';

export default {
  methods: {
    convertDateWithTimeZone(date: Date, offset: number | null) {
      return convertDateWithTimeZone(date, offset);
    },

    isShowingTimezone(offset: number | null) {
      return offset !== getLocalTimezoneInSeconds();
    },

    getTimeZoneString(offset: number | null) {
      return getTimeZoneString(offset);
    },
  },
};
