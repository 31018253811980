import { PathLayer } from '@deck.gl/layers';

/**
 * This layers draws paths with stroked border. Path should have width of 5 pixels
 *
 * TODO: make path width as a prop
 */
export default class OutlinedPathLayer extends PathLayer {
  getShaders() {
    const shaders = super.getShaders();
    shaders.fs = `
#define SHADER_NAME path-layer-fragment-shader

precision highp float;

const vec3 BORDER_COLOR = vec3(0, 0, 0);

uniform float jointType;
uniform float miterLimit;

varying vec4 vColor;
varying vec2 vCornerOffset;
varying float vMiterLength;
/*
  * vPathPosition represents the relative coordinates of the current fragment on the path segment.
  * vPathPosition.x - position along the width of the path, between [-1, 1]. 0 is the center line.
  * vPathPosition.y - position along the length of the path, between [0, L / width].
  */
varying vec2 vPathPosition;
varying float vPathLength;

void main(void) {
  geometry.uv = vPathPosition;

  if (vPathPosition.y < 0.0 || vPathPosition.y > vPathLength) {
    // if joint is rounded, test distance from the corner
    if (jointType > 0.0 && length(vCornerOffset) > 1.0) {
      discard;
    }
    // trim miter
    if (jointType == 0.0 && vMiterLength > miterLimit + 1.0) {
      discard;
    }
  }

  float isLine = smoothedge(abs(vPathPosition.x), 0.6);
  gl_FragColor = vec4(mix(BORDER_COLOR, vColor.rgb, isLine), vColor.a);

  DECKGL_FILTER_COLOR(gl_FragColor, geometry);
}
`;
    return shaders;
  }
}
