
import Vue, { VNode } from 'vue';
import ResourceImport from './ResourceImport.vue';
import { importAccounts } from '@/api/import';

export default Vue.extend({
  functional: true,

  render(h, { parent, listeners }): VNode {
    return h(ResourceImport, {
      props: {
        title: parent.$t('Accounts import'),
        url: '/accounts',
        api: importAccounts,
      },
      on: listeners,
    });
  },
});
